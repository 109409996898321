import { makeActionCreator } from "_redux/rootActions";
import { API ,Auth} from "aws-amplify";


import {
  CLEAR_STATE,
  INIT_SITEMAP,
  DOCS_GET_REQUEST,
  DOCS_GET_SUCCESS,
  DOCS_GET_FAILURE,
  EDIT_DOCS_REQUEST,
  EDIT_DOCS_SUCCESS,
  EDIT_DOCS_FAILURE,
  LOCK_DOCS_REQUEST,
  LOCK_DOCS_SUCCESS,
  LOCK_DOCS_FAILURE,
  DELETE_DOCS_REQUEST,
  DELETE_DOCS_SUCCESS,
  DELETE_DOCS_FAILURE,  
  UPLOAD_DOCS_REQUEST,
  UPLOAD_DOCS_SUCCESS,
  UPLOAD_DOCS_FAILURE,
} from "_redux/siteDocuments/types";

import { SITE_MAP_API_ID } from "_config/api-constants";
//const API_HOST =  "https://tcd5fhp4g6.execute-api.us-east-1.amazonaws.com/dev/sites/";

export const clearState = makeActionCreator(CLEAR_STATE);
export const getSiteDocumentsRequest = makeActionCreator(DOCS_GET_REQUEST);
export const getSiteDocumentsSuccess = makeActionCreator(DOCS_GET_SUCCESS, "sitedocdata");
export const getSiteDocumentsFailure = makeActionCreator(DOCS_GET_FAILURE);

export const fetchSiteDocsData = (siteId) => {
  return async (dispatch) => {
    dispatch(getSiteDocumentsRequest());
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    console.log("Token : ",token);
    console.log("session  : ",session );
    try {
      const siteDocData = await API.get(SITE_MAP_API_ID,`/sites/${siteId}/documents`);      
      // const siteDocData = await new Promise((resolve, reject) => {
      //   fetch(`${API_HOST}${siteId}/documents`, {
      //     method: "GET",
      //   })
      //     .then((res) => res.json())
      //     .then(
      //       (result) => resolve(result),
      //       (error) => reject(error)
      //     )
      //     .catch((error) => reject(error));
      // });
      // return response;
      dispatch(getSiteDocumentsSuccess(siteDocData));
    } catch (e) {
      console.error(e);
      dispatch(getSiteDocumentsFailure());
    }
  };
};

export const editSiteDocumentsRequest = makeActionCreator(EDIT_DOCS_REQUEST);
export const editSiteDocumentsSuccess = makeActionCreator(EDIT_DOCS_SUCCESS, "sitedoceditdata");
export const editSiteDocumentsFailure = makeActionCreator(EDIT_DOCS_FAILURE);

export const editSiteDocsData = (data) => {
    const body = [{
      "id": data.id,
      "name": data.name,
      "isLocked": data.isLocked
    }]

  return async (dispatch) => {
    dispatch(editSiteDocumentsRequest());
    try {
      const siteEditDocData = await API.put(SITE_MAP_API_ID,`/sites/${data.siteId}/documents`,{body});      
      
      dispatch(editSiteDocumentsSuccess(siteEditDocData));
    } catch (e) {
      console.error(e);
      dispatch(editSiteDocumentsFailure());
    }
  };
};

export const lockSiteDocumentsRequest = makeActionCreator(LOCK_DOCS_REQUEST);
export const lockSiteDocumentsSuccess = makeActionCreator(LOCK_DOCS_SUCCESS, "sitedoclockdata");
export const lockSiteDocumentsFailure = makeActionCreator(LOCK_DOCS_FAILURE);

export const lockSiteDocsData = (data) => {
    const body = data.selectItemsList;

  return async (dispatch) => {
    dispatch(lockSiteDocumentsRequest());
    try {
      const siteLockDocData = await API.put(SITE_MAP_API_ID,`/sites/${data.siteId}/documents`,{body});      
      
      dispatch(lockSiteDocumentsSuccess(siteLockDocData));
    } catch (e) {
      console.error(e);
      dispatch(lockSiteDocumentsFailure());
    }
  };
};

export const deleteSiteDocumentsRequest = makeActionCreator(DELETE_DOCS_REQUEST);
export const deleteSiteDocumentsSuccess = makeActionCreator(DELETE_DOCS_SUCCESS, "sitedocdeletedata");
export const deleteSiteDocumentsFailure = makeActionCreator(DELETE_DOCS_FAILURE);

export const deleteSiteDocs = (data) => {
   
  return async (dispatch) => {
    dispatch(deleteSiteDocumentsRequest());
    try {
      const siteDeleteDocData = await API.del(SITE_MAP_API_ID,`/sites/${data.siteId}}/documents?documentIds=${data.selectItemsIDs}`); 
          
      
      dispatch(deleteSiteDocumentsSuccess(siteDeleteDocData));
    } catch (e) {
      console.error(e);
      dispatch(deleteSiteDocumentsFailure());
    }
  };
};

export const uploadSiteDocumentsRequest = makeActionCreator(UPLOAD_DOCS_REQUEST);
export const uploadSiteDocumentsSuccess = makeActionCreator(UPLOAD_DOCS_SUCCESS, "sitedocuploaddata");
export const uploadSiteDocumentsFailure = makeActionCreator(UPLOAD_DOCS_FAILURE);

export const uploadSiteDocs = (siteId,body) => {
  var headers = {"content-type":"multipart/form-data"}
   
  return async (dispatch) => {
    dispatch(uploadSiteDocumentsRequest());
    console.log("body ppppppppppp :",body);
    
    try {
      const siteUploadDocData = await API.post(SITE_MAP_API_ID,`/sites/${siteId}}/documents`,{body});           
      
      dispatch(uploadSiteDocumentsSuccess(siteUploadDocData));
    } catch (e) {
      console.error(e);
      dispatch(uploadSiteDocumentsFailure());
    }
  };
};






