// React
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";

// UI Framework
import { Button, Text, View, Input, TextArea, Form } from "native-base";

// Style
import { styles as global } from "_style/Global.style";
import { styles } from "./SharesiteViaMailEmail.style";
import { Image, TextInput } from "react-native";
import FormInput from "_components/common/FormInputOn.component";
const defaultState = {
  phone_number: "",
  email: "",
  errorMessage: "",
  errorMessages: {},
};
import {
  formatPhoneNumber,
  validateFormData,
  EMAIL_CONSTRAINT,
  PHONE_NUMBER_CONSTRAINT,
  REQUIRED_CONSTRAINT,
} from "_util/form-util";

import validation from "validate.js";
// Form Validation
const constraints = {
  phone_number: PHONE_NUMBER_CONSTRAINT,
  email: EMAIL_CONSTRAINT,
  controller: REQUIRED_CONSTRAINT("Controller or serial #"),
  category: REQUIRED_CONSTRAINT("Category"),
  message: REQUIRED_CONSTRAINT("Message"),
};

class SharesiteViaMailEmail extends Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
    this._handleFormInput = this._handleFormInput.bind(this);
  }

  _handleFormInput(fieldId, value) {
    const { errorMessages } = this.state;

    if (fieldId === "phone_number") {
      value = formatPhoneNumber(value);
    }

    // Always set the value of the form field to whatever the
    // user typed.
    this.setState({
      [fieldId]: value,
    });

    // Validate the form field
    const validationResult = validation.validate(
      { [fieldId]: value },
      { [fieldId]: constraints[fieldId] }
    );

    // Either set an error message or clear it for the form field.
    if (validationResult) {
      this.setState({
        errorMessages: {
          ...errorMessages,
          [fieldId]: validationResult[fieldId][0],
        },
      });
    } else {
      this.setState({
        errorMessages: {
          ...errorMessages,
          [fieldId]: null,
        },
      });
    }
  }
  phoneFormat = (value) => {
    const cleaned = value.replace(/\D/g, "");
    if (cleaned.length > 10) {
      return;
    }
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    const formatted = !match[2]
      ? match[1]
      : match[1] +
        "-" +
        match[2] +
        (match[3] ? "-" + match[3] : "") +
        (match[4] ? "" + match[4] : "");
    this.setState({ phone_number: formatted });
  };

  render() {
    const { errorMessage, errorMessages } = this.state;
    const {
      teleMailLable,
      messageLable,
      submitButtonText,
      inputMsgValue,
      onChangetext,
      mobileNumber,
      inputValue,
      onTextChange,
      copyConfirmText,
      mailSubmit,
      textSubmit,
      submit,
      onBlur,
      showHelpText,
    } = this.props;

    return (
      <View style={[styles.confirmRemovalModalContent]}>
        <Form>
          <View style={[styles.InputCol, { marginBottom: 4 }]}>
            {mobileNumber ? (
              <FormInput
                fieldId={"phone_number"}
                onChange={() => {}}
                //onChange={this._handleFormInput}
                onInput={(e) => this.phoneFormat(e)}
                inputValue={this.state.phone_number}
                maxLength={15}
                placeholder={"Phone Number"}
                floatingLabel={"Mobile Number"}
                helpText={"###-###-####"}
                itemStyles={[global.item]}
                inputStyles={[global.input]}
                errorMessage={errorMessages["phone_number"] || ""}
              />
            ) : (
              <FormInput
                fieldId={"email"}
                onChange={this._handleFormInput}
                onInput={(e) => this.setState({ email: e })}
                inputValue={this.state.email}
                placeholder={"Email"}
                maxLength={256}
                floatingLabel={"Email Address"}
                itemStyles={[global.item]}
                inputStyles={[global.input]}
                errorMessage={errorMessages["email"] || ""}
              />
            )}
            {Object.values(errorMessages).every(
              (x) => x === null || x === ""
            ) &&
              this.props.error && (
                <Text style={{ color: "red", fontSize: 12, marginTop: 2 }}>
                  Fill required field
                </Text>
              )}
          </View>
          <View style={[styles.InputCol, { marginBottom: 6 }]}>
            <Text style={[styles.confirmRemovalText]}>{messageLable}</Text>
            <TextInput
              maxLength={500}
              style={[styles.input, { marginBottom: 3, height: 110 }]}
              value={inputMsgValue}
              onChangeText={onChangetext}
              multiline={true}
            />
          </View>

          <Button
            style={[
              global.confirmationButton,
              { marginBottom: 15, marginTop: 15 },
            ]}
            onPress={() =>
              Object.values(errorMessages).every((x) => x === null || x === "")
                ? this.props.submit(this.state)
                : null
            }
          >
            <Text style={[global.confirmationButtonText]}>
              {submitButtonText}
            </Text>
          </Button>
        </Form>
      </View>
    );
  }
}

SharesiteViaMailEmail.propTypes = {
  teleMailLable: PropTypes.string,
  messageLable: PropTypes.string,
  onTextChange: PropTypes.any,
  onBlur: PropTypes.any,
  inputMsgValue: PropTypes.any,
  onChangetext: PropTypes.any,
  submit: PropTypes.func,
  mobileNumber: PropTypes.any,
  mailSubmit: PropTypes.func,
  textSubmit: PropTypes.func,
  copysubmit: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string,
  copyConfirmText: PropTypes.string,
  inputValue: PropTypes.string,
  showHelpText: PropTypes.any,
  error: PropTypes.any,
};

export default connect(null, null)(SharesiteViaMailEmail);
