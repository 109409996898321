import { StyleSheet } from 'react-native';
import {
  yellow,
  textDark,
  robotoCondensed,
} from '_style/Global.style';

export const styles = StyleSheet.create({
  valveContainer: {
    flexDirection: 'column',
    //justifyContent: 'space-between',
    width: "100%",
    marginTop: 10,
  },
  valveRow: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: '#424242',
    paddingTop: 5,
    paddingBottom: 5,
  },
  valveLabel: {
    //width: '30%',
    fontFamily: robotoCondensed,
    fontSize: 18,
  }
});
