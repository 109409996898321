import { StyleSheet } from 'react-native';

// Common Colors
export const placeholderTextColor = 'rgba(255, 255, 255, 0.6)';

export const textLight = '#FAFAFA';
export const textMedium = '#424242';
export const textDark = '#000000';
export const light_yellow = '#F2C94C';
export const yellow = '#EEB310';
export const red = 'red';
export const green = '#006644';
export const lighter_gray = '#B3B3B3';
export const light_gray = '#424242';
export const dark_gray = '#212121';
export const darker_gray = '#1A1A1A';

export const container_gray = '#383838';

export const card_gray = '#B2B2B2';
export const card_yellow = yellow;
export const card_red = '#C82A32';
export const card_green = '#006644';

export const roboto = 'Roboto';
export const robotoMono = 'Roboto Mono';
export const robotoMonoBold = 'Roboto Mono Bold';
export const robotoCondensed = 'Roboto Condensed';
export const robotoCondensedBold = 'Roboto Condensed Bold';

export const SFPro = 'SF Pro';

// Common Configurations
const button = {
  borderRadius: 5,
};
const buttonText = {
  marginLeft: 'auto',
  marginRight: 'auto',
};
const toast = {
  fontSize: 28,
  fontFamily: roboto,
  paddingLeft: 25,
  paddingRight: 25,
  paddingTop: 10,
  paddingBottom: 10,
  marginLeft: 'auto',
  marginRight: 'auto',
  textTransform: 'capitalize'
};

export const styles = StyleSheet.create({
  background: {
    backgroundColor: 'rgba(17, 21, 14, 0.9)',
  },
  backgroundImage: {
    flex: 1,
    // resizeMode: 'cover',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  button,
  buttonYellow: {
    ...button,
    backgroundColor: light_yellow,
  },
  buttonYellowText: {
    ...buttonText,
    color: textDark,
  },
  cancelButton: {
    ...button,
    backgroundColor: light_gray,
    shadowColor: 'black',
    shadowRadius: 8,
    shadowOpacity: .5,
    shadowOffset: {width: 4, height: 4},
    elevation: 0.5,
  },
  cancelButtonText: {
    ...buttonText,
    color: "#FAFAFA",
  },
  confirmationButton: {
    ...button,
    backgroundColor: yellow,
    shadowColor: 'black',
    shadowRadius: 8,
    shadowOpacity: .5,
    shadowOffset: {width: 4, height: 4},
    elevation: 0.5,
  },
  confirmationButtonText: {
    ...buttonText,
    color: textDark,
    fontWeight: 'bold',
    letterSpacing: 1.6,
    fontFamily: 'Roboto',
  },

  dropShadow: {
    shadowColor: 'black',
    shadowRadius: 8,
    shadowOpacity: .5,
    shadowOffset: {width: 4, height: 4},
    elevation: 0.5,
  },

  defaultButton: {
    ...button,
    backgroundColor: dark_gray,
    shadowColor: 'black',
    shadowRadius: 8,
    shadowOpacity: .5,
    shadowOffset: {width: 4, height: 4},
    elevation: 0.5,
    height: 64,
    width: '100%',
  },
  defaultButtonText: {
    ...buttonText,
    color: textLight,
    fontWeight: 'bold',
    fontSize: 36,
  },
  authFormButton: {
    ...button,
    backgroundColor: light_yellow,
    borderRadius: 15,
    height: 50,
    maxWidth: 400,
    width: '100%',
    marginTop: 10,
  },
  authFormButtonText: {
    ...buttonText,
    color: textDark,
    fontSize: 24,
  },
  authInputContainer: {
    marginBottom: 20,
  },
  authInput: {
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    borderRadius: 14,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0.15)',

    color: '#FAFAFA',

    fontSize: 24,
    fontFamily: SFPro,

    paddingLeft: 20,
    paddingRight: 20,
  },
  authItem: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: 60,
    width: '100%',
    borderBottomWidth: 0,
  },
  authInputHelpText: {
    color: yellow,
    fontSize: 18,
  },
  authInputErrorText: {
    color: red,
    fontSize: 18,
  },
  card: {
    backgroundColor: 'rgba(0, 0, 0, 0.78)',
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    borderRadius: 20,
  },
  cardItem: {
    borderLeftWidth: 0,
    backgroundColor: 'transparent',
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  noBorder: {
    borderBottomWidth: 0,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
  input: {
    width: '100%',

    backgroundColor: light_gray,
    height: 41,
    borderColor: 'rgba(255, 255, 255, 0.15)',
    borderRadius: 4,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    fontSize: 18,
    fontFamily: 'SF Pro',
    color: '#FAFAFA',
    paddingLeft: 20,
    paddingRight: 20,
    // marginBottom: 20,
  },
  item: {
    width: '100%',
    borderBottomWidth: 0,
    marginLeft: 0,
  },
  fontRoboto: {
    fontFamily: 'Roboto',
  },
  fontRobotoCondensed: {
    fontFamily: robotoCondensed,
  },
  fontRobotoCondensedBold: {
    fontFamily: 'Roboto Condensed Bold',
  },
  fontRobotoMonoBold: {
    fontFamily: robotoMonoBold,
  },
  fontSF: {
    fontFamily: 'SF Pro'
  },
  textLight: {
    color: textLight
  },
  textDark: {
    color: textDark,
  },
  textYellow: {
    color: yellow,
  },
  telemetryValue: {
    color: yellow,
    fontSize: 21,
    fontFamily: robotoMonoBold,
  },
  pageTitle: {
    color: textLight,
    fontSize: 48,
    fontWeight: 'bold',
    alignSelf: 'flex-start',
    letterSpacing: 1.6,
  },
  googleIcon: {
    width: 29,
    height: 30,
  },
  googleButton: {
    ...button,
    backgroundColor: '#F2F2F2',
    height: 50,
    maxWidth: 400,
    marginBottom: 10,
    marginTop: 10,
    width: '100%',
    justifyContent: 'center',
  },
  googleButtonText: {
    fontSize: 20,
    color: '#000000'
  },
  facebookButton: {
    ...button,
    backgroundColor: '#3B5998',
    height: 50,
    maxWidth: 400,
    width: '100%',
    marginBottom: 10,
    marginTop: 20,
    justifyContent: 'center'
  },
  facebookButtonText: {
    fontSize: 20,
    color: '#F1F1F1'
  },
  connectingText: {
    fontSize: 36,
  },
  facebookIcon: {
    height: 42,
    width: 42,
  },
  separator: {
    width: 425,
    borderBottomWidth: 2,
    borderBottomColor: '#F1F1F1',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#1A1A1A',
    width: '100%',
    height: '100%',
    borderRadius: 10,
    padding: 15,
  },
  disabledContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    position:'absolute',
    top: 0,
    bottom: 0,
    opacity: 0.8,
    backgroundColor: 'black',
    width: '100%',
    height: 585,
    zIndex: 1,
  },
  disconnectedContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position:'absolute',
    top: 0,
    bottom: 0,
    opacity: 0.9,
    backgroundColor: dark_gray,
    width: '100%',
    height: 585,
    zIndex: 1,
  },
  disconnectedErrorContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position:'absolute',
    top: 0,
    bottom: 0,
    opacity: 0,
    backgroundColor: dark_gray,
    width: '100%',
    height: 585,
    zIndex: 1,
  },
  toastContainer: {
    shadowColor: 'black',
    shadowRadius: 8,
    shadowOpacity: .5,
    shadowOffset: {width: 4, height: 4},
    elevation: 0.5,
    width: '50%',
    opacity: 0.6,
  },
  toast: {
    ...toast,
    color: green,
  },
  toastError: {
    ...toast,
    color: "#FFFFFF",
  },
  capitalized : {
    textTransform:'uppercase'
  },
});
