import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  confirmRemovalModalContent: {
    width: "425",
    marginLeft: 75,
    marginRight: 75,
  },
  confirmRemovalText: {
    textAlign: "center",
    marginTop: 20,
    fontSize: 18,
    marginBottom: 40,
    letterSpacing: 1.6,
  },
  btnstyle: {
    height: 40,
    //fontSize: 18,
    marginBottom: 15,
  },
});
