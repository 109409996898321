// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import {
  addGroup,
  updateGroup,
} from '_redux/admin/actions';

import FormInput from '_components/common/FormInput.component';

// UI Framework
import {
  Button,
  Spinner,
  Text,
  View,
} from 'native-base';
import { Image } from 'react-native';

import {
  formatPhoneNumber,
  validateFormData,
  validateFormField,
  updateFormData,
  PHONE_NUMBER_CONSTRAINT,
  REQUIRED_CONSTRAINT,
} from '_util/form-util';

// Style
import { styles as global } from '_style/Global.style';
import { styles } from '_components/accounts/AddEditAccountModalContent.style';

// Form Validation
const constraints = {
  group_name: REQUIRED_CONSTRAINT('Client Name'),
  website: REQUIRED_CONSTRAINT('Website'),
  phone_number: PHONE_NUMBER_CONSTRAINT,
  street: REQUIRED_CONSTRAINT('Street'),
  city: REQUIRED_CONSTRAINT('City'),
  state: REQUIRED_CONSTRAINT('State'),
  zip: REQUIRED_CONSTRAINT('Zip'),
  industry: REQUIRED_CONSTRAINT('Industry'),
};

/**
 * Renders the content for the Add and Edit screens within the Jobs
 * Modal.
 */
class AddEditAccountModalContent extends Component {

  /**
   * Constructor.
   *
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.state = {
      errorMessages: {},
      formData: props.formData || {},
      addGroupSuccess: false,
    },

    this._handleFormInput = this._handleFormInput.bind(this);
    this._onCancel = this._onCancel.bind(this);

    this._createClientAccount = this._createClientAccount.bind(this);
    this._updateClientAccount = this._updateClientAccount.bind(this);

    this._generateAddEditContent = this._generateAddEditContent.bind(this);
    this._generateSuccessContent = this._generateSuccessContent.bind(this);
  }

  /**
   * Check to see if we successfully added or edited a group, show the
   * appropriate success response and close the modal.
   *
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps){
    const {
      isAddingGroup,
      addGroupFailed,

      isUpdatingGroup,
      updateGroupFailed,
    } = this.props;
    const {
      isAddingGroup: prevIsAddingGroup,
      isUpdatingGroup: prevIsUpdatingGroup,
    } = prevProps;

    // Check for add group success
    if (prevIsAddingGroup && !isAddingGroup && !addGroupFailed) {
      this.setState({
        addGroupSuccess: true,
      });
    }

    // Check for update group success
    if (prevIsUpdatingGroup && !isUpdatingGroup && !updateGroupFailed) {
      this._onCancel();
    }
  }

  /**
   *
   * @param {*} fieldId
   * @param {*} value
   */
  _handleFormInput(fieldId, value) {
    const { errorMessages, formData } = this.state;

    if (fieldId === 'phone_number') {
      value = formatPhoneNumber(value);
    }
    const errorMessage = validateFormField(fieldId, value, constraints);

    this.setState(updateFormData(
      fieldId,
      value,
      errorMessage,
      formData,
      errorMessages,
    ));
  }

  /**
   * Submit the form, creating the new client account.
   */
  _createClientAccount() {
    const { parentGroup } = this.props;
    const { formData } = this.state;

    const errorMessages = validateFormData(formData, constraints);

    if (Object.keys(errorMessages).length > 0) {
      this.setState({
        errorMessages,
      });
      return;
    }
    const groupInfo = formData;
    groupInfo.groupParentId = parentGroup.group_id;
    groupInfo.groupRoleId = parentGroup.child_role_id;

    this.props.addGroup(formData);
  }

  /**
   * Submit an edit for the group.
   */
  _updateClientAccount() {
    const { formData } = this.state;

    const errorMessages = validateFormData(formData, constraints);

    if (Object.keys(errorMessages).length > 0) {
      this.setState({
        errorMessages,
      });
      return;
    }

    this.props.updateGroup(formData);
  }

  /**
   * Close the modal.
   */
  _onCancel() {
    this.props.onCancel();
  }

  /**
   *
   */
  _generateSuccessContent() {
    const {
      formData,
    } = this.state;
    return (
      <View style={[styles.successContainer]}>
        <Image style={[styles.successIcon]} source={require('_assets/images/success-check.png')} />
        <Text style={[styles.successText]}>
          CONGRATULATIONS
        </Text>
        <Text style={[styles.successDescriptionText]}>
          YOU HAVE SUCCESSFULLY CREATED THE ACCOUNT
        </Text>
        <Text style={[styles.accountNameText]}>
          { formData.group_name }
        </Text>
        <Text style={[styles.subTitle, { width: 350 }]}>
          You can now add system users to their account by clicking view acount next to their name, view system users, create new system user.
        </Text>
        <Button style={[global.cancelButton, styles.actionButton]} onPress={this._onCancel}>
          <Text style={[global.cancelButtonText, styles.closeButtonText]}>
            CLOSE
          </Text>
        </Button>
      </View>

    );
  }

  /**
   * Generate the main form content.
   */
  _generateAddEditContent() {
    const {
      editMode = false,
      isAddingGroup,
      addGroupFailed,
      addGroupErrorMessage,

      isUpdatingGroup,
    } = this.props;

    const {
      errorMessages,
      formData,
    } = this.state;

    return (
      <View style={[styles.content]}>
          <Text style={[styles.subTitle]}>
            Fill out the information below to create a new Client Account
          </Text>
          {/* CLIENT NAME */}
          <View style={[styles.formRow]}>
            <View style={[styles.formLabelContainer]}>
              <Text style={[styles.formLabel]}>
                Client Name:
              </Text>
            </View>
            <View style={[styles.formInputContainer]}>
              <FormInput
                fieldId={'group_name'}
                onChange={this._handleFormInput}
                placeholder={'Client Name'}
                itemStyles={[global.item]}
                inputStyles={[global.input]}
                errorMessage={errorMessages['group_name'] || ''}
                defaultValue={formData.group_name || ''}
              />
            </View>
          </View>

          {/* WEBSITE*/}
          <View style={[styles.formRow]}>
            <View style={[styles.formLabelContainer]}>
              <Text style={[styles.formLabel]}>
                Website:
              </Text>
            </View>
            <View style={[styles.formInputContainer]}>
              <FormInput
                fieldId={'website'}
                onChange={this._handleFormInput}
                placeholder={'Website'}
                itemStyles={[global.item]}
                inputStyles={[global.input]}
                errorMessage={errorMessages['website'] || ''}
                defaultValue={formData.website || ''}
              />
            </View>
          </View>

          {/* CLIENT NAME */}
          <View style={[styles.formRow]}>
            <View style={[styles.formLabelContainer]}>
              <Text style={[styles.formLabel]}>
                Phone:
              </Text>
            </View>
            <View style={[styles.formInputContainer]}>
              <FormInput
                fieldId={'phone_number'}
                onChange={this._handleFormInput}
                placeholder={'Phone Number'}
                itemStyles={[global.item]}
                inputStyles={[global.input]}
                errorMessage={errorMessages['phone_number'] || ''}
                defaultValue={formData.phone_number || ''}
              />
            </View>
          </View>

          {/* ADDRESS 1 */}
          <View style={[styles.formRow]}>
            <View style={[styles.formLabelContainer]}>
              <Text style={[styles.formLabel]}>
                Address:
              </Text>
            </View>
            <View style={[styles.formInputContainer]}>
              <FormInput
                fieldId={'street'}
                onChange={this._handleFormInput}
                placeholder={'Street'}
                itemStyles={[global.item]}
                inputStyles={[global.input]}
                errorMessage={errorMessages['street'] || ''}
                defaultValue={formData.street || ''}
              />
            </View>
          </View>

          {/* ADDRESS 2 */}
          <View style={[styles.formRow]}>
            <View style={[styles.formLabelContainer]}>
              <Text style={[styles.formLabel]}>
              </Text>
            </View>
            <View style={[styles.formInputContainer, { flex: 3, margin: 0 }]}>
              <View style={[styles.formInputContainer, { flex: 2 }]}>
                <FormInput
                  fieldId={'city'}
                  onChange={this._handleFormInput}
                  placeholder={'City'}
                  itemStyles={[global.item]}
                  inputStyles={[global.input]}
                  errorMessage={errorMessages['city'] || ''}
                  defaultValue={formData.city || ''}
                />
              </View>
              <View style={[styles.formInputContainer,  { flex: 1 }]}>
                <FormInput
                  fieldId={'state'}
                  onChange={this._handleFormInput}
                  placeholder={'State'}
                  itemStyles={[global.item]}
                  inputStyles={[global.input]}
                  errorMessage={errorMessages['state'] || ''}
                  defaultValue={formData.state || ''}
                />
              </View>
              <View style={[styles.formInputContainer,  { flex: 1 }]}>
                <FormInput
                  fieldId={'zip'}
                  onChange={this._handleFormInput}
                  placeholder={'Zip'}
                  itemStyles={[global.item]}
                  inputStyles={[global.input]}
                  errorMessage={errorMessages['zip'] || ''}
                  defaultValue={formData.zip || ''}
                />
              </View>
            </View>
          </View>

          {/* INDUSTRY */}
          <View style={[styles.formRow]}>
            <View style={[styles.formLabelContainer]}>
              <Text style={[styles.formLabel]}>
                Industry:
              </Text>
            </View>
            <View style={[styles.formInputContainer]}>
              <FormInput
                fieldId={'industry'}
                onChange={this._handleFormInput}
                placeholder={'Industry'}
                itemStyles={[global.item]}
                inputStyles={[global.input]}
                errorMessage={errorMessages['industry'] || ''}
                defaultValue={formData.industry || ''}
              />
            </View>
          </View>

          {/* Notes */}
          <View style={[styles.formRow]}>
            <View style={[styles.formLabelContainer]}>
              <Text style={[styles.formLabel]}>
                Notes:
              </Text>
            </View>
            <View style={[styles.formInputContainer]}>
              <FormInput
                fieldId={'notes'}
                onChange={this._handleFormInput}
                placeholder={'Notes'}
                itemStyles={[global.item]}
                inputStyles={[global.input]}
                multiline={true}
                errorMessage={errorMessages['notes'] || ''}
                defaultValue={formData.notes || ''}
              />
            </View>
          </View>

          <View style={[styles.errorMessageContainer]}>
            {
              addGroupFailed &&
                <Text style={[styles.errorMessage]}>
                  { addGroupErrorMessage.message }
                </Text>

            }
          </View>

          <View style={[styles.actionButtonContainer]}>
            {
              isAddingGroup  || isUpdatingGroup ?
               <Spinner color={'red'} />
               :
                editMode ?
                  <Button style={[global.confirmationButton, { marginBottom: 15, marginTop: 25 }]} onPress={this._updateClientAccount}>
                    <Text style={[global.confirmationButtonText]}>
                      SAVE CHANGES
                    </Text>
                  </Button>
                :
                  <Button style={[global.confirmationButton, { marginBottom: 15, marginTop: 25 }]} onPress={this._createClientAccount}>
                    <Text style={[global.confirmationButtonText]}>
                      CREATE CLIENT ACCOUNT
                    </Text>
                  </Button>
            }

            <Button style={[global.cancelButton]} onPress={this._onCancel}>
              <Text style={[global.cancelButtonText]}>
                CANCEL
              </Text>
            </Button>
          </View>

        </View>
    );
  }

  render() {
    const {
      addGroupSuccess,
    } = this.state;


    return (
      <View style={[styles.main]}>
        { !addGroupSuccess ? this._generateAddEditContent() : this._generateSuccessContent() }
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAddingGroup: state.admin.isAddingGroup,
    addGroupFailed: state.admin.addGroupFailed,
    addGroupErrorMessage: state.admin.addGroupErrorMessage,

    isUpdatingGroup: state.admin.isUpdatingGroup,
    updateGroupFailed: state.admin.updateGroupFailed,
    updateGroupErrorMessage: state.admin.updateGroupErrorMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addGroup: (groupInfo) => dispatch(addGroup(groupInfo)),
    updateGroup: (groupInfo) => dispatch(updateGroup(groupInfo)),
  };
};

AddEditAccountModalContent.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func.isRequired,

  parentGroup: PropTypes.any.isRequired,
  formData: PropTypes.any,
  editMode: PropTypes.bool,

  addGroup: PropTypes.func.isRequired,
  isAddingGroup: PropTypes.bool.isRequired,
  addGroupFailed: PropTypes.bool.isRequired,
  addGroupErrorMessage: PropTypes.any.isRequired,

  updateGroup: PropTypes.func.isRequired,
  isUpdatingGroup: PropTypes.bool.isRequired,
  updateGroupFailed: PropTypes.bool.isRequired,
  updateGroupErrorMessage: PropTypes.any.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditAccountModalContent);
