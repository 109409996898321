const locators = [];

const getElevation = async (args) => {
  return new Promise((resolve, reject) => {
    const elevator = new window.google.maps.ElevationService();
    elevator
      .getElevationForLocations({
        locations: args,
      })
      .then(async ({ results }) => {
        if (results.length > 0) {
          resolve(results);
        } else {
          resolve([]);
        }
      })
      .catch((e) => resolve([]));
  });
};
const getElavationByPosition = async (args) => {
  const res = await getElevation(args);
  locators.push({
    locator: args,
    results: res,
  });
  return res;
};

export const ElevationUtil = {
  getElavationByPosition,
};
