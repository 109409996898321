// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import {
  assignDevice,
  clearGroupChildrenForAssignment,
  getGroupChildrenForAssignment,
} from '_redux/admin/actions';

// Util
import { getFriendlyUsername } from '_redux/user/utils';

import
  AccessControl,
  { ADMIN, MANUFACTURER, OWNER, DEALER }
from '_components/auth/AccessControl.component';
import CheckBox from '_components/common/CheckBox.component';

// UI Framework
import {
  Button,
  Spinner,
  Text,
  View,
} from 'native-base';

// Style
import { styles as global, dark_gray, light_gray, yellow, textLight } from '_style/Global.style';
import { styles } from '_components/accounts/ReassignDeviceModalContent.style';

const EDIT_MODE = {
  OFF: 0,
  MANUFACTURER: 1,
  DEALER: 2,
  OWNER: 3,
};

class ReassignDeviceModalContent extends Component {

  /**
   * Constructor.
   *
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.state = {
      detailsTitleGroup: '',
      selectedGroup: '',
      selectedGroupName: 'None',
      editMode: EDIT_MODE.OFF,
      dirty: false,

      selectedManufacturerId: props.device.level_1_id,
      selectedManufacturerName: props.device.level_1_name || 'None',
      selectedDealerId: props.device.level_2_id,
      selectedDealerName: props.device.level_2_name || 'None',
      selectedOwnerId: props.device.level_3_id,
      selectedOwnerName: getFriendlyUsername(props.device) || 'None',
    },

    this._onCancel = this._onCancel.bind(this);
    this._reassignDevice = this._reassignDevice.bind(this);
    this._generateGroupEditDisplay = this._generateGroupEditDisplay.bind(this);

    this._confirmNewGroup = this._confirmNewGroup.bind(this);
    this._updateSelectedGroup = this._updateSelectedGroup.bind(this);

    this._editManufacturer = this._editManufacturer.bind(this);
    this._editDealer = this._editDealer.bind(this);
    this._editOwner = this._editOwner.bind(this);
  }

  /**
   *
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    const { isAssigningDevice } = this.props;
    const { isAssigningDevice: prevIsAssigningDevice } = prevProps;

    if (prevIsAssigningDevice && !isAssigningDevice) {
      this.setState({
        dirty: false,
        editMode: EDIT_MODE.OFF,
      });
      this.props.clearGroupChildrenForAssignment();
    }
  }

  /**
   * Close the modal.
   */
  _onCancel() {
    this.props.clearGroupChildrenForAssignment();
    this.props.onCancel();
  }

  /**
   * Kick off the reassignment
   */
  _reassignDevice() {
    const {
      assignDevice,
      device,
    } = this.props;

    const {
      selectedManufacturerId,
      selectedDealerId,
      selectedOwnerId,
    } = this.state;

    assignDevice(device.device_id, selectedManufacturerId, selectedDealerId, selectedOwnerId);
  }

  /**
   * Event handler for when the user clicks one of the radio
   * buttons in the right container.
   *
   * @param {string} fieldId id of the field
   * @param {boolean} value true or false
   * @param {*} data data that contains the name of the field
   */
  _updateSelectedGroup(fieldId, value, data) {

    if (!value || fieldId === 'none') {
      this.setState({
        selectedGroup: 'none',
        selectedGroupName: 'None',
      });
    } else {
      this.setState({
        selectedGroup: fieldId,
        selectedGroupName: data.name,
      });
    }
  }

  /**
   *
   */
  _confirmNewGroup() {
    const {
      editMode,
      selectedGroup,
      selectedGroupName,
    } = this.state;

    if (editMode === EDIT_MODE.MANUFACTURER) {
      this.setState({
        dirty: true,
        selectedManufacturerId: selectedGroup,
        selectedManufacturerName: selectedGroupName,
      });
    } else if (editMode === EDIT_MODE.DEALER) {
      this.setState({
        dirty: true,
        selectedDealerId: selectedGroup,
        selectedDealerName: selectedGroupName,
      });
    } else {
      this.setState({
        dirty: true,
        selectedOwnerId: selectedGroup,
        selectedOwnerName: selectedGroupName,
      });
    }
  }

  _editManufacturer() {
    const {
      getGroupChildrenForAssignment,
    } = this.props;

    const {
      selectedManufacturerId,
      selectedManufacturerName,
    } = this.state;

    this.setState({
      detailsTitleGroup: 'Manufacturer',
      editMode: EDIT_MODE.MANUFACTURER,
      selectedGroup: selectedManufacturerId,
      selectedGroupName: selectedManufacturerName,
    });

    getGroupChildrenForAssignment('murcal', false);
  }

  _editDealer() {
    const {
      getGroupChildrenForAssignment,
    } = this.props;

    const {
      selectedManufacturerId,
      selectedDealerId,
      selectedDealerName,
    } = this.state;

    this.setState({
      detailsTitleGroup: 'Dealer',
      editMode: EDIT_MODE.DEALER,
      selectedGroup: selectedDealerId,
      selectedGroupName: selectedDealerName,
    });

    getGroupChildrenForAssignment(selectedManufacturerId, false);
  }

  _editOwner() {
    const {
      getGroupChildrenForAssignment,
    } = this.props;

    const {
      selectedDealerId,
      selectedOwnerId,
      selectedOwnerName,
    } = this.state;

    this.setState({
      detailsTitleGroup: 'Owner',
      editMode: EDIT_MODE.OWNER,
      selectedGroup: selectedOwnerId,
      selectedGroupName: selectedOwnerName,
    });

    getGroupChildrenForAssignment(selectedDealerId, true);
  }

  _generateGroupEditDisplay(groupTitle, groupName, editHandler, allowedGroups, requiredId, disabled) {
    const {
      editMode,
    } = this.state;

    // Gray out the sections that aren't active.
    disabled = disabled && editMode !== EDIT_MODE.OFF;

    return (
      <View style={[styles.groupContainer]}>
        <View style={[styles.groupTitleRow]}>
          <Text style={[styles.groupLabelText, disabled ? { color: light_gray} : {}]}>
            { groupTitle }
          </Text>
          <AccessControl allowedGroups={allowedGroups}>
            {
              requiredId && requiredId !== 'none' &&
                <Button style={[styles.editButton, global.dropShadow, disabled ? { backgroundColor: light_gray} : {}]} onPress={editHandler}>
                  <Text style={[styles.editButtonText]}>
                    EDIT
                  </Text>
                </Button>
            }
          </AccessControl>
        </View>
        <View style={[styles.groupInputRow]}>
          <Text style={[styles.selectedGroupText, disabled ? { color: light_gray} : {}]}>
            { groupName }
          </Text>
        </View>
      </View>
    );
  }

  _generateGroupList() {
    const {
      groupChildrenForAssignment,
    } = this.props;
    const {
      editMode,
      selectedGroup,
    } = this.state;

    const groupList = [];
    groupList.push((
      <View key={'none'} style={[styles.checkboxRow]}>
        <CheckBox
          fieldId={'none'}
          label={'None'}
          data={{ name: 'None' }}
          onChange={this._updateSelectedGroup}
          checked={!selectedGroup || (selectedGroup === 'none')}
          width={20}
        />
      </View>
    ));

    if (editMode === EDIT_MODE.OWNER) {
      groupChildrenForAssignment.forEach((user, index) => {
        if (user.role_id !== OWNER) {
          return;
        }

        const lightRow = index % 2 === 0;
        const backgroundColor = lightRow ? dark_gray : '';
        const checked = selectedGroup === user.cognito_sub;

        groupList.push((
          <View key={user.cognito_sub} style={[styles.checkboxRow, { backgroundColor } ]}>
            <CheckBox
              fieldId={user.cognito_sub}
              label={getFriendlyUsername(user)}
              data={{ name: getFriendlyUsername(user) }}
              onChange={this._updateSelectedGroup}
              checked={checked}
              width={20}
            />
          </View>
        ));
      });
    } else {
      groupChildrenForAssignment.forEach((group, index) => {

        const lightRow = index % 2 === 0;
        const backgroundColor = lightRow ? dark_gray : '';
        const checked = selectedGroup === group.group_id;

        groupList.push((
          <View key={group.group_id} style={[styles.checkboxRow, { backgroundColor } ]}>
            <CheckBox
              fieldId={group.group_id}
              label={group.group_name}
              data={{ name: group.group_name }}
              onChange={this._updateSelectedGroup}
              checked={checked}
              width={20}
            />
          </View>
        ));
      });
    }

    return groupList;
  }

  /**
   * Render Component
   */
  render() {
    const {
      detailsTitleGroup,
      dirty,
      editMode,
      selectedManufacturerId,
      selectedManufacturerName,
      selectedDealerId,
      selectedDealerName,
      selectedOwnerName,
    } = this.state;

    const {
      assignDeviceFailed,
      isAssigningDevice,
      isFetchingGroupChildrenForAssignment,
    } = this.props;

    return (
      <View style={[styles.main]}>
        {/* <Text style={[styles.deviceId]}>
          { device ? device.device_id : '' }
        </Text> */}
        <View style={[styles.content]}>
          {/* LEFT */}
          <View style={[styles.leftCol]}>
            {
              this._generateGroupEditDisplay(
                'Manufacturer',
                selectedManufacturerName,
                this._editManufacturer,
                [ADMIN],
                true,
                editMode !== EDIT_MODE.MANUFACTURER,
              )
            }
            {
              this._generateGroupEditDisplay(
                'Dealer',
                selectedDealerName,
                this._editDealer,
                [ADMIN, MANUFACTURER],
                selectedManufacturerId,
                editMode !== EDIT_MODE.DEALER,
              )
            }
            {
              this._generateGroupEditDisplay(
                'Owner',
                selectedOwnerName,
                this._editOwner,
                [ADMIN, MANUFACTURER, DEALER],
                selectedDealerId,
                editMode !== EDIT_MODE.OWNER,
              )
            }
          </View>

          {/* RIGHT */}
          <View style={[styles.rightCol]}>
            {
              isFetchingGroupChildrenForAssignment ?
                <Spinner color={'red'} />
                :
                editMode !== EDIT_MODE.OFF &&
                  <View style={[styles.detailsContainer]}>
                    <View style={[styles.detailsTitleRow]}>
                      <Text style={[styles.detailsTitleText]}>
                        Select: { detailsTitleGroup }
                      </Text>
                    </View>

                    <View style={[styles.detailsContentRow]}>
                      <Text>
                        { this._generateGroupList() }
                      </Text>
                    </View>

                    <View style={[styles.detailsFooterRow]}>
                      <Button style={[styles.selectButton, global.dropShadow]} onPress={this._confirmNewGroup}>
                        <Text style={[styles.selectButtonText]}>
                          SELECT
                        </Text>
                      </Button>
                    </View>
                  </View>
            }
          </View>
        </View>

        <View style={[styles.actionButtonsContainer]}>
          {
            !isAssigningDevice && assignDeviceFailed &&
              <Text style={[styles.errorMessage]}>Failed to re-assign device.</Text>
          }
          {
            isAssigningDevice ?
              <Spinner color={'red'} />
              :
              dirty &&
                <Button
                  style={[global.confirmationButton, styles.actionButton, { backgroundColor: yellow}]}
                  onPress={this._reassignDevice}
                >
                  <Text style={[global.confirmationButtonText, { color: dark_gray }]}>
                    SAVE CHANGES
                  </Text>
                </Button>
          }
          <Button
            style={[global.confirmationButton, styles.actionButton, { backgroundColor: light_gray}]}
            onPress={this._onCancel}
          >
            <Text style={[global.confirmationButtonText, { color: textLight }]}>
              CLOSE
            </Text>
          </Button>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isFetchingGroupChildrenForAssignment: state.admin.isFetchingGroupChildrenForAssignment,
    fetchGroupChildrenForAssignmentFailed: state.admin.fetchGroupChildrenForAssignmentFailed,
    fetchGroupChildrenForAssignmentError: state.admin.fetchGroupChildrenForAssignmentError,
    groupChildrenForAssignment: state.admin.groupChildrenForAssignment,

    isAssigningDevice: state.admin.isAssigningDevice,
    assignDeviceFailed: state.admin.assignDeviceFailed,
    assignDeviceError: state.admin.assignDeviceError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    assignDevice: (deviceId, level1ID, level2ID, level3ID) => dispatch(assignDevice(deviceId, level1ID, level2ID, level3ID)),
    clearGroupChildrenForAssignment: () => dispatch(clearGroupChildrenForAssignment()),
    getGroupChildrenForAssignment: (groupId, isDealerLevel) => dispatch(getGroupChildrenForAssignment(groupId, isDealerLevel)),
  };
};

ReassignDeviceModalContent.propTypes = {
  onCancel: PropTypes.func.isRequired,
  device: PropTypes.any.isRequired,
  group: PropTypes.any.isRequired,

  clearGroupChildrenForAssignment: PropTypes.func.isRequired,
  fetchGroupChildrenForAssignmentError: PropTypes.any.isRequired,
  fetchGroupChildrenForAssignmentFailed: PropTypes.bool.isRequired,
  getGroupChildrenForAssignment: PropTypes.func.isRequired,
  groupChildrenForAssignment: PropTypes.array.isRequired,
  isFetchingGroupChildrenForAssignment: PropTypes.bool.isRequired,

  assignDevice: PropTypes.func.isRequired,
  isAssigningDevice: PropTypes.bool.isRequired,
  assignDeviceFailed: PropTypes.bool.isRequired,
  assignDeviceError: PropTypes.any.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(ReassignDeviceModalContent);
