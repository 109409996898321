import { StyleSheet } from 'react-native';
import {
  yellow,
  robotoMonoBold,
  robotoCondensed,
} from '_style/Global.style';

export const styles = StyleSheet.create({
  input: {
    backgroundColor: '#0F0F0E',
    borderRadius: 2,
    height: 28,
    fontFamily: robotoMonoBold,
    color: yellow,
    textAlign: 'center',
    width: "60%",
  },
  inputLabel: {
    fontFamily: robotoCondensed,
    fontSize: 18,
    width: "40%",
  },
  item: {
    width: "60%",
    marginLeft: 5,
  },
  inputGroup: {
    marginTop: 1,
  },
  inputRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 8,
  }
});
