// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

import { Image, TouchableOpacity } from 'react-native';
import { Text, View } from 'native-base';

import { styles } from '_components/jobs/JobTimer.style';

/**
 * JobTimer Component
 */
class JobTimer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      on: false,
      time: props.elapsedTime || 0,
      start: 0,
    };

    this.timerInterval = null;

    this._startTimer = this._startTimer.bind(this);
    this._stopTimer = this._stopTimer.bind(this);
    this._calculateTime = this._calculateTime.bind(this);
  }

  /**
   * Start the timer.
   */
  _startTimer() {
    // Initialize the state with the correct starting time.
    this.setState({
      on: true,
      time: this.state.time,
      start: Date.now() - this.state.time,
    });

    // Every second, update the state with the new elapsed time.
    this.timerInterval = setInterval(() => {
      const { start } = this.state;
      const elapsedTime = Date.now() - start;

      this.setState({
        time: elapsedTime,
      });

      // If there is a registered function for onTimeElapsed,
      // call it with the new total time elapsed.
      if (this.props.onTimeElapsed) {
        this.props.onTimeElapsed(elapsedTime);
      }

    }, 1000); // 1 second
  }

  /**
   * Stop the timer
   */
  _stopTimer() {
    clearInterval(this.timerInterval);
    this.timerInterval = null;

    this.setState({
      on: false,
    });
  }

  /**
   * Returns an object with the elapsed hours,
   * minutes, and seconds from the current time
   * in the state.
   */
  _calculateTime() {
    const { time } = this.state;

    const addZero = (x) => x < 10 ? `0${x}` : x;

    const secondsElapsed = Math.floor(time / 1000);
    const minutesElapsed = Math.floor(time / (1000 * 60));
    const hoursElapsed = Math.floor(time / (1000 * 60 * 60));

    const seconds = addZero(secondsElapsed % 60);
    const minutes = addZero(minutesElapsed % 60);
    const hours   = addZero(hoursElapsed % 60);

    return { hours, minutes, seconds };
  }

  render() {

    const { on } = this.state;
    const time = this._calculateTime();

    return (
      <View style={[styles.mainContainer]}>
        <View style={[styles.timerContainer]}>
          <Text style={[styles.timerText]}>
            {time.hours}:{time.minutes}:{time.seconds}
          </Text>
        </View>
        <View style={[styles.buttonContainer]}>
          {
            !on ?
              <TouchableOpacity onPress={this._startTimer}>
                <Image source={require('_assets/images/start-job-button.png')} style={[styles.button]} />
              </TouchableOpacity>
              :
              <TouchableOpacity onPress={this._stopTimer}>
                <Image source={require('_assets/images/stop-job-button.png')} style={[styles.button]} />
              </TouchableOpacity>
          }
        </View>
      </View>
    );
  }
}

JobTimer.propTypes = {
  elapsedTime: PropTypes.number,
  onTimeElapsed: PropTypes.func,
};

export default connect(null, null)(JobTimer);
