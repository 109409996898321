import React, { Component } from "react";
import { navigate } from "_routes/RootNavigation";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { styles as global } from "_style/Global.style";
import { styles } from "./CreateSite.style";
import { styles2 } from "_pages/Site.style";
// UI Framework
import { Button, Spinner, Text, View, Input } from "native-base";
import {
  FlatList,
  StyleSheet,
  TouchableOpacity,
  Platform,
  Image,
} from "react-native";
import { Row, Col, Grid } from "react-native-easy-grid";
import FormInput from "_components/common/FormInputOn.component";
import {
  formatPhoneNumber,
  validateFormData,
  REQUIRED_CONSTRAINT,
} from "_util/form-util";

import validation from "validate.js";
import { ScrollView } from "react-native";
// Style

// Form Validation
const constraints = {
  //site_name: REQUIRED_CONSTRAINT("Site name"),
  //note: REQUIRED_CONSTRAINT("Note"),
};

export class CreateSite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      site_name: "",
      address: "",
      phone_number: "",
      email: "",
      manager: "",
      note: this.props.siteNotes,
      placeCenter: "",
      errorMessage: "",
      errorMessages: {},
    };
    this._handleFormInput = this._handleFormInput.bind(this);
  }
  componentWillUnmount = () => {
    document.querySelectorAll(".pac-container").forEach((element) => {
      element.remove();
    });
  };

  //input handler
  _handleFormInput = (fieldId, value) => {
    const { errorMessages } = this.state;

    // Always set the value of the form field to whatever the
    // user typed.
    this.setState({
      [fieldId]: value,
    });

    // Validate the form field
    const validationResult = validation.validate({ [fieldId]: value });

    // Either set an error message or clear it for the form field.
    if (validationResult) {
      this.setState({
        errorMessages: {
          ...errorMessages,
          [fieldId]: validationResult[fieldId][0],
        },
      });
    } else {
      this.setState({
        errorMessages: {
          ...errorMessages,
          [fieldId]: null,
        },
      });
    }
  };

  render() {
    const { errorMessage, errorMessages } = this.state;
    const { stateValue = this.state } = this.props;
    return (
      <View>
        <ScrollView
          contentContainerStyle={[
            styles.SiteCreateModalContent,
            { zIndex: -1 },
          ]}
        >
          <Grid>
            <Row style={{ marginTop: 2, marginBottom: 12 }}>
              <Col size={7} style={{ marginTop: -8, paddingLeft: 8 }}>
                <FormInput
                  fieldId={"note"}
                  maxLength={1500}
                  onInput={(e) => this.setState({ note: e })}
                  inputValue={this.state.note}
                  onChange={this._handleFormInput}
                  placeholder={""}
                  defaultValue={this.props.siteNotes}
                  value={this.props.siteNotes}
                  itemStyles={[styles.item]}
                  inputStyles={[styles.input, { height: 450 }]}
                  multiline3={true}
                  multiline={true}
                />
              </Col>
            </Row>
          </Grid>
          <Button
            disabled={this.props.disabled}
            style={[
              styles.confirmationButton,
              {
                marginBottom: 10,
                height: 40,
                borderRadius: 5,
                marginTop: "6%",
                marginBottom: "5%",
              },
            ]}
            onPress={() => {
              this.props.ConfirmModal(this.state);
            }}
          >
            <Text style={[styles.confirmationButtonText]}>SAVE NOTES</Text>
          </Button>
        </ScrollView>
      </View>
    );
  }
}

CreateSite.PropTypes = {
  ConfirmModal: PropTypes.func.isRequired,
  stateValue: PropTypes.any,
  disabled: PropTypes.any,
};
export default connect(null, null)(CreateSite);
//export default new CreateSite();
