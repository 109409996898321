// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// UI Framework
import {
  Button,
  Card,
  CardItem,
  Text,
} from 'native-base';

// Styles
import { styles as global } from '_style/Global.style';
import { styles } from '_components/device_card/ButtonGroup.style';

const defaultFontSize = 14;

class ButtonGroup extends Component {

  constructor(props) {
    super(props);

    const { buttons } = this.props;

    // Initiate the button states.
    const buttonOn = {};
    for (let i = 0; i < buttons.length; i += 1) {
      const { name, on } = buttons[i];
      buttonOn[name] = on;
    }

    this.state = {
      buttonOn,
    };
  }

  /**
   * Event handler for when a button is clicked.
   *
   * @param {string} name label of the button
   * @param {function} onPressCallback function to call when the button is clicked.
   */
  _handleButtonClick(name, onPressCallback) {
    const { buttonOn } = this.state;

    // Set every button to off, then set the clicked button to on.
    Object.keys(buttonOn).forEach(v => buttonOn[v] = false);
    buttonOn[name] = true;

    this.setState({
      buttonOn,
    });

    // invoke the callback passed in from the parent component.
    onPressCallback();
  }

  /**
   * Handle the case where a parent component changes the
   * highlighted index. Have that highlighted index override
   * whatever was previously highlighted.
   *
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    const { buttons } = this.props;
    const { buttons: prevButtons } = prevProps;
    const { buttonOn } = this.state;

		const highlightIndex = buttons.findIndex(button => button.on === 1)
		const prevHighlightIndex = prevButtons.findIndex(button => button.on === 1)

    if (highlightIndex !== prevHighlightIndex) {
      // Set every button to off, then set the clicked button to on.
      Object.keys(buttonOn).forEach(v => buttonOn[v] = false);

      buttonOn[buttons[highlightIndex].name] = true;

      this.setState({ buttonOn });
    }
  }

  render() {
    const { buttons, fontSize, width } = this.props;
    const { buttonOn } = this.state;

    // Iterate over all the button objects that were passed in to construct the
    // group of buttons.
    const buttonComponents = [];
    for (let i = 0; i < buttons.length; i += 1) {
      const { name, onPressCallback } = buttons[i];

      buttonComponents.push((
        <Button
          key={i}
          // eslint-disable-next-line react/jsx-no-bind
          onPress={() => this._handleButtonClick(name, onPressCallback)}
          style={[styles.button, buttonOn[name] ? styles.buttonOn : styles.buttonOff]}
        >
          <Text style={[
            global.fontRobotoCondensedBold,
            styles.buttonText,
            buttonOn[name] ? styles.buttonTextOn : styles.buttonTextOff,
            { fontSize: fontSize || defaultFontSize }
          ]}>
            { name }
          </Text>
        </Button>
      ));
    }

    return (
      <Card style={ [global.card, styles.card, { width }] }>
        <CardItem style={ [styles.cardItem] }>
          { buttonComponents }
        </CardItem>
      </Card>
    );
  }
}

ButtonGroup.propTypes = {
  buttons: PropTypes.any.isRequired,
  fontSize: PropTypes.number,
  width: PropTypes.number.isRequired,
  highlightIndex: PropTypes.number,
};

export default connect(null, null)(ButtonGroup);
