const actionPrefix = 'app/crewMembers';

export const CLEAR_STATE = `${actionPrefix}/CLEAR_STATE`;
export const CLEAR_API_STATE =`${actionPrefix}/CLEAR_API_STATE`;

export const GET_CREW_MEMBERS_REQUEST = `${actionPrefix}/GET_CREW_MEMBERS_REQUEST`;
export const GET_CREW_MEMBERS_SUCCESS = `${actionPrefix}/GET_CREW_MEMBERS_SUCCESS`;
export const GET_CREW_MEMBERS_FAILURE = `${actionPrefix}/GET_CREW_MEMBERS_FAILURE`;

export const INVITE_CREW_MEMBER_REQUEST = `${actionPrefix}/INVITE_CREW_MEMBER_REQUEST`;
export const INVITE_CREW_MEMBER_SUCCESS = `${actionPrefix}/INVITE_CREW_MEMBER_SUCCESS`;
export const INVITE_CREW_MEMBER_FAILURE = `${actionPrefix}/INVITE_CREW_MEMBER_FAILURE`;

export const ADD_CREW_MEMBER_REQUEST = `${actionPrefix}/ADD_CREW_MEMBER_REQUEST`;
export const ADD_CREW_MEMBER_SUCCESS = `${actionPrefix}/ADD_CREW_MEMBER_SUCCESS`;
export const ADD_CREW_MEMBER_FAILURE = `${actionPrefix}/ADD_CREW_MEMBER_FAILURE`;

export const REMOVE_CREW_MEMBER_REQUEST = `${actionPrefix}/REMOVE_CREW_MEMBER_REQUEST`;
export const REMOVE_CREW_MEMBER_SUCCESS = `${actionPrefix}/REMOVE_CREW_MEMBER_SUCCESS`;
export const REMOVE_CREW_MEMBER_FAILURE = `${actionPrefix}/REMOVE_CREW_MEMBER_FAILURE`;

export const UPDATE_CREW_MEMBER_REQUEST = `${actionPrefix}/UPDATE_CREW_MEMBER_REQUEST`;
export const UPDATE_CREW_MEMBER_SUCCESS = `${actionPrefix}/UPDATE_CREW_MEMBER_SUCCESS`;
export const UPDATE_CREW_MEMBER_FAILURE = `${actionPrefix}/UPDATE_CREW_MEMBER_FAILURE`;

export const CHECK_CREW_MEMBER_ELIGIBILITY_REQUEST = `${actionPrefix}/CHECK_CREW_MEMBER_ELIGIBILITY_REQUEST`;
export const CHECK_CREW_MEMBER_ELIGIBILITY_SUCCESS = `${actionPrefix}/CHECK_CREW_MEMBER_ELIGIBILITY_SUCCESS`;
export const CHECK_CREW_MEMBER_ELIGIBILITY_FAILURE = `${actionPrefix}/CHECK_CREW_MEMBER_ELIGIBILITY_FAILURE`;

export const LEAVE_AND_ADD_IN_PROGRESS = `${actionPrefix}/LEAVE_AND_ADD_IN_PROGRESS`;
export const LEAVE_AND_ADD_COMPLETE = `${actionPrefix}/LEAVE_AND_ADD_COMPLETE`;

export const CHECK_AND_ADD_IN_PROGRESS = `${actionPrefix}/CHECK_AND_ADD_IN_PROGRESS`;
export const CHECK_AND_ADD_COMPLETE = `${actionPrefix}/CHECK_AND_ADD_COMPLETE`;

