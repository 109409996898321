import { StyleSheet } from "react-native";
import { green } from "_style/Global.style";

export const styles = StyleSheet.create({
  button: {
    marginRight: "10px",
  },
  toolBox: {
    backgroundColor: "#1A1A1A",
    minWidth: 210,
    maxWidth: 240,
    minHeight: 250,
    paddingHorizontal: 0,
    paddingVertical: 18,
  },
  tabPanel: {
    backgroundColor: "#1A1A1A",
    height: 30,
  },
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    padding: 25,
    backgroundColor: "#383838",
    overflow: "auto",
  },

  fullScreen: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  topContainer: {
    height: 64,
  },
  topSubContainer: {
    padding: 20,
    borderRadius: 5,
  },
  bottomContainer: {
    marginTop: 10,
    backgroundColor: "#1A1A1A",
  },
  mapContainer: {
    padding: 5,
    backgroundColor: "#1A1A1A",
  },
  tabContainStyle: {
    height: 31,
    borderRadius: 10,
    borderColor: "#EEB310",
    backgroundColor: "unset",
    paddingHorizontal: 10
  },
  subHeaders: {
    marginTop: 20,
    marginBottom: 10,
    textAlign: "center",
    color: "#EEB310",
  },
  Undopanel: {
    flex: 1,
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginTop: 28,
    marginBottom: 28,
  },
  mapTap: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    borderWidth: 3,
    borderColor: "#EEB310",
    paddingHorizontal: 10,
    height:400
  },
  activeMapPanel: {
    backgroundColor: "#EEB310",
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  groupTab: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderWidth: 3,
    borderColor: "#EEB310",
  },
  activeGroupPanel: {
    backgroundColor: "#EEB310",
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
});
