import { StyleSheet } from 'react-native';
import {
  darker_gray,
  light_gray,
  roboto,
  textLight,
  yellow,
} from '_style/Global.style';

export const styles = StyleSheet.create({
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    width: '100%',
    backgroundColor: '#383838',
    overflow: 'auto',
  },
  content: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#383838',
    paddingTop: 13,
    paddingBottom: 13,
    paddingLeft: 24,
    paddingRight: 24,
  },

  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    alignSelf: 'stretch',
    marginTop: 10,
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: 25,
    paddingRight: 25,
  },

  headerLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flex: 1,
    minWidth: 200,
  },
  headerRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    flex: 2,
    minWidth: 400,
  },

  devicesTitleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  
  accountsTitleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },

  mainRow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: 25,
    paddingRight: 25,
    marginTop: 10,
  },

  breadcrumbRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: 25,
    paddingRight: 25,
    marginTop: 10,
  },

  headerRightRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  card: {
    backgroundColor: darker_gray,
    borderRadius: 10,
  },

  companyTitle: {
    fontFamily: roboto,
    fontSize: 24,
    fontWeight: 'bold',
    color: textLight,
    letterSpacing: 1.6,
  },
  headerText: {
    fontFamily: roboto,
    fontSize: 14,
    fontWeight: 'bold',
    color: textLight,
    letterSpacing: 1.6,
  },
  hyperlink: {
    fontFamily: roboto,
    fontSize: 12,
    color: yellow,
    letterSpacing: 1.6,
    marginTop: 5,
    marginBottom: 10,
  },
  headerButton: {
    backgroundColor: light_gray,
    borderRadius: 5,
    height: 25,
    width: 185,
  },
  headerButtonText: {
    fontFamily: roboto,
    fontSize: 12,
    fontWeight: 'bold',
    color: textLight,
    letterSpacing: 1.6,
    paddingLeft: 0,
    paddingRight:0 ,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  separator: {
    width: '100%',
    borderBottomWidth: 2,
    borderBottomColor: '#757575',
    marginTop: 10,
    marginBottom: 10,
  },

  tableTitle: {
    fontFamily: roboto,
    fontSize: 32,
    fontWeight: 'bold',
    color: textLight,
    letterSpacing: 1.6,
    marginBottom: 20,
  },
  actionColContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    height: 100,
    backgroundColor: darker_gray,
    paddingRight: 20,
    marginTop: 20,
  }
});
