// React
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";

// UI Framework
import { Button, Text, View, Input } from "native-base";

// Style
import { styles as global } from "_style/Global.style";
import { styles } from "./Sharesite.style";
import { Image } from "react-native";

class ShareSiteModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      confirmationText,
      submitButtonText,
      copyConfirmText,
      inputValue,
      mailSubmit,
      textSubmit,
      submit,
    } = this.props;

    return (
      <View style={[styles.confirmRemovalModalContent]}>
        <View style={[styles.mailrow]}>
          <View style={[{ alignItems: "center" }]}>
            <Button
              style={[styles.buttonImg, styles.btnTop]}
              onPress={mailSubmit}
            >
              <Image
                source={require("_assets/images/email.png")}
                style={[styles.buttonImg]}
              />
            </Button>
            <Text style={[styles.btnText]}>Email</Text>
          </View>
          <View style={[{ alignItems: "center" }]}>
            <Button
              style={[styles.buttonImg, styles.btnTop]}
              onPress={textSubmit}
            >
              <Image
                source={require("_assets/images/text-messages.png")}
                style={[styles.buttonImg]}
              />
            </Button>
            <Text style={[styles.btnText]}>Text Message</Text>
          </View>
        </View>
        <Text style={[styles.confirmRemovalText]}>{confirmationText}</Text>
        <View style={[styles.InputRow, { marginBottom: 6 }]}>
          <Input
            style={[styles.input, { marginBottom: 3 }]}
            value={inputValue}
          />
          <Button
            style={[global.cancelButton, { marginTop: 0, height: 40 }]}
            onPress={this.props.copysubmit}
          >
            <Text style={[global.cancelButtonText]}>Copy</Text>
          </Button>
        </View>
        <View style={{ flex: 1, flexDirection: "column" }}>
          <Text style={[{ color: "#EEB310", textAlign: "center" }]}>
            {copyConfirmText}
          </Text>
        </View>
        <Button
          style={[
            global.confirmationButton,
            { marginBottom: 15, marginTop: 15 },
          ]}
          onPress={this.props.submit()}
        >
          <Text style={[global.confirmationButtonText]}>
            {submitButtonText}
          </Text>
        </Button>
      </View>
    );
  }
}

ShareSiteModal.propTypes = {
  submit: PropTypes.func,
  mailSubmit: PropTypes.func,
  textSubmit: PropTypes.func,
  copysubmit: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string,
  confirmationText: PropTypes.string,
  copyConfirmText: PropTypes.string,
  inputValue: PropTypes.string,
};

export default connect(null, null)(ShareSiteModal);
