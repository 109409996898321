import { StyleSheet } from 'react-native';
import { robotoCondensedBold } from '_style/Global.style';

export const styles = StyleSheet.create({
  onOffGroupContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  onOffButton: {
    width: 68,
    height: 28,
    marginHorizontal: 2,
    marginVertical: 5,
    flexDirection: 'column',
    alignItems: 'center',
  },
  onOffButtonText: {
    fontFamily: robotoCondensedBold,
    fontWeight: '500',
    letterSpacing: 1,
    fontSize: 14,
    paddingTop: 7,
  },
  onOffButtonOffText: {
    color: '#212121',
  },
  onOffButtonOnText: {
    color: '#FAFAFA',
    textDecorationLine: 'underline',
  },
});
