import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  mainView: {
    borderWidth: 1,
    borderStyle: "dashed",
    alignItems: "center",
    borderColor: "white",
    padding: 50,
  },
  topText: {
    textAlign: "center",
    fontFamily: "Roboto",
    marginTop: 16,
    fontSize: 14,
    color: "#EEB310",
  },
  validationText: {
    textAlign: "center",
    fontFamily: "Roboto",
    marginTop: 16,
    fontSize: 14,
    color: "#FF1818",
  },
  selectFileText: {
    textAlign: "center",
    color: "white",
    // width: 103,
    // height: 40,
  },
  msgText: {
    textAlign: "center",
    fontFamily: "Roboto",
    marginTop: 16,
    fontSize: 12,
    color: "white",
  },
  uploadBtn: {
    width: 209,
    borderRadius: 5,
    height: 32,
    //textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#2C2B2B",
    //color: "#D9D9D9",
    marginBottom: 4,
    marginTop: "3%",
  },
});
