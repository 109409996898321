import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: "#383838",
    flex: 1,
  },
  subContainer: {
    padding: 25,
    borderRadius: 10,
  },

  mainView: {
    flex: 1,
    justifyContent: "space-around",
    borderRadius: 12,
  },
  topViewLeft: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  bottomLeftDiv: {
    justifyContent: "center",
    borderBottomLeftRadius: 12,
  },
  bottomRightDiv: {
    marginTop: 0,
    justifyContent: "center",
    borderBottomRightRadius: 12,
  },
  main_b_radius: {
    borderRadius: 12,
  },
  mainCol: {
    padding: 40,
    borderTopLeftRadius: 12,
  },
  backBtnView: {
    marginTop: 4,
    borderRadius: 8,
  },
  contentRows: {
    justifyContent: "space-between",
  },
  button: {
    marginRight: "10px",
  },
  toolBox: {
    backgroundColor: "#1A1A1A",
    paddingHorizontal: 26,
    paddingVertical: 18,
  },
  mapCont: {
    backgroundColor: "#1A1A1A",
    paddingHorizontal: 26,
    paddingVertical: 18,
  },
  backButton: {
    width: 88.4,
    borderRadius: 5,
    height: 29,
    //fontSize: 10,
    //textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#424242",
    Color: "white",
  },
  lockButton: {
    width: 68,
    borderRadius: 5,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#424242",
    Color: "white",
  },

  backButtonCont: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  backButtonImg: {
    width: 15.5,
    height: 13,
    marginRight: 4,
  },
  shareButton: {
    width: 141,
    borderRadius: 5,
    height: 40,
    padding: 2,
    //fontSize: 10,
    //textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#424242",
    //color: "white",
  },
  shareButtonImg: {
    width: 21,
    height: 23,
    marginRight: 14,
  },
  shareButtonText: {
    textAlign: "center",
    color: "#FAFAFA",
    fontSize: 18,
    fontWeight: "bold",
  },
  backButtonText: {
    textAlign: "center",
    color: "#FAFAFA",
    fontSize: 14,
    fontWeight: "bold",
  },
  siteButton: {
    width: 130,
    borderRadius: 5,
    padding: "2",
    paddingLeft: 8,
    paddingRight: 8,
    height: 40,
    marginTop: "2.4%",
    marginRight: "1.2%",
    //textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#424242",
    // color: "white",
  },
  siteButtonText: {
    textAlign: "center",
    fontFamily: "Roboto",
    paddingLeft: 12,
    paddingRight: 12,
    color: "#FAFAFA",
    fontWeight: "700",
    fontSize: 18,
  },
  siteButtonTextInact: {
    textAlign: "center",
    fontFamily: "Roboto",
    color: "#615F5F",
    fontWeight: "700",
    fontSize: 18,
  },
  downDiv: {
    flex: 1,
    flexDirection: "row",
    paddingBottom: 40,
    marginTop: 0,
    backgroundColor: "#1A1A1A",
  },
  text: {
    fontSize: 28,
  },
  titleText: {
    fontSize: 18,
    color: "#EEB310",
  },
  conText: {
    fontSize: 14,
    color: "#FAFAFA",
  },
  topMargin: {
    marginTop: 30,
  },
  topMargin2: {
    marginTop: 15,
  },
});
