import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  sideBar: {
    backgroundColor: "#0F0F0E",
    paddingTop: 15,
    display: "flex",
    height: "100%",
    justifyContent: "space-between",
  },
  sideBarItem: {
    borderBottomWidth: 0,
    height: 48,
    marginTop: 10,
    marginBottom: 10,
    borderTopLeftRadius: 24,
    borderBottomLeftRadius: 24,
    opacity: 1,
  },
  sideBarItemActive: {
    backgroundColor: "#383838",
  },
  sideBarItemText: {
    color: "#FAFAFA",
    fontSize: 18,
  },
  sideBarItemTextActive: {
    color: "#00B8D9",
    fontSize: 18,
  },
  textContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",

    height: 40,
    marginLeft: 20,
  },
  icon: {
    width: 26,
    height: 24,
  },
  iconContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 10,
    width: 40,
    height: 40,
  },
  versionContainer: {
    padding: 10,
  },
});
