// import { StyleSheet, Text, TouchableOpacity, View, Image } from "react-native";
// import React from "react";
// import {Images} from '../../assets/'
// const IconButtons = (props) => {
//   const { imageName} = props
//   console.log("IconButtons",Images[imageName])
//   return (
//     <TouchableOpacity>
//       {/* <Text>SS</Text> */}
//       <Image
//         style={[styles.editIcon]}
//         //source={Images[imageName]}
//         source={require('../../assets/images/app-header-logo.png')}
//       />
//     </TouchableOpacity>
//   );
// };

// export default IconButtons;

// const styles = StyleSheet.create({});

import React, { Component } from "react";
import PropTypes from "prop-types";
// Redux
import { connect } from "react-redux";
import { Image, TouchableOpacity, Text } from "react-native";

import { styles } from "_components/common/IconButtons.style.js";
import { Images } from "../../assets/";
import { View } from "react-native-web";
/**
 * TRASH ICON
 *
 * Common trash icon button for each notification, allows the user
 * to delete the notification.
 */
class IconButtons extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { imageName, buttonWithText, label, onPress, style, disabled } = this.props;
    return buttonWithText ? (
      <TouchableOpacity onPress={onPress} style={style} disabled = {disabled}>
        <View style={[styles.buttonWrapper]}>
          <View style={[styles.textWrapper]}>
            <Text style={styles.buttonLabel}>{label}</Text>
          </View>
          <View style={styles.iconWrapper}>
            <Image style={[styles.iconStyleSmall]} source={Images[imageName]} />
          </View>
        </View>
      </TouchableOpacity>
    ) : (
      <TouchableOpacity onPress={onPress} style={style} disabled={disabled}>
        <Image style={[styles.iconStyle,style]} source={Images[imageName]} />
      </TouchableOpacity>
    );
  }
}

IconButtons.propTypes = {
  onClick: PropTypes.func.isRequired,
  imageName: PropTypes.any.isRequired,
  
};

export default connect(null, null)(IconButtons);
