// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { useIsDrawerOpen } from '@react-navigation/drawer';
import { Offline } from "react-detect-offline";

// Redux
import { connect } from 'react-redux';
import { clearState } from '_redux/rootActions';
import { getFriendlyUsername } from '_redux/user/utils';

import Modal from '_components/common/Modal.component';
import UserProfileModalContent from '_components/user/UserProfileModalContent.component';

// Amplify
import { Auth } from 'aws-amplify';

// React Native
import { Image } from 'react-native';

// Native Base Components
import {
  Button,
  Text,
  View,
} from 'native-base';

// Style
import { styles } from '_components/common/AppHeader.style';

class AppHeader extends Component {

  constructor(props) {
    super(props);

    this.state = {
      username: '',
      showUserProfileModal: false,
      userProfileData: {},
      showOfflineView: false
    };

    this._getUsername = this._getUsername.bind(this);
    this._signOut = this._signOut.bind(this);

    this._hideUserProfileModal = this._hideUserProfileModal.bind(this);
    this._showUserProfileModal = this._showUserProfileModal.bind(this);

    this._toggleDrawer = this._toggleDrawer.bind(this)
  }

  /**
   * Executed when the component mounts. Get the username from Auth
   * to show in the header.
   */
  async componentDidMount() {
    this._getUsername();
  }

  componentWillUnmount() {
    clearTimeout(this.offlineTimeout); // Clear timeout on unmount
  }


  /**
   * Gets the username out of the Auth and adds it to the state.
   */
  async _getUsername() {
    const userInfo = await Auth.currentUserInfo();
    const { attributes, username } = userInfo;

    let displayName = getFriendlyUsername({ ...userInfo.attributes, username });

    this.setState({
      username: displayName,
      userProfileData: {
        firstName: attributes.given_name,
        lastName: attributes.family_name,
        email: attributes.email,
        phoneNumber: attributes.phone_number,
      }
    });
  }

  async _showUserProfileModal() {
    this.setState({
      showUserProfileModal: true,
    });
  }
  _hideUserProfileModal() {
    this.setState({
      showUserProfileModal: false,
    });
    this._getUsername();
  }

  async _toggleDrawer() {
    this.props.toggleDrawer();
  }

  /**
   * Sign the user out and navigate back to the login screen.
   */
  _signOut() {
    const { clearState } = this.props;

    Auth.signOut({ global: true })
      .then(() => {
        clearState();
      });
  }

  render() {
    const {
      showUserProfileModal,
      userProfileData,
    } = this.state;

    const {
      jobName,
      disconnectError,
    } = this.props;


    // Check if both jobName and disconnectError are truthy
    if (jobName && disconnectError && !this.state.showOfflineView) {
      // Use setTimeout to delay the rendering of the view after 1 second
      setTimeout(() => {
        // Set a state to indicate that the view should be shown
        this.setState({ showOfflineView: true });
      }, 1000);
    } else if (!disconnectError && this.state.showOfflineView) {
      this.setState({ showOfflineView: false });
    }

    return (
      <View style={[styles.main]}>
        <View style={[styles.appHeader]}>
          <View style={styles.leftHeader}>
            <View style={styles.drawerMenuButton}>
              <Button transparent onPress={this._toggleDrawer}>
                <Image
                  resizeMode='contain'
                  style={{ height: 32, width: 32 }}
                  source={require('_assets/images/drawer-menu-button.png')}
                />
              </Button>
            </View>
            <Image
              resizeMode='contain'
              style={{ height: 36, width: 245 }}
              source={require('_assets/images/app-header-logo.png')}
            />
            <Offline>
              {this.state.showOfflineView && (
                <View style={{ flexDirection: 'row' }}>
                  <Image
                    resizeMode='contain'
                    style={{ height: 30, width: 30, marginLeft: 10 }} // Adjusted width to match the height of the image
                    source={require('_assets/images/outline-cloud-off-white-24dp.png')}
                  />
                  <Text style={[styles.usernameText]}> Offline</Text>
                </View>
              )}
            </Offline>
          </View>
          <View>
            <Text style={[styles.jobNameText]}>
              {jobName}
            </Text>
          </View>
          <View style={styles.rightHeader}>
            <Button transparent onPress={this._showUserProfileModal}>
              <Text style={[styles.usernameText]}>
                {this.state.username}
              </Text>
              <Image
                style={{ height: 32, width: 32 }}
                source={require('_assets/images/user-icon.png')}
              />
            </Button>
          </View>

        </View>
        {/* User Profile Modal */}
        <Modal
          isVisible={showUserProfileModal}
          title={'USER PROFILE SETTINGS'}
          content={!showUserProfileModal ? null :
            <UserProfileModalContent
              formData={userProfileData}
            />
          }
          onClose={this._hideUserProfileModal}
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    jobName: state.dashboard.lastViewedJobName,
    disconnectError: state.iot.disconnectError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearState: () => dispatch(clearState()),
  };
};

AppHeader.propTypes = {
  clearState: PropTypes.func.isRequired,
  // jobName: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);


