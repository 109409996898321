import { StyleSheet } from 'react-native';
import { roboto, textLight, red, yellow } from '_style/Global.style';

export const styles = StyleSheet.create({
  main: {
    width: 650,
    alignItems: 'center',
  },
  content: {
    width: 350,
  },
  deviceDetailsContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  deviceDetailsRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 5,
  },
  actionButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 50,
  },

  actionButton: {
    width: 425,
    marginTop: 10,
    marginBottom: 10,
  },

});
