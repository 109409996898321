import { StyleSheet } from 'react-native';
import { robotoMonoBold, yellow } from '_style/Global.style';

export const styles = StyleSheet.create({
  shadow: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3
  },
  ball: {
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    left: -75,
  },
  ballText: {
    fontFamily: robotoMonoBold,
    fontSize: 14,
    color: '#FAFAFA',
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderWidth: 2,
    paddingTop: 1,
    paddingBottom: 1,
    paddingLeft: 2,
    paddingRight: 10,
    borderColor: yellow,
  },
  container: {
    overflow: "hidden",
  },
  slider: {
    position: "absolute",
    bottom: 0
  },
  renderIndicator: {
    position: 'absolute',
    left: 44,
    // top: -15,
  },
});
