// React
import React, { Component } from "react";
import PropTypes from "prop-types";
import { styles2 } from "./SiteSuccessModal.style";
// Redux
import { connect } from "react-redux";
import { FlatList, StyleSheet, TouchableOpacity, Image } from "react-native";
// UI Framework
import { Button, Text, View } from "native-base";
// Style
import { styles as global } from "_style/Global.style";
import { styles } from "_components/common/ConfirmDeleteModalContent.style";
import { Images } from "../../assets/";

class SiteSuccessModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      onClose,
      title,
      subTitle,
      buttonText,
      imageName,
      titleColor,
      imageStyle,
    } = this.props;

    return (
      <>
        <View style={[styles2.SiteCongrtzModalContent]}>
          <View style={{ alignItems: "center", marginTop: 12 }}>
            <Image style={[imageStyle]} source={Images[imageName]} />
          </View>
          <Text style={[styles2.titleText, { color: `${titleColor}` }]}>
            {title}
          </Text>
          <Text style={[styles2.subTitle]}>
            {subTitle}
            <br />
          </Text>
        </View>

        <Button style={[global.cancelButton, styles2.btn]} onPress={onClose}>
          <Text style={[global.cancelButtonText, styles2.btnText]}>
            {buttonText}
          </Text>
        </Button>
      </>
    );
  }
}

SiteSuccessModal.propTypes = {
  imageStyle: PropTypes.any,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  titleColor: PropTypes.any,
  imageName: PropTypes.any,
};

export default connect(null, null)(SiteSuccessModal);
