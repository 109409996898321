// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Offline } from "react-detect-offline";

// Routing
import { navigate } from '_routes/RootNavigation';

// Redux
import { connect } from 'react-redux';
import { getDashboardDevices, clearState } from '_redux/dashboard/actions';
import { connectDevice, disconnectDevice, setDisconnectErrorViewToTrue, clearState as clearIoTState } from '_redux/iot/actions';
import { getUserParentId, getUserCognitoSub, getUserRoleId } from '_redux/user/selectors';

// Components
// import { OWNER, CREW_LEADER, CREW_MEMBER } from '_components/auth/AccessControl.component';
import DashboardFooter from '_components/device_card/DashboardFooter.component';
import DeviceCard from '_components/device_card/DeviceCard.component';
import MiniDeviceCard from '_components/device_card/MiniDeviceCard.component';
import CurrentHydroStat from '_components/device_card/CurrentHydroStat.component';
import MiniCurrentHydroStat from '_components/device_card/MiniCurrentHydroStat.component';

import Modal from '_components/common/Modal.component';


// UI Framework
import { Spinner, Button, Text } from 'native-base';
import { Col, Row, Grid } from 'react-native-easy-grid';

// React Native
import { Image } from 'react-native';

// Style
import { styles as global } from '_style/Global.style';
import { styles } from '_pages/Dashboard.style';

class Dashboard extends Component {

  /**
   * When the component mounts, query the backend for the dashboard
   * devices.
   */
  constructor(props) {
    super(props);

    this.state = {
      connectedDevices: false,
      showLoadJobDashboardModal: false,
      showMini: false,
      showAllValves: false,
      showAllVitals: true,
      showOfflineView: false,
    };

    const {
      getDashboardDevices,
      lastViewedJobId,
      route,
      userCognitoSub,
    } = this.props;
    const { jobId, deviceIds } = route.params || {};

    // Entering the dashboard from the Account screen.
    if (deviceIds) {
      getDashboardDevices(null, deviceIds, userCognitoSub);
      // Entering the dashboard from the jobs page ("View Job Dashboard")
    } else if (jobId) {
      getDashboardDevices(jobId, null, userCognitoSub);
      // Entering the dashboard after the user has already viewed a job.
    } else if (lastViewedJobId) {
      getDashboardDevices(lastViewedJobId, null, userCognitoSub);
    } else {
      // // Instruct the user to load a dashboard from the Jobs page
      this.state = { ...this.state, ...{ showLoadJobDashboardModal: true } };
    }

    this._closeLoadJobDashboardModal = this._closeLoadJobDashboardModal.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick);
  }

  /**
   * When the component unmounts, disconnect the devices
   */
  async componentWillUnmount() {
    const {
      dashboardDevices = [],
      disconnectDevice,
    } = this.props;

    // Disconnect all devices.
    dashboardDevices.forEach((device) => {
      disconnectDevice(device.device_id);
    });
    this.props.clearState();
    this.props.clearIoTState();

    document.removeEventListener('click', this.handleClick);
  }

  /**
   *
   * @param {*} prevProps
   * @param {*} prevState
   */
  componentDidUpdate() {
    const { dashboardDevices: nextDevices } = this.props;
    const { connectedDevices } = this.state;

    if (nextDevices.length > 0 && !connectedDevices) {
      nextDevices.forEach((device) => {
        if (!device.is_suspended) {
          this.props.connectDevice(device.device_id);
        }
      });
      this.setState({
        connectedDevices: true,
      });
    }
  }

  /**
   *
   */
  //  _showLoadJobDashboardModal() {
  //   this.setState({
  //     showLoadJobDashboardModal: true,
  //   });
  // }

  _closeLoadJobDashboardModal() {
    this.setState({
      showLoadJobDashboardModal: false,
    });
    navigate('Jobs');
  }

  // Callback from Dashboard Footer to actvate Mini Cards
  handleCallback = (childData) => {
    this.setState({ showMini: childData })
  }

  // Recieve callback from CurrentEngine to DeviceCard to Dashbaord
  // Called when we go to valves or vitals mode in local card
  handleHandleCallbackMakeValvesVitalsFalse = () => {
    this.setState({ showAllValves: false });
    this.setState({ showAllVitals: false });

  }

  // Callback from Dashboard Footer to show all valves
  handleDashboardCallbackValvesAll = () => {
    this.setState({ showaAllVitals: false })
    this.setState({ showAllValves: true })
  }

  // Callback from Dashboard Footer to show all vitals
  handleDashboardCallbackVitalsAll = () => {
    this.setState({ showAllValves: false })
    this.setState({ showAllVitals: true })
  }


  handleClick = (event) => {
    const { disconnectError, setDisconnectErrorViewToTrue } = this.props;

    if (disconnectError) {
      setDisconnectErrorViewToTrue();
    }
  }


  render() {

    const showMini = this.state.showMini;
    const showAllValves = this.state.showAllValves;
    const showAllVitals = this.state.showAllVitals;
    const {
      dashboardDevices,
      isGettingDashboardDevices,
      disconnectError,
      disconnectErrorView,
    } = this.props;
    const { showLoadJobDashboardModal } = this.state;

    const deviceCards = [];
    const deviceIds = [];

    let disconnectTimer;
    // Check if disconnectError is truthy
    if (disconnectError && !this.state.showOfflineView) {
      // Use setTimeout to delay the rendering of the view after 1 second
      disconnectTimer = setTimeout(() => {
        // Set a state to indicate that the view should be shown
        //change back to 15 seconds after testing.
        this.setState({ showOfflineView: true });
      }, 15000);
    } else if (!disconnectError && this.state.showOfflineView) {
      this.setState({ showOfflineView: false });
      clearTimeout(disconnectTimer);
    }

    /*

       // fixes bug where vertical slider moves browser
       function preventDefault(e){
         e.preventDefault();
       }
       document.body.addEventListener('touchmove', preventDefault, { passive: false });
       // end of bug fix
       */
    // document.body.style.overscrollBehavior = 'none';
    //these three lines fixed the issue.
    //fixes scroll up bounce effect ios issue.
    document.body.parentElement.style.position = 'fixed';
    document.body.style.position = 'fixed';
    //fixes refresh when scrolling down on ios issue.
    document.body.parentElement.style.overflow = 'hidden';

    if (showMini === true) {
      for (let i = 0; i < dashboardDevices.length; i += 1) {
        const device = dashboardDevices[i];
        deviceIds.push(device['device_id']);

        const { card_settings = {} } = device;
        const hydrostatPosRight = card_settings.hydrostatpos === "RIGHT";

        const currentHydroStat = !device['is_suspended']
          ? <MiniCurrentHydroStat deviceId={device['device_id']} deviceName={device['device_name']} />
          : null;

        deviceCards.push((
          <React.Fragment key={device['device_id']}>
            {!hydrostatPosRight ? currentHydroStat : null}
            <MiniDeviceCard deviceId={device['device_id']} deviceName={device['device_name']} disabled={device['is_suspended'] ? true : false} />
            {hydrostatPosRight ? currentHydroStat : null}
          </React.Fragment>
        ));
      }
    }

    if (showMini === false) {
      for (let i = 0; i < dashboardDevices.length; i += 1) {
        const device = dashboardDevices[i];
        deviceIds.push(device['device_id']);

        const { card_settings = {} } = device;
        const hydrostatPosRight = card_settings.hydrostatpos === "RIGHT";

        const currentHydroStat = !device['is_suspended']
          ? <CurrentHydroStat deviceId={device['device_id']} deviceName={device['device_name']} />
          : null;

        deviceCards.push((
          <React.Fragment key={device['device_id']}>
            {!hydrostatPosRight ? currentHydroStat : null}
            <DeviceCard handleCallbackMakeValvesVitalsFalse={this.handleHandleCallbackMakeValvesVitalsFalse} showAllValves={showAllValves} showAllVitals={showAllVitals} deviceId={device['device_id']} deviceName={device['device_name']} disabled={device['is_suspended'] ? true : false} />
            {hydrostatPosRight ? currentHydroStat : null}
          </React.Fragment>
        ));
      }
    }

    return (
      <Grid>
        {isGettingDashboardDevices ?
          <Row style={[styles.row, styles.dashboard]} size={90}>
            <Col style={styles.loading}>
              <Spinner color="red" />
            </Col>
          </Row>
          :
          <Row style={[styles.row, styles.dashboard]} size={90}>
            {deviceCards}
          </Row>
        }
        <Row size={6}>
          <DashboardFooter parentCallback={this.handleCallback} dashboardCallbackValvesAll={this.handleDashboardCallbackValvesAll} dashboardCallbackVitalsAll={this.handleDashboardCallbackVitalsAll} deviceIds={deviceIds} />
        </Row>
        {showLoadJobDashboardModal &&
          <Modal
            isVisible={showLoadJobDashboardModal}
            title={'NO JOB LOADED'}
            subTitle={'Please load one of the available jobs into the dashboard by selecting the job name and clicking the View Job Dashboard Button.'}
            hideExitIcon={true}
            onClose={this._closeLoadJobDashboardModal}
            content={showLoadJobDashboardModal &&
              <Button style={[global.confirmationButton, { width: 500, marginBottom: 15 }]} onPress={this._closeLoadJobDashboardModal}>
                <Text style={[global.confirmationButtonText]}>
                  CLOSE
                </Text>
              </Button>}
          />
        }
        <Offline>
          {
            (this.state.showOfflineView || disconnectErrorView) &&
            <Modal
              isVisible={true}
              title={<React.Fragment>
                <Image
                  resizeMode='contain'
                  style={{ height: 100, width: 100, alignSelf: 'center', marginBottom: 10, marginTop: 0 }}
                  source={require('_assets/images/outline-cloud-off-white-24dp.png')}
                />
              </React.Fragment>
              }
              hideExitIcon={true}
              content={
                <React.Fragment>
                  <Text style={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center', marginBottom: 10 }}>Poor network connection detected.</Text>
                  <Text style={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center', marginBottom: 10 }}>Please check your connectivity.</Text>
                </React.Fragment>
              }
            />
          }
        </Offline>

      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dashboardDevices: state.dashboard.devices,
    isGettingDashboardDevices: state.dashboard.isGettingDashboardDevices,
    lastViewedJobId: state.dashboard.lastViewedJobId,
    userCognitoSub: getUserCognitoSub(state),
    userParentId: getUserParentId(state),
    userRoleId: getUserRoleId(state),
    disconnectError: state.iot.disconnectError,
    disconnectErrorView: state.iot.disconnectErrorView,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    connectDevice: (deviceId) => dispatch(connectDevice(deviceId)),
    disconnectDevice: (deviceId) => dispatch(disconnectDevice(deviceId)),

    getDashboardDevices: (jobId, deviceIds, cognitoSub) => dispatch(getDashboardDevices(jobId, deviceIds, cognitoSub)),
    clearState: () => dispatch(clearState()),
    clearIoTState: () => dispatch(clearIoTState()),
    setDisconnectErrorViewToTrue: () => dispatch(setDisconnectErrorViewToTrue()),
  };
};

Dashboard.propTypes = {
  clearState: PropTypes.func.isRequired,
  clearIoTState: PropTypes.func.isRequired,
  dashboardDevices: PropTypes.array.isRequired,

  lastViewedJobId: PropTypes.any,

  userCognitoSub: PropTypes.string,
  userParentId: PropTypes.string,
  userRoleId: PropTypes.string,

  route: PropTypes.any.isRequired,

  getDashboardDevices: PropTypes.func.isRequired,
  isGettingDashboardDevices: PropTypes.bool.isRequired,

  connectDevice: PropTypes.func.isRequired,
  disconnectDevice: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
