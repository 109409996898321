// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { inviteCrewMember, clearAPIState } from '_redux/crewMembers/actions';
import { showToast } from '_redux/toasts/actions';

// UI Framework
import { Button, Spinner, Text, View } from 'native-base';

import CheckBox from '_components/common/CheckBox.component';
import DropDown from '_components/common/DropDown.component';
import FormInput from '_components/common/FormInput.component';

import {
  formatPhoneNumber,
  validateFormData,
  EMAIL_CONSTRAINT,
  PHONE_NUMBER_CONSTRAINT,
  REQUIRED_CONSTRAINT,
} from '_util/form-util';
import validation from 'validate.js';

// Style
import { styles as global } from '_style/Global.style';
import { styles } from '_components/crew_members/AddCrewMemberModalContent.style';

// Form Validation
const constraints = {
  firstName: REQUIRED_CONSTRAINT('First name'),
  lastName: REQUIRED_CONSTRAINT('Last name'),
  phoneNumber: PHONE_NUMBER_CONSTRAINT,
  email: EMAIL_CONSTRAINT,
  roleType: REQUIRED_CONSTRAINT('Role type'),
};

/**
 * Renders the content for the Add and Edit screens within the Jobs
 * Modal.
 */
class AddCrewMemberModalContent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      formData: {},
      errorMessages: {},
      showSuccessScreen: false,
    },

    this._onCancel = this._onCancel.bind(this);

    this._handleInputChange = this._handleInputChange.bind(this);
    this._handleAddCrewMember = this._handleAddCrewMember.bind(this);

    this._renderAddCrewMemberScreen = this._renderAddCrewMemberScreen.bind(this);
    this._renderSuccessScreen = this._renderSuccessScreen.bind(this);
  }

  /**
   * Triggered when the user successfully fills out the Add Crew Member / Leader
   * form. Handles triggering the API call necessary to register the new
   * member.
   *
   * @param {object} formData add crew member / leader form data.
   */
  _handleAddCrewMember() {
    const { inviteCrewMember } = this.props;
    const { formData } = this.state;

    const errorMessages = validateFormData(formData, constraints);

    if (Object.keys(errorMessages).length === 0) {
      inviteCrewMember(formData);
    } else {
      this.setState({
        errorMessages,
      });
    }
  }

  /**
   * Triggered when the cancel button is clicked.
   */
  _onCancel() {
    this.props.clearAPIState();
    this.props.onCancel();
  }

  /**
   * Handle input changes on the form
   * @param {*} fieldId id of the form field, e.g. 'email'
   * @param {*} value value of the form field, e.g. 'abc@email.com'
   */
  _handleInputChange(fieldId, value) {
    const { errorMessages, formData } = this.state;

    if (fieldId === 'phoneNumber') {
      value = formatPhoneNumber(value);
    }

    // Validate the form field
    const validationResult = validation.validate(
      { [fieldId]: value },
      { [fieldId]: constraints[fieldId] }
    );
    // Either set an error message or clear it for the form field.
    if (validationResult) {
      this.setState({
        formData: {
          ...formData,
          [fieldId]: value,
        },
        errorMessages: {
          ...errorMessages,
          [fieldId]: validationResult[fieldId][0],
        }
      });
    } else {
      this.setState({
        formData: {
          ...formData,
          [fieldId]: value,
        },
        errorMessages: {
          ...errorMessages,
          [fieldId]: null,
        },
      });
    }
  }

  /**
   * Renders the screen after a successful invite.
   */
  _renderSuccessScreen() {
    const {
      firstName,
      lastName,
      phoneNumber,
      email,
      roleType,
    } = this.state.formData;

    return (
      <>
        <Text style={[styles.successTitle]}>
          INVITE SENT SUCCESSFULLY
        </Text>
        <Text style={[styles.description]}>
          The Following Crew Member should receive a link to create an account shortly.
        </Text>
        <View style={[styles.successInfoContainer]}>
          <View style={[styles.successRow]}>
            <View>
              <Text style={[styles.tableText]}>Name:</Text>
            </View>
            <View>
              <Text style={[styles.tableText]}>{ firstName } { lastName }</Text>
            </View>
          </View>
          <View style={[styles.successRow]}>
            <View>
              <Text style={[styles.tableText]}>Email Address:</Text>
            </View>
            <View>
              <Text style={[styles.tableText]}>{ email }</Text>
            </View>
          </View>
          <View style={[styles.successRow]}>
            <View>
              <Text style={[styles.tableText]}>Phone Number:</Text>
            </View>
            <View>
              <Text style={[styles.tableText]}>{ phoneNumber }</Text>
            </View>
          </View>
          <View style={[styles.successRow]}>
            <View>
              <Text style={[styles.tableText]}>Crew Role:</Text>
            </View>
            <View>
              <Text style={[styles.tableText]}>{ roleType }</Text>
            </View>
          </View>
        </View>
        <Button style={[global.cancelButton, styles.closeButton]} onPress={this._onCancel}>
          <Text style={[global.cancelButtonText, styles.closeButtonText]}>
            CLOSE
          </Text>
        </Button>
      </>
    );
  }

  /**
   * Renders the view where the user is filling out the form
   * to send the invite.
   */
  _renderAddCrewMemberScreen() {

    const {
      errorMessages,
    } = this.state;

    const {
      failedToInviteCrewMember,
      inviteCrewMemberErrorMessage,
      isInvitingCrewMember,
    } = this.props;

    return (
      <>
        <Text style={[styles.description]}>
          Fill out the information below for the person you would like to add to your crew.
        </Text>

        <View style={[styles.formInput]}>
          <FormInput
            fieldId={'firstName'}
            placeholder={'First Name'}
            onChange={this._handleInputChange}
            itemStyles={[global.item]}
            inputStyles={[global.input]}
            errorMessage={errorMessages['firstName'] || ''}
          />
        </View>

        <View style={[styles.formInput]}>
          <FormInput
            fieldId={'lastName'}
            placeholder={'Last Name'}
            onChange={this._handleInputChange}
            itemStyles={[global.item]}
            inputStyles={[global.input]}
            errorMessage={errorMessages['lastName'] || ''}
          />
        </View>

        <View style={[styles.formInput]}>
          <FormInput
            fieldId={'email'}
            placeholder={'Email Address'}
            onChange={this._handleInputChange}
            itemStyles={[global.item]}
            inputStyles={[global.input]}
            errorMessage={errorMessages['email'] || ''}
          />
        </View>

        <View style={[styles.formInput]}>
          <FormInput
            fieldId={'phoneNumber'}
            placeholder={'Phone Number'}
            onChange={this._handleInputChange}
            itemStyles={[global.item]}
            inputStyles={[global.input]}
            errorMessage={errorMessages['phoneNumber'] || ''}
          />
        </View>

        <View style={[styles.formInput]}>
          <View style={[styles.roleTypeContainer, { zIndex: 10 }]}>
            <Text style={[styles.labelText]}>
              Choose Role Type:
            </Text>
            <View style={[styles.dropDownContainer]}>
              <DropDown
                fieldId={'roleType'}
                onChange={this._handleInputChange}
                values={['Crew Member', 'Crew Leader']}
                errorMessage={errorMessages['roleType'] || ''}
              />
            </View>
          </View>

          <View>
            <Text style={[styles.labelText]}>
              Send Invite Link to Crew Member:
            </Text>
            <View style={[styles.checkboxContainer]}>
              <CheckBox
                fieldId={'emailInvite'}
                label={'Send Invite Link By Email'}
                onChange={this._handleInputChange}
              />
            </View>
            <View style={[styles.checkboxContainer]}>
              <CheckBox
                fieldId={'textInvite'}
                label={'Send Invite Link By Text'}
                onChange={this._handleInputChange}
              />
            </View>
          </View>
        </View>

        <View style={[styles.actionButtonContainer]}>
          {
            failedToInviteCrewMember &&
              <Text style={[styles.errorMessage]}>
                { inviteCrewMemberErrorMessage }
              </Text>
          }
          {
            isInvitingCrewMember ?
              <Spinner color={'red'}/>
              :
              <>
                <Button style={[global.confirmationButton, { marginBottom: 15 }]} onPress={this._handleAddCrewMember}>
                  <Text style={[global.confirmationButtonText]}>
                    ADD TO CREW
                  </Text>
                </Button>
                <Button style={[global.cancelButton]} onPress={this._onCancel}>
                  <Text style={[global.cancelButtonText]}>
                    CANCEL
                  </Text>
                </Button>
              </>
          }
        </View>
      </>
    );
  }

  /**
   * Check to see if the invite was a success, if so show the success screen.
   *
   * @param {*} prevProps previous this.props state
   */
  componentDidUpdate(prevProps) {
    const { isInvitingCrewMember, failedToInviteCrewMember } = this.props;
    const { isInvitingCrewMember: prevIsInvitingCrewMember } = prevProps;

    if (prevIsInvitingCrewMember && !isInvitingCrewMember && !failedToInviteCrewMember) {
      this.setState({
        showSuccessScreen: true,
      });
    }
  }

  render() {
    const {
      showSuccessScreen,
    } = this.state;

    return (
      <View style={[styles.addCrewMemberModalContent]}>
        { !showSuccessScreen ? this._renderAddCrewMemberScreen() : this._renderSuccessScreen() }
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    inviteCrewMemberErrorMessage: state.crewMembers.inviteCrewMemberErrorMessage,
    failedToInviteCrewMember: state.crewMembers.failedToInviteCrewMember,
    isInvitingCrewMember: state.crewMembers.isInvitingCrewMember,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    inviteCrewMember: (crewMemberInfo) => dispatch(inviteCrewMember(crewMemberInfo)),
    clearAPIState: () => dispatch(clearAPIState()),
    showToast: (message, isError, openDelay) => (dispatch(showToast(message, isError, openDelay))),
  };
};

AddCrewMemberModalContent.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,

  inviteCrewMember: PropTypes.func.isRequired,
  inviteCrewMemberErrorMessage: PropTypes.string.isRequired,
  clearAPIState: PropTypes.func.isRequired,
  failedToInviteCrewMember: PropTypes.bool.isRequired,
  isInvitingCrewMember: PropTypes.bool.isRequired,
  showToast: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCrewMemberModalContent);
