// Amplify
import { Auth, API  } from 'aws-amplify';
import { USERS_API_ID } from '_config/api-constants';
import amplifyConfig from '_config/_aws-exports.js';
import { makeActionCreator } from '_redux/rootActions';

import { getUserCognitoSub } from '_redux/user/utils';

import { updateDeviceName } from '_redux/iot/actions';
import { updateLastViewedJob, updateUserDeviceCardSettings } from '_redux/dashboard/actions';

// Types
import {
  CLEAR_STATE,
  CLEAR_REGISTER_STATE,

  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE,

  GET_USER_INFO_REQUEST,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_FAILURE,

  REGISTER_DEVICE_REQUEST,
  REGISTER_DEVICE_SUCCESS,
  REGISTER_DEVICE_FAILURE,

  UNREGISTER_DEVICE_REQUEST,
  UNREGISTER_DEVICE_SUCCESS,
  UNREGISTER_DEVICE_FAILURE,

  GET_USER_DEVICES_REQUEST,
  GET_USER_DEVICES_SUCCESS,
  GET_USER_DEVICES_FAILURE,

  GET_USER_NOTIFICATIONS_REQUEST,
  GET_USER_NOTIFICATIONS_SUCCESS,
  GET_USER_NOTIFICATIONS_FAILURE,

  DELETE_USER_NOTIFICATION_REQUEST,
  DELETE_USER_NOTIFICATION_SUCCESS,
  DELETE_USER_NOTIFICATION_FAILURE,

  CLEAR_USER_DEVICES,

  SAVE_USER_DEVICE_CARD_SETTINGS_REQUEST,
  SAVE_USER_DEVICE_CARD_SETTINGS_SUCCESS,
  SAVE_USER_DEVICE_CARD_SETTINGS_FAILURE,

  UPDATE_USER_PROFILE_REQUEST,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAILURE,

  GET_INVITE_REQUEST,
  GET_INVITE_SUCCESS,
  GET_INVITE_FAILURE,

} from '_redux/user/types';

const DEVICE_PREFIX = 'bf-device';

export const clearState = makeActionCreator(CLEAR_STATE);
export const clearUserDevices = makeActionCreator(CLEAR_USER_DEVICES);
export const clearRegisterState = makeActionCreator(CLEAR_REGISTER_STATE);

/**
 * Get metadata information about the user that is outside of what
 *
 * is stored in cognito. For example, the user's parent (e.g. MurCal,
 * Bazooka Farmstar).
 */
export const getUserInfo = () => {
  return async (dispatch) => {
    dispatch(getUserInfoRequest());

    try {
      // Refresh the user's session information in case group assignments
      // have changed or similar.
      await Auth.currentAuthenticatedUser({ bypassCache: true });

      // Cognito Info
      const cognitoInfo = await Auth.currentUserInfo();
      const cognitoSession = await Auth.currentSession();
      const cognitoGroups = cognitoSession.accessToken.payload['cognito:groups'];

      // DB Info
      const userInfo = await API.get(USERS_API_ID, `/users/${encodeURIComponent(cognitoInfo.attributes.sub)}`);

      // Store all of this information in the store so it's easily accessible.
      const user = {...cognitoInfo,  cognitoGroups, ...userInfo};

      dispatch(getUserInfoSuccess(user));
      dispatch(updateLastViewedJob({ job_id: user.last_viewed_job }));


    } catch(e) {
      console.error(e);
      dispatch(getUserInfoFailure());
    }
  };
};
export const getUserInfoRequest = makeActionCreator(GET_USER_INFO_REQUEST);
export const getUserInfoSuccess = makeActionCreator(GET_USER_INFO_SUCCESS, 'userInfo');
export const getUserInfoFailure = makeActionCreator(GET_USER_INFO_FAILURE);

/**
 * This is called when the user successfully signs in. The purpose is
 * to add the user's Identity ID as a custom attribute, this is to get
 * around a limitation of Cognito. This has no effect if the user already
 * has the required attribute associated with their user object.
 */
export const registerUser = () => {
  return async (dispatch) => {
    dispatch(registerUserRequest());

    try {
      const { id: cognitoId, attributes, username } = await Auth.currentUserInfo();
      const { sub } = attributes;

      // Only perform the post registration action if the user doesn't
      // already have a cognitoIdentityId associated with their user
      // attributes.
      if (!attributes['custom:cognitoIdentityId']) {
        // Construct and send the API request for the registration.
        const endpoint = `/users/${encodeURIComponent(sub)}/register`;
        const body = {
          username,
          userPoolId: amplifyConfig.Auth.userPoolId,
          cognitoId,
        };

        // Hit the REST API to perform the update
        await API.post(USERS_API_ID, endpoint, { body });

        // Success
        dispatch(registerUserSuccess());
        dispatch(getUserInfo());

      } else {
        // No need (Succes)
        dispatch(registerUserSuccess());
      }

    } catch (e) {
      // Failure
      console.error(e);
      dispatch(registerUserFailure());
    }
  };
};
export const registerUserRequest = makeActionCreator(REGISTER_USER_REQUEST);
export const registerUserSuccess = makeActionCreator(REGISTER_USER_SUCCESS);
export const registerUserFailure = makeActionCreator(REGISTER_USER_FAILURE);

/**
 * Get User Devices Action
 *
 * This will query the API for all the devices that a user
 * currently has access to.
 */
export const getUserDevices = (cognitoSub) => {
  return async (dispatch) => {
    dispatch(getUserDevicesRequest());

    try {
      if (!cognitoSub) {
        cognitoSub = await getUserCognitoSub();
      }

      // Hit the API
      const devices = await API.get(USERS_API_ID, `/users/${encodeURIComponent(cognitoSub)}/devices`);

      // Success
      dispatch(getUserDevicesSuccess(devices));

    } catch (e) {
      // Failure
      console.error(e);
      dispatch(registerUserFailure());
    }
  };
};
export const getUserDevicesRequest = makeActionCreator(GET_USER_DEVICES_REQUEST);
export const getUserDevicesSuccess = makeActionCreator(GET_USER_DEVICES_SUCCESS, 'devices');
export const getUserDevicesFailure = makeActionCreator(GET_USER_DEVICES_FAILURE);

/**
 * Attempts to register a device to a user given the device id provided.
 *
 * @param {string} deviceId device id
 */
export const registerDevice = (code, deviceLabel) => {
  return async (dispatch) => {

    const deviceId = `${DEVICE_PREFIX}-${code}`;

    dispatch(registerDeviceRequest(deviceId));

    try {
      const cognitoSub = await getUserCognitoSub();

      const endpoint = `/users/${encodeURIComponent(cognitoSub)}/devices/${encodeURIComponent(deviceId)}`;

      await API.post(USERS_API_ID, endpoint, {});

      dispatch(updateDeviceName(deviceId, deviceLabel));

      dispatch(registerDeviceSuccess(deviceId));
      dispatch(getUserDevices());

    } catch (e) {
      console.log(e);
      dispatch(registerDeviceFailure(deviceId, e.response.data));
    }
  };
};
export const registerDeviceRequest = makeActionCreator(REGISTER_DEVICE_REQUEST, 'deviceId');
export const registerDeviceSuccess = makeActionCreator(REGISTER_DEVICE_SUCCESS, 'deviceId');
export const registerDeviceFailure = makeActionCreator(REGISTER_DEVICE_FAILURE, 'deviceId', 'error');

/**
 * Attempts to register a device to a user given the device id provided.
 *
 * @param {string} deviceId device id
 */
export const unregisterDevice = (deviceId) => {
  return async (dispatch) => {

    dispatch(unregisterDeviceRequest(deviceId));

    try {
      const cognitoSub = await getUserCognitoSub();

      const endpoint = `/users/${encodeURIComponent(cognitoSub)}/devices/${encodeURIComponent(deviceId)}`;

    const result = await API.del(USERS_API_ID, endpoint, {});
      console.log(result);

      dispatch(unregisterDeviceSuccess(deviceId));
      dispatch(getUserDevices());

    } catch (e) {
      console.log(e);
      dispatch(unregisterDeviceFailure(deviceId));
    }
  };
};
export const unregisterDeviceRequest = makeActionCreator(UNREGISTER_DEVICE_REQUEST, 'deviceId');
export const unregisterDeviceSuccess = makeActionCreator(UNREGISTER_DEVICE_SUCCESS, 'deviceId');
export const unregisterDeviceFailure = makeActionCreator(UNREGISTER_DEVICE_FAILURE, 'deviceId');

/**
 * Save a user's device card settings.
 *
 * @param {*} deviceId
 * @param {*} settings
 */
export const saveUserDeviceCardSettings = (deviceId, settings) => {
  return async (dispatch) => {

    dispatch(saveUserDeviceCardSettingsRequest(deviceId));

    try {
      const cognitoSub = await getUserCognitoSub();

      const endpoint = `/users/${encodeURIComponent(cognitoSub)}/devices/${encodeURIComponent(deviceId)}/devicecardsettings`;

      await API.put(USERS_API_ID, endpoint, { body: settings });

      dispatch(saveUserDeviceCardSettingsSuccess(deviceId));
      dispatch(updateUserDeviceCardSettings(settings, deviceId));

    } catch (e) {
      console.log(e);
      dispatch(saveUserDeviceCardSettingsFailure(deviceId));
    }
  };
};
export const saveUserDeviceCardSettingsRequest = makeActionCreator(SAVE_USER_DEVICE_CARD_SETTINGS_REQUEST, 'deviceId');
export const saveUserDeviceCardSettingsSuccess = makeActionCreator(SAVE_USER_DEVICE_CARD_SETTINGS_SUCCESS, 'deviceId');
export const saveUserDeviceCardSettingsFailure = makeActionCreator(SAVE_USER_DEVICE_CARD_SETTINGS_FAILURE, 'deviceId');

/**
 * Get a user's notifications.
 */
export const getUserNotifications = () => {
  return async (dispatch) => {
    dispatch(getUserNotificationsRequest());

    try {
      const cognitoSub = await getUserCognitoSub();

      const endpoint = `/users/${encodeURIComponent(cognitoSub)}/notifications`;

      const notifications = await API.get(USERS_API_ID, endpoint);

      dispatch(getUserNotificationsSuccess(notifications));

    } catch(e) {
      console.error(e);
      dispatch(getUserNotificationsFailure());
    }
  };
};
export const getUserNotificationsRequest = makeActionCreator(GET_USER_NOTIFICATIONS_REQUEST);
export const getUserNotificationsSuccess = makeActionCreator(GET_USER_NOTIFICATIONS_SUCCESS, 'notifications');
export const getUserNotificationsFailure = makeActionCreator(GET_USER_NOTIFICATIONS_FAILURE);

/**
 * Delete a single user notification.
 *
 * @param {*} notificationId
 */
export const deleteUserNotification = (notificationId) => {
  return async (dispatch) => {
    dispatch(deleteUserNotificationRequest(notificationId));

    try {
      const cognitoSub = await getUserCognitoSub();

      const endpoint = `/users/${encodeURIComponent(cognitoSub)}/notifications/${encodeURIComponent(notificationId)}`;

      await API.del(USERS_API_ID, endpoint);

      dispatch(deleteUserNotificationSuccess(notificationId));

    } catch(e) {
      console.error(e);
      dispatch(deleteUserNotificationFailure());
    }
  };
};
export const deleteUserNotificationRequest = makeActionCreator(DELETE_USER_NOTIFICATION_REQUEST, 'notificationId');
export const deleteUserNotificationSuccess = makeActionCreator(DELETE_USER_NOTIFICATION_SUCCESS, 'notificationId');
export const deleteUserNotificationFailure = makeActionCreator(DELETE_USER_NOTIFICATION_FAILURE);

/**
 * Get metadata information about the user that is outside of what
 * is stored in cognito. For example, the user's parent (e.g. MurCal,
 * Bazooka Farmstar).
 */
export const updateUserProfile = (userProfile) => {
  return async (dispatch) => {
    dispatch(updateUserProfileRequest());

    try {
      const { username, attributes } = await Auth.currentUserInfo();
      const cognitoSub = attributes.sub;

      const userPoolId = amplifyConfig.Auth.userPoolId;

      userProfile.username = username;
      userProfile.userPoolId = userPoolId;

      await API.put(USERS_API_ID, `/users/${encodeURIComponent(cognitoSub)}`, { body: userProfile });

      dispatch(updateUserProfileSuccess());

    } catch(e) {
      console.error(e);
      dispatch(updateUserProfileFailure());
    }
  };
};
export const updateUserProfileRequest = makeActionCreator(UPDATE_USER_PROFILE_REQUEST);
export const updateUserProfileSuccess = makeActionCreator(UPDATE_USER_PROFILE_SUCCESS);
export const updateUserProfileFailure = makeActionCreator(UPDATE_USER_PROFILE_FAILURE);

/**
 * Get metadata information about the user that is outside of what
 * is stored in cognito. For example, the user's parent (e.g. MurCal,
 * Bazooka Farmstar).
 */
export const getInvite = (inviteCode) => {
  return async (dispatch) => {
    dispatch(getInviteRequest());

    try {
      const invite = await API.get(USERS_API_ID, `/invites/${encodeURIComponent(inviteCode)}`,);

      dispatch(getInviteSuccess(invite[0]));

    } catch(e) {
      console.error(e);
      dispatch(getInviteFailure());
    }
  };
};
export const getInviteRequest = makeActionCreator(GET_INVITE_REQUEST);
export const getInviteSuccess = makeActionCreator(GET_INVITE_SUCCESS, 'invite');
export const getInviteFailure = makeActionCreator(GET_INVITE_FAILURE);
