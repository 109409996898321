// React
import React from "react";
import PropTypes from 'prop-types';

// UI Framework
import {
  Button,
  Spinner,
  Text,
  View,
} from 'native-base';

// Style
import { styles as global } from '_style/Global.style';
import { styles } from '_components/accounts/BreadCrumbs.style';


const BreadCrumbs = (props) => {
  const { breadcrumbs, isUpdating, onCrumbPress, onBackPress } = props;
  const crumbs = breadcrumbs.map((item, i) => (
    <React.Fragment key={i}>
      <Button
        style={[styles.button]}
        onPress={() => onCrumbPress(item.id, i)}
      >
        <Text style={breadcrumbs.length !== i + 1 ? [styles.buttonText] : [styles.buttonTextHighlight]}>
          {item.name}
        </Text>
      </Button>
      {breadcrumbs.length !== i + 1
        ?
        <Text style={[styles.buttonText]}>
          {'≫'}
        </Text>
        :
        null}
    </React.Fragment>
  ));

  return (
    <View style={[styles.breadCrumbRow, styles.card, global.dropShadow]}>
      {
        isUpdating ?
          <Spinner color={'red'} style={[{ alignSelf: 'center' }]} />
          :
          <>
            <Button style={[styles.buttonBack]} onPress={() => onBackPress()}>
              <Text style={[styles.buttonBackText]}>
                {'BACK'}
              </Text>
            </Button>
            <Text style={styles.crumb}>
              {crumbs}
            </Text>
          </>
      }
    </View>
  );
}

BreadCrumbs.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  onCrumbPress: PropTypes.func.isRequired,
  onBackPress: PropTypes.func.isRequired,
}

export default BreadCrumbs;