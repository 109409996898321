const actionPrefix = 'app/jobs';

export const CLEAR_STATE = `${actionPrefix}/CLEAR_STATE`;

export const ADD_JOB_REQUEST = `${actionPrefix}/ADD_JOB_REQUEST`;
export const ADD_JOB_SUCCESS = `${actionPrefix}/ADD_JOB_SUCCESS`;
export const ADD_JOB_FAILURE = `${actionPrefix}/ADD_JOB_FAILURE`;

export const EDIT_JOB_REQUEST = `${actionPrefix}/EDIT_JOB_REQUEST`;
export const EDIT_JOB_SUCCESS = `${actionPrefix}/EDIT_JOB_SUCCESS`;
export const EDIT_JOB_FAILURE = `${actionPrefix}/EDIT_JOB_FAILURE`;

export const GET_JOBS_REQUEST = `${actionPrefix}/GET_JOBS_REQUEST`;
export const GET_JOBS_SUCCESS = `${actionPrefix}/GET_JOBS_SUCCESS`;
export const GET_JOBS_FAILURE = `${actionPrefix}/GET_JOBS_FAILURE`;

export const GET_USER_JOBS_REQUEST = `${actionPrefix}/GET_USER_JOBS_REQUEST`;
export const GET_USER_JOBS_SUCCESS = `${actionPrefix}/GET_USER_JOBS_SUCCESS`;
export const GET_USER_JOBS_FAILURE = `${actionPrefix}/GET_USER_JOBS_FAILURE`;

export const DELETE_JOB_REQUEST = `${actionPrefix}/DELETE_JOB_REQUEST`;
export const DELETE_JOB_SUCCESS = `${actionPrefix}/DELETE_JOB_SUCCESS`;
export const DELETE_JOB_FAILURE = `${actionPrefix}/DELETE_JOB_FAILURE`;

export const SET_DEFAULT_JOB_REQUEST = `${actionPrefix}/SET_DEFAULT_JOB_REQUEST`;
export const SET_DEFAULT_JOB_SUCCESS = `${actionPrefix}/SET_DEFAULT_JOB_SUCCESS`;
export const SET_DEFAULT_JOB_FAILURE = `${actionPrefix}/SET_DEFAULT_JOB_FAILURE`;

export const UPDATE_DEVICE_ORDER_REQUEST = `${actionPrefix}/UPDATE_DEVICE_ORDER_REQUEST`;
export const UPDATE_DEVICE_ORDER_SUCCESS = `${actionPrefix}/UPDATE_DEVICE_ORDER_SUCCESS`;
export const UPDATE_DEVICE_ORDER_FAILURE = `${actionPrefix}/UPDATE_DEVICE_ORDER_FAILURE`;

