// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { suspendDevice } from '_redux/admin/actions';

// Routing
import { navigate } from '_routes/RootNavigation';

// Util
import { getFriendlyUsername } from '_redux/user/utils';

import
  AccessControl,
  { ADMIN, MANUFACTURER, DEALER }
from '_components/auth/AccessControl.component';

// UI Framework
import {
  Button,
  Spinner,
  Text,
  View,
} from 'native-base';

// Style
import { styles as global, green, yellow, red, textLight } from '_style/Global.style';
import { styles } from '_components/accounts/ViewDeviceModalContent.style';
import { TouchableOpacity } from 'react-native';

class ViewDeviceModalContent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showReassignDeviceModal: false,
    };

    this._onCancel = this._onCancel.bind(this);

    this._loadDeviceIntoDashboard = this._loadDeviceIntoDashboard.bind(this);
    this._suspendDeviceAccess = this._suspendDeviceAccess.bind(this);

    this._showReassignDeviceModal = this._showReassignDeviceModal.bind(this);

    this._generateLink = this._generateLink.bind(this);
  }

  /**
   * Close this modal and navigate to the Dashboard, providing
   * this single device id to load.
   */
  _loadDeviceIntoDashboard() {
    this._onCancel();
    navigate('Dashboard', { deviceIds: [this.props.device.device_id] });
  }

  /**
   * Close this modal and open up the Reassign Device Modal
   */
  _showReassignDeviceModal() {
    this.props.onReassignDevice();
  }

  /**
   * Suspend access to this device.
   */
  _suspendDeviceAccess() {
    const {
      device,
      suspendDevice,
    } = this.props;

    suspendDevice(device.device_id, !device.is_suspended);
  }

  /**
   * Close the modal.
   */
  _onCancel() {
    this.props.onCancel();
  }

  /**
   * For the clickable nav links in the modal, generate the content for
   * it.
   *
   * @param {string} label
   * @param {string} id
   * @param {function} handler
   */
  _generateLink(label, id, handler) {
    const navigate = () => {
      handler(id, label);
    };
    const disabled = !id || id === 'none';
    return (
      <TouchableOpacity onPress={navigate} disabled={disabled}>
        <Text style={[{color: disabled ? textLight : yellow}]}>
          { label }
        </Text>
      </TouchableOpacity>
    );
  }

  /**
   * Render Component
   */
  render() {

    const {
      device,
      isSuspendingDevice,
    } = this.props;

    const {
      cognito_sub,
      created,
      device_id,
      device_name,
      first_name,
      last_name,
      level_1_id,
      level_1_name,
      level_2_id,
      level_2_name,
      username,
    } = device;

    let ownerName = 'None';
    if (cognito_sub) {
      ownerName = getFriendlyUsername({username: username || '', first_name, last_name});
    }

    return (
      <View style={[styles.main]}>
        <View style={[styles.content]}>
          {/* Device Information Table */}
          <View style={[styles.deviceDetailsContainer]}>
            {/* Manufacturer */}
            <AccessControl allowedGroups={[ADMIN, MANUFACTURER]}>
              <View style={[styles.deviceDetailsRow]}>
                <Text>
                  Manufacturer:
                </Text>
                { this._generateLink(level_1_name || 'None', level_1_id, this.props.onNavigateToGroup) }
              </View>
            </AccessControl>
            {/* Dealer */}
            <AccessControl allowedGroups={[ADMIN, MANUFACTURER, DEALER]}>
              <View style={[styles.deviceDetailsRow]}>
                <Text>
                  Dealer:
                </Text>
                { this._generateLink(level_2_name || 'None', level_2_id, this.props.onNavigateToGroup) }
              </View>
            </AccessControl>
            {/* Owner */}
            <AccessControl allowedGroups={[ADMIN, MANUFACTURER, DEALER]}>
              <View style={[styles.deviceDetailsRow]}>
                <Text>
                  Owner:
                </Text>
                { this._generateLink(ownerName, cognito_sub, this.props.onNavigateToOwner) }
              </View>
            </AccessControl>

            <View style={[styles.deviceDetailsRow]}>
              <Text>
                Device Serial #:
              </Text>
              <Text>
                { device_id }
              </Text>
            </View>
            <View style={[styles.deviceDetailsRow]}>
              <Text>
                Device Name:
              </Text>
              <Text>
                { device_name }
              </Text>
            </View>
            <View style={[styles.deviceDetailsRow]}>
              <Text>
                Created:
              </Text>
              <Text>
                { created }
              </Text>
            </View>
          </View>
          {/* Action Buttons */}
          <View style={[styles.actionButtonsContainer]}>
            <Button
              style={[global.confirmationButton, styles.actionButton, { backgroundColor: green}]}
              onPress={this._loadDeviceIntoDashboard}
            >
              <Text style={[global.confirmationButtonText, { color: textLight }]}>
                LOAD DEVICE INTO DASHBOARD
              </Text>
            </Button>
            {
              isSuspendingDevice ?
                <Spinner color={'red'} />
                :
                <Button
                  style={[global.confirmationButton, styles.actionButton, { backgroundColor: yellow}]}
                  onPress={this._suspendDeviceAccess}
                >
                  <Text style={[global.confirmationButtonText]}>
                    { device.is_suspended ? 'UNSUSPEND DEVICE ACCESS' : 'SUSPEND DEVICE ACCESS' }
                  </Text>
                </Button>
            }
            <Button
              style={[global.confirmationButton, styles.actionButton, { backgroundColor: red}]}
              onPress={this._showReassignDeviceModal}
            >
              <Text style={[global.confirmationButtonText, { color: textLight }]}>
                REASSIGN DEVICE
              </Text>
            </Button>
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isSuspendingDevice: state.admin.isSuspendingDevice,
    suspendDeviceFailed: state.admin.suspendDeviceFailed,
    suspendDeviceErrorMessage: state.admin.suspendDeviceErrorMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    suspendDevice: (deviceId, suspend) => dispatch(suspendDevice(deviceId, suspend)),
  };
};

ViewDeviceModalContent.propTypes = {
  onCancel: PropTypes.func.isRequired,
  device: PropTypes.any.isRequired,
  group: PropTypes.any.isRequired,
  onNavigateToGroup: PropTypes.func.isRequired,
  onNavigateToOwner: PropTypes.func.isRequired,
  onReassignDevice: PropTypes.func.isRequired,

  suspendDevice: PropTypes.func.isRequired,
  isSuspendingDevice: PropTypes.bool.isRequired,
  suspendDeviceFailed: PropTypes.bool.isRequired,
  suspendDeviceErrorMessage: PropTypes.any.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(ViewDeviceModalContent);
