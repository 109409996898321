import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1A1A1A',
    size: 6,
  },
  disconnectFooterContainer: {
    position: 'absolute', // Position absolutely within the parent container
    top: 0, // Align with the top edge of the parent
    left: 0, // Align with the left edge of the parent
    right: 0, // Align with the right edge of the parent
    bottom: 0, // Align with the bottom edge of the parent
    backgroundColor: '#212121', // Semi-transparent background color
    opacity: 0,
    zIndex: 999, // Ensure a higher z-index to overlay on top of other content
  },
  buttonRow: {
    alignItems: 'center',
    width: '100%',
  },
  button: {
    marginLeft: 10,
    marginRight: 10,
    width: 180,
    height: 48,
  },

  firstButtonGroup: {
    marginLeft: 40,
    marginRight: 10,
    width: 180,
    height: 48,
  },

  buttonText: {
    color: '#FAFAFA',
    fontSize: 24,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  stopAllButton: {
    backgroundColor: '#C82A32',
  },
  idleAllButton: {
    backgroundColor: '#EEB310',
  },
  runAllButton: {
    backgroundColor: '#006644',
  },

  maxAllButton: {
    backgroundColor: '#E7E6E6',
  },

  minAllButton: {
    backgroundColor: '#E7E6E6',
  },

  valvesAllButton: {
    backgroundColor: '#616161',
  },

  vitalsAllButton: {
    backgroundColor: '#616161',
  },

  maxMinbuttonText: {
    color: '#000000',
    fontSize: 24,
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  vitalButtonText: {
    color: '#E7E6E6',
    fontSize: 24,
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  valveButtonText: {
    color: '#E7E6E6',
    fontSize: 24,
    marginLeft: 'auto',
    marginRight: 'auto',
  },

});
