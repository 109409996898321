import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  leftRightGroup: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  rightButtonOff: {
    width: 99,
    height: 39,
  },
  leftButtonOn: {
    width: 88,
    height: 28,
    marginRight: 24,
  },
  rightButtonOn: {
    width: 88,
    height: 28,
  },
  leftButtonOff: {
    width: 99,
    height: 39,
    marginRight: 24,
  },
});
