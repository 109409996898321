import { StyleSheet } from 'react-native';
import { roboto } from '_style/Global.style';

export const styles = StyleSheet.create({
  main: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',

    borderRadius: 2,
    padding:15,
  },
  nameText: {
    fontFamily: roboto,
    fontSize: 18,
  },
  emailText: {
    fontFamily: roboto,
    fontSize: 12
  },
  crewMemberNameContainer: {
    flex: 1,
  },
  crewMemberActionsContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  crewMemberDropdownContainer: {
    flex: 2,
    paddingRight: 50,
  },
  crewMemberEditDeleteContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  }
});
