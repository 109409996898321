import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";

import { Image, TouchableOpacity } from "react-native";

import { styles } from "_components/common/EditButton.style";

/**
 * TRASH ICON
 *
 * Common trash icon button for each notification, allows the user
 * to delete the notification.
 */
class EditButton extends Component {
  constructor(props) {
    super(props);

    this._onClick = this._onClick.bind(this);
  }

  _onClick() {
    const { onClick, data } = this.props;

    onClick(data);
  }

  render() {
    return (
      <TouchableOpacity onPress={this._onClick} style={this.props.buttonStyle}>
        <Image
          style={[styles.editIcon]}
          source={require("_assets/images/edit-icon.png")}
        />
      </TouchableOpacity>
    );
  }
}

EditButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  data: PropTypes.any,
  buttonStyle: PropTypes.any,
};

export default connect(null, null)(EditButton);
