import { StyleSheet } from 'react-native';
import {
  yellow,
  robotoCondensed,
  robotoCondensedBold,
  robotoMonoBold,
  textLight,
} from '_style/Global.style';

export const styles = StyleSheet.create({
  hydroStatCard: {
    backgroundColor: '#212121',
    borderRadius: 5,
    height: 540,
    width: 160,
    marginLeft: 10,
    marginTop: 10,
  },
  hydroStatCardContent: {
    alignItems: 'flex-start',
    backgroundColor: '#212121',
    borderRadius: 5,
    height: '100%',
    paddingLeft: 6,
    paddingRight: 6,
    paddingTop: 6,
    paddingBottom: 6,
  },
  hydroStatCardTitle: {
    backgroundColor: yellow,
    height: 32,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderRadius: 0,
  },
  hydrostatCardTitleText: {
    fontSize: 12,
    paddingTop: 8,
    textTransform: 'uppercase',
    width: '100%',
  },
  hydrostatButtonAuto: {
    width: 208,
    height: 32,
  },
  hydrostatButtonManual: {
    width: 208,
    height: 32,
  },
  inletPercentageContainer: {
    marginTop: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 208,
    borderBottomWidth: 1,
    borderBottomColor: '#777777',
    paddingBottom: 10,
  },
  inletPercentageLabel: {
    paddingLeft: 10,
    fontFamily: robotoCondensed,
    fontSize: 18,
  },
  inletPercentageValue: {
    color: yellow,
    fontFamily: robotoMonoBold,
    fontSize: 21,
    paddingRight: 10,
  },
  sectionContainer: {
    width: 208,
    marginTop: 10,
    paddingBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#777777',

    flexDirection: 'column',
    alignItems: 'center',
  },
  sectionTitle: {
    fontFamily: robotoCondensed,
    fontSize: 18,
  },
  hydrostatModeContainer: {
    width: 208,
    height: 32,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  hydrostatModeText: {
    fontFamily: robotoCondensedBold,
    fontSize: 18,
    letterSpacing: 2,
    color: yellow,
  },
  inletOutletContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inletOutletColumn: {
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10,
    alignItems: 'center',
  },
  inletOutletTitle: {
    fontFamily: robotoCondensed,
    fontSize: 14,
  },
  inletOutletDetails: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  inletOutletValue: {
    color: yellow,
    fontFamily: robotoMonoBold,
    fontSize: 21,
  },
  inletOutletUnit: {
    fontFamily: robotoCondensed,
    fontSize: 18,
    marginTop: 2,
    marginLeft: 20,
  },
  noBorder: {
    borderBottomWidth: 0,
  },
  flowRateContainer: {
    marginTop: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 190,
    borderBottomWidth: 1,
    borderBottomColor: '#777777',
    paddingBottom: 10,
  },
  flowRateValueContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '30%',
  },
  flowRateLabel: {
    paddingLeft: 10,
    fontFamily: robotoCondensed,
    fontSize: 18,
  },
  flowRateUnits: {
    paddingLeft: 10,
    fontFamily: robotoCondensed,
    fontSize: 18,
  },
  flowRateValue: {
    color: yellow,
    fontFamily: robotoMonoBold,
    fontSize: 21,
  },
  totalFlowValue: {
    fontFamily: robotoMonoBold,
    color: yellow,
    fontSize: 24,
    fontWeight: '500',
  },
  totalFlowLabel: {
    fontFamily: robotoCondensed,
    fontSize: 18,
  },
  pitDepthContainer: {
    width: 208,
    height: 80,
    backgroundColor: '#0F0F0E',
    borderRadius: 2,
    flexDirection: 'column',
    alignItems: 'center',
  },
  pitDepthValue: {
    fontFamily: robotoMonoBold,
    color: yellow,
    fontSize: 24,
    fontWeight: '500',
  },
  pitDepthLabel: {
    fontFamily: robotoCondensed,
    fontSize: 18,
  },
  inletPsiButton: {
    width: 54,
    height: 48,
    marginHorizontal: 10,
    marginVertical: 5,
    flexDirection: 'column',
    alignItems: 'center',
  },
  inletPsiValue: {
    color: '#C424242',
    fontFamily: robotoMonoBold,
    fontSize: 21,
    height: 22,
  },
  inletPsiLabel: {
    fontFamily: robotoMonoBold,
    color: '#424242',
    fontSize: 14,
  },
  inletPsiContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,

  },
  inletPsiGroup: {
    flexDirection: 'column',
    alignItems: 'center'
  },
  sliderContainer: {
    alignItems: 'center',
    marginTop: 10,
    // justifyContent: 'space-around',
  },
  sliderTitle: {
    width: 90,
    fontFamily: robotoCondensed,
    fontWeight: 'bold',
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 30,
    color: textLight,
  },
  sliderThumb: {
    width: 48,
    height: 62,
  },
});
