// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { setSetPoints } from '_redux/iot/actions';
import { getSetPointsForDevice } from '_redux/iot/selectors';
import { getUserDeviceCardSettingsById } from '_redux/dashboard/selectors';
import {
  CONTROL_POINTS,
  convertKPAToPSI,
  convertPSIToKPA,
} from '_redux/iot/utils';

// Util
import { debounce } from '_util';


// Components
import TelemetryValue from '_components/device_card/TelemetryValue.component';

// UI Framework
import { Image, TouchableOpacity } from 'react-native';
import { Text, View } from 'native-base';
import { Grid, Col, Row } from 'react-native-easy-grid';


// Style
import { styles } from '_components/device_card/MiniPressureControl.style';

const DEFAULT_INLET_MIN = 0;
const DEFAULT_INLET_MAX = 50;
const DEFAULT_INLET_STEP = 1;

const DEFAULT_OUTLET_MIN = 0;
const DEFAULT_OUTLET_MAX = 300;
const DEFAULT_OUTLET_STEP = 5;

/**
 * This component represents the pressure controls on the right hand
 * side of the device card. It is visible when the card is in
 * pressure mode.
 *
 * c3 -> commanded pump outlet pressure.
 * c4 -> commanded pump inlet pressure.
 */
class MiniPressureControl extends Component {

  constructor(props) {
    super(props);

    const {
      [CONTROL_POINTS.COMMANDED_PUMP_OUTLET_PRESSURE]: commandedPumpOutletPressure = DEFAULT_OUTLET_MIN,
      [CONTROL_POINTS.COMMANDED_PUMP_INLET_PRESSURE]: commandedPumpInletPressure = DEFAULT_INLET_MIN,
    } = props.setPoints.C || {};

    this.state = {
      commandedPumpOutletPressure,
      commandedPumpInletPressure,
    };

    this._increaseInlet = this._increaseInlet.bind(this);
    this._decreaseInlet = this._decreaseInlet.bind(this);
    this._increaseOutlet = this._increaseOutlet.bind(this);
    this._decreaseOutlet = this._decreaseOutlet.bind(this);

    this._updateInlet = debounce(this._updateInlet.bind(this), 500);
    this._updateOutlet = debounce(this._updateOutlet.bind(this), 500);
  }

  /**
   * Event handler when the user clicks the INLET target UP arrow.
   */
  _increaseInlet() {
    const { deviceSettings } = this.props;
    const { commandedPumpInletPressure } = this.state;

    const {
      inletMax = DEFAULT_INLET_MAX,
      inletStep = DEFAULT_INLET_STEP,
    } = deviceSettings;

    const KPAStep = convertPSIToKPA(inletStep, false);
    const KPAMax = convertPSIToKPA(inletMax, false);

    console.log(KPAStep);

    this.setState({
      commandedPumpInletPressure: Math.min(KPAMax, commandedPumpInletPressure + KPAStep),
    });

    this._updateInlet();
  }

  /**
   * Event handler when the user clicks the INLET target DOWN arrow.
   */
  _decreaseInlet() {
    const { deviceSettings } = this.props;
    const { commandedPumpInletPressure } = this.state;

    const {
      inletMin = DEFAULT_INLET_MIN,
      inletStep = DEFAULT_INLET_STEP,
    } = deviceSettings;

    const KPAStep = convertPSIToKPA(inletStep, false);
    const KPAMin = convertPSIToKPA(inletMin, false);

    this.setState({
      commandedPumpInletPressure: Math.max(KPAMin, commandedPumpInletPressure - KPAStep),
    });

    this._updateInlet();
  }

  /**
   * Event handler when the user clicks the OUTLET target UP arrow.
   */
  _increaseOutlet() {
    const { deviceSettings } = this.props;
    const { commandedPumpOutletPressure } = this.state;

    const {
      ouletMax = DEFAULT_OUTLET_MAX,
      outletStep = DEFAULT_OUTLET_STEP,
    } = deviceSettings;

    const KPAStep = convertPSIToKPA(outletStep, false);
    const KPAMax = convertPSIToKPA(ouletMax, false);

    this.setState({
      commandedPumpOutletPressure: Math.min(KPAMax, commandedPumpOutletPressure + KPAStep),
    });

    this._updateOutlet();
  }


  /**
   * Event handler when the user clicks the OUTLET target DOWN arrow.
   */
  _decreaseOutlet() {
    const { deviceSettings } = this.props;
    const { commandedPumpOutletPressure } = this.state;

    const {
      outletMin = DEFAULT_OUTLET_MIN,
      outletStep = DEFAULT_OUTLET_STEP,
    } = deviceSettings;

    const KPAStep = convertPSIToKPA(outletStep, false);
    const KPAMin = convertPSIToKPA(outletMin, false);

    this.setState({
      commandedPumpOutletPressure: Math.max(KPAMin, commandedPumpOutletPressure - KPAStep),
    });

    this._updateOutlet();
  }

  /**
   * Debounced handler for updating the INLET control point.
   */
  _updateInlet() {
    const { commandedPumpInletPressure } = this.state;
    const { deviceId, setSetPoints } = this.props;

    setSetPoints(deviceId, {
      C: {
        [CONTROL_POINTS.COMMANDED_PUMP_INLET_PRESSURE]: Math.round(commandedPumpInletPressure),
      },
    });
  }

  /**
   * Debounced handler for updating the INLET control point.
   */
  _updateOutlet() {
    const { commandedPumpOutletPressure } = this.state;
    const { deviceId, setSetPoints } = this.props;

    setSetPoints(deviceId, {
      C: {
        [CONTROL_POINTS.COMMANDED_PUMP_OUTLET_PRESSURE]: Math.round(commandedPumpOutletPressure),
      },
    });
  }

  /**
   * Handler to make sure that if the initial get set points
   * request is slow and the component has already rendered that
   * we can catch when the set points do come back and update the UI
   * accordingly.
   *
   * Also catches the instance where another user on another instance
   * of the app has made an update to the set points.
   */
  componentDidUpdate(prevProps) {
    // Incoming props.
    const { setPoints } = this.props;
    const {
      [CONTROL_POINTS.COMMANDED_PUMP_OUTLET_PRESSURE]: commandedPumpOutletPressure,
      [CONTROL_POINTS.COMMANDED_PUMP_INLET_PRESSURE]: commandedPumpInletPressure,
    } = setPoints.C || {};

    // Previous props.
    const { setPoints: prevSetPoints } = prevProps;
    const {
      [CONTROL_POINTS.COMMANDED_PUMP_OUTLET_PRESSURE]: prevCommandedPumpOutletPressure,
      [CONTROL_POINTS.COMMANDED_PUMP_INLET_PRESSURE]: prevCommandedPumpInletPressure,
    } = prevSetPoints.C || {};

    // If the incoming props don't match the previous props,
    // update the state using the new props.
    if (commandedPumpOutletPressure !== prevCommandedPumpOutletPressure) {
      this.setState({
        commandedPumpOutletPressure,
      });
    }
    if (commandedPumpInletPressure !== prevCommandedPumpInletPressure) {
      this.setState({
        commandedPumpInletPressure,
      });
    }
  }

  render() {
    const {
      commandedPumpOutletPressure,
      commandedPumpInletPressure,
    } = this.state;

    return (
      <Row style={[styles.pressureControlContainer]}>

        {/* INLET TARGET */}
        <Col style={[styles.inletControlContainer]}>
          <TouchableOpacity onPress={this._increaseInlet}>
            <Image
              style={[styles.pressureArrowButton]}
              source={require('_assets/images/arrow-up-button.png')}
            />
          </TouchableOpacity>

          <View style={[styles.pressureTargetContainer]}>
            <Text style={[styles.pressureTargetLabel]}>
              INLET TARGET
            </Text>
            <TelemetryValue value={convertKPAToPSI(commandedPumpInletPressure)} fontSize={36}/>
            <Text style={[styles.pressureTargetUnit]}>
              PSI
            </Text>
          </View>

          <TouchableOpacity onPress={this._decreaseInlet}>
            <Image
              style={[styles.pressureArrowButton]}
              source={require('_assets/images/arrow-down-button.png')}
            />
          </TouchableOpacity>

        </Col>

            {/* OUTLET TARGET */}
            <Col style={[styles.outletControlContainer]}>
          <TouchableOpacity onPress={this._increaseOutlet}>
            <Image
              style={[styles.pressureArrowButton]}
              source={require('_assets/images/arrow-up-button.png')}
            />
          </TouchableOpacity>

          <View style={[styles.pressureTargetContainer]}>
            <Text style={[styles.pressureTargetLabel]}>
              OUTLET TARGET
            </Text>
            <TelemetryValue value={convertKPAToPSI(commandedPumpOutletPressure)} fontSize={36}/>
            <Text style={[styles.pressureTargetUnit]}>
              PSI
            </Text>
          </View>

          <TouchableOpacity onPress={this._decreaseOutlet}>
            <Image
              style={[styles.pressureArrowButton]}
              source={require('_assets/images/arrow-down-button.png')}
            />
          </TouchableOpacity>
        </Col>
        
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    deviceSettings: getUserDeviceCardSettingsById(state, ownProps.deviceId),
    setPoints: getSetPointsForDevice(state, ownProps.deviceId),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSetPoints: (deviceId, setPoints) => dispatch(setSetPoints(deviceId, setPoints)),
  };
};

MiniPressureControl.propTypes = {
  deviceId: PropTypes.any.isRequired,
  deviceSettings: PropTypes.any.isRequired,
  setPoints: PropTypes.any.isRequired,
  setSetPoints: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(MiniPressureControl);
