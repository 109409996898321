import { Auth } from 'aws-amplify';

export const INVITE_CODE_KEY = '@farmstar:inviteCode';

/**
 * Get the cognito sub for the current user.
 */
export const getUserCognitoSub = async () => {
  const userInfo = await Auth.currentUserInfo();

  return userInfo.attributes.sub;
};

/**
 * Generates a friendly username for the user based on the attrs
 * set for that user.
 *
 * @param {*} user
 */
export const getFriendlyUsername = (user) => {
  if (!user) {
    return '';
  }
  const {
    email,
    given_name,
    family_name,
    first_name,
    last_name,
    username,
  } = user;

  // First Name + Last Name
  if (first_name || last_name) {
    return `${first_name} ${last_name}`.trim();
  }
  if (given_name || family_name) {
    return `${given_name} ${family_name}`.trim();
  }

  // Username, don't show Facebook or Google usernames
  if (username && (username.toLowerCase().startsWith('facebook_') || username.toLowerCase().startsWith('google_'))) {
    return email;
  }

  // Username
  return username || '';
};
