import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  mainContent: {
    flexDirection: "row",
    borderRadius: 6,
    height: 68,
    justifyContent: "space-between",
    width: 276,
    backgroundColor: "#2C2B2B",
  },
  leftSideView: {
    width: 6,
    flexDirection: "row",
    backgroundColor: "#17966C",
  },
  middleView: {
    flex: 3,
    justifyContent: "center",
    alignItems: "center",
  },
  rightView: {
    flex: 8,
    justifyContent: "center",
  },
});
