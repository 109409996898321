import { StyleSheet } from 'react-native';
import {
  robotoCondensed,
  textLight,
} from '_style/Global.style';

export const styles = StyleSheet.create({
  leftColumn: {
    height: '100%',

    alignItems: 'center',
  },
  rightColumn: {
    height: 525,
  },
  powerButtonContainer: {
    width: 60,
    alignItems: 'center',
    marginTop: 10,
  },

  powerButton: {
    height: 60,
    width: 60,
  },

  powerIcon: {
    color: '#424242',
  },

  powerSpeedPressureContainer: {
    alignItems: 'center',

  },

  engineStateContainer: {
    justifyContent: 'center',
    alignItems: 'center',
   
    marginTop: 7,
  },

  engineSpeedLabel: {
    fontSize: 18,
    marginLeft: 15,
    marginTop: 1,
  },

  engineIcon: {
    height: 62,
    width: 75,
    marginLeft: 15,
    marginRight: 15,
  },
  warningErrorContainer: {
    alignItems: 'center',
    marginTop: 7,
  },

  inletOutletContainer: {
    marginTop: 6,
    alignItems: 'center',
  },
  inletContainer: {
    alignItems: 'center',
  },
  outletContainer: {
    alignItems: 'center',
  },
  inletOutletTitle: {
    fontSize: 14,
    fontFamily: robotoCondensed,
    color: textLight,
  },
  inletOutletUnit: {
    fontSize: 18,
    fontFamily: robotoCondensed,
    color: textLight,
    marginLeft: 15,
    marginTop: 2,
  },

  inletOutletValues: {
    alignItems: 'center',
  },
  inletOutletValue: {
    alignItems: 'center',
    marginRight: 20,
  },
  vitalsValvesContainer: {
    borderTopWidth: 1,
    borderColor: '#777777',
    marginTop: 5,
    paddingTop: 5,
    width: 208,
  },
});
