// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { getFriendlyUsername } from '_redux/user/utils';

// UI Framework
import { Button, Text, View } from 'native-base';
import InsetShadow from 'react-native-inset-shadow';

import CheckBox from '_components/common/CheckBox.component';
import FormInput from '_components/common/FormInput.component';

// Style
import { styles as global, textLight, yellow } from '_style/Global.style';
import { styles } from '_components/jobs/AddEditJobModalContent.style';

/**
 * Renders the content for the Add and Edit screens within the Jobs
 * Modal.
 */
class AddEditJobModalContent extends Component {

  constructor(props) {
    super(props);

    // If the parent passed in form data and that it should be in edit mode, set the
    // state to the passed in form data. Otherwise set the state to the default.
    if (props.editMode && props.formData) {
      const { jobName, selectedDevices, selectedCrewMembers, isDefault } = props.formData;
      this.state = {
        jobName,
        selectedDevices,
        selectedCrewMembers,
        isDefault,
        jobNameErrorMessage: '',
        jobSelectionsErrorMessage: '',
      };
    } else {
      this.state = {
        jobName: '',
        selectedDevices: {},
        selectedCrewMembers: {},
        isDefault: false,
        jobNameErrorMessage: '',
        jobSelectionsErrorMessage: '',
      };
    }

    this._validate = this._validate.bind(this);
    this._onConfirm = this._onConfirm.bind(this);
    this._onCancel = this._onCancel.bind(this);

    this._handleJobNameChange = this._handleJobNameChange.bind(this);
    this._handleDeviceSelect = this._handleDeviceSelect.bind(this);
    this._handleCrewMemberSelect = this._handleCrewMemberSelect.bind(this);
    this._handleDefaultSelect = this._handleDefaultSelect.bind(this);

    this._clearState = this._clearState.bind(this);
  }

  /**
   * Common method for cleaning up the state.
   */
  _clearState() {
    this.setState({
      jobName: '',
      selectedDevices: {},
      selectedCrewMembers: {},
      isDefault: false,
    });
  }

  /**
   * Validates the form, returns false if there is an error, true
   * otherwise.
   */
  _validate() {
    const { jobName, selectedCrewMembers, selectedDevices } = this.state;

    // Count the number of true items in both selectedCrewMembers and selectedDevices
    let crewMemberCount = 0;
    for (let [key, value] of Object.entries(selectedCrewMembers)) {
      crewMemberCount += value ? 1 : 0
    }

    let deviceCount = 0;
    for (let [key, value] of Object.entries(selectedDevices)) {
      deviceCount += value ? 1 : 0
    }

    // validate that there is at least 1 crew and 1 device selected
    if (!deviceCount || !crewMemberCount) {
      this.setState({
        jobSelectionsErrorMessage: 'Select at least 1 Device and 1 Crew Member'
      });
      return false;
    } else {
      this.setState({
        jobSelectionsErrorMessage: ''
      });
    }

    if (!jobName) {
      this.setState({
        jobNameErrorMessage: 'Job name is required.'
      });
      return false;
    } else {
      this.setState({
        jobNameErrorMessage: '',
      });
    }
    return true;
    
  }

  /**
   * Triggered when the confirmation button is clicked.
   */
  _onConfirm() {
    if (this._validate()) {
      this._clearState();
      this.props.onConfirm();
    }
  }

  /**
   * Triggered when the cancel button is clicked.
   */
  _onCancel() {
    this._clearState();
    this.props.onCancel();
  }

  /**
   * Triggered when the value of the Job Name field changes.
   *
   * @param {string} fieldId
   * @param {string} value
   */
  _handleJobNameChange(fieldId, value) {
    this.setState({
      jobName: value,
    });

    this.props.onChange('jobName', value);
  }

  /**
   * Triggered when one of the device checkboxes is selected
   * or unselected.
   *
   * @param {string} deviceId
   * @param {boolean} isSelected
   */
  _handleDeviceSelect(deviceId, isSelected) {
    const { selectedDevices } = this.state;

    console.log(deviceId, isSelected);
    selectedDevices[deviceId] = isSelected;

    this.setState({
      selectedDevices,
    });

    this.props.onChange('selectedDevices', selectedDevices);
  }

  /**
   * Triggered when on of the crew member checkboxes is selected
   * or unselected.
   *
   * @param {string} crewMemberId
   * @param {boolean} isSelected
   */
  _handleCrewMemberSelect(crewMemberId, isSelected) {
    const { selectedCrewMembers } = this.state;

    selectedCrewMembers[crewMemberId] = isSelected;

    this.setState({
      selectedCrewMembers,
    });

    this.props.onChange('selectedCrewMembers', selectedCrewMembers);
  }

  /**
   * Triggered when the is default checkbox is selected or
   * unselected.
   *
   * @param {string} fieldId
   * @param {boolean} isSelected
   */
  _handleDefaultSelect(fieldId, isSelected) {
    this.setState({
      isDefault: isSelected
    });

    this.props.onChange('isDefault', isSelected);
  }

  /**
   * Generate the majority of the modal content.
   *
   * @param {string} description this is the top level description
   * @param {Component} actionButtons these are the specific action buttons for edit or for add.
   */
  _generateAddEditJobModalContent(description, actionButtons) {
    const { devices, crewMembers} = this.props;
    const {
      jobName,
      selectedDevices,
      selectedCrewMembers,
      isDefault,
      jobNameErrorMessage,
      jobSelectionsErrorMessage,
    } = this.state;

    // Devices
    const deviceCheckboxes = [];
    devices.forEach((device, index) => {
      const { device_id, device_name } = device;

      deviceCheckboxes.push((
        <View key={index} style={[styles.checkbox]}>
          <CheckBox
            key={index}
            fieldId={device_id}
            label={device_name || device_id}
            onChange={this._handleDeviceSelect}
            checked={selectedDevices[device_id]}
          />
        </View>
      ));
    });

    // CrewMembers
    const crewMemberCheckboxes = [];
    crewMembers.forEach((member, index) => {
      const {
        cognito_sub,
        username,
        is_leader
      } = member;
      const labelColor = is_leader === 0 ? textLight : yellow;

      crewMemberCheckboxes.push((
        <View key={index} style={[styles.checkbox]}>
          <CheckBox
            key={index}
            fieldId={cognito_sub}
            label={getFriendlyUsername(member)}
            onChange={this._handleCrewMemberSelect}
            labelColor={labelColor}
            checked={selectedCrewMembers[cognito_sub]}
          />
        </View>
      ));
    });

    return (
      <View style={[styles.confirmRemovalModalContent]}>
        <Text style={[styles.addJobModalText]}>
          { description }
        </Text>
        <View style={{ marginBottom: 20 }}>
          <FormInput
            fieldId={'job_name'}
            placeholder={'Job Name'}
            onChange={this._handleJobNameChange}
            itemStyles={[global.item]}
            inputStyles={[global.input]}
            defaultValue={jobName || ''}
            errorMessage={jobNameErrorMessage}
          />
        </View>

        <View style={[styles.devicesCrewMembersContainer]}>
          <View style={[styles.devicesContainer]}>
            <Text style={[styles.jobDetailsSubTitle]}>
              Choose Devices
            </Text>
            <InsetShadow shadowRadius={5}>
              <View style={[styles.checkboxContainer]}>
                  { deviceCheckboxes }
              </View>
            </InsetShadow>
            <Text style={[styles.errorMessage]}>
              {jobSelectionsErrorMessage}
            </Text>
          </View>
          <View tyle={[styles.crewMembersContainer]}>
            <Text style={[styles.jobDetailsSubTitle]}>
              Choose Crew
            </Text>
            <InsetShadow shadowRadius={5}>
              <View style={[styles.checkboxContainer]}>
                { crewMemberCheckboxes }
              </View>
            </InsetShadow>
            <View>
              <Text style={[styles.crewLeaderNote]}>
                * A Name Highlighted Yellow Indicates a Crew Leader
              </Text>
            </View>
          </View>
        </View>
        <View style={[styles.defaultCheckboxContainer]}>
          <CheckBox
            fieldId={'default'}
            label={'Set this as the default job'}
            onChange={this._handleDefaultSelect}
            checked={isDefault || false}
          />
        </View>

        { actionButtons }

      </View>
    );
  }

  /**
   * Creates the add modal content.
   */
  _generateAddJobModalContent() {
    return this._generateAddEditJobModalContent(
      'Fill Out The Information Below To Set Up A New Job',
      (
        <>
          <Button style={[global.confirmationButton, { marginBottom: 15 }]} onPress={this._onConfirm}>
            <Text style={[global.confirmationButtonText]}>
              CREATE JOB
            </Text>
          </Button>
          <Button style={[global.cancelButton, { marginBottom: 15 }]} onPress={this._onCancel}>
            <Text style={[global.cancelButtonText]}>
              CANCEL
            </Text>
          </Button>
        </>
      ),
    );
  }

  /**
   * Creates the edit modal content.
   */
  _generateEditJobModalContent() {
    return this._generateAddEditJobModalContent(
      'Make The Changes You Would Like To This Job Below',
      (
        <>
          <Button style={[global.confirmationButton, { marginBottom: 15 }]} onPress={this._onConfirm }>
            <Text style={[global.confirmationButtonText]}>
              SAVE JOB SETTINGS
            </Text>
          </Button>
          <Button style={[global.cancelButton, { marginBottom: 15 }]} onPress={this._onCancel}>
            <Text style={[global.cancelButtonText]}>
              CANCEL
            </Text>
          </Button>
        </>
      )
    );
  }

  render() {
    const { editMode } = this.props;

    return (
      <>
        { editMode ? this._generateEditJobModalContent() : this._generateAddJobModalContent() }
      </>
    );
  }
}

AddEditJobModalContent.propTypes = {
  editMode: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,

  formData: PropTypes.any,
  devices: PropTypes.array.isRequired,
  crewMembers: PropTypes.array.isRequired,
};

export default connect(null, null)(AddEditJobModalContent);
