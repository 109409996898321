import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  iconStyle: {
    width: 26,
    height: 28,
    marginRight: 5,
  },
  iconStyleSmall: {
    width: 16,
    height: 18,
  },
  buttonWrapper: {
    backgroundColor: "#424242",
    borderRadius: 5,
    justifyContent: "center",
    height: 29,
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    width: "fit-content",
    marginTop: 5,
    marginBottom: 5,
    padding: 10,
  },
  buttonLabel: {
    color: "#FAFAFA",
    textTransform: "uppercase",
    alignItems: "center",
    fontSize: 12,
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },
  textWrapper: {
    width: "80%",
    marginRight: "10px",
    alignItems: "center",
  },
  iconWrapper: {
    width: "20%",
    alignItems: "center",
  },
});
