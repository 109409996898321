// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

import { Image, TouchableOpacity } from 'react-native';
import { View,Text} from 'native-base';

import { styles } from '_components/jobs/JobListItem.style';

/**
 * JobListItem Component
 */
class JobListItem extends Component {
  constructor(props) {
    super(props);

    this._onPress = this._onPress.bind(this);

  }

  _onPress() {
    const { onPress, job } = this.props;
    onPress(job);
  }

  render() {
    const { job, isSelected } = this.props;
    return (
      <TouchableOpacity onPress={this._onPress} style={[styles.jobListItemButton]}>
        <View style={styles.jobListItemRow}>
          <Text style={[isSelected ? styles.jobListItemTextSelected : styles.jobListItemTextUnselected]}>
            { job.job_name }
          </Text>
          { job.is_default ?
            <Image source={require('_assets/images/default-job-star.png')} style={styles.jobListItemRowMarker}/>
            :
            null
          }
        </View>
      </TouchableOpacity>
    );
  }
}

JobListItem.propTypes = {
  job: PropTypes.any.isRequired,
  onPress: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default connect(null, null)(JobListItem);
