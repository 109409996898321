const actionPrefix = 'app/user';

export const CLEAR_STATE = `${actionPrefix}/CLEAR_STATE`;
export const CLEAR_REGISTER_STATE = `${actionPrefix}/CLEAR_REGISTER_STATE`;

export const REGISTER_USER_REQUEST = `${actionPrefix}/REGISTER_USER_REQUEST`;
export const REGISTER_USER_SUCCESS = `${actionPrefix}/REGISTER_USER_SUCCESS`;
export const REGISTER_USER_FAILURE = `${actionPrefix}/REGISTER_USER_FAILURE`;

export const GET_USER_INFO_REQUEST = `${actionPrefix}/GET_USER_INFO_REQUEST`;
export const GET_USER_INFO_SUCCESS = `${actionPrefix}/GET_USER_INFO_SUCCESS`;
export const GET_USER_INFO_FAILURE = `${actionPrefix}/GET_USER_INFO_FAILURE`;

export const GET_USER_DEVICES_REQUEST = `${actionPrefix}/GET_USER_DEVICES_REQUEST`;
export const GET_USER_DEVICES_SUCCESS = `${actionPrefix}/GET_USER_DEVICES_SUCCESS`;
export const GET_USER_DEVICES_FAILURE = `${actionPrefix}/GET_USER_DEVICES_FAILURE`;

export const CLEAR_USER_DEVICES = `${actionPrefix}/CLEAR_USER_DEVICES}`;

export const GET_USER_NOTIFICATIONS_REQUEST = `${actionPrefix}/GET_USER_NOTIFICATIONS_REQUEST`;
export const GET_USER_NOTIFICATIONS_SUCCESS = `${actionPrefix}/GET_USER_NOTIFICATIONS_SUCCESS`;
export const GET_USER_NOTIFICATIONS_FAILURE = `${actionPrefix}/GET_USER_NOTIFICATIONS_FAILURE`;

export const DELETE_USER_NOTIFICATION_REQUEST = `${actionPrefix}/DELETE_USER_NOTIFICATION_REQUEST`;
export const DELETE_USER_NOTIFICATION_SUCCESS = `${actionPrefix}/DELETE_USER_NOTIFICATION_SUCCESS`;
export const DELETE_USER_NOTIFICATION_FAILURE = `${actionPrefix}/DELETE_USER_NOTIFICATION_FAILURE`;

export const SAVE_USER_DEVICE_CARD_SETTINGS_REQUEST = `${actionPrefix}/SAVE_USER_DEVICE_CARD_SETTINGS_REQUEST`;
export const SAVE_USER_DEVICE_CARD_SETTINGS_SUCCESS = `${actionPrefix}/SAVE_USER_DEVICE_CARD_SETTINGS_SUCCESS`;
export const SAVE_USER_DEVICE_CARD_SETTINGS_FAILURE = `${actionPrefix}/SAVE_USER_DEVICE_CARD_SETTINGS_FAILURE`;

export const REGISTER_DEVICE_REQUEST = `${actionPrefix}/REGISTER_DEVICE_REQUEST`;
export const REGISTER_DEVICE_SUCCESS = `${actionPrefix}/REGISTER_DEVICE_SUCCESS`;
export const REGISTER_DEVICE_FAILURE = `${actionPrefix}/REGISTER_DEVICE_FAILURE`;

export const UNREGISTER_DEVICE_REQUEST = `${actionPrefix}/UNREGISTER_DEVICE_REQUEST`;
export const UNREGISTER_DEVICE_SUCCESS = `${actionPrefix}/UNREGISTER_DEVICE_SUCCESS`;
export const UNREGISTER_DEVICE_FAILURE = `${actionPrefix}/UNREGISTER_DEVICE_FAILURE`;

export const UPDATE_USER_PROFILE_REQUEST = `${actionPrefix}/UPDATE_USER_PROFILE_REQUEST`;
export const UPDATE_USER_PROFILE_SUCCESS = `${actionPrefix}/UPDATE_USER_PROFILE_SUCCESS`;
export const UPDATE_USER_PROFILE_FAILURE = `${actionPrefix}/UPDATE_USER_PROFILE_FAILURE`;

export const GET_INVITE_REQUEST = `${actionPrefix}/GET_INVITE_REQUEST`;
export const GET_INVITE_SUCCESS = `${actionPrefix}/GET_INVITE_SUCCESS`;
export const GET_INVITE_FAILURE = `${actionPrefix}/GET_INVITE_FAILURE`;



