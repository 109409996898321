// React / Redux
import React, { Component }  from 'react';
import { Provider } from 'react-redux';
import { initStore }  from '_redux/store';

// Expo
import * as Font from 'expo-font';
import { Ionicons } from '@expo/vector-icons';

// Amplify
import Amplify, { Hub } from 'aws-amplify';
import amplifyConfig from '_config/_aws-exports.js';
import {
  AmplifyTheme,
  Authenticator,
} from '_components/auth/withAuthenticator';

// Components
import CustomSignIn from '_components/auth/CustomSignIn.component';
import CustomSignUp from '_components/auth/CustomSignUp.component';
import CustomConfirmSignUp from '_components/auth/CustomConfirmSignUp.component';
import CustomForgotPassword from '_components/auth/CustomForgotPassword.component';
import CustomVerifyContact from '_components/auth/CustomVerifyContact.component';
import MainNavigation from '_routes/MainNavigation';

import { Spinner } from 'native-base';

// Configure Amplify
Amplify.configure(amplifyConfig);
const AuthTheme = Object.assign({}, AmplifyTheme, {
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
    height: '100%',
  }
});

// Instantiate the Redux Store.
const store = initStore();

export default class App extends Component {
  /**
   * Constructor.
   *
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.state = {
      isLoadingFont: true,
    };

    this._fontLoaded = this._fontLoaded.bind(this);
    this._handleAuthStateChange = this._handleAuthStateChange.bind(this);
  }

  /**
   * Load native-base specific fonts.
   */
  async componentDidMount() {
    await Font.loadAsync({
      'Roboto': require('native-base/Fonts/Roboto.ttf'),
      'Roboto Condensed': require('_assets/fonts/RobotoCondensed-Regular.ttf'),
      'Roboto Condensed Bold': require('_assets/fonts/RobotoCondensed-Bold.ttf'),
      'Roboto_medium': require('native-base/Fonts/Roboto_medium.ttf'),
      'Roboto Mono': require('_assets/fonts/RobotoMono-Regular.ttf'),
      'Roboto Mono Bold': require('_assets/fonts/RobotoMono-Bold.ttf'),
      'Roboto Mono Regular': require('_assets/fonts/RobotoMono-Regular.ttf'),
      'SF Pro': require('_assets/fonts/SF-Pro-Display-Medium.otf'),
      ...Ionicons.font,
    });
    this._fontLoaded();
  }

  /**
   * Triggered when the fonts have been loaded and the application is
   * ready to render.
   */
  _fontLoaded () {
    this.setState({
      isLoadingFont: false,
    });
  }

  /**
   * Triggered when the auth state changes.
   */
  _handleAuthStateChange(state) {
    // no-op for now.
    // console.log(state);
    console.log('authState', state);
  }

  /**
   * Render the application.
   */
  render() {
    const { isLoadingFont } = this.state;

    if (isLoadingFont) {
      return (
        <Spinner color="red" />
      );
    }

    return (
      <Provider store={ store }>
        <Authenticator
          theme={ AuthTheme }
          hideDefault={ true }
          onStateChange={ this._handleAuthStateChange }
        >
          {/* Don't need this unless MFA is enabled. */}
          {/* <ConfirmSignIn /> */}

          {/* Custom Auth and Application */}
          <CustomSignIn override={'SignIn'} />
          <CustomSignUp override={'SignUp'} />
          <CustomConfirmSignUp override={'ConfirmSignUp'} />
          <CustomForgotPassword override={'ForgotPassword'} />
          <CustomVerifyContact override={'VerifyContact'} />
          <MainNavigation />

        </Authenticator>
      </Provider>
    );
  }
}
