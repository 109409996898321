// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import ButtonGroup from '_components/device_card/ButtonGroup.component';
import EngineState from '_components/device_card/EngineState.component';
import Indicator from '_components/device_card/Indicator.component';
import PressureControl from '_components/device_card/PressureControl.component';
import SpeedControl from '_components/device_card/SpeedControl.component';
import TelemetryValue from '_components/device_card/TelemetryValue.component';
import Vitals from '_components/device_card/Vitals.component';
import Valves from '_components/device_card/Valves.component';

// Redux
import { connect } from 'react-redux';
import { executeCommand } from '_redux/iot/actions';
import { getTelemetryForDevice, isWarningOnForDevice, isAlarmOnForDevice, isEngineRunningForDevice } from '_redux/iot/selectors';
import { SUPPORTED_COMMANDS } from '_redux/iot/utils';
import { TELEMETRY_DEFAULT_VALUE } from '_redux/iot/selectors';

// UI Framework
import { Text, View } from 'native-base';
import { Image, TouchableOpacity } from 'react-native';
import { Grid, Col, Row } from 'react-native-easy-grid';

// Styling
import { styles as global } from '_style/Global.style';
import { styles } from '_components/device_card/CurrentEngine.style';

class CurrentEngine extends Component {

  constructor(props) {
    super(props);

    this.state = {
      powerButtonOn: false,
      vitalsMode: true,
    };


    this._handlePowerOff = this._handlePowerOff.bind(this);
    this._handlePowerOn = this._handlePowerOn.bind(this);

    this._handlePressureModePress = this._handlePressureModePress.bind(this);
    this._handleSpeedModePress = this._handleSpeedModePress.bind(this);

    this._handleVitalsModePress = this._handleVitalsModePress.bind(this);
    this._handleValvesModePress = this._handleValvesModePress.bind(this);
  }

  /**
   *
   */
  _handlePowerOn() {
    const { deviceId, executeCommand } = this.props;

    executeCommand(deviceId, SUPPORTED_COMMANDS.START_ENGINE);

    this.setState({
      powerButtonOn: true,
    });
  }
    /**
   *
   */
  _handlePowerOff() {
    const { deviceId, executeCommand } = this.props;

    executeCommand(deviceId, SUPPORTED_COMMANDS.STOP_ENGINE);

    this.setState({
      powerButtonOn: false
    });
  }

  /**
   *
   */
  _handleSpeedModePress() {
    const { deviceId, executeCommand } = this.props;

    executeCommand(deviceId, SUPPORTED_COMMANDS.SET_THROTTLE_MODE, {
      data: {
        mode: 'speed',
      },
    });
  }

  /**
   *
   */
  _handlePressureModePress() {
    const { deviceId, executeCommand } = this.props;

    executeCommand(deviceId, SUPPORTED_COMMANDS.SET_THROTTLE_MODE, {
      data: {
        mode: 'pressure',
      },
    });
  }

  /**
   *
   */
  _handleVitalsModePress() {

    if ((this.props.showAllValves || this.props.showAllVitals) === true) {
      this.props.callbackMakeValvesVitalsFalse();
    }

    this.setState({
      vitalsMode: true,
    });
  }

  /**
   *
   */
  _handleValvesModePress() {

    if ((this.props.showAllValves || this.props.showAllVitals) === true) {
      this.props.callbackMakeValvesVitalsFalse();
    }

    this.setState({
      vitalsMode: false,
    });
  }

  // used in order to change the state with our prop
  static getDerivedStateFromProps(props, state) {
    if (props.showAllValves === true) {
      return {
        vitalsMode: false
      }
    }

    if(props.showAllVitals === true) {
      return {
        vitalsMode: true
      }
    }
    return null
  }

  render() {
    const {
      deviceId,
      telemetry,
      isAlarmOnForDevice,
      isEngineRunningForDevice,
      isWarningOnForDevice,
    } = this.props;

    const {
      vitalsMode,
    } = this.state;

    const {
      es, // default to stopped until we get our first reading.
      espeed,
      psp,
      pdp,
      hvs,
      mode,
    } = telemetry;

    const speedMode = mode === 0;
    const pressureMode = mode === 1;

    let engineIcon = require('_assets/images/engine-icon.png');
    if (isAlarmOnForDevice) {
      engineIcon = require('_assets/images/engine-icon-error.png');
    } else if (isWarningOnForDevice) {
      engineIcon = require('_assets/images/engine-icon-warn.png');
    }

    return (
      <Grid>
        {/* Left Column */}
        <Col size={75} style={[styles.leftColumn]}>
          {/* Power - Speed | Pressure */}
          <Row style={[styles.powerSpeedPressureContainer]}>
            <View style={[styles.powerButtonContainer]}>
              {
                isEngineRunningForDevice ?
                  // ENGINE IS RUNNING
                  <TouchableOpacity onPress={this._handlePowerOff}>
                    <Image
                      style={ styles.powerButton }
                      source={require('_assets/images/power-button-on.png')}
                    />
                  </TouchableOpacity>
                  :
                  // ENGINE IS STOPPED
                  <TouchableOpacity onPress={this._handlePowerOn}>
                    <Image
                      style={ styles.powerButton }
                      source={require('_assets/images/power-button-off.png')}
                    />
                  </TouchableOpacity>
              }
            </View>
            <View style={[styles.speedPressureContainer]}>
              <ButtonGroup
                width={148}
                buttons={[
                  { onPressCallback: this._handleSpeedModePress, name: 'SPEED', on: (mode ? 0 : 1)},
                  { onPressCallback: this._handlePressureModePress, name: 'PRESSURE', on: (mode ? 1 : 0)},
                ]}
              />
            </View>
          </Row>

          {/* Stabilize | RPM */}
          <Row style={[styles.engineStateContainer]}>
            <EngineState engineState={ es } />
            <TelemetryValue value={ espeed } />
            <Text style={[global.fontRobotoCondensed, global.textLight, styles.engineSpeedLabel]}>
              RPM
            </Text>
          </Row>

          {/* Warning | Error */}
          <Row style={[styles.warningErrorContainer]}>
            <Indicator
              isOn={ isWarningOnForDevice }
              onImage={require('_assets/images/warning-on.png')}
              offImage={require('_assets/images/warning-off.png')}
              flash={true}

            />
            <Image
              style={ [styles.engineIcon] }
              source={engineIcon}
            />
            <Indicator
              isOn={ isAlarmOnForDevice }
              onImage={require('_assets/images/error-on.png')}
              offImage={require('_assets/images/error-off.png')}
            />
          </Row>

          {/*  */}
          <Row style={[styles.inletOutletContainer]}>
            <Col style={[styles.inletContainer]}>
              <Row>
                <Text style={[styles.inletOutletTitle]}>
                  INLET
                </Text>
              </Row>
              <Row style={[styles.inletOutletValues]}>
                <TelemetryValue value={ psp } />
                <Text style={[styles.inletOutletUnit]}>
                  PSI
                </Text>
              </Row>
            </Col>
            <Col style={[styles.outletContainer]}>
              <Row>
                <Text style={[styles.inletOutletTitle]}>
                  OUTLET
                </Text>
              </Row>
              <Row style={[styles.inletOutletValues]}>
                <TelemetryValue value={ pdp } />
                <Text style={[styles.inletOutletUnit]}>
                  PSI
                </Text>
              </Row>
            </Col>
          </Row>

          {/* Vitals | Valves */}
          <Row style={[styles.vitalsValvesContainer]}>
            <ButtonGroup 
            width={208} 
            buttons={[
                { onPressCallback: this._handleVitalsModePress, name: 'VITALS', on: (vitalsMode ? 1 : 0)},
                { onPressCallback: this._handleValvesModePress, name: 'VALVES', on: (vitalsMode ? 0 : 1)},
            ]} />
          </Row>
          {
            vitalsMode ?
              <Vitals deviceId={deviceId} />
              :
              <Valves deviceId={deviceId} hvs={ hvs }/>
          }

        </Col>
        <Col size={25} style={[styles.rightColumn]}>
          {
            speedMode || !pressureMode ?
              <SpeedControl deviceId={ deviceId } />
              :
              <PressureControl deviceId={ deviceId } />
          }
        </Col>
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    telemetry: getTelemetryForDevice(state, ownProps.deviceId),
    isAlarmOnForDevice: isAlarmOnForDevice(state, ownProps.deviceId),
    isWarningOnForDevice: isWarningOnForDevice(state, ownProps.deviceId),
    isEngineRunningForDevice: isEngineRunningForDevice(state, ownProps.deviceId),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    executeCommand: (deviceId, command, data) => dispatch(executeCommand(deviceId, command, data)),
  };
};

CurrentEngine.propTypes = {
  deviceId: PropTypes.string.isRequired,
  executeCommand: PropTypes.func.isRequired,
  telemetry: PropTypes.any.isRequired,
  isAlarmOnForDevice: PropTypes.bool.isRequired,
  isWarningOnForDevice: PropTypes.bool.isRequired,
  isEngineRunningForDevice: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentEngine);
