import { StyleSheet } from 'react-native';

import { roboto, textLight, textDark, lighter_gray } from '_style/Global.style';

export const styles = StyleSheet.create({
  changePasswordModalContent: {
    width: 425,
    marginLeft: 75,
    marginRight: 75,
  },

  formInput: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 10,
    paddingBottom: 10,
  },

  actionButtonContainer:{
    padding: 15,
  },

});
