// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { getUserNotifications, deleteUserNotification } from '_redux/user/actions';

// Components
import Modal from '_components/common/Modal.component';

// UI Framework
import {
  Image,
  TouchableOpacity,
} from 'react-native';
import {
  Spinner,
  Text,
  View ,
} from 'native-base';

// Style
import { styles as global } from '_style/Global.style';
import { styles } from '_components/Notifications.style';

/**
 *  VIEW BUTTON
 *
 *  This is a common button for all notifications that will bring up
 *  a modal to display the details of the notification.
 */
class ViewButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };

    this._openNotification = this._openNotification.bind(this);
    this._closeNotification = this._closeNotification.bind(this);
  }

  /**
   * Open the modal
   */
  _openNotification() {
    this.setState({
      showModal: true,
    });
  }

  /**
   * Close the modal
   */
  _closeNotification() {
    this.setState({
      showModal: false,
    });
  }

  render() {
    const { showModal } = this.state;
    const { message, modalTitle, modalSubtitle } = this.props;

    const modalContent = (
      <Text>{message}</Text>
    );

    return (
      <View>
        {/* View Button */}
        <TouchableOpacity onPress={this._openNotification}>
          <Image style={[styles.viewButton]} source={require('_assets/images/view-button.png')} />
        </TouchableOpacity>

        <Modal
          isVisible={showModal}
          title={modalTitle}
          subTitle={modalSubtitle}
          message={message}
          content={modalContent}
          onClose={this._closeNotification}
        />

      </View>
    );
  }
}

ViewButton.propTypes = {
  message: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
  modalSubtitle: PropTypes.string.isRequired,
};

/**
 * TRASH ICON
 *
 * Common trash icon button for each notification, allows the user
 * to delete the notification.
 */
class TrashIcon extends Component {
  constructor(props) {
    super(props);

    this._deleteNotification = this._deleteNotification.bind(this);
  }

  /**
   * Delete the notification for the user.
   */
  _deleteNotification() {
    const { deleteUserNotification, notificationId } = this.props;

    deleteUserNotification(notificationId);
  }

  render() {
    const {
      deletingUserNotificationId,
      isDeletingNotification,
      notificationId,
    } = this.props;

    return (
      <>
      {
        isDeletingNotification && (notificationId === deletingUserNotificationId) ?
          <Spinner color={'red'} />
          :
          <TouchableOpacity onPress={this._deleteNotification}>
            <Image style={[styles.trashIcon]} source={require('_assets/images/trash-icon.png')} />
          </TouchableOpacity>
      }
      </>
    );
  }
}

TrashIcon.propTypes = {
  deleteUserNotification: PropTypes.func.isRequired,
  deletingUserNotificationId: PropTypes.any,
  isDeletingNotification: PropTypes.bool.isRequired,
  notificationId: PropTypes.any.isRequired,
};

/**
 * NOTIFICATIONS
 *
 * Top level component that organizes the notifications into a list.
 */
class Notifications extends Component {

  /**
   * Constructor.
   *
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this._handleGetUserNotifications = this._handleGetUserNotifications.bind(this);
  }

  /**
   * When the component mounts, fetch the user's notifications
   * from the backend.
   */
  async componentDidMount() {
    this._handleGetUserNotifications();
  }

  /**
   * Trigger the redux action to get the user's notifications.
   */
  _handleGetUserNotifications() {
    const { getUserNotifications } = this.props;

    getUserNotifications();
  }

  /**
   * Render Component.
   */
  render() {

    const {
      deleteUserNotification,
      deletingUserNotificationId,
      isDeletingNotification,
      isFetchingNotifications,
      notifications = [],
    } = this.props;

    const notificationRows = [];
    notifications.forEach((notification, index) => {

      const { notification_id, subject, type, message } = notification;
      // Every other row is a light background.
      const lightBackground = index % 2 !== 0;

      notificationRows.push((
        <View key={notification_id} style={[styles.notificationRow, lightBackground ? styles.lightRow : {}]}>
          {/* Subject / Type */}
          <View style={[styles.subjectTypeContainer]}>
            <Text style={[styles.subject]}>{subject}</Text>
            <Text style={[styles.type]}>{type}</Text>
          </View>

          <View style={[styles.actionsContainer]}>
            {/* View */}
            <View>
              <ViewButton modalTitle={subject} modalSubtitle={type} message={message} />
            </View>
            {/* Delete */}
            <View>
              <TrashIcon
                notificationId={notification_id}
                deleteUserNotification={deleteUserNotification}
                deletingUserNotificationId={deletingUserNotificationId}
                isDeletingNotification={isDeletingNotification}
              />
            </View>
          </View>
        </View>
      ));
    });

    return (
      <View style={[styles.notificationsContainer, global.dropShadow]}>
        <Text style={[styles.title]}>
          NOTIFICATIONS
        </Text>
        <View style={[styles.notificationRowsContainer]}>
          {
            isFetchingNotifications ?
              <Spinner color='red' />
              :
              notificationRows.length > 0 ?
                notificationRows
                :
                <Text style={[styles.noNotificationsText]}>No notifications</Text>
          }
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deletingUserNotificationId: state.user.deletingUserNotificationId,
    isDeletingNotification: state.user.isDeletingNotification,
    isFetchingNotifications: state.user.isFetchingNotifications,
    notifications: state.user.notifications,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserNotifications: () => dispatch(getUserNotifications()),
    deleteUserNotification: (notificationId) => dispatch(deleteUserNotification(notificationId)),
  };
};

Notifications.propTypes = {
  deletingUserNotificationId: PropTypes.any.isRequired,
  deleteUserNotification: PropTypes.func.isRequired,
  isDeletingNotification: PropTypes.bool.isRequired,
  getUserNotifications: PropTypes.func.isRequired,
  isFetchingNotifications: PropTypes.bool.isRequired,
  notifications: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);


