import { StyleSheet } from 'react-native';
import { roboto, card_red, green, textLight, yellow } from '_style/Global.style';

export const styles = StyleSheet.create({
  // Containers
  inviteModalContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 420,
    alignItems: 'center',
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  successContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  // Action Buttons
  actionButton: {
    width: 360,
    marginTop: 20,
  },
  closeButtonText: {
    color: textLight,
    fontSize: 18,
    fontFamily: roboto,
    fontWeight: 'bold',
    letterSpacing: 1.6,
  },

  // Icons
  errorIcon: {
    width: 112,
    height: 112,
  },
  warningIcon: {
    width: 112,
    height: 105,
  },
  successIcon: {
    width: 112,
    height: 112,
  },

  // Text
  errorDescriptionText: {
    color: textLight,
    fontSize: 18,
    fontFamily: roboto,
    letterSpacing: 1.6,
    textAlign: 'center',
    marginBottom: 20,
    width: 360
  },
  wrongText: {
    color: card_red,
    fontSize: 24,
    fontFamily: roboto,
    fontWeight: 'bold',
    letterSpacing: 1.6,
    padding: 25,
  },
  warningText: {
    color: yellow,
    fontSize: 24,
    fontFamily: roboto,
    fontWeight: 'bold',
    letterSpacing: 1.6,
    padding: 25,
  },
  successText: {
    color: green,
    fontSize: 28,
    fontFamily: roboto,
    fontWeight: 'bold',
    letterSpacing: 1.6,
    padding: 25,
  },
  successDescriptionText: {
    color: textLight,
    fontSize: 24,
    fontFamily: roboto,
    fontWeight: 'bold',
    letterSpacing: 1.6,
    textAlign: 'center',
    marginTop: 40,
    marginBottom: 40,
  },
});
