const actionPrefix = 'app/dashboard';

export const CLEAR_STATE = `${actionPrefix}/CLEAR_STATE`;

export const GET_DASHBOARD_DEVICES_REQUEST = `${actionPrefix}/GET_DASHBOARD_DEVICES_REQUEST`;
export const GET_DASHBOARD_DEVICES_SUCCESS = `${actionPrefix}/GET_DASHBOARD_DEVICES_SUCCESS`;
export const GET_DASHBOARD_DEVICES_FAILURE = `${actionPrefix}/GET_DASHBOARD_DEVICES_FAILURE`;

export const GET_USER_DEVICE_CARD_SETTINGS_REQUEST = `${actionPrefix}/GET_USER_DEVICE_CARD_SETTINGS_REQUEST`;
export const GET_USER_DEVICE_CARD_SETTINGS_SUCCESS = `${actionPrefix}/GET_USER_DEVICE_CARD_SETTINGS_SUCCESS`;
export const GET_USER_DEVICE_CARD_SETTINGS_FAILURE = `${actionPrefix}/GET_USER_DEVICE_CARD_SETTINGS_FAILURE`;

export const UPDATE_LAST_VIEWED_JOB = `${actionPrefix}/UPDATE_LAST_VIEWED_JOB`;

export const UPDATE_USER_DEVICE_CARD_SETTINGS = `${actionPrefix}/UPDATE_USER_DEVICE_CARD_SETTINGS`;

