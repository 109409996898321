export const StrokeMinWeight = 6;
export const CalculateTimeout = 3;
export const POLYLINE_ICON_ZOOM_LEVEL = 15;
export const PIT_OBS_TEXT_ZOOM_LEVEL = 15;
export const FIELD_TEXT_ZOOM_LEVEL = 14;
export const MARKER_TEXT_ZOOM_LEVEL = 15;
export const ElementColorMap = {
  pit: "#008DDC",
  hose: "#27FFFF",
  field: "#64BC6D",
  obstacle: "#E05252",
  dragline: "#99ff00",
  set_layout: "#FF820E",
  pipe_line: "#B53CFF",
};

export const GoogleDrawType = {
  POLYLINE: "polyline",
  POLYGON: "polygon",
  MARKER: "marker",
  CIRCLE: "circle",
  RECTANGLE: "rectangle",
};

export const GoogleMapType = {
  ROADMAP: "roadmap",
  SATELLITE: "satellite",
};

export const ToolTypes = {
  PIT: "pit",
  FIELD: "field",
  OBSTACLE: "obstacle",
  HOSE: "hose",
  PUMP: "pump",
  CULVERT: "culvert",
  RAISER: "riser",
  SET_LAYOUT: "drag hose",
  PIPE_LINE: "pipeline",
  OTHER: "other",
};
export const UNDO_REDO_CONSTANTS = {
  UNDO: "undo",
  REDO: "redo",
  ADD: "add",
  REMOVE: "delete",
  UPDATE: "update",
  UPDATE_ITEM: "update_item",
};

export const ToolsButtons = [
  {
    name: ToolTypes.PIT,
    label: ToolTypes.PIT,
    img: "PIT_ICO",
    googleDrawType: GoogleDrawType.POLYGON,
    data: {
      drawType: ToolTypes.PIT,
      strokeColor: ElementColorMap.pit,
      fillColor: ElementColorMap.pit,
      fillOpacity: 0.6,
      strokeWeight: 3,
      strokeOpacity: 1,
      clickable: true,
      zIndex: 1,
      groupable: false,
    },
  },
  {
    name: ToolTypes.FIELD,
    label: ToolTypes.FIELD,
    img: "FIELD_ICO",
    googleDrawType: GoogleDrawType.POLYGON,
    data: {
      strokeColor: ElementColorMap.field,
      drawType: ToolTypes.FIELD,
      fillColor: ElementColorMap.field,
      fillOpacity: 0.6,
      padding: 4,
      strokeWeight: 3,
      strokeOpacity: 1,
      clickable: true,
      zIndex: 1,
      groupable: true,
    },
  },
  {
    name: ToolTypes.OBSTACLE,
    label: ToolTypes.OBSTACLE,
    img: "OBSTRACLE_ICO",
    googleDrawType: GoogleDrawType.POLYGON,
    data: {
      strokeColor: ElementColorMap.obstacle,
      drawType: ToolTypes.OBSTACLE,
      fillColor: ElementColorMap.obstacle,
      fillOpacity: 0.6,
      strokeWeight: 3,
      strokeOpacity: 1,
      clickable: true,
      zIndex: 1,
      groupable: false,
    },
  },
  {
    name: ToolTypes.PUMP,
    label: ToolTypes.PUMP,
    img: "PUMP_ICO",
    googleDrawType: GoogleDrawType.MARKER,
    data: {
      drawType: ToolTypes.PUMP,
      icon: "PUMP_ICO_ON",
      animation: "DROP",
      groupable: true,
    },
  },
  {
    name: ToolTypes.HOSE,
    label: ToolTypes.HOSE,
    img: "HOSE_ICO",
    googleDrawType: GoogleDrawType.POLYLINE,
    data: {
      strokeColor: ElementColorMap[ToolTypes.HOSE],
      drawType: ToolTypes.HOSE,
      strokeWeight: 8,
      maxWeight: 14,
      strokeOpacity: 1,
      clickable: true,
      zIndex: 1,
      groupable: true,
    },
  },
  {
    name: ToolTypes.CULVERT,
    label: ToolTypes.CULVERT,
    img: "CULVERT_ICO",
    googleDrawType: GoogleDrawType.MARKER,
    data: {
      drawType: ToolTypes.CULVERT,
      icon: "CULVERT_ICO_ON",
      animation: "DROP",
      groupable: true,
    },
  },
  {
    name: ToolTypes.RAISER,
    label: "Riser Pipe",
    img: "RISER_PIPE_ICO",
    googleDrawType: GoogleDrawType.MARKER,
    data: {
      drawType: ToolTypes.RAISER,
      icon: "RISER_PIPE_ICO_ON",
      animation: "DROP",
      groupable: true,
    },
  },
  {
    name: ToolTypes.SET_LAYOUT,
    label: ToolTypes.SET_LAYOUT,
    img: "SET_LAYOUT_ICO",
    googleDrawType: GoogleDrawType.POLYLINE,
    data: {
      strokeColor: ElementColorMap.set_layout,
      drawType: ToolTypes.SET_LAYOUT,
      strokeWeight: 6,
      maxWeight: 8,
      strokeOpacity: 1,
      clickable: true,
      zIndex: 1,
      groupable: true,
    },
  },
  {
    name: ToolTypes.PIPE_LINE,
    label: ToolTypes.PIPE_LINE,
    img: "PIPE_LINE_ICO",
    googleDrawType: GoogleDrawType.POLYLINE,
    data: {
      strokeColor: ElementColorMap.pipe_line,
      drawType: ToolTypes.PIPE_LINE,
      strokeWeight: 12,
      maxWeight: 20,
      strokeOpacity: 1,
      clickable: true,
      zIndex: 1,
      groupable: true,
    },
  },
  {
    name: ToolTypes.OTHER,
    label: "Other",
    img: "OTHER_ICO",
    googleDrawType: GoogleDrawType.MARKER,
    data: {
      drawType: ToolTypes.OTHER,
      icon: "OTHER_ICO_ON",
      animation: "DROP",
      groupable: true,
    },
  },
];

export const DrawElementConst = {
  GROUP: "GROUP",
  UNGROUP: "UNGROUP",
  EDIT: "EDIT",
  VIEW: "VIEW",
  group: "group",
  NEW: "new",
  hidden: "hidden",
  visible: "visible",
};

export const MAP_Zoom_Levels = {
  22: 0,
  21: 0,
  20: 1,
  19: 5,
  18: 10,
  17: 20,
  16: 25,
  15: 30,
  14: 40,
  13: 50,
};

export function calculateStrokeWeight(zoomLevel, weight, strokeMinWeight) {
  let final = strokeMinWeight - (MAP_Zoom_Levels[zoomLevel] || 70) / 10;
  if (final <= 1) {
    final = 1;
  }

  return final;
}

export const getPolylineArrowOffset = (path, map, def) => {
  const projection = map.getProjection();
  const pixelSize = Math.pow(2, -map.getZoom());

  const p1 = projection.fromLatLngToPoint(path[0]);
  const p2 = projection.fromLatLngToPoint(path[1]);
  const totalPixelLength =
    Math.sqrt((p1.x - p2.x) * (p1.x - p2.x) + (p1.y - p2.y) * (p1.y - p2.y)) /
    pixelSize;
  const offset = totalPixelLength < def ? totalPixelLength : def;
  return `${offset.toFixed(0)}px`;
};
