// React
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";
// Routing
import { navigate } from "_routes/RootNavigation";
import { View } from "react-native";
import Modal from "_components/common/Modal.component";
import ConfirmDeleteModalContent from "_components/common/ConfirmDeleteModalContent.component";
import ConfirmSuccess from "_components/common/SiteSuccessModal.component";

// UI Framework
import { styles } from "_pages/Dashboard.style";
import MapEditor from "../components/map/MapEditor";
import { showToast } from "_redux/toasts/actions";

import {
  clearState,
  siteMapEditInitialized,
  makeMapFullScreen,
  saveMapData,
  loadMapData,
} from "_redux/siteMap/actions";
const location = {
  lat: 36.0193197,
  lng: -86.5818118,
};

const zoom = 10;
class MapHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: true,
      location: null,
      zoom: null,
      readOnly: true,
      elements: [],
      groups: [],
      showModal: false,
      mapExit: false,
      geometry: false,
      polygon: false,
      siteId: null,
      saveMapData: null,
      confirmDeleteGroup: false,
    };
    this.onExitMap = this.onExitMap.bind(this);
    this._handleConfirmClick = this._handleConfirmClick.bind(this);
    this._handleCancelClick = this._handleCancelClick.bind(this);
    this._callSaveMapData = this._callSaveMapData.bind(this);
    this._onShowWarning = this._onShowWarning.bind(this);
    this._handleGeometryClick = this._handleGeometryClick.bind(this);
    this._handleConfirmSave = this._handleConfirmSave.bind(this);
    this._handleSaveCancel = this._handleSaveCancel.bind(this);
    this._handleSaveMapClick = this._handleSaveMapClick.bind(this);
    this._handleCancelDeleteGroup = this._handleCancelDeleteGroup.bind(this);
    this._handleConfirmDeleteGroup = this._handleConfirmDeleteGroup.bind(this);
  }

  componentDidMount() {
    const { route, callLoadMapData } = this.props;
    if (route && route.params) {
      this.setState({
        siteId: route.params.siteId,
      });
      callLoadMapData(route.params.siteId);
    }
  }

  componentDidUpdate(prevProps) {
    const { getMapdata, route, isMapDataLoaded } = this.props;

    if (prevProps.route.params.readOnly !== route.params.readOnly) {
      this.setState({ readOnly: route.params.readOnly });
    }

    if (prevProps.isSiteMapSave !== this.props.isSiteMapSave) {
      if (!this.props.isSiteMapSave) {
        this.setState({
          showModal: true,
          saveconfirm: true,
        });
      }
    }
    if (
      prevProps.getMapdata !== getMapdata &&
      getMapdata &&
      route &&
      route.params
    ) {
      this.setState({
        elements: getMapdata.elements || [],
        groups: getMapdata.groups || [],
        zoom: getMapdata.zoomLevel || route.params.zoom,
        location: getMapdata.location || route.params.location,
      });
    }
  }

  componentWillUnmount() {
    const { makeMapFullScreen, clearState } = this.props;
    makeMapFullScreen(false);
    clearState();
  }

  _handleConfirmDeleteGroup() {
    this.setState({
      confirmDeleteGroup: true,
      deletegroup: false,
      showModal: false,
    });
  }

  _handleCancelDeleteGroup() {
    this.setState({
      confirmDeleteGroup: false,
      deletegroup: false,
      showModal: false,
    });
  }

  _handleConfirmClick() {
    const { callLoadMapData, callSaveMapData } = this.props;
    this.setState({ showModal: false, mapExit: false });
    callSaveMapData(this.state.siteId, this.state.saveMapData);
    navigate("Site", {
      location: this.state.location,
      zoom: this.state.zoom,
      readOnly: true,
      siteId: this.state.siteId,
    });
  }

  _handleCancelClick() {
    setTimeout(() => {
      this.setState({ showModal: false, savedata: false, readOnly: true });
      this.onExitMap(false);
    });
  }

  _handleConfirmSave() {
    const { callSaveMapData } = this.props;
    callSaveMapData(this.state.siteId, this.state.saveMapData);
    this.setState({ showModal: false, savedata: false, saveMapData: null });
  }

  _handleSaveCancel() {
    this.setState({ showModal: false, savedata: false, saveMapData: null });
  }

  _callSaveMapData(mapData) {
    this.setState({
      saveMapData: mapData,
    });
    this._onShowWarning({
      savedata: true,
    });
  }

  _onShowWarning(args) {
    const { geometry, deletegroup, deleteitem, savedata, polygon } = args;
    if (geometry) {
      this.setState({
        showModal: true,
        geometry: true,
      });
    } else if (polygon) {
      this.setState({
        showModal: true,
        polygon: true,
      });
    } else if (savedata) {
      this.setState({
        showModal: true,
        savedata: true,
      });
    } else if (deletegroup) {
      this.setState({
        showModal: true,
        deletegroup: true,
        confirmDeleteGroup: false,
      });
    }
  }

  _handleGeometryClick() {
    this.setState({
      showModal: false,
      geometry: false,
      polygon: false,
    });
  }

  _handleSaveMapClick() {
    this.setState({
      showModal: false,
      saveconfirm: false,
    });
  }

  onExitMap(mapDataChanged, mapData) {
    const currentReadOnly = this.state.readOnly;
    const { route, callLoadMapData } = this.props;
    this.setState({
      saveMapData: mapData,
    });
    if (currentReadOnly && route.params.fromEditSite) {
      navigate("SiteEdit");
    } else if (currentReadOnly) {
      navigate("Sites");
    } else {
      if (mapDataChanged) {
        this.setState({
          showModal: true,
          mapExit: true,
        });
      } else {
        navigate("Site", {
          location: this.state.location,
          zoom: this.state.zoom,
          readOnly: true,
          siteId: this.state.siteId,
        });
      }
    }
  }

  render() {
    let zoom = this.state.zoom;
    let elements = this.state.elements;
    let groups = this.state.groups;
    let location = this.state.location;
    const { siteMapEditInitialized, makeMapFullScreen, getMapdata, route } =
      this.props;
    if (this.state.readOnly === false) {
      siteMapEditInitialized();
    } else {
      makeMapFullScreen(true);
    }
    return (
      <>
        <MapEditor
          readOnly={this.state.readOnly}
          elements={elements}
          groups={groups}
          location={location}
          zoom={zoom}
          deleteConfirmGroup={this.state.confirmDeleteGroup}
          onExitMap={this.onExitMap}
          onSaveMapData={this._callSaveMapData}
          onShowWarning={this._onShowWarning}
        />
        <View>
          <Modal
            isVisible={this.state.showModal}
            hideTitle={true}
            hideExitIcon={true}
            content={
              this.state.mapExit ? (
                <ConfirmDeleteModalContent
                  title={"UNSAVED CHANGES"}
                  onConfirm={this._handleConfirmClick}
                  onCancel={this._handleCancelClick}
                  confirmationText={"Would you like to save your map changes?"}
                  confirmButtonText={"SAVE CHANGES"}
                  cancelButtonText={"DISCARD CHANGES"}
                />
              ) : this.state.geometry ? (
                <ConfirmSuccess
                  title={"NOTICE"}
                  titleColor={"#EEB310"}
                  imageStyle={{ height: 104, width: 112 }}
                  imageName={"NOTICE_IMAGE"}
                  subTitle={
                    "YOU CANNOT PLACE ITEMS \n INSIDE A OBSTACLE or PIT AREA"
                  }
                  onClose={this._handleGeometryClick}
                  buttonText={"CLOSE"}
                />
              ) : this.state.savedata ? (
                <ConfirmDeleteModalContent
                  title={"SAVE MAP"}
                  onConfirm={this._handleConfirmSave}
                  onCancel={this._handleSaveCancel}
                  confirmationText={
                    "Would you like to save the map with new \n mapping data of the site?"
                  }
                  confirmButtonText={"SAVE MAP"}
                  cancelButtonText={"DO NOT SAVE MAP"}
                />
              ) : this.state.saveconfirm ? (
                <ConfirmSuccess
                  title={"SUCCESS"}
                  titleColor={"#006644"}
                  imageStyle={{ height: 112, width: 112 }}
                  imageName={"SUCCESS_IMAGE"}
                  subTitle={"YOUR MAPPING DATA \n SUCCESSFULLY SAVED"}
                  onClose={this._handleSaveMapClick}
                  buttonText={"CLOSE"}
                />
              ) : this.state.deletegroup ? (
                <ConfirmDeleteModalContent
                  title={"DELETE GROUP"}
                  onConfirm={this._handleConfirmDeleteGroup}
                  onCancel={this._handleCancelDeleteGroup}
                  confirmationText={
                    "Would you like to delete this group \n and ungroup items?"
                  }
                  confirmButtonText={"DELETE"}
                  cancelButtonText={"DO NOT DELETE"}
                />
              ) : this.state.polygon ? (
                <ConfirmSuccess
                  title={"Warning"}
                  titleColor={"#EEB310"}
                  imageStyle={{ height: 104, width: 112 }}
                  imageName={"NOTICE_IMAGE"}
                  subTitle={
                    "FIELDS, PITS OR OBSTACLES \n CANNOT EXIST WITH 2 NODES"
                  }
                  onClose={this._handleGeometryClick}
                  buttonText={"CLOSE"}
                />
              ) : null
            }
          />
        </View>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isFetchingUserInfo: state.user.isFetchingUserInfo,
    getMapdata: state.siteMap.mapdata,
    isSiteMapSave: state.siteMap.isSaveSiteMap,
    getMapFullScreenStatus: state.siteMap.isMapFulScreen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    siteMapEditInitialized: () => dispatch(siteMapEditInitialized()),
    makeMapFullScreen: (status) => dispatch(makeMapFullScreen(status)),
    callSaveMapData: (siteId, mapData) =>
      dispatch(saveMapData(siteId, mapData)),
    callLoadMapData: (siteId) => dispatch(loadMapData(siteId)),
    clearState: () => dispatch(clearState()),
    showToast: (message, isError) => dispatch(showToast(message, isError)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MapHome);
