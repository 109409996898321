import React, { Component } from "react";

import { Col, Row, Grid } from "react-native-easy-grid";
import { Text, Button } from "native-base";
import { navigate } from "_routes/RootNavigation";
import { styles2 } from "_pages/Site.style";

import { styles as global } from "_style/Global.style";
import { styles } from "./CreateSite.style";
import {
  Image,
  StyleSheet,
  TouchableOpacity,
  View,
  ScrollView,
  Dimensions,
} from "react-native";
import FormInput from "_components/common/FormInputOn.component";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SearchBox from "_components/create_site/LocationSearchBox.component";
const GOOGLE_MAP_GEO_LOCATION =
  "https://maps.googleapis.com/maps/api/geocode/json?address=";
import {
  formatPhoneNumber,
  validateFormData,
  EMAIL_CONSTRAINT_ALLOW_EMPTY,
  PHONE_NUMBER_CONSTRAINT,
  REQUIRED_CONSTRAINT,
} from "_util/form-util";

import validation from "validate.js";

// Form Validation
const constraints = {
  site_name: REQUIRED_CONSTRAINT("Site name"),
  // address: REQUIRED_CONSTRAINT("Address"),
  phone_number: PHONE_NUMBER_CONSTRAINT,
  email: EMAIL_CONSTRAINT_ALLOW_EMPTY,
  // manager: REQUIRED_CONSTRAINT("Manager"),
  //note: REQUIRED_CONSTRAINT("Note"),
};

const windowHeight = Dimensions.get("window").width;

export class SiteMapEditModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      site_name: this.props.siteName,
      address: this.props.siteAddress,
      phone_number: this.props.sitePhone.substring(3, 19),
      email: this.props.siteEmail,
      manager: this.props.siteManager,
      note: this.props.siteNotes,
      placeCenter: this.props.placeCenter,
      errorMessage: "",
      errorMessages: {},
    };
    this._handleFormInput = this._handleFormInput.bind(this);
    //Bind the functions
  }
  componentDidMount() {}

  //input handler
  _handleFormInput = (fieldId, value) => {
    const { errorMessages } = this.state;

    if (fieldId === "phone_number") {
      value = formatPhoneNumber(value);
    }

    // Always set the value of the form field to whatever the
    // user typed.
    this.setState({
      [fieldId]: value,
    });

    // Validate the form field
    const validationResult = validation.validate(
      { [fieldId]: value },
      (fieldId === "email" && value.length > 0) || fieldId === "site_name"
        ? { [fieldId]: constraints[fieldId] }
        : null
    );

    // Either set an error message or clear it for the form field.
    if (validationResult) {
      this.setState({
        errorMessages: {
          ...errorMessages,
          [fieldId]: validationResult[fieldId][0],
        },
      });
    } else {
      this.setState({
        errorMessages: {
          ...errorMessages,
          [fieldId]: null,
        },
      });
    }
  };

  onChangePalce = (place) => {
    console.log(place);
    this.state.placeChecked = place.lat
      ? this.setState({ placeChecked: false })
      : null;
    let myKey = "AIzaSyBU0_g8nGzHxsr-BQUuQvSZ3zuPBSKAFjg";
    fetch(
      GOOGLE_MAP_GEO_LOCATION + place.lat + "," + place.lng + "&key=" + myKey
    )
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          placeCenter: place,
          address: responseJson.results[0].formatted_address,
        });
      });
  };
  phoneFormat = (value) => {
    const cleaned = value.replace(/\D/g, "");
    if (cleaned.length > 10) {
      return;
    }
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    const formatted = !match[2]
      ? match[1]
      : match[1] +
        "-" +
        match[2] +
        (match[3] ? "-" + match[3] : "") +
        (match[4] ? "" + match[4] : "");
    this.setState({ phone_number: formatted });
  };

  render() {
    const { errorMessage, errorMessages } = this.state;

    return (
      <View style={[styles2.SiteCreateModalContent]}>
        <ScrollView>
          <Grid>
            <Row
              style={{
                marginBottom: Object.values(errorMessages).every(
                  (x) => x === "site name is required."
                )
                  ? "0.8%"
                  : "1.4%",
              }}
            >
              <Col size={3}>
                <FormInput
                  fieldId={"site_name"}
                  maxLength={256}
                  onChange={this._handleFormInput}
                  placeholder={"Site name"}
                  onInput={(e) => this.setState({ site_name: e })}
                  inputValue={this.state.site_name}
                  itemStyles={[global.item]}
                  inputStyles={[global.input]}
                  defaultValue={this.props.siteName}
                  value={this.props.siteName}
                  errorMessage={errorMessages["site_name"] || ""}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: "10%" }}>
              <Col size={3}>
                <SearchBox
                  fieldId={"address"}
                  placeholder={"Address"}
                  maxLength={500}
                  multiline2={true}
                  multiline={true}
                  defaultValue={this.props.siteAddress}
                  itemStyles={[styles.item]}
                  inputStyles={[styles.input]}
                  onChange={(e) =>
                    e.target.value == ""
                      ? this.setState({ address: "", placeCenter: {} })
                      : this.setState({
                          address: this.props.siteAddress,
                          placeCenter: this.props.placeCenter,
                        })
                  }
                  width={424}
                  //errorMessage={errorMessages['address'] || ''}
                  onPlaceChange={(place) => {
                    this.onChangePalce(place);
                  }}
                />
                {/* <FormInput
                fieldId={"address"}
                maxLength={256}
                onChange={this._handleFormInput}
                placeholder={"Address"}
                itemStyles={[global.item]}
                inputStyles={[
                  global.input,
                  { color: "gray", marginBottom: "120" },
                ]}
                defaultValue={this.props.siteAddress}
                value={this.props.siteAddress}
                multiline={true}
                multiline2={true}
                disabled
              /> */}
              </Col>
            </Row>
            <Row style={{ marginBottom: "1%" }}>
              <Col size={3}>
                <FormInput
                  fieldId={"manager"}
                  maxLength={256}
                  onInput={(e) => this.setState({ manager: e })}
                  inputValue={this.state.manager}
                  onChange={this._handleFormInput}
                  placeholder={"Manager name"}
                  itemStyles={[global.item]}
                  inputStyles={[global.input]}
                  defaultValue={this.props.siteManager}
                  value={this.props.siteManager}
                  //errorMessage={errorMessages["manager"] || ""}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: "1%" }}>
              <Col size={3}>
                <FormInput
                  fieldId={"phone_number"}
                  maxLength={19}
                  //onChange={this._handleFormInput}
                  placeholder={"Phone number"}
                  onInput={(e) => this.phoneFormat(e)}
                  onChange={() => {}}
                  inputValue={this.state.phone_number}
                  itemStyles={[global.item]}
                  inputStyles={[global.input]}
                  defaultValue={this.props.sitePhone}
                  value={this.props.sitePhone}
                  errorMessage={errorMessages["phone_number"] || ""}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: "1%" }}>
              <Col size={3}>
                <FormInput
                  fieldId={"email"}
                  maxLength={256}
                  onChange={this._handleFormInput}
                  onInput={(e) => this.setState({ email: e })}
                  inputValue={this.state.email}
                  placeholder={"Email"}
                  itemStyles={[global.item]}
                  inputStyles={[global.input]}
                  defaultValue={this.props.siteEmail}
                  value={this.props.siteEmail}
                  errorMessage={errorMessages["email"] || ""}
                />
              </Col>
            </Row>
          </Grid>
          <Button
            disabled={this.props.disabled}
            style={[
              global.confirmationButton,
              { marginTop: "7%", borderRadius: 5, marginBottom: 4, height: 40 },
            ]}
            onPress={() =>
              Object.values(errorMessages).every(
                (x) => x === "Email is required." || x === null || x === ""
              )
                ? this.props.onClick(this.state)
                : null
            }
          >
            <Text style={[global.confirmationButtonText, { fontSize: 18 }]}>
              SAVE DETAILS
            </Text>
          </Button>
          <Button
            style={[
              global.cancelButton,
              {
                marginTop: "2%",
                borderRadius: 5,
                height: 40,
                marginBottom: 14,
              },
            ]}
            onPress={() => this.props.onClose(this.state)}
          >
            <Text
              style={[
                global.cancelButtonText,
                { fontSize: 18, color: "#FAFAFA" },
              ]}
            >
              CLOSE
            </Text>
          </Button>
        </ScrollView>
      </View>
    );
  }
}
SiteMapEditModal.PropTypes = {
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  disabled: PropTypes.any,
};

export default connect(null, null)(SiteMapEditModal);
