import { StyleSheet } from 'react-native';
import { robotoCondensedBold } from '_style/Global.style';

export const styles = StyleSheet.create({
  closeOpenGroupContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  openClosedButton: {
    width: 68,
    height: 28,
    marginHorizontal: 2,
    marginVertical: 5,
    flexDirection: 'column',
    alignItems: 'center',
  },
  openClosedButtonText: {
    fontFamily: robotoCondensedBold,
    fontWeight: '500',
    letterSpacing: 1.5,
    fontSize: 14,
    paddingTop: 7,
  },
  openClosedButtonOffText: {
    color: '#212121',
  },
  openClosedButtonPressedText: {
    color: '#FAFAFA',
  },
  openClosedButtonOnText: {
    color: '#FAFAFA',
    textDecorationLine: 'underline',
  },
});
