import { StyleSheet } from 'react-native';
import { yellow, dark_gray, textLight, green } from '_style/Global.style';

export const styles = StyleSheet.create({
  main: {
    position: 'relative',
    zIndex: 0,
    display: 'flex',
    height: '100%',
    width: '100%',
    backgroundColor: '#383838',
    padding: 25,
    overflow: 'auto',
  },

  crewMembersContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',

    borderRadius: 10,
    backgroundColor: dark_gray,
    padding: 15,
    marginTop: 15,

    // zIndex: 0,
  },

  addCrewMemberButtonContainer: {
    height: 'auto',
    width: '100%',
    marginTop: 15,
  },
  addCrewMemberButton: {
    height: 'auto',
  },
  addCrewMemberButtonText: {
    fontSize: 48,
  },
});
