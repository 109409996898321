import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

import {
  Spinner,
  Text,
  View,
} from 'native-base';

import { styles } from '_components/common/Table.style';

class Table extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      headers,
      highlightHeader = true,
      isLoading = false,
      rowData,
    } = this.props;

    // Header Row
    const headerCols = [];
    headers.forEach((header, index) => {
      const separatorRight = index < headers.length - 1;
      headerCols.push((
        <View
          key={index}
          style={[
            styles.tableCol,
            separatorRight ? styles.rightSeparator : {},
            { flex: header.flex,  alignItems: header.align },
          ]}
        >
          <Text style={[styles.tableText]}>
            { header.value }
          </Text>
        </View>
      ));
    });

    // Rows
    const rows = [];
    rowData.forEach((row, rowIndex) => {
      const highlightHeader = rowIndex % 2 !== 0;
      const rowCols = [];

      // Row Columns
      Object.keys(row).forEach((key, colIndex) => {
        const separatorRight = colIndex < Object.keys(row).length - 1;
        const {
          align,
          flex,
          value,
        } = row[key];

        if (typeof value !== 'object') {
          rowCols.push((
            <View
              key={colIndex}
              style={[
                styles.tableCol, separatorRight ? styles.rightSeparator : {},
                { flex, alignItems: align},
              ]}
            >
              <Text style={[styles.tableText]}>
                { value }
              </Text>
            </View>
          ));
        } else {
          rowCols.push((
            <View
              key={colIndex}
              style={[
                styles.tableCol, separatorRight ? styles.rightSeparator : {},
                { flex, alignItems: align},
              ]}
            >
              { value }
            </View>
          ));
        }
      });

      // Row
      rows.push((
        <View key={rowIndex} style={[styles.tableRow, highlightHeader ? styles.highlightRow : {}]}>
          { rowCols }
        </View>
      ));
    });

    return (
      <View style={[styles.main]}>
        <View style={[styles.tableRow, styles.headerRow, highlightHeader ? styles.highlightHeader : {}, {marginBottom: 20}]}>
          { headerCols }
        </View>
        {
          isLoading ?
            <Spinner color={'red'} />
            :
            <>
            {
              rows.length > 0 ?
                <>
                { rows }
                </>
                :
                <Text style={[{ alignSelf: 'center' }]}>
                  No data
                </Text>
            }
            </>
        }
      </View>
    );
  }
}

Table.propTypes = {
  headers: PropTypes.any.isRequired,
  rowData: PropTypes.any.isRequired,
  highlightHeader: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default connect(null, null)(Table);
