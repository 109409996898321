// React
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";

// UI Framework
import { Button, Text, View } from "native-base";

// Style
import { styles as global } from "_style/Global.style";
import { styles } from "_components/common/ConfirmDeleteModalContent.style";

class ConfirmDeleteModalContent extends Component {
  constructor(props) {
    super(props);

    this._onConfirm = this._onConfirm.bind(this);
    this._onCancel = this._onCancel.bind(this);
  }

  _onConfirm() {
    const { data } = this.props;
    this.props.onConfirm(data);
  }

  _onCancel() {
    this.props.onCancel();
  }

  render() {
    const { title, confirmationText, confirmButtonText, cancelButtonText } =
      this.props;

    return (
      <View style={[styles.confirmRemovalModalContent]}>
        {title ? (<View style={[styles.titleView]}>
          <Text style={[styles.titleText]}>{title}</Text>
        </View>) : null}
        <Text style={[styles.confirmRemovalText]}>{confirmationText}</Text>
        <Button
          style={[global.confirmationButton, styles.mBottom]}
          onPress={this._onConfirm}
        >
          <Text style={[global.confirmationButtonText]}>
            {confirmButtonText}
          </Text>
        </Button>
        <Button
          style={[global.cancelButton, styles.mBottom]}
          onPress={this._onCancel}
        >
          <Text style={[global.cancelButtonText]}>{cancelButtonText}</Text>
        </Button>
      </View>
    );
  }
}

ConfirmDeleteModalContent.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,

  confirmationText: PropTypes.string.isRequired,
  confirmButtonText: PropTypes.string.isRequired,
  cancelButtonText: PropTypes.string.isRequired,

  data: PropTypes.any,
};

export default connect(null, null)(ConfirmDeleteModalContent);
