// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { getTelemetryForDevice } from '_redux/iot/selectors';
import { getUserDeviceCardSettingsById, isUserFetchingDeviceCardSettings } from '_redux/dashboard/selectors';

// Components
import TelemetryValue from '_components/device_card/TelemetryValue.component';

// UI Framework
import { Text, View, Spinner } from 'native-base';
import { Col, Row } from 'react-native-easy-grid';

// Styles
import { styles as global } from '_style/Global.style';
import { styles } from '_components/device_card/Vitals.style';

import { defaultVitalsSettings } from '_components/device_settings/VitalsSettings.component';

const MAX_VITALS = 8;

const Vital = (props) => {
  const { label, value, unit, style } = props;
  return (
    <Row style={[styles.vitalsRow, ...style]}>
      <Text style={[styles.vitalsLabel]}>{label}</Text>
      <TelemetryValue value={value} />
      <Text style={[styles.vitalsUnit]}>{unit}</Text>
    </Row>
  );
}

const nFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: "" },
    // { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup.slice().reverse().find(item => (num >= item.value));
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}

class Vitals extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {
      telemetry,
      deviceSettings,
      isUserFetchingDeviceCardSettings,
    } = this.props;

    const { vitalsSettings = defaultVitalsSettings } = deviceSettings;

    const {
      eop,
      ect,
      ebatt,
      eload,
      efr,
      efl,
      sfr,
      stf,
      def,
      soot,
    } = telemetry;


    const vitals = [
      { id: 'oilPressure', label: 'OIL PRESSURE', value: eop, unit: 'PSI' },
      { id: 'coolantTemp', label: 'COOLANT TEMP', value: ect, unit: '°F' },
      { id: 'battery', label: 'BATTERY', value: ebatt, unit: 'VDC' },
      { id: 'fuelLevel', label: 'FUEL LEVEL', value: efl, unit: '%' },
      { id: 'fuelRate', label: 'FUEL RATE', value: efr, unit: 'GPH' },
      { id: 'flowRate', label: 'FLOW RATE', value: sfr, unit: 'GPM' },
      { id: 'totalFlow', label: 'TOTAL FLOW', value: nFormatter(stf, 3), unit: 'GAL' },
      { id: 'load', label: 'LOAD', value: eload, unit: '%' },
      { id: 'defLevel', label: 'DEF LEVEL', value: def, unit: '%' },
      { id: 'soot', label: 'SOOT', value: soot, unit: '%' },
    ];

    const vitalComponents = [];

    // sort the vitals settings by order
    let sortedVitals = Object.entries(vitalsSettings).sort((a, b) => {
      return a[1].order - b[1].order;
    });

    // iterate through vitals settings and build ordered list of vital display component
    let vitalCount = 0;
    for (const [key, value] of sortedVitals) {
      if (value.show) {
        vitalCount++;
        const item = vitals.filter(vital => vital.id === key)[0];
        vitalComponents.push(
          <Vital style={[(vitalCount === MAX_VITALS) && styles.noBorder]}
            key={item.id}
            label={item.label}
            value={item.value} 
            unit={item.unit}
          />
        );
      }
    }

    return (
      <View>
        {
          isUserFetchingDeviceCardSettings
            ?
            <Spinner color={'red'} />
            :
            <Col style={[styles.vitalsContainer]}>
              {vitalComponents}
            </Col>
        }
      </View>
    );
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    telemetry: getTelemetryForDevice(state, ownProps.deviceId),
    deviceSettings: getUserDeviceCardSettingsById(state, ownProps.deviceId),
    isFetchingCardSettings: isUserFetchingDeviceCardSettings(state, ownProps.deviceId),
  };
};

Vitals.propTypes = {
  deviceId: PropTypes.string.isRequired,
  telemetry: PropTypes.any.isRequired,
  deviceSettings: PropTypes.any.isRequired,
  isFetchingCardSettings: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, null)(Vitals);
