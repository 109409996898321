import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  mainContent: {
    flex: 1,
    flexDirection: "row",
    marginTop: "3%",
    marginBottom: "6%",
    justifyContent: "center",
    alignItems: "center",
  },
  inputStyle: {
    borderColor: "white",
    borderRadius: 5,
    height: 41,
    color: "#B3B3B3",
    backgroundColor: "#424242",
  },
  btnStyle: {
    height: 40,
    marginBottom: 15,
  },
  confirmRemovalModalContent: {
    width: 425,
    marginLeft: 75,
    marginRight: 75,
  },
  confirmRemovalText: {
    textAlign: "center",
    fontSize: 20,
    fontWeight: "bold",
    marginTop: 35,
    marginRight: 30,
    marginBottom: 40,
    letterSpacing: 1.6,
  },
});
