import { StyleSheet } from "react-native";

export const styles2 = StyleSheet.create({
  bRadius: {
    borderRadius: 10,
  },
  mainViewContainer: {
    flex: 1,
    justifyContent: "space-around",
    borderRadius: 12,
  },
  rowMainContent: {
    padding: 20,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
  },
  topContent: {
    flex: 1,
    flexDirection: "row",
    marginTop: 4,
    padding: 8,
    borderRadius: 8,
  },
  mapsubContent: {
    marginTop: "-1%",
    height: 450,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
  },
  backButton: {
    width: 88.4,
    borderRadius: 5,
    height: 29,
    fontSize: 10,
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#424242",
    Color: "white",
  },
  editButton: {
    width: 115,
    borderRadius: 5,
    marginLeft: 10,
    height: 29,
    fontSize: 8,
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#424242",
    Color: "white",
  },
  editButtonImg: {
    width: 18,
    height: 18,
    marginRight: 6,
  },
  groupButtonImg: {
    width: 18,
    height: 12,
    marginRight: 6,
  },
  editButtonText: {
    textAlign: "center",
    color: "white",
    fontSize: 12,
    fontWeight: "bold",
  },
});
