const actionPrefix = "app/site";

export const CLEAR_STATE = `${actionPrefix}/CLEAR_STATE`;
export const INIT_SITEMAP = `${actionPrefix}/INIT_SITEMAP`;

export const DOCS_GET_REQUEST = `${actionPrefix}/DOCS_GET_REQUEST`;
export const DOCS_GET_SUCCESS = `${actionPrefix}/DOCS_GET_SUCCESS`;
export const DOCS_GET_FAILURE = `${actionPrefix}/DOCS_GET_FAILURE`;
export const EDIT_DOCS_REQUEST = `${actionPrefix}/EDIT_DOCS_REQUEST`;
export const EDIT_DOCS_SUCCESS = `${actionPrefix}/EDIT_DOCS_SUCCESS`;
export const EDIT_DOCS_FAILURE = `${actionPrefix}/EDIT_DOCS_FAILURE`;
export const LOCK_DOCS_REQUEST = `${actionPrefix}/LOCK_DOCS_REQUEST`;
export const LOCK_DOCS_SUCCESS = `${actionPrefix}/LOCK_DOCS_SUCCESS`;
export const LOCK_DOCS_FAILURE = `${actionPrefix}/LOCK_DOCS_FAILURE`;
export const DELETE_DOCS_REQUEST = `${actionPrefix}/DELETE_DOCS_REQUEST`;
export const DELETE_DOCS_SUCCESS = `${actionPrefix}/DELETE_DOCS_SUCCESS`;
export const DELETE_DOCS_FAILURE = `${actionPrefix}/DELETE_DOCS_FAILURE`;
export const UPLOAD_DOCS_REQUEST = `${actionPrefix}/UPLOAD_DOCS_REQUEST`;
export const UPLOAD_DOCS_SUCCESS = `${actionPrefix}/UPLOAD_DOCS_SUCCESS`;
export const UPLOAD_DOCS_FAILURE = `${actionPrefix}/UPLOAD_DOCS_FAILURE`;
