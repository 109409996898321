import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  confirmRemovalModalContent: {
    width: 425,
    marginLeft: 75,
    marginRight: 75,
    padding:5,
  },
  confirmRemovalText: {
    textAlign: "center",
    marginBottom: 40,
    letterSpacing: 1.6,
  },
  mBottom: {
    marginBottom: 15,
  },
  titleView: {
    width: "100%",
    borderBottomColor: "#FAFAFA",
    borderBottomWidth: 3,
    padding: 10,
    textAlign: "center",
    marginBottom: 50,
    marginTop: 10,
  },
  titleText: {
    fontSize: 24,
    fontWeight: "700",
    color: "#FAFAFA",

  }
});
