import { createReducer } from '_redux/rootActions';

// Types
import {
  CLEAR_STATE,

  GET_DASHBOARD_DEVICES_REQUEST,
  GET_DASHBOARD_DEVICES_SUCCESS,
  GET_DASHBOARD_DEVICES_FAILURE,

  GET_USER_DEVICE_CARD_SETTINGS_REQUEST,
  GET_USER_DEVICE_CARD_SETTINGS_SUCCESS,
  GET_USER_DEVICE_CARD_SETTINGS_FAILURE,

  UPDATE_USER_DEVICE_CARD_SETTINGS,

  UPDATE_LAST_VIEWED_JOB,
} from '_redux/dashboard/types';

const initialState = {
  isGettingDashboardDevices: false,
  isFetchingUserDeviceCardSettings: {},
  devices: [],

  lastViewedJobId: null,
  lastViewedJobName: '',
};

export default createReducer(initialState, {
  // CLEAR STATE
  [CLEAR_STATE]: () => initialState,

  // ADD JOB
  [GET_DASHBOARD_DEVICES_REQUEST]: (state) => {
    return {...state, isGettingDashboardDevices: true};
  },
  [GET_DASHBOARD_DEVICES_SUCCESS]: (state, action) => {
    const { devices } = action;
    return {...state, isGettingDashboardDevices: false, devices};
  },
  [GET_DASHBOARD_DEVICES_FAILURE]: (state) => {
    return {...state, isGettingDashboardDevices: false};
  },

  // UPDATE LAST VIEWED JOB
  [UPDATE_LAST_VIEWED_JOB]: (state, action) => {
    const { job } = action;
    if (job) {
      return {
        ...state,
        lastViewedJobId: job.job_id,
        lastViewedJobName: job.job_name,
      };
    }
    return {
      ...state,
      lastViewedJobId: null,
      lastViewedJobName: '',
    };
  },


  // GET USER DEVICE CARD SETTINGS
  [GET_USER_DEVICE_CARD_SETTINGS_REQUEST]: (state, action) => {
    const { deviceId } = action;
    return {...state, isFetchingUserDeviceCardSettings: { [deviceId]: true } };
  },
  [GET_USER_DEVICE_CARD_SETTINGS_SUCCESS]: (state, action) => {
    const { deviceCardSettings, deviceId } = action;

    const deviceIndex = state.devices.findIndex((device) => device.device_id === deviceId);

    const updatedDevices = state.devices.map((item, index) => {
      if (index !== deviceIndex) {
        return item;
      }
      return {
        ...item,
        card_settings: deviceCardSettings,
      };
    });

    return {...state, devices: updatedDevices, isFetchingUserDeviceCardSettings: { [deviceId]: false } };
  },
  [GET_USER_DEVICE_CARD_SETTINGS_FAILURE]: (state, action) => {
    const { deviceId } = action;
    return {...state, isFetchingUserDeviceCardSettings: { [deviceId]: false } };
  },

  // UPDATE USER DEVICE CARD SETTINGS
  [UPDATE_USER_DEVICE_CARD_SETTINGS]: (state, action) => {
    const { deviceId, settings } = action;

    return saveUserDeviceCardSettings(state, { deviceId, deviceCardSettings: settings });
  },
});


/**
 * Redux safe way to update the user's device card settings.
 *
 * @param {} state
 * @param {*} action
 */
const saveUserDeviceCardSettings = (state, action) => {
  const { deviceCardSettings, deviceId } = action;

  const deviceIndex = state.devices.findIndex((device) => device.device_id === deviceId);

  const updatedDevices = state.devices.map((item, index) => {
    if (index !== deviceIndex) {
      return item;
    }
    const oldSettings = item.card_settings;
    return {
      ...item,
      card_settings: { ...oldSettings, ...deviceCardSettings },
    };
  });

  return {...state, devices: updatedDevices, isSavingUserDeviceCardSettings: { [deviceId]: false } };
};
