import { StyleSheet } from 'react-native';
import { green } from '_style/Global.style';

export const styles = StyleSheet.create({
  feedback: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    height: '100%',
    width: '100%',
    padding: 25,
    backgroundColor: '#383838',
    overflow: 'auto',
  },
  tableText:{
     textAlign:"right", 
     fontSize: 18,
     fontFamily:"Roboto Condensed",   
     marginRight:5,
     width:"100%"
  },
  UnsupportContainer: {
    display: 'flex',
    flex:1,
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#1A1A1A',
    width: '100%',
    height:'100%',
    borderRadius: 10,
    padding: 15,
    marginTop: 25,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#1A1A1A',
    width: '100%',
    borderRadius: 10,
    padding: 15,
    marginTop: 25,
  },
  submitFeedbackButtonContainer: {
    width: '100%',
    marginTop: 15,
  },
  submitFeedbackButton: {
    height: 88,
  },
  submitFeedbackButtonText: {
    fontSize: 48,
  },
  formTitle: {
    fontSize: 30,
    fontWeight: 'bold',
  },
  formSubtitle: {
    fontSize: 14,
    textAlign: 'center',
    maxWidth: 450,
    marginTop: 20,
  },
  mainForm: {
    marginTop: 25,
    width: '100%',
    maxWidth: 700,
  },
  formRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  formItem: {
    flex: 1,
    marginLeft: 5,
    marginRight: 5,
    marginBottom: 20,
  },
  errorMessage: {
    color: 'red',
  },
  feedbackSuccessMessage:{
    color: green,
    fontSize: 64,
  },
});
