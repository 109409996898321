// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Native Base
import {
  Text,
} from 'native-base';

import { styles as global } from '_style/Global.style';

const defaultFontSize = 21;

class TelemetryValue extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { value, fontSize } = this.props;

    return (
      <Text style={[global.telemetryValue, { fontSize: fontSize || defaultFontSize }]}>
        { value }
      </Text>
    );
  }
}

TelemetryValue.propTypes = {
  value: PropTypes.any.isRequired,
  fontSize: PropTypes.number,
};

export default connect(null, null)(TelemetryValue);
