import { createReducer } from '_redux/rootActions';

// Types
import {
  CLEAR_STATE,
  SUBMIT_FEEDBACK_REQUEST,
  SUBMIT_FEEDBACK_SUCCESS,
  SUBMIT_FEEDBACK_FAILURE,
} from '_redux/feedback/types';

const initialState = {
  isSubmittingFeedback: false,
  submitFeedbackSuccess: false,
};

export default createReducer(initialState, {
  // Clear state.
  [CLEAR_STATE]: () => initialState,

  // Submit feedback.
  [SUBMIT_FEEDBACK_REQUEST]: (state) => {
    return {...state, isSubmittingFeedback: true, submitFeedbackSuccess: false };
  },
  [SUBMIT_FEEDBACK_SUCCESS]: (state) => {
    return {...state, isSubmittingFeedback: false, submitFeedbackSuccess: true };
  },
  [SUBMIT_FEEDBACK_FAILURE]: (state) => {
    return {...state, isSubmittingFeedback: false, submitFeedbackSuccess: false };
  },
});
