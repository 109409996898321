import React from "react";
import { Container, Content, Icon, Accordion, Text } from "native-base";
import { TouchableOpacity, View, Image } from "react-native";
import { styles } from "./MapEditor.style";
import { Images } from "../../assets";
import CreateGroupButton from "../common/CreateGroupButton";
import AccessControl, {
  ADMIN,
  MANUFACTURER,
  CREW_LEADER,
  CREW_MEMBER,
  DEALER,
  OWNER,
} from "_components/auth/AccessControl.component";
import { NativeBaseAccordian } from "./NativeBaseAccordian";

const GroupAccordian = ({
  dataArray,
  leftIcon,
  rightIcon,
  iconColor,
  headerStyles,
  maxHeight,
  onSelectedGroup,
  hideUnHideGroup,
  onSelectedLayer,
  onEditGrp,
}) => {
  const [groupData, setGroupData] = React.useState(dataArray);
  const [expandIndex, setExpandIndex] = React.useState(-1);
  const [contentHeight, setContentHeight] = React.useState(20);

  React.useEffect(() => {
    setGroupData(dataArray);
    const index = dataArray.findIndex((group) => group.selected);
    setExpandIndex(index);
    let containerHeight = maxHeight || contentHeight;
    if (dataArray.length > 1) {
      containerHeight = containerHeight / dataArray.length - 1;
      setContentHeight(containerHeight);
    }
  }, [dataArray]);

  const ArrowDown = ({ color }) => {
    return (
      <svg
        width="13"
        height="10"
        viewBox="0 0 13 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M6.5 10L0.870835 0.25L12.1292 0.250001L6.5 10Z" fill={color} />
      </svg>
    );
  };

  const ArrowSide = ({ color }) => {
    return (
      <svg
        width="10"
        height="13"
        viewBox="0 0 10 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 6.5L0.250001 12.1292L0.250001 0.870835L10 6.5Z"
          fill={color}
        />
      </svg>
    );
  };

  const _renderContent = (item) => {
    return (
      <View>
        {item.items.map((element) => {
          return (
            <TouchableOpacity
              onPress={() => onSelectedLayer(element)}
              key={element.id}
            >
              <View style={[{ flexDirection: "row", marginLeft: 20 }]}>
                <Image
                  style={{
                    width: 15,
                    height: 13,
                    marginRight: 3,
                    marginTop: 4,
                  }}
                  source={Images[element.img]}
                />
                <Text>&nbsp; {element.name}</Text>
              </View>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  };
  const _renderHeader = (item, expanded) => {
    return (
      <View
        style={{
          flexDirection: "row",
          flex: 1,
          justifyContent: "space-between",
          paddingTop: 10,
          paddingBottom: 10,
          marginRight: 20,
        }}
      >
        {expanded ? (
          <View
            style={{
              width: 13,
              height: 13,
              marginRight: 11,
              marginTop: 4,
            }}
          >
            <ArrowDown color={iconColor || "#EEB310"} />
          </View>
        ) : (
          <View
            style={{
              width: 13,
              height: 13,
              marginRight: 11,
              marginTop: 4,
            }}
          >
            <ArrowSide color={iconColor || "#EEB310"} />
          </View>
        )}
        <TouchableOpacity onPress={() => onSelectedGroup(item)}>
          <View
            style={{
              flexDirection: "row",
            }}
          >
            {leftIcon ? (
              <CreateGroupButton
                imageName={!item.visible ? "CLICK_EYE_ICON" : "EYE_ICON"}
                buttonWithText={false}
                onPress={() => hideUnHideGroup(item)}
              />
            ) : null}

            <Text
              style={[
                {
                  color: "#EEB310",
                  marginLeft: 6,
                  fontWeight: "600",
                  width: "6.5vw",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                },
                headerStyles,
              ]}
            >
              {" "}
              {item.name}
            </Text>
          </View>
        </TouchableOpacity>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <AccessControl
            allowedGroups={[ADMIN, MANUFACTURER, CREW_LEADER, DEALER, OWNER]}
          >
            {rightIcon ? (
              <CreateGroupButton
                iconStyles={[
                  {
                    width: 13,
                    height: 13,
                    marginTop: 3,
                    marginLeft: 3,
                  },
                ]}
                selected={item.state === "EDIT"}
                imageName={"ICON__EDIT"}
                buttonWithText={false}
                onPress={() => onEditGrp(item)}
              />
            ) : null}
          </AccessControl>
        </View>
      </View>
    );
  };

  return (
    <>
      <NativeBaseAccordian
        style={{ border: 0 }}
        dataArray={groupData}
        animation={true}
        expanded={expandIndex}
        renderHeader={_renderHeader}
        renderContent={_renderContent}
      />
    </>
  );
};

export default React.memo(GroupAccordian);
