import { StyleSheet } from 'react-native';
import {
  light_gray,
  roboto,
  textLight,
} from '_style/Global.style';

export const styles = StyleSheet.create({
  actionButton: {
    backgroundColor: light_gray,
    borderRadius: 5,
  },
  actionButtonText: {
    fontFamily: roboto,
    fontWeight: 'bold',
    color: textLight,
    letterSpacing: 1.6,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});
