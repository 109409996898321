// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// UI Framework
import { Button, Spinner, Text, View } from 'native-base';

import CheckBox from '_components/common/CheckBox.component';
import FormInput from '_components/common/FormInput.component';

import {
  formatPhoneNumber,
  validateFormData,
  EMAIL_CONSTRAINT,
  PHONE_NUMBER_CONSTRAINT,
  REQUIRED_CONSTRAINT,
} from '_util/form-util';
import validation from 'validate.js';

// Style
import { styles as global } from '_style/Global.style';
import { styles } from '_components/accounts/CreateUserAccountModalContent.style';

// Form Validation
const constraints = {
  firstName: REQUIRED_CONSTRAINT('First name'),
  lastName: REQUIRED_CONSTRAINT('Last name'),
  phoneNumber: PHONE_NUMBER_CONSTRAINT,
  email: EMAIL_CONSTRAINT,
};

/**
 * Renders the content for the Add and Edit screens within the
 * Modal.
 */
class CreateSystemUserModalContent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      formData: {},
      errorMessages: {},
      showSuccessScreen: false,
    },

    this._onCancel = this._onCancel.bind(this);

    this._handleInputChange = this._handleInputChange.bind(this);
    this._handleCreate = this._handleCreate.bind(this);

    this._renderCreateScreen = this._renderCreateScreen.bind(this);
    this._renderSuccessScreen = this._renderSuccessScreen.bind(this);
  }

  /**
   *
   * Triggered when the user successfully fills out the Create System User
   * form. Handles triggering the API call necessary to register the new
   * member.
   *
   * @param {object} formData Create System User form data.
   */
  _handleCreate() {
    const { formData } = this.state;
    const { create, groupId, isOwnerInvite } = this.props;

    const errorMessages = validateFormData(formData, constraints);

    formData.isOwnerInvite = isOwnerInvite;

    if (Object.keys(errorMessages).length === 0) {
      create(formData, groupId);
    } else {
      this.setState({
        errorMessages,
      });
    }
  }

  /**
   * Triggered when the cancel button is clicked.
   */
  _onCancel() {
    this.props.onCancel();
  }

  /**
   * Handle input changes on the form
   * @param {*} fieldId id of the form field, e.g. 'email'
   * @param {*} value value of the form field, e.g. 'abc@email.com'
   */
  _handleInputChange(fieldId, value) {
    const { errorMessages, formData } = this.state;

    if (fieldId === 'phoneNumber') {
      value = formatPhoneNumber(value);
    }

    // Validate the form field
    const validationResult = validation.validate(
      { [fieldId]: value },
      { [fieldId]: constraints[fieldId] }
    );
    // Either set an error message or clear it for the form field.
    if (validationResult) {
      this.setState({
        formData: {
          ...formData,
          [fieldId]: value,
        },
        errorMessages: {
          ...errorMessages,
          [fieldId]: validationResult[fieldId][0],
        }
      });
    } else {
      this.setState({
        formData: {
          ...formData,
          [fieldId]: value,
        },
        errorMessages: {
          ...errorMessages,
          [fieldId]: null,
        },
      });
    }
  }

  /**
   * Renders the screen after a successful invite.
   */
  _renderSuccessScreen() {
    const {
      firstName,
      lastName,
      phoneNumber,
      email,
    } = this.state.formData;

    return (
      <>
        <Text style={[styles.successTitle]}>
          INVITE SENT SUCCESSFULLY
        </Text>
        <Text style={[styles.description]}>
          The Following user should receive a link to create an account shortly.
        </Text>
        <View style={[styles.successInfoContainer]}>
          <View style={[styles.successRow]}>
            <View>
              <Text style={[styles.tableText]}>Name:</Text>
            </View>
            <View>
              <Text style={[styles.tableText]}>{ firstName } { lastName }</Text>
            </View>
          </View>
          <View style={[styles.successRow]}>
            <View>
              <Text style={[styles.tableText]}>Email Address:</Text>
            </View>
            <View>
              <Text style={[styles.tableText]}>{ email }</Text>
            </View>
          </View>
          <View style={[styles.successRow]}>
            <View>
              <Text style={[styles.tableText]}>Phone Number:</Text>
            </View>
            <View>
              <Text style={[styles.tableText]}>{ phoneNumber }</Text>
            </View>
          </View>
        </View>
        <Button style={[global.cancelButton, styles.closeButton]} onPress={this._onCancel}>
          <Text style={[global.cancelButtonText, styles.closeButtonText]}>
            CLOSE
          </Text>
        </Button>
      </>
    );
  }

  /**
   * Renders the view where the user is filling out the form
   * to send the invite.
   */
  _renderCreateScreen() {
    const {
      confirmButtonLabel,
      createInProgress,
      createFailed,
      createError,
      subTitle,
      title,
    } = this.props;

    const {
      errorMessages,
    } = this.state;

    return (
      <>
        <Text style={[styles.title]}>
          { title }
        </Text>
        <Text style={[styles.description]}>
          { subTitle }
        </Text>
        <View style={[styles.formInput]}>
          <FormInput
            fieldId={'firstName'}
            placeholder={'First Name'}
            onChange={this._handleInputChange}
            itemStyles={[global.item]}
            inputStyles={[global.input]}
            errorMessage={errorMessages['firstName'] || ''}
          />
        </View>

        <View style={[styles.formInput]}>
          <FormInput
            fieldId={'lastName'}
            placeholder={'Last Name'}
            onChange={this._handleInputChange}
            itemStyles={[global.item]}
            inputStyles={[global.input]}
            errorMessage={errorMessages['lastName'] || ''}
          />
        </View>

        <View style={[styles.formInput]}>
          <FormInput
            fieldId={'email'}
            placeholder={'Email Address'}
            onChange={this._handleInputChange}
            itemStyles={[global.item]}
            inputStyles={[global.input]}
            errorMessage={errorMessages['email'] || ''}
          />
        </View>

        <View style={[styles.formInput]}>
          <FormInput
            fieldId={'phoneNumber'}
            placeholder={'Phone Number'}
            onChange={this._handleInputChange}
            itemStyles={[global.item]}
            inputStyles={[global.input]}
            errorMessage={errorMessages['phoneNumber'] || ''}
          />
        </View>

        <View style={[styles.formInput]}>

          <View>
            <Text style={[styles.labelText]}>
              Send Invite Link to User:
            </Text>
            <View style={[styles.checkboxContainer]}>
              <CheckBox
                fieldId={'emailInvite'}
                label={'Send Invite Link By Email'}
                onChange={this._handleInputChange}
              />
            </View>
            <View style={[styles.checkboxContainer]}>
              <CheckBox
                fieldId={'textInvite'}
                label={'Send Invite Link By Text'}
                onChange={this._handleInputChange}
              />
            </View>
          </View>
        </View>

        <View style={[styles.actionButtonContainer]}>
          {
            createFailed &&
              <Text style={[styles.errorMessage]}>
                { createError.message }
              </Text>
          }
          {
            <>
              {
                createInProgress ?
                  <Spinner color={'red'} />
                  :
                  <Button style={[global.confirmationButton, { marginBottom: 15 }]} onPress={this._handleCreate}>
                    <Text style={[global.confirmationButtonText]}>
                      { confirmButtonLabel }
                    </Text>
                  </Button>
              }
              <Button style={[global.cancelButton]} onPress={this._onCancel} disabled={createInProgress}>
                <Text style={[global.cancelButtonText]}>
                  CANCEL
                </Text>
              </Button>
            </>
          }
        </View>
      </>
    );
  }

  /**
   * Check to see if the invite/creation was a success.
   *
   * @param {*} prevProps previous this.props state
   */
  componentDidUpdate(prevProps) {
    const {
      createInProgress,
      createFailed,
    } = this.props;

    const {
      createInProgress: prevCreateInProgress,
    } = prevProps;

    if (prevCreateInProgress && !createInProgress && !createFailed) {
      this.setState({
        showSuccessScreen: true,
      });
    }
  }

  /**
   * Component Render
   */
  render() {
    const {
      createFailed,
      showSuccessScreen,
    } = this.state;

    return (
      <View style={[styles.addCrewMemberModalContent]}>
        { !showSuccessScreen ? this._renderCreateScreen() : this._renderSuccessScreen() }
        {
          createFailed &&
            <Text>There was an error adding your account. Refresh the page to try again.</Text>
        }
      </View>
    );
  }
}

CreateSystemUserModalContent.propTypes = {
  onCancel: PropTypes.func.isRequired,

  groupId: PropTypes.string.isRequired,

  create: PropTypes.func.isRequired,
  createInProgress: PropTypes.bool.isRequired,
  createFailed: PropTypes.bool.isRequired,
  createError: PropTypes.any.isRequired,

  confirmButtonLabel: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  isOwnerInvite: PropTypes.bool.isRequired,
};

export default connect(null, null)(CreateSystemUserModalContent);
