// React
import React from 'react';
import PropTypes from 'prop-types';

// UI Framework
import { TouchableOpacity, View, Text } from 'react-native';
import { Row } from 'react-native-easy-grid';

// Styles
import { styles } from '_components/device_card/SliderTicks.style';

/**
 * This component is used to put tick values next to a vertical slider
 * @param {*} props 
 *  tickCount - Number of tick marks to display
 *  min - Minimum value
 *  max - Maximum value
 *  onPress - Function called when tick value is pressed
 */
const SliderTicks = (props) => {
  const { tickCount, min, max, onPress } = props;

  // Figure out the value for each tick
  const tickSpan = Math.floor((max - min) / tickCount);
  const ticksVals = new Array(tickCount + 1).fill(null).map((x, i) => (min + i * tickSpan)).reverse();

  // Create an array of clickable ticks
  const ticks = ticksVals.map((item, i) => {
    let style = styles.tickRow;

    // Used to select different styles for the top and bottom ticks
    if (i === 0) {
      style = styles.tickRowTop;
    } else if (i === ticksVals.length) {
      style = styles.tickRowBottom;
    }

    return (
      <Row key={item}>
        <TouchableOpacity onPress={() => onPress(item)}>
          <View style={[style]}>
            <Text style={[styles.tickText]}>
              {item}
            </Text>
          </View>
        </TouchableOpacity>
      </Row>);
  });

  return (
    <>
      {ticks}
    </>
  );
};

SliderTicks.propTypes = {
  tickCount: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onPress: PropTypes.func.isRequired,
};

export default SliderTicks;
