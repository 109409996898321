import { StyleSheet } from "react-native";

export const styles2 = StyleSheet.create({
  rowRadius: {
    borderRadius: 12,
  },
  topLeftDiv: {
    padding: 0,
    borderTopLeftRadius: 12,
  },
  scrollDiv: {
    height: "54vh",
    marginTop: "2%",
    width: "100%",
  },
  validationText: {
    textAlign: "center",
    fontFamily: "Roboto",
    marginTop: 16,
    fontSize: 14,
    color: "#FF1818",
  },
  gridStyle: {
    flex: 1,
    maxWidth: "33.33%",
    marginTop: 6,
    flexDirection: "row",
    //backgroundColor: index % 2 === 0 ? "#000" : '#212121',
    padding: 1,
  },
  checkBoxView: {
    flexDirection: "row",
    width: "100%",
  },
  checkBoxStyles: {
    marginRight: -42,
    marginLeft: 6,
    position: "absolute",
    zIndex: 1,
    marginTop: 6,
    backgroundColor: "red",
    outlineColor: "white",
  },
  imageSize: {
    width: "90%",
    borderRadius: 2,
    height: 100,
  },
  w_100: {
    width: "100%",
  },
  h_100: {
    width: "100%",
    height: 600,
  },
  topRightDiv: {
    borderTopRightRadius: 12,
    // padding: "100px",
    // margin: "10px",
  },
  leftBottomDiv: {
    justifyContent: "center",
    borderBottomLeftRadius: 12,
  },
  rightBottomDiv: {
    flexWrap: "wrap",
    paddingLeft: -10,
    paddingBottom: "1%",
    borderBottomRightRadius: 12,
  },
  downDiv: {
    flex: 1,
    flexDirection: "row",
    paddingBottom: 40,
    marginTop: 0,
    backgroundColor: "#1A1A1A",
  },
  toolBox: {
    backgroundColor: "#1A1A1A",
    paddingHorizontal: 10,
    paddingLeft: 40,
    paddingVertical: 18,
  },
  mapCont: {
    backgroundColor: "#1A1A1A",
    // padding: 20,
    // border: "30px",
    paddingHorizontal: 10,
    paddingRight: 40,
    paddingVertical: 18,
  },
  imageBigSizePreview: {
    // padding: 2,
    resizeMode: "contain",
    width: "100%",
    // border: "20px",
    // padding: "20%",
    borderRadius: 5,
    marginTop: 10,
    height: "100%",
    alignSelf: "center",
  },
  imageBigSize: {
    // padding: 2,
    resizeMode: "contain",
    width: "90%",
    borderRadius: 5,
    marginTop: 10,
    height: "90%",
    alignSelf: "center",
  },
  lockButton: {
    width: 18,
    height: 26,
  },
  Thumbnailbtn: {
    marginTop: 4,
    marginLeft: 12,
    width: 20,
    height: 19,
  },
  emptyItem: {
    textAlignVertical: "center",
    marginTop: "30%",
    textAlign: "center",
    color: "#F5F5F5",
  },
  imageRenderItemsContainer: {
    flex: 1,
    width: "100%",
    marginTop: 6,
    flexDirection: "row",
    //backgroundColor: index % 2 === 0 ? "#000" : '#212121',
    padding: 1,
  },
  listRenderItemsContainer: {
    flex: 1,
    width: "96%",
    flexDirection: "row",
    padding: 10,
  },
  subRenderContainer: {
    flexDirection: "row",
    width: "100%",
  },
  imageNameText: {
    cursor: "pointer",
    fontSize: 12,
    marginLeft: 10,
    width: "80%",
  },
  mainColumn: {
    borderRadius: 10,
  },
  mainView: {
    flex: 1,
    justifyContent: "space-around",
    borderRadius: 12,
  },
  topHead: {
    flex: 1,
    flexDirection: "row",
    marginTop: 4,
    borderRadius: 8,
    justifyContent: "space-between",
  },
  topHeadSub1: {
    flex: 1,
    flexDirection: "row",
  },
  topHeadSub2: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  bottumRightRow: {
    marginTop: 0,
    justifyContent: "center",
    borderBottomRightRadius: 12,
  },
  bottomButton: {
    width: 100,
    borderRadius: 5,
    height: 40,
    marginTop: "1.3%",
    marginRight: "1.2%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#424242",
  },
  siteButtonText: {
    textAlign: "center",
    fontFamily: "Roboto",
    paddingLeft: 12,
    paddingRight: 12,
    color: "#FAFAFA",
    fontWeight: "700",
    fontSize: 16,
  },
  bottomButtonSmall: {
    width: 90,
    borderRadius: 5,
    height: 40,
    marginTop: "1%",
    marginRight: "1.2%",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#424242",
    color: "#FAFAFA",
  },
  siteButtonTextSmall: {
    textAlign: "center",
    fontFamily: "Roboto",
    paddingLeft: 12,
    paddingRight: 12,
    color: "#FAFAFA",
    fontWeight: "700",
    fontSize: 14,
  },
  siteButtonTextInact: {
    textAlign: "center",
    fontFamily: "Roboto",
    color: "#615F5F",
    fontWeight: "700",
    fontSize: 16,
  },
  downloaButton: {
    borderRadius: 5,
    height: 40,
    marginTop: 6,
    marginRight: 15,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#1A1A1A",
  },
  downloadButtonnImg: {
    width: 29,
    height: 31,
    marginRight: 6,
    color: "yellow",
  },
  checkbox: {
    backgroundColor: "#EEB310",
  },
  fullViewSwiper: {
    flex: 1,
    width: 950,
    height: 550,
    alignItems: "center",
  },
  fullViewSwiperDiv: {
    alignSelf: "center",
    paddingRight: 12,
    flex: 1,
    width: "99%",
    alignItems: "stretch",
  },
  fullViewSwiperText: {
    textAlign: "center",
    color: "white",
    marginBottom: 10,
  },
  imageContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    // height: "728px",
    paddingBottom: "42px",
  },
  leftSideBtn: {
    flex: 1,
    borderRadius: 20,
    backgroundColor: "#424242",
    justifyContent: "center",
    alignItmes: "center",
    width: 40,
    height: 40,
    marginLeft: 20,
  },
  rightSideBtn: {
    flex: 1,
    borderRadius: 20,
    backgroundColor: "#424242",
    justifyContent: "center",
    alignItmes: "center",
    width: 40,
    height: 40,
    marginLeft: -10,
    marginRight: 20,
  },
  sideBtnImg: {
    width: 13,
    height: 22,
  },
});
