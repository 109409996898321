// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// UI Framework
import { Image } from 'react-native';

// Styles
import { styles } from '_components/device_card/Indicator.style';

const DEFAULT_FLASH_RATE = 750; // 0.5 seconds
const DEFAULT_FLASH_TIME = 1000 * 60; // 1 minute

class Indicator extends Component {

  constructor(props) {
    super(props);

    this.flashInterval = null;
    this.flashTimeout = null;

    this._isFlashing = this._isFlashing.bind(this);
    this._startFlashing = this._startFlashing.bind(this);
    this._stopFlashing = this._stopFlashing.bind(this);

    this.state = {
      on: props.isOn,
    };

    if (props.isOn) {
      this._startFlashing();
    }
  }

  /**
   * Returns true if the indicator is flashing, false otherwise.
   */
  _isFlashing() {
    return this.flashInterval !== null && this.flashTimeout !== null;
  }

  /**
   * Clears the interval and timeouts that control the
   * flashing mechanism.
   */
  _stopFlashing() {
    clearInterval(this.flashInterval);
    clearTimeout(this.flashTimeout);

    this.flashInterval = null;
    this.flashTimeout = null;
  }

  /**
   * Start the flashing mechanism.
   */
  _startFlashing() {
    // Stop any current interval/timeout.
    if (this._isFlashing()) {
      this._stopFlashing();
    }

    const {
      flashRate = DEFAULT_FLASH_RATE,
      flashTime = DEFAULT_FLASH_TIME,
    } = this.props;

    // The Interval will flip the on/off state
    // for the flash effect.
    this.flashInterval = setInterval(() => {
      const { on } = this.state;
      this.setState({
        on: !on,
      });
    }, flashRate);

    // The timeout will kill the flashing after a certain time.
    this.flashTimeout = setTimeout(this._stopFlashing, flashTime);
  }

  /**
   * Clean up our intervals/timeouts if the component is unmounted.
   */
  componentWillUnmount() {
    this._stopFlashing();
  }

  /**
   * Need to check whether or not to start/stop
   * the flashing mechanism if the parent component changes
   * the isOn prop.
   *
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    const { isOn, flash = false } = this.props;
    const { isOn: prevIsOn } = prevProps;

    if (isOn !== prevIsOn) {

      this.setState({
        on: isOn,
      });

      // If the indicator is now on, and it's set to flash,
      // start the flashing mechanism.
      if (isOn && flash) {
        this._startFlashing();
      // If the indicator is now off, stop the flashing mechanism.
      } else if (!isOn) {
        this._stopFlashing();
      }
    }
  }

  render() {
    const { onImage, offImage } = this.props;
    const { on } = this.state;

    return (
      <>
        {
          on ?
            <Image
              style={ styles.indicatorIcon }
              source={ onImage }
            />
            :
            <Image
              style={ styles.indicatorIcon }
              source={ offImage }
            />
        }
      </>
    );
  }
}

Indicator.propTypes = {
  isOn: PropTypes.bool.isRequired,
  onImage: PropTypes.any.isRequired,
  offImage: PropTypes.any.isRequired,
  flash: PropTypes.bool,
  flashRate: PropTypes.number,
  flashTime: PropTypes.number,
};

export default connect(null, null)(Indicator);
