import { StyleSheet } from 'react-native';
import { yellow, dark_gray, textLight, green } from '_style/Global.style';

export const styles = StyleSheet.create({
  // Main
  main: {
    display: 'flex',
    height: '100%',
    width: '100%',
    backgroundColor: '#383838',
    padding: 25,
    overflow: 'auto',
  },
  content: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 25,
  },

  // Job List
  jobListContainer: {
    flex: 3,
    marginRight: 5,
    height: '100%',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  jobListItemsContainer: {
    width: '100%',
  },

  defaultButtonContainer: {
    width: '100%',
    marginTop: 50,
  },
  defaultButton: {
    backgroundColor: dark_gray,
    shadowColor: 'black',
    shadowRadius: 8,
    shadowOpacity: .5,
    shadowOffset: {width: 4, height: 4},
    elevation: 0.5,
    height: 64,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  defaultButtonText: {
    color: textLight,
    fontWeight: 'bold',
    fontSize: 36,
  },
  defaultButtonImage: {
    width: 30,
    height: 30,
  },


  // Job Details
  jobDetailsContainer: {
    flex: 8,
    marginLeft: 5,
    height: '100%',
  },
  jobDetailsTitle: {
    color: yellow,
    letterSpacing: 1.6,
    fontSize: 36,
    padding: 10,
    textTransform: 'uppercase',
  },
  jobDetailsHeaderRow: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
    borderBottomColor: dark_gray,
    borderBottomWidth: 1,
  },
  jobDetailsActionButtons: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
  },
  editButtonContainer: {
    marginBottom: 7,
    marginLeft: 15,
  },
  jobDetailsContentRow: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
    width: '100%',
    height: 400,
    overflow: 'auto',
  },
  jobDetailsDevicesContainer: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flex: 1,
    padding: 10,
  },
  jobDetailsDeviceRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingTop: 10,
    paddingBottom: 10,
  },
  jobDetailsCrewMembersContainer: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flex: 1,
    padding: 10,
  },
  arrowButtonContainer: {
    marginLeft: 10,
  },
  jobDetailsSubTitle: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: 21,
    letterSpacing: 1.6,
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: dark_gray,
    marginBottom: 10,
  },
  jobDetailsDevice: {
    width: '100%',
    fontSize: 18,
    color: textLight,
    letterSpacing: 1.6,
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 5,
    paddingLeft: 15,
    borderRadius: 2,
  },
  jobDetailsCrewMember: {
    width: '100%',
    fontSize: 18,
    color: textLight,
    letterSpacing: 1.6,
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 5,
    paddingLeft: 15,
    borderRadius: 2,
  },
  jobDetailsCrewLeader: {
    width: '100%',
    fontSize: 18,
    color: yellow,
    letterSpacing: 1.6,
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 5,
    paddingLeft: 15,
    borderRadius: 2,
  },
  jobDetailsButtonRow: {
    height: 'auto',
    width: '100%',
    marginTop: 15,
  },

  // Add Job Button
  addJobButtonContainer: {
    height: 'auto',
    width: '100%',
    marginTop: 15,
  },
  addJobButton: {
    height: 88,
  },
  addJobButtonText: {
    fontSize: 48,
  },
  viewDashboardButton: {
    height: 64,
    backgroundColor: green,
  },
  viewDashboardButtonText: {
    fontSize: 36,
    color: textLight,
  },

  // Modals


});
