// React
import React, { Component } from "react";
import PropTypes from "prop-types";
// Redux
import { connect } from "react-redux";
// UI Framework
import { Button, Text, View } from "native-base";
// Style
import { styles as global } from "_style/Global.style";
import { styles } from "./ArchiveDeleteModalContent.style";

class ArchiveDeleteModalContent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { deleteButtonText, archiveButtonText, confirmationText } =
      this.props;

    return (
      <View style={[styles.confirmRemovalModalContent]}>
        <Text style={[styles.confirmRemovalText]}>{confirmationText}</Text>
        <Button
          style={[global.cancelButton, styles.btnstyle]}
          onPress={() => this.props.onDelete()}
        >
          <Text style={[styles.cancelButtonText]}>{deleteButtonText}</Text>
        </Button>
        <Button
          style={[global.cancelButton, styles.btnstyle]}
          onPress={() => this.props.onArchive()}
        >
          <Text style={[styles.cancelButtonText, { fontSize: 18 }]}>
            {archiveButtonText}
          </Text>
        </Button>
      </View>
    );
  }
}

ArchiveDeleteModalContent.propTypes = {
  deleteButtonText: PropTypes.any,
  archiveButtonText: PropTypes.any,
  onArchive: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  confirmationText: PropTypes.any,
};

export default connect(null, null)(ArchiveDeleteModalContent);
