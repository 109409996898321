import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  main: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: '100%',
    width: '100%',
    padding: 25,
    overflow: 'auto',
    backgroundColor: '#383838',
  },
  notifications: {
  },
});
