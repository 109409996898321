import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

import { Image, TouchableOpacity, UIManager, findNodeHandle } from 'react-native';
import { Text, View } from 'native-base';

import { styles as global } from '_style/Global.style';
import { styles } from '_components/common/DropDown.style';

class DropDownItem extends Component {
  constructor(props) {
    super(props);

    this._selected = this._selected.bind(this);
  }

  _selected() {
    const { value, onSelect } = this.props;

    onSelect(value);
  }

  render() {
    const { selected, value } = this.props;
    return (
      <TouchableOpacity onPress={this._selected}>
        <Text
          key={value}
          style={[styles.dropDownItem, selected ? styles.dropDownItemSelected : {}]}
        >
          {value}
        </Text>
      </TouchableOpacity>
    );
  }
}

DropDownItem.propTypes = {
  value: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

/**
 * Form Input
 *
 * Common form input to keep styling and logic all in one place.
 */
class DropDown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      selectedValue: this.props.defaultValue || null,
    };

    this._registerDropdown = this._registerDropdown.bind(this);

    this._toggleOpen = this._toggleOpen.bind(this);
    this._onChange = this._onChange.bind(this);
  }

  /**
   *
   * @param {*} dropdown
   */
  _registerDropdown(dropdown) {
    this._dropdown = dropdown;
  }

  /**
   *
   */
  _toggleOpen() {
    const { open } = this.state;

    UIManager.measureInWindow(findNodeHandle(this._dropdown), (x, y, containerWidth, containerHeight) => {
      this.setState({
        open: !open,
        dropDownTop: 0 + containerHeight,
        dropDownLeft: 0,
        dropDownWidth: containerWidth,
      });
    });
  }

  /**
   *
   * @param {*} value
   */
  _onChange(value) {
    const { fieldId, onChange } = this.props;
    this._toggleOpen();
    this.setState({
      selectedValue: value,
    });
    onChange(fieldId, value);
  }

  componentDidUpdate(prevProps) {
    const { defaultValue } = this.props;
    const { defaultValue: prevDefaultValue } = prevProps;

    if (defaultValue !== prevDefaultValue) {
      this.setState({
        selectedValue: defaultValue,
      });
    }
  }
  componentDidMount() {

  }

  render() {
    const {
      floatingLabel,
      values,
      inlineError = true,
      errorMessage,
    } = this.props;

    const {
      open,
      selectedValue,
      dropDownTop = 0,
      dropDownLeft = 0,
      dropDownWidth = 100,
    } = this.state;

    const dropDownRows = [];

    values.forEach((value) => {
      dropDownRows.push((
        <DropDownItem
          key={value}
          value={value}
          selected={value === selectedValue}
          onSelect={this._onChange}
        />
      ));
    });

    const dropDownValuesTop = floatingLabel ? 70 : 40;

    return (
      <View style={[styles.mainContainer]} ref={this._registerDropdown} >
        {
          floatingLabel &&
            <Text style={[styles.floatingLabel]}>{ floatingLabel }</Text>
        }
        <TouchableOpacity onPress={this._toggleOpen}>
          <View style={[styles.dropDownContainer]}>
            {
              !selectedValue ?
                <Text style={[styles.placeholderValue, global.fontSF]}>
                  ......
                </Text>
                :
                <Text style={[styles.selectedValue, global.fontSF]}>
                  { selectedValue }
                </Text>
            }
            <Image
              source={require('_assets/images/dropdown-arrow.png')}
              style={[styles.dropdownArrow]}
            />
          </View>
        </TouchableOpacity>
        {
          open &&
            <View style={[
              styles.dropDownValuesContainer,
              { top: dropDownTop, left: dropDownLeft, width: dropDownWidth },
            ]}>
              { dropDownRows }
            </View>
        }
        {
          inlineError &&
            <Text style={[
              styles.errorMessage,
              { top: dropDownValuesTop },
            ]}>
              { errorMessage }
            </Text>
        }
      </View>
    );
  }
}

DropDown.propTypes = {
  defaultValue: PropTypes.string,
  errorMessage: PropTypes.string,
  fieldId: PropTypes.string.isRequired,
  floatingLabel: PropTypes.string,
  inlineError: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.array.isRequired,
};

export default connect(null, null)(DropDown);
