const actionPrefix = 'app/admin';

export const CLEAR_STATE = `${actionPrefix}/CLEAR_STATE`;

export const GET_GROUP_REQUEST = `${actionPrefix}/GET_GROUP_REQUEST`;
export const GET_GROUP_SUCCESS = `${actionPrefix}/GET_GROUP_SUCCESS`;
export const GET_GROUP_FAILURE = `${actionPrefix}/GET_GROUP_FAILURE`;

export const GET_GROUP_CHILDREN_REQUEST = `${actionPrefix}/GET_GROUP_CHILDREN_REQUEST`;
export const GET_GROUP_CHILDREN_SUCCESS = `${actionPrefix}/GET_GROUP_CHILDREN_SUCCESS`;
export const GET_GROUP_CHILDREN_FAILURE = `${actionPrefix}/GET_GROUP_CHILDREN_FAILURE`;

export const CLEAR_GROUP_CHILDREN_FOR_ASSIGNMENT = `${actionPrefix}/CLEAR_GROUP_CHILDREN_FOR_ASSIGNMENT`;
export const GET_GROUP_CHILDREN_FOR_ASSIGNMENT_REQUEST = `${actionPrefix}/GET_GROUP_CHILDREN_FOR_ASSIGNMENT_REQUEST`;
export const GET_GROUP_CHILDREN_FOR_ASSIGNMENT_SUCCESS = `${actionPrefix}/GET_GROUP_CHILDREN_FOR_ASSIGNMENT_SUCCESS`;
export const GET_GROUP_CHILDREN_FOR_ASSIGNMENT_FAILURE = `${actionPrefix}/GET_GROUP_CHILDREN_FOR_ASSIGNMENT_FAILURE`;

export const GET_GROUP_USERS_REQUEST = `${actionPrefix}/GET_GROUP_USERS_REQUEST`;
export const GET_GROUP_USERS_SUCCESS = `${actionPrefix}/GET_GROUP_USERS_SUCCESS`;
export const GET_GROUP_USERS_FAILURE = `${actionPrefix}/GET_GROUP_USERS_FAILURE`;

export const ADD_GROUP_REQUEST = `${actionPrefix}/ADD_GROUP_REQUEST`;
export const ADD_GROUP_SUCCESS = `${actionPrefix}/ADD_GROUP_SUCCESS`;
export const ADD_GROUP_FAILURE = `${actionPrefix}/ADD_GROUP_FAILURE`;

export const UPDATE_GROUP_REQUEST = `${actionPrefix}/UPDATE_GROUP_REQUEST`;
export const UPDATE_GROUP_SUCCESS = `${actionPrefix}/UPDATE_GROUP_SUCCESS`;
export const UPDATE_GROUP_FAILURE = `${actionPrefix}/UPDATE_GROUP_FAILURE`;

export const INVITE_SYSTEM_USER_REQUEST = `${actionPrefix}/INVITE_SYSTEM_USER_REQUEST`;
export const INVITE_SYSTEM_USER_SUCCESS = `${actionPrefix}/INVITE_SYSTEM_USER_SUCCESS`;
export const INVITE_SYSTEM_USER_FAILURE = `${actionPrefix}/INVITE_SYSTEM_USER_FAILURE`;

export const ADD_SYSTEM_USER_REQUEST = `${actionPrefix}/ADD_SYSTEM_USER_REQUEST`;
export const ADD_SYSTEM_USER_SUCCESS = `${actionPrefix}/ADD_SYSTEM_USER_SUCCESS`;
export const ADD_SYSTEM_USER_FAILURE = `${actionPrefix}/ADD_SYSTEM_USER_FAILURE`;

export const REMOVE_SYSTEM_USER_REQUEST = `${actionPrefix}/REMOVE_SYSTEM_USER_REQUEST`;
export const REMOVE_SYSTEM_USER_SUCCESS = `${actionPrefix}/REMOVE_SYSTEM_USER_SUCCESS`;
export const REMOVE_SYSTEM_USER_FAILURE = `${actionPrefix}/REMOVE_SYSTEM_USER_FAILURE`;

export const CHECK_SYSTEM_USER_ELIGIBILITY_REQUEST = `${actionPrefix}/CHECK_SYSTEM_USER_ELIGIBILITY_REQUEST`;
export const CHECK_SYSTEM_USER_ELIGIBILITY_SUCCESS = `${actionPrefix}/CHECK_SYSTEM_USER_ELIGIBILITY_SUCCESS`;
export const CHECK_SYSTEM_USER_ELIGIBILITY_FAILURE = `${actionPrefix}/CHECK_SYSTEM_USER_ELIGIBILITY_FAILURE`;

export const SEARCH_DEVICES_REQUEST = `${actionPrefix}/SEARCH_DEVICES_REQUEST`;
export const SEARCH_DEVICES_SUCCESS = `${actionPrefix}/SEARCH_DEVICES_SUCCESS`;
export const SEARCH_DEVICES_FAILURE = `${actionPrefix}/SEARCH_DEVICES_FAILURE`;

export const SUSPEND_DEVICE_REQUEST = `${actionPrefix}/SUSPEND_DEVICE_REQUEST`;
export const SUSPEND_DEVICE_SUCCESS = `${actionPrefix}/SUSPEND_DEVICE_SUCCESS`;
export const SUSPEND_DEVICE_FAILURE = `${actionPrefix}/SUSPEND_DEVICE_FAILURE`;

export const ASSIGN_DEVICE_REQUEST = `${actionPrefix}/ASSIGN_DEVICE_REQUEST`;
export const ASSIGN_DEVICE_SUCCESS = `${actionPrefix}/ASSIGN_DEVICE_SUCCESS`;
export const ASSIGN_DEVICE_FAILURE = `${actionPrefix}/ASSIGN_DEVICE_FAILURE`;

export const CHECK_AND_ADD_IN_PROGRESS = `${actionPrefix}/CHECK_AND_ADD_IN_PROGRESS`;
export const CHECK_AND_ADD_COMPLETE = `${actionPrefix}/CHECK_AND_ADD_COMPLETE`;

export const SEARCH_GROUP_CHILDREN_REQUEST = `${actionPrefix}/SEARCH_GROUP_CHILDREN_REQUEST`;
export const SEARCH_GROUP_CHILDREN_SUCCESS = `${actionPrefix}/SEARCH_GROUP_CHILDREN_SUCCESS`;
export const SEARCH_GROUP_CHILDREN_FAILURE = `${actionPrefix}/SEARCH_GROUP_CHILDREN_FAILURE`;

export const SEARCH_GROUP_USERS_REQUEST = `${actionPrefix}/SEARCH_GROUP_USERS_REQUEST`;
export const SEARCH_GROUP_USERS_SUCCESS = `${actionPrefix}/SEARCH_GROUP_USERS_SUCCESS`;
export const SEARCH_GROUP_USERS_FAILURE = `${actionPrefix}/SEARCH_GROUP_USERS_FAILURE`;
