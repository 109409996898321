import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  confirmRemovalModalContent: {
    width: 425,
    marginLeft: 75,
    marginRight: 75,
  },
  confirmRemovalText: {
    textAlign: "left",
    fontSize: 18,
    fontWeight: "400",
    marginBottom: 14,
    marginTop: 34,
    letterSpacing: 1.6,
  },
  mailrow: {
    flexDirection: "row",
    justifyContent: "space-around",
    backgroundColor: "#1A1A1A",
    borderRadius: 10,
    padding: 8,
  },
  btnText: {
    marginTop: 3,
    marginBottom: 12,
    color: "white",
    fontSize: 15,
    textAlign: "center",
  },
  InputCol: {
    flexDirection: "column",
  },
  input: {
    width: "100%",
    height: 41,
    padding: 4,
    color: "#FAFAFA",
    backgroundColor: "#1A1A1A",
    border: 1,
    marginTop: 2,
    marginRight: 8,
    borderRadius: 4,
  },
  btnTop: {
    marginTop: 15,
    marginBottom: 15,
    backgroundColor: "transparent",
  },
  buttonImg: {
    width: 45,
    height: 41,
    alignItems: "center",
  },
});
