import { StyleSheet } from "react-native";
import { red, yellow } from "_style/Global.style";

// Common text properties for any text that goes below the
// form input,
const subText = {
  fontSize: 12,
  marginLeft: 15,
  padding: 5,
};

export const styles = StyleSheet.create({
  inputContainer: {
    width: "100%",
  },
  formTextarea: {
    height: 150,
    padding: 20,
  },
  formTextarea2: {
    height: 80,
    padding: 20,
  },
  formTextarea3: {
    height: 850,
    padding: 10,
  },
  floatingLabel: {
    padding: 5,
    fontSize: 14,
  },
  errorMessage: {
    ...subText,
    color: red,
  },
  helpText: {
    ...subText,
    color: yellow,
  },
  showHideIconContainer: {
    position: "absolute",
    top: 15,
    right: -50,
  },
  showPasswordIcon: {
    height: 31,
    width: 38,
  },
  hidePasswordIcon: {
    height: 30,
    width: 30,
  },
});
