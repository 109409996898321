import { StyleSheet } from 'react-native';
import {
  textLight,
  robotoCondensed,
} from '_style/Global.style';

export const styles = StyleSheet.create({
  engineStateText: {
    color: textLight,
    fontFamily: robotoCondensed,
    fontSize: 18,
    width: 120,
    textAlign: 'center',
  },
});
