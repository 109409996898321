
import { createReducer } from '_redux/rootActions';

// Types
import {
  CLEAR_STATE,
  CLEAR_USER_DEVICES,
  CLEAR_REGISTER_STATE,

  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE,

  GET_USER_INFO_REQUEST,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_FAILURE,

  REGISTER_DEVICE_REQUEST,
  REGISTER_DEVICE_SUCCESS,
  REGISTER_DEVICE_FAILURE,

  UNREGISTER_DEVICE_REQUEST,
  UNREGISTER_DEVICE_SUCCESS,
  UNREGISTER_DEVICE_FAILURE,

  GET_USER_DEVICES_REQUEST,
  GET_USER_DEVICES_SUCCESS,
  GET_USER_DEVICES_FAILURE,

  GET_USER_NOTIFICATIONS_REQUEST,
  GET_USER_NOTIFICATIONS_SUCCESS,
  GET_USER_NOTIFICATIONS_FAILURE,

  DELETE_USER_NOTIFICATION_REQUEST,
  DELETE_USER_NOTIFICATION_SUCCESS,
  DELETE_USER_NOTIFICATION_FAILURE,

  SAVE_USER_DEVICE_CARD_SETTINGS_REQUEST,
  SAVE_USER_DEVICE_CARD_SETTINGS_SUCCESS,
  SAVE_USER_DEVICE_CARD_SETTINGS_FAILURE,

  UPDATE_USER_PROFILE_REQUEST,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAILURE,

  GET_INVITE_REQUEST,
  GET_INVITE_SUCCESS,
  GET_INVITE_FAILURE,

} from '_redux/user/types';

const initialState = {
  deletingUserNotificationId: '',
  isRegistering: false,
  isRegisteringDevice: false,
  isUnregisteringDevice: false,
  isFetchingDevices: false,
  isFetchingInvite: false,
  isFetchingUserInfo: false,
  isFetchingNotifications: false,
  isDeletingNotification: false,
  isSavingUserDeviceCardSettings: {},
  isUpdatingUserProfile: false,
  registerDeviceFailed: false,
  registerDeviceErrorMessage: '',
  userInfo: {},
  devices: [],
  notifications: [],
  invite: null,
};

export default createReducer(initialState, {
  // CLEAR STATE
  [CLEAR_STATE]: (state) => state,
  [CLEAR_USER_DEVICES]: (state) => {
    return { ...state, devices: [] };
  },
  [CLEAR_REGISTER_STATE]: (state) => {
    return {
      ...state,
      isRegisteringDevice: false,
      registerDeviceFailed: false,
      registerDeviceErrorMessage: '',
    };
  },

  // REGISTER DEVICE
  [REGISTER_DEVICE_REQUEST]: (state) => {
    return {
      ...state,
      isRegisteringDevice: true,
      registerDeviceFailed: false,
      registerDeviceErrorMessage: '',
    };
  },
  [REGISTER_DEVICE_SUCCESS]: (state) => {
    return {
      ...state,
      isRegisteringDevice: false,
      registerDeviceFailed: false,
      registerDeviceErrorMessage: '',
    };
  },
  [REGISTER_DEVICE_FAILURE]: (state, action) => {
    const { error } = action;
    return {
      ...state,
      isRegisteringDevice: false,
      registerDeviceFailed: true,
      registerDeviceErrorMessage: error.message,
    };
  },

  // CONNECT DEVICE
  [REGISTER_USER_REQUEST]: (state) => {
    return {...state, isRegistering: true };
  },
  [REGISTER_USER_SUCCESS]: (state) => {
    return {...state, isRegistering: false };
  },
  [REGISTER_USER_FAILURE]: (state) => {
    return {...state, isRegistering: false };
  },

  // GET USER DEVICES
  [GET_USER_DEVICES_REQUEST]: (state) => {
    return {...state, isFetchingDevices: true };
  },
  [GET_USER_DEVICES_SUCCESS]: (state, action) => {
    const { devices } = action;
    return {...state, isFetchingDevices: false, devices };
  },
  [GET_USER_DEVICES_FAILURE]: (state) => {
    return {...state, isFetchingDevices: false };
  },

  // UNREGISTER DEVICE
  [UNREGISTER_DEVICE_REQUEST]: (state) => {
    return {...state, isUnregisteringDevice: true };
  },
  [UNREGISTER_DEVICE_SUCCESS]: (state) => {
    return {...state, isUnregisteringDevice: false };
  },
  [UNREGISTER_DEVICE_FAILURE]: (state) => {
    return {...state, isUnregisteringDevice: false };
  },

  // GET USER INFO
  [GET_USER_INFO_REQUEST]: (state) => {
    return {...state, isFetchingUserInfo: true };
  },
  [GET_USER_INFO_SUCCESS]: (state, action) => {
    const { userInfo } = action;
    return {...state, isFetchingUserInfo: false, userInfo };
  },
  [GET_USER_INFO_FAILURE]: (state) => {
    return {...state, isFetchingUserInfo: false };
  },

  // SAVE USER DEVICE CARD SETTINGS
  [SAVE_USER_DEVICE_CARD_SETTINGS_REQUEST]: (state, action) => {
    const { deviceId } = action;
    return {...state, isSavingUserDeviceCardSettings: { [deviceId]: true } };
  },
  [SAVE_USER_DEVICE_CARD_SETTINGS_SUCCESS]: (state, action) => {
    const { deviceId } = action;
    return {...state, isSavingUserDeviceCardSettings: { [deviceId]: false } };
  },
  [SAVE_USER_DEVICE_CARD_SETTINGS_FAILURE]: (state, action) => {
    const { deviceId } = action;
    return {...state, isSavingUserDeviceCardSettings: { [deviceId]: false } };
  },

  // GET USER NOTIFICATIONS
  [GET_USER_NOTIFICATIONS_REQUEST]: (state) => {
    return {...state, isFetchingNotifications: true };
  },
  [GET_USER_NOTIFICATIONS_SUCCESS]: (state, action) => {
    const { notifications } = action;
    return {...state, isFetchingNotifications: false, notifications };
  },
  [GET_USER_NOTIFICATIONS_FAILURE]: (state) => {
    return {...state, isFetchingNotifications: false };
  },

  // DELETE USER NOTIFICATION
  [DELETE_USER_NOTIFICATION_REQUEST]: (state, action) => {
    const { notificationId } = action;

    return {...state, isDeletingNotification: true, deletingUserNotificationId: notificationId};
  },
  [DELETE_USER_NOTIFICATION_SUCCESS]: (state, action) => {
    const { notificationId } = action;
    const notificationIndex = state.notifications.findIndex((notification) => notification.notification_id === notificationId);
    const newNotifications = state.notifications.filter((item, index) => index !== notificationIndex);

    return {...state, notifications: newNotifications, isDeletingNotification: false };
  },
  [DELETE_USER_NOTIFICATION_FAILURE]: (state) => {
    return {...state, isDeletingNotification: false, deletingUserNotificationId: '' };
  },

  // UPDATE USER PROFILE
  [UPDATE_USER_PROFILE_REQUEST]: (state) => {
    return {...state, isUpdatingUserProfile: true };
  },
  [UPDATE_USER_PROFILE_SUCCESS]: (state) => {
    return {...state, isUpdatingUserProfile: false };
  },
  [UPDATE_USER_PROFILE_FAILURE]: (state) => {
    return {...state, isUpdatingUserProfile: false };
  },

  // GET INVITE
  [GET_INVITE_REQUEST]: (state) => {
    return {...state, isFetchingInvite: true };
  },
  [GET_INVITE_SUCCESS]: (state, action) => {
    const { invite } = action;
    return {...state, isFetchingInvite: false, invite };
  },
  [GET_INVITE_FAILURE]: (state) => {
    return {...state, isFetchingInvite: false };
  },
});
