import { API, Auth } from 'aws-amplify';
import { CREW_MEMBERS_API_ID } from '_config/api-constants';
import amplifyConfig from '_config/_aws-exports.js';
import { makeActionCreator } from '_redux/rootActions';

import { getUserCognitoSub } from '_redux/user/utils';

import {
  CLEAR_STATE,
  CLEAR_API_STATE,

  INVITE_CREW_MEMBER_REQUEST,
  INVITE_CREW_MEMBER_SUCCESS,
  INVITE_CREW_MEMBER_FAILURE,

  ADD_CREW_MEMBER_REQUEST,
  ADD_CREW_MEMBER_SUCCESS,
  ADD_CREW_MEMBER_FAILURE,

  GET_CREW_MEMBERS_REQUEST,
  GET_CREW_MEMBERS_SUCCESS,
  GET_CREW_MEMBERS_FAILURE,

  REMOVE_CREW_MEMBER_REQUEST,
  REMOVE_CREW_MEMBER_SUCCESS,
  REMOVE_CREW_MEMBER_FAILURE,

  UPDATE_CREW_MEMBER_REQUEST,
  UPDATE_CREW_MEMBER_SUCCESS,
  UPDATE_CREW_MEMBER_FAILURE,

  CHECK_CREW_MEMBER_ELIGIBILITY_REQUEST,
  CHECK_CREW_MEMBER_ELIGIBILITY_SUCCESS,
  CHECK_CREW_MEMBER_ELIGIBILITY_FAILURE,

  LEAVE_AND_ADD_IN_PROGRESS,
  LEAVE_AND_ADD_COMPLETE,

  CHECK_AND_ADD_IN_PROGRESS,
  CHECK_AND_ADD_COMPLETE,
} from '_redux/crewMembers/types';

export const clearState = makeActionCreator(CLEAR_STATE);
export const clearAPIState = makeActionCreator(CLEAR_API_STATE);

// Operation progress actions.
export const checkAndAddInProgress = makeActionCreator(CHECK_AND_ADD_IN_PROGRESS);
export const checkAndAddComplete = makeActionCreator(CHECK_AND_ADD_COMPLETE);

export const leaveAndAddInProgress = makeActionCreator(LEAVE_AND_ADD_IN_PROGRESS);
export const leaveAndAddComplete = makeActionCreator(LEAVE_AND_ADD_COMPLETE);

/**
 * Get the crew members for this user.
 */
export const getCrewMembers = (cognitoSub) => {
  return async (dispatch) => {
    dispatch(getCrewMembersRequest());

    try {
      if (!cognitoSub) {
        cognitoSub = await getUserCognitoSub();
      }

      const crewMembers = await API.get(
        CREW_MEMBERS_API_ID,
        `/user/${encodeURIComponent(cognitoSub)}/crews/-1/crewmembers`
      );

      dispatch(getCrewMembersSuccess(crewMembers));

    } catch (e) {
      console.error(e);
      dispatch(getCrewMembersFailure());
    }
  };
};
export const getCrewMembersRequest = makeActionCreator(GET_CREW_MEMBERS_REQUEST);
export const getCrewMembersSuccess = makeActionCreator(GET_CREW_MEMBERS_SUCCESS, 'crewMembers');
export const getCrewMembersFailure = makeActionCreator(GET_CREW_MEMBERS_FAILURE);

/**
 * Get the crew members for this user.
 */
export const inviteCrewMember = (crewMemberInfo) => {
  return async (dispatch) => {
    dispatch(inviteCrewMemberRequest());

    try {
      const userInfo = await Auth.currentUserInfo();

      crewMemberInfo.ownerUsername = userInfo.username;
      crewMemberInfo.userPoolId = amplifyConfig.Auth.userPoolId;

      const crewMembers = await API.post(
        CREW_MEMBERS_API_ID,
        `/invite/${encodeURIComponent(userInfo.attributes.sub)}/crews/-1`,
        { body: crewMemberInfo },
      );

      dispatch(inviteCrewMemberSuccess(crewMembers));

    } catch (e) {
      console.error(e);
      dispatch(inviteCrewMemberFailure(e.response.data));
    }
  };
};
export const inviteCrewMemberRequest = makeActionCreator(INVITE_CREW_MEMBER_REQUEST);
export const inviteCrewMemberSuccess = makeActionCreator(INVITE_CREW_MEMBER_SUCCESS);
export const inviteCrewMemberFailure = makeActionCreator(INVITE_CREW_MEMBER_FAILURE, 'error');

/**
 * Remove a crew member from their crew.
 */
export const removeCrewMember = (cognitoSub, username) => {
  return async (dispatch) => {
    try {
      if (!cognitoSub) {
        cognitoSub = await getUserCognitoSub();
      }
      if (!username) {
        const userInfo = await Auth.currentUserInfo();
        username = userInfo.username;
      }

      dispatch(removeCrewMemberRequest(cognitoSub));


      await API.del(
        CREW_MEMBERS_API_ID,
        `/crews/-1/user/${encodeURIComponent(cognitoSub)}`,
        {
          body: {
            userPoolId: amplifyConfig.Auth.userPoolId,
            username,
          }
        }
      );

      dispatch(removeCrewMemberSuccess(cognitoSub));

    } catch (e) {
      console.error(e);
      dispatch(removeCrewMemberFailure(e.response.data));
    }
  };
};
export const removeCrewMemberRequest = makeActionCreator(REMOVE_CREW_MEMBER_REQUEST, 'cognitoSub');
export const removeCrewMemberSuccess = makeActionCreator(REMOVE_CREW_MEMBER_SUCCESS, 'cognitoSub');
export const removeCrewMemberFailure = makeActionCreator(REMOVE_CREW_MEMBER_FAILURE, 'error');

/**
 * Get the crew members for this user.
 */
export const updateCrewMember = (cognitoSub, username, isLeader) => {
  return async (dispatch) => {
    dispatch(updateCrewMemberRequest(cognitoSub));

    try {

      await API.put(
        CREW_MEMBERS_API_ID,
        `/crews/-1/user/${encodeURIComponent(cognitoSub)}`,
        { body: {
            isLeader,
            username,
            userPoolId: amplifyConfig.Auth.userPoolId
          }
        },
      );

      dispatch(updateCrewMemberSuccess(cognitoSub, isLeader));

    } catch (e) {
      console.error(e);
      dispatch(updateCrewMemberFailure());
    }
  };
};
export const updateCrewMemberRequest = makeActionCreator(UPDATE_CREW_MEMBER_REQUEST, 'cognitoSub');
export const updateCrewMemberSuccess = makeActionCreator(UPDATE_CREW_MEMBER_SUCCESS, 'cognitoSub', 'isLeader');
export const updateCrewMemberFailure = makeActionCreator(UPDATE_CREW_MEMBER_FAILURE, 'error');

/**
 * Get the crew members for this user.
 */
export const checkCrewMemberEligibility = (cognitoSub) => {
  return async (dispatch) => {
    dispatch(checkCrewMemberEligibilityRequest());

    try {
      // If a cognito sub wasn't passed in, assume the call is for the current user.
      if (!cognitoSub) {
        cognitoSub = await getUserCognitoSub();
      }

      const response = await API.get(CREW_MEMBERS_API_ID, `/users/${encodeURIComponent(cognitoSub)}/crewMemberEligible`);
      const { error } = response;

      dispatch(checkCrewMemberEligibilitySuccess(error));

    } catch (e) {
      console.error(e);
      dispatch(checkCrewMemberEligibilityFailure());
    }
  };
};
export const checkCrewMemberEligibilityRequest = makeActionCreator(CHECK_CREW_MEMBER_ELIGIBILITY_REQUEST);
export const checkCrewMemberEligibilitySuccess = makeActionCreator(CHECK_CREW_MEMBER_ELIGIBILITY_SUCCESS, 'error');
export const checkCrewMemberEligibilityFailure = makeActionCreator(CHECK_CREW_MEMBER_ELIGIBILITY_FAILURE);

/**
 * Get the crew members for this user.
 */
export const addCrewMember = (ownerCognitoSub, userCognitoSub, isLeader, userUserName) => {
  return async (dispatch) => {
    dispatch(addCrewMemberRequest());

    try {
      if (!userCognitoSub) {
        userCognitoSub = await getUserCognitoSub();
      }
      const { id: cognitoId } = await Auth.currentUserInfo();
      const crewMemberInfo = {
        isLeader,
        userPoolId: amplifyConfig.Auth.userPoolId,
        userCognitoId: cognitoId,
        userUserName: userUserName,
      };

      await API.post(
        CREW_MEMBERS_API_ID,
        `/user/${ownerCognitoSub}/crews/-1/crewmembers/${userCognitoSub}`,
        { body: crewMemberInfo },
      );

      dispatch(addCrewMemberSuccess());

    } catch (e) {
      console.error(e);
      dispatch(addCrewMemberFailure(e.response.data));
    }
  };
};
export const addCrewMemberRequest = makeActionCreator(ADD_CREW_MEMBER_REQUEST);
export const addCrewMemberSuccess = makeActionCreator(ADD_CREW_MEMBER_SUCCESS);
export const addCrewMemberFailure = makeActionCreator(ADD_CREW_MEMBER_FAILURE, 'error');
