// React
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";

import { TouchableOpacity } from "react-native";
import { Text, View } from "native-base";

// Style
import { styles } from "_components/common/CheckBox.style";

class CheckBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      on: undefined,
      touched: false,
    };

    this._toggleCheckbox = this._toggleCheckbox.bind(this);
  }

  /**
   *
   */
  _toggleCheckbox() {
    const { onChange, fieldId, checked, data } = this.props;
    const { on, touched } = this.state;
    let toggled = !touched ? !checked : !on;

    this.setState({
      on: toggled,
      touched: true,
    });

    onChange(fieldId, toggled, data || {});
  }

  componentDidUpdate(prevProps) {
    const { checked } = this.props;
    const { checked: prevChecked } = prevProps;

    if (checked !== prevChecked) {
      this.setState({
        on: checked,
        touched: false,
      });
    }
  }

  render() {
    const {
      label = "",
      labelColor = "#FAFAFA",
      labelStyle = {},
      checked = false,
      width = 32,
    } = this.props;

    const { on = checked } = this.state;

    const height = width;
    const dotHeight = height / 2;
    const dotWidth = height / 2;
    const borderRadius = dotHeight / 2;

    return (
      <View style={[styles.main]}>
        <TouchableOpacity onPress={this._toggleCheckbox}>
          <View style={[styles.checkbox, { width, height }]}>
            {on && (
              <View
                style={[
                  styles.dot,
                  { height: dotHeight, width: dotWidth, borderRadius },
                ]}
              />
            )}
          </View>
        </TouchableOpacity>
        <View style={[]}>
          <Text style={[styles.label, { color: labelColor }, labelStyle]}>
            {label}
          </Text>
        </View>
      </View>
    );
  }
}

CheckBox.propTypes = {
  fieldId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  labelColor: PropTypes.string,
  labelStyle: PropTypes.any,
  checked: PropTypes.bool,
  width: PropTypes.number,
  data: PropTypes.any,
};

export default connect(null, null)(CheckBox);
