import {
  JOBS_API_ID,
  DEVICES_API_ID,
  FEEDBACK_API_ID,
  USERS_API_ID,
  GROUPS_API_ID,
  CREW_MEMBERS_API_ID,
  SITE_MAP_API_ID,
} from '_config/api-constants';

export const DISABLE_REDUX_LOGS = false;

export default {
  Auth: {
    // Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: 'us-east-2:e3325aec-cac5-4c29-844e-b262d935434b',

    // Amazon Cognito Region
    region: 'us-east-2',

    // Amazon Cognito User Pool ID
    userPoolId: 'us-east-2_Y0uZ5cst6',

    // Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '6sgmer3kv9orebsbh1nplku7sd',

    oauth: {
      domain: 'test-bazooka-farmstar.auth.us-east-2.amazoncognito.com',
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: 'https://test.farmstar-ecs.com',
      redirectSignOut: 'https://test.farmstar-ecs.com',
      responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
  API: {
    endpoints: [
      {
        name: JOBS_API_ID,
        endpoint: "https://fm95pi0y4i.execute-api.us-east-2.amazonaws.com/test/v1",
        region: "us-east-2",
      },
      {
        name: DEVICES_API_ID,
        endpoint: "https://7fha1wugbj.execute-api.us-east-2.amazonaws.com/test/v1",
        region: "us-east-2",
      },
      {
        name: FEEDBACK_API_ID,
        endpoint: "https://bgoxykb427.execute-api.us-east-2.amazonaws.com/test/v1",
        region: "us-east-2",
      },
      {
        name: USERS_API_ID,
        endpoint: "https://kmts2n6e45.execute-api.us-east-2.amazonaws.com/test/v1",
        region: "us-east-2",
      },
      {
        name: GROUPS_API_ID,
        endpoint: "https://gi03pokbbc.execute-api.us-east-2.amazonaws.com/test/v1",
        region: "us-east-2",
      },
      {
        name: CREW_MEMBERS_API_ID,
        endpoint: "https://a1p58ngysg.execute-api.us-east-2.amazonaws.com/test/v1",
        region: "us-east-2",
      },
      {
        name: SITE_MAP_API_ID,
        endpoint: "https://6fuxwiw3ch.execute-api.us-east-2.amazonaws.com/test/v1",
        region: "us-east-2",
      },
    ]
  },
  PubSub: {
    aws_pubsub_region: "us-east-2",
    aws_pubsub_endpoint: "wss://a2zr17itrl5ti6-ats.iot.us-east-2.amazonaws.com/mqtt",
  },
};
