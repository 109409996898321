import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    transparent: "true",
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#212121",
    borderRadius: 10,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "20%",
    marginLeft: "46%",
  },
  modalHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "80%",
  },
  modalTitle: {
    color: "#FAFAFA",
    fontSize: 24,
    fontWeight: "bold",
    letterSpacing: 1.6,
    paddingLeft: 25,
    paddingRight: 25,
    marginLeft: 25,
    marginRight: 25,
    borderBottomWidth: 2,
    borderBottomColor: "#FAFAFA",
    width: "100%",
    maxWidth: 475,
    textAlign: "center",
  },
  modalSubTitle: {
    color: "#FAFAFA",
    fontSize: 18,
    fontFamily: "Roboto Condensed",
    margin: 20,
    letterSpacing: 1.6,
    maxWidth: 500,
    textAlign: "center",
  },
  exitIconContainer: {
    alignSelf: "flex-end",
  },
  exitIcon: {
    width: 43,
    height: 43,
  },
  content: {
    padding: 0,
  },
});
