import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  iconStyle: {
    width: 16,
    height: 10,
    marginRight:5,
    marginTop:6,
  },
  iconStyleSmall: {
    width: 12,
    height: 12,
  },
  buttonWrapper : {
    backgroundColor: '#424242',
    borderRadius: 5,
    justifyContent:'center',
    height:29,
    flexDirection:'row',
    alignContent:'center',
    alignItems:'center',
    width: 'fit-content',
    margin: 5,
    padding: 10,
  },
  buttonLabel :{
    color:'#FAFAFA',
    textTransform: 'uppercase',
    alignItems:'center',
    fontSize:12,
    marginLeft:6,
    fontWeight:"bold",
    whiteSpace: "nowrap",
  },
  textWrapper :{
    alignItems:'center',
  },
  iconWrapper :{
    alignItems:'center'
  },
  selected: {
    paddingTop: 1,
    paddingBottom: 3,
    paddingLeft: 5,
    backgroundColor: "#282727",
    borderColor: "#9F9F9F",
    borderWidth: 1,
    borderRadius: 4,
  }
});
