// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import {
  removeCrewMember,
  updateCrewMember,
} from '_redux/crewMembers/actions';
import { showToast } from '_redux/toasts/actions';
import { getUserCognitoSub } from '_redux/user/selectors';

import ConfirmDeleteModalContent from '_components/common/ConfirmDeleteModalContent.component';
import DropDown from '_components/common/DropDown.component';
import Modal from '_components/common/Modal.component';
import TrashButton from '_components/common/TrashButton.component';


// UI Framework
import { Spinner, Text, View } from 'native-base';

// Style
import { styles } from '_components/crew_members/CrewMemberListItem.style';

class CrewMemberListItem extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showConfirmDeleteCrewMemberModal: false,
    };

    this._handleDelete = this._handleDelete.bind(this);
    this._handleEdit = this._handleEdit.bind(this);

    this._showConfirmDeleteCrewMemberModal = this._showConfirmDeleteCrewMemberModal.bind(this);
    this._hideConfirmDeleteCrewMemberModal = this._hideConfirmDeleteCrewMemberModal.bind(this);
  }

  /**
   * Triggered when the edit icon is clicked.
   */
  _handleEdit(fieldId, value) {
    const { cognitoSub, username, updateCrewMember } = this.props;
    const isLeader = value === 'Crew Leader';

    updateCrewMember(cognitoSub, username, isLeader);
  }

  /**
   * Triggered when the trash icon is clicked.
   */
  _handleDelete() {
    const { cognitoSub, removeCrewMember, username } = this.props;

    this._hideConfirmDeleteCrewMemberModal();
    removeCrewMember(cognitoSub, username);
  }

  _showConfirmDeleteCrewMemberModal() {
    this.setState({
      showConfirmDeleteCrewMemberModal: true,
    });
  }
  _hideConfirmDeleteCrewMemberModal() {
    this.setState({
      showConfirmDeleteCrewMemberModal: false,
    });
  }

  componentDidUpdate(prevProps) {
    const {
      failedToRemoveCrewMember,
      failedToUpdateCrewMember,
      isRemovingCrewMember,
      showToast,
    } = this.props;
    const {
      failedToRemoveCrewMember: prevFailedToRemoveCrewMember,
      failedToUpdateCrewMember: prevFailedToUpdateCrewMember,
      isRemovingCrewMember: prevIsRemovingCrewMember,
    } = prevProps;

    // Failed to remove crew member
    if (!prevFailedToRemoveCrewMember && failedToRemoveCrewMember) {
      showToast('Error removing crew member', true, 0);
    // Succeeded in removing crew member
    } else if (prevIsRemovingCrewMember && !isRemovingCrewMember && !failedToRemoveCrewMember) {
      showToast('Successfully removed crew member', false, 0);
    // Failed to update crew member.
    } else if (!prevFailedToUpdateCrewMember && failedToUpdateCrewMember) {
      showToast('Error updating crew member', true, 0);
    }
  }

  render() {

    const {
      showConfirmDeleteCrewMemberModal,
    } = this.state;

    const {
      backgroundColor,
      cognitoSub,
      currentUserCognitoSub,
      email,
      isLeader,
      isRemovingCrewMember,
      isUpdatingCrewMember,
      name,
      removingCrewMemberCognitoSub,
      updatingCrewMemberCognitoSub,
      zIndex,
      username
    } = this.props;

    console.log(currentUserCognitoSub);

    const showDeleteSpinner = isRemovingCrewMember && (removingCrewMemberCognitoSub === cognitoSub);
    const showUpdateSpinner = isUpdatingCrewMember && (updatingCrewMemberCognitoSub === cognitoSub);

    return (
      <View style={[styles.main, backgroundColor ? { backgroundColor } : {}, { zIndex }]}>

        {/* Name */}
        <View style={[styles.crewMemberNameContainer]}>
          <Text style={[styles.nameText]}>{ name }</Text>
          <Text style={[styles.emailText]}>Email: { email }</Text>
          <Text style={[styles.emailText]}>Username: { username }</Text>
        </View>

        {/* Actions */}
        {
          currentUserCognitoSub !== cognitoSub &&
          <>
            <View style={[styles.crewMemberActionsContainer]}>

              <View style={[styles.crewMemberDropdownContainer]}>
                {
                  showUpdateSpinner ?
                    <Spinner color={'red'} />
                    :
                    <DropDown
                      fieldId={'crewMemberLeader'}
                      onChange={this._handleEdit}
                      values={['Crew Member', 'Crew Leader']}
                      defaultValue={isLeader ? 'Crew Leader' : 'Crew Member'}
                    />
                }
              </View>

              <View style={[styles.crewMemberEditDeleteContainer]}>
                {
                  showDeleteSpinner ?
                    <Spinner color={'red'} />
                    :
                    <View>
                      <TrashButton data={cognitoSub} onClick={this._showConfirmDeleteCrewMemberModal} />
                    </View>
                }
              </View>

            </View>

            <View>
              <Modal
                isVisible={showConfirmDeleteCrewMemberModal}
                title={'REMOVE CREW MEMBER'}
                hideExitIcon={true}
                onClose={this._hideConfirmDeleteCrewMemberModal}
                content={showConfirmDeleteCrewMemberModal &&
                  <ConfirmDeleteModalContent
                    onConfirm={this._handleDelete}
                    onCancel={this._hideConfirmDeleteCrewMemberModal}

                    confirmationText={'Would you like to continue remove this crew member / leader?'}
                    confirmButtonText={'REMOVE CREW MEMBER'}
                    cancelButtonText={'DO NOT REMOVE CREW MEMBER'}
                  />
                }
              />
            </View>
          </>
        }

      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUserCognitoSub: getUserCognitoSub(state),
    isRemovingCrewMember: state.crewMembers.isRemovingCrewMember,
    isUpdatingCrewMember: state.crewMembers.isUpdatingCrewMember,
    failedToRemoveCrewMember: state.crewMembers.failedToRemoveCrewMember,
    failedToUpdateCrewMember: state.crewMembers.failedToUpdateCrewMember,
    removingCrewMemberCognitoSub: state.crewMembers.removingCrewMemberCognitoSub,
    updatingCrewMemberCognitoSub: state.crewMembers.updatingCrewMemberCognitoSub,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeCrewMember: (cognitoSub, username) => dispatch(removeCrewMember(cognitoSub, username)),
    showToast: (message, isError) => dispatch(showToast(message, isError)),
    updateCrewMember: (cognitoSub, username, isLeader) => dispatch(updateCrewMember(cognitoSub, username, isLeader)),
  };
};

CrewMemberListItem.propTypes = {
  currentUserCognitoSub: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  cognitoSub: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,

  email: PropTypes.string,
  name: PropTypes.string,
  isLeader: PropTypes.bool,

  isRemovingCrewMember: PropTypes.bool.isRequired,
  failedToRemoveCrewMember: PropTypes.bool.isRequired,
  removeCrewMember: PropTypes.func.isRequired,
  removingCrewMemberCognitoSub: PropTypes.string.isRequired,

  updateCrewMember: PropTypes.func.isRequired,
  isUpdatingCrewMember: PropTypes.bool.isRequired,
  failedToUpdateCrewMember: PropTypes.bool.isRequired,
  updatingCrewMemberCognitoSub: PropTypes.string.isRequired,

  showToast: PropTypes.func.isRequired,
  zIndex: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CrewMemberListItem);


