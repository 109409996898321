// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { executeCommand } from '_redux/iot/actions';
import { setSetPoints } from '_redux/iot/actions';
import { CONTROL_POINTS, SUPPORTED_COMMANDS } from '_redux/iot/utils';
import { getUserDeviceCardSettingsById } from '_redux/dashboard/selectors';
import { getSetPointsForDevice, getTelemetryForDevice } from '_redux/iot/selectors';

// Components
import VerticalSlider from '_components/device_card/VerticalSlider.component';
import SliderTicks from '_components/device_card/SliderTicks.component';

// Util
import { debounce } from '_util';

// UI Framework
import { Image, TouchableOpacity } from 'react-native';
import { Text, View } from 'native-base';
import { Grid, Col } from 'react-native-easy-grid';

// Styles
import { styles as global } from '_style/Global.style';
import { styles } from '_components/device_card/SpeedControl.style';

const DEFAULT_MIN_RPM = 800;
const DEFAULT_MAX_RPM = 3000;
const DEFAULT_STEP_RPM = 100;

const defaultFontSize = 21;


/**
 * This component is the speed control slider in the current engine
 * device card. It is visible when the card is in Speed mode.
 */
class MiniSpeedControl extends Component {

  constructor(props) {
    super(props);

    const { setPoints } = props;
    const { [CONTROL_POINTS.COMMANDED_ENGINE_SPEED]: commandedEngineSpeed } = setPoints.C || {};

    this.state = {
      step: DEFAULT_STEP_RPM,
      max: DEFAULT_MAX_RPM,
      min: DEFAULT_MIN_RPM,
      commandedEngineSpeed: commandedEngineSpeed || DEFAULT_MIN_RPM,
    };

    this._dragChange = this._dragChange.bind(this);
    this._sendMaintainSpeedCommand = debounce(this._sendMaintainSpeedCommand.bind(this), 1000);
    this._updateCommandedSpeedSetPoint = debounce(this._updateCommandedSpeedSetPoint.bind(this), 1000);
    this._speedUpClick = this._speedUpClick.bind(this);
    this._speedDownClick = this._speedDownClick.bind(this);
  }

  /**
   * Event handler for when the user clicks the up arrow button.
   */
  _speedUpClick() {
    const { deviceSettings } = this.props;
    const { commandedEngineSpeed } = this.state;

    const {
      maxRPM = DEFAULT_MAX_RPM,
      stepRPM = DEFAULT_STEP_RPM,
    } = deviceSettings;

    // Update the value of the commanded speed.
    this.setState({
      commandedEngineSpeed: Math.min(maxRPM, commandedEngineSpeed + stepRPM),
    });

    // Send the device the command to maintain speed
    // and update the commanded speed set point.
    this._sendMaintainSpeedCommand();
    this._updateCommandedSpeedSetPoint();
  }

  /**
   *  Event handler for when the user clicks the down arrow button.
   */
  _speedDownClick() {
    const { deviceSettings } = this.props;
    const { commandedEngineSpeed } = this.state;

    const {
      minRPM = DEFAULT_MIN_RPM,
      stepRPM = DEFAULT_STEP_RPM,
    } = deviceSettings;

    // Update the value of the commanded speed.
    this.setState({
      commandedEngineSpeed: Math.max(minRPM, commandedEngineSpeed - stepRPM),
    });

    // Send the device the command to maintain speed
    // and update the commanded speed set point.
    this._sendMaintainSpeedCommand();
    this._updateCommandedSpeedSetPoint();
  }

  /**
   * Execute the set maintain speed command over IoT.
   *
   * This is debounced 500 milliseconds as configured in the constructor.
   */
  _sendMaintainSpeedCommand() {
    const { deviceId, executeCommand } = this.props;
    const { commandedEngineSpeed } = this.state;

    executeCommand(
      deviceId,
      SUPPORTED_COMMANDS.SET_MAINTAIN_SPEED,
      {
        data: {
          speed: commandedEngineSpeed,
        },
      },
    );
  }

  /**
   * Update the commanded speed set point by sending a
   * shadow update request.
   *
   * This is debounced 500 milliseconds as configured in the constructor.
   */
  _updateCommandedSpeedSetPoint() {
    const { deviceId, setSetPoints } = this.props;
    const { commandedEngineSpeed } = this.state;

    setSetPoints(deviceId, {
      C: {
        [CONTROL_POINTS.COMMANDED_ENGINE_SPEED]: commandedEngineSpeed
      },
    });
  }

  /**
   * Event handler for when the user drags the slider up and down.
   * Gets called every tick of the slider drag.
   *
   * @param {*} commandedEngineSpeed current value of the slider.
   */
  _dragChange(commandedEngineSpeed) {
    this.setState({
      commandedEngineSpeed,
    });

    this._sendMaintainSpeedCommand();
    this._updateCommandedSpeedSetPoint();
  }

  /**
   * Handler to make sure that if the initial get set points
   * request is slow and the component has already rendered that
   * we can catch when the set points do come back and update the UI
   * accordingly.
   *
   * Also catches the instance where another user on another instance
   * of the app has made an update to the set points.
   */
  componentDidUpdate(prevProps) {
    const {
      deviceSettings,
      setPoints,
    } = this.props;

    const {
      maxRPM = DEFAULT_MAX_RPM,
      minRPM = DEFAULT_MIN_RPM,
    } = deviceSettings;

    // Incoming props.
    const { [CONTROL_POINTS.COMMANDED_ENGINE_SPEED]: commandedEngineSpeed } = setPoints.C || {};

    // Previous props.
    const { setPoints: prevSetPoints } = prevProps;
    const { [CONTROL_POINTS.COMMANDED_ENGINE_SPEED]: prevCommandedEngineSpeed } = prevSetPoints.C || {};

    // If the incoming props don't match the previous props,
    // update the state using the new props.
    if (commandedEngineSpeed !== prevCommandedEngineSpeed) {
      if (commandedEngineSpeed < minRPM) {
        this.setState({
          commandedEngineSpeed: minRPM,
        });
      } else if (commandedEngineSpeed > maxRPM) {
        this.setState({
          commandedEngineSpeed: maxRPM,
        });
      } else {
        this.setState({
          commandedEngineSpeed,
        });
      }
    }
  }

  render() {
    const { deviceSettings, telemetry } = this.props;

    // Engine State
    const { es } = telemetry;
    const disabled = es === 1;

    // Device Settings
    const {
      minRPM = DEFAULT_MIN_RPM,
      maxRPM = DEFAULT_MAX_RPM,
      stepRPM = DEFAULT_STEP_RPM,
     } = deviceSettings;

    // Commanded Engine Speed
    let {
      commandedEngineSpeed,
    } = this.state;

    commandedEngineSpeed = Math.max(commandedEngineSpeed, minRPM);

    const sliderThumb = (
      <Image style={[styles.sliderThumb]}
        source={require('_assets/images/slider-thumb-engine.png')}
      />
    );

    return (
      <View style={[styles.mainContainer]}>
        <View style={[styles.speedControlContainer]}>
          <TouchableOpacity disabled={disabled} onPress={this._speedUpClick}>
            <Image
              style={[styles.speedArrowButton]}
              source={require('_assets/images/arrow-up-button.png')}
            />
          </TouchableOpacity>
          <Text style={[global.telemetryValue, { fontSize: 21 }]}>
            {commandedEngineSpeed}
          </Text>
          <Text style={[global.fontRobotoCondensed, global.textLight, styles.engineSpeedLabel]}>
            RPM
          </Text>
          
          <TouchableOpacity disabled={disabled} onPress={this._speedDownClick}>
            <Image
              style={[styles.speedArrowButton]}
              source={require('_assets/images/arrow-down-button.png')}
            />
          </TouchableOpacity>
          </View>
      </View>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    deviceSettings: getUserDeviceCardSettingsById(state, ownProps.deviceId),
    setPoints: getSetPointsForDevice(state, ownProps.deviceId),
    telemetry: getTelemetryForDevice(state, ownProps.deviceId),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    executeCommand: (deviceId, command, data) => dispatch(executeCommand(deviceId, command, data)),
    setSetPoints: (deviceId, setPoints) => dispatch(setSetPoints(deviceId, setPoints)),
  };
};

MiniSpeedControl.propTypes = {
  deviceId: PropTypes.string.isRequired,
  deviceSettings: PropTypes.any.isRequired,
  executeCommand: PropTypes.func.isRequired,
  setPoints: PropTypes.any.isRequired,
  setSetPoints: PropTypes.func.isRequired,
  telemetry: PropTypes.any.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(MiniSpeedControl);
