
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  indicatorIcon: {
    height: 44,
    width: 48,
    borderRadius: 2,
  },
});
