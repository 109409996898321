import React, { Component } from "react";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  LoadScript,
  DrawingManager,
} from "@react-google-maps/api";

import { Col, Row, Grid } from "react-native-easy-grid";
import { Text, Button, Spinner } from "native-base";
import { navigate } from "_routes/RootNavigation";
import OptionPopup from "_components/create_site/Archive.component";
const containerStyle = {
  height: "100%",
  width: "100%",
};
import {
  DrawElementConst,
  GoogleDrawType,
  GoogleMapType,
  ToolTypes,
  ToolsButtons,
  UNDO_REDO_CONSTANTS,
  calculateStrokeWeight,
  StrokeMinWeight,
} from "../components/map/mapUtil";
import ConfirmSuccess from "_components/common/SiteSuccessModal.component";
import {
  Image,
  StyleSheet,
  TouchableOpacity,
  View,
  Clipboard,
  ScrollView,
  Linking,
} from "react-native";
import { styles } from "./SiteMapEdit.style";
import { Images } from "../assets/";
import EditButtonComponent from "../components/common/EditButton.component";
import Modal from "_components/common/Modal.component";
import SitePage from "./Site.page";
import Sharesitepage from "_components/create_site/ShareSite.component";
import SharesiteMailpage from "_components/create_site/SharesiteViaMailEmail.component";
import IconButtons from "../components/common/IconButtons";
import { connect } from "react-redux";
import SiteMapEditModal from "_components/create_site/SiteMapEditModal";
import EditNotesContent from "_components/create_site/EditNotesModalContent";
import AccessControl, {
  ADMIN,
  MANUFACTURER,
  CREW_LEADER,
  CREW_MEMBER,
  DEALER,
  OWNER,
} from "_components/auth/AccessControl.component";
import API from "_redux/site/api";

import {
  clearState,
  getOneSiteData,
  editSite,
  shareSite,
} from "_redux/site/actions";

import ToastModal from "_components/create_site/ToastModal.component";
import ToastComponent from "_components/create_site/ToastContent.component";

var location = {};
import {
  formatPhoneNumber,
  validateFormData,
  EMAIL_CONSTRAINT,
  PHONE_NUMBER_CONSTRAINT,
  REQUIRED_CONSTRAINT,
} from "_util/form-util";

class SiteEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ShowSiteEditModal: false,
      ShowConfirmModal: false,
      EditSite: false,
      BackToPre: false,
      ShowOptionModal: false,
      SiteShareModal: false,
      NavigateToDocumentPage: false,
      LinkCopied: false,
      copyText: "",
      //copyText: "http://localhost:3000/",
      siteStateValue: {},
      isLoaded: false,
      SiteShareMailModal: false,
      SiteShareTextModal: false,
      errorMSG: false,
      recieverDetails:
        "Hey There! Bazooka Farmstar is sharing a job site with you.",
      ShowToastModal: false,
      showMapAddressEmpty: false,
      shareHandleCode: "",
      setValueToPass: {},
      ShowNotesModal: false,
    };
    //Bind the functions
  }
  componentDidMount() {
    this.setState({
      siteStateValues: this.props.route.params.passValue,
    });

    this.getSiteDate();
  }

  componentWillUnmount() {
    const { clearState } = this.props;
    clearState();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.getSiteData !== this.props.getSiteData) {
      if (this.props.getSiteData) {
        location = {
          lat: this.props.getSiteData.siteAddress.latitude,
          lng: this.props.getSiteData.siteAddress.longitude,
        };
        this.setState({
          isLoaded: true,
          siteStateValues: this.props.getSiteData,
          copyText:
            this.state.copyText.length == 0
              ? this.props.getSiteData.siteShareUrl.concat(
                  this.props.getSiteData.urlHandler
                )
              : this.state.copyText,
          recieverDetails:
            "Hey There! Bazooka Farmstar is sharing a job site with you.",
        });
      }
    }

    if (prevProps.getSiteEditRes !== this.props.getSiteEditRes) {
      if (
        this.props.getSiteEditRes
          ? this.props.getSiteEditRes.status === "success"
          : null
      ) {
        this.setState({
          ShowConfirmModal: true,
          ShowSiteEditModal: false,
          ShowNotesModal: false,
        });
        this.getSiteDate();
      } else {
        this.setState({ isLoaded: true });
      }
    }
    if (prevProps.getShareSiteRes !== this.props.getShareSiteRes) {
      if (
        this.props.getShareSiteRes
          ? this.props.getShareSiteRes.message === "invite sent!"
          : null
      ) {
        this.setState({
          isLoaded: true,
          ShowToastModal: true,
          SiteShareTextModal: false,
          SiteShareMailModal: false,
        });
        this.getSiteDate();
      } else {
        this.setState({
          isLoaded: true,
          SiteShareMailModal: false,
          SiteShareTextModal: false,
        });
      }
    }
  }

  getSiteDate = () => {
    let siteID = this.props.route.params.passValue.siteId;
    this.setState({
      isLoaded: false,
      prevPageSiteData: this.props.route.params.passValue,
      //ShowToastModal:false,
    });
    setTimeout(() => {
      this.setState({
        ShowToastModal: false,
      });
    }, 1500);

    const { fetchSiteDataFromStore } = this.props;
    fetchSiteDataFromStore(siteID);
  };

  _closeOptionModal = () => {
    this.setState({ ShowOptionModal: false });
  };

  _CloseAndSave = () => {
    this.setState({ ShowConfirmModal: true, ShowOptionModal: false });
  };

  _CloseShareModal = () => {
    this.setState({ SiteShareModal: false });
  };
  CopyTextModal = () => {
    this.setState({ LinkCopied: true });
    Clipboard.setString(this.state.copyText);
    setTimeout(() => {
      this.setState({ LinkCopied: false });
      this.fetchCopiedText();
    }, 1500);
  };

  fetchCopiedText = async () => {
    const text = await Clipboard.getString();
  };
  _CloseOption = (e) => {
    var newNumber = e.phone_number.replace(/-/g, "");
    var existNumber = this.state.siteStateValues.siteManager.phone.substring(
      2,
      this.state.siteStateValues.siteManager.phone.length
    );
    if (
      e.site_name !== this.state.siteStateValues.siteName ||
      e.address !== this.state.siteStateValues.siteAddress.place ||
      newNumber !== existNumber ||
      e.email !== this.state.siteStateValues.siteManager.email ||
      e.manager !== this.state.siteStateValues.siteManager.name ||
      e.note !== this.state.siteStateValues.notes
    ) {
      this.setState({
        ShowOptionModal: true,
        ShowSiteEditModal: false,
        setValueToPass: e,
      });
      this.CloseOptionModal(e);
    } else {
      this.setState({
        ShowOptionModal: false,
        ShowSiteEditModal: false,
      });
    }
  };

  CloseOptionModal = (data) => {
    var passValue = data;
    return (
      <Modal
        isVisible={this.state.ShowOptionModal}
        title={"SAVE DETAILS"}
        hideExitIcon={true}
        content={
          this.state.ShowOptionModal ? (
            <OptionPopup
              onCancel={() => this._closeOptionModal}
              submitButtonText={"SAVE DETAILS"}
              confirmationText={
                "Would you like to save the details? \n All unsaved changes will be lost"
              }
              //submit={()=>this._editSiteInfo(this.state.setValueToPass)}
              submit={() =>
                Object.values(this.state.setValueToPass.errorMessages).every(
                  (x) => x === "Email is required." || x === null || x === ""
                )
                  ? this._editSiteInfo(this.state.setValueToPass)
                  : this.setState({
                      ShowSiteEditModal: true,
                      ShowOptionModal: false,
                    })
              }
              cancelButtonText={"CANCEL"}
            />
          ) : null
        }
      />
    );
  };
  _editNotesInfo = (data) => {
    this.setState({
      ShowSiteEditModal: false,
      ShowNotesModal: false,
      ShowOptionModal: false,
      isLoaded: false,
    });
    const { editSiteDetails } = this.props;
    editSiteDetails(this.state.siteStateValues.siteId, data);
  };

  _editSiteInfo = (data) => {
    data.site_name = data.site_name
      ? data.site_name
      : this.state.siteStateValues.siteName;

    data.status = data.status ? data.status : this.state.siteStateValues.status;

    this.setState({
      ShowSiteEditModal: false,
      ShowNotesModal: false,
      ShowOptionModal: false,
      isLoaded: false,
    });
    const { editSiteDetails } = this.props;
    editSiteDetails(this.state.siteStateValues.siteId, data);
  };

  editNotesModal = () => {
    return (
      <Modal
        isVisible={this.state.ShowNotesModal}
        title={"EDIT NOTES"}
        subTitle={" "}
        headerColor={"#FAFAFA"}
        propagateSwipe={true}
        onBackdropPress={() => this.setState({ ShowNotesModal: false })}
        hideExitIcon={false}
        onClose={() => this.setState({ ShowNotesModal: false })}
        content={
          this.state.ShowNotesModal ? (
            <EditNotesContent
              ConfirmModal={(e) => this._editNotesInfo(e)}
              siteNotes={
                this.state.siteStateValues
                  ? this.state.siteStateValues.notes
                  : ""
              }
              disabled={!this.state.isLoaded}
            />
          ) : null
        }
      />
    );
  };

  showEditModal = () => {
    return (
      <Modal
        isVisible={this.state.ShowSiteEditModal}
        title={"EDIT DETAILS"}
        subTitle={"Update the job site details"}
        headerColor={"#FAFAFA"}
        propagateSwipe={true}
        onBackdropPress={() => this.setState({ ShowSiteEditModal: false })}
        hideExitIcon={true}
        onClose={() => this.setState({ ShowSiteEditModal: false })}
        content={
          this.state.ShowSiteEditModal ? (
            <SiteMapEditModal
              disabled={!this.state.isLoaded}
              siteName={
                this.state.siteStateValues
                  ? this.state.siteStateValues.siteName
                  : ""
              }
              siteAddress={
                this.state.siteStateValues
                  ? this.state.siteStateValues.siteAddress.place
                  : ""
              }
              placeCenter={
                this.state.siteStateValues
                  ? {
                      lat: this.state.siteStateValues.siteAddress.latitude,
                      lng: this.state.siteStateValues.siteAddress.longitude,
                    }
                  : ""
              }
              siteManager={
                this.state.siteStateValues
                  ? this.state.siteStateValues.siteManager.name
                  : ""
              }
              sitePhone={
                this.state.siteStateValues.siteManager.phone
                  ? `${
                      this.state.siteStateValues.siteManager.phone.substring(
                        0,
                        1
                      ) +
                      this.state.siteStateValues.siteManager.phone.substring(
                        0,
                        5
                      ) +
                      "-" +
                      this.state.siteStateValues.siteManager.phone.substring(
                        5,
                        8
                      ) +
                      "-" +
                      this.state.siteStateValues.siteManager.phone.substring(
                        8,
                        14
                      )
                    }`
                  : ""
              }
              siteEmail={
                this.state.siteStateValues
                  ? this.state.siteStateValues.siteManager.email
                  : ""
              }
              siteNotes={
                this.state.siteStateValues
                  ? this.state.siteStateValues.notes
                  : ""
              }
              onClick={(e) => this._editSiteInfo(e)}
              onClose={(e) => this._CloseOption(e)}
            />
          ) : null
        }
      />
    );
  };

  confirmModal = () => {
    return (
      <Modal
        isVisible={this.state.ShowConfirmModal}
        hideTitle={true}
        hideExitIcon={true}
        content={
          this.state.ShowConfirmModal ? (
            <ConfirmSuccess
              title={"SUCCESS"}
              titleColor={"#006644"}
              imageStyle={{ height: 112, width: 112 }}
              imageName={"SUCCESS_IMAGE"}
              subTitle={"YOU HAVE UPDATED \n THE SITE DETAILS"}
              onClose={() => this.setState({ ShowConfirmModal: false })}
              buttonText={"CLOSE"}
            />
          ) : null
        }
      />
    );
  };

  sharesiteModal = () => {
    return (
      <Modal
        isVisible={this.state.SiteShareModal}
        title={"SHARE SITE"}
        subTitle={"Share your job site to other users via"}
        hideExitIcon={false}
        headerColor={"#FAFAFA"}
        onClose={() => this.setState({ SiteShareModal: false })}
        content={
          this.state.SiteShareModal ? (
            <Sharesitepage
              confirmationText={"Job site link"}
              mailSubmit={() =>
                this.setState({
                  SiteShareMailModal: true,
                  SiteShareModal: false,
                })
              }
              submit={() => this._CloseShareModal}
              textSubmit={() =>
                this.setState({
                  SiteShareTextModal: true,
                  SiteShareMailModal: true,
                  SiteShareModal: false,
                })
              }
              copysubmit={() => this.CopyTextModal()}
              submitButtonText={"DONE"}
              inputValue={this.state.copyText}
              copyConfirmText={this.state.LinkCopied ? "Copied!" : null}
            />
          ) : null
        }
      />
    );
  };

  phoneFormat = (value) => {
    const cleaned = value.replace(/\D/g, "");
    if (cleaned.length > 10) {
      return;
    }
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    const formatted = !match[2]
      ? match[1]
      : match[1] +
        "-" +
        match[2] +
        (match[3] ? "-" + match[3] : "") +
        (match[4] ? "" + match[4] : "");
    this.setState({ phone_number: formatted });
  };

  sharesiteMailModal = () => {
    const shareType = this.state.SiteShareTextModal ? "text" : "email";
    return (
      <Modal
        isVisible={this.state.SiteShareMailModal}
        title={`SHARE SITE VIA ${
          this.state.SiteShareTextModal ? "TEXT MESSAGE" : "EMAIL"
        }`}
        subTitle={`Enter your ${
          this.state.SiteShareTextModal ? "mobile number" : "email address"
        } to share the job site`}
        hideExitIcon={false}
        headerColor={"#FAFAFA"}
        onClose={() =>
          this.setState({
            SiteShareMailModal: false,
            SiteShareTextModal: false,
            errorMSG: false,
          })
        }
        content={
          this.state.SiteShareMailModal ? (
            <SharesiteMailpage
              submit={(e) =>
                e.email || e.phone_number
                  ? this.ShareSiteRequest(
                      e,
                      shareType,
                      this.state.siteStateValues,
                      `${this.state.recieverDetails}`
                    )
                  : !e.errorMessage
                  ? this.setState({ errorMSG: true })
                  : this.setState({ errorMSG: false })
              }
              onChangetext={(e) => this.setState({ recieverDetails: e })}
              error={this.state.errorMSG}
              showHelpText={this.state.validNumber}
              inputValue={this.state.recieverDetails}
              inputMsgValue={this.state.recieverDetails}
              mobileNumber={this.state.SiteShareTextModal}
              messageLable={"Message"}
              submitButtonText={"SEND"}
              copyConfirmText={this.state.LinkCopied ? "Copied!" : null}
            />
          ) : null
        }
      />
    );
  };

  ShareSiteRequest = (sender, shareType, itemValue, msg) => {
    this.setState({
      SiteShareMailModal: false,
      isLoaded: false,
      errorMSG: false,
    });
    const { shareSiterequest, siteShareSuccess } = this.props;
    shareSiterequest(sender, shareType, itemValue, msg);
  };

  shareSiteSuccessModal = () => {
    return (
      <ToastModal
        isVisible={this.state.ShowToastModal}
        hideTitle={true}
        animIn="slideInRight"
        animOut="slideOutRight"
        inTiming={1000}
        outTiming={1000}
        hideExitIcon={true}
        //onClose={this._closeConfirmCreateModal}
        // content={this._siteCreateContent()}
        content={
          <ToastComponent
            toastText={"SITE SHARE SUCCESS"}
            imageStyle={{ height: 44, width: 44 }}
            imageName={"SUCCESS_IMAGE"}
          />
        }
      />
    );
  };

  addressEmptyModal = () => {
    return (
      <Modal
        isVisible={this.state.showMapAddressEmpty}
        hideTitle={true}
        hideExitIcon={true}
        content={
          this.state.showMapAddressEmpty ? (
            <ConfirmSuccess
              title={"NOTICE"}
              titleColor={"#EEB310"}
              imageStyle={{ height: 104, width: 112 }}
              imageName={"NOTICE_IMAGE"}
              subTitle={
                "YOU CAN NOT ACCESS TO\n A SITE MAP WITHOUT \n AN ADDRESS"
              }
              onClose={() => this.setState({ showMapAddressEmpty: false })}
              buttonText={"CLOSE"}
            />
          ) : null
        }
      />
    );
  };

  render() {
    var siteID = this.props.route.params.passValue.siteId;
    var activeStatus = this.props.route.params.passValue.status;
    return (
      <Grid style={[styles.mainContainer]}>
        <Col style={[styles.subContainer]} size={9}>
          <View>
            {this.shareSiteSuccessModal()}
            {this.sharesiteModal()}
            {this.showEditModal()}
            {this.confirmModal()}
            {this.CloseOptionModal()}
            {this.sharesiteMailModal()}
            {this.addressEmptyModal()}
            {this.editNotesModal()}
          </View>
          {!this.state.isLoaded ? (
            <View style={{ flex: 1, alignItems: "center" }}>
              <Spinner color={"red"} />
            </View>
          ) : (
            <View style={[styles.mainView]}>
              <Row style={[styles.main_b_radius]} size={7}>
                <Col size={4} style={[styles.toolBox, styles.mainCol]}>
                  <View style={[styles.backBtnView]}>
                    <TouchableOpacity
                      onClick={() => navigate("Sites", { activeStatus })}
                      style={styles.backButton}
                    >
                      <View style={[styles.backButtonCont]}>
                        <Image
                          source={require("_assets/images/back-icon.svg")}
                          style={[styles.backButtonImg]}
                        />
                        <Text style={styles.backButtonText}>BACK</Text>
                      </View>
                    </TouchableOpacity>
                  </View>
                  <ScrollView style={{ marginTop: "2%" }}>
                    <View>
                      <View style={[styles.topMargin, styles.topViewLeft]}>
                        <Text style={[styles.text]}>
                          {this.state.siteStateValues
                            ? this.state.siteStateValues.siteName
                            : ""}
                        </Text>
                        <AccessControl
                          allowedGroups={[
                            ADMIN,
                            MANUFACTURER,
                            CREW_LEADER,
                            DEALER,
                            OWNER,
                          ]}
                        >
                          {this.state.prevPageSiteData.status !== "ARCHIVED" ? (
                            <EditButtonComponent
                              style={[styles.topMargin]}
                              onClick={() =>
                                this.setState({ ShowSiteEditModal: true })
                              }
                            />
                          ) : null}
                        </AccessControl>
                      </View>
                    </View>
                    <View style={[styles.contentRows]}>
                      <Text style={[styles.titleText, styles.topMargin]}>
                        Address
                      </Text>
                      <Text style={[styles.conText, styles.topMargin2]}>
                        {this.state.siteStateValues
                          ? this.state.siteStateValues.siteAddress.place
                          : ""}
                      </Text>
                      <Text style={[styles.conText]}></Text>
                    </View>

                    <View style={[styles.contentRows]}>
                      <Text style={[styles.titleText, styles.topMargin]}>
                        Site-Manager Contact
                      </Text>
                      <Text style={[styles.conText, styles.topMargin2]}>
                        {this.state.siteStateValues
                          ? this.state.siteStateValues.siteManager.name
                          : ""}
                      </Text>
                      <Text style={[styles.conText]}>
                        {this.state.siteStateValues.siteManager.phone
                          ? `${
                              this.state.siteStateValues.siteManager.phone.substring(
                                0,
                                1
                              ) +
                              this.state.siteStateValues.siteManager.phone.substring(
                                1,
                                2
                              ) +
                              " (" +
                              this.state.siteStateValues.siteManager.phone.substring(
                                2,
                                5
                              ) +
                              ") " +
                              this.state.siteStateValues.siteManager.phone.substring(
                                5,
                                8
                              ) +
                              "-" +
                              this.state.siteStateValues.siteManager.phone.substring(
                                8,
                                12
                              )
                            }`
                          : ""}
                      </Text>
                      <Text style={[styles.conText]}>
                        {this.state.siteStateValues
                          ? this.state.siteStateValues.siteManager.email
                          : ""}
                      </Text>
                    </View>
                    <View
                      style={{
                        flex: 1,
                        alignItems: "left",
                        justifyContent: "flex-start",
                        marginTop: 12,
                        paddingRight: "10%",
                        marginBottom: 0,
                      }}
                    >
                      <hr
                        style={{
                          background: "#616161",
                          color: "#616161",
                          borderColor: "#616161",
                          height: 0.25,
                          width: "100%",
                          maxWidth: 475,
                          textAlign: "left",
                        }}
                      />
                    </View>
                    <View style={[styles.contentRows, { marginTop: -10 }]}>
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: 20,
                        }}
                      >
                        <Text style={[styles.titleText]}>Notes</Text>
                        <AccessControl
                          allowedGroups={[
                            ADMIN,
                            MANUFACTURER,
                            CREW_LEADER,
                            DEALER,
                            OWNER,
                          ]}
                        >
                          {this.state.prevPageSiteData.status !== "ARCHIVED" ? (
                            // <EditButtonComponent
                            //   buttonStyle={{height:20,width:20}}
                            //   onClick={() => {}}
                            // />
                            <IconButtons
                              imageName={"EDIT_NOTES"}
                              style={{ height: 31, width: 28, marginLeft: 15 }}
                              buttonWithText={false}
                              onPress={() => {
                                this.setState({
                                  ShowNotesModal: true,
                                  shareSiteEmpty: true,
                                });
                              }}
                            />
                          ) : null}
                        </AccessControl>
                      </View>
                      <ScrollView style={{ height: 150 }}>
                        <Text
                          style={[
                            styles.conText,
                            styles.topMargin2,
                            { lineHeight: 22 },
                          ]}
                        >
                          {this.state.siteStateValues
                            ? this.state.siteStateValues.notes
                            : ""}
                        </Text>
                      </ScrollView>
                      <Text style={[styles.conText]}></Text>
                    </View>
                  </ScrollView>
                </Col>
                <Col
                  size={5}
                  style={[styles.mapCont, { borderTopRightRadius: 12 }]}
                >
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={location}
                    options={{ mapTypeId: GoogleMapType.SATELLITE }}
                    zoom={15}
                  >
                    <Marker position={location} />
                  </GoogleMap>
                </Col>
              </Row>
              <Row
                size={1}
                style={{
                  justifyContent: "center",
                  alignContent: "center",
                  minHeight: 50,
                }}
              >
                <Col size={4} style={[styles.toolBox, styles.bottomLeftDiv]}>
                  <TouchableOpacity
                    onClick={() => {
                      this.state.prevPageSiteData.status === "ACTIVE"
                        ? this.setState({ SiteShareModal: true })
                        : null;
                    }}
                    style={[styles.shareButton, { marginBottom: "1vh" }]}
                  >
                    <View style={[styles.backButtonCont]}>
                      <Image
                        source={require("_assets/images/share-icon.png")}
                        style={[styles.shareButtonImg]}
                      />
                      <Text
                        style={[
                          styles.shareButtonText,
                          {
                            color:
                              this.state.prevPageSiteData.status === "ARCHIVED"
                                ? "#615F5F"
                                : "#FAFAFA",
                          },
                        ]}
                      >
                        SHARE
                      </Text>
                    </View>
                  </TouchableOpacity>
                </Col>
                <Col
                  size={5}
                  style={[styles.bottomRightDiv, { minWidth: 280 }]}
                >
                  <View
                    style={[
                      styles.downDiv,
                      { borderBottomRightRadius: 12, flexWrap: "wrap" },
                    ]}
                  >
                    <TouchableOpacity
                      onClick={() => {
                        this.state.siteStateValues.siteAddress.place &&
                        this.state.prevPageSiteData.status === "ACTIVE"
                          ? navigate("Site", {
                              location,
                              zoom: 15,
                              readOnly: true,
                              siteId: siteID,
                              fromEditSite: true,
                            })
                          : this.setState({ showMapAddressEmpty: true });
                      }}
                      style={[styles.siteButton, { marginBottom: "3vh" }]}
                    >
                      <Text
                        style={[
                          styles.siteButtonText,
                          {
                            color:
                              this.state.prevPageSiteData.status === "ARCHIVED"
                                ? "#615F5F"
                                : "#FAFAFA",
                          },
                        ]}
                      >
                        SITE MAP
                      </Text>
                    </TouchableOpacity>

                    <TouchableOpacity
                      style={[styles.siteButton, { marginBottom: "3vh" }]}
                      onClick={() =>
                        navigate("UploadDocument", {
                          siteID,
                          activeStatus,
                          fromSiteMenu: false,
                        })
                      }
                    >
                      <Text style={styles.siteButtonText}>DOCUMENTS</Text>
                    </TouchableOpacity>
                  </View>
                </Col>
              </Row>
            </View>
          )}
        </Col>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isFetchingUserInfo: state.user.isFetchingUserInfo,
    getSiteData: state.siteDetails.onesitedata,
    getSiteEditRes: state.siteDetails.editsitedata,
    getShareSiteRes: state.siteDetails.sharesitedata,
    siteShareSuccess: state.siteDetails.siteShareSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSiteDataFromStore: (SiteId) => dispatch(getOneSiteData(SiteId)),
    editSiteDetails: (SiteId, data) => dispatch(editSite(SiteId, data)),
    shareSiterequest: (Sender, shareType, itemValue, msg) =>
      dispatch(shareSite(Sender, shareType, itemValue, msg)),
    clearState: () => dispatch(clearState()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SiteEdit);
