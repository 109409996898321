import { GROUP_DISPLAY_NAMES } from '_components/auth/AccessControl.component';

/**
 * Gets the array of user devices from the state.
 *
 * @param {object} state current state
 *
 * @returns {Array<object>}
 */
export const getUserDevices = (state) => {
  return state.user.devices || [];
};

/**
 * Returns the current user's cognito id.
 *
 * @param {*} state current state.
 */
export const getUserCognitoId = (state) => {
  return state.user.userInfo ? state.user.userInfo['cognito_id'] : null;
};

/**
 *
 * @param {*} state
 */
export const getUserParentId = (state) => {
  return state.user.userInfo ? state.user.userInfo['parent_id'] : null;
};

/**
 *
 * @param {*} state
 */
export const getUserRoleId = (state) => {
  return state.user.userInfo ? state.user.userInfo['role_id'] : null;
};


/**
 * Returns true if the user is saving the settings for the given device.
 * False otherwise.
 *
 * @param {*} state
 * @param {*} deviceId
 */
export const isSavingUserDeviceCardSettings = (state, deviceId) => {

  return state.user.isSavingUserDeviceCardSettings[deviceId] ? true : false;
};

/**
 * Gets the user's unique code out of the state.
 *
 * @param {*} state
 */
export const getUserCode = (state) => {
  return state.user.userInfo.attributes ? state.user.userInfo.attributes['custom:userCode'] : '';
};

/**
 * Gets the user's user groups.
 *
 * @param {*} state
 */
export const getUserGroups = (state) => {
  const groups = state.user.userInfo.cognitoGroups ? state.user.userInfo.cognitoGroups : [];

  return groups.filter((g) => GROUP_DISPLAY_NAMES[g]);
};

/**
 * Get the cognito sub for the current user.

 * @param {*} state redux state
 */
export const getUserCognitoSub = (state) => {
  return state.user.userInfo.attributes ? state.user.userInfo.attributes.sub : '';
};
