export const STATUS_CODES = {
  0: 'STABILIZE',
  1: 'STOPPED',
  2: 'STANDBY',
  3: 'PRESTART 1',
  4: 'SAFETY CHECK',
  5: 'PRESTART 2',
  6: 'CRANK ON',
  7: 'CRANK OFF',
  8: 'FALSE START',
  9: 'WARMUP',
  10: 'LINE FILL 1',
  11: 'LINE FILL 2',
  12: 'RUNNING',
  13: 'COOLDOWN',
  14: 'STOPPING',
  15: 'SPINDOWN',
};
