import { API } from 'aws-amplify';
import { FEEDBACK_API_ID } from '_config/api-constants';
import { makeActionCreator } from '_redux/rootActions';

import { getUserCognitoSub } from '_redux/user/utils';

import {
  CLEAR_STATE,
  SUBMIT_FEEDBACK_REQUEST,
  SUBMIT_FEEDBACK_SUCCESS,
  SUBMIT_FEEDBACK_FAILURE,
} from '_redux/feedback/types';

/**
 * Clear state.
 */
export const clearState = makeActionCreator(CLEAR_STATE);

/**
 * Submit feedback.
 *
 * @param {*} feedback
 */
export const submitFeedback = (feedback) => {
  return async (dispatch) => {
    dispatch(submitFeedbackRequest());

    try {
      const cognitoSub = await getUserCognitoSub();
      feedback.cognito_sub = cognitoSub;

      await API.post(FEEDBACK_API_ID, '/feedback', { body: feedback });

      dispatch(submitFeedbackSuccess());

    } catch (e) {
      console.error(e);
      dispatch(submitFeedbackFailure());
    }
  };
};
export const submitFeedbackRequest = makeActionCreator(SUBMIT_FEEDBACK_REQUEST);
export const submitFeedbackSuccess = makeActionCreator(SUBMIT_FEEDBACK_SUCCESS);
export const submitFeedbackFailure = makeActionCreator(SUBMIT_FEEDBACK_FAILURE);
