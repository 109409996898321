import { createReducer } from '_redux/rootActions';

// Types
import {
  CLEAR_STATE,
  CLEAR_API_STATE,

  ADD_CREW_MEMBER_REQUEST,
  ADD_CREW_MEMBER_SUCCESS,
  ADD_CREW_MEMBER_FAILURE,

  INVITE_CREW_MEMBER_REQUEST,
  INVITE_CREW_MEMBER_SUCCESS,
  INVITE_CREW_MEMBER_FAILURE,

  GET_CREW_MEMBERS_REQUEST,
  GET_CREW_MEMBERS_SUCCESS,
  GET_CREW_MEMBERS_FAILURE,

  REMOVE_CREW_MEMBER_REQUEST,
  REMOVE_CREW_MEMBER_SUCCESS,
  REMOVE_CREW_MEMBER_FAILURE,

  UPDATE_CREW_MEMBER_REQUEST,
  UPDATE_CREW_MEMBER_SUCCESS,
  UPDATE_CREW_MEMBER_FAILURE,

  CHECK_CREW_MEMBER_ELIGIBILITY_REQUEST,
  CHECK_CREW_MEMBER_ELIGIBILITY_SUCCESS,
  CHECK_CREW_MEMBER_ELIGIBILITY_FAILURE,

  LEAVE_AND_ADD_IN_PROGRESS,
  LEAVE_AND_ADD_COMPLETE,

  CHECK_AND_ADD_IN_PROGRESS,
  CHECK_AND_ADD_COMPLETE,
} from '_redux/crewMembers/types';

const initialState = {
  isAddingCrewMember: false,
  failedToAddCrewMember: false,
  addCrewMemberErrorMessage: '',

  isInvitingCrewMember: false,
  failedToInviteCrewMember: false,
  inviteCrewMemberErrorMessage: '',

  isGettingCrewMembers: false,

  isRemovingCrewMember: false,
  failedToRemoveCrewMember: false,
  removeCrewMemberErrorMessage: '',
  removingCrewMemberCognitoSub: '',

  isUpdatingCrewMember: false,
  updatingCrewMemberCognitoSub: '',
  failedToUpdateCrewMember: false,

  isCheckingCrewMemberEligibility: false,
  crewMemberEligibilityError: '',

  leaveAndAddInProgress: false,
  checkAndAddInProgress: false,

  crewMembers: [],
};

export default createReducer(initialState, {
  // CLEAR STATE
  [CLEAR_STATE]: () => initialState,
  [CLEAR_API_STATE]: (state) => {
    const { crewMembers } = state;
    return {...initialState, crewMembers};
  },

  // CHECK ELIGIBILITY AND ADD CREW MEMER OPERATION
  [CHECK_AND_ADD_IN_PROGRESS]: (state) => {
    return {...state, checkAndAddInProgress: true};
  },
  [CHECK_AND_ADD_COMPLETE]: (state) => {
    return {...state, checkAndAddInProgress: false};
  },

  // LEAVE AND ADD CREW MEMER OPERATION
  [LEAVE_AND_ADD_IN_PROGRESS]: (state) => {
    return {...state, leaveAndAddInProgress: true};
  },
  [LEAVE_AND_ADD_COMPLETE]: (state) => {
    return {...state, leaveAndAddInProgress: false};
  },

  // GET CREW MEMBERS
  [GET_CREW_MEMBERS_REQUEST]: (state) => {
    return {...state, isGettingCrewMembers: true };
  },
  [GET_CREW_MEMBERS_SUCCESS]: (state, action) => {
    const { crewMembers } = action;

    return {...state, isGettingCrewMembers: false, crewMembers };
  },
  [GET_CREW_MEMBERS_FAILURE]: (state) => {
    return {...state, isGettingCrewMembers: false };
  },

  // ADD CREW MEMBER
  [ADD_CREW_MEMBER_REQUEST]: (state) => {
    return {
      ...state,
      isAddingCrewMember: true,
      failedToAddCrewMember: false,
      addCrewMemberErrorMessage: '',
    };
  },
  [ADD_CREW_MEMBER_SUCCESS]: (state) => {
    return {
      ...state,
      isAddingCrewMember: false,
      failedToAddCrewMember: false,
      addCrewMemberErrorMessage: '',
    };
  },
  [ADD_CREW_MEMBER_FAILURE]: (state, action) => {
    const { error } = action;
    return {
      ...state,
      isAddingCrewMember: false,
      failedToAddCrewMember: true,
      addCrewMemberErrorMessage: error,
    };
  },

  // INVITE CREW MEMBER
  [INVITE_CREW_MEMBER_REQUEST]: (state) => {
    return {...state, isInvitingCrewMember: true, failedToInviteCrewMember: false, inviteCrewMemberErrorMessage: '' };
  },
  [INVITE_CREW_MEMBER_SUCCESS]: (state) => {
    return {...state, isInvitingCrewMember: false, failedToInviteCrewMember: false, inviteCrewMemberErrorMessage: '' };
  },
  [INVITE_CREW_MEMBER_FAILURE]: (state, action) => {
    const { error } = action;

    return {...state, isInvitingCrewMember: false, failedToInviteCrewMember: true, inviteCrewMemberErrorMessage: error.message };
  },

  // REMOVE CREW MEMBER
  [REMOVE_CREW_MEMBER_REQUEST]: (state, action) => {
    const { cognitoSub } = action;

    return {...state, isRemovingCrewMember: true, removingCrewMemberCognitoSub: cognitoSub, failedToRemoveCrewMember: false, removeCrewMemberErrorMessage: '' };
  },
  [REMOVE_CREW_MEMBER_SUCCESS]: (state, action) => {
    const { cognitoSub } = action;

    const crewMemberIndex = state.crewMembers.findIndex((crewMember) => crewMember.cognito_sub === cognitoSub);
    const newCrewMemberArray = state.crewMembers.slice();
    newCrewMemberArray.splice(crewMemberIndex, 1);


    return {...state, crewMembers: newCrewMemberArray, isRemovingCrewMember: false, failedToRemoveCrewMember: false, removeCrewMemberErrorMessage: '', removingCrewMemberCognitoSub: '' };
  },
  [REMOVE_CREW_MEMBER_FAILURE]: (state, action) => {
    const { error } = action;

    return {...state, isRemovingCrewMember: false, failedToRemoveCrewMember: true, removeCrewMemberErrorMessage: error.message, removingCrewMemberCognitoSub: '' };
  },

  // UPDATE CREW MEMBER
  [UPDATE_CREW_MEMBER_REQUEST]: (state, action) => {
    const { cognitoSub } = action;
    return {
      ...state,
      isUpdatingCrewMember: true,
      updatingCrewMemberCognitoSub: cognitoSub,
      failedToUpdateCrewMember: false,
    };
  },
  [UPDATE_CREW_MEMBER_SUCCESS]: (state, action) => {
    const { cognitoSub, isLeader } = action;

    const updatedCrewMembers = updateCrewMember(state, cognitoSub, { is_leader: isLeader ? 1 : 0 });

    return {
      ...state,
      isUpdatingCrewMember: false,
      updatingCrewMemberCognitoSub: '',
      failedToUpdateCrewMember: false,
      crewMembers: updatedCrewMembers,
    };
  },
  [UPDATE_CREW_MEMBER_FAILURE]: (state) => {
    return {
      ...state,
      isUpdatingCrewMember: false,
      updatingCrewMemberCognitoSub: '',
      failedToUpdateCrewMember: true,
    };
  },

  // CHECK CREW MEMBER ELIGIBILITY
  [CHECK_CREW_MEMBER_ELIGIBILITY_REQUEST]: (state) => {
    return {
      ...state,
      isCheckingCrewMemberEligibility: true,
      crewMemberEligibilityError: '',
    };
  },
  [CHECK_CREW_MEMBER_ELIGIBILITY_SUCCESS]: (state, action) => {
    const { error } = action;
    return {
      ...state,
      isCheckingCrewMemberEligibility: false,
      crewMemberEligibilityError: error,
    };
  },
  [CHECK_CREW_MEMBER_ELIGIBILITY_FAILURE]: (state) => {
    return {
      ...state,
      isCheckingCrewMemberEligibility: false,
      crewMemberEligibilityError: 'FAILED_CHECK',
    };
  },
});

/**
 * Utility method to get the array index of the crew member given the
 * cognito sub provided.
 *
 * @param {*} state redux state
 * @param {*} cognitoSub cognito sub of the user.
 */
const getCrewMemberIndex = (state, cognitoSub) => {
  return state.crewMembers.findIndex((crewMember) => crewMember.cognito_sub === cognitoSub);
};

/**
 * Update a crew member in the state in a redux safe way.
 *
 * @param {*} state redux state
 * @param {string} cognitoSub the cognito sub of the crew member
 * @param {*} updates object of updates to apply to the crew member
 */
const updateCrewMember = (state, cognitoSub, updates) => {
  const crewMemberIndex = getCrewMemberIndex(state, cognitoSub);

  return state.crewMembers.map((crewMember, index) => {
    if (index !== crewMemberIndex) {
      return crewMember;
    }

    return {
      ...crewMember,
      ...updates,
    };
  });
};
