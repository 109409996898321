// React
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";

// Util
import { isUndefined } from "_util";

// Custom Components
import InsetShadow from "react-native-inset-shadow";

// UI Framework
import { Image, TouchableOpacity } from "react-native";
import { Item, Input, Text, View } from "native-base";

// Style
import { styles as global } from "_style/Global.style";
import { styles } from "_components/common/FormInput.style";

/**
 * Form Input
 *
 * Common form input to keep styling and logic all in one place.
 */
class FormInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      value: undefined,
      hideSecureField: true,
    };

    this.inputRef = React.createRef();

    this._onChange = this._onChange.bind(this);
    this._onChangeText = this._onChangeText.bind(this);

    this._showSecureField = this._showSecureField.bind(this);
    this._hideSecureField = this._hideSecureField.bind(this);
  }

  _showSecureField() {
    this.setState({
      hideSecureField: false,
    });
  }

  _hideSecureField() {
    this.setState({
      hideSecureField: true,
    });
  }

  /**
   * Keep track of the current value of the input field so we
   * can pass it back up to any parent component.
   *
   * @param {string} value
   */
  _onChangeText(value) {
    const { onInput } = this.props;
    this.setState({
      value,
    });
    if (onInput) {
      onInput(value);
    }
  }

  /**
   * Called when the input blurs.
   *
   * @param {*} e input event
   */
  _onChange(e) {
    const { fieldId, onChange } = this.props;
    const value = e.nativeEvent.text;

    onChange(fieldId, value);
  }

  /**
   * Need to check the prev props when the component updates
   * in case the parent component has changed the default value.
   *
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    const { defaultValue } = this.props;
    const { defaultValue: prevValue } = prevProps;

    if (defaultValue !== prevValue) {
      this.setState({
        value: defaultValue,
      });
    }
  }

  render() {
    const {
      defaultValue = "",
      disabled,
      errorMessage,
      errorMessageStyle = [],
      fieldId,
      floatingLabel,
      helpText,
      helpTextStyle = [],
      inlineError = true,
      inputStyles = [],
      insetShadow = true,
      itemStyles = [],
      multiline,
      multiline2 = false,
      multiline3 = false,
      maxLength,
      placeholder,
      placeholderTextColor,
      secure,
      inputValue = "",
      showHide = false,
    } = this.props;
    const { hideSecureField, value = defaultValue } = this.state;

    // Show/Hide the error message or the help text.
    const showErrorMessage =
      inlineError && !isUndefined(errorMessage) && errorMessage !== "";
    const showHelpText = !showErrorMessage && !isUndefined(helpText);

    const helpTextStyles = [styles.helpText].concat(helpTextStyle);
    const errorMessageStyles = [styles.errorMessage].concat(errorMessageStyle);

    // // Add to the input styles with styles specific to certain states, such
    // // as the input being a text area..
    if (multiline) inputStyles.push(styles.formTextarea);
    if (multiline && multiline2) inputStyles.push(styles.formTextarea2);
    if (multiline && multiline3) inputStyles.push(styles.formTextarea3);

    const hideInput = secure && hideSecureField;
    // console.log('hideInput', hideInput);

    const inputComponent = (
      <Item style={itemStyles} disabled>
        <Input
          ref={this.inputRef}
          key={fieldId}
          autoCapitalize={"none"}
          placeholder={placeholder}
          disabled={disabled}
          style={inputStyles}
          placeholderTextColor={placeholderTextColor || global.lighter_gray}
          onBlur={this._onChange}
          onChangeText={this._onChangeText}
          secureTextEntry={hideInput}
          multiline={multiline}
          // multiline2={multiline2}
          multiline3={multiline3}
          maxLength={maxLength}
          value={value}

        //ref={(ref)=>{this.state.va}}
        />
      </Item>
    );

    return (
      <View style={[styles.inputContainer]}>
        {floatingLabel && (
          <Text style={[styles.floatingLabel]}>{floatingLabel}</Text>
        )}
        {insetShadow ? (
          <InsetShadow containerStyle={{ height: "auto" }}>
            {inputComponent}
          </InsetShadow>
        ) : (
          <>{inputComponent}</>
        )}
        {showErrorMessage && (
          <Text style={errorMessageStyles}>{errorMessage}</Text>
        )}
        {showHelpText && <Text style={helpTextStyles}>{helpText}</Text>}
        {showHide && (
          <View style={[styles.showHideIconContainer]}>
            {hideSecureField ? (
              <TouchableOpacity onPress={this._showSecureField}>
                <Image
                  style={[styles.hidePasswordIcon]}
                  source={require("_assets/images/hide-password.png")}
                />
              </TouchableOpacity>
            ) : (
              <TouchableOpacity onPress={this._hideSecureField}>
                <Image
                  style={[styles.showPasswordIcon]}
                  source={require("_assets/images/show-password.png")}
                />
              </TouchableOpacity>
            )}
          </View>
        )}
      </View>
    );
  }
}

FormInput.propTypes = {
  fieldId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onInput: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  inputValue: PropTypes.any,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  errorMessageStyle: PropTypes.any,
  floatingLabel: PropTypes.string,
  helpText: PropTypes.string,
  helpTextStyle: PropTypes.any,
  inlineError: PropTypes.bool,
  inputStyles: PropTypes.array,
  inputHeight: PropTypes.number,
  insetShadow: PropTypes.bool,
  itemStyles: PropTypes.array,
  multiline: PropTypes.bool,
  multiline2: PropTypes.bool,
  multiline3: PropTypes.bool,
  maxLength: PropTypes.any,
  placeholderTextColor: PropTypes.string,
  secure: PropTypes.bool,
  showHide: PropTypes.bool,
};

export default connect(null, null)(FormInput);
