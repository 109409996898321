import React, { Component } from "react";

import { Col, Row, Grid } from "react-native-easy-grid";

import { navigate } from "_routes/RootNavigation";
import { styles2 } from "_pages/Site.style";
import { styles } from "./UploadDocumnets.style";

import { styles as global } from "_style/Global.style";
import {
  Image,
  StyleSheet,
  TouchableOpacity,
  View,
  TextInput,
} from "react-native";
import FormInput from "_components/common/FormInput.component";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Item, Input, Text, Button } from "native-base";

class UploadDocumentsModal extends Component {
  _handleFormInput = (fieldId, value) => {};

  render() {
    const canUpload = this.props.validationSize === "";
    return (
      <View style={[styles2.SiteCreateModalContent]}>
        <Grid>
          <Row style={{ marginTop: "2%" }}>
            <Col size={3}>
              <View style={[styles.mainView]}>
                {canUpload && (
                  <Text style={[styles.topText]}>{this.props.fileName}</Text>
                )}
                <Text style={[styles.validationText]}>
                  {this.props.validationSize}
                </Text>
                <Button
                  style={[styles.uploadBtn]}
                  onPress={this.props.filePick}
                >
                  <Text style={[styles.selectFileText]}>Select Files</Text>
                </Button>
                <Text style={[styles.msgText]}>
                  Max file size is 25 mb and <br />
                  accepts .jpeg .jpg .png .pdf file types only
                </Text>
              </View>
            </Col>
          </Row>
        </Grid>
        <Button
          style={[
            global.confirmationButton,
            {
              marginBottom: 12,
              marginTop: "3%",
              backgroundColor: canUpload ? "#EEB310" : "#424242",
              width: "380px",
              height: "40px",
              borderRadius: "5px",
              alignSelf: "center",
            },
          ]}
          onPress={canUpload ? this.props.onClick : undefined}
          disabled={!canUpload}
        >
          <Text style={[global.confirmationButtonText]}>UPLOAD</Text>
        </Button>
      </View>
    );
  }
}
UploadDocumentsModal.PropTypes = {
  onClick: PropTypes.func.isRequired,
  filePick: PropTypes.func.isRequired,
  fileName: PropTypes.any,
  validationSize: PropTypes.any,
};

export default connect(null, null)(UploadDocumentsModal);
