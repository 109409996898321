import { StyleSheet } from 'react-native';
import {
  roboto,
  yellow,
} from '_style/Global.style';

export const styles = StyleSheet.create({
  tickRowTop: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column'
  },
  tickRowBottom: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column'
  },
  tickRow: {
    breadCrumbRow: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column'
    },
  },
  tickText: {
    fontFamily: roboto,
    fontSize: 12,
    color: yellow,
    paddingLeft: 0,
    paddingRight:0 ,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});