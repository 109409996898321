import validation from 'validate.js';

// Common form field constraints.
export const EMAIL_CONSTRAINT = {
  presence: {
    allowEmpty: false,
    message: '^Email is required.'
  },
  email: {
    message: '^Email is invalid.'
  }
};
export const EMAIL_CONSTRAINT_ALLOW_EMPTY = {
  presence: {
    allowEmpty: true,
    message: '^Email is required.'
  },
  email: {
    message: '^Email is invalid.'
  }
};
export const PHONE_NUMBER_CONSTRAINT = {
  presence: {
    allowEmpty: false,
    message: '^Phone number is required.'
  },
  format: {
    pattern: /\+1(\d){10}/,
    message: '^###-###-#### (with or without dashes)'
  },
};
export const PASSWORD_CONSTRAINT = {
  presence: {
    allowEmpty: false,
    message: '^Password is required.'
  },
  length: {
    minimum: 8,
    message: '^Must be at least 8 characters',
  },
  format: {
    // At least 1 number, 1 lowercase
    pattern: /(?=.*\d)(?=.*[a-z]).*/,
    message: '^Must have at least 1 number and 1 lowercase letter'
  }
};
export const REQUIRED_CONSTRAINT = (fieldLabel) => {
  return {
    presence: {
      allowEmpty: false,
      message: `^${fieldLabel} is required.`,
    }
  };
};

/**
 * Attempts to format a phone number according to what Cognito expects it to be.
 * Cognito requires that phone numbers are in E.164 format, e.g. +15554443333
 *
 * @param {string} phoneNumber
 */
export const formatPhoneNumber = (phoneNumber) => {
  let formatted = phoneNumber;

  // Remove all non-digit characters
  formatted = formatted.replace(/\D/g, '');

  // Add +1 to the start of the phone number if it isn't there.
  if (formatted.length === 10) {
    formatted = `+1${formatted}`;
  } else if (formatted.length === 11) {
    formatted = `+${formatted}`;
  }

  return formatted;
};

/**
 * Common function for validating form data.
 *
 * @param {*} formData object of key - value pairs where the key is the field id and the
 *                     value is the value of the form field.
 * @param {*} constraints validation object. @see validation.js
 */
export const validateFormData = (formData, constraints) => {
  const errorMessages = {};

  const validationErrors = validation.validate(formData, constraints);

  Object.keys(validationErrors || {}).forEach((fieldId) => {
    errorMessages[fieldId] = validationErrors[fieldId][0];
  });

  return errorMessages;
};

/**
 * Common function for validating a single form field.
 *
 * @param {string} fieldId id of the form field
 * @param {*} constraints validation object @see validation.js
 */
export const validateFormField = (fieldId, fieldValue, constraints) => {

  const validationResult = validation.validate(
    { [fieldId]: fieldValue  },
    { [fieldId]: constraints[fieldId] }
  );

  return validationResult ? validationResult[fieldId][0] : null;
};

/**
 * Redux / React State safe way of updating a form data object. Throughout the application
 * components that have some sort of form can benefit from this as long as the
 * component has a 'formData' property in its state which is used to managed the form
 * fields.
 *
 * @param {*} fieldId
 * @param {*} value
 * @param {*} errorMessage
 */
export const updateFormData = (fieldId, value, errorMessage, formData, errorMessages) => {
  return {
    formData: {
      ...formData,
      [fieldId]: value,
    },
    errorMessages: {
      ...errorMessages,
      [fieldId]: errorMessage,
    },
  };
};

/**
 * Redux / React state safe way to check for password equality. This will return a new object
 * with updated form data and error messages for the calling component to use. Takes care of the
 * tricky logic in validating the two related password fields.
 *
 * @param {string} fieldId either 'password' or 'confirmPassword'
 * @param {string} value the valie of the field id passed in
 * @param {object} formData formData object from the component
 * @param {object} errorMessages errorMessages object from the component
 * @param {boolean} touchedConfirmPassword true if confirm password has been touched by the user, false otherwise.
 */
export const checkPasswordEquality = (fieldId, value, formData, errorMessages, touchedConfirmPassword, constraints) => {
  let errorMessage = null;
  let matchErrorMessage = null;

  // If the confirm password field is changing, or if we have ever touched the
  // confirm password field, check password equality.
  if (fieldId === 'confirmPassword' || touchedConfirmPassword) {

    // Pass both newPassword and confirmNewPassword to the validation function
    // since they are dependent on eachother. Pick out the confirmNewPassword
    // error message if there is one.
    matchErrorMessage = validateFormData(
      {
        password: fieldId === 'password' ? value : formData.password,
        confirmPassword: fieldId === 'confirmPassword' ? value : formData.confirmPassword,
      },
      constraints,
    )['confirmPassword'];

  }
  // If the password field is changing, validated as usual.
  if (fieldId === 'password') {
    errorMessage = validateFormField(fieldId, value, constraints);
  }

  // Updated form data, error messages, and touchedConfirmPassword
  return {
    formData: {
      ...formData,
      [fieldId]: value,
    },
    errorMessages: {
      ...errorMessages,
      'password': errorMessage,
      'confirmPassword': matchErrorMessage,
    },
    touchedConfirmPassword: touchedConfirmPassword || fieldId === 'confirmPassword',
  };
};
