import { StyleSheet } from 'react-native';
import {
  yellow,
  textDark,
  robotoCondensed,
} from '_style/Global.style';

export const styles = StyleSheet.create({
  throttleSettingsContainer: {
    marginTop: 10,
    alignItems: 'center',
  },
  explanationTextContainer: {
    borderBottomWidth: 1,
    borderBottomColor: '#424242',
    paddingBottom: 5,
  },
  explanationText: {
    fontFamily: robotoCondensed,
    fontSize: 14,
    textAlign: 'center',
  },

  inletDeadbandContainer: {
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: '#424242',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  increaseDecreaseButton: {
    width: 56,
    height: 56,
    marginLeft: 20,
    marginRight: 20,
  },

  inletTargetContainer: {
    alignItems: 'center',
    // backgroundColor: '#0F0F0E',
    borderRadius: 2,
    width: 96,
    height: 100,
    marginTop: 8,
    marginBottom: 8,
  },
  inletTargetLabel: {
    fontSize: 14,
    fontFamily: robotoCondensed,
    textAlign: 'center',
    width: 75,
  },
  inletTargetUnit: {
    fontSize: 18,
    fontFamily: robotoCondensed,
  },
});
