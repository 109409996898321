// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Amplify
import { Auth } from 'aws-amplify';

// Custom Components.
import FormInput from '_components/common/FormInput.component';
import {
  validateFormData,
  validateFormField,
  updateFormData,
  REQUIRED_CONSTRAINT,
} from '_util/form-util';

// UI Framework
import { Image, ImageBackground } from 'react-native';
import {
  Body,
  Button,
  Card,
  CardItem,
  Spinner,
  Text,
  View,
} from 'native-base';
import { Col, Grid } from 'react-native-easy-grid';

// Style
import { styles as global, placeholderTextColor, red } from '_style/Global.style';
import { styles } from '_components/auth/CustomVerifyContact.style';

const defaultState = {
  isGettingCode: false,
  isResending: false,
  isVerifying: false,
  failedVerification: false,
  failedGettingCode: false,
  willVerify: false,
  errorMessage: '',
  formData: {},
  errorMessages: {},
};

const constraints = {
  verification_code: REQUIRED_CONSTRAINT('Verification code'),
};

/**
 * The CustomForgotPassword component handles the logic that allows a user
 * to reset their password.
 */
class CustomVerifyContact extends Component {

  constructor(props) {
    super(props);

    this.state = defaultState;

    this._clearState = this._clearState.bind(this);

    this._verifyContact = this._verifyContact.bind(this);
    this._getVerifyContactCode = this._getVerifyContactCode.bind(this);
    this._skipVerification = this._skipVerification.bind(this);

    this._generateWillVerifyScreen = this._generateWillVerifyScreen.bind(this);
    this._generateVerifyScreen = this._generateVerifyScreen.bind(this);

    this._handleFormInput = this._handleFormInput.bind(this);
  }

  /**
   * Utility function to clear the state.
   */
  _clearState() {
    this.setState(defaultState);
  }

  /**
   * User chose to skip verification, just go to the home screen.
   */
  _skipVerification() {
    const { onStateChange, authData } = this.props;

    onStateChange('signedIn', authData);
    this._clearState();
  }

  /**
   * Triggers Cognito to generate a password reset text for the user.
   */
  async _getVerifyContactCode() {

    this.setState({
      isGettingCode: true,
    });

    try {
      await Auth.verifyCurrentUserAttribute('phone_number');

      this.setState({
        willVerify: true,
      });

    } catch (e) {
      this.setState({
        errorMessage: e.message,
        failedGettingCode: true,
      });
    } finally {
      this.setState({
        isGettingCode: false,
      });
    }
  }

  /**
   * Handle verification
   */
  async _verifyContact() {
    const { formData } = this.state;
    const { verification_code } = formData;
    const { onStateChange, authData } = this.props;

    const errorMessages = validateFormData(formData, constraints);
    if (Object.keys(errorMessages).length > 0) {
      this.setState({
        errorMessages,
      });
      return;
    }

    this.setState({
      isVerifying: true,
    });

    try {
      await Auth.verifyCurrentUserAttributeSubmit('phone_number', verification_code);

      onStateChange('signedIn', authData);
      this._clearState();

    } catch (e) {
      console.log(e);
      this.setState({
        failedVerification: true,
        errorMessage: e.message,
      });
    } finally {
      this.setState({
        isVerifying: false,
      });
    }
  }

  /**
   * Keeps track of what the user has entered into the form fields.
   */
  _handleFormInput(fieldId, value) {
    const { errorMessages, formData } = this.state;

    const errorMessage = validateFormField(fieldId, value, constraints);
    this.setState(updateFormData(
      fieldId,
      value,
      errorMessage,
      formData,
      errorMessages,
    ));
  }

  /**
   * Generates the first step of the password reset process, where the
   * user enters their username.
   */
  _generateWillVerifyScreen() {
    const { isGettingCode, failedGettingCode, errorMessage } = this.state;

    return (
      <>
        <Text style={ [global.textLight, global.fontSF, styles.paragraph] }>
          Account recovery requires verified contact information
        </Text>
        {
          isGettingCode ?
            <Spinner color={ red } />
            :
            <Button
              disabled={ isGettingCode }
              style={ [global.authFormButton] }
              onPress={ this._getVerifyContactCode }
            >
              <Text
                uppercase={ false }
                style={ [global.authFormButtonText] }
              >
                Verify
              </Text>
            </Button>
        }
        { !failedGettingCode ? null :
          <Text style={ styles.errorMessage }>
            { errorMessage }
          </Text>
        }
        {/* <Text style={ [global.textLight, global.fontSF, styles.backToSignIn] }>
        </Text> */}
          <Button transparent onPress={ this._skipVerification } style={[styles.backToSignIn]}>
            <Text uppercase={ false } style={ [global.textYellow, styles.signIn] }>
              Skip Verification
            </Text>
          </Button>
      </>
    );
  }

  /**
   * Generates the second step of the password reset process, where
   * the user enters the verification code and their new password.
   */
  _generateVerifyScreen() {
    const {
      isVerifying,
      isResending,
      failedVerification,
      errorMessage,
      errorMessages,
    } = this.state;

    return (
      <>
        <View style={[styles.formContainer]}>
          <View style={[global.authInputContainer]}>
            <FormInput
              fieldId={'verification_code'}
              placeholder={'Verification code'}
              placeholderTextColor={placeholderTextColor}
              helpText={'6 digit code texted to your phone'}
              helpTextStyle={global.authInputHelpText}
              inputStyles={[global.authInput]}
              itemStyles={[global.authItem]}
              onChange={this._handleFormInput}
              insetShadow={false}
              errorMessage={errorMessages['verification_code'] || ''}
              errorMessageStyle={[global.authInputErrorText]}
            />
          </View>
        </View>
        {
          isResending ?
            <Spinner color={ red } />
            :
            <Text style={ [global.textLight, global.fontSF, styles.resendCode] }>
              <Button transparent onPress={ this._resendCode }>
                <Text uppercase={ false } style={ [global.textYellow, styles.resendCodeText] }>
                  Resend Code
                </Text>
              </Button>
            </Text>
        }
        {
          isVerifying ?
            <Spinner color={ red } />
            :
            <Button
              disabled={ isVerifying }
              style={ [global.authFormButton] }
              onPress={ this._verifyContact }
            >
              <Text
                uppercase={ false }
                style={ [global.authFormButtonText, styles.verifyButton] }
              >
                Verify
              </Text>
            </Button>
        }
        { !failedVerification ? null :
          <Text style={ styles.errorMessage }>
            { errorMessage }
          </Text>
        }
      </>
    );
  }

  render() {
    const { willVerify } = this.state;
    const { authState } = this.props;

    if (authState !== 'verifyContact') {
      return null;
    }
    return (
      <ImageBackground
        style={ global.backgroundImage }
        source={require('_assets/images/auth-background.jpg')}
      >
        <Grid style={ global.background }>
          <Col style={ global.center }>
            <Card style={ [global.card, styles.card] }>
              <CardItem style={ global.cardItem }>
                <Body style={ global.center }>
                  <Image
                    style={ styles.headerImage }
                    source={require('_assets/images/bazooka_farmstar.png')}
                  />
                  {
                    !willVerify ?
                      this._generateWillVerifyScreen()
                      :
                      this._generateVerifyScreen()
                  }
                </Body>
              </CardItem>
            </Card>
          </Col>
        </Grid>
      </ImageBackground>
    );
  }
}

CustomVerifyContact.propTypes = {
  authData: PropTypes.any,
  authState: PropTypes.string,
  onStateChange: PropTypes.func,
};

export default connect(null, null)(CustomVerifyContact);


