// React
import React, { Component } from 'react';

// Redux
import { connect } from 'react-redux';

// Components
import NotificationsList from '_components/Notifications.component';

// UI Framework
import { View } from 'native-base';
import { Grid, Row, Col } from 'react-native-easy-grid';

// Style
import { styles as global } from '_style/Global.style';
import { styles } from '_pages/Notifications.style';

class Notifications extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <View style={ styles.main }>
        <NotificationsList />
      </View>
    );
  }
}

export default connect(null, null)(Notifications);


