/**
 * Generic action creator. Useful for simplifying the boilerplate
 * for the request > success / fail pattern for most async actions
 * in the application.
 *
 * @param {*} type
 * @param  {...any} argNames
 */
export const makeActionCreator = (type, ...argNames) => {
  return function (...args) {
    const action = { type };
    argNames.forEach((arg, index) => {
      action[argNames[index]] = args[index];
    });
    return action;
  };
};

/**
 * Generic reducer creator. Useful for simplifying the boilerplate code
 * in creating reducers for the redux store.
 *
 * @param {*} initialState initial state of this reducer's slice of the store.
 * @param {*} handlers action handlers.
 */
export const createReducer = (initialState, handlers) => {
  return function reducer(state = initialState, action) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    } else {
      return state;
    }
  };
};

// Global Clear State Action.
export const CLEAR_STATE = `app/admin/CLEAR_STATE`;
export const clearState = makeActionCreator(CLEAR_STATE);
