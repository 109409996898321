import React from "react";
import { Text } from "native-base";
import { TouchableOpacity, View } from "react-native";
import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  buttonWrapper: {
    backgroundColor: "transparent",
    borderRadius: 5,
    justifyContent: "center",
    height: 19,
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    width: "fit-content",
    marginRight: 10,
    marginLeft: 10,
    marginTop: 5,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    borderColor: "#EEB310",
    borderWidth: 1,
  },
  textWrapper: {
    width: "100%",
    alignItems: "center",
  },
  buttonLabel: {
    color: "#EEB310",
    textTransform: "uppercase",
    alignItems: "center",
    fontSize: 12,
    fontWeight: "bold",
    whiteSpace: "nowrap",
  },
});

export default function TopBarCalculateButton({ onPress, label }) {
  return (
    <TouchableOpacity onPress={onPress}>
      <View style={[styles.buttonWrapper]}>
        <View style={styles.textWrapper}>
          <Text style={styles.buttonLabel}>{label}</Text>
        </View>
      </View>
    </TouchableOpacity>
  );
}
