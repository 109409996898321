// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { updateUserDeviceCardSettings } from '_redux/dashboard/actions';
import { getUserDeviceCardSettingsById } from '_redux/dashboard/selectors';


import ShowHideGroup from '_components/device_settings/ShowHideGroup.component';
import LeftRightGroup from '_components/device_settings/LeftRightGroup.component';
// Redux
import { connect } from 'react-redux';

// Native Base
import {
  Text,
} from 'native-base';
import { Grid, Col } from 'react-native-easy-grid';

import { styles } from '_components/device_settings/HydroSettings.style';

class HydroSettings extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      showPos: this.props.deviceSettings.hydrostat === 'SHOW'
    };

    this._showHydroStat = this._showHydroStat.bind(this);
    this._hideHydroStat = this._hideHydroStat.bind(this);
    this._leftPosHydroStat = this._leftPosHydroStat.bind(this);
    this._rightPosHydroStat = this._rightPosHydroStat.bind(this);
  }


  // Note: you can uncomment the below and by doing so the hydrostat card will open
  // and close immediately as the user toggles between show/hide.
  _showHydroStat() {
    // const { deviceId } = this.props;
    this.props.settingsChanged({ 'hydrostat': 'SHOW' });
    this.setState({showPos: true});
    // this.props.updateUserDeviceCardSettings(deviceId, { 'hydrostat': 'SHOW' });
  }
  // Note: you can uncomment the below and by doing so the hydrostat card will open
  // and close immediately as the user toggles between show/hide.
  _hideHydroStat() {
    // const { deviceId } = this.props;
    this.props.settingsChanged({ 'hydrostat': 'HIDE' });
    this.setState({showPos: false});
    // this.props.updateUserDeviceCardSettings(deviceId, { 'hydrostat': 'HIDE' });
  }

  _leftPosHydroStat() {
    this.props.settingsChanged({ 'hydrostatpos': 'LEFT' });
  }

  _rightPosHydroStat() {
    this.props.settingsChanged({ 'hydrostatpos': 'RIGHT' });
  }

  render() {

    const { deviceSettings } = this.props;
    const { hydrostat, hydrostatpos = 'LEFT' } = deviceSettings;

    let positionComponents = null;

    if (this.state.showPos) {
      positionComponents =
        <React.Fragment>
          <Text style={[styles.hydroSettingsPositionTitle]}>POSITION</Text>,
          <LeftRightGroup left={hydrostatpos === 'LEFT'} onLeft={this._leftPosHydroStat} onRight={this._rightPosHydroStat} />
        </React.Fragment>;
    }
    
    return (
      <Grid>
        <Col style={[styles.hydroSettingsContainer]}>
          <Text style={[styles.hydroSettingsTitle]}>HYDROSTAT</Text>
          <ShowHideGroup show={ hydrostat === 'SHOW' } onShow={this._showHydroStat} onHide={this._hideHydroStat} />
          {positionComponents}
        </Col>
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    deviceSettings: getUserDeviceCardSettingsById(state, ownProps.deviceId),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserDeviceCardSettings: (deviceId, settings) => dispatch(updateUserDeviceCardSettings(deviceId, settings)),
  };
};


HydroSettings.propTypes = {
  deviceId: PropTypes.string.isRequired,
  deviceSettings: PropTypes.any.isRequired,
  settingsChanged: PropTypes.func.isRequired,
  updateUserDeviceCardSettings: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(HydroSettings);
