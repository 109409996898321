import { StyleSheet } from 'react-native';
import {
  robotoCondensed,
} from '_style/Global.style';

export const styles = StyleSheet.create({
  hydroSettingsContainer: {
    alignItems: 'center',
    marginTop: 30,
  },
  hydroSettingsTitle: {
    fontFamily: robotoCondensed,
    fontSize: 21,
    marginBottom: 20,
  },
  hydroSettingsPositionTitle: {
    fontFamily: robotoCondensed,
    fontSize: 21,
    marginVertical: 20,
  }
});
