// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { getSetPointsForDevice } from '_redux/iot/selectors';
import {
  convertKPAToPSI,
  convertPSIToKPA,
} from '_redux/iot/utils';

// Components
import TelemetryValue from '_components/device_card/TelemetryValue.component';

// UI Framework
import { Image, TouchableOpacity } from 'react-native';
import {
  Text,
  View,
} from 'native-base';
import { Grid, Col } from 'react-native-easy-grid';

import { styles } from '_components/device_settings/ThrottleSettings.style';

const MAX_INLET = convertPSIToKPA(300, false);
const INLET_STEP = convertPSIToKPA(1, false);
const MAX_OUTLET = convertPSIToKPA(100, false);
const OUTLET_STEP = convertPSIToKPA(5, false);

class ThrottleSettings extends Component {

  constructor(props) {
    super(props);

    const { setPoints } = props;
    const {
      p24,
      p70
    } = setPoints.P || {};

    this.state = {
      p24,
      p70,
    };

    this._decreaseInletDeadband = this._decreaseInletDeadband.bind(this);
    this._increaseInletDeadband = this._increaseInletDeadband.bind(this);

    this._decreaseOutletDeadband = this._decreaseOutletDeadband.bind(this);
    this._increaseOutletDeadband = this._increaseOutletDeadband.bind(this);
  }

  _decreaseInletDeadband() {
    const { settingsChanged } = this.props;
    const { p70 } = this.state;

    const newP70 = Math.max(0, p70 - INLET_STEP);

    settingsChanged({
      P: {
        p70: newP70,
      },
    });

    this.setState({
      p70: newP70,
    });
  }

  _increaseInletDeadband() {
    const { settingsChanged } = this.props;
    const { p70 } = this.state;

    const newP70 = Math.min(MAX_INLET, p70 + INLET_STEP);

    settingsChanged({
      P: {
        p70: newP70,
      },
    });

    this.setState({
      p70: newP70,
    });
  }

  _decreaseOutletDeadband() {
    const { settingsChanged } = this.props;
    const { p24 } = this.state;

    const newP24 = Math.max(0, p24 - OUTLET_STEP);

    settingsChanged({
      P: {
        p24: newP24,
      },
    });

    this.setState({
      p24: newP24,
    });
  }

  _increaseOutletDeadband() {
    const { settingsChanged } = this.props;
    const { p24 } = this.state;

    const newP24 = Math.min(MAX_OUTLET, p24 + OUTLET_STEP);

    settingsChanged({
      P: {
        p24: newP24,
      },
    });

    this.setState({
      p24: newP24,
    });
  }

  componentDidUpdate(prevProps) {
    const { setPoints } = this.props;
    const {
      p24,
      p70
    } = setPoints.P || {};

    const { setPoints: prevSetPoints } = prevProps;
    const {
      p24: prevp24,
      p70: prevp70,
    } = prevSetPoints.P || {};

    if (p24 !== prevp24 || p70 !== prevp70) {
      this.setState({
        p24,
        p70,
      });
    }
  }

  render() {
    const {
      p24,
      p70
    } = this.state;

    return (
      <Grid>
        <Col style={[styles.throttleSettingsContainer]}>
          <View style={[styles.explanationTextContainer]}>
            <Text style={[styles.explanationText]}>
              PRESSURE MODE DEADBAND IS A REGION, +/- THE TARGET PRESSURE, WHERE SPEED WILL NOT CHANGE.
            </Text>
          </View>

          <View style={[styles.inletDeadbandContainer]}>
            <TouchableOpacity onPress={this._decreaseInletDeadband}>
              <Image
                style={[styles.increaseDecreaseButton]}
                source={require('_assets/images/minus-button.png')}
              />
            </TouchableOpacity>

            <View style={[styles.inletTargetContainer]}>
              <Text style={[styles.inletTargetLabel]}>
                INLET DEADBAND
              </Text>
              <TelemetryValue value={convertKPAToPSI(p70)} fontSize={36}/>
              <Text style={[styles.inletTargetUnit]}>
                PSI
              </Text>
            </View>

            <TouchableOpacity onPress={this._increaseInletDeadband}>
              <Image
                style={[styles.increaseDecreaseButton]}
                source={require('_assets/images/plus-button.png')}
              />
            </TouchableOpacity>
          </View>

          <View style={[styles.inletDeadbandContainer]}>
            <TouchableOpacity onPress={this._decreaseOutletDeadband}>
              <Image
                style={[styles.increaseDecreaseButton]}
                source={require('_assets/images/minus-button.png')}
              />
            </TouchableOpacity>

            <View style={[styles.inletTargetContainer]}>
              <Text style={[styles.inletTargetLabel]}>
                OUTLET DEADBAND
              </Text>
              <TelemetryValue value={convertKPAToPSI(p24)} fontSize={36}/>
              <Text style={[styles.inletTargetUnit]}>
                PSI
              </Text>
            </View>

            <TouchableOpacity onPress={this._increaseOutletDeadband}>
              <Image
                style={[styles.increaseDecreaseButton]}
                source={require('_assets/images/plus-button.png')}
              />
            </TouchableOpacity>
          </View>
        </Col>
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    setPoints: getSetPointsForDevice(state, ownProps.deviceId),
  };
};


ThrottleSettings.propTypes = {
  deviceId: PropTypes.string.isRequired,
  setPoints: PropTypes.any.isRequired,
  settingsChanged: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, null)(ThrottleSettings);
