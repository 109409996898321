import React from "react";
import { Col, Row, Grid } from "react-native-easy-grid";
import { Text, Button } from "native-base";
import { Image, StyleSheet, TouchableOpacity, View } from "react-native";
import ToolBoxButtons from "../common/ToolBoxButton.component";
import Modal from "_components/common/Modal.component";
import FormInput from "_components/common/FormInput.component";
import IconButtons from "../common/IconButtons";
import { styles as global } from "_style/Global.style";
import { styles } from "./TopBarPopup.style";

import { DistanceUtil } from "../../../util/distance.util";
import { ElevationUtil } from "../../../util/elevation.util";
import ElevationChat from "./ElevationChat";
import AccessControl, {
  ADMIN,
  MANUFACTURER,
  CREW_LEADER,
  CREW_MEMBER,
  DEALER,
  OWNER,
} from "_components/auth/AccessControl.component";
import {
  CalculateTimeout,
  DrawElementConst,
  ToolTypes,
  ToolsButtons,
  calculateStrokeWeight,
  StrokeMinWeight,
} from "./mapUtil";
import TopBarCalculateButton from "./TopBarCalculateButton";

const TopPopup = ({
  title,
  subTitle,
  map,
  primaryButtonText,
  secondaryButtonText,
  modalData,
  visibility,
  onEditItem,
}) => {
  const [selectedElement, setSelectedElement] = React.useState(null);
  const [showPopup, setShowPopup] = React.useState(false);
  const [type, setType] = React.useState(null);
  const [tools, setTools] = React.useState(ToolsButtons);
  const [errorMessages, setErrorMessages] = React.useState({});

  React.useEffect(() => {
    setShowPopup(visibility === "visible");
    if (!!modalData) {
      setSelectedElement(modalData.selectedElement);
      setType(modalData.type);
    }
  }, [visibility]);

  const onCloseModalClick = () => {
    onEditItem(null);
    setSelectedElement(null);
    setErrorMessages({});
    setShowPopup(false);
  };

  const onSaveModalClick = () => {
    const hasErrors = Object.keys(errorMessages).find(
      (key) => errorMessages[key] !== null
    )
      ? true
      : false;
    if (!hasErrors) {
      onEditItem(selectedElement);
      setSelectedElement(null);
      setShowPopup(false);
    }
  };

  const _handleFormInput = (field, value) => {
    const temp = Object.assign({}, selectedElement);
    temp[field] = value;
    setSelectedElement(temp);
  };

  const _handelDiameterChange = (field, value) => {
    const { maxWeight } = tools.find(
      (tool) => tool.name === selectedElement.drawType
    ).data;
    errorMessages[field] = null;
    const temp = Object.assign({}, selectedElement);
    if (maxWeight && maxWeight >= Number(value) && 1 <= Number(value)) {
      temp.strokeWeight = calculateStrokeWeight(
        map.zoom,
        Number(value),
        StrokeMinWeight
      );
      temp.actualStrokeWeight = Number(value);
    } else {
      errorMessages[field] = `Diameter should be 1 to ${maxWeight}`;
    }
    setErrorMessages(errorMessages);
    setSelectedElement(temp);
  };

  const _setChangeState = (value, field) => {
    const temp = Object.assign({}, selectedElement);
    temp[field] = value;
    setSelectedElement(temp);
    if (field === "strokeWeight") {
      _handelDiameterChange(field, value);
    }
  };

  return (
    <Modal
      isVisible={showPopup}
      title={title}
      subTitle={subTitle}
      hideExitIcon={true}
      onClose={onCloseModalClick}
      content={
        showPopup ? (
          <>
            <View
              style={{
                width: 425,
                marginLeft: 75,
                marginRight: 75,
                paddingBottom: 15,
              }}
            >
              <Grid>
                <Row style={{ marginBottom: "3%", minHeight: 40 }}>
                  <Col size={3}>
                    <FormInput
                      fieldId={"name"}
                      onChange={_handleFormInput}
                      onInput={(e) => _setChangeState(e, "name")}
                      placeholder={"Item name"}
                      itemStyles={[global.item]}
                      inputStyles={[global.input]}
                      defaultValue={selectedElement?.name}
                      maxLength={20}
                    />
                  </Col>
                </Row>
                <Row style={{ marginBottom: "3%", minHeight: 80 }}>
                  <Col size={3} style={{}}>
                    <FormInput
                      fieldId={"notes"}
                      onChange={_handleFormInput}
                      onInput={(e) => _setChangeState(e, "notes")}
                      placeholder={"Add your notes here"}
                      itemStyles={[global.item]}
                      inputStyles={[global.input]}
                      defaultValue={selectedElement?.notes}
                      multiline={true}
                      multiline2={true}
                      maxLength={50}
                    />
                    {/* <Text style={{textAlign:"right",marginTop:2,marginBottom:"4%" ,paddingBottom:6,color:"#9F9F9F"}}>
                      {selectedElement.notes?selectedElement.notes.length:"0"}/50
                    </Text> */}
                  </Col>
                </Row>
                {selectedElement.drawType === ToolTypes.HOSE ||
                selectedElement.drawType === ToolTypes.PIPE_LINE ||
                selectedElement.drawType === ToolTypes.SET_LAYOUT ? (
                  <Row style={{ marginBottom: "3%", minHeight: 40 }}>
                    <Col style={{ textAlign: "right" }} size={2}>
                      <Text
                        style={{
                          lineHeight: 34,
                          color: "#EEB310",
                          textTransform: "capitalize",
                        }}
                      >
                        {`${selectedElement.drawType} Diameter (In):`}{" "}
                      </Text>
                    </Col>
                    <Col size={1}>
                      <FormInput
                        fieldId={"strokeWeight"}
                        onChange={_handelDiameterChange}
                        onInput={(e) => _setChangeState(e, "strokeWeight")}
                        placeholder={""}
                        itemStyles={[global.item, { textAlign: "right" }]}
                        inputStyles={[global.input, { textAlign: "right" }]}
                        defaultValue={
                          selectedElement?.actualStrokeWeight ||
                          selectedElement?.strokeWeight
                        }
                        errorMessage={errorMessages["strokeWeight"] || ""}
                      />
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
              </Grid>

              <Button
                style={[
                  global.confirmationButton,
                  { marginBottom: 4, marginTop: "5%" },
                ]}
                onPress={onSaveModalClick}
              >
                <Text style={[global.confirmationButtonText]}>
                  {primaryButtonText}
                </Text>
              </Button>
              <Button
                style={[
                  global.cancelButton,
                  { marginTop: "2%", marginBottom: 14, paddingBottom: "2%" },
                ]}
                onPress={onCloseModalClick}
              >
                <Text style={[global.cancelButtonText]}>
                  {secondaryButtonText}
                </Text>
              </Button>
            </View>
          </>
        ) : null
      }
    />
  );
};

const TopBarPopup = React.forwardRef(
  (
    {
      selected,
      groupEditMode,
      mode,
      map,
      onEditItem,
      onSaveMapData,
      onDeleteItem,
      backBtnClick,
      readOnly,
      onEditMap,
      onAddRemoveToGroup,
      onEditGroupComplete,
      onAddRemoveToGroupCancel,
      isFieldAvalableInDroup,
      getGroupElement,
    },
    ref
  ) => {
    const [selectedElement, setSelectedElement] = React.useState(null);
    const [selectedGroup, setSelectedGroup] = React.useState(null);
    const [groupEdit, setGroupEdit] = React.useState(false);
    const [popupData, setPopupData] = React.useState(null);
    const [showEditModal, setShowEditModal] = React.useState(
      DrawElementConst.hidden
    );
    const [tempActions, setTempActions] = React.useState([]);
    const [calculateTimeout, setCalculateTimeout] = React.useState(-1);

    React.useImperativeHandle(ref, () => ({
      addToGroup(item) {
        onAddToGroup(item);
      },
      removeFromGroup(item) {
        onRemoveFromGroup(item);
      },
    }));

    React.useEffect(() => {
      if (selected) {
        setPopupData({
          selectedElement: selected,
          type: selected.type,
        });
        if (selected.type === DrawElementConst.group) {
          setSelectedGroup(selected);
          if (selected.newGroup) {
            setShowEditModal(DrawElementConst.hidden);
            setTimeout(() => setShowEditModal(DrawElementConst.visible));
            setGroupEdit(true);
          }
          setGroupEdit(selected.state === DrawElementConst.EDIT);
        } else {
          setSelectedElement(selected);
          if (
            !!selectedGroup &&
            selectedGroup.state === DrawElementConst.EDIT
          ) {
            setGroupEdit(false);
            setTimeout(() => setGroupEdit(true));
          }
        }
        if (
          (selected.drawType === ToolTypes.FIELD ||
            selected.drawType === ToolTypes.PIT) &&
          selected.calulatedValues.totalArea === null
        ) {
          clearTimeout(calculateTimeout);
          const calcTimeout = setTimeout(
            () => onCalculateEvent(selected),
            CalculateTimeout * 1000
          );
          setCalculateTimeout(calcTimeout);
        }
        if (
          (selected.drawType === ToolTypes.HOSE ||
            selected.drawType === ToolTypes.PIPE_LINE ||
            selected.drawType === ToolTypes.SET_LAYOUT) &&
          selected.calulatedValues.length === null
        ) {
          clearTimeout(calculateTimeout);
          const calcTimeout = setTimeout(
            () => onCalculateEvent(selected),
            CalculateTimeout * 1000
          );
          setCalculateTimeout(calcTimeout);
        }
      } else {
        setSelectedGroup(null);
        setSelectedElement(null);
        setGroupEdit(false);
      }
    }, [selected]);

    React.useEffect(() => {
      setGroupEdit(groupEditMode);
    }, [groupEditMode]);

    const onEditInfo = (e) => {
      setShowEditModal(DrawElementConst.hidden);
      setTimeout(() => setShowEditModal(DrawElementConst.visible));
    };

    const onDelete = (e) => {
      onDeleteItem(e);
    };

    const onCalculateEvent = async (selected) => {
      clearTimeout(calculateTimeout);
      onCaluclateHandeller(selected);
    };

    function calculteElementArea(selected) {
      const SQM_Fraction = 0.00025;
      const calulatedArea = DistanceUtil.getAreaByPosition(selected.path);
      const totalArea = calulatedArea * SQM_Fraction;
      return DistanceUtil.roundWithDecimals(totalArea, 2);
    }

    function calculteElementLength(selected) {
      const Length_Fraction = 3.2808;
      const calulatedDistance = DistanceUtil.getDistanceByPosition(
        selected.path
      );
      const totleLength =
        calulatedDistance &&
        calulatedDistance.reduce((acc, location) => {
          acc = acc + location.results;
          return acc;
        }, 0);
      return DistanceUtil.roundWithDecimals(totleLength * Length_Fraction, 0);
    }

    async function calculteElementElevation(selected) {
      const Length_Fraction = 3.2808;
      const calulatedElevation = await ElevationUtil.getElavationByPosition(
        selected.path
      );
      if (calulatedElevation.length > 0) {
        const elevationGap =
          calulatedElevation[calulatedElevation.length - 1].elevation -
          calulatedElevation[0].elevation;
        return DistanceUtil.roundWithDecimals(
          elevationGap * Length_Fraction,
          0
        );
      }
      return DistanceUtil.roundWithDecimals(0, 2);
    }

    const onCaluclateHandeller = async (selected) => {
      if (!selected.calulatedValues) {
        selected.calulatedValues = {
          totalArea: null,
          length: null,
          elevation: null,
          diameter: null,
          eleAndLengthChartData: null,
        };
      }
      if (selected.type === DrawElementConst.group) {
        const geoupFileds = getGroupElement(selected.id);
        const totalFieldArea = geoupFileds.reduce((acc, filed) => {
          acc = acc + calculteElementArea(filed);
          return acc;
        }, 0);
        selected.calulatedValues.totalArea = DistanceUtil.roundWithDecimals(
          totalFieldArea,
          2
        );
      } else {
        if (
          selected.drawType === ToolTypes.HOSE ||
          selected.drawType === ToolTypes.PIPE_LINE ||
          selected.drawType === ToolTypes.SET_LAYOUT
        ) {
          selected.calulatedValues.length = calculteElementLength(selected);
          selected.calulatedValues.elevation = await calculteElementElevation(
            selected
          );
        } else if (
          selected.drawType === ToolTypes.PIT ||
          selected.drawType === ToolTypes.FIELD
        ) {
          selected.calulatedValues.totalArea = calculteElementArea(selected);
        }
      }
      setSelectedElement(null);
      setTimeout(() => {
        setSelectedElement(selected);
      }, 0);
    };

    const onEditGroupCancel = () => {
      setGroupEdit(false);
      selectedGroup.state = DrawElementConst.VIEW;
      onAddRemoveToGroupCancel(
        selectedGroup.id,
        tempActions,
        DrawElementConst.VIEW
      );
      setTempActions([]);
    };

    const onEditGroupSave = () => {
      setGroupEdit(false);
      selectedGroup.state = DrawElementConst.VIEW;
      setTempActions([]);
      onEditGroupComplete(selectedGroup.id);
    };

    const onEditComplete = (item) => {
      onEditItem(item);
      setShowEditModal(DrawElementConst.hidden);
    };

    const onAddToGroup = (item) => {
      const found = item.group.find((groupId) => groupId === selectedGroup.id);
      if (!found) {
        item.group.push(selectedGroup.id);
        item.hightlight = true;
      }
      const newAction = {
        action: DrawElementConst.GROUP,
        item: item,
      };
      addToTempAction(newAction);
      onAddRemoveToGroup(selectedGroup.id, item, DrawElementConst.EDIT);
    };

    const onRemoveFromGroup = (item) => {
      const foundIndex = item.group.findIndex(
        (groupId) => groupId === selectedGroup.id
      );
      if (foundIndex > -1) {
        item.group.splice(foundIndex, 1);
        item.hightlight = false;
      }
      const newAction = {
        action: DrawElementConst.UNGROUP,
        item: item,
      };
      addToTempAction(newAction);
      onAddRemoveToGroup(selectedGroup.id, item, DrawElementConst.EDIT);
    };

    const isCalculatableGroup = () => {
      if (selectedGroup) {
        return isFieldAvalableInDroup(selectedGroup.id);
      }
      return false;
    };

    function addToTempAction(newAction) {
      if (
        !tempActions.find(
          (action) => JSON.stringify(newAction) === JSON.stringify(action)
        )
      ) {
        tempActions.push(newAction);
      }
      setTempActions(tempActions);
    }

    function numberWithCommas(x) {
      return !!x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0";
    }

    return (
      <>
        <TopPopup
          map={map}
          title={
            popupData && popupData.selectedElement.newGroup
              ? "CREATE NEW GROUP"
              : "EDIT ITEM DETAILS"
          }
          subTitle={
            popupData && popupData.selectedElement.newGroup
              ? "Add group details"
              : "Update the item details"
          }
          primaryButtonText={"SAVE DETAILS"}
          secondaryButtonText={"CLOSE"}
          modalData={popupData}
          visibility={showEditModal}
          onEditItem={onEditComplete}
        />
        <View
          style={{
            padding: "10px",
            flex: 1,
            zIndex: 1,
            position: "absolute",
            left: "0px",
            right: "0px",
            top: "0px",
            backgroundColor: "#1A1A1A",
            flexDirection: "row",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              flex: 1,
              width: "20%",
              minWidth: 218,
            }}
          >
            <TouchableOpacity
              onClick={backBtnClick}
              style={[styles.backButton, { marginTop: 6, marginBottom: 4 }]}
            >
              <View style={[styles.backButtonCont]}>
                <Image
                  source={require("_assets/images/back-icon.svg")}
                  style={[styles.backButtonImg]}
                />
                <Text style={styles.backButtonText}>BACK</Text>
              </View>
            </TouchableOpacity>

            {readOnly ? (
              <TouchableOpacity
                style={[styles.editButton, { marginTop: 6, marginBottom: 4 }]}
                onPress={onEditMap}
              >
                <AccessControl
                  allowedGroups={[
                    ADMIN,
                    MANUFACTURER,
                    CREW_LEADER,
                    DEALER,
                    OWNER,
                  ]}
                >
                  <View style={[styles.backButtonCont]}>
                    <Image
                      source={require("_assets/images/edit-icon.png")}
                      style={[styles.editButtonImg]}
                    />
                    <Text style={styles.editButtonText}>EDIT MAP</Text>
                  </View>
                </AccessControl>
                <AccessControl allowedGroups={[CREW_MEMBER]}>
                  <View style={[styles.backButtonCont]}>
                    <Image
                      source={require("_assets/images/eye-icon.svg")}
                      style={[styles.editButtonImg, { height: 11, width: 18 }]}
                    />
                    <Text style={styles.editButtonText}>VIEW GROUPS</Text>
                  </View>
                </AccessControl>
              </TouchableOpacity>
            ) : (
              <>
                <AccessControl allowedGroups={[CREW_MEMBER]}>
                  <TouchableOpacity
                    style={[
                      styles.editButton,
                      { marginTop: 6, marginBottom: 4 },
                    ]}
                    onClick={backBtnClick}
                  >
                    <View style={[styles.backButtonCont]}>
                      <Image
                        source={require("_assets/images/eye-icon.svg")}
                        style={[
                          styles.editButtonImg,
                          { height: 11, width: 18 },
                        ]}
                      />
                      <Text style={styles.editButtonText}>HIDE GROUPS</Text>
                    </View>
                  </TouchableOpacity>
                </AccessControl>
                <AccessControl
                  allowedGroups={[
                    ADMIN,
                    MANUFACTURER,
                    CREW_LEADER,
                    DEALER,
                    OWNER,
                  ]}
                >
                  <TouchableOpacity
                    style={[
                      styles.editButton,
                      { marginTop: 6, marginBottom: 4 },
                    ]}
                    onPress={onSaveMapData}
                  >
                    <View style={[styles.backButtonCont]}>
                      <Image
                        source={require("_assets/images/savemap-icon.svg")}
                        style={[styles.editButtonImg]}
                      />
                      <Text style={styles.editButtonText}>SAVE MAP</Text>
                    </View>
                  </TouchableOpacity>
                </AccessControl>
              </>
            )}
          </View>
          <View
            style={{
              flexDirection: "row",
              width: "80%",
              flex: 4,
              justifyContent: "flex-end",
            }}
          >
            {selected ? (
              <>
                <View
                  style={{
                    flex: 4,
                    flexDirection: "column",
                    width: "80%",
                    justifyContent: "flex-start",
                    backgroundColor: "#000000",
                    borderRadius: 8,
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "flex-start",
                    }}
                  >
                    <View
                      style={{
                        width: "50%",
                        paddingLeft: 10,
                        paddingRight: 10,
                      }}
                    >
                      <Text
                        style={{
                          color: "#EEB310",
                          marginTop: 5,
                          fontSize: 14,
                          marginRight: 10,
                        }}
                      >
                        {selected.name}
                        {selected?.drawType === ToolTypes.HOSE ||
                        selected?.drawType === ToolTypes.PIPE_LINE ||
                        selected?.drawType === ToolTypes.SET_LAYOUT
                          ? ` - ${DistanceUtil.roundWithDecimals(
                              selected.actualStrokeWeight ||
                                selectedElement?.strokeWeight,
                              2
                            )} inches`
                          : ""}
                      </Text>
                    </View>

                    <AccessControl
                      allowedGroups={[
                        ADMIN,
                        MANUFACTURER,
                        CREW_LEADER,
                        CREW_MEMBER,
                        DEALER,
                        OWNER,
                      ]}
                    >
                      {!groupEdit ? (
                        <>
                          {(selected?.type === DrawElementConst.group &&
                            isCalculatableGroup()) ||
                          selected?.drawType === ToolTypes.FIELD ||
                          selected?.drawType === ToolTypes.PIT ? (
                            <>
                              <View
                                style={{
                                  flexDirection: "row",
                                  width: "50%",
                                  justifyContent: "flex-end",
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                  textAlign: "right",
                                }}
                              >
                                <Text
                                  style={{
                                    color: "white",
                                    marginTop: 5,
                                    marginBottom: 2,
                                    fontSize: 14,
                                    marginRight: 10,
                                  }}
                                >
                                  TOTAL SIZE:
                                </Text>
                                {selected.calulatedValues &&
                                !!selected.calulatedValues.totalArea ? (
                                  <Text
                                    style={{
                                      color: "white",
                                      marginTop: 5,
                                      marginBottom: 2,
                                      fontSize: 14,
                                      marginRight: 10,
                                    }}
                                  >
                                    {selected.calulatedValues.totalArea} acres
                                  </Text>
                                ) : (
                                  <>
                                    <TopBarCalculateButton
                                      style={{
                                        color: "white",
                                        marginTop: 5,
                                        marginBottom: 2,
                                        fontSize: 14,
                                        marginRight: 10,
                                      }}
                                      label={"Calculate"}
                                      onPress={() => onCalculateEvent(selected)}
                                      onClick={() => console.log("cl")}
                                    />
                                  </>
                                )}
                              </View>
                            </>
                          ) : (
                            <>
                              {selected?.drawType === ToolTypes.HOSE ||
                              selected?.drawType === ToolTypes.PIPE_LINE ||
                              selected?.drawType === ToolTypes.SET_LAYOUT ? (
                                <>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      width: "50%",
                                      justifyContent: "flex-end",
                                      paddingLeft: 10,
                                      paddingRight: 10,
                                      textAlign: "right",
                                    }}
                                  >
                                    {selected.calulatedValues &&
                                    !!selected.calulatedValues.length ? (
                                      <Text
                                        style={{
                                          color: "white",
                                          marginTop: 5,
                                          marginBottom: 2,
                                          textAlign: "left",
                                          fontSize: 14,
                                          marginRight: 10,
                                        }}
                                      >
                                        ELEVATION:{" "}
                                        {numberWithCommas(
                                          selected.calulatedValues.elevation
                                        )}{" "}
                                        ft{"    "}
                                        TOTAL LENGTH:{" "}
                                        {numberWithCommas(
                                          selected.calulatedValues.length
                                        )}{" "}
                                        ft
                                      </Text>
                                    ) : (
                                      <>
                                        <Text
                                          style={{
                                            color: "white",
                                            marginTop: 5,
                                            marginBottom: 2,
                                            fontSize: 14,
                                            marginRight: 10,
                                          }}
                                        >
                                          ELEVATION & LENGTH:
                                        </Text>
                                        <TopBarCalculateButton
                                          style={{
                                            color: "white",
                                            marginTop: 5,
                                            marginBottom: 2,
                                            fontSize: 14,
                                            marginRight: 10,
                                          }}
                                          label={"Calculate"}
                                          onPress={() =>
                                            onCalculateEvent(selected)
                                          }
                                          onClick={() => console.log("cl")}
                                        />
                                      </>
                                    )}
                                  </View>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </AccessControl>
                  </View>

                  <View
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      paddingLeft: 10,
                      paddingRight: 10,
                      paddingBottom: 2,
                      width: "99%",
                      overflow: "hidden",
                    }}
                  >
                    <Text
                      style={{
                        color: "white",
                        marginTop: -4,
                        fontSize: 12,
                        marginRight: 10,
                        height: 17,
                        whiteSpace: "nowrap",
                      }}
                    >
                      {selected.notes || " "}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    minWidth: 150,
                    width: "20%",
                    justifyContent: "flex-end",
                  }}
                >
                  <AccessControl
                    allowedGroups={[
                      ADMIN,
                      MANUFACTURER,
                      CREW_LEADER,
                      DEALER,
                      OWNER,
                    ]}
                  >
                    {selected.type !== DrawElementConst.group ? (
                      <>
                        {groupEdit ? (
                          <>
                            {selectedElement &&
                            selectedElement.group &&
                            selectedElement.group.find(
                              (groupId) => groupId === selectedGroup.id
                            ) ? (
                              <ToolBoxButtons
                                label={"Ungroup"}
                                onPress={() => onRemoveFromGroup(selected)}
                                onClick={() => console.log("cl")}
                              />
                            ) : (
                              <>
                                {selected.groupable ? (
                                  <ToolBoxButtons
                                    label={"Group"}
                                    onPress={() => onAddToGroup(selected)}
                                    onClick={() => console.log("cl")}
                                  />
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                            <ToolBoxButtons
                              label={"Cancel"}
                              buttonWithText={false}
                              width={450}
                              onPress={onEditGroupCancel}
                              onClick={() => console.log("cl")}
                              buttonStyle={{ justifyContent: "center" }}
                            />
                          </>
                        ) : (
                          <>
                            <TouchableOpacity
                              onClick={onEditInfo}
                              style={[
                                styles.smallButton,
                                {
                                  marginTop: 6,
                                  marginRight: "1.2%",
                                  marginBottom: 4,
                                },
                              ]}
                            >
                              <View style={[styles.smallButtonCont]}>
                                <Text style={styles.smallButtonText}>
                                  EDIT INFO
                                </Text>
                              </View>
                            </TouchableOpacity>
                            {mode === DrawElementConst.EDIT ? (
                              <TouchableOpacity
                                onClick={onDelete}
                                style={[
                                  styles.smallButton,
                                  {
                                    marginTop: 6,
                                    marginRight: "1.2%",
                                    width: 80,
                                    marginBottom: 4,
                                  },
                                ]}
                              >
                                <View style={[styles.smallButtonCont]}>
                                  <Text style={styles.smallButtonText}>
                                    DELETE ITEM
                                  </Text>
                                </View>
                              </TouchableOpacity>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {!groupEdit ? (
                          <TouchableOpacity
                            onClick={onDelete}
                            style={[
                              styles.smallButton,
                              {
                                marginTop: 6,
                                marginRight: "1.2%",
                                width: 88,
                                marginBottom: 4,
                              },
                            ]}
                          >
                            <View style={[styles.smallButtonCont]}>
                              <Text style={styles.smallButtonText}>
                                DELETE GROUP
                              </Text>
                            </View>
                          </TouchableOpacity>
                        ) : (
                          <>
                            <TouchableOpacity
                              onClick={onEditInfo}
                              style={[
                                styles.smallButton,
                                {
                                  marginTop: 6,
                                  marginRight: "1.2%",
                                  marginBottom: 4,
                                },
                              ]}
                            >
                              <View style={[styles.smallButtonCont]}>
                                <Text style={styles.smallButtonText}>
                                  EDIT INFO
                                </Text>
                              </View>
                            </TouchableOpacity>
                            <TouchableOpacity
                              onClick={onEditGroupSave}
                              style={[
                                styles.smallButton,
                                {
                                  marginTop: 6,
                                  marginRight: "1.2%",
                                  marginBottom: 4,
                                },
                              ]}
                            >
                              <View style={[styles.smallButtonCont]}>
                                <Text style={styles.smallButtonText}>SAVE</Text>
                              </View>
                            </TouchableOpacity>
                            <TouchableOpacity
                              onClick={onEditGroupCancel}
                              style={[
                                styles.smallButton,
                                {
                                  marginTop: 6,
                                  marginRight: "1.2%",
                                  marginBottom: 4,
                                },
                              ]}
                            >
                              <View style={[styles.smallButtonCont]}>
                                <Text style={styles.smallButtonText}>
                                  CANCEL
                                </Text>
                              </View>
                            </TouchableOpacity>
                          </>
                        )}
                      </>
                    )}
                  </AccessControl>
                </View>
              </>
            ) : (
              <></>
            )}
          </View>
        </View>
      </>
    );
  }
);
export default React.memo(TopBarPopup);
