// React
import React, { Component } from "react";
import PropTypes from "prop-types";

import { Linking } from "react-native";
import { getQueryStringParameters } from "_util";

// Redux
import { connect } from "react-redux";
import { INVITE_CODE_KEY } from "_redux/user/utils";

// Amplify
import { Auth } from "aws-amplify";

import FormInput from "_components/common/FormInput.component";
import {
  validateFormData,
  validateFormField,
  updateFormData,
  REQUIRED_CONSTRAINT,
} from "_util/form-util";

// UI Framework
import { AsyncStorage, Image, ImageBackground } from "react-native";
import { Body, Button, Card, CardItem, Spinner, Text, View } from "native-base";
import { Col, Grid } from "react-native-easy-grid";

// Style
import {
  styles as global,
  placeholderTextColor,
  red,
} from "_style/Global.style";
import { styles } from "_components/auth/CustomSignIn.style";

const defaultState = {
  isSigningIn: false,
  failedLogIn: false,
  errorMessage: "",
  errorMessages: {},
  formData: {
    username: "",
    password: "",
  },
};

const constraints = {
  username: REQUIRED_CONSTRAINT("Username"),
  password: REQUIRED_CONSTRAINT("Password"),
};

/**
 * The CustomSignIn Component handles the login mechanism for the application,
 * which let's the user authenticate with cognito creds or with Facebook
 * or Google credentials.
 */
class CustomSignIn extends Component {
  constructor(props) {
    super(props);

    this.state = defaultState;

    this._clearState = this._clearState.bind(this);

    this._signIn = this._signIn.bind(this);
    this._signUp = this._signUp.bind(this);
    this._confirm = this._confirm.bind(this);
    this._forgotPassword = this._forgotPassword.bind(this);

    this._signInWithFacebook = this._signInWithFacebook.bind(this);
    this._signInWithGoogle = this._signInWithGoogle.bind(this);

    this._handleInputChange = this._handleInputChange.bind(this);
  }

  /**
   * Utility function to clear the state.
   */
  _clearState() {
    this.setState(defaultState);
  }

  /**
   * Event handler for when a value in the form changes.
   *
   * @param {string} fieldId
   * @param {string} value
   */
  _handleInputChange = (fieldId, value) => {
    const { errorMessages, formData } = this.state;

    const errorMessage = validateFormField(fieldId, value, constraints);
    this.setState(
      updateFormData(fieldId, value, errorMessage, formData, errorMessages)
    );
  };

  /**
   * Event handler for when the user clicks on the Sign Up link, navigates
   * them to the SignUp component.
   */
  _signUp(inviteCode) {
    const { onStateChange } = this.props;
    if (typeof inviteCode === "string") {
      onStateChange("signUp", { inviteCode });
    } else {
      onStateChange("signUp");
    }
    this._clearState();
  }

  /**
   * Event handler for when the user clicks the Trouble Logging In? button,
   * navigates them to the forgot password screen.
   */
  _forgotPassword() {
    const { onStateChange } = this.props;

    onStateChange("forgotPassword");
    this._clearState();
  }

  /**
   * Event handler for when the user needs to verify their phone number. This
   * will trigger a verification code to be sent to the user's phone number
   * and will navigate the user to the custom confirmation screen.
   */
  _confirm() {
    const { onStateChange } = this.props;
    const { username } = this.state;

    Auth.resendSignUp(username)
      .then(() => {
        onStateChange("confirm", username);
        this._clearState();
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          failedLogIn: true,
          errorMessage: e.message,
        });
      });
  }

  /**
   * Attempt to sign the user in given the user name and password they provided.
   */
  async _signIn() {
    const { formData } = this.state;
    const { onStateChange } = this.props;

    const errorMessages = validateFormData(formData, constraints);

    if (Object.keys(errorMessages).length > 0) {
      this.setState({
        errorMessages,
      });
      return;
    }

    // This will activate a spinner and disable the input fields.
    this.setState({
      isSigningIn: true,
    });

    try {
      // Use the Amplify sign in.
      const user = await Auth.signIn(formData.username, formData.password);
      // If we're here then login was a success.
      this.setState({
        failedLogIn: false,
      });

      // If the user doesn't have a verified phone number, ask if they
      // want to.
      if (!user.attributes.phone_number_verified) {
        onStateChange("verifyContact", user);
      } else {
        onStateChange("signedIn", user);
      }

      this._clearState();
    } catch (e) {
      console.log("error signing in", e);

      if (e.code === "UserNotConfirmedException") {
        this._confirm();
      } else if (e.code === "PasswordResetRequiredException") {
        this._forgotPassword();
      } else {
        this.setState({
          failedLogIn: true,
          errorMessage: e.message,
        });
      }
    } finally {
      this.setState({
        isSigningIn: false,
      });
    }
  }

  /**
   *
   */
  async _signInWithFacebook() {
    Auth.federatedSignIn({ provider: "Facebook" })
      .then((data) => {
        console.log(data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  /**
   *
   */
  async _signInWithGoogle() {
    Auth.federatedSignIn({ provider: "Google" })
      .then((data) => {
        console.log(data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  /**
   * Check if there is an invite code if we're just coming out ot the loading
   * state.
   *
   * @param {} prevProps
   */
  async componentDidUpdate(prevProps) {
    const { authState } = this.props;
    const { authState: prevAuthState } = prevProps;

    if (
      (prevAuthState === "loading" || prevAuthState === "signedIn") &&
      authState === "signIn"
    ) {
      const initialUrl = await Linking.getInitialURL();
      const queryStringParameters = getQueryStringParameters(initialUrl);

      if (queryStringParameters.inviteCode) {
        await AsyncStorage.setItem(
          INVITE_CODE_KEY,
          queryStringParameters.inviteCode
        );
        this._signUp(queryStringParameters.inviteCode);
      }
    }
  }

  render() {
    const { errorMessage, errorMessages, failedLogIn, isSigningIn } =
      this.state;
    const { authState } = this.props;

    if (authState === "signIn") {
      return (
        <ImageBackground
          style={global.backgroundImage}
          source={require("_assets/images/auth-background.jpg")}
        >
          <Grid style={global.background}>
            <Col style={global.center}>
              <Card style={[global.card, styles.card]}>
                <CardItem style={global.cardItem}>
                  <Body style={global.center}>
                    {/* Form Header */}
                    <Image
                      style={styles.headerImage}
                      source={require("_assets/images/bazooka_farmstar.png")}
                    />
                    <Text style={[global.textLight, styles.title]}>NEXUS</Text>
                    <Text
                      style={[global.textLight, global.fontSF, styles.subtitle]}
                    >
                      Electronic Control System
                    </Text>
                    <Text
                      style={[
                        global.textLight,
                        global.fontSF,
                        styles.paragraph,
                      ]}
                    >
                      Sign In
                    </Text>

                    {/* Form Fields */}
                    <View style={[styles.formContainer]}>
                      <View style={[global.authInputContainer]}>
                        <FormInput
                          fieldId={"username"}
                          placeholder={"Username"}
                          placeholderTextColor={placeholderTextColor}
                          onChange={this._handleInputChange}
                          inputStyles={[global.authInput]}
                          itemStyles={[global.authItem]}
                          insetShadow={false}
                          errorMessage={errorMessages["username"] || ""}
                          errorMessageStyle={[global.authInputErrorText]}
                        />
                      </View>
                      <View style={[global.authInputContainer]}>
                        <FormInput
                          fieldId={"password"}
                          placeholder={"Password"}
                          placeholderTextColor={placeholderTextColor}
                          secure={true}
                          onChange={this._handleInputChange}
                          inputStyles={[global.authInput]}
                          itemStyles={[global.authItem]}
                          insetShadow={false}
                          errorMessage={errorMessages["password"] || ""}
                          errorMessageStyle={[global.authInputErrorText]}
                          showHide={true}
                        />
                      </View>
                    </View>

                    {/* Sign In Button */}
                    {isSigningIn ? (
                      <Spinner color={red} />
                    ) : (
                      <Button
                        disabled={isSigningIn}
                        style={[global.buttonYellow, styles.signInButton]}
                        onPress={this._signIn}
                      >
                        <Text
                          uppercase={false}
                          style={[
                            global.buttonYellowText,
                            styles.signInButtonText,
                          ]}
                        >
                          Sign In
                        </Text>
                      </Button>
                    )}
                    {!failedLogIn ? null : (
                      <Text style={styles.errorMessage}>{errorMessage}</Text>
                    )}

                    {/* Forgot Password Button */}
                    <Button transparent onPress={this._forgotPassword}>
                      <Text
                        uppercase={false}
                        style={[
                          global.textYellow,
                          global.center,
                          global.fontSF,
                          styles.troubleText,
                        ]}
                      >
                        Trouble Logging In?
                      </Text>
                    </Button>

                    {/* Line Separator */}
                    <View style={[global.separator]} />

                    {/* Facebook Sign In */}
                    {/* <Button
                      disabled={ isSigningIn }
                      style={ [global.facebookButton] }
                      onPress={ this._signInWithFacebook }
                    >
                      <Image source={require('_assets/images/facebook-icon.png')} style={[global.facebookIcon]}/>
                      <Text
                        uppercase={ false }
                        style={ [global.facebookButtonText, global.fontSF] }
                      >
                        Sign In with Facebook
                      </Text>
                    </Button> */}

                    {/* Google Sign In */}
                    {/* <Button
                      disabled={ isSigningIn }
                      style={ [global.googleButton] }
                      onPress={ this._signInWithGoogle }
                    >
                      <Image source={require('_assets/images/google-icon.png')} style={[global.googleIcon]}/>
                      <Text
                        uppercase={ false }
                        style={ [global.googleButtonText, global.fontSF] }
                      >
                        Sign In with Google
                      </Text>
                    </Button> */}

                    {/* Sign Up Button */}
                    <View style={[styles.signUpButtonNewContainer]}>
                      <Text
                        style={[
                          global.textLight,
                          global.fontSF,
                          styles.dontHaveAnAccount,
                          { marginTop: 10 },
                        ]}
                      >
                        Don&apos;t have an account?
                      </Text>
                      {/* <Button transparent onPress={ this._signUp }>
                        <Text uppercase={ false } style={ [global.textYellow, styles.createAnAccount] }>
                          Create an account
                        </Text>
                      </Button> */}
                      <Text style={[global.textLight, global.fontSF]}>
                        {`\u2022   Request an invite from an existing Nexus account holder`}
                      </Text>
                      <Text style={[global.textLight, global.fontSF]}>
                        {`\u2022`} &nbsp;&nbsp;E-mail&nbsp;
                        <a
                          style={{ color: "red", textDecoration: "none" }}
                          href="mailTo:Nexus@bazookafarmstar.com"
                        >
                          Nexus@bazookafarmstar.com
                        </a>
                      </Text>
                      <Text
                        style={[
                          global.textLight,
                          global.fontSF,
                          { marginBottom: 30 },
                        ]}
                      >
                        {`\u2022`} &nbsp;&nbsp;Call customer service at
                        <a
                          style={{ color: "red", textDecoration: "none" }}
                          href="tel:+18007757448"
                        >
                          &nbsp;800-775-7448
                        </a>
                      </Text>
                    </View>
                  </Body>
                </CardItem>
              </Card>
            </Col>
          </Grid>
        </ImageBackground>
      );
    } else {
      return null;
    }
  }
}

CustomSignIn.propTypes = {
  authState: PropTypes.string,
  onStateChange: PropTypes.func,
};

export default connect(null, null)(CustomSignIn);
