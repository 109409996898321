// Util
import { isUndefined } from '_util';
import {
  convertCelsiustoFahrenheit,
  convertKPAToPSI,
  convertLPHToGPH,
  convertPitDepth,
} from '_redux/iot/utils';

// Default value for missing telemetry data.
export const TELEMETRY_DEFAULT_VALUE = '-';

/**
 * Returns true if there is a request out to update a device name,
 * false otherwise.
 *
 * @param {*} state redux state
 */
export const isUpdatingDeviceName = (state) => {
  return state.iot.isUpdatingDeviceName;
};

/**
 * Returns a single device from the state.
 *
 * @param {*} state redux state
 * @param {string} deviceId id of the device to fetch
 */
export const getIoTDeviceById = (state, deviceId) => {
  return state.iot.devices[deviceId] || null;
};

/**
 * Returns true if there is a subscription to the device with the provided device id.
 *
 * @param {*} state
 * @param {*} deviceId
 */
export const iotDeviceIsConnected = (state, deviceId) => {
  return (state.iot.devices[deviceId] && state.iot.devices[deviceId].isConnected ? true : false);
};

/**
 * Returns true if there is a subscription to the device with the provided device id.
 *
 * @param {*} state
 * @param {*} deviceId
 */
export const iotDeviceIsConnectedVisibility = (state, deviceId) => {
  return (state.iot.devices[deviceId] && state.iot.devices[deviceId].isConnectedDeviceVisible ? true : false);
};


/**
 * Returns the setpoints for the device with the provided device id.
 *
 * @param {*} state
 * @param {*} deviceId
 */
export const getSetPointsForDevice = (state, deviceId) => {

  const device = state.iot.devices[deviceId];

  if (device && !isUndefined(device.setPoints)) {
    const { setPoints } = device;

    if (setPoints.current) {
      return setPoints.current.state.reported;
    }

    if (setPoints.state) {
      return setPoints.state.reported;
    }
  }

  return {};
};

/**
 * Returns the telemetry for the device with the provided device id.
 * It performs conversions to the correct unit (e.g. KPA to PSI) for
 * the appropriate metrics.
 *
 * @param {*} state
 * @param {*} deviceId
 */
export const getTelemetryForDevice = (state, deviceId) => {

  const device = getIoTDeviceById(state, deviceId);
  const { telemetry = {} } = device || {};
  return {
    ecom    : !isUndefined(telemetry.ecom)    ? telemetry.ecom : TELEMETRY_DEFAULT_VALUE,
    es      : !isUndefined(telemetry.es)      ? telemetry.es : TELEMETRY_DEFAULT_VALUE,
    espn    : !isUndefined(telemetry.espn)    ? telemetry.espn : TELEMETRY_DEFAULT_VALUE,
    efmi    : !isUndefined(telemetry.efmi)    ? telemetry.efmi : TELEMETRY_DEFAULT_VALUE,
    eop     : !isUndefined(telemetry.eop)     ? convertKPAToPSI(telemetry.eop) : TELEMETRY_DEFAULT_VALUE,
    ect     : !isUndefined(telemetry.ect)     ? convertCelsiustoFahrenheit(telemetry.ect) : TELEMETRY_DEFAULT_VALUE,
    espeed  : !isUndefined(telemetry.espeed)  ? telemetry.espeed : TELEMETRY_DEFAULT_VALUE,
    ebatt   : !isUndefined(telemetry.ebatt)   ? telemetry.ebatt : TELEMETRY_DEFAULT_VALUE,
    ehours  : !isUndefined(telemetry.ehours)  ? telemetry.ehours : TELEMETRY_DEFAULT_VALUE,
    eprpm   : !isUndefined(telemetry.eprpm)   ? telemetry.eprpm : TELEMETRY_DEFAULT_VALUE,
    elrpm   : !isUndefined(telemetry.elrpm)   ? telemetry.elrpm : TELEMETRY_DEFAULT_VALUE,
    eload   : !isUndefined(telemetry.eload)   ? telemetry.eload : TELEMETRY_DEFAULT_VALUE,
    efr     : !isUndefined(telemetry.efr)     ? convertLPHToGPH(telemetry.efr) : TELEMETRY_DEFAULT_VALUE,
    efl     : !isUndefined(telemetry.efl)     ? telemetry.efl : TELEMETRY_DEFAULT_VALUE,
    et4s    : !isUndefined(telemetry.et4s)    ? telemetry.et4s : TELEMETRY_DEFAULT_VALUE,
    hs      : !isUndefined(telemetry.hs)      ? telemetry.hs : TELEMETRY_DEFAULT_VALUE,
    mode    : !isUndefined(telemetry.mode)    ? telemetry.mode : TELEMETRY_DEFAULT_VALUE,
    psp     : !isUndefined(telemetry.psp)     ? convertKPAToPSI(telemetry.psp) : TELEMETRY_DEFAULT_VALUE,
    pdp     : !isUndefined(telemetry.pdp)     ? convertKPAToPSI(telemetry.pdp) : TELEMETRY_DEFAULT_VALUE,
    pmsp    : !isUndefined(telemetry.pmsp)    ? convertKPAToPSI(telemetry.pmsp) : TELEMETRY_DEFAULT_VALUE,
    pmdp     : !isUndefined(telemetry.pmdp)   ? convertKPAToPSI(telemetry.pmdp) : TELEMETRY_DEFAULT_VALUE,
    hvs     : !isUndefined(telemetry.hvs)     ? telemetry.hvs : TELEMETRY_DEFAULT_VALUE,
    hcm     : !isUndefined(telemetry.hcm)     ? telemetry.hcm : TELEMETRY_DEFAULT_VALUE,
    shp1    : !isUndefined(telemetry.shp1)    ? convertKPAToPSI(telemetry.shp1) < 0 ? 0 : convertKPAToPSI(telemetry.shp1) : TELEMETRY_DEFAULT_VALUE,
    shp2    : !isUndefined(telemetry.shp1)    ? convertKPAToPSI(telemetry.shp2) < 0 ? 0 : convertKPAToPSI(telemetry.shp2) : TELEMETRY_DEFAULT_VALUE,
    // Pit Depth
    spd     : !isUndefined(telemetry.spd)     ? convertPitDepth(telemetry.spd) : TELEMETRY_DEFAULT_VALUE,

    sfr     : !isUndefined(telemetry.sfr)     ? telemetry.sfr : TELEMETRY_DEFAULT_VALUE,
    stf     : !isUndefined(telemetry.stf)     ? telemetry.stf : TELEMETRY_DEFAULT_VALUE,
    eeflcom : !isUndefined(telemetry.eeflcom) ? telemetry.eeflcom : TELEMETRY_DEFAULT_VALUE,
    def     : !isUndefined(telemetry.def)     ? telemetry.def : TELEMETRY_DEFAULT_VALUE,
    soot    : !isUndefined(telemetry.soot)    ? telemetry.soot : TELEMETRY_DEFAULT_VALUE,
  };
};

/**
 * Returns true if there is a warning on for the device. False
 * otherwise.
 *
 * @param {*} state
 * @param {*} deviceId
 */
export const isWarningOnForDevice = (state, deviceId) => {
  // return true;
  const {
    espn,
    efmi,
  } = getTelemetryForDevice(state, deviceId);

  return (espn !== 0) && ((efmi >= 3 && efmi <= 21) || efmi === 31);
};

/**
 * Returns true if there is an alarm on for the device. False otherwise.
 *
 * @param {} state
 * @param {*} deviceId
 */
export const isAlarmOnForDevice = (state, deviceId) => {
  // return true;
  const {
    espn,
    efmi,
  } = getTelemetryForDevice(state, deviceId);

  return (espn !== 0) && (efmi === 0 || efmi === 1 || efmi === 2);
};

/**
 * Returns true if the engine is running for the given device id.
 *
 * @param {*} state
 * @param {*} deviceId
 */
export const isEngineRunningForDevice = (state, deviceId) => {
  const {
    es,
  } = getTelemetryForDevice(state, deviceId);

  return es !== 1 && es !== '-';
};

/**
 * Returns true if device is reconnecting otherwise false.
 *
 * @param {*} state
 * @param {*} deviceId
 */
export const isReconnecting = (state, deviceId) => {
   return getIoTDeviceById(state, deviceId)?.reconnectTimerId != null;
}


