// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { getUserCode } from '_redux/user/selectors';
import { getFriendlyUsername } from '_redux/user/utils';
import {
  addCrewMember,
  getCrewMembers,
  removeCrewMember,
} from '_redux/crewMembers/actions';
import { showToast } from '_redux/toasts/actions';

import CrewMemberListItem from '_components/crew_members/CrewMemberListItem.component';
import Modal from '_components/common/Modal.component';
import AddCrewMemberModalContent from '_components/crew_members/AddCrewMemberModalContent.component';

// UI Framework
import {
  Button,
  Spinner,
  Text,
  View,
} from 'native-base';

// Style
import { styles as global, container_gray, dark_gray } from '_style/Global.style';
import { styles } from '_pages/CrewMembers.style';

class CrewMembers extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showAddCrewMemberModal: false,
    };

    this._showAddCrewMemberModal = this._showAddCrewMemberModal.bind(this);
    this._hideAddCrewMemberModal = this._hideAddCrewMemberModal.bind(this);

    this._showEditCrewMemberModal = this._showEditCrewMemberModal.bind(this);
    this._hideEditCrewMemberModal = this._hideEditCrewMemberModal.bind(this);

    this._handleGetCrewMembers = this._handleGetCrewMembers.bind(this);
  }

  componentDidMount() {
    this._handleGetCrewMembers();
  }

  /**
   * Modal visibility.
   */
  _showAddCrewMemberModal() {
    this.setState({
      showAddCrewMemberModal: true,
    });

  }
  _hideAddCrewMemberModal() {
    this.setState({
      showAddCrewMemberModal: false,
    });
  }
  _showEditCrewMemberModal() {
    this.setState({
      showEditCrewMemberModal: true,
    });
  }
  _hideEditCrewMemberModal() {
    this.setState({
      showEditCrewMemberModal: false,
    });
  }


  /**
   * Handles getting this owner's crew members.
   */
  _handleGetCrewMembers() {
    const { getCrewMembers } = this.props;

    getCrewMembers();
  }

  render() {
    const {
      showAddCrewMemberModal,
      // showEditCrewMemberModal,
    } = this.state;

    const {
      crewMembers,
      isGettingCrewMembers,
      // userCode,
    } = this.props;

    const crewMemberListItems = [];
    crewMembers.forEach((member, index) => {

      const even = index % 2 === 0;
      const backgroundColor = even ? dark_gray : container_gray;

      const name = getFriendlyUsername(member);

      crewMemberListItems.push((
        <CrewMemberListItem
          key={index}
          backgroundColor={backgroundColor}
          cognitoSub={ member.cognito_sub }
          username={ member.username }
          email={ member.email }
          name ={ name }
          isLeader={ member.is_leader === 1}
          onDelete={ this._showConfirmDeleteCrewMemberModal }
          onEdit={ this._showEditCrewMemberModal }
          zIndex={1000 - index}
        />
      ));
    });

    return (
      <View style={[styles.main]}>
        <Text style={[global.pageTitle]}>
          CREW MEMBERS
          {/* [{ userCode }] */}
        </Text>

        <View style={[styles.crewMembersContainer, { zIndex: 10 }, global.dropShadow]}>
          {
            isGettingCrewMembers ?
              <Spinner color ={'red'} style={{ alignSelf: 'center' }} />
              :
              <>
                { crewMemberListItems }
              </>
          }
        </View>

        <View style={[styles.addCrewMemberButtonContainer]}>
          <Button style={[global.confirmationButton, styles.addCrewMemberButton]} onPress={this._showAddCrewMemberModal}>
            <Text style={[global.confirmationButtonText, styles.addCrewMemberButtonText]}>
              ADD CREW MEMBER / LEADER
            </Text>
          </Button>
        </View>


        {/* Modals */}

        {/* ADD */}
        <Modal
          isVisible={showAddCrewMemberModal}
          title={'ADD CREW MEMBER / LEADER'}
          hideExitIcon={false}
          onClose={this._hideAddCrewMemberModal}
          content={showAddCrewMemberModal &&
            <AddCrewMemberModalContent
              onConfirm={this._hideAddCrewMemberModal}
              onCancel={this._hideAddCrewMemberModal}

            />
          }
        />

        {/* EDIT */}
        {/* <Modal
          isVisible={showEditCrewMemberModal}
          title={'EDIT CREW MEMBER / LEADER'}
          hideExitIcon={true}
          onClose={this._hideEditCrewMemberModal}
          content={showEditCrewMemberModal &&
            <>
            <Text>Edit Modal</Text>
            </>
          }
        /> */}

      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    crewMembers: state.crewMembers.crewMembers,
    userCode: getUserCode(state),
    isAddingCrewMember: state.crewMembers.isAddingCrewMember,
    isGettingCrewMembers: state.crewMembers.isGettingCrewMembers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addCrewMember: (crewMemberInfo) => dispatch(addCrewMember(crewMemberInfo)),
    getCrewMembers: () => dispatch(getCrewMembers()),
    removeCrewMember: (cognitoSub) => dispatch(removeCrewMember(cognitoSub)),
    showToast: (message, isError) => dispatch(showToast(message, isError)),
  };
};

CrewMembers.propTypes = {
  crewMembers: PropTypes.array.isRequired,
  getCrewMembers: PropTypes.func.isRequired,
  isGettingCrewMembers: PropTypes.bool.isRequired,
  removeCrewMember: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  userCode: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(CrewMembers);
