import { StyleSheet } from 'react-native';

import { roboto, textLight, textDark, lighter_gray } from '_style/Global.style';

export const styles = StyleSheet.create({
  userProfileModalContent: {
    width: 425,
    marginLeft: 75,
    marginRight: 75,
  },

  description: {
    fontFamily: roboto,
    fontSize: 14,
    letterSpacing: 1.6,
    textAlign: 'center',
    marginBottom: 15,
  },

  formInput: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 10,
    paddingBottom: 10,
  },

  labelText: {
    fontFamily: roboto,
    fontSize: 18,
    fontWeight: 'bold',
    color: textLight,
  },

  roleTypeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 40,
  },

  roleTypeInput: {
    flex: 1,
    marginLeft: 20,
  },

  actionButtonContainer:{
    padding: 15,
  },

  verificationInputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  verificateCodeInput: {
    flex: 1,
    marginRight: 20,
  },
  verificationCodeButton: {

  },
  verificationErrorMessage: {
    color: 'red',
    fontSize: 12,
  },
  logoutTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  logoutText: {
    color: lighter_gray,
    fontWeight: 'bold',
    letterSpacing: 1.6,
    marginLeft: 10,
    fontSize: 14,
  }

});
