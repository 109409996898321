import { API, Auth } from 'aws-amplify';
import { GROUPS_API_ID } from '_config/api-constants';
import amplifyConfig from '_config/_aws-exports.js';

import { getUserCognitoSub } from '_redux/user/utils';

import { makeActionCreator } from '_redux/rootActions';

import {
  CLEAR_STATE,
  CLEAR_GROUP_CHILDREN_FOR_ASSIGNMENT,

  ADD_GROUP_REQUEST,
  ADD_GROUP_SUCCESS,
  ADD_GROUP_FAILURE,

  ADD_SYSTEM_USER_REQUEST,
  ADD_SYSTEM_USER_SUCCESS,
  ADD_SYSTEM_USER_FAILURE,

  REMOVE_SYSTEM_USER_REQUEST,
  REMOVE_SYSTEM_USER_SUCCESS,
  REMOVE_SYSTEM_USER_FAILURE,

  GET_GROUP_REQUEST,
  GET_GROUP_SUCCESS,
  GET_GROUP_FAILURE,

  GET_GROUP_USERS_REQUEST,
  GET_GROUP_USERS_SUCCESS,
  GET_GROUP_USERS_FAILURE,

  GET_GROUP_CHILDREN_REQUEST,
  GET_GROUP_CHILDREN_SUCCESS,
  GET_GROUP_CHILDREN_FAILURE,

  GET_GROUP_CHILDREN_FOR_ASSIGNMENT_REQUEST,
  GET_GROUP_CHILDREN_FOR_ASSIGNMENT_SUCCESS,
  GET_GROUP_CHILDREN_FOR_ASSIGNMENT_FAILURE,

  UPDATE_GROUP_REQUEST,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAILURE,

  INVITE_SYSTEM_USER_REQUEST,
  INVITE_SYSTEM_USER_SUCCESS,
  INVITE_SYSTEM_USER_FAILURE,

  CHECK_SYSTEM_USER_ELIGIBILITY_REQUEST,
  CHECK_SYSTEM_USER_ELIGIBILITY_SUCCESS,
  CHECK_SYSTEM_USER_ELIGIBILITY_FAILURE,

  SEARCH_DEVICES_REQUEST,
  SEARCH_DEVICES_SUCCESS,
  SEARCH_DEVICES_FAILURE,

  SUSPEND_DEVICE_REQUEST,
  SUSPEND_DEVICE_SUCCESS,
  SUSPEND_DEVICE_FAILURE,

  ASSIGN_DEVICE_REQUEST,
  ASSIGN_DEVICE_SUCCESS,
  ASSIGN_DEVICE_FAILURE,

  CHECK_AND_ADD_IN_PROGRESS,
  CHECK_AND_ADD_COMPLETE,

  SEARCH_GROUP_CHILDREN_REQUEST,
  SEARCH_GROUP_CHILDREN_SUCCESS,
  SEARCH_GROUP_CHILDREN_FAILURE,

  SEARCH_GROUP_USERS_REQUEST,
  SEARCH_GROUP_USERS_SUCCESS,
  SEARCH_GROUP_USERS_FAILURE,

} from '_redux/admin/types';

export const clearState = makeActionCreator(CLEAR_STATE);
export const clearGroupChildrenForAssignment = makeActionCreator(CLEAR_GROUP_CHILDREN_FOR_ASSIGNMENT);
export const checkAndAddInProgress = makeActionCreator(CHECK_AND_ADD_IN_PROGRESS);
export const checkAndAddComplete = makeActionCreator(CHECK_AND_ADD_COMPLETE);

/**
 * Get the children for a group.
 */
export const getGroupChildren = (groupId) => {
  return async (dispatch) => {
    dispatch(getGroupChildrenRequest());

    try {

      const endpoint = `/groups/${encodeURIComponent(groupId)}/children`;

      const children = await API.get(GROUPS_API_ID, endpoint);

      dispatch(getGroupChildrenSuccess(children));

    } catch (e) {
      console.error(e);
      dispatch(getGroupChildrenFailure(e.response.data));
    }
  };
};
export const getGroupChildrenRequest = makeActionCreator(GET_GROUP_CHILDREN_REQUEST);
export const getGroupChildrenSuccess = makeActionCreator(GET_GROUP_CHILDREN_SUCCESS, 'children');
export const getGroupChildrenFailure = makeActionCreator(GET_GROUP_CHILDREN_FAILURE, 'error');

/**
 * Get the children for a group.
 */
export const getGroupChildrenForAssignment = (groupId, isDealerLevel) => {
  return async (dispatch) => {
    dispatch(getGroupChildrenForAssignmentRequest());

    try {
      let children = [];

      if (!isDealerLevel) {
        children = await API.get(GROUPS_API_ID, `/groups/${encodeURIComponent(groupId)}/children`);
      } else {
        children = await API.get(GROUPS_API_ID, `/groups/${encodeURIComponent(groupId)}/users`);
      }

      dispatch(getGroupChildrenForAssignmentSuccess(children));

    } catch (e) {
      console.error(e);
      dispatch(getGroupChildrenForAssignmentFailure(e.response.data));
    }
  };
};
export const getGroupChildrenForAssignmentRequest = makeActionCreator(GET_GROUP_CHILDREN_FOR_ASSIGNMENT_REQUEST);
export const getGroupChildrenForAssignmentSuccess = makeActionCreator(GET_GROUP_CHILDREN_FOR_ASSIGNMENT_SUCCESS, 'children');
export const getGroupChildrenForAssignmentFailure = makeActionCreator(GET_GROUP_CHILDREN_FOR_ASSIGNMENT_FAILURE, 'error');

/**
 * Add a new group.
 */
export const addGroup = (groupInfo) => {
  return async (dispatch) => {
    dispatch(addGroupRequest());

    try {
      await API.post(GROUPS_API_ID, '/groups', { body: groupInfo });

      dispatch(addGroupSuccess(groupInfo));
      // TODO: might be able to optimize here.
      dispatch(getGroup(groupInfo.groupParentId));
      dispatch(getGroupChildren(groupInfo.groupParentId));

    } catch (e) {
      console.error(e);
      dispatch(addGroupFailure(e.response.data));
    }
  };
};
export const addGroupRequest = makeActionCreator(ADD_GROUP_REQUEST);
export const addGroupSuccess = makeActionCreator(ADD_GROUP_SUCCESS);
export const addGroupFailure = makeActionCreator(ADD_GROUP_FAILURE, 'error');

/**
 * Add a new group.
 */
export const getGroup = (groupId) => {
  return async (dispatch) => {
    dispatch(getGroupRequest());

    try {
      const group = await API.get(GROUPS_API_ID, `/groups/${encodeURIComponent(groupId)}`);

      dispatch(getGroupSuccess(group));

    } catch (e) {
      console.error(e);
      dispatch(getGroupFailure(e.response.data));
    }
  };
};
export const getGroupRequest = makeActionCreator(GET_GROUP_REQUEST);
export const getGroupSuccess = makeActionCreator(GET_GROUP_SUCCESS, 'group');
export const getGroupFailure = makeActionCreator(GET_GROUP_FAILURE, 'error');

/**
 * Add a new group.
 */
export const getGroupUsers = (groupId) => {
  return async (dispatch) => {
    dispatch(getGroupUsersRequest());

    try {
      const groupUsers = await API.get(GROUPS_API_ID, `/groups/${encodeURIComponent(groupId)}/users`);

      dispatch(getGroupUsersSuccess(groupUsers));

    } catch (e) {
      console.error(e);
      dispatch(getGroupUsersFailure(e.response.data));
    }
  };
};
export const getGroupUsersRequest = makeActionCreator(GET_GROUP_USERS_REQUEST);
export const getGroupUsersSuccess = makeActionCreator(GET_GROUP_USERS_SUCCESS, 'groupUsers');
export const getGroupUsersFailure = makeActionCreator(GET_GROUP_USERS_FAILURE, 'error');

/**
 * Add a new group.
 */
export const updateGroup = (groupInfo) => {
  return async (dispatch) => {
    dispatch(updateGroupRequest());

    try {
      await API.put(GROUPS_API_ID, `/groups/${encodeURIComponent(groupInfo.group_id)}`, { body: groupInfo });

      dispatch(updateGroupSuccess(groupInfo));

    } catch (e) {
      console.error(e);
      dispatch(updateGroupFailure(e.response.data));
    }
  };
};
export const updateGroupRequest = makeActionCreator(UPDATE_GROUP_REQUEST);
export const updateGroupSuccess = makeActionCreator(UPDATE_GROUP_SUCCESS, 'groupInfo');
export const updateGroupFailure = makeActionCreator(UPDATE_GROUP_FAILURE, 'error');

/**
 * Invite a new system user.
 */
export const inviteSystemUser = (systemUserInfo, groupId) => {
  return async (dispatch) => {
    dispatch(inviteSystemUserRequest());

    try {
      const userInfo = await Auth.currentUserInfo();

      systemUserInfo.invitingUserUsername = userInfo.username;
      systemUserInfo.userPoolId = amplifyConfig.Auth.userPoolId;

      await API.post(
        GROUPS_API_ID,
        `/invite/${encodeURIComponent(groupId)}`,
        { body: systemUserInfo },
      );

      dispatch(inviteSystemUserSuccess());

    } catch (e) {
      console.error(e);
      dispatch(inviteSystemUserFailure(e.response.data));
    }
  };
};
export const inviteSystemUserRequest = makeActionCreator(INVITE_SYSTEM_USER_REQUEST);
export const inviteSystemUserSuccess = makeActionCreator(INVITE_SYSTEM_USER_SUCCESS);
export const inviteSystemUserFailure = makeActionCreator(INVITE_SYSTEM_USER_FAILURE, 'error');

/**
 * Add a new group.
 */
export const checkSystemUserEligibility = (cognitoSub, inviteCode) => {
  return async (dispatch) => {
    dispatch(checkSystemUserEligibilityRequest());

    try {
      // If a cognito sub wasn't passed in, assume the call is for the current user.
      if (!cognitoSub) {
        cognitoSub = await getUserCognitoSub();
      }
      const response = await API.get(GROUPS_API_ID, `/users/${encodeURIComponent(cognitoSub)}/systemUserEligible?inviteCode=${encodeURIComponent(inviteCode)}`);
      const { error } = response;

      dispatch(checkSystemUserEligibilitySuccess(error));

    } catch (e) {
      console.error(e);
      dispatch(checkSystemUserEligibilityFailure(e.response.data));
    }
  };
};
export const checkSystemUserEligibilityRequest = makeActionCreator(CHECK_SYSTEM_USER_ELIGIBILITY_REQUEST);
export const checkSystemUserEligibilitySuccess = makeActionCreator(CHECK_SYSTEM_USER_ELIGIBILITY_SUCCESS, 'error');
export const checkSystemUserEligibilityFailure = makeActionCreator(CHECK_SYSTEM_USER_ELIGIBILITY_FAILURE);

/**
 * Get the crew members for this user.
 */
export const addSystemUser = (groupId, cognitoSub, isOwnerInvite) => {
  return async (dispatch) => {
    dispatch(addSystemUserRequest());

    try {
      if (!cognitoSub) {
        cognitoSub = await getUserCognitoSub();
      }

      const systemUserInfo = {
        userPoolId: amplifyConfig.Auth.userPoolId,
        isOwnerInvite,
      };

      await API.post(
        GROUPS_API_ID,
        `/groups/${encodeURIComponent(groupId)}/users/${encodeURIComponent(cognitoSub)}`,
        { body: systemUserInfo },
      );

      dispatch(addSystemUserSuccess());

    } catch (e) {
      console.error(e);
      dispatch(addSystemUserFailure(e.response.data));
    }
  };
};
export const addSystemUserRequest = makeActionCreator(ADD_SYSTEM_USER_REQUEST);
export const addSystemUserSuccess = makeActionCreator(ADD_SYSTEM_USER_SUCCESS);
export const addSystemUserFailure = makeActionCreator(ADD_SYSTEM_USER_FAILURE, 'error');

/**
 * Get the crew members for this user.
 */
export const removeSystemUser = (groupId, cognitoSub) => {
  return async (dispatch) => {
    dispatch(removeSystemUserRequest(cognitoSub));

    try {
      if (!cognitoSub) {
        cognitoSub = await getUserCognitoSub();
      }

      const systemUserInfo = {
        userPoolId: amplifyConfig.Auth.userPoolId,
      };

      await API.del(
        GROUPS_API_ID,
        `/groups/${encodeURIComponent(groupId)}/users/${encodeURIComponent(cognitoSub)}`,
        { body: systemUserInfo },
      );

      dispatch(removeSystemUserSuccess(cognitoSub));

    } catch (e) {
      console.error(e);
      dispatch(removeSystemUserFailure(e.response.data));
    }
  };
};
export const removeSystemUserRequest = makeActionCreator(REMOVE_SYSTEM_USER_REQUEST, 'cognitoSub');
export const removeSystemUserSuccess = makeActionCreator(REMOVE_SYSTEM_USER_SUCCESS, 'cognitoSub');
export const removeSystemUserFailure = makeActionCreator(REMOVE_SYSTEM_USER_FAILURE, 'error');

/**
 * Add a new group.
 */
export const searchDevices = (searchText, groupId, limit, offset) => {
  return async (dispatch) => {
    dispatch(searchDevicesRequest());

    try {
      limit = limit || Number.MAX_SAFE_INTEGER;
      offset = offset || 0;
      searchText = searchText || '';

      const endpoint = `/groups/${encodeURIComponent(groupId)}/devices?deviceId=${encodeURIComponent(searchText)}&limit=${limit}&offset=${offset}`;

      const results = await API.get(GROUPS_API_ID, endpoint);

      dispatch(searchDevicesSuccess(results));

    } catch (e) {
      console.error(e);
      dispatch(searchDevicesFailure(e.response.data));
    }
  };
};
export const searchDevicesRequest = makeActionCreator(SEARCH_DEVICES_REQUEST);
export const searchDevicesSuccess = makeActionCreator(SEARCH_DEVICES_SUCCESS, 'results');
export const searchDevicesFailure = makeActionCreator(SEARCH_DEVICES_FAILURE, 'error');

/**
 * Add a new group.
 */
export const suspendDevice = (deviceId, suspend) => {
  return async (dispatch) => {
    dispatch(suspendDeviceRequest());

    try {

      await API.get(GROUPS_API_ID, `/devices/${encodeURIComponent(deviceId)}/suspend?suspend=${suspend}`);

      dispatch(suspendDeviceSuccess(deviceId, suspend));

    } catch (e) {
      console.error(e);
      dispatch(suspendDeviceFailure(e.response.data));
    }
  };
};
export const suspendDeviceRequest = makeActionCreator(SUSPEND_DEVICE_REQUEST);
export const suspendDeviceSuccess = makeActionCreator(SUSPEND_DEVICE_SUCCESS, 'deviceId', 'suspend');
export const suspendDeviceFailure = makeActionCreator(SUSPEND_DEVICE_FAILURE, 'error');

/**
 * assign a device
 */
export const assignDevice = (deviceId, level1Id, level2Id, level3Id) => {
  return async (dispatch) => {
    dispatch(assignDeviceRequest());

    try {

      await API.post(GROUPS_API_ID, `/devices/${encodeURIComponent(deviceId)}/assign`,{
        body: {
          level_1_id: level1Id,
          level_2_id: level2Id,
          level_3_id: level3Id,
        },
      });

      dispatch(assignDeviceSuccess());

    } catch (e) {
      console.error(e);
      dispatch(assignDeviceFailure());
    }
  };
};
export const assignDeviceRequest = makeActionCreator(ASSIGN_DEVICE_REQUEST);
export const assignDeviceSuccess = makeActionCreator(ASSIGN_DEVICE_SUCCESS);
export const assignDeviceFailure = makeActionCreator(ASSIGN_DEVICE_FAILURE, 'error');

/**
 * Search the children of a group.
 */
 export const searchGroupChildren = (searchText, groupId, limit, offset) => {
  return async (dispatch) => {
    dispatch(searchGroupChildrenRequest());

    try {
      limit = limit || Number.MAX_SAFE_INTEGER;
      offset = offset || 0;
      searchText = searchText || '';

      const endpoint = `/groups/${encodeURIComponent(groupId)}/children/search?searchText=${encodeURIComponent(searchText)}&limit=${limit}&offset=${offset}`;

      const children = await API.get(GROUPS_API_ID, endpoint);

      dispatch(searchGroupChildrenSuccess(children));

    } catch (e) {
      console.error(e);
      dispatch(searchGroupChildrenFailure(e.response.data));
    }
  };
};
export const searchGroupChildrenRequest = makeActionCreator(SEARCH_GROUP_CHILDREN_REQUEST);
export const searchGroupChildrenSuccess = makeActionCreator(SEARCH_GROUP_CHILDREN_SUCCESS, 'children');
export const searchGroupChildrenFailure = makeActionCreator(SEARCH_GROUP_CHILDREN_FAILURE, 'error');

/**
 * Search the users of a group.
 */
 export const searchGroupUsers = (searchText, groupId, limit, offset) => {
  return async (dispatch) => {
    dispatch(searchGroupUsersRequest());

    try {
      limit = limit || Number.MAX_SAFE_INTEGER;
      offset = offset || 0;
      searchText = searchText || '';

      const endpoint = `/groups/${encodeURIComponent(groupId)}/users/search?searchText=${encodeURIComponent(searchText)}&limit=${limit}&offset=${offset}`;

      const groupUsers = await API.get(GROUPS_API_ID, endpoint);

      dispatch(searchGroupUsersSuccess(groupUsers));

    } catch (e) {
      console.error(e);
      dispatch(searchGroupUsersFailure(e.response.data));
    }
  };
};
export const searchGroupUsersRequest = makeActionCreator(SEARCH_GROUP_USERS_REQUEST);
export const searchGroupUsersSuccess = makeActionCreator(SEARCH_GROUP_USERS_SUCCESS, 'groupUsers');
export const searchGroupUsersFailure = makeActionCreator(SEARCH_GROUP_USERS_FAILURE, 'error');

