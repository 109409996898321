import { makeActionCreator } from "_redux/rootActions";
import { API } from "aws-amplify";

import {
  CLEAR_STATE,
  INIT_SITEMAP,
  SITES_GET_REQUEST,
  SITES_GET_SUCCESS,
  SITES_GET_FAILURE,
  SITE_DELETE_REQUEST,
  SITE_DELETE_SUCCESS,
  SITE_DELETE_FAILURE,
  SITE_GET_REQUEST,
  SITE_GET_SUCCESS,
  SITE_GET_FAILURE,
  SITE_ARCHIVE_REQUEST,
  SITE_ARCHIVE_SUCCESS,
  SITE_ARCHIVE_FAILURE,
  SITE_UNARCHIVE_REQUEST,
  SITE_UNARCHIVE_SUCCESS,
  SITE_UNARCHIVE_FAILURE,
  CREATE_SITE_REQUEST,
  CREATE_SITE_SUCCESS,
  CREATE_SITE_FAILURE,
  EDIT_SITE_REQUEST,
  EDIT_SITE_SUCCESS,
  EDIT_SITE_FAILURE,
  SHARE_SITE_REQUEST,
  SHARE_SITE_SUCCESS,
  SHARE_SITE_FAILURE,
  SITE_SUB_DELETE_REQUEST,
  SITE_SUB_DELETE_SUCCESS,
  SITE_SUB_DELETE_FAILURE,
  SITE_ORDER_REQUEST,
  SITE_ORDER_SUCCESS,
  SITE_ORDER_FAILURE,
} from "_redux/site/types";

import { formatPhoneNumber } from "_util/form-util";

import { SITE_MAP_API_ID, USERS_API_ID } from "_config/api-constants";
//const API_HOST =  "https://tcd5fhp4g6.execute-api.us-east-1.amazonaws.com/dev/sites/";

export const clearState = makeActionCreator(CLEAR_STATE);
export const getSiteDetailsRequest = makeActionCreator(SITES_GET_REQUEST);
export const getSiteDetailsSuccess = makeActionCreator(
  SITES_GET_SUCCESS,
  "sitedata"
);
export const getSiteDetailsFailure = makeActionCreator(SITES_GET_FAILURE);

export const fetchSiteData = () => {
  return async (dispatch) => {
    dispatch(getSiteDetailsRequest());
    try {
      const siteData = await API.get(SITE_MAP_API_ID, `/sites/`);
      // const siteData = await new Promise((resolve, reject) => {
      //   fetch(`${API_HOST}`, {
      //     method: "GET",
      //   })
      //     .then((res) => res.json())
      //     .then(
      //       (result) => resolve(result),
      //       (error) => reject(error)
      //     )
      //     .catch((error) => reject(error));
      // });
      // return response;
      dispatch(getSiteDetailsSuccess(siteData));
    } catch (e) {
      console.error(e);
      dispatch(getSiteDetailsFailure());
    }
  };
};

export const getOnesiteDetailRequest = makeActionCreator(SITE_GET_REQUEST);
export const getOnesiteDetailSuccess = makeActionCreator(
  SITE_GET_SUCCESS,
  "onesitedata"
);
export const getOnesiteDetailFailure = makeActionCreator(SITE_GET_FAILURE);

export const getOneSiteData = (siteId) => {
  return async (dispatch) => {
    dispatch(getOnesiteDetailRequest());
    try {
      const siteData = await API.get(SITE_MAP_API_ID, `/sites/${siteId}`);

      dispatch(getOnesiteDetailSuccess(siteData));
    } catch (e) {
      console.error(e);
      dispatch(getOnesiteDetailFailure());
    }
  };
};

export const siteDeleteRequest = makeActionCreator(SITE_DELETE_REQUEST);
export const siteDeleteSuccess = makeActionCreator(
  SITE_DELETE_SUCCESS,
  "detelesitedata"
);
export const siteDeleteFailure = makeActionCreator(SITE_DELETE_FAILURE);

export const deleteSiteItem = (siteId) => {
  return async (dispatch) => {
    dispatch(siteDeleteRequest());
    try {
      const siteDelete = await API.del(SITE_MAP_API_ID, `/sites/${siteId}`);

      dispatch(siteDeleteSuccess(siteDelete));
    } catch (e) {
      console.error(e);
      dispatch(siteDeleteFailure());
    }
  };
};

export const siteArchivedRequest = makeActionCreator(SITE_ARCHIVE_REQUEST);
export const siteArchivedSuccess = makeActionCreator(
  SITE_ARCHIVE_SUCCESS,
  "archivesitedata"
);
export const siteArchivedFailure = makeActionCreator(SITE_ARCHIVE_FAILURE);

export const archivedSiteitem = (siteId, body) => {
  return async (dispatch) => {
    dispatch(siteArchivedRequest());
    try {
      const siteArchived = await API.put(
        SITE_MAP_API_ID,
        `/sites/${siteId}/status`,
        { body }
      );

      dispatch(siteArchivedSuccess(siteArchived));
    } catch (e) {
      console.error(e);
      dispatch(siteArchivedFailure());
    }
  };
};

export const siteunArchivedRequest = makeActionCreator(SITE_UNARCHIVE_REQUEST);
export const siteunArchivedSuccess = makeActionCreator(
  SITE_UNARCHIVE_SUCCESS,
  "unarchivesitedata"
);
export const siteunArchivedFailure = makeActionCreator(SITE_UNARCHIVE_FAILURE);

export const unarchivedSiteitem = (siteId, body) => {
  return async (dispatch) => {
    dispatch(siteunArchivedRequest());
    try {
      const siteArchived = await API.put(
        SITE_MAP_API_ID,
        `/sites/${siteId}/status`,
        { body }
      );

      dispatch(siteunArchivedSuccess(siteArchived));
    } catch (e) {
      console.error(e);
      dispatch(siteunArchivedFailure());
    }
  };
};

export const createSiteRequest = makeActionCreator(CREATE_SITE_REQUEST);
export const createSiteSuccess = makeActionCreator(
  CREATE_SITE_SUCCESS,
  "createsitedata"
);
export const createSiteFailure = makeActionCreator(CREATE_SITE_FAILURE);

export const createSite = (data) => {
  const body = {
    siteName: data.site_name,
    siteManager: {
      name: data.manager,
      email: data.email,
      phone: data.phone_number,
    },
    siteAddress: {
      place: data.address,
      longitude: data.placeCenter.lng,
      latitude: data.placeCenter.lat,
    },
    notes: data.note,
  };

  return async (dispatch) => {
    dispatch(createSiteRequest());
    try {
      const createSitePost = await API.post(SITE_MAP_API_ID, `/sites`, {
        body,
      });

      dispatch(createSiteSuccess(createSitePost));
    } catch (e) {
      console.error(e);
      dispatch(createSiteFailure());
    }
  };
};

export const editSiteRequest = makeActionCreator(EDIT_SITE_REQUEST);
export const editSiteSuccess = makeActionCreator(
  EDIT_SITE_SUCCESS,
  "editsitedata"
);
export const editSiteFailure = makeActionCreator(EDIT_SITE_FAILURE);

export const editSite = (siteId, data) => {
  var phone_number = data.phone_number
    ? formatPhoneNumber(data.phone_number)
    : "";

  const body = data.site_name
    ? {
        siteName: data.site_name,
        siteManager: {
          name: data.manager,
          email: data.email,
          phone: phone_number,
        },
        siteAddress: {
          place: data.address,
          longitude: data.placeCenter.lng,
          latitude: data.placeCenter.lat,
        },
        notes: data.note,
        status: data.status,
      }
    : {
        notes: data.note,
      };

  return async (dispatch) => {
    dispatch(editSiteRequest());
    try {
      const editSitePost = await API.put(SITE_MAP_API_ID, `/sites/${siteId}`, {
        body,
      });

      dispatch(editSiteSuccess(editSitePost));
    } catch (e) {
      console.error(e);
      dispatch(editSiteFailure());
    }
  };
};

export const shareSiteRequest = makeActionCreator(SHARE_SITE_REQUEST);
export const shareSiteSuccess = makeActionCreator(
  SHARE_SITE_SUCCESS,
  "sharesitedata"
);
export const shareSiteFailure = makeActionCreator(SHARE_SITE_FAILURE);

export const shareSite = (sender, shareType, itmeValue, data) => {
  var body = {};
  if (shareType === "email") {
    body = {
      message: data,
      shareType: shareType,
      email: sender.email,
    };
  } else {
    var phone_number = formatPhoneNumber(sender.phone_number);
    body = {
      message: data,
      shareType: shareType,
      phoneNumber: phone_number,
    };
  }

  return async (dispatch) => {
    dispatch(shareSiteRequest());
    try {
      const shareSitePost = await API.post(
        SITE_MAP_API_ID,
        `/sites/${itmeValue.siteId}/share`,
        {
          body,
        }
      );

      dispatch(shareSiteSuccess(shareSitePost));
    } catch (e) {
      console.error(e);
      dispatch(shareSiteFailure());
    }
  };
};

export const siteSubscriptionDeleteRequest = makeActionCreator(
  SITE_SUB_DELETE_REQUEST
);
export const siteSubscriptionDeleteSuccess = makeActionCreator(
  SITE_SUB_DELETE_SUCCESS,
  "sitesubscriptiondeletedata"
);
export const siteSubscriptionDeleteFailure = makeActionCreator(
  SITE_SUB_DELETE_FAILURE
);

export const subscriptionSite = (cognitoSub, isSubscribed) => {
  return async (dispatch) => {
    dispatch(siteSubscriptionDeleteRequest());
    try {
      const sitesubscriptionDelete = isSubscribed
        ? await API.post(
            USERS_API_ID,
            `/users/${encodeURIComponent(cognitoSub)}/mapsubscription`
          )
        : await API.del(
            USERS_API_ID,
            `/users/${encodeURIComponent(cognitoSub)}/mapsubscription`
          );

      dispatch(siteSubscriptionDeleteSuccess(sitesubscriptionDelete));
    } catch (e) {
      console.error(e);
      dispatch(siteSubscriptionDeleteFailure());
    }
  };
};

export const sitesOrderRequest = makeActionCreator(SITE_ORDER_REQUEST);
export const sitesOrderSuccess = makeActionCreator(
  SITE_ORDER_SUCCESS,
  "siteorderdata"
);
export const siteOrderFailure = makeActionCreator(SITE_ORDER_FAILURE);

export const orderSiteitem = (body) => {
  body = { data: body };
  return async (dispatch) => {
    dispatch(sitesOrderRequest());
    try {
      const siteOrdered = await API.put(SITE_MAP_API_ID, `/sites/sequence`, {
        body,
      });

      dispatch(sitesOrderSuccess(siteOrdered));
    } catch (e) {
      console.error(e);
      dispatch(siteOrderFailure());
    }
  };
};
