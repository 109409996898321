import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

import { Image, TouchableOpacity } from 'react-native';

import { styles } from '_components/common/MapButton.style';

/**
 * TRASH ICON
 *
 * Common trash icon button for each notification, allows the user
 * to delete the notification.
 */
class MapButton extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <TouchableOpacity onPress={this.props.onClick}>
        <Image style={[styles.editIcon]} source={require('_assets/images/map-icon.svg')} />
      </TouchableOpacity>
    );
  }
}

MapButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  
};

export default connect(null, null)(MapButton);
