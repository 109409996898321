import React, { Component } from "react";
import PropTypes from "prop-types";
// Redux
import { connect } from "react-redux";
import { Image, TouchableOpacity, Text } from "react-native";

import { styles } from "_components/common/CreateGroupButton.style.js";
import { Images } from "../../assets/";
import { View } from "react-native-web";
/**
 * TRASH ICON
 *
 * Common trash icon button for each notification, allows the user
 * to delete the notification.
 */
class IconButtons extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      imageName,
      buttonWithText,
      label,
      onPress,
      width,
      iconStyles,
      selected,
    } = this.props;
    return buttonWithText ? (
      <TouchableOpacity onPress={onPress}>
        <View style={[styles.buttonWrapper]}>
          <View style={styles.iconWrapper}>
            <Image style={[styles.iconStyleSmall]} source={Images[imageName]} />
          </View>
          <View style={[styles.textWrapper]}>
            <Text style={styles.buttonLabel}>{label}</Text>
          </View>
        </View>
      </TouchableOpacity>
    ) : (
      <TouchableOpacity onPress={onPress}>
        <View style={[selected ? styles.selected : {}]}>
          <Image
            style={[styles.iconStyle, iconStyles]}
            source={Images[imageName]}
          />
        </View>
      </TouchableOpacity>
    );
  }
}

IconButtons.propTypes = {
  onClick: PropTypes.func.isRequired,
  imageName: PropTypes.any.isRequired,
  iconStyles: PropTypes.any,
};

export default connect(null, null)(IconButtons);
