// React
import { Component } from 'react';
import PropTypes from 'prop-types';

import { getUserGroups } from '_redux/user/selectors';

// Redux
import { connect } from 'react-redux';

/**
 * This component allows you to easily show or hide other
 * components based on the user's cognito group assignment.
 */
class AccessControl extends Component {

  /**
   * Constructor.
   *
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this._hasAccess = this._hasAccess.bind(this);
  }

  /**
   * Method to check if the user has access based on their current
   * group assignment and the allowedGroups / disallowedGroups props
   * passed into the component.
   */
  _hasAccess() {
    const {
      userGroups,
      allowedGroups,
      disallowedGroups,
    } = this.props;

    // If allowedGroups and disallowedGroups are missing,
    // grant access.
    if (!allowedGroups && !disallowedGroups) {
      return true;
    }

    // check if the user is in the allowed groups array.
    const inAllowed = userGroups.some(g => (allowedGroups || []).includes(g));

    // check if the user is in the disallowed groups array.
    const inDisallowed = userGroups.some(g => (disallowedGroups || []).includes(g));

    return inAllowed && !inDisallowed;
  }

  /**
   * Render Component.
   */
  render() {
    const { children } = this.props;

    return this._hasAccess() ? children : null;
  }
}

const mapStateToProps = (state) => {
  return {
    userGroups: getUserGroups(state),
  };
};

AccessControl.propTypes = {
  allowedGroups: PropTypes.array,
  children: PropTypes.any,
  disallowedGroups: PropTypes.array,
  userGroups: PropTypes.array,
};

// Cognito Constants
// TODO: Refactor out of here?
export const ADMIN = 'admin';
export const MANUFACTURER = 'manufacturer';
export const DEALER = 'dealer';
export const OWNER = 'owner';
export const CREW_LEADER = 'crew_leader';
export const CREW_MEMBER = 'crew_member';
export const INDIVIDUAL = 'individual';
export const GROUP_DISPLAY_NAMES = {
  [ADMIN]: 'Admin',
  [DEALER]: 'Dealer',
  [MANUFACTURER]: 'Manufacturer',
  [OWNER]: 'Owner',
  [CREW_LEADER]: 'Crew Leader',
  [CREW_MEMBER]: 'Crew Member',
};

export default connect(mapStateToProps, null)(AccessControl);


