import { StyleSheet } from 'react-native';
import { roboto, textLight, red, yellow, light_gray } from '_style/Global.style';

export const styles = StyleSheet.create({
  addCrewMemberModalContent: {
    width: 425,
    marginLeft: 75,
    marginRight: 75,
  },
  title: {
    color: yellow,
    fontFamily: roboto,
    fontWeight: 'bold',
    fontSize: 24,
    letterSpacing: 1.6,
    textAlign: 'center',
    marginBottom: 15,
  },
  description: {
    fontFamily: roboto,
    fontSize: 14,
    letterSpacing: 1.6,
    textAlign: 'center',
    marginBottom: 15,
  },

  formInput: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 10,
    paddingBottom: 10,
  },

  actionButtonContainer:{
    padding: 15,
  },

  labelText: {
    fontFamily: roboto,
    fontSize: 18,
    fontWeight: 'bold',
    color: textLight,
  },

  roleTypeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 40,
  },

  dropDownContainer:{
    width: 225,
  },

  checkboxContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
  errorMessage: {
    color: red,
    padding: 10,
  },

  successTitle: {
    fontFamily: roboto,
    fontSize: 36,
    fontWeight: 'bold',
    color: yellow,
    textAlign: 'center',
    marginBottom: 20,
  },
  successInfoContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    width: 360,
    marginTop: 20,
    marginBottom: 40,
  },
  successRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
    width: '100%',
  },
  tableText: {
    fontFamily: roboto,
    fontWeight: 'bold',
    fontSize: 18,
    color: textLight,
  },
  closeButton: {
    width: '100%',
    backgroundColor: '#424242',
  },
  closeButtonText: {
    color: '#FAFAFA',
  },
});
