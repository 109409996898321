// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Amplify
import { Auth } from 'aws-amplify';

// Custom Components.
import FormInput from '_components/common/FormInput.component';

// UI Framework
import { Image, ImageBackground } from 'react-native';
import {
  Body,
  Button,
  Card,
  CardItem,
  Spinner,
  Text,
  View,
} from 'native-base';
import { Col, Grid } from 'react-native-easy-grid';

// Style
import { styles as global, placeholderTextColor, red } from '_style/Global.style';
import { styles } from '_components/auth/CustomConfirmSignUp.style';


/**
 * The CustomConfirmSignUp component implements the authentication screen
 * where the user enters a verification code they receive via text
 * to confirm their account.
 */
class CustomConfirmSignUp extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isConfirming: false,
      isResending: false,
      failedConfirmation: false,
      failedResend: false,
      verification_code: '',
      errorMessage: '',
    };

    this._clearState = this._clearState.bind(this);

    this._confirmSignUp = this._confirmSignUp.bind(this);
    this._resendCode = this._resendCode.bind(this);
    this._signIn = this._signIn.bind(this);

    this._handleCodeInput = this._handleCodeInput.bind(this);
  }

  /**
   * Utility function to clear the state.
   */
  _clearState() {
    this.setState({
      isConfirming: false,
      isResending: false,
      failedConfirmation: false,
      verification_code: '',
      errorMessage: '',
    });
  }

  /**
   * Will navigate the user back to the sign in form.
   */
  _signIn () {
    const { onStateChange } = this.props;

    onStateChange('signIn');
  }

  /**
   * After the user has entered the verification code into the form,
   * they click the Confirm button and this is triggered to make a call
   * back to AWS to confirm the code they entered was correct.
   */
  async _confirmSignUp() {
    const { verification_code } = this.state;
    const { onStateChange, authData: username } = this.props;

    // Basic form validation.
    if (!verification_code) {
      this.setState({
        failedConfirmation: true,
        errorMessage: 'Verification code required'
      });
    }

    // Replace the Confirm button with a spinner.
    this.setState({
      isConfirming: true,
    });

    try {
      // Initiate the confirmation.
      await Auth.confirmSignUp(username, verification_code);

      // If we're here the user succeeded, navigate back to the sign
      // in screen.
      this._clearState();
      onStateChange('signIn');

    } catch (e) {
      console.log(e);
      this.setState({
        failedConfirmation: true,
        errorMessage: e.message,
      });

    } finally {
      this.setState({
        isConfirming: false,
      });
    }
  }

  /**
   * Will trigger a re-send of the verification code.
   */
  async _resendCode() {
    const { authData: username } = this.props;

    this.setState({
      isResending: true,
    });

    try {
      await Auth.resendSignUp(username);
    } catch (e) {
      console.log(e);
      this.setState({
        failedResend: true,
        errorMessage: e.message,
      });
    } finally {
      this.setState({
        isResending: false,
      });
    }
  }

  /**
   * Keeps track of what the user has entered into the code field.
   */
  _handleCodeInput(fieldId, value) {
    this.setState({
      [fieldId]: value,
    });
  }

  render() {
    const { isConfirming, isResending, failedConfirmation, failedResend, errorMessage } = this.state;
    const { authState } = this.props;

    if (authState !== 'confirm') {
      return null;
    }
    return (
      <ImageBackground
        style={ global.backgroundImage }
        source={require('_assets/images/auth-background.jpg')}
      >
        <Grid style={ global.background }>
          <Col style={ global.center }>
            <Card style={ [global.card, styles.card] }>
              <CardItem style={ global.cardItem }>
                <Body style={ global.center }>
                  <Image
                    style={ styles.headerImage }
                    source={require('_assets/images/bazooka_farmstar.png')}
                  />
                  <Text style={ [global.textLight, global.fontSF, styles.paragraph] }>
                    Confirm Account
                  </Text>
                  <View style={[styles.formContainer]}>
                    <View style={[global.authInputContainer]}>
                      <FormInput
                        fieldId={'verification_code'}
                        placeholder={'Verification code'}
                        placeholderTextColor={placeholderTextColor}
                        helpText={'6 digit code texted to your phone'}
                        helpTextStyle={global.authInputHelpText}
                        onChange={this._handleCodeInput}
                        inputStyles={[global.authInput]}
                        itemStyles={[global.authItem]}
                        insetShadow={false}
                      />
                    </View>
                  </View>
                  {
                    isResending ?
                      <Spinner color={ red } />
                      :
                      <Text style={ [global.textLight, global.fontSF, styles.resendCode] }>
                        <Button transparent onPress={ this._resendCode }>
                          <Text uppercase={ false } style={ [global.textYellow, styles.resendCodeText] }>
                            Resend Code
                          </Text>
                        </Button>
                      </Text>
                  }
                  { isConfirming ?
                      <Spinner color={ red } />
                      :
                      <Button
                        disabled={ isConfirming }
                        style={ [global.authFormButton] }
                        onPress={ this._confirmSignUp }
                      >
                        <Text
                          uppercase={ false }
                          style={ [global.authFormButtonText] }
                        >
                          Confirm
                        </Text>
                      </Button>
                  }
                  { !failedConfirmation && !failedResend ? null :
                    <Text style={ styles.errorMessage }>
                      { errorMessage }
                    </Text>
                  }
                  <Text style={ [global.textLight, global.fontSF, styles.backToSignIn] }>
                    <Button transparent onPress={ this._signIn }>
                      <Text uppercase={ false } style={ [global.textYellow, styles.signIn] }>
                        Back to Sign in
                      </Text>
                    </Button>
                  </Text>
                </Body>
              </CardItem>
            </Card>
          </Col>
        </Grid>
      </ImageBackground>
    );
  }
}

CustomConfirmSignUp.propTypes = {
  authData: PropTypes.any,
  authState: PropTypes.string,
  onStateChange: PropTypes.func,
};

export default connect(null, null)(CustomConfirmSignUp);


