import { createReducer } from "_redux/rootActions";
import {
   CLEAR_STATE,
   MAP_FULL_SCREEN,
   INIT_SITEMAP,
   SITEMAP_SAVE_REQUEST,
   SITEMAP_LOAD_SUCCESS,
   SITEMAP_SAVE_FAILEAR,
   SITEMAP_SAVE_SUCCESS,
   SITEMAP_LOAD_FAILEAR,
   SITEMAP_LOAD_REQUEST
   } from "_redux/siteMap/types";

const initialState = {
    isSiteMapIntialized: false,
    isMapFulScreen: false,
};

export default createReducer(initialState, {
  // CLEAR STATE
  [CLEAR_STATE]: () => initialState,
  // MAP FULL SCREEN
  [MAP_FULL_SCREEN]: (state, action) => {
    const { status } = action;
    return { ...state, isMapFulScreen: status };
  },
  // INIT SITE MAP
  [INIT_SITEMAP]: (state) => {
    return {
        ...state,
        isSiteMapIntialized: true,
        isMapFulScreen: true,
    }
  },

  [SITEMAP_SAVE_REQUEST]: (state) => {
    return {...state, isSaveSiteMap: true};
  },

  [SITEMAP_SAVE_SUCCESS]: (state) => {
    return {...state, isSaveSiteMap: false};
  },

  [SITEMAP_SAVE_FAILEAR]: (state) => {
    return {...state, isSaveSiteMap: false};
  },

  [SITEMAP_LOAD_REQUEST]: (state) => {
    return {...state, isLoadSiteMap: false};
  },

  [SITEMAP_LOAD_SUCCESS]: (state, action) => {
    const { mapdata } = action;
    return {...state, isLoadSiteMap: true, mapdata};
  },

  [SITEMAP_LOAD_FAILEAR]: (state) => {
    return {...state, isLoadSiteMap: true};
  },
});
