// React
import React, { Component } from 'react';

// Redux
import { connect } from 'react-redux';

// Native Base Components
import {
  Text,
} from 'native-base';

// Style
import { styles } from '_components/common/AppVersion.style';

import packageJson from '../../../../package.json';

class AppVersion extends Component {

  constructor(props) {
    super(props);
  }

  render() {

    return (
      <Text style={[styles.versionNumber]}>
        v{ packageJson.version }
      </Text>
    );
  }
}

export default connect(null, null)(AppVersion);


