import {
  FlatList,
  StyleSheet,
  TouchableOpacity,
  AsyncStorage,
  Platform,
  Image,
  Dimensions,
  Clipboard,
} from "react-native";

import React, { Component } from "react";
import { navigate } from "_routes/RootNavigation";
import { connect } from "react-redux";
import { styles as global } from "_style/Global.style";
import { styles } from "_pages/Feedback.style";
import { styles2 } from "_pages/Site.style";
import Modal from "_components/common/Modal.component";
import ConfirmSuccess from "_components/common/SiteSuccessModal.component";
import ArchivePopUp from "_components/create_site/Archive.component";
import ArchiveDeleteModal from "_components/create_site/ArchiveDeleteModalContent";
import { showToast } from "_redux/toasts/actions";
import Sharesitepage from "_components/create_site/ShareSite.component";
import Images from "../assets/imagePaths";
// UI Framework
import {
  Button,
  Spinner,
  Text,
  List,
  View,
  Fab,
  Picker,
  Icon,
  Tooltip,
} from "native-base";
import { Animated, ScrollView } from "react-native";
import { Row, Col, Grid } from "react-native-easy-grid";

import EditButtonComponent from "../components/common/EditButton.component";
import MoreButtonComponent from "../components/common/moreOptionsbtn.component";
import OptionButtonComponent from "../components/common/OptionButton.component";
import SiteMapEdit from "./SiteMapEdit.page";
import ShowMap from "./ShowMap.page";
import MapButtonComponent from "../components/common/MapButton.component";
import IconButtons from "../components/common/IconButtons";
import FormInput from "_components/common/FormInput.component";
import CreateSiteComponent, {
  CreateSite,
} from "_components/create_site/CreateSite.component";
import ToastComponent from "_components/create_site/ToastContent.component";
import { DrawerActions } from "@react-navigation/native";
import DraggableFlatList from "react-native-draggable-flatlist";
import Geocoder from "react-native-geocoding";
import ToastModal from "_components/create_site/ToastModal.component";
import API from "_redux/site/api";
import SharesiteMailpage from "_components/create_site/SharesiteViaMailEmail.component";
import EditNotesContent from "_components/create_site/EditNotesModalContent";

import AccessControl, {
  ADMIN,
  MANUFACTURER,
  CREW_LEADER,
  CREW_MEMBER,
  DEALER,
  OWNER,
} from "_components/auth/AccessControl.component";

import Menu, {
  MenuProvider,
  MenuTrigger,
  MenuOptions,
  MenuOption,
  renderers,
} from "react-native-popup-menu";

import {
  clearState,
  fetchSiteData,
  deleteSiteItem,
  archivedSiteitem,
  unarchivedSiteitem,
  createSite,
  shareSite,
  editSite,
  orderSiteitem,
} from "_redux/site/actions";

const { SlideInMenu } = renderers;

class SiteDrafts extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      ShowSiteCreateModal: false,
      ShowConfirmModal: false,
      EditSite: false,
      pageEdit: false,
      DeletePopup: false,
      ArchivePopup: false,
      drawerClose: false,
      showMapPage: false,
      ActiveArchive: false,
      active: false,
      ShowUnarchiveModal: false,
      activeDataSource: [],
      archivedDataSource: [],
      menuOpened: false,
      DeletePopupFromArchived: false,
      ShowToastModal: false,
      ShowDeleteToastModal: false,
      isLoaded: false,
      ArchiveDeleteModal: false,
      selectedItem: {},
      indicator: new Animated.Value(0),
      activeStatus: "",
      placeCenter: {},
      showMapAddressEmpty: false,
      shareSiteEmpty: false,
      wholeHeight: 1,
      visibleHeight: 0,
      windowWidth: "",
      windowHeight: "",
      screenWidth: "",
      screenHeight: "",
      orientation: "",
      SiteShareModal: false,
      LinkCopied: false,
      copyText: "",
      SiteShareMailModal: false,
      SiteShareTextModal: false,
      recieverDetails:
        "Hey There! Bazooka Farmstar is sharing a job site with you.",
      errorMSG: false,
      ShowShareSiteToastModal: false,
      ShowNotesModal: false,
      siteId: "",
      itemValue: "",
      noteEdited: false,
      clickedListitem: "",
      siteItemIndex: 0,
      siteItemIndexText: "",
      arc_clickedListitem: "",
      arc_siteItemIndex: 0,
      arc_siteItemIndexText: "",
    };
    this.flatListRef = React.createRef();
    this.scrollPositionRef = React.createRef();
    this._openDeletedModal = this._openDeletedModal.bind(this);
    this.deleteSiteitem = this.deleteSiteitem.bind(this);
  }

  NavigateToEditMap = () => {
    this.setState({ pageEdit: true });
  };

  componentDidMount() {
    const windowWidth = Dimensions.get("window").width;
    const windowHeight = Dimensions.get("window").height;
    const screenWidth = Dimensions.get("screen").width;
    const screenHeight = Dimensions.get("screen").height;

    const { fetchSiteDataFromStore } = this.props;
    fetchSiteDataFromStore();

    this.setState({
      activeStatus: this.props.route.params ? this.props.route.params : null,
      windowWidth: windowWidth,
      windowHeight: windowHeight,
      screenWidth: screenWidth,
      screenHeight: screenHeight,
    });
    if (this.state.windowHeight >= this.state.windowWidth) {
      this.setState({ orientation: "PORTRAIT" });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.getSitesData !== this.props.getSitesData) {
      //Perform some operation here
      this.setState({
        isLoaded: true,
        activeDataSource: this.props.getSitesData.filter(
          (e) => e.status == "ACTIVE"
        ),
        archivedDataSource: this.props.getSitesData.filter(
          (e) => e.status == "ARCHIVED"
        ),
      });
      // do what you wanna do with user here
    }

    if (prevProps.getSiteEditRes !== this.props.getSiteEditRes) {
      if (
        this.props.getSiteEditRes
          ? this.props.getSiteEditRes.status === "success"
          : null
      ) {
        this.setState({
          ShowConfirmModal: true,
          ShowNotesModal: false,
          isLoaded: false,
          noteEdited: true,
        });
        const { fetchSiteDataFromStore } = this.props;
        fetchSiteDataFromStore();
      } else {
        this.setState({ isLoaded: false });
      }
    }
    if (prevProps.getShareSiteRes !== this.props.getShareSiteRes) {
      if (
        this.props.getShareSiteRes
          ? this.props.getShareSiteRes.message === "invite sent!"
          : null
      ) {
        this.setState({
          isLoaded: true,
          SiteShareTextModal: false,
          SiteShareMailModal: false,
          ShowShareSiteToastModal: true,
          recieverDetails:
            "Hey There! Bazooka Farmstar is sharing a job site with you.",
        });
        this._completedShareSite();
      } else {
        this.setState({
          isLoaded: true,
          SiteShareMailModal: false,
          SiteShareTextModal: false,
        });
      }
    }

    if (prevProps.getDeleteSiteRes !== this.props.getDeleteSiteRes) {
      if (this.props.getDeleteSiteRes.deleteSite === "Site deleted.") {
        this._openDeletedModal();
      } else {
        this.setState({
          isLoaded: true,
        });
      }
    }
    if (prevProps.getArchivedRes !== this.props.getArchivedRes) {
      if (this.props.getArchivedRes.status === "success") {
        this._openArchivedModal();
      } else {
        this.setState({
          isLoaded: true,
        });
      }
    }
    if (prevProps.getunArchivedRes !== this.props.getunArchivedRes) {
      if (this.props.getunArchivedRes.status === "success") {
        this._openUnArchivedModal();
      } else {
        this.setState({
          isLoaded: true,
        });
      }
    }
    if (prevProps.createSiteRes !== this.props.createSiteRes) {
      if (this.props.createSiteRes.status === "success") {
        this._openConfirmModal();
      } else {
        this.setState({
          isLoaded: true,
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.route.params = "";
    const { clearState } = this.props;
    clearState();
  }

  pressDeleteSiteDataFromArchive(data) {
    this.setState({ DeletePopupFromArchived: true, selectedItem: data });
  }
  pressDeleteSiteData(data) {
    this.setState({ DeletePopup: true, selectedItem: data });
  }

  deleteSiteitem(data) {
    this.setState({
      DeletePopup: false,
      DeletePopupFromArchived: false,
      isLoaded: false,
      ArchiveDeleteModal: false,
    });
    const { deleteSite } = this.props;
    deleteSite(data.siteId);
  }

  _CloseShareModal = () => {
    this.setState({ SiteShareModal: false });
  };

  pressArchivedSiteData(data) {
    this.setState({
      ArchivePopup: true,
      selectedItem: data,
    });
  }
  archivedSiteitem(data) {
    this.setState({
      ArchivePopup: false,
      isLoaded: false,
      ArchiveDeleteModal: false,
    });
    const formdata = { status: "ARCHIVED" };

    const { archiveSite } = this.props;
    archiveSite(data.siteId, formdata);
  }

  pressUnarchivedSiteData(data) {
    this.setState({ ShowUnarchiveModal: true, selectedItem: data });
  }

  _closeUnarchivedModal = (data) => {
    this.setState({ ShowUnarchiveModal: false, isLoaded: false });
    const formdata = { status: "ACTIVE" };
    const { unarchiveSite } = this.props;
    unarchiveSite(data.siteId, formdata);
  };

  _closeDrawer = () => {
    this.setState({ drawerClose: true });
  };

  NavigateToMap = (address) => {
    // Initialize the module (needs to be done only once)
    Geocoder.init("AIzaSyBU0_g8nGzHxsr-BQUuQvSZ3zuPBSKAFjg"); // use a valid API key
    // Search by address
    Geocoder.from(address)
      .then((json) => {
        const location = json.results[0].geometry.location;
        window.open(
          "https://maps.google.com?q=" + location.lat + "," + location.lng
        );
      })
      .catch((error) => console.warn(error));
  };

  NavigateToShowMapPage = (address, siteId) => {
    Geocoder.init("AIzaSyBU0_g8nGzHxsr-BQUuQvSZ3zuPBSKAFjg"); // use a valid API key
    // Search by address
    Geocoder.from(address)
      .then((json) => {
        const location = json.results[0].geometry.location;
        const zoom = 15;
        const readOnly = true;

        navigate("Site", {
          location,
          zoom,
          readOnly,
          siteId,
          fromEditSite: false,
        });
        //window.open("https://maps.google.com?q=" + location.lat + "," + location.lng);
      })
      .catch((error) => console.warn(error));
  };

  NavigateToEditMapPage = (item) => {
    var passValue = item;
    navigate("SiteEdit", { passValue });
  };

  clickedOnItem = (value, index) => {
    this.setState({ clickedListitem: value.siteId, siteItemIndex: index });
    const arr = this.state.activeDataSource;
    const itemIndex = arr.findIndex((item) => item.siteId === value.siteId);
    if (index == 0) {
      this.setState({ siteItemIndexText: "up_0" });
    } else {
      this.setState({ siteItemIndexText: "" });
    }
    if (index == arr.length - 1) {
      this.setState({ siteItemIndexText: "down_0" });
    }
  };

  clickedOnArchivedItem = (value, index) => {
    this.setState({
      arc_clickedListitem: value.siteId,
      arc_siteItemIndex: index,
    });
    const arr = this.state.archivedDataSource;
    const itemIndex = arr.findIndex((item) => item.siteId === value.siteId);
    if (index == 0) {
      this.setState({ arc_siteItemIndexText: "up_0" });
    } else {
      this.setState({ arc_siteItemIndexText: "" });
    }
    if (index == arr.length - 1) {
      this.setState({ arc_siteItemIndexText: "down_0" });
    }
  };

  arc_moveSiteUp() {
    const arr = this.state.archivedDataSource;
    const itemIndex = arr.findIndex(
      (item) => item.siteId === this.state.arc_clickedListitem
    );

    if (itemIndex > 0) {
      const prevItem = arr[itemIndex - 1];
      arr[itemIndex - 1] = arr[itemIndex];
      arr[itemIndex] = prevItem;
    }
    if (itemIndex === 1 || itemIndex === 0) {
      this.setState({
        arc_siteItemIndexText: "up_0",
      });
    } else {
      this.setState({
        arc_siteItemIndexText: "",
      });
    }
    const newDeviceList = arr.slice();
    this.state.archivedDataSource = newDeviceList;

    // value.isChecked = !value.isChecked;
    this.setState({
      archivedDataSource: this.state.archivedDataSource,
      arc_siteItemIndex: itemIndex,
    });

    this.arcflatListRef.scrollToIndex({ animated: true, index: itemIndex - 1 });

    const passDataList = this.state.archivedDataSource.map(
      (listValue, index) => {
        return { siteId: listValue.siteId, sequence: index };
      }
    );

    const { orderedSite } = this.props;
    orderedSite(passDataList);
  }

  arc_moveSiteDown() {
    const arr = this.state.archivedDataSource;
    const itemIndex = arr.findIndex(
      (item) => item.siteId === this.state.arc_clickedListitem
    );
    if (itemIndex !== arr.length - 1) {
      const prevItem = arr[itemIndex + 1];
      arr[itemIndex + 1] = arr[itemIndex];
      arr[itemIndex] = prevItem;
    }
    if (itemIndex === arr.length - 2) {
      this.setState({
        arc_siteItemIndexText: "down_0",
      });
    } else {
      this.setState({
        arc_siteItemIndexText: "",
      });
    }
    const newDeviceList = arr.slice();
    this.state.archivedDataSource = newDeviceList;

    //value.isChecked = !value.isChecked;
    this.setState({
      archivedDataSource: this.state.archivedDataSource,
      siteItemIndex: itemIndex,
    });
    if (itemIndex > 4) {
      this.arcflatListRef.scrollToIndex({ animated: true, index: itemIndex });
    }
    const passDataList = this.state.archivedDataSource.map(
      (listValue, index) => {
        return { siteId: listValue.siteId, sequence: index };
      }
    );

    const { orderedSite } = this.props;
    orderedSite(passDataList);
  }

  _moveSiteUp() {
    //this.scrollToIndex()
    const arr = this.state.activeDataSource;
    const itemIndex = arr.findIndex(
      (item) => item.siteId === this.state.clickedListitem
    );

    if (itemIndex > 0) {
      const prevItem = arr[itemIndex - 1];
      arr[itemIndex - 1] = arr[itemIndex];
      arr[itemIndex] = prevItem;
    }
    if (itemIndex === 1 || itemIndex === 0) {
      this.setState({
        siteItemIndexText: "up_0",
      });
    } else {
      this.setState({
        siteItemIndexText: "",
      });
    }
    const newDeviceList = arr.slice();
    this.state.activeDataSource = newDeviceList;

    // value.isChecked = !value.isChecked;
    this.setState({
      activeDataSource: this.state.activeDataSource,
      siteItemIndex: itemIndex,
    });
    if (this.state.siteItemIndexText !== "up_0") {
      this.flatListRef.scrollToIndex({ animated: true, index: itemIndex - 1 });
    }
    const passDataList = this.state.activeDataSource.map((listValue, index) => {
      return { siteId: listValue.siteId, sequence: index };
    });

    const { orderedSite } = this.props;
    orderedSite(passDataList);
  }

  _moveSiteDown() {
    // this.scrollToIndex()
    const arr = this.state.activeDataSource;
    const itemIndex = arr.findIndex(
      (item) => item.siteId === this.state.clickedListitem
    );
    if (itemIndex !== arr.length - 1) {
      const prevItem = arr[itemIndex + 1];
      arr[itemIndex + 1] = arr[itemIndex];
      arr[itemIndex] = prevItem;
    }
    if (itemIndex === arr.length - 2) {
      this.setState({
        siteItemIndexText: "down_0",
      });
    } else {
      this.setState({
        siteItemIndexText: "",
      });
    }
    const newDeviceList = arr.slice();
    this.state.activeDataSource = newDeviceList;

    //value.isChecked = !value.isChecked;
    this.setState({
      activeDataSource: this.state.activeDataSource,
      siteItemIndex: itemIndex,
    });
    if (itemIndex > 4) {
      this.flatListRef.scrollToIndex({ animated: true, index: itemIndex });
    }
    const passDataList = this.state.activeDataSource.map((listValue, index) => {
      return { siteId: listValue.siteId, sequence: index };
    });

    const { orderedSite } = this.props;
    orderedSite(passDataList);
  }

  activeRenderItems = ({ item, index, move, moveEnd, isActive }) => {
    var siteID = item.siteId;
    var activeStatus = "ACTIVE";
    {
      this.state.activeDataSource.map((item, index) => {
        return (item.isChecked = false);
      });
    }
    const addborderColor =
      item.siteId === this.state.clickedListitem ? 0.05 : 0;
    console.log(item);
    return (
      <>
        <View
          ref={this.myRef}
          key={index}
          onClick={() => this.clickedOnItem(item, index)}
          style={{
            flex: 1,
            width: "100%",
            flexDirection: "row",
            backgroundColor: index % 2 === 0 ? "#212121" : "#1A1A1A",
            padding: 10,
            borderColor: "#EEB310",
            borderRadius: 6,
            borderWidth: addborderColor,
          }}
        >
          <View style={{ width: "79.4%" }}>
            <View style={{ flexDirection: "row" }}>
              <Text style={[styles2.listFontSize]}>{item.siteName}</Text>
              <Text style={[styles2.listFieldFont]}>
                {" "}
                - {item.fieldsCount} Active Fields
              </Text>
            </View>
            <Text
              style={[styles2.listAddrztext]}
              onClick={() => this.NavigateToMap(item.siteAddress.place)}
            >
              {item.siteAddress ? item.siteAddress.place : ""}
            </Text>
          </View>

          <View style={[styles2.rowLeftDiv]}>
            <IconButtons
              imageName={"MENU_MAP_ICON"}
              style={[styles2.menuIcons]}
              buttonWithText={false}
              onPress={() =>
                item.siteAddress.place
                  ? this.NavigateToShowMapPage(
                      item.siteAddress.place,
                      item.siteId
                    )
                  : this.setState({ showMapAddressEmpty: true })
              }
            />

            <IconButtons
              imageName={"DOC_MENU"}
              style={[styles2.menuIcons]}
              buttonWithText={false}
              onPress={() =>
                navigate("UploadDocument", {
                  siteID,
                  activeStatus,
                  fromSiteMenu: true,
                })
              }
            />

            <AccessControl
              allowedGroups={[ADMIN, MANUFACTURER, CREW_LEADER, DEALER, OWNER]}
            >
              <IconButtons
                imageName={"NOTE_ICON"}
                style={[styles2.menuIcons]}
                buttonWithText={false}
                onPress={() => {
                  this.setState({
                    ShowNotesModal: true,
                    shareSiteEmpty: true,
                    siteId: item.siteId,
                    itemValue: item,
                  });
                }}
              />
            </AccessControl>
            <IconButtons
              imageName={"MENU_SAHRE_ICON"}
              style={[styles2.menuIcons]}
              buttonWithText={false}
              onPress={() => {
                this.setState({
                  SiteShareModal: true,
                  copyText: item.siteShareUrl.concat(item.urlHandler),
                  itemValue: item,
                });
              }}
            />

            <AccessControl
              allowedGroups={[ADMIN, MANUFACTURER, CREW_LEADER, DEALER, OWNER]}
            >
              <IconButtons
                imageName={"MENU_SITE"}
                style={[styles2.menuIcons, { marginRight: 24 }]}
                buttonWithText={false}
                onPress={() => this.NavigateToEditMapPage(item)}
              />
            </AccessControl>
            <AccessControl allowedGroups={[CREW_MEMBER]}>
              <TouchableOpacity
                onClick={() => this.NavigateToEditMapPage(item)}
              >
                <Image
                  style={[styles2.editIcon2]}
                  source={require("_assets/images/VIEW.svg")}
                />
              </TouchableOpacity>
            </AccessControl>
            <AccessControl
              allowedGroups={[ADMIN, MANUFACTURER, CREW_LEADER, DEALER, OWNER]}
            >
              <MoreButtonComponent
                onClick={() => {
                  this.setState({
                    ArchiveDeleteModal: !this.state.ArchiveDeleteModal,
                    ArchiveDeleteObject: item,
                  });
                }}
              />
            </AccessControl>
          </View>
        </View>
      </>
    );
  };

  archiveRenderItems = ({ item, index, move, moveEnd, isActive }) => {
    const addborderColor =
      item.siteId === this.state.arc_clickedListitem ? 0.05 : 0;

    return (
      <>
        <View
          key={index}
          onClick={() => this.clickedOnArchivedItem(item, index)}
          style={{
            flex: 1,
            width: "100%",
            flexDirection: "row",
            backgroundColor: index % 2 === 0 ? "#212121" : "#1A1A1A",
            padding: 10,
            borderColor: "#EEB310",
            borderRadius: 6,
            borderWidth: addborderColor,
          }}
        >
          <View style={{ width: "79.4%" }}>
            <Text style={[styles2.listFontSize]}>{item.siteName}</Text>
            <Text
              style={[styles2.listAddrztext]}
              onClick={() => this.NavigateToMap(item.siteAddress.place)}
            >
              {item.siteAddress ? item.siteAddress.place : ""}
            </Text>
          </View>
          <View style={[styles2.rowLeftDiv]}>
            <IconButtons
              imageName={"ICON_RESTORE"}
              buttonWithText={false}
              style={[styles2.archivedIcons]}
              onPress={() => this.pressUnarchivedSiteData(item)}
              onClick={() => {}}
            />
            <IconButtons
              imageName={"MENU_EDIT"}
              buttonWithText={false}
              style={[styles2.archivedIcons]}
              onPress={() => this.NavigateToEditMapPage(item)}
              onClick={() => {}}
            />
            <IconButtons
              imageName={"ICON_BIN"}
              buttonWithText={false}
              style={[styles2.archivedIcons]}
              onPress={() => this.pressDeleteSiteDataFromArchive(item)}
              onClick={() => {}}
            />
          </View>
        </View>
      </>
    );
  };

  _openConfirmModal = () => {
    setTimeout(() => {
      const { fetchSiteDataFromStore } = this.props;
      fetchSiteDataFromStore();
      this.setState({
        ShowConfirmModal: true,
      });
    }, 1500);
  };

  _showConfirmModal = (data) => {
    if (
      data.site_name
      // &&data.address
      // && data.phone_number &&
      // data.email &&
      // data.manager
    ) {
      this.setState({ ShowSiteCreateModal: false, isLoaded: false });
      const { createSitePost } = this.props;
      createSitePost(data);
    }
  };
  _completedShareSite = () => {
    setTimeout(() => {
      const { fetchSiteDataFromStore } = this.props;
      fetchSiteDataFromStore();

      this.setState({
        ShowShareSiteToastModal: false,
      });
    }, 1500);
  };

  _closeConfirmModal = () => {
    this.setState({ ShowConfirmModal: false, noteEdited: false });
  };

  _closeArchiveModal = () => {
    this.setState({ ArchivePopup: false });
  };
  _openArchivedModal = () => {
    this.setState({ ArchivePopup: false, ShowToastModal: true });

    setTimeout(() => {
      const { fetchSiteDataFromStore } = this.props;
      fetchSiteDataFromStore();

      this.setState({
        ShowToastModal: false,
        ActiveArchive: true,
      });
    }, 1500);
  };

  _openUnArchivedModal = () => {
    this.setState({ ShowUnarchiveModal: false, isLoaded: false });

    setTimeout(() => {
      const { fetchSiteDataFromStore } = this.props;
      fetchSiteDataFromStore();

      this.setState({
        ShowToastModal: false,
        ActiveArchive: false,
      });
    }, 1500);
  };
  _openDeletedModal = () => {
    this.setState({ DeletePopup: false, ShowDeleteToastModal: true });

    setTimeout(() => {
      const { fetchSiteDataFromStore } = this.props;
      fetchSiteDataFromStore();
      this.setState({
        ShowDeleteToastModal: false,
      });
    }, 1500);
  };

  _closeDeleteModal = () => {
    this.setState({ DeletePopup: false, DeletePopupFromArchived: false });
  };
  _showSiteCreate = () => {
    this.setState({ ShowSiteCreateModal: true });
  };
  _closeConfirmCreateModal = () => {
    this.setState({ ShowSiteCreateModal: false });
  };

  CopyTextModal = () => {
    this.setState({ LinkCopied: true });
    Clipboard.setString(this.state.copyText);
    setTimeout(() => {
      this.setState({ LinkCopied: false });
      this.fetchCopiedText();
    }, 1500);
  };
  fetchCopiedText = async () => {
    const text = await Clipboard.getString();
  };

  editNotesModal = () => {
    return (
      <Modal
        isVisible={this.state.ShowNotesModal}
        title={"EDIT NOTES"}
        subTitle={" "}
        headerColor={"#FAFAFA"}
        propagateSwipe={true}
        onBackdropPress={() => this.setState({ ShowNotesModal: false })}
        hideExitIcon={false}
        onClose={() => this.setState({ ShowNotesModal: false })}
        content={
          this.state.ShowNotesModal ? (
            <EditNotesContent
              ConfirmModal={(e) => this._ConfirmModal(e)}
              siteNotes={this.state.itemValue ? this.state.itemValue.notes : ""}
              disabled={!this.state.isLoaded}
            />
          ) : null
        }
      />
    );
  };

  _ConfirmModal = (data) => {
    this.setState({
      ShowNotesModal: false,
      isLoaded: false,
    });

    const { editSiteDetails } = this.props;
    editSiteDetails(this.state.siteId, data);
  };

  sharesiteModal = () => {
    return (
      <Modal
        isVisible={this.state.SiteShareModal}
        title={"SHARE SITE"}
        subTitle={"Share your job site to other users via"}
        hideExitIcon={false}
        headerColor={"#FAFAFA"}
        onClose={() => this.setState({ SiteShareModal: false })}
        content={
          this.state.SiteShareModal ? (
            <Sharesitepage
              confirmationText={"Job site link"}
              mailSubmit={() =>
                this.setState({
                  SiteShareMailModal: true,
                  SiteShareModal: false,
                })
              }
              submit={() => this._CloseShareModal}
              textSubmit={() =>
                this.setState({
                  SiteShareTextModal: true,
                  SiteShareMailModal: true,
                  SiteShareModal: false,
                })
              }
              copysubmit={() => this.CopyTextModal()}
              submitButtonText={"DONE"}
              inputValue={this.state.copyText}
              copyConfirmText={this.state.LinkCopied ? "Copied!" : null}
            />
          ) : null
        }
      />
    );
  };

  phoneFormat = (value) => {
    const cleaned = value.replace(/\D/g, "");
    if (cleaned.length > 10) {
      return;
    }
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    const formatted = !match[2]
      ? match[1]
      : match[1] +
        "-" +
        match[2] +
        (match[3] ? "-" + match[3] : "") +
        (match[4] ? "" + match[4] : "");
    this.setState({ phone_number: formatted });
  };

  sharesiteMailModal = () => {
    const shareType = this.state.SiteShareTextModal ? "text" : "email";
    return (
      <Modal
        isVisible={this.state.SiteShareMailModal}
        title={`SHARE SITE VIA ${
          this.state.SiteShareTextModal ? "TEXT MESSAGE" : "EMAIL"
        }`}
        subTitle={`Enter your ${
          this.state.SiteShareTextModal ? "mobile number" : "email address"
        } to share the job site`}
        hideExitIcon={false}
        headerColor={"#FAFAFA"}
        onClose={() =>
          this.setState({
            SiteShareMailModal: false,
            SiteShareTextModal: false,
            errorMSG: false,
          })
        }
        content={
          this.state.SiteShareMailModal ? (
            <SharesiteMailpage
              submit={(e) =>
                e.email || e.phone_number
                  ? this.ShareSiteRequest(
                      e,
                      shareType,
                      this.state.itemValue,
                      `${this.state.recieverDetails}`
                    )
                  : !e.errorMessage
                  ? this.setState({ errorMSG: true })
                  : this.setState({ errorMSG: false })
              }
              onChangetext={(e) => this.setState({ recieverDetails: e })}
              error={this.state.errorMSG}
              showHelpText={this.state.validNumber}
              inputValue={this.state.recieverDetails}
              inputMsgValue={this.state.recieverDetails}
              mobileNumber={this.state.SiteShareTextModal}
              messageLable={"Message"}
              submitButtonText={"SEND"}
              copyConfirmText={this.state.LinkCopied ? "Copied!" : null}
            />
          ) : null
        }
      />
    );
  };

  ShareSiteRequest = (sender, shareType, itmeValue, msg) => {
    this.setState({
      SiteShareMailModal: false,
      isLoaded: false,
      errorMSG: false,
    });
    const { shareSiterequest, siteShareSuccess } = this.props;
    shareSiterequest(sender, shareType, itmeValue, msg);
  };

  shareSiteSuccessModal = () => {
    return (
      <ToastModal
        isVisible={this.state.ShowShareSiteToastModal}
        hideTitle={true}
        animIn="slideInRight"
        animOut="slideOutRight"
        inTiming={1000}
        outTiming={1000}
        hideExitIcon={true}
        content={
          <ToastComponent
            toastText={"SITE SHARE SUCCESS"}
            imageStyle={{ height: 44, width: 44 }}
            imageName={"SUCCESS_IMAGE"}
          />
        }
      />
    );
  };

  render() {
    const { userInformation } = this.props;
    const indicatorSize =
      this.state.wholeHeight > this.state.visibleHeight
        ? (this.state.visibleHeight * this.state.visibleHeight) /
          this.state.wholeHeight
        : this.state.visibleHeight;

    if (this.state.drawerClose) {
      this.props.navigation.toggleDrawer;
      this.props.navigation.dispatch(DrawerActions.closeDrawer());
    }
    if (this.state.pageEdit) {
      return <SiteMapEdit />;
    } else if (this.state.showMapPage) {
      return <ShowMap />;
    }
    if (
      this.state.windowWidth < 350 ||
      this.state.screenWidth < 350 ||
      this.state.windowHeight < 350 ||
      this.state.screenHeight < 350
    ) {
      return (
        <View style={[styles.feedback]}>
          <View
            style={[
              styles.UnsupportContainer,
              { alignItems: "center", justifyContent: "center" },
            ]}
          >
            <Image
              style={{ height: 85, width: 92 }}
              source={require("_assets/images/notice-icon.svg")}
            />
            <Text
              style={{ fontSize: 28, color: "#EEB310", textAlign: "center" }}
            >
              UNSUPPORTED RESOLUTION
            </Text>
            <Text style={{ textAlign: "center", fontSize: 28 }}>
              Site mapping feature requires minimum 350x350 resolution
            </Text>
          </View>
        </View>
      );
    } else if (
      userInformation.is_subscribed === 0 &&
      (userInformation.role_id === "owner" ||
        userInformation.role_id === "crew_leader" ||
        userInformation.role_id === "crew_member")
    ) {
      console.log("PLEASE SUBCRIBE FOR SITES");
      return (
        <View style={[styles.feedback]}>
          <View
            style={[
              styles.UnsupportContainer,
              { alignItems: "center", justifyContent: "center" },
            ]}
          >
            <Image
              style={{ height: 104, width: 104 }}
              source={require("_assets/images/site_lage_Icon.svg")}
            />
            <Text
              style={{
                fontSize: 28,
                color: "#EEB310",
                textAlign: "center",
                marginTop: 38,
                marginBottom: 38,
                fontWeight: "700",
              }}
            >
              SITE MAPPING NOT ENABLED
            </Text>
            <View style={{ alignItems: "left" }}>
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 22,
                  color: "#FAFAFA",
                  fontWeight: "700",
                }}
              >
                IF YOU WOULD LIKE TO USE THE ADVANCED SITE MAPPING TOOL :
              </Text>
              <View style={{ alignItems: "left" }}>
                {/* <Button transparent onPress={ this._signUp }>
                <Text uppercase={ false } style={ [global.textYellow, styles.createAnAccount] }>
                  Create an account
                </Text>
              </Button> */}
                <Text
                  style={[
                    global.textLight,
                    global.fontSF,
                    {
                      marginTop: 10,
                      fontSize: 18,
                      color: "#FAFAFA",
                      fontWeight: "700",
                    },
                  ]}
                >
                  {`\u2022`} &nbsp;&nbsp;CONTACT YOUR&nbsp;
                  <a
                    style={{ color: "#D00000", textDecoration: "none" }}
                    target="blank"
                    href="https://www.bazookafarmstar.com/where-to-buy/"
                  >
                    DEALER
                  </a>
                </Text>
                <Text
                  style={[
                    global.textLight,
                    global.fontSF,
                    {
                      fontSize: 18,
                      marginTop: 10,
                      color: "#FAFAFA",
                      fontWeight: "700",
                    },
                  ]}
                >
                  {`\u2022`} &nbsp;&nbsp;OR EMAIL&nbsp;
                  <a
                    style={{ color: "#D00000", textDecoration: "none" }}
                    href="mailTo:Nexus@bazookafarmstar.com"
                  >
                    NEXUS@BAZOOKAFARMSTAR.COM
                  </a>
                </Text>
                <Text
                  style={[
                    global.textLight,
                    global.fontSF,
                    {
                      marginTop: 10,
                      fontSize: 18,
                      color: "#FAFAFA",
                      fontWeight: "700",
                    },
                  ]}
                >
                  {`\u2022`} &nbsp;&nbsp;OR CALL &nbsp;
                  <a
                    style={{ color: "#D00000", textDecoration: "none" }}
                    href="tel:+18007757448"
                  >
                    800-775-7448
                  </a>
                </Text>
              </View>
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 22,
                  color: "#FAFAFA",
                  fontWeight: "700",
                  marginTop: 10,
                }}
              >
                SEE&nbsp;
                <a
                  style={{ color: "#D00000", textDecoration: "none" }}
                  href="https://www.bazookafarmstar.com/"
                  target="blank"
                >
                  WWW.BAZOOKAFARMSTAR.COM
                </a>
                &nbsp;FOR MORE DETAILS.​
              </Text>
            </View>
          </View>
        </View>
      );
    } else {
      return (
        <View style={[styles.feedback]} scrollEnabled={false}>
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text style={[{ width: "90%" }, global.pageTitle]}>
              {this.state.ActiveArchive ||
              (this.state.activeStatus &&
                this.state.activeStatus.activeStatus === "ARCHIVED")
                ? "ARCHIVED SITES"
                : "ACTIVE SITES"}
            </Text>
            {this.state.ActiveArchive ||
            (this.state.activeStatus &&
              this.state.activeStatus.activeStatus === "ARCHIVED") ? (
              <View style={styles2.orderBtnContainer}>
                <IconButtons
                  imageName={
                    this.state.arc_clickedListitem &&
                    this.state.arc_siteItemIndexText !== "up_0" &&
                    this.state.archivedDataSource.length !== 1
                      ? "UP_ARROW"
                      : "UP_ARROW_DISABLED"
                  }
                  disabled={
                    this.state.arc_clickedListitem &&
                    this.state.arc_siteItemIndexText !== "up_0" &&
                    this.state.archivedDataSource.length !== 1
                      ? false
                      : true
                  }
                  style={[styles2.menuIcons]}
                  buttonWithText={false}
                  onPress={() =>
                    this.state.arc_clickedListitem &&
                    this.state.arc_siteItemIndexText !== "up_0" &&
                    this.state.archivedDataSource.length !== 1
                      ? this.arc_moveSiteUp()
                      : null
                  }
                />
                <IconButtons
                  imageName={
                    this.state.arc_clickedListitem &&
                    this.state.arc_siteItemIndexText !== "down_0" &&
                    this.state.archivedDataSource.length !== 1
                      ? "DOWN_ARROW"
                      : "DOWN_ARROW_DISABLED"
                  }
                  style={[styles2.menuIcons]}
                  disabled={
                    this.state.arc_clickedListitem &&
                    this.state.arc_siteItemIndexText !== "down_0" &&
                    this.state.archivedDataSource.length !== 1
                      ? false
                      : true
                  }
                  buttonWithText={false}
                  onPress={() =>
                    this.state.arc_clickedListitem &&
                    this.state.arc_siteItemIndexText !== "down_0" &&
                    this.state.archivedDataSource.length !== 1
                      ? this.arc_moveSiteDown()
                      : null
                  }
                />
              </View>
            ) : (
              <View style={styles2.orderBtnContainer}>
                <IconButtons
                  imageName={
                    this.state.clickedListitem &&
                    this.state.siteItemIndexText !== "up_0" &&
                    this.state.activeDataSource.length !== 1
                      ? "UP_ARROW"
                      : "UP_ARROW_DISABLED"
                  }
                  disabled={
                    this.state.clickedListitem &&
                    this.state.siteItemIndexText !== "up_0" &&
                    this.state.activeDataSource.length !== 1
                      ? false
                      : true
                  }
                  style={[styles2.menuIcons]}
                  buttonWithText={false}
                  onPress={() =>
                    this.state.clickedListitem &&
                    this.state.siteItemIndexText !== "up_0" &&
                    this.state.activeDataSource.length !== 1
                      ? this._moveSiteUp()
                      : null
                  }
                />
                <IconButtons
                  imageName={
                    this.state.clickedListitem &&
                    this.state.siteItemIndexText !== "down_0" &&
                    this.state.activeDataSource.length !== 1
                      ? "DOWN_ARROW"
                      : "DOWN_ARROW_DISABLED"
                  }
                  style={[styles2.menuIcons]}
                  disabled={
                    this.state.clickedListitem &&
                    this.state.siteItemIndexText !== "down_0" &&
                    this.state.activeDataSource.length !== 1
                      ? false
                      : true
                  }
                  buttonWithText={false}
                  onPress={() =>
                    this.state.clickedListitem &&
                    this.state.siteItemIndexText !== "down_0" &&
                    this.state.activeDataSource.length !== 1
                      ? this._moveSiteDown()
                      : null
                  }
                />
              </View>
            )}
          </View>
          {this.state.ActiveArchive ||
          (this.state.activeStatus &&
            this.state.activeStatus.activeStatus === "ARCHIVED") ? (
            <View
              style={[styles.formContainer, global.dropShadow, styles2.listRow]}
            >
              {!this.state.isLoaded ? (
                <Spinner color={"red"} />
              ) : (
                <MenuProvider>
                  <FlatList
                    key={"_"}
                    ref={(ref) => {
                      this.arcflatListRef = ref;
                    }}
                    numColumns={1}
                    showSeparator={true}
                    data={this.state.archivedDataSource}
                    renderItem={this.archiveRenderItems}
                    style={{ width: "100%", flex: 1 }}
                    keyExtractor={(item) => item.id}
                  />

                  <Button
                    style={[styles2.activeSiteBtn]}
                    onPress={() =>
                      this.setState({ ActiveArchive: false, activeStatus: "" })
                    }
                  >
                    <View style={[styles2.archivedBtnDiv]}>
                      <Image
                        style={[styles2.archivedBtnImg]}
                        source={require("_assets/images/active_sites_ico.svg")}
                      />
                      <Text style={[styles2.archiveBtnTxt, global.capitalized]}>
                        GO TO ACTIVE SITES
                      </Text>
                    </View>
                  </Button>
                </MenuProvider>
              )}
            </View>
          ) : (
            <View
              style={[styles.formContainer, global.dropShadow, styles2.listRow]}
            >
              {!this.state.isLoaded ? (
                <Spinner color={"red"} />
              ) : (
                <MenuProvider>
                  <FlatList
                    ref={(ref) => {
                      this.flatListRef = ref;
                    }}
                    key={"_"}
                    numColumns={1}
                    bounces={true}
                    showSeparator={true}
                    data={this.state.activeDataSource}
                    renderItem={this.activeRenderItems}
                    style={{ width: "100%", flex: 1 }}
                    keyExtractor={(item) => item.id}
                  />

                  <AccessControl
                    allowedGroups={[
                      ADMIN,
                      MANUFACTURER,
                      CREW_LEADER,
                      DEALER,
                      OWNER,
                    ]}
                  >
                    <Button
                      style={[styles2.activeSiteBtn]}
                      onPress={() => this.setState({ ActiveArchive: true })}
                    >
                      <View style={[styles2.archivedBtnDiv]}>
                        <Image
                          style={[styles2.archivedBtnImg]}
                          source={require("_assets/images/archive-icon.svg")}
                        />
                        <Text
                          style={[styles2.archiveBtnTxt, global.capitalized]}
                        >
                          GO TO ARCHIVED SITES
                        </Text>
                      </View>
                    </Button>
                  </AccessControl>
                </MenuProvider>
              )}
            </View>
          )}
          <AccessControl
            allowedGroups={[ADMIN, MANUFACTURER, CREW_LEADER, DEALER, OWNER]}
          >
            <View style={[styles.submitFeedbackButtonContainer]}>
              <Button
                style={[global.confirmationButton, styles.submitFeedbackButton]}
                onPress={() => this._showSiteCreate()}
              >
                <Text
                  style={[
                    global.confirmationButtonText,
                    styles.submitFeedbackButtonText,
                    global.capitalized,
                  ]}
                >
                  create a site
                </Text>
              </Button>
            </View>
          </AccessControl>
          {/* Site Create Modal */}
          <View>
            <Modal
              isVisible={this.state.ShowSiteCreateModal}
              title={"CREATE A SITE"}
              headerColor={"#EEB310"}
              propagateSwipe={true}
              subTitle={"Fill out the information below to add a new site"}
              onClose={this._closeConfirmCreateModal}
              onBackdropPress={this._closeConfirmCreateModal}
              // content={this._siteCreateContent()}
              content={
                this.state.ShowSiteCreateModal ? (
                  <CreateSiteComponent
                    showConfirmModal={(e) => this._showConfirmModal(e)}
                    disabled={!this.state.isLoaded}
                  />
                ) : null
              }
            />
            <Modal
              isVisible={this.state.ShowConfirmModal}
              hideTitle={true}
              hideExitIcon={true}
              content={
                this.state.ShowConfirmModal ? (
                  <ConfirmSuccess
                    title={"SUCCESS"}
                    titleColor={"#006644"}
                    imageStyle={{ height: 112, width: 112 }}
                    imageName={"SUCCESS_IMAGE"}
                    subTitle={`YOU HAVE SUCCESSFULLY \n ${
                      this.state.noteEdited
                        ? "UPDATED THE NOTES"
                        : "CREATED THE NEW SITE"
                    }`}
                    onClose={this._closeConfirmModal}
                    buttonText={"CLOSE"}
                  />
                ) : null
              }
            />

            <Modal
              isVisible={this.state.DeletePopupFromArchived}
              title={"DELETE SITE"}
              hideExitIcon={true}
              content={
                this.state.DeletePopupFromArchived ? (
                  <ArchivePopUp
                    onCancel={() => this._closeDeleteModal}
                    submitButtonText={"DELETE"}
                    confirmationText={
                      "Would you like to permanently delete \n the site from your archived sites?"
                    }
                    submit={() => this.deleteSiteitem(this.state.selectedItem)}
                    cancelButtonText={"DO NOT DELETE"}
                  />
                ) : null
              }
            />
            <Modal
              isVisible={this.state.ShowUnarchiveModal}
              hideTitle={true}
              hideExitIcon={true}
              content={
                this.state.ShowUnarchiveModal ? (
                  <ConfirmSuccess
                    title={"NOTICE"}
                    titleColor={"#EEB310"}
                    imageStyle={{ height: 104, width: 112 }}
                    imageName={"NOTICE_IMAGE"}
                    subTitle={"SITE HAS BEEN MOVED\n TO THE ACTIVE SITES"}
                    onClose={() =>
                      this._closeUnarchivedModal(this.state.selectedItem)
                    }
                    buttonText={"CLOSE"}
                  />
                ) : null
              }
            />
            <Modal
              isVisible={this.state.showMapAddressEmpty}
              hideTitle={true}
              hideExitIcon={true}
              content={
                this.state.showMapAddressEmpty ? (
                  <ConfirmSuccess
                    title={"NOTICE"}
                    titleColor={"#EEB310"}
                    imageStyle={{ height: 104, width: 112 }}
                    imageName={"NOTICE_IMAGE"}
                    subTitle={
                      this.state.shareSiteEmpty
                        ? "YOU CAN NOT ACCESS TO\n A SHARE SITE WITHOUT \n AN ADDRESS"
                        : "YOU CAN NOT ACCESS TO\n A SITE MAP WITHOUT \n AN ADDRESS"
                    }
                    onClose={() =>
                      this.setState({
                        showMapAddressEmpty: false,
                        shareSiteEmpty: false,
                      })
                    }
                    buttonText={"CLOSE"}
                  />
                ) : null
              }
            />
            {this.sharesiteModal()}
            {this.sharesiteMailModal()}
            {this.shareSiteSuccessModal()}
            {this.editNotesModal()}
            <Modal
              isVisible={this.state.ArchiveDeleteModal}
              hideTitle={
                this.state.DeletePopup || this.state.ArchivePopup ? false : true
              }
              title={
                this.state.DeletePopup
                  ? "DELETE SITE"
                  : this.state.ArchivePopup
                  ? "ARCHIVE SITE"
                  : ""
              }
              hideExitIcon={
                this.state.ArchivePopup || this.state.DeletePopup ? true : false
              }
              onClose={() => this.setState({ ArchiveDeleteModal: false })}
              content={
                this.state.ArchivePopup ? (
                  <ArchivePopUp
                    onCancel={() => this._closeArchiveModal}
                    submitButtonText={"ARCHIVE"}
                    confirmationText={
                      "Would you like to archive the \n active job site?"
                    }
                    submit={() =>
                      this.archivedSiteitem(this.state.selectedItem)
                    }
                    cancelButtonText={"DO NOT ARCHIVE"}
                  />
                ) : this.state.DeletePopup ? (
                  <ArchivePopUp
                    onCancel={() => this._closeDeleteModal}
                    submitButtonText={"DELETE"}
                    confirmationText={
                      "Would you like to permanently delete \n the site from your active sites?"
                    }
                    submit={() => this.deleteSiteitem(this.state.selectedItem)}
                    cancelButtonText={"DO NOT DELETE"}
                  />
                ) : (
                  <ArchiveDeleteModal
                    confirmationText={"MORE ACTIONS"}
                    siteEditButtonText={"SITE INFO EDIT"}
                    docButtonText={"DOCUMENTS"}
                    deleteButtonText={"DELETE SITE"}
                    archiveButtonText={"ARCHIVE SITE"}
                    onArchive={() =>
                      this.pressArchivedSiteData(this.state.ArchiveDeleteObject)
                    }
                    onDelete={() =>
                      this.pressDeleteSiteData(this.state.ArchiveDeleteObject)
                    }
                  />
                )
              }
            />
            <ToastModal
              isVisible={this.state.ShowToastModal}
              hideTitle={true}
              animIn="slideInRight"
              animOut="slideOutRight"
              inTiming={1000}
              outTiming={1000}
              hideExitIcon={true}
              onClose={this._closeConfirmCreateModal}
              // content={this._siteCreateContent()}
              content={
                <ToastComponent
                  toastText={"JOB SITE ARCHIVED"}
                  imageStyle={{ height: 44, width: 44 }}
                  imageName={"SUCCESS_IMAGE"}
                />
              }
            />
            <ToastModal
              isVisible={this.state.ShowDeleteToastModal}
              hideTitle={true}
              animIn="slideInRight"
              animOut="slideOutRight"
              inTiming={1000}
              outTiming={1000}
              hideExitIcon={true}
              onClose={this._closeConfirmCreateModal}
              // content={this._siteCreateContent()}
              content={
                <ToastComponent
                  toastText={"JOB SITE DELETED"}
                  imageStyle={{ height: 44, width: 44 }}
                  imageName={"SUCCESS_IMAGE"}
                />
              }
            />
          </View>
        </View>
      );
    }
    //}
  }
}

const mapStateToProps = (state) => {
  return {
    isFetchingUserInfo: state.user.isFetchingUserInfo,
    userInformation: state.user.userInfo,
    getSitesData: state.siteDetails.sitedata,
    getOneSiteDate: state.siteDetails.onesitedata,
    getDeleteSiteRes: state.siteDetails.detelesitedata,
    getArchivedRes: state.siteDetails.archivesitedata,
    getunArchivedRes: state.siteDetails.unarchivesitedata,
    createSiteRes: state.siteDetails.createsitedata,
    getShareSiteRes: state.siteDetails.sharesitedata,
    getSiteEditRes: state.siteDetails.editsitedata,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchSiteDataFromStore: () => dispatch(fetchSiteData()),
    deleteSite: (siteId) => dispatch(deleteSiteItem(siteId)),
    archiveSite: (siteId, data) => dispatch(archivedSiteitem(siteId, data)),
    unarchiveSite: (siteId, data) => dispatch(unarchivedSiteitem(siteId, data)),
    orderedSite: (data) => dispatch(orderSiteitem(data)),
    createSitePost: (data) => dispatch(createSite(data)),
    clearState: () => dispatch(clearState()),
    editSiteDetails: (SiteId, data) => dispatch(editSite(SiteId, data)),
    showToast: (message, isError) => dispatch(showToast(message, isError)),
    shareSiterequest: (Sender, shareType, itmeValue, msg) =>
      dispatch(shareSite(Sender, shareType, itmeValue, msg)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteDrafts);
