import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  backButton: {
    width: 88.4,
    borderRadius: 5,
    height: 29,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#424242",
    Color: "white",
  },
  backButtonCont: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  backButtonImg: {
    width: 15.5,
    height: 13,
    marginRight: 4,
  },
  backButtonText: {
    textAlign: "center",
    color: "#FAFAFA",
    fontSize: 14,
    fontWeight: "bold",
  },
  smallButton: {
    width: 60,
    borderRadius: 5,
    height: 29,
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#424242",
    Color: "white",
  },
  smallButtonCont: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  smallButtonText: {
    textAlign: "center",
    color: "#FAFAFA",
    fontSize: 11,
    fontWeight: "bold",
  },
  editButton: {
    width: 115,
    borderRadius: 5,
    marginLeft: 10,
    height: 29,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#424242",
    Color: "white",
  },
  editButtonImg: {
    width: 16,
    height: 15,
    marginRight: 6,
  },
  editButtonText: {
    textAlign: "center",
    color: "white",
    fontSize: 12,
    fontWeight: "bold",
  },
});
