import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

import {
  Button,
  Text,
} from 'native-base';

import { styles as global, light_gray } from '_style/Global.style';
import { styles } from '_components/accounts/ViewButton.style';

class ViewButton extends Component {
  constructor(props) {
    super(props);

    this._onClick = this._onClick.bind(this);
  }

  /**
   *
   */
  _onClick() {
    const { onClick, data } = this.props;

    onClick(data);
  }

  render() {
    const {
      label,
      width = 200,
      height = 35,
      fontSize = 18,
    } = this.props;

    return (
      <Button style={[styles.actionButton, global.dropShadow, { width, height }]} onPress={this._onClick}>
        <Text style={[styles.actionButtonText, { fontSize }]}>
          { label }
        </Text>
      </Button>
    );
  }
}

ViewButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  data: PropTypes.any.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  fontSize: PropTypes.number,
};

export default connect(null, null)(ViewButton);
