import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  mainContainer: {
    height: '100%',
  },
  speedControlContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
  },
  speedArrowButton: {
    height: 74,
    width: 74,
    marginTop: 10,
    marginBottom: 10,
  },
  sliderThumb: {
    width: 48,
    height: 62,
  },
});
