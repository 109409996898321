import { StyleSheet } from 'react-native';
import { light_gray, yellow } from '_style/Global.style';

const jobsListItemText = {
  fontSize: 21,
  letterSpacing: 1.6,
  textTransform: 'uppercase',
};

export const styles = StyleSheet.create({
  jobListItemButton: {
    padding: 10,
  },
  jobListItemTextUnselected: {
    ...jobsListItemText,
    color: light_gray,

  },
  jobListItemTextSelected: {
    ...jobsListItemText,
    color: yellow,
  },
  jobListItemRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  jobListItemRowMarker: {
    width: 20,
    height: 20,
  },
});
