// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Image, ImageBackground, TouchableOpacity } from 'react-native';

import {
  Text,
  View,
} from 'native-base';

// Redux
import { connect } from 'react-redux';

// Native Base

import { styles } from '_components/device_card/CloseOpenGroup.style';

class CloseOpenGroup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      openPressed: false,
      closedPressed: false,
    };

    this.openDelayTimer = null;
    this.closeDelayTimer = null;

    this._closeOn = this._closeOn.bind(this);
    this._openOn = this._openOn.bind(this);

    this._openPressOff = this._openPressOff.bind(this);
    this._closedPressOff = this._closedPressOff.bind(this);

    this._startOpenDelayTimer = this._startOpenDelayTimer.bind(this);
    this._endOpenDelayTimer = this._endOpenDelayTimer.bind(this);
    this._startCloseDelayTimer = this._startCloseDelayTimer.bind(this);
    this.endCloseDelayTimer = this._endCloseDelayTimer.bind(this);
  }

  /**
   * If there is an open delay set, start a timer that
   * will turn off the open intermediate state after a set
   * amount of time if the telemetry doesn't indicate the
   * actual open state first.
   */
  _startOpenDelayTimer() {
    const { openDelay } = this.props;

    this._endCloseDelayTimer();

    this.openDelayTimer = setTimeout(() => {
      this.setState({
        openPressed: false,
      });
    }, openDelay * 1000); // open delay is in seconds
  }
  _endOpenDelayTimer() {
    clearTimeout(this.openDelayTimer);
  }

  /**
   * If there is a close delay set, start a timer that
   * will turn off the closed intermediate state after a set
   * amount of time if the telemetry doesn't indicate the
   * actual closed state first.
   */
  _startCloseDelayTimer() {
    const { closeDelay } = this.props;

    this._endOpenDelayTimer();

    if (closeDelay) {
      this.closeDelayTimer = setTimeout(() => {
        this.setState({
          closedPressed: false,
        });
      }, closeDelay * 1000); // close delay is in seconds
    }
  }
  _endCloseDelayTimer() {
    clearTimeout(this.closeDelayTimer);
  }

  /**
   * Handler for when the closed button is pressed.
   */
  _closeOn() {
    const { onClosed } = this.props;

    // Put the closed button into the intermediate pressed state.
    this.setState({
      openPressed: false,
      closedPressed: true,
    });

    this._startCloseDelayTimer();

    onClosed('closed');
  }

  /**
   * Handler for when the open button is pressed.
   */
  _openOn() {
    const { onOpen } = this.props;

    // Put the open button into the intermediate pressed state.
    this.setState({
      openPressed: true,
      closedPressed: false,
    });

    this._startOpenDelayTimer();

    onOpen('open');
  }

  /**
   * Handler for when the closed button is pressed while in the
   * on state.
   */
  _closedPressOff() {
    this.setState({
      closedPressed: false,
    });
  }

  /**
   * Handler for when the closed button is pressed while in the
   * on state.
   */
  _openPressOff() {
    this.setState({
      openPressed: false,
    });
  }

  /**
   * If the components properties are updating, we need to check to see
   * if the telemetry is indicating either that the open button was previously
   * pressed and is now actually open, or if the closed button was
   * previously pressed and is now actually closed. In each case, clear any delay
   * timers and update the button state appropriately.
   *
   * @param {*} prevProps component's previously known properties.
   */
  componentDidUpdate(prevProps) {
    const { open: openPrev } = prevProps;
    const { open } = this.props;

    if (!openPrev && open) {
      this._endOpenDelayTimer();
      this._openPressOff();
    }
    if (openPrev && !open) {
      this._endCloseDelayTimer();
      this._closedPressOff();
    }
  }

  render() {
    const { open } = this.props;
    const { openPressed, closedPressed } = this.state;

    let openButton;
    let closedButton;

    // Open Button
    if (open && !openPressed) {
      openButton = (
        <TouchableOpacity onPress={this._openOn}>
          <ImageBackground
            style={ [styles.openClosedButton] }
            source={require('_assets/images/open-closed-button-open-on.png')}
          >
            <Text style={[styles.openClosedButtonText, styles.openClosedButtonOnText]}>OPEN</Text>
          </ImageBackground>
        </TouchableOpacity>
      );
    } else if (openPressed) {
      openButton = (
        <TouchableOpacity onPress={this._openPressOff}>
          <ImageBackground
            style={ [styles.openClosedButton] }
            source={require('_assets/images/open-closed-button-pressed.png')}
          >
            <Text style={[styles.openClosedButtonText, styles.openClosedButtonPressedText]}>OPEN</Text>
          </ImageBackground>
        </TouchableOpacity>
      );
    } else {
      openButton = (
        <TouchableOpacity onPress={this._openOn}>
          <ImageBackground
            style={ [styles.openClosedButton] }
            source={require('_assets/images/open-closed-button-off.png')}
          >
            <Text style={[styles.openClosedButtonText, styles.openClosedButtonOffText]}>OPEN</Text>
          </ImageBackground>
        </TouchableOpacity>
      );
    }

    // Close Button
    if (!open && !closedPressed) {
      closedButton = (
        <TouchableOpacity onPress={this._closeOn}>
          <ImageBackground
            style={ [styles.openClosedButton] }
            source={require('_assets/images/open-closed-button-closed-on.png')}
          >
            <Text style={[styles.openClosedButtonText, styles.openClosedButtonOnText]}>CLOSED</Text>
          </ImageBackground>
        </TouchableOpacity>
      );
    } else if (closedPressed) {
      closedButton = (
        <TouchableOpacity onPress={this._closedPressOff}>
          <ImageBackground
            style={ [styles.openClosedButton] }
            source={require('_assets/images/open-closed-button-pressed.png')}
          >
            <Text style={[styles.openClosedButtonText, styles.openClosedButtonPressedText]}>CLOSED</Text>
          </ImageBackground>
        </TouchableOpacity>
      );
    } else {
      closedButton = (
        <TouchableOpacity onPress={this._closeOn}>
          <ImageBackground
            style={ [styles.openClosedButton] }
            source={require('_assets/images/open-closed-button-off.png')}
          >
            <Text style={[styles.openClosedButtonText, styles.openClosedButtonOffText]}>CLOSED</Text>
          </ImageBackground>
        </TouchableOpacity>
      );
    }

    return (

      <View style={[styles.closeOpenGroupContainer]}>
        { closedButton }
        { openButton }
      </View>

    );
  }
}

CloseOpenGroup.propTypes = {
  onOpen: PropTypes.func.isRequired,
  onClosed: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  closed: PropTypes.bool.isRequired,

  openDelay: PropTypes.number,
  closeDelay: PropTypes.number,
};

export default connect(null, null)(CloseOpenGroup);
