import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  editIcon: {
    width: 28,
    height: 29,
    marginRight:0, 
    marginLeft:0,  
  },
});
