import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  home: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    backgroundColor: '#383838',
    overflow: 'auto',
    padding: 25,
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    height: '100%',
    width: '100%',
  },
  navigationContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    margin: 20,
  },
  navItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: '#1A1A1A',
    borderRadius: 10,
    height: 80,
    marginBottom: 20,
  },
  navItemIcon: {
    width: 50,
    marginLeft: 50,
  },
  navItemText: {
    color: '#FAFAFA',
    fontSize: 36,
    marginLeft: 15,
    marginRight: 15,
  },
  notificationsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor: '#1A1A1A',
    margin: 20,
    borderRadius: 10,
  },
  footerContainer: {

  },
});
