// React
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";

// UI Framework
import { Button, Text, View, Input } from "native-base";

// Style
import { styles as global } from "_style/Global.style";
import { styles } from "./EditDoc.style";

class EditDoc extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      labelText,
      submitButtonText,
      cancelButtonText,
      inputValue,
      inputPlace,
      onChange,
    } = this.props;

    return (
      <View style={[styles.confirmRemovalModalContent]}>
        <View style={[styles.mainContent]}>
          <Text style={[styles.confirmRemovalText]}>{labelText}</Text>
          <Input
            style={[styles.inputStyle]}
            placeholder={inputPlace}
            value={inputValue}
            onChangeText={onChange}
          />
        </View>
        <Button
          style={[global.confirmationButton, styles.btnStyle]}
          onPress={() => this.props.submit()}
        >
          <Text style={[global.confirmationButtonText,{fontSize:18}]}>
            {submitButtonText}
          </Text>
        </Button>
        <Button
          style={[global.cancelButton, styles.btnStyle]}
          onPress={this.props.onCancel()}
        >
          <Text style={[global.cancelButtonText,{fontSize:18,color:"#FAFAFA"}]}>{cancelButtonText}</Text>
        </Button>
      </View>
    );
  }
}

EditDoc.propTypes = {
  onChange: PropTypes.func,
  inputValue: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string,
  labelText: PropTypes.string,
  inputPlace: PropTypes.string,
  cancelButtonText: PropTypes.string,
};

export default connect(null, null)(EditDoc);
