import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

import { Image, TouchableOpacity } from 'react-native';

import { styles } from '_components/common/ArrowButton.style';

/**

 */
class ArrowButton extends Component {
  constructor(props) {
    super(props);

    this._onClick = this._onClick.bind(this);
  }

  _onClick() {
    const { onClick, data } = this.props;

    onClick(data);
  }

  render() {
    const {
      up
    } = this.props;

    return (
      <TouchableOpacity onPress={this._onClick}>
        {
          up ?
            <Image style={[styles.arrowIcon]} source={require('_assets/images/up-arrow.png')} />
            :
            <Image style={[styles.arrowIcon]} source={require('_assets/images/down-arrow.png')} />
        }
      </TouchableOpacity>
    );
  }
}

ArrowButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  data: PropTypes.any.isRequired,
  up: PropTypes.bool.isRequired,
};

export default connect(null, null)(ArrowButton);
