import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";

import { Image, TouchableOpacity, StyleSheet } from "react-native";

/**
 * TRASH ICON
 *
 * Common trash icon button for each notification, allows the user
 * to delete the notification.
 */
class EditButton extends Component {
  constructor(props) {
    super(props);

    this._onClick = this._onClick.bind(this);
  }

  _onClick() {
    const { onClick, data } = this.props;

    onClick(data);
  }

  render() {
    return (
      <TouchableOpacity onPress={this._onClick} style={[styles.editBtn]}>
        <Image
          style={[styles.editIcon]}
          source={require("_assets/images/option-icon.svg")}
        />
      </TouchableOpacity>
    );
  }
}
const styles = StyleSheet.create({
  editBtn: {
    width: 34,
    height: 34,
    marginRight: 1,
  },
  editIcon: {
    width: 30.3,
    height: 6.4,
    marginTop: 12,
    marginRight: 1,
  },
});
EditButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  data: PropTypes.any,
};

export default connect(null, null)(EditButton);
