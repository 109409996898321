import { createReducer } from '_redux/rootActions';

// Types
import {
  CLEAR_STATE,
  SHOW_TOAST,
} from '_redux/toasts/types';

const initialState = {
  id: 0,
  message: '',
  isError: false,
  openDelay: 0,
};

export default createReducer(initialState, {
  // Clear state.
  [CLEAR_STATE]: () => initialState,

  // Show Toast
  [SHOW_TOAST]: (state, action) => {
    const { message, isError, openDelay = 0 } = action;

    return {...state, id: state.id + 1, message, isError, openDelay};
  },
});
