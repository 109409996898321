const actionPrefix = "app/site";

export const CLEAR_STATE = `${actionPrefix}/CLEAR_STATE`;
export const INIT_SITEMAP = `${actionPrefix}/INIT_SITEMAP`;

export const SITES_GET_REQUEST = `${actionPrefix}/SITES_GET_REQUEST`;
export const SITES_GET_SUCCESS = `${actionPrefix}/SITES_GET_SUCCESS`;
export const SITES_GET_FAILURE = `${actionPrefix}/SITES_GET_FAILURE`;

export const SITE_GET_REQUEST = `${actionPrefix}/SITE_GET_REQUEST`;
export const SITE_GET_SUCCESS = `${actionPrefix}/SITE_GET_SUCCESS`;
export const SITE_GET_FAILURE = `${actionPrefix}/SITE_GET_FAILURE`;

export const SITE_DELETE_REQUEST = `${actionPrefix}/SITE_DELETE_REQUEST`;
export const SITE_DELETE_SUCCESS = `${actionPrefix}/SITE_DELETE_SUCCESS`;
export const SITE_DELETE_FAILURE = `${actionPrefix}/SITE_DELETE_FAILURE`;

export const SITE_ARCHIVE_REQUEST = `${actionPrefix}/SITE_ARCHIVE_REQUEST`;
export const SITE_ARCHIVE_SUCCESS = `${actionPrefix}/SITE_ARCHIVE_SUCCESS`;
export const SITE_ARCHIVE_FAILURE = `${actionPrefix}/SITE_ARCHIVE_FAILURE`;

export const SITE_UNARCHIVE_REQUEST = `${actionPrefix}/SITE_UNARCHIVE_REQUEST`;
export const SITE_UNARCHIVE_SUCCESS = `${actionPrefix}/SITE_UNARCHIVE_SUCCESS`;
export const SITE_UNARCHIVE_FAILURE = `${actionPrefix}/SITE_UNARCHIVE_FAILURE`;

export const CREATE_SITE_REQUEST = `${actionPrefix}/CREATE_SITE_REQUEST`;
export const CREATE_SITE_SUCCESS = `${actionPrefix}/CREATE_SITE_SUCCESS`;
export const CREATE_SITE_FAILURE = `${actionPrefix}/CREATE_SITE_FAILURE`;

export const EDIT_SITE_REQUEST = `${actionPrefix}/EDIT_SITE_REQUEST`;
export const EDIT_SITE_SUCCESS = `${actionPrefix}/EDIT_SITE_SUCCESS`;
export const EDIT_SITE_FAILURE = `${actionPrefix}/EDIT_SITE_FAILURE`;

export const SITE_SAVE_REQUEST = `${actionPrefix}/SITE_SAVE_REQUEST`;
export const SITE_SAVE_SUCCESS = `${actionPrefix}/SITE_SAVE_SUCCESS`;
export const SITE_SAVE_FAILURE = `${actionPrefix}/SITE_SAVE_FAILURE`;

export const SITE_REQUEST = `${actionPrefix}/SITE_REQUEST`;
export const SITE_SUCCESS = `${actionPrefix}/SITE_SUCCESS`;
export const SITE_FAILEAR = `${actionPrefix}/SITE_FAILEAR`;

export const SHARE_SITE_REQUEST = `${actionPrefix}/SHARE_SITE_REQUEST`;
export const SHARE_SITE_SUCCESS = `${actionPrefix}/SHARE_SITE_SUCCESS`;
export const SHARE_SITE_FAILURE = `${actionPrefix}/SHARE_SITE_FAILURE`;

export const SITE_SUB_DELETE_REQUEST = `${actionPrefix}/SITE_SUB_DELETE_REQUEST`;
export const SITE_SUB_DELETE_SUCCESS = `${actionPrefix}/SITE_SUB_DELETE_SUCCESS`;
export const SITE_SUB_DELETE_FAILURE = `${actionPrefix}/SITE_SUB_DELETE_FAILURE`;

export const SITE_ORDER_REQUEST = `${actionPrefix}/SITE_ORDER_REQUEST`;
export const SITE_ORDER_SUCCESS = `${actionPrefix}/SITE_ORDER_SUCCESS`;
export const SITE_ORDER_FAILURE = `${actionPrefix}/SITE_ORDER_FAILURE`;
