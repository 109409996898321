import { API } from 'aws-amplify';
import {
  DEVICES_API_ID,
  JOBS_API_ID,
  USERS_API_ID,
} from '_config/api-constants';
import { makeActionCreator } from '_redux/rootActions';

import { getUserCognitoSub } from '_redux/user/utils';

import {
  CLEAR_STATE,

  GET_DASHBOARD_DEVICES_REQUEST,
  GET_DASHBOARD_DEVICES_SUCCESS,
  GET_DASHBOARD_DEVICES_FAILURE,

  GET_USER_DEVICE_CARD_SETTINGS_REQUEST,
  GET_USER_DEVICE_CARD_SETTINGS_SUCCESS,
  GET_USER_DEVICE_CARD_SETTINGS_FAILURE,

  UPDATE_USER_DEVICE_CARD_SETTINGS,

  UPDATE_LAST_VIEWED_JOB,
} from '_redux/dashboard/types';

export const clearState = makeActionCreator(CLEAR_STATE);

/**
 *
 * @param {*} jobId
 * @param {*} deviceIds
 * @param {*} cognitoId
 */
export const getDashboardDevices = (jobId, deviceIds, cognitoId) => {
  return async (dispatch) => {
    dispatch(getDashboardDevicesRequest());

    try {
      let response = [];

      // Get the owner's devices
      if (!jobId && !deviceIds) {
        response = await API.get(USERS_API_ID, `/users/${encodeURIComponent(cognitoId)}/devices?preferDefaultJob=true`);

      // Get the job devices
      } else if (jobId) {
        response = await API.get(JOBS_API_ID, `/jobs/${encodeURIComponent(jobId)}/devices`);

      // Get the specified list of devices.
      } else if (deviceIds) {
        const deviceList = deviceIds.join(',');

        response = await API.get(DEVICES_API_ID, `/devices/${encodeURIComponent(deviceList)}`);
      }

      // Update Last Viewed Job
      dispatch(updateLastViewedJob(response.job));

      const devices = response.devices || response || [];
      devices.forEach((device) => {
        dispatch(getUserDeviceCardSettings(device.device_id));
      });
      dispatch(getDashboardDevicesSuccess(devices));

    } catch (e) {
      console.error(e);
      dispatch(getDashboardDevicesFailure());
    }
  };
};
export const getDashboardDevicesRequest = makeActionCreator(GET_DASHBOARD_DEVICES_REQUEST);
export const getDashboardDevicesSuccess = makeActionCreator(GET_DASHBOARD_DEVICES_SUCCESS, 'devices');
export const getDashboardDevicesFailure = makeActionCreator(GET_DASHBOARD_DEVICES_FAILURE);
export const updateLastViewedJob = makeActionCreator(UPDATE_LAST_VIEWED_JOB, 'job');

/**
 * Get the device card settings for this user given the device id.
 *
 * @param {string} deviceId device id
 */
export const getUserDeviceCardSettings = (deviceId) => {
  return async (dispatch) => {

    dispatch(getUserDeviceCardSettingsRequest(deviceId));

    try {
      const cognitoSub = await getUserCognitoSub();

      const endpoint = `/users/${encodeURIComponent(cognitoSub)}/devices/${encodeURIComponent(deviceId)}/devicecardsettings`;

      const settings = await API.get(USERS_API_ID, endpoint, {});

      dispatch(getUserDeviceCardSettingsSuccess(deviceId, settings));

    } catch (e) {
      console.log(e);
      dispatch(getUserDeviceCardSettingsFailure(deviceId));
    }
  };
};
export const getUserDeviceCardSettingsRequest = makeActionCreator(GET_USER_DEVICE_CARD_SETTINGS_REQUEST, 'deviceId');
export const getUserDeviceCardSettingsSuccess = makeActionCreator(GET_USER_DEVICE_CARD_SETTINGS_SUCCESS, 'deviceId', 'deviceCardSettings');
export const getUserDeviceCardSettingsFailure = makeActionCreator(GET_USER_DEVICE_CARD_SETTINGS_FAILURE, 'deviceId');

export const updateUserDeviceCardSettings = makeActionCreator(UPDATE_USER_DEVICE_CARD_SETTINGS, 'deviceId', 'settings');
