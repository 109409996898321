// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Native Base Components
import {
  Button,
  Icon,
  Text,
  View,
} from 'native-base';
import {
  TouchableOpacity,
} from 'react-native';

// Style
import { styles } from '_components/common/Pagination.style';

class Pagination extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentPage: 0,
    };

    this._back = this._back.bind(this);
    this._forward = this._forward.bind(this);
    this._setPage = this._setPage.bind(this);

  }

  _back() {
    const {
      currentPage
    } = this.state;

    this.props.onNav(currentPage - 1);
    this.setState({
      currentPage: currentPage - 1,
    });
  }

  _forward() {
    const {
      currentPage
    } = this.state;

    this.props.onNav(currentPage + 1);
    this.setState({
      currentPage: currentPage + 1,
    });
  }

  _setPage(pageNumber) {
    this.props.onNav(pageNumber);
    this.setState({
      currentPage: pageNumber,
    });
  }

  render() {

    const {
      total,
      pageSize,
    } = this.props;

    const {
      currentPage,
    } = this.state;

    const pageCount = Math.ceil(total / pageSize);
    const numbers = [];
    for (let i = 1; i <= pageCount; i++) {
      const active = currentPage === i - 1;
      numbers.push((
        <TouchableOpacity key={i} disabled={active} onPress={() => this._setPage(i - 1)}>
          <Text style={[active ? styles.activeNavNumber : styles.navNumber]}>
            { i }
          </Text>
        </TouchableOpacity>
      ));
    }

    const backDisabled = currentPage === 0;
    const forwardDisabled = currentPage + 1 >= pageCount;
    const backStyle = backDisabled ? styles.disabledNavArrow : styles.enabledNavArrow;
    const forwardStyle = forwardDisabled ? styles.disabledNavArrow : styles.enabledNavArrow;

    return (
      <View style={[styles.main]}>
        <Button transparent onPress={this._back} disabled={backDisabled}>
          <Icon style={[backStyle]} name='ios-arrow-back' />
        </Button>
        { numbers }
        <Button transparent onPress={this._forward} disabled={forwardDisabled}>
          <Icon style={[forwardStyle]} name='ios-arrow-forward' />
        </Button>
      </View>
    );
  }
}

Pagination.propTypes = {
  total: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,

  onNav: PropTypes.func.isRequired,
};

export default connect(null, null)(Pagination);


