import { StyleSheet } from 'react-native';
import {
  darker_gray,
  roboto,
  textLight,
  yellow,
} from '_style/Global.style';

export const styles = StyleSheet.create({
  breadCrumbRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
    flexWrap: 'wrap',
    alignSelf: 'stretch',
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 25,
    paddingRight: 25,
  },
  card: {
    backgroundColor: darker_gray,
    borderRadius: 10,
  },
  button: {
    backgroundColor: "transparent",
    height: 25,
    width: 170,
  },
  buttonBack: {
    backgroundColor: yellow,
    height: 25,
    width: 90,
  },
  buttonBackText: {
    fontFamily: roboto,
    fontSize: 14,
    fontWeight: 'bold',
    color: 'black',
    letterSpacing: 1.6,
    paddingLeft: 0,
    paddingRight:0 ,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  buttonText: {
    fontFamily: roboto,
    fontSize: 14,
    fontWeight: 'bold',
    color: textLight,
    letterSpacing: 1.6,
    paddingLeft: 0,
    paddingRight:0 ,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  buttonTextHighlight: {
    fontFamily: roboto,
    fontSize: 14,
    fontWeight: 'bold',
    color: yellow,
    letterSpacing: 1.6,
    paddingLeft: 0,
    paddingRight:0 ,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  crumb: {
    paddingLeft: 0,
    paddingRight: 0,
  },
});
