import { createReducer } from "_redux/rootActions";

// Types
import {
  CLEAR_STATE,
  DOCS_GET_REQUEST,
  DOCS_GET_SUCCESS,
  DOCS_GET_FAILURE,
  EDIT_DOCS_REQUEST,
  EDIT_DOCS_SUCCESS,
  EDIT_DOCS_FAILURE,
  DELETE_DOCS_REQUEST,
  DELETE_DOCS_SUCCESS,
  DELETE_DOCS_FAILURE,
  LOCK_DOCS_REQUEST,
  LOCK_DOCS_SUCCESS,
  LOCK_DOCS_FAILURE,  
  UPLOAD_DOCS_REQUEST,
  UPLOAD_DOCS_SUCCESS,
  UPLOAD_DOCS_FAILURE,
} from '_redux/siteDocuments/types';

const initialState = {
  isDocsGetRequest: false,
  getDocsRequestSuccess: false,
  isDocsEditRequest: false,
  editDocsRequestSuccess: false,
  isDocsLockRequest: false,
  lockDocsRequestSuccess: false,
  isDocsDeleteRequest: false,
  deleteDocsRequestSuccess: false,  
  isDocsUploadRequest: true,
  uploadDocsRequestSuccess: false
};

export default createReducer(initialState, {
  // Clear state.
  [CLEAR_STATE]: () => initialState,

  // get Sites Documents Details.
  [DOCS_GET_REQUEST]: (state) => {
    return {...state, isDocsGetRequest: true, getDocsRequestSuccess: false };
  },
  [DOCS_GET_SUCCESS]: (state , action) => {
    const { sitedocdata } = action;
    return {...state, isDocsGetRequest: false, getDocsRequestSuccess: true ,sitedocdata };
  },
  [DOCS_GET_FAILURE]: (state) => {
    return {...state, isDocsGetRequest: false, getDocsRequestSuccess: false };
  },

  // edit Sites Documents Details.
  [EDIT_DOCS_REQUEST]: (state) => {
    return {...state, isDocsEditRequest: true, editDocsRequestSuccess: false };
  },
  [EDIT_DOCS_SUCCESS]: (state , action) => {
    const { sitedoceditdata } = action;
    return {...state, isDocsEditRequest: false, editDocsRequestSuccess: true ,sitedoceditdata };
  },
  [EDIT_DOCS_FAILURE]: (state) => {
    return {...state, isDocsEditRequest: false, editDocsRequestSuccess: false };
  },

  // Lock Sites Documents.
  [LOCK_DOCS_REQUEST]: (state) => {
    return {...state, isDocsLockRequest: true, lockDocsRequestSuccess: false };
  },
  [LOCK_DOCS_SUCCESS]: (state , action) => {
    const { sitedoclockdata } = action;
    return {...state, isDocsLockRequest: false, lockDocsRequestSuccess: true ,sitedoclockdata };
  },
  [LOCK_DOCS_FAILURE]: (state) => {
    return {...state, isDocsLockRequest: false, lockDocsRequestSuccess: false };
  },

   // Delete Sites Documents .
   [DELETE_DOCS_REQUEST]: (state) => {
    return {...state, isDocsDeleteRequest: true, deleteDocsRequestSuccess: false };
  },
  [DELETE_DOCS_SUCCESS]: (state , action) => {
    const { sitedocdeletedata } = action;
    return {...state, isDocsDeleteRequest: false, deleteDocsRequestSuccess: true ,sitedocdeletedata };
  },
  [DELETE_DOCS_FAILURE]: (state) => {
    return {...state, isDocsDeleteRequest: false, deleteDocsRequestSuccess: false };
  },

  // Upload Sites Documents .
  [UPLOAD_DOCS_REQUEST]: (state) => {
    return {...state, isDocsUploadRequest: true, uploadDocsRequestSuccess: false };
  },
  [UPLOAD_DOCS_SUCCESS]: (state , action) => {
    const { sitedocuploaddata } = action;
    return {...state, isDocsUploadRequest: false, uploadDocsRequestSuccess: true ,sitedocuploaddata };
  },
  [UPLOAD_DOCS_FAILURE]: (state) => {
    return {...state, isDocsUploadRequest: false, uploadDocsRequestSuccess: false };
  },

});
