import { StyleSheet } from 'react-native';
import {
  yellow,
  textDark,
  robotoCondensed,
} from '_style/Global.style';

export const styles = StyleSheet.create({
  vitalsContainer: {
    flexDirection: 'column',
    //justifyContent: 'space-between',
    width: "100%",
    marginTop: 1,
    marginHorizontal: 2,
  },
  vitalsRow: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: '#424242',
    paddingTop: 1,
    paddingBottom: 1,
  },
  vitalsLabel: {
    //width: '30%',
    fontFamily: robotoCondensed,
    fontSize: 13,
  }
});
