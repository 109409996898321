// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { getSetPointsForDevice } from '_redux/iot/selectors';
import { getUserDeviceCardSettingsById } from '_redux/dashboard/selectors';
import { executeCommand } from '_redux/iot/actions';
import { SUPPORTED_COMMANDS } from '_redux/iot/utils';

// Util
import { debounce } from '_util';

// Components
import CloseOpenGroup from '_components/device_card/CloseOpenGroup.component';
import OnOffGroup from '_components/device_card/OnOffGroup.component';

// UI Framework
import { Image, TouchableOpacity } from 'react-native';
import {
  Text,
  View,
} from 'native-base';

// Styling
import { styles } from '_components/device_card/Valves.style';

// Default Valve Settings
import { defaultValveSettings } from '_components/device_settings/ValveSettings.component';

/**
 * Manages each individual valve.
 */

class Valve extends Component {
  constructor(props) {
    super(props);

    this._onOpen = debounce(this._onOpen.bind(this), 500, true);
    this._onClose = debounce(this._onClose.bind(this), 500, true);
  }

  _onOpen() {
    const { deviceId, executeCommand, valveId } = this.props;

    executeCommand(
      deviceId,
      SUPPORTED_COMMANDS.HYDRAULIC_VALVE_OPEN,
      {
        data: {
          valve: valveId,
        }
      }
    );
  }

  _onClose() {
    const { deviceId, executeCommand, valveId } = this.props;

    executeCommand(
      deviceId,
      SUPPORTED_COMMANDS.HYDRAULIC_VALVE_CLOSE,
      {
        data: {
          valve: valveId,
        }
      }
    );
  }

  render() {

    const { valveId, label, isOpen, isClosed, openDelay, closeDelay } = this.props;


    return (
      <View key={ valveId } style={[styles.valvesRow]}>
        <Text style={[styles.valveLabel]}>{ label }</Text>
        <CloseOpenGroup
          onOpen={this._onOpen}
          onClosed={this._onClose}
          open={isOpen}
          closed={isClosed}
          openDelay={openDelay}
          closeDelay={closeDelay}
        />
      </View>
    );
  }
}

Valve.propTypes = {
  deviceId: PropTypes.any.isRequired,
  executeCommand: PropTypes.func.isRequired,
  valveId: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isClosed: PropTypes.bool.isRequired,

  openDelay: PropTypes.number,
  closeDelay: PropTypes.number,
};

  /**
   * Manages Auxilary.
  */

 class Auxilary extends Component {
  constructor(props) {
    super(props);

    this._onOn = debounce(this._onOn.bind(this), 500, true);
    this._onOff = debounce(this._onOff.bind(this), 500, true);
  }

  _onOn() {
    const { deviceId, executeCommand, valveId } = this.props;

    executeCommand(
      deviceId,
      SUPPORTED_COMMANDS.HYDRAULIC_VALVE_OPEN,
      {
        data: {
          valve: valveId,
        }
      }
    );
  }

  _onOff() {
    const { deviceId, executeCommand, valveId } = this.props;

    executeCommand(
      deviceId,
      SUPPORTED_COMMANDS.HYDRAULIC_VALVE_CLOSE,
      {
        data: {
          valve: valveId,
        }
      }
    );
  }

  render() {
    const { valveId, label, isOpen, isClosed } = this.props;

    return (
      <View key={ valveId } style={[styles.valvesRow]}>
        <Text style={[styles.valveLabel]}>{ label }</Text>
        <OnOffGroup
          onOn={this._onOn}
          onOff={this._onOff}
          on={isOpen}
          off={isClosed}
        />
      </View>
    );
  }
}

Valve.propTypes = {
  deviceId: PropTypes.any.isRequired,
  executeCommand: PropTypes.func.isRequired,
  valveId: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isClosed: PropTypes.bool.isRequired,

  openDelay: PropTypes.number,
  closeDelay: PropTypes.number,
};

/**
 * Manages the group of Valves.
 */

class Valves extends Component {

  constructor(props) {
    super(props);
  }

  render() {

    // added deviceSettings
    const { deviceId, executeCommand, hvs, setPoints, deviceSettings } = this.props;
    const {
      i1, // VALVE 1 Open Delay
      i2, // VALVE 1 Close Delay
      i3, // VALVE 2 Open Delay
      i4, // VALVE 2 Close Delay
      i5, // VALVE 3 Open Delay
      i6, // VALVE 3 Close Delay
      i7, // VALVE 4 Open Delay
      i8, // VALVE 4 Close Delay
      i9, // VALVE 5 Open Delay
      i10,// VALVE 5 Close Delay
      i11,// VALVE 1 Label
      i12,// VALVE 2 Label
      i13,// VALVE 3 Label
      i14,// VALVE 4 Label
      i15,// VALVE 5 Label
      i86,// AUX Label
      i87,
      i88,
      i89,
      i90,
      i91,
      i92,
    } = setPoints.I || {};

    // in case deviceSettings is null
    const { valvesSettings = defaultValveSettings } = deviceSettings;

    const auxilaryOn = (hvs & 8192) === 8192;
    const auxilaryShow = valvesSettings.aux.show;
    
    const valveLabels = [
      'INLET',
      'OUTLET',
      'PIG', 
      'BYPASS',
      'VACUUM',
      'CLUTCH',
      'AUX',
    ];

    const auxLabels = [
      'PUMP',
      'LIGHTS',
    ];

    // changed label and show values to respond to device settings instead of I values - JC Lopez 
    const valves = [
      { id: 1, label: valvesSettings.valve1.label, openValue: 8, closeValue: 4, show: valvesSettings.valve1.show, openDelay: i1, closeDelay: i2 },
      { id: 2, label: valvesSettings.valve2.label, openValue: 32, closeValue: 16, show: valvesSettings.valve2.show, openDelay: i3, closeDelay: i4 },
      { id: 3, label: valvesSettings.valve3.label, openValue: 128, closeValue: 64, show: valvesSettings.valve3.show, openDelay: i5, closeDelay: i6 },
      { id: 4, label: valvesSettings.valve4.label, openValue: 512, closeValue: 256, show: valvesSettings.valve4.show, openDelay: i7, closeDelay: i8 },
      { id: 5, label: valvesSettings.valve5.label, openValue: 2048, closeValue: 1024, show: valvesSettings.valve5.show, openDelay: i9, closeDelay: i10 },
    ];

    const valveComponents = [];

    valves.forEach((valve) => {
      if (valve.show) {
        valveComponents.push((
          <Valve
            key={valve.id}
            valveId={valve.id}
            deviceId={deviceId}
            executeCommand={executeCommand}
            label={valve.label}
            isOpen={(hvs & valve.openValue) === valve.openValue}
            isClosed={(hvs & valve.closeValue) === valve.closeValue}
            openDelay={valve.openDelay}
            closeDelay={valve.closeDelay}
          />
        ));
      }
    });

    if (auxilaryShow) {
      valveComponents.push((
        <Auxilary
          key={6}
          valveId={6}
          deviceId={deviceId}
          executeCommand={executeCommand}
          label={valvesSettings.aux.label}
          isOpen={auxilaryOn}
          isClosed={!auxilaryOn}
        />
      ));
    }

    return (
      <View style={[styles.valvesContainer]}>
        { valveComponents }
      </View>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    setPoints: getSetPointsForDevice(state, ownProps.deviceId),
    // added to get device settings - JC Lopez
    deviceSettings: getUserDeviceCardSettingsById(state, ownProps.deviceId),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    executeCommand: (deviceId, command, data) => dispatch(executeCommand(deviceId, command, data)),
  };
};


Valves.propTypes = {
  deviceId: PropTypes.string.isRequired,
  executeCommand: PropTypes.func.isRequired,
  hvs: PropTypes.any.isRequired,
  setPoints: PropTypes.any.isRequired,
  deviceSettings: PropTypes.any.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Valves);