import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  card: {
    maxWidth: 650,
    width: '100%',
  },
  headerImage: {
    height: 55,
    maxWidth: 388,
    width: '100%',
    marginBottom: 15,
    marginTop: 25,
  },
  paragraph: {
    fontSize: 20,
    marginTop: 25,
    marginBottom: 25,
  },
  subtitle: {
    fontSize: 26,
    fontWeight: 'bold',
  },
  title: {
    fontSize: 80,
    fontWeight: 'bold',
  },
  formContainer: {
    width: '100%',
    maxWidth: 400,
  },
  signUpButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  signUpButtonNewContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  signInButton: {
    borderRadius: 15,
    height: 50,
    maxWidth: 400,
    width: '100%',
    marginTop: 10,
    marginBottom: 40,
  },
  signInButtonText: {
    fontSize: 24,
  },
  troubleText: {
    fontSize: 16,
    height: '100%',
  },
  dontHaveAnAccount: {
    marginTop: 75,
  },
  createAnAccount: {
    fontSize: 16,
  },
  errorMessage: {
    margin: 10,
    color: 'red',
  }
});
