import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({

  body: {
    position: 'fixed',
  },

  dashboard: {
    backgroundColor: '#383838',
    overflow: 'auto',
    height: '100%',
  },
  cardSet: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  col: {
    paddingLeft: 10,
    paddingRight: 10,
    width: 500,
    height: 1000,
  },
  picker: {
    height: 50,
    backgroundColor: 'gray',
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
});
