import React, { Component } from "react";
import { navigate } from "_routes/RootNavigation";
import { connect } from "react-redux";
import { List } from "native-base";
import PropTypes from "prop-types";
import { styles as global } from "_style/Global.style";
import { styles } from "./ToastContent.style";

// UI Framework
import { Button, Spinner, Text, View } from "native-base";
import { Row, Col, Grid } from "react-native-easy-grid";
import { Image } from "react-native";

import { Images } from "../../assets/";
class ToastContent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { imageName, imageStyle, toastText } = this.props;
    return (
      <View style={[styles.mainContent]}>
        <View style={[styles.leftSideView]}></View>
        <View style={[styles.middleView]}>
          <Image style={[imageStyle]} source={Images[imageName]} />
        </View>
        <View style={[styles.rightView]}>
          <Text style={{ fontSize: 18 }}>{toastText}</Text>
        </View>
      </View>
    );
  }
}
ToastContent.propTypes = {
  imageName: PropTypes.any,
  imageStyle: PropTypes.any,
  toastText: PropTypes.any,
};

export default connect(null, null)(ToastContent);
