import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  showHideGroup: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  hideButtonOff: {
    width: 99,
    height: 39,
  },
  showButtonOn: {
    width: 88,
    height: 28,
    marginRight: 12,
    marginLeft: 5,
  },
  hideButtonOn: {
    width: 88,
    height: 28,
  },
  showButtonOff: {
    width: 99,
    height: 39,
    marginRight: 12,
    marginLeft: 5,
  },
});
