import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  button: {
    borderRadius: 2,
    height: 25,
    flex: 1,
    // width: 67,
    marginLeft: 3.5,
    marginRight: 3.5,
    paddingLeft: 0,
    paddingRight: 0,
  },
  buttonOn: {
    backgroundColor: '#EDEDED',
  },
  buttonOff: {
    backgroundColor: '#767676',
  },
  buttonText: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: 0,
    paddingRight: 0,
  },
  buttonTextOn: {
    color: '#212121',
    textDecorationLine: 'underline',
  },
  buttonTextOff: {
    color: '#212121',
    opacity: 0.8,
  },
  card: {
    backgroundColor: '#1A1A1A',
    borderRadius: 5,

    height: 34,

    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.34,
    shadowRadius: 6.27,

    elevation: 10,

  },
  cardItem: {
    height: '100%',
    paddingLeft: 3.5,
    paddingRight: 3.5,
    justifyContent: 'space-evenly',
  },
});
