import React from "react";

import { StandaloneSearchBox } from "@react-google-maps/api";

const PlaceSearchBox = ({
  onPlaceChange,
  onplz,
  value,
  width,
  onChange,
  defaultValue,
  placeholder,
}) => {
  const [searchBox, setSearchBox] = React.useState(null);

  const onSBLoad = (ref) => {
    setSearchBox(ref);
  };
  const onPlacesChanged = () => {
    const place = searchBox.getPlaces()[0];
    if (place) {
      onPlaceChange({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
    }
  };

  const zIndexHandler = () => {
    document.querySelectorAll(".pac-container").forEach((element) => {
      element.style.zIndex = 9999;
      // $(".pac-container").remove();
    });
  };

  return (
    <>
      <StandaloneSearchBox onLoad={onSBLoad} onPlacesChanged={onPlacesChanged}>
        <input
          type="text"
          placeholder={placeholder}
          maxLength={500}
          defaultValue={defaultValue}
          onChange={onChange}
          value={value}
          onMouseDown={zIndexHandler}
          options={{
            types: ["address"],
            fields: ["formatted_address", "geometry", "name"],
          }}
          style={{
            boxSizing: `border-box`,
            border: `rgba(255, 255, 255, 0.15)`,
            width,
            height: `81px`,
            padding: `0 12px`,
            borderRadius: `4px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `18px`,
            outline: `none`,
            color: "#FAFAFA",
            backgroundColor: "#424242",
            borderRadius: "4px",
            borderRightWidth: "0px",
            borderLeftWidth: "0px",
            borderTopWidth: "0px",
            borderBottomWidth: "0px",
            zIndex: "1110",
          }}
        />
      </StandaloneSearchBox>
    </>
  );
};
export default React.memo(PlaceSearchBox);
