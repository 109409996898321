// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { updateUserDeviceCardSettings } from '_redux/dashboard/actions';
import { getUserDeviceCardSettingsById } from '_redux/dashboard/selectors';

// Native Base
import {
  Text,
  Item,
  Input,
  View,
} from 'native-base';
import { Grid, Col } from 'react-native-easy-grid';


import { styles as global } from '_style/Global.style';
import { styles } from '_components/device_settings/WidgetSettings.style';

/**
 * Re-usable input component with basic validation.
 */
class WidgetInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: String(props.inputValue),
    };

    this._handleInputChange = this._handleInputChange.bind(this);
    this._handleValidation = this._handleValidation.bind(this);
  }

  /**
   * Called when the user makes a change to the
   * @param {*} value current value of the input.
   */
  _handleInputChange(value) {
    this.setState({value});
  }

  /**
   * Called when the input loses focus. At this point, perform some
   * basic validation and report back to the parent DeviceSettings
   * component that a value has changed.
   */
  _handleValidation(event) {
    const { handleInputChange, inputKey, min, max } = this.props;
    const { value: v } = this.state;
    console.log(event);

    let value = parseInt(v);

    if (value < min) {
      value = min;
    } else if (value > max) {
      value = max;
    }

    this.setState({value: String(value)});
    handleInputChange(inputKey, value);
  }

  render() {
    return (
      <Input
        value={this.state.value}
        style={[ styles.input]}
        onChangeText={this._handleInputChange}
        onBlur={this._handleValidation}
        keyboardType={'numeric'}
      />
    );
  }
}

WidgetInput.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  inputKey: PropTypes.string.isRequired,
  inputValue: PropTypes.any,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
};

/**
 * Widget Settings component that contains multiple Widget Inputs.
 */
class WidgetSettings extends Component {

  constructor(props) {
    super(props);

    this.state = {};

    this._handleInputChange = this._handleInputChange.bind(this);
  }

  _handleInputChange(key, value) {
    console.log('key', key);
    console.log('value', value);
    this.setState({
      [key]: value,
    });
    this.props.settingsChanged({ [key]: value });
  }

  render() {

    const { deviceSettings } = this.props;

    const groups = {
      '1': [
        { key: 'maxRPM', label: 'MAX RPM:', min: 800, max: 3000, value: deviceSettings['maxRPM']},
        { key: 'minRPM', label: 'MIN RPM:', min: 800, max: 3000, value: deviceSettings['minRPM']},
      ],
      '2': [
        { key: 'idleRPM', label: 'IDLE RPM:', min: 800, max: 3000, value: deviceSettings['idleRPM']},
        { key: 'runRPM', label: 'RUN RPM:', min: 800, max: 3000, value: deviceSettings['runRPM']},
        { key: 'stepRPM', label: 'STEP RPM:', min: 0, max: 100, value: deviceSettings['stepRPM']},
      ],
      '3': [
        { key: 'outletMax', label: 'OUTLET MAX:', min: 0, max: 300, value: deviceSettings['outletMax']},
        { key: 'outletMin', label: 'OUTLET MIN:', min: 0, max: 300, value: deviceSettings['outletMin']},
        { key: 'outletStep', label: 'OUTLET STEP:', min: 0, max: 50, value: deviceSettings['outletStep']},
      ],
      '4': [
        { key: 'inletMax', label: 'INLET MAX:', min: 0, max: 50, value: deviceSettings['inletMax']},
        { key: 'inletMin', label: 'INLET MIN:', min: 0, max: 50, value: deviceSettings['inletMin']},
        { key: 'inletStep', label: 'INLET STEP:', min: 0, max: 25, value: deviceSettings['inletStep']},
      ]
    };

    const inputGroups = [];
    Object.keys(groups).forEach((inputGroup) => {
      const fields = groups[inputGroup];
      inputGroups.push((
        <View key={inputGroup} style={[styles.inputGroup]}>
          {
            fields.map((field) => {
             return (
              <View key={field.key} style={[styles.inputRow]}>
                <Text style={[styles.inputLabel]}>{ field.label }</Text>
                <Item style={ [global.item, styles.item]} disabled>
                  <WidgetInput
                    inputKey={field.key}
                    inputValue={field.value}
                    min={field.min}
                    max={field.max}
                    handleInputChange={this._handleInputChange} />
                </Item>
              </View>);
            })
          }
        </View>
      ));

    });

    return (
      <Grid>
        <Col>
          { inputGroups }
        </Col>
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    deviceSettings: getUserDeviceCardSettingsById(state, ownProps.deviceId),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserDeviceCardSettings: (deviceId, settings) => dispatch(updateUserDeviceCardSettings(deviceId, settings)),
  };
};

WidgetSettings.propTypes = {
  deviceId: PropTypes.string.isRequired,
  deviceSettings: PropTypes.any.isRequired,
  settingsChanged: PropTypes.func.isRequired,
  updateUserDeviceCardSettings: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(WidgetSettings);
