// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Native Base
import { Text } from 'native-base';

// Config
import { STATUS_CODES } from '_components/device_card/EngineState.config';

// Style
import { styles } from '_components/device_card/EngineState.style';

class EngineState extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { engineState } = this.props;

    return (
      <Text style={[styles.engineStateText]}>
        {STATUS_CODES[engineState]}
      </Text>
    );
  }
}

EngineState.propTypes = {
  engineState: PropTypes.any.isRequired,
};

export default connect(null, null)(EngineState);
