// React Core
import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { registerUser, getUserInfo } from "_redux/user/actions";
import { clearState } from "_redux/rootActions";

import { getActiveRouteName } from "_routes/RootNavigation";

import { StyleSheet } from "react-native";

// Custom Components
import AppHeader from "_components/common/AppHeader.component";
import SideBar from "_components/common/SideBar.component";

import Accounts from "_pages/Accounts.page";
import CrewMembers from "_pages/CrewMembers.page";
import Dashboard from "_pages/Dashboard.page";
import Devices from "_pages/Devices.page";
import Feedback from "_pages/Feedback.page";
import Home from "_pages/Home.page";
import Jobs from "_pages/Jobs.page";
import Notifications from "_pages/Notifications.page";
import MapHome from "_pages/MapHome.page";
import SiteDrafts from "_pages/Site.page";
import SiteMapEdit from "_pages/SiteMapEdit.page";
import ShowMapPage from "_pages/ShowMap.page";
import UploadDocument from "_pages/UploadDocuments.page";
import { Dimensions } from "react-native";

import { StyleProvider, View } from "native-base";
import Toast from "react-native-easy-toast";
import getTheme from "_theme/components";
import platform from "_theme/variables/platform";

import { NavigationContainer } from '@react-navigation/native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { navigationRef } from '_routes/RootNavigation';
import Modal from '_components/common/Modal.component';
import { Spinner, Button, Text } from 'native-base';
import { styles as global } from '_style/Global.style';

import { LoadScript } from "@react-google-maps/api";

const Drawer = createDrawerNavigator();

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");
const googleLibs = ["drawing", "places"];

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dimensions: {
        window,
        screen,
      },
      mapFullscreen: false,
      libraries: googleLibs,
      showModal: false,
      ignored: false,
    };
    this.toggleDrawer = null;

    this._sideBar = this._sideBar.bind(this);
    this._registerToast = this._registerToast.bind(this);

    this._onDimensionChange = this._onDimensionChange.bind(this);
    this._toggleDrawer = this._toggleDrawer.bind(this);
    this._openModal = this._openModal.bind(this);
    this._closeModal = this._closeModal.bind(this);
    this._closeModalRefresh = this._closeModalRefresh.bind(this);
  }

  _sideBar(props) {
    this.toggleDrawer = props.navigation.toggleDrawer;
    return <SideBar {...props} />;
  }

  _registerToast(toast) {
    this._toast = toast;
  }

  _onDimensionChange = ({ window, screen }) => {
    this.setState({ dimensions: { window, screen } });
  };

  componentDidMount() {
    Dimensions.addEventListener('change', this._onDimensionChange);
    setTimeout(this._openModal, 1209600000 );
  }

  componentWillUnmount() {
    Dimensions.removeEventListener("change", this._onDimensionChange);
  }
  _toggleDrawer() {
    this.toggleDrawer();
  }
  /**
   * Check if we need to show a global toast message.
   * Check if we need to update the user info in signedIn.
   * Check if there is an invite code and sign the user out.
   *
   * @param {} prevProps
   */
  async componentDidUpdate(prevProps) {
    const { authState, toastId, toastMessage, toastOpenDelay } = this.props;

    const { authState: prevAuthState, toastId: prevToastId } = prevProps;

    // If the user just signed in, we need to get their user information
    // into the store to make available to components.
    if (prevAuthState !== "signedIn" && authState === "signedIn") {
      this.props.getUserInfo();
      this.props.registerUser();
    }

    // If the toastId changed that means there is a new message
    // to show.
    if (toastId !== 0 && toastId !== prevToastId) {
      setTimeout(() => {
        this._toast.show(toastMessage, 5000);
      }, toastOpenDelay);
    }

    //check to see if user has ignored refresh modal 
    const { ignored } = this.state;

    if (ignored) {
      this.setState({
        ignored: false,
      });

      setTimeout(this._openModal,  86400000 );
    }
  }

  _openModal() {
    console.log("modal activated");
    this.setState({
      showModal: true,
    });
  }

  /**
   * Close the modal
   */
  _closeModal() {
    this.setState({
      showModal: false,
      ignored: true,
    });
  }

  _closeModalRefresh() {
    this.setState({
      showModal: false,
    });
    location.reload(true);
  }

  render() {
    const { showModal } = this.state;
    const { authState, toastIsError } = this.props;
    const { dimensions } = this.state;

    const width = dimensions.window.width;
    const iconOnly = width <= 1024;

    const drawerWidth = this.state.mapFullscreen ? 0 : iconOnly ? 85 : 250;

    const styles = StyleSheet.create({
      appContainer: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      },
      headerRow: {},
      mainRow: {
        flex: 1,
      },
      ignoreText: {
        color: '#B3B3B3',
        fontWeight: 'bold',
        letterSpacing: 1.6,
        marginLeft: 'auto',
        marginRight: 'auto',
        fontSize: 14,
      }
    });

    const modalContent = (
      <View>
      <Button style={[global.confirmationButton, { width: 460, marginBottom: 20 }]} onPress={this._closeModalRefresh}>
        <Text style={[global.confirmationButtonText]}>
          REFRESH
        </Text>
      </Button>
      <Button style={[global.cancelButton, { width: 460, marginBottom: 40 }]} onPress={this._closeModal}>
        <Text style={[styles.ignoreText]}>
          IGNORE
        </Text>
      </Button>
      </View>
    );

    if (authState === 'signedIn') {

      return (
        <StyleProvider style={getTheme(platform)}>
          <View style={[styles.appContainer]}>
            <Toast
              ref={this._registerToast}
              fadeOutDuration={500}
              position={"center"}
              textStyle={toastIsError ? global.toastError : global.toast}
              style={global.toastContainer}
            />
            {/* App Header */}
            <View style={[styles.headerRow]}>
              <AppHeader toggleDrawer={this._toggleDrawer} />
              <AppHeader />
            </View>

            <View>
              <Modal
                isVisible={showModal}
                title={"UPDATES MAY BE AVAILABLE"}
                subTitle={"Press the REFRESH button to load the latest version of the website Or IGNORE and refresh the window yourself when more convenient."}
                hideExitIcon={true}
                onClose={this._closeModal}
                content={modalContent}  
              />
            </View>
            
            {/* App Content */}
            <View style={[styles.mainRow]}>
              <LoadScript
                googleMapsApiKey="AIzaSyBU0_g8nGzHxsr-BQUuQvSZ3zuPBSKAFjg"
                libraries={this.state.libraries}
              >
                <NavigationContainer ref={navigationRef}>
                  <Drawer.Navigator
                    initialRouteName="Home"
                    drawerType={"front"}
                    headerMode="none"
                    drawerStyle={[
                      this.props.getMapFullScreenStatus
                        ? { display: "none" }
                        : { display: "" },
                      {
                        width: drawerWidth,
                        borderRightWidth: 0,
                        height: "100%",
                      },
                    ]}
                    drawerContent={this._sideBar}
                  >
                    <Drawer.Screen
                      name="Accounts"
                      component={Accounts}
                      options={{ unmountOnBlur: true }}
                    />
                    <Drawer.Screen
                      name="Crew Members"
                      component={CrewMembers}
                      options={{ unmountOnBlur: true }}
                    />
                    <Drawer.Screen
                      name="Dashboard"
                      component={Dashboard}
                      options={{ unmountOnBlur: true }}
                    />
                    <Drawer.Screen
                      name="Devices"
                      component={Devices}
                      options={{ unmountOnBlur: true }}
                    />
                    <Drawer.Screen
                      name="Feedback"
                      component={Feedback}
                      options={{ unmountOnBlur: true }}
                    />
                    <Drawer.Screen
                      name="Home"
                      component={Home}
                      options={{ unmountOnBlur: true }}
                    />
                    <Drawer.Screen
                      name="Jobs"
                      component={Jobs}
                      options={{ unmountOnBlur: true }}
                    />
                    <Drawer.Screen
                      name="Notifications"
                      component={Notifications}
                      options={{ unmountOnBlur: true }}
                    />
                    <Drawer.Screen
                      name="Sites"
                      component={SiteDrafts}
                      options={{ unmountOnBlur: true }}
                    />
                    <Drawer.Screen
                      name="Site"
                      component={MapHome}
                      options={{ unmountOnBlur: true }}
                    />
                    <Drawer.Screen
                      name="SiteEdit"
                      component={SiteMapEdit}
                      options={{ unmountOnBlur: true }}
                    />
                    <Drawer.Screen
                      name="ShowMap"
                      component={ShowMapPage}
                      options={{ unmountOnBlur: true }}
                    />
                    <Drawer.Screen
                      name="UploadDocument"
                      component={UploadDocument}
                      options={{ unmountOnBlur: true }}
                    />
                  </Drawer.Navigator>
                </NavigationContainer>
              </LoadScript>
            </View>
          </View>
        </StyleProvider>
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    toastId: state.toasts.id,
    toastMessage: state.toasts.message,
    toastIsError: state.toasts.isError,
    toastOpenDelay: state.toasts.openDelay,
    getMapFullScreenStatus: state.siteMap.isMapFulScreen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    registerUser: () => dispatch(registerUser()),
    getUserInfo: () => dispatch(getUserInfo()),
    clearState: () => dispatch(clearState()),
  };
};

App.propTypes = {
  authState: PropTypes.string,

  clearState: PropTypes.func.isRequired,

  toastId: PropTypes.number.isRequired,
  toastMessage: PropTypes.string.isRequired,
  toastIsError: PropTypes.bool.isRequired,
  toastOpenDelay: PropTypes.number.isRequired,

  getUserInfo: PropTypes.func.isRequired,
  registerUser: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
