import { StyleSheet } from 'react-native';
import { dark_gray, yellow, red } from '_style/Global.style';

export const styles = StyleSheet.create({
confirmRemovalModalContent: {
    width: 425,
    marginLeft: 75,
    marginRight: 75,
  },
  confirmRemovalText: {
    textAlign: 'center',
    marginBottom: 40,
    letterSpacing: 1.6,
  },
  devicesCrewMembersContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
    width: '100%',
    height: 325,
  },
  devicesContainer: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    flex: 1,
  },
  crewMembersContainer: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    flex: 1,
  },

  addJobModalText: {
    textAlign: 'center',
    marginBottom: 40,
    letterSpacing: 1.6,
    fontSize: 14,
  },

  checkboxContainer: {
    alignSelf: 'center',
    width: 200,
    height: 225,
    overflow: 'auto',
    padding: 5,
    borderRadius: 11,
  },
  checkbox: {
    padding: 10,
  },
  defaultCheckboxContainer:{
    alignItems: 'center',
    marginBottom: 30,
  },
  crewLeaderNote: {
    color: yellow,
    fontSize: 12,
    width: 180,
    margin: 10,
    textAlign: 'center',
  },
  jobDetailsSubTitle: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: 21,
    letterSpacing: 1.6,
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: dark_gray,
    marginBottom: 10,
  },
  errorMessage: {
    fontSize: 12,
    marginLeft: 15,
    padding: 5,
    color: red,
  },
});
