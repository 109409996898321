// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Native Base
import {
  Text,
  View,
} from 'native-base';
import { Grid, Col } from 'react-native-easy-grid';

import { styles } from '_components/device_settings/AlarmSettings.style';

import { FAULT_CODES } from '_components/device_settings/AlarmSettings.config';

class AlarmSettings extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { espn, efmi } = this.props;

    const { actionMessage = '-', faultMessage = '-' } = FAULT_CODES[`${espn}`] || {};

    return (
      <Grid>
        <Col style={[styles.alarmContainer]}>
          <View style={[styles.alarmSection]}>
            <Text style={[styles.alarmTextLabel]}>SPN:</Text>
            <Text style={[styles.alarmText]}>{ espn }</Text>
          </View>

          <View style={[styles.alarmSection]}>
            <Text style={[styles.alarmTextLabel]}>FMI:</Text>
            <Text style={[styles.alarmText]}>{ efmi }</Text>
          </View>

          <View style={[styles.alarmDetailsSection, styles.center]}>
            <Text style={[styles.alarmText, styles.noPadding]}>
              { faultMessage }
            </Text>
            <Text style={[styles.alarmTextAction, styles.noPadding]}>
              { actionMessage }
            </Text>
          </View>

        </Col>
      </Grid>
    );
  }
}

AlarmSettings.propTypes = {
  deviceId: PropTypes.string.isRequired,
  espn: PropTypes.any.isRequired,
  efmi: PropTypes.any.isRequired,
};

export default connect(null, null)(AlarmSettings);
