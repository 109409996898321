import { StyleSheet } from 'react-native';
import {
  light_gray,
  lighter_gray,
} from '_style/Global.style';

export const styles = StyleSheet.create({
  mainContainer: {
    width: '100%',
  },
  dropDownContainer: {
    position:'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: light_gray,
    height: 40,
    borderRadius: 4,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    // zIndex:9999,
  },
  dropDownValuesContainer: {
    backgroundColor: '#000000',
    borderRadius: 4,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    padding: 10,

    width: '100%',
    position: 'absolute',
    top: 70,
    // zIndex: 9999,
  },
  floatingLabel: {
    padding: 5,
    fontSize: 14,
  },
  dropDownItem: {
    padding: 5,
    marginLeft: 15,
    fontSize: 14,
  },
  dropDownItemSelected: {
    color: '#00B8D9',
  },
  selectedValue: {
    fontSize: 18,
    marginLeft: 10,
  },
  placeholderValue: {
    fontSize: 18,
    color: lighter_gray,
    marginLeft: 20,
  },
  dropdownArrow: {
    width: 29,
    height: 12,
    marginRight: 20,
  },
  errorMessage:{
    color: 'red',
    fontSize: 12,
    position: 'absolute',
    top: 70,
    marginLeft: 10,
  }
});
