// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Amplify
import { Auth } from 'aws-amplify';

// Custom Components.
import FormInput from '_components/common/FormInput.component';
import {
  checkPasswordEquality,
  validateFormData,
  validateFormField,
  updateFormData,
  REQUIRED_CONSTRAINT,
  PASSWORD_CONSTRAINT,
} from '_util/form-util';

// UI Framework
import { Image, ImageBackground } from 'react-native';
import {
  Body,
  Button,
  Card,
  CardItem,
  Spinner,
  Text,
  View,
} from 'native-base';
import { Col, Grid } from 'react-native-easy-grid';

// Style
import { styles as global, placeholderTextColor, red } from '_style/Global.style';
import { styles } from '_components/auth/CustomForgotPassword.style';

const defaultState = {
  isGettingCode: false,
  isResetting: false,
  isResending: false,
  failedReset: false,
  failedGettingCode: false,
  enteredUsername: false,
  touchedConfirmPassword: false,
  usernameScreenErrorMessage: '',
  passwordResetScreenErrorMessage: '',
  formData: {},
  errorMessages: {},
};

const userNameConstraints = {
  username: REQUIRED_CONSTRAINT('Username'),
};

const resetPasswordConstraints = {
  verification_code: REQUIRED_CONSTRAINT('Verification code'),
  password: PASSWORD_CONSTRAINT,
  confirmPassword: {
    equality: {
      attribute: 'password',
      message: '^Passwords do not match'
    }
  },
};


/**
 * The CustomForgotPassword component handles the logic that allows a user
 * to reset their password.
 */
class CustomForgotPassword extends Component {

  constructor(props) {
    super(props);

    this.state = defaultState;

    this._clearState = this._clearState.bind(this);

    this._resetPassword = this._resetPassword.bind(this);
    this._resendCode = this._resendCode.bind(this);
    this._signIn = this._signIn.bind(this);
    this._getPasswordResetCode = this._getPasswordResetCode.bind(this);

    this._generateUsernameScreen = this._generateUsernameScreen.bind(this);
    this._generateResetPasswordScreen = this._generateResetPasswordScreen.bind(this);

    this._handleFormInput = this._handleFormInput.bind(this);
    this._checkPasswordEquality = this._checkPasswordEquality.bind(this);
  }

  /**
   * Utility function to clear the state.
   */
  _clearState() {
    this.setState(defaultState);
  }

  /**
   * Triggers Cognito to generate a password reset text for the user.
   */
  async _getPasswordResetCode() {
    const { username } = this.state.formData;
    this.setState({
      isGettingCode: true,
    });
    try {
      await Auth.forgotPassword(username);

      this.setState({
        enteredUsername: true,
      });

    } catch (e) {
      this.setState({
        usernameScreenErrorMessage: e.message,
        failedGettingCode: true,
      });
    } finally {
      this.setState({
        isGettingCode: false,
      });
    }
  }

  /**
   * Will trigger a re-send of the verification code.
   */
  async _resendCode() {
    const { username } = this.state.formData;

    this.setState({
      isResending: true,
    });

    try {
      await Auth.resendSignUp(username);
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({
        isResending: false,
      });
    }
  }

  /**
   *
   */
  async _resetPassword() {
    const { formData } = this.state;
    const { verification_code, password, username } = formData;
    const { onStateChange } = this.props;
    const errorMessages = validateFormData(
      formData,
      {...userNameConstraints, ...resetPasswordConstraints},
    );

    if (Object.keys(errorMessages).length > 0) {
      this.setState({
        errorMessages,
      });
      return;
    }

    this.setState({
      isResetting: true,
    });

    try {

      await Auth.forgotPasswordSubmit(
        username,
        verification_code,
        password,
      );

      this._clearState();
      onStateChange('signIn');

    } catch (e) {
      console.log(e);
      this.setState({
        failedReset: true,
        passwordResetScreenErrorMessage: e.message,
      });
    } finally {
      this.setState({
        isResetting: false,
      });
    }
  }

  /**
   * Navigates back to the sign in screen.
   */
  _signIn () {
    const { onStateChange } = this.props;

    onStateChange('signIn');
  }

  /**
   * Custom logic to validate the password and confirm password fields. A little tricky
   * here since they're related to eachother and need to update the error message on each
   * given the other's input and vice versa.
   *
   * @param {string} fieldId either 'password' or 'confirmNewPassword'
   * @param {string} value value of the input
   */
  _checkPasswordEquality(fieldId, value) {
    const { touchedConfirmPassword, formData, errorMessages } = this.state;
    const updatedState = checkPasswordEquality(
      fieldId,
      value,
      formData,
      errorMessages,
      touchedConfirmPassword,
      resetPasswordConstraints,
    );
    this.setState(updatedState);
  }

  /**
   * Keeps track of what the user has entered into the form fields.
   */
  _handleFormInput(fieldId, value) {
    const { errorMessages, formData } = this.state;

    // Need to handle password fields separately because of their
    // related validation logic.
    if (fieldId === 'password' || fieldId === 'confirmPassword') {
      this._checkPasswordEquality(fieldId, value);

    } else if (fieldId === 'username') {
      this.setState(updateFormData(
        fieldId,
        value,
        validateFormField(fieldId, value, userNameConstraints),
        formData,
        errorMessages,
      ));

    } else if (fieldId === 'verification_code') {
      this.setState(updateFormData(
        fieldId,
        value,
        validateFormField(fieldId, value, resetPasswordConstraints),
        formData,
        errorMessages,
      ));
    }
  }

  /**
   * Generates the first step of the password reset process, where the
   * user enters their username.
   */
  _generateUsernameScreen() {
    const {
      errorMessages,
      failedGettingCode,
      isGettingCode,
      usernameScreenErrorMessage,
    } = this.state;
    const { username } = this.state.formData;

    return (
      <>
        <View style={[styles.formContainer]}>
          <View style={[global.authInputContainer]}>
            <FormInput
              key={'username'}
              fieldId={'username'}
              placeholder={'Username'}
              placeholderTextColor={placeholderTextColor}
              inputStyles={[global.authInput]}
              itemStyles={[global.authItem]}
              onChange={this._handleFormInput}
              insetShadow={false}
              errorMessage={errorMessages['username'] || ''}
              errorMessageStyle={[global.authInputErrorText]}
            />
          </View>
        </View>
        {
          isGettingCode ?
            <Spinner color={ red } />
            :
            <Button
              disabled={ isGettingCode || !username }
              style={ [global.authFormButton] }
              onPress={ this._getPasswordResetCode }
            >
              <Text
                uppercase={ false }
                style={ [global.authFormButtonText] }
              >
                Get Reset Code
              </Text>
            </Button>
        }
        { !failedGettingCode ? null :
          <Text style={ styles.errorMessage }>
            { usernameScreenErrorMessage }
          </Text>
        }
      </>
    );
  }

  /**
   * Generates the second step of the password reset process, where
   * the user enters the verification code and their new password.
   */
  _generateResetPasswordScreen() {
    const {
      isResetting,
      isResending,
      errorMessages,
      failedReset,
      passwordResetScreenErrorMessage,
    } = this.state;

    return (
      <>
        <View style={[styles.formContainer]}>
          <View style={[global.authInputContainer]}>
            <FormInput
              key={'verification_code'}
              fieldId={'verification_code'}
              placeholder={'Verification code'}
              helpText={'6 digit code texted to your phone'}
              helpTextStyle={global.authInputHelpText}
              placeholderTextColor={placeholderTextColor}
              inputStyles={[global.authInput]}
              itemStyles={[global.authItem]}
              onChange={this._handleFormInput}
              insetShadow={false}
              errorMessage={errorMessages['verification_code'] || ''}
              errorMessageStyle={[global.authInputErrorText]}
            />
          </View>
          <View style={[global.authInputContainer, { marginBottom: 0 }]}>
            <FormInput
              key={'password'}
              fieldId={'password'}
              placeholder={'New Password'}
              helpText={'Password must be at least 8 characters long and contain 1 number, 1 uppercase letter, and 1 lowercase letter'}
              helpTextStyle={global.authInputHelpText}
              placeholderTextColor={placeholderTextColor}
              inputStyles={[global.authInput]}
              itemStyles={[global.authItem]}
              secure={true}
              onChange={this._handleFormInput}
              insetShadow={false}
              errorMessage={errorMessages['password'] || ''}
              errorMessageStyle={[global.authInputErrorText]}
              showHide={true}
            />
          </View>
          <View style={[global.authInputContainer]}>
            <FormInput
              key={'confirmPassword'}
              fieldId={'confirmPassword'}
              placeholder={'Confirm New Password'}
              placeholderTextColor={placeholderTextColor}
              inputStyles={[global.authInput]}
              itemStyles={[global.authItem]}
              secure={true}
              onChange={this._handleFormInput}
              insetShadow={false}
              errorMessage={errorMessages['confirmPassword'] || ''}
              errorMessageStyle={[global.authInputErrorText]}
              showHide={true}
            />
          </View>
        </View>
        {
          isResending ?
            <Spinner color={ red } />
            :
            <Text style={ [global.textLight, global.fontSF, styles.resendCode] }>
              <Button transparent onPress={ this._resendCode }>
                <Text uppercase={ false } style={ [global.textYellow, styles.resendCodeText] }>
                  Resend Code
                </Text>
              </Button>
            </Text>
        }
        {
          isResetting ?
            <Spinner color={ red } />
            :
            <Button
              disabled={ isResetting }
              style={ [global.authFormButton] }
              onPress={ this._resetPassword }
            >
              <Text
                uppercase={ false }
                style={ [global.authFormButtonText] }
              >
                Reset Password
              </Text>
            </Button>
        }
        { !failedReset ? null :
          <Text style={ styles.errorMessage }>
            { passwordResetScreenErrorMessage }
          </Text>
        }
      </>
    );
  }

  render() {
    const { enteredUsername } = this.state;
    const { authState } = this.props;

    if (authState !== 'forgotPassword') {
      return null;
    }
    return (
      <ImageBackground
        style={ global.backgroundImage }
        source={require('_assets/images/auth-background.jpg')}
      >
        <Grid style={ global.background }>
          <Col style={ global.center }>
            <Card style={ [global.card, styles.card] }>
              <CardItem style={ global.cardItem }>
                <Body style={ global.center }>
                  <Image
                    style={ styles.headerImage }
                    source={require('_assets/images/bazooka_farmstar.png')}
                  />
                  <Text style={ [global.textLight, global.fontSF, styles.paragraph] }>
                    Reset Your Password
                  </Text>
                  {
                    !enteredUsername ?
                      this._generateUsernameScreen()
                      :
                      this._generateResetPasswordScreen()
                  }

                  <Button transparent onPress={ this._signIn } style={[styles.backToSignIn]}>
                    <Text uppercase={ false } style={ [global.textYellow, styles.signIn] }>
                      Back to Sign in
                    </Text>
                  </Button>
                </Body>
              </CardItem>
            </Card>
          </Col>
        </Grid>
      </ImageBackground>
    );
  }
}

CustomForgotPassword.propTypes = {
  authData: PropTypes.any,
  authState: PropTypes.string,
  onStateChange: PropTypes.func,
};

export default connect(null, null)(CustomForgotPassword);


