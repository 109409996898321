import React, { Component } from "react";
import PropTypes from 'prop-types';

import {
  View,
  Text,
  Animated,
  PanResponder,
  Easing
} from "react-native";

import { styles } from '_components/device_card/VerticalSlider.style';

import { connect } from 'react-redux';

class VerticalSlider extends Component {
  _moveStartValue = null;

  constructor(props) {
    super(props);

    let panResponder = PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onMoveShouldSetPanResponder: () => false,
      onPanResponderGrant: () => {
        this._moveStartValue = this.state.value;
      },
      onPanResponderMove: (event, gestureState) => {
        if (this.props.disabled) {
          return;
        }
        const value = this._fetchNewValueFromGesture(gestureState);
        this._changeState(value);
        if (this.props.onChange) {
          this.props.onChange(value);
        }
      },
      onPanResponderRelease: (event, gestureState) => {
        if (this.props.disabled) {
          return;
        }
        const value = this._fetchNewValueFromGesture(gestureState);
        this._changeState(value);
        if (this.props.onComplete) {
          this.props.onComplete(value);
        }
      },
      onPanResponderTerminationRequest: () => false,
      onPanResponderTerminate: (event, gestureState) => {
        if (this.props.disabled) {
          return;
        }
        const value = this._fetchNewValueFromGesture(gestureState);
        this._changeState(value);
        if (this.props.onComplete) {
          this.props.onComplete(value);
        }
      }
    });

    this.state = {
      value: props.value,
      preValue: props.value,
      sliderHeight: new Animated.Value(0),
      ballHeight: new Animated.Value(0),
      panResponder
    };
  }

  _fetchNewValueFromGesture(gestureState) {
    const { min, max, step, height } = this.props;
    const ratio = -gestureState.dy / height;
    const diff = max - min;
    if (step) {
      return Math.max(
        min,
        Math.min(
          max,
          this._moveStartValue + Math.round((ratio * diff) / step) * step
        )
      );
    }
    let value = Math.max(min, this._moveStartValue + ratio * diff);
    return Math.floor(value * 100) / 100;
  }

  _getSliderHeight(value) {
    const { min, max, height } = this.props;
    return ((value - min) * height) / (max - min);
  }

  _changeState(value) {
    const { height, ballIndicatorWidth, animationDuration } = this.props;
    const sliderHeight = this._getSliderHeight(value);
    const ballHeight = ballIndicatorWidth ? ballIndicatorWidth : 48;
    let ballPosition = sliderHeight - ballHeight;
    // if (ballPosition + ballHeight >= height) {
    //   ballPosition = height - ballHeight;
    // } else if (ballPosition - ballHeight <= 0) {
    //   ballPosition = 0;
    // } else {
    //   ballPosition = ballPosition - ballHeight / 2;
    // }
    Animated.parallel([
      Animated.timing(this.state.sliderHeight, {
        toValue: sliderHeight,
        easing: Easing.linear,
        duration: animationDuration || 0,
        useNativeDriver: false,
      }),
      Animated.timing(this.state.ballHeight, {
        toValue: ballPosition + 30,
        easing: Easing.linear,
        duration: animationDuration || 0,
        useNativeDriver: false,
      })
    ]).start();
    this.setState({ value });
  }

  componentDidMount() {
    const { value } = this.props;
    if (value) {
      this._changeState(value);
    }
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (value !== prevProps.value) {
      this._changeState(value);
    }
  }

  render() {
    const {
      width,
      height,
      borderRadius,
      maximumTrackTintColor,
      minimumTrackTintColor,
      ballIndicatorColor,
      ballIndicatorWidth,
      ballIndicatorPosition,
      ballIndicatorTextColor,
      ballIndicatorTextBorderColor,
      renderIndicator,
      indicatorSuffix = '',
    } = this.props;
    const { value } = this.state;

    return (
      <View style={[{ height, width, borderRadius }]}>
        <View
          style={[
            styles.container,
            styles.shadow,
            {
              height,
              width,
              borderRadius,
              backgroundColor: maximumTrackTintColor
                ? maximumTrackTintColor
                : "#ECECEC"
            }
          ]}
          {...this.state.panResponder.panHandlers}
        >
          <Animated.View
            style={[
              styles.slider,
              {
                height: this.state.sliderHeight,
                width,
                backgroundColor: minimumTrackTintColor
                  ? minimumTrackTintColor
                  : "#ECECEC"
              }
            ]}
          />
        </View>
        {this.props.showBallIndicator ? (
          <View>
          <Animated.View
            style={[
              styles.ball,
              styles.shadow,
              {
                width: ballIndicatorWidth ? ballIndicatorWidth : 48,
                height: ballIndicatorWidth ? ballIndicatorWidth / 2 : 24,
                borderRadius: 5, // ballIndicatorWidth ? ballIndicatorWidth / 2 : 24,
                bottom: this.state.ballHeight,
                left: ballIndicatorPosition ? ballIndicatorPosition : -60,
                backgroundColor: ballIndicatorColor
                  ? ballIndicatorColor
                  : "#ECECEC"
              }
            ]}
          >
              <Text
                style={[
                  styles.ballText,
                  {
                    color: ballIndicatorTextColor
                      ? ballIndicatorTextColor
                      : "#000000",
                    // marginBottom: 20,
                    borderColor: ballIndicatorTextBorderColor,

                  }
                ]}
              >
                {Math.round(value * 100) / 100}{ indicatorSuffix }
              </Text>
              <View style={[styles.renderIndicator]} {...this.state.panResponder.panHandlers}>
                { renderIndicator }
              </View>
          </Animated.View>
            </View>
        ) : null}
      </View>
    );
  }
}

VerticalSlider.propTypes = {
  value: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  borderRadius: PropTypes.number.isRequired,
  maximumTrackTintColor: PropTypes.string.isRequired,
  minimumTrackTintColor: PropTypes.string.isRequired,
  showBallIndicator: PropTypes.bool.isRequired,
  step: PropTypes.number,
  ballIndicatorColor: PropTypes.string,
  ballIndicatorWidth: PropTypes.number,
  ballIndicatorPosition: PropTypes.number,
  ballIndicatorTextColor: PropTypes.string,
  animationDuration: PropTypes.number,
  renderIndicator: PropTypes.any,
  indicatorSuffix: PropTypes.string,
};

export default connect(null, null)(VerticalSlider);
