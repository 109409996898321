import { StyleSheet } from 'react-native';
import { roboto, textLight, green, red, yellow } from '_style/Global.style';

export const styles = StyleSheet.create({
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width:'100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 20,
  },
  formRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  formLabelContainer: {
    flex: 1,
  },
  formLabel: {
    fontSize: 20,
    fontFamily: roboto,
    color: textLight,
  },
  formInputContainer: {
    flexDirection: 'row',
    flex: 3,
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 5,
  },
  actionButtonContainer: {
    width: '100%'
  },
  subTitle: {
    color: textLight,
    fontSize: 14,
    margin: 20,
    letterSpacing: 1.6,
  },

  successContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  // Action Buttons
  actionButton: {
    width: 360,
    marginTop: 20,
  },
  closeButtonText: {
    color: textLight,
    fontSize: 18,
    fontFamily: roboto,
    fontWeight: 'bold',
    letterSpacing: 1.6,
  },
  successIcon: {
    width: 112,
    height: 112,
  },
  successText: {
    color: green,
    fontSize: 28,
    fontFamily: roboto,
    fontWeight: 'bold',
    letterSpacing: 1.6,
    padding: 25,
  },
  successDescriptionText: {
    color: textLight,
    fontSize: 24,
    fontFamily: roboto,
    fontWeight: 'bold',
    letterSpacing: 1.6,
    textAlign: 'center',
    marginTop: 40,
    width: 350,
  },
  accountNameText: {
    color: yellow,
    fontSize: 24,
    fontFamily: roboto,
    fontWeight: 'bold',
    letterSpacing: 1.6,
    textAlign: 'center',
    marginTop: 40,
    width: 350,
  },
  errorMessage: {
    color: red,
  }

});
