// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { getInvite } from '_redux/user/actions';

// Amplify
import { Auth } from 'aws-amplify';

// Custom Components
import CheckBox from '_components/common/CheckBox.component';
import FormInput from '_components/common/FormInput.component';
import {
  checkPasswordEquality,
  validateFormData,
  validateFormField,
  updateFormData,
  EMAIL_CONSTRAINT,
  PASSWORD_CONSTRAINT,
  PHONE_NUMBER_CONSTRAINT,
  REQUIRED_CONSTRAINT,
} from '_util/form-util';

import { formatPhoneNumber } from '_util/form-util';

// UI Framework
import { Image, ImageBackground } from 'react-native';
import {
  Body,
  Button,
  Card,
  CardItem,
  Spinner,
  Text,
  View,
} from 'native-base';
import { Col, Grid } from 'react-native-easy-grid';

// Style
import { styles as global, placeholderTextColor, red, yellow } from '_style/Global.style';
import { styles } from '_components/auth/CustomSignUp.style';

const defaultState = {
  isSigningUp: false,
  failedSignUp: false,
  touchedConfirmPassword: false,
  errorMessage: '',
  errorMessages: {},
  formData: {},
};

const constraints = {
  username: REQUIRED_CONSTRAINT('Username'),
  password: PASSWORD_CONSTRAINT,
  confirmPassword: {
    equality: {
      attribute: 'password',
      message: '^Passwords do not match'
    },
  },
  email: EMAIL_CONSTRAINT,
  phone_number: PHONE_NUMBER_CONSTRAINT,
};

/**
 * The CustomSignUp component is the form the user fills out when they are
 * manually creating an account instead of logging in with Facebook or
 * Google credentials.
 */
class CustomSignUp extends Component {

  constructor(props) {
    super(props);

    this.state = defaultState;

    this._clearState = this._clearState.bind(this);

    this._signIn = this._signIn.bind(this);
    this._signUp = this._signUp.bind(this);

    this._handleFormInput = this._handleFormInput.bind(this);
    this._checkPasswordEquality = this._checkPasswordEquality.bind(this);

    this._getInviteInformation = this._getInviteInformation.bind(this);
  }

  /**
   * Utility function to clear the state.
   */
  _clearState() {
    this.setState(defaultState);
  }

  /**
   * Navigates back to the sign in screen.
   */
  _signIn() {
    const { onStateChange } = this.props;

    onStateChange('signIn');
    this._clearState();
  }

  /**
   *
   * @param {*} inviteCode
   */
  _getInviteInformation(inviteCode) {
    this.props.getInvite(inviteCode);
  }

  /**
   * Handles registering the user given the provided
   * form field inputs.
   */
  async _signUp() {
    const {
      formData,
    } = this.state;

    const { onStateChange } = this.props;

    const errorMessages = validateFormData(formData, constraints);

    if (Object.keys(errorMessages).length > 0) {
      this.setState({
        errorMessages,
      });
      return;
    }

    // This will activate a spinner and disable the input fields.
    this.setState({
      isSigningUp: true,
    });

    try {
      // Use the Amplify sign up.

      await Auth.signUp({
        username: formData.username,
        password: formData.password,
        attributes: {
          email: formData.email,
          phone_number: formData.phone_number,
          given_name: formData.first_name || '',
          family_name: formData.last_name || '',
          'custom:inviteCode': formData.invite_code || '',
        },
      }
      );

      // If we're here then login was a success.
      onStateChange('confirm', formData.username);
      this._clearState();

    } catch (e) {
      console.log('error signing up', e);

      this.setState({
        failedSignUp: true,
        errorMessage: e.message,
      });

    } finally {
      this.setState({
        isSigningUp: false,
      });
    }
  }

  /**
   * Custom logic to validate the password and confirm password fields. A little tricky
   * here since they're related to eachother and need to update the error message on each
   * given the other's input and vice versa.
   *
   * @param {string} fieldId either 'newPassword' or 'confirmNewPassword'
   * @param {string} value value of the input
   */
  _checkPasswordEquality(fieldId, value) {
    const { touchedConfirmPassword, formData, errorMessages } = this.state;
    const updatedState = checkPasswordEquality(
      fieldId,
      value,
      formData,
      errorMessages,
      touchedConfirmPassword,
      constraints,
    );
    this.setState(updatedState);
  }

  /**
   * Keeps track of the user inputs into the form by field.
   */
  _handleFormInput(fieldId, value) {
    const { formData, errorMessages } = this.state;

    // Need to handle password fields separately because of their
    // related validation logic.
    if (fieldId === 'password' || fieldId === 'confirmPassword') {
      this._checkPasswordEquality(fieldId, value);
    } else {
      // Cognito expects a very specific phone number format, try
      // to clean up what the user entered.
      if (fieldId === 'phone_number') {
        value = formatPhoneNumber(value);
      }

      this.setState(updateFormData(
        fieldId,
        value,
        validateFormField(fieldId, value, constraints),
        formData,
        errorMessages,
      ));
    }

  }

  /**
   * Check to see if this component has received an invite code, in which case
   * we need to load in the invite data.
   *
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    const { authData: prevAuthData, invite: prevInvite } = prevProps;
    const { authData, invite } = this.props;

    if (!prevAuthData && (authData && authData.inviteCode)) {
      console.log(authData);
      this._getInviteInformation(authData.inviteCode);
    } else if (!prevInvite && invite) {
      this.setState({
        formData: invite,
      });
    }
  }

  render() {
    const { authState, isFetchingInvite, invite } = this.props;
    const { isSigninUp, failedSignUp, formData, errorMessage, errorMessages } = this.state;


    if (authState === 'signUp') {
      return (
        <ImageBackground
          style={global.backgroundImage}
          source={require('_assets/images/auth-background.jpg')}
        >
          <Grid style={global.background}>
            <Col style={global.center}>
              <Card style={[global.card, styles.card]}>
                <CardItem style={global.cardItem}>
                  <Body style={global.center}>
                    <Image
                      style={styles.headerImage}
                      source={require('_assets/images/bazooka_farmstar.png')}
                    />
                    <Text style={[global.textLight, global.fontSF, styles.paragraph]}>
                      Create New Account
                    </Text>

                    <View style={[styles.formContainer]}>
                      {
                        isFetchingInvite ?
                          <Spinner color={'red'} />
                          :
                          invite === undefined ?
                            <>
                              {this._signIn()}
                            </>
                            :
                            <>
                              {/* <View style={[global.authInputContainer, { marginBottom: 40 }]}>
                              <View style={[{ padding: 10 }]}>
                                <CheckBox
                                  fieldId={'hasInviteCode'}
                                  onChange={this._handleFormInput}
                                  label={'Invite code?'}
                                  labelColor={yellow}
                                  checked={formData.invite_code}
                                />
                              </View>
                              {
                                (formData.invite_code || formData.hasInviteCode) &&
                                  <FormInput
                                    fieldId={'inviteCode'}
                                    placeholder={'Invite Code'}
                                    placeholderTextColor={placeholderTextColor}
                                    helpText={'9 character alphanumeric code'}
                                    helpTextStyle={global.authInputHelpText}
                                    onChange={this._handleFormInput}
                                    inputStyles={[global.authInput]}
                                    itemStyles={[global.authItem]}
                                    insetShadow={false}
                                    defaultValue={formData.invite_code}
                                  />
                              }
                            </View> */}
                              <View style={[global.authInputContainer, { marginBottom: 40 }]}>
                                <FormInput
                                  fieldId={'username'}
                                  placeholder={'Username'}
                                  placeholderTextColor={placeholderTextColor}
                                  onChange={this._handleFormInput}
                                  inputStyles={[global.authInput]}
                                  itemStyles={[global.authItem]}
                                  insetShadow={false}
                                  errorMessage={errorMessages['username'] || ''}
                                  errorMessageStyle={[global.authInputErrorText]}
                                />
                              </View>
                              <View style={[global.authInputContainer, { marginBottom: 0 }]}>
                                <FormInput
                                  fieldId={'password'}
                                  placeholder={'Password'}
                                  helpText={'Password must be at least 8 characters long and contain atleast 1 number'}
                                  helpTextStyle={global.authInputHelpText}
                                  placeholderTextColor={placeholderTextColor}
                                  onChange={this._handleFormInput}
                                  inputStyles={[global.authInput]}
                                  itemStyles={[global.authItem]}
                                  secure={true}
                                  insetShadow={false}
                                  errorMessage={errorMessages['password'] || ''}
                                  errorMessageStyle={[global.authInputErrorText]}
                                  showHide={true}
                                />
                              </View>
                              <View style={[global.authInputContainer, { marginBottom: 40 }]}>
                                <FormInput
                                  fieldId={'confirmPassword'}
                                  placeholder={'Confirm Password'}
                                  placeholderTextColor={placeholderTextColor}
                                  onChange={this._handleFormInput}
                                  inputStyles={[global.authInput]}
                                  itemStyles={[global.authItem]}
                                  secure={true}
                                  insetShadow={false}
                                  errorMessage={errorMessages['confirmPassword'] || ''}
                                  errorMessageStyle={[global.authInputErrorText]}
                                  showHide={true}
                                />
                              </View>
                              <View style={[global.authInputContainer]}>
                                <FormInput
                                  fieldId={'email'}
                                  placeholder={'Email'}
                                  placeholderTextColor={placeholderTextColor}
                                  onChange={this._handleFormInput}
                                  inputStyles={[global.authInput]}
                                  itemStyles={[global.authItem]}
                                  insetShadow={false}
                                  errorMessage={errorMessages['email'] || ''}
                                  errorMessageStyle={[global.authInputErrorText]}
                                  defaultValue={formData.email}
                                />
                              </View>
                              <View style={[global.authInputContainer]}>

                                <FormInput
                                  fieldId={'phone_number'}
                                  placeholder={'Phone Number'}
                                  helpText={'###-###-####'}
                                  helpTextStyle={global.authInputHelpText}
                                  placeholderTextColor={placeholderTextColor}
                                  onChange={this._handleFormInput}
                                  inputStyles={[global.authInput]}
                                  itemStyles={[global.authItem]}
                                  insetShadow={false}
                                  errorMessage={errorMessages['phone_number'] || ''}
                                  errorMessageStyle={[global.authInputErrorText]}
                                  defaultValue={formData.phone_number}
                                />
                              </View>
                            </>
                      }
                    </View>
                    {isSigninUp ?
                      <Spinner color={red} />
                      :
                      <Button
                        disabled={isSigninUp || isFetchingInvite}
                        style={[global.buttonYellow, styles.signUpButton]}
                        onPress={this._signUp}
                      >
                        <Text
                          uppercase={false}
                          style={[global.buttonYellowText, styles.signUpButtonText]}
                        >
                          Sign Up
                        </Text>
                      </Button>
                    }
                    {!failedSignUp ? null :
                      <Text style={styles.errorMessage}>
                        {errorMessage}
                      </Text>
                    }
                    <View style={[styles.signInButtonContainer]}>
                      <Text style={[global.textLight, global.fontSF, styles.alreadyHaveAnAccount]}>
                        Already have an account?
                      </Text>
                      <Button transparent onPress={this._signIn}>
                        <Text uppercase={false} style={[global.textYellow, styles.signIn]}>
                          Sign in
                        </Text>
                      </Button>
                    </View>
                  </Body>
                </CardItem>
              </Card>
            </Col>
          </Grid>
        </ImageBackground>
      );
    }
    return null;

  }
}

const mapStateToProps = (state) => {
  return {
    invite: state.user.invite,
    isFetchingInvite: state.user.isFetchingInvite,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getInvite: (inviteCode) => dispatch(getInvite(inviteCode)),
  };
};

CustomSignUp.propTypes = {
  authState: PropTypes.string,
  authData: PropTypes.any,
  onStateChange: PropTypes.func,

  getInvite: PropTypes.func.isRequired,
  isFetchingInvite: PropTypes.bool.isRequired,
  invite: PropTypes.any,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomSignUp);


