import { createReducer } from "_redux/rootActions";

// Types
import {
  CLEAR_STATE,
  SITES_GET_REQUEST,
  SITES_GET_SUCCESS,
  SITES_GET_FAILURE,
  SITE_DELETE_REQUEST,
  SITE_DELETE_SUCCESS,
  SITE_DELETE_FAILURE,
  SITE_GET_REQUEST,
  SITE_GET_SUCCESS,
  SITE_GET_FAILURE,
  SITE_ARCHIVE_REQUEST,
  SITE_ARCHIVE_SUCCESS,
  SITE_ARCHIVE_FAILURE,
  SITE_UNARCHIVE_REQUEST,
  SITE_UNARCHIVE_SUCCESS,
  SITE_UNARCHIVE_FAILURE,
  CREATE_SITE_REQUEST,
  CREATE_SITE_SUCCESS,
  CREATE_SITE_FAILURE,
  EDIT_SITE_REQUEST,
  EDIT_SITE_SUCCESS,
  EDIT_SITE_FAILURE,
  SHARE_SITE_REQUEST,
  SHARE_SITE_SUCCESS,
  SHARE_SITE_FAILURE,
  SITE_SUB_DELETE_REQUEST,
  SITE_SUB_DELETE_SUCCESS,
  SITE_SUB_DELETE_FAILURE,
  SITE_ORDER_REQUEST,
  SITE_ORDER_SUCCESS,
  SITE_ORDER_FAILURE,
} from "_redux/site/types";

const initialState = {
  isSiteGetRequest: false,
  getRequestSuccess: false,
  isSiteDeleteRequest: false,
  siteDeleteSuccess: false,
  isSiteArchiveRequest: false,
  siteArchiveSuccess: false,
  isSiteunArchiveRequest: false,
  siteunArchiveSuccess: false,
  isSiteCreateRequest: false,
  siteCreateSuccess: false,
  isSiteEditRequest: false,
  siteEditSuccess: false,
  isSiteShareRequest: false,
  siteShareSuccess: false,
  isSiteSubscriptionRequest: false,
  siteSubscriptionSuccess: false,
  siteSubscriptionFail: false,
  isSiteOrderRequest: false,
  siteOrderSuccess: false,
};

export default createReducer(initialState, {
  // Clear state.
  [CLEAR_STATE]: () => initialState,

  // get Sites Details.
  [SITES_GET_REQUEST]: (state) => {
    return { ...state, isSiteGetRequest: true, getRequestSuccess: false };
  },
  [SITES_GET_SUCCESS]: (state, action) => {
    const { sitedata } = action;
    return {
      ...state,
      isSiteGetRequest: false,
      getRequestSuccess: true,
      sitedata,
    };
  },
  [SITES_GET_FAILURE]: (state) => {
    return { ...state, isSiteGetRequest: false, getRequestSuccess: false };
  },

  // get one Site Details.
  [SITE_GET_REQUEST]: (state) => {
    return { ...state, isSiteGetRequest: true, getRequestSuccess: false };
  },
  [SITE_GET_SUCCESS]: (state, action) => {
    const { onesitedata } = action;
    return {
      ...state,
      isSiteGetRequest: false,
      getRequestSuccess: true,
      onesitedata,
    };
  },
  [SITE_GET_FAILURE]: (state) => {
    return { ...state, isSiteGetRequest: false, getRequestSuccess: false };
  },

  // Site Delete.
  [SITE_DELETE_REQUEST]: (state) => {
    return { ...state, isSiteDeleteRequest: true, siteDeleteSuccess: false };
  },
  [SITE_DELETE_SUCCESS]: (state, action) => {
    const { detelesitedata } = action;
    return {
      ...state,
      isSiteDeleteRequest: false,
      siteDeleteSuccess: true,
      detelesitedata,
    };
  },
  [SITE_DELETE_FAILURE]: (state) => {
    return { ...state, isSiteDeleteRequest: false, siteDeleteSuccess: false };
  },

  // Site Archived.
  [SITE_ARCHIVE_REQUEST]: (state) => {
    return { ...state, isSiteArchiveRequest: true, siteArchiveSuccess: false };
  },
  [SITE_ARCHIVE_SUCCESS]: (state, action) => {
    const { archivesitedata } = action;
    return {
      ...state,
      isSiteArchiveRequest: false,
      siteArchiveSuccess: true,
      archivesitedata,
    };
  },
  [SITE_ARCHIVE_FAILURE]: (state) => {
    return { ...state, isSiteArchiveRequest: false, siteArchiveSuccess: false };
  },

  // Site unArchived.
  [SITE_UNARCHIVE_REQUEST]: (state) => {
    return {
      ...state,
      isSiteunArchiveRequest: true,
      siteunArchiveSuccess: false,
    };
  },
  [SITE_UNARCHIVE_SUCCESS]: (state, action) => {
    const { unarchivesitedata } = action;
    return {
      ...state,
      isSiteunArchiveRequest: false,
      siteunArchiveSuccess: true,
      unarchivesitedata,
    };
  },
  [SITE_UNARCHIVE_FAILURE]: (state) => {
    return {
      ...state,
      isSiteunArchiveRequest: false,
      siteunArchiveSuccess: false,
    };
  },

  // Site Create.
  [CREATE_SITE_REQUEST]: (state) => {
    return { ...state, isSiteCreateRequest: true, siteCreateSuccess: false };
  },
  [CREATE_SITE_SUCCESS]: (state, action) => {
    const { createsitedata } = action;
    return {
      ...state,
      isSiteCreateRequest: false,
      siteCreateSuccess: true,
      createsitedata,
    };
  },
  [CREATE_SITE_FAILURE]: (state) => {
    return { ...state, isSiteCreateRequest: false, siteCreateSuccess: false };
  },

  // Site Edit.
  [EDIT_SITE_REQUEST]: (state) => {
    return { ...state, isSiteEditRequest: true, siteEditSuccess: false };
  },
  [EDIT_SITE_SUCCESS]: (state, action) => {
    const { editsitedata } = action;
    return {
      ...state,
      isSiteEditRequest: false,
      siteEditSuccess: true,
      editsitedata,
    };
  },
  [EDIT_SITE_FAILURE]: (state) => {
    return { ...state, isSiteEditRequest: false, siteEditSuccess: false };
  },

  // Site order.
  [SITE_ORDER_REQUEST]: (state) => {
    return { ...state, isSiteOrderRequest: true, siteOrderSuccess: false };
  },
  [SITE_ORDER_SUCCESS]: (state, action) => {
    const { siteorderdata } = action;
    return {
      ...state,
      isSiteOrderRequest: false,
      siteOrderSuccess: true,
      siteorderdata,
    };
  },
  [SITE_ORDER_FAILURE]: (state) => {
    return { ...state, isSiteOrderRequest: false, siteOrderSuccess: false };
  },

  // Site Share.
  [SHARE_SITE_REQUEST]: (state) => {
    return { ...state, isSiteShareRequest: true, siteShareSuccess: false };
  },
  [SHARE_SITE_SUCCESS]: (state, action) => {
    const { sharesitedata } = action;
    return {
      ...state,
      isSiteShareRequest: false,
      siteShareSuccess: true,
      sharesitedata,
    };
  },
  [SHARE_SITE_FAILURE]: (state) => {
    return { ...state, isSiteShareRequest: false, siteShareSuccess: false };
  },

  // Site Subscription.
  [SITE_SUB_DELETE_REQUEST]: (state) => {
    return {
      ...state,
      isSiteSubscriptionRequest: true,
      siteSubscriptionSuccess: false,
      siteSubscriptionFail: false,
    };
  },
  [SITE_SUB_DELETE_SUCCESS]: (state, action) => {
    const { sitesubscriptiondeletedata } = action;
    return {
      ...state,
      isSiteSubscriptionRequest: false,
      siteSubscriptionSuccess: true,
      siteSubscriptionFail: false,
      sitesubscriptiondeletedata,
    };
  },
  [SITE_SUB_DELETE_FAILURE]: (state) => {
    return {
      ...state,
      isSiteSubscriptionRequest: false,
      siteSubscriptionSuccess: false,
      siteSubscriptionFail: true,
    };
  },
});
