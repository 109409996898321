import { createReducer } from '_redux/rootActions';

// Types
import {
  CLEAR_STATE,
  CLEAR_DEVICE_TELEMETRY,

  CONNECT_DEVICE_REQUEST,
  CONNECT_DEVICE_SUCCESS,
  CONNECT_DEVICE_FAILURE,

  DISCONNECT_DEVICE_REQUEST,
  DISCONNECT_DEVICE_SUCCESS,
  DISCONNECT_DEVICE_FAILURE,

  START_RECONNECT_DEVICE_REQUEST,
  START_RECONNECT_DEVICE_SUCCESS,
  START_RECONNECT_DEVICE_FAILURE,

  SET_RECONNECT_DEVICE_COMPLETE,

  SET_DEVICE_CONNECTED,
  SET_DEVICE_DISCONNECTED,

  GET_SET_POINTS_REQUEST,
  GET_SET_POINTS_FAILURE,

  SET_SET_POINTS_REQUEST,
  SET_SET_POINTS_FAILURE,

  UPDATE_SET_POINTS,

  EXECUTE_COMMAND_REQUEST,
  EXECUTE_COMMAND_FAILURE,

  UPDATE_DEVICE_NAME_REQUEST,
  UPDATE_DEVICE_NAME_SUCCESS,
  UPDATE_DEVICE_NAME_FAILURE,

  UPDATE_COMMAND_LOG,

  UPDATE_DEVICE_SUBSCRIPTION,

  UPDATE_TELEMETRY_DATA,

  SET_DEVICE_VISIBILITY_TO_FALSE,
  UPDATE_DISCONNECT_ERROR_TO_TRUE,
  UPDATE_DISCONNECT_ERROR_TO_FALSE,
  UPDATE_DISCONNECT_ERROR_VIEW_TO_TRUE,
  UPDATE_DISCONNECT_ERROR_VIEW_TO_FALSE,
} from '_redux/iot/types';


/**
 * IOT STATE SHAPE:
 *
 * iot: {
 *  devices: {
 *    'bf-device-123456': {
 *      subscription: <obj>,
 *      subscribedTo: [
 *        'telemetry/bazooka-farmstar/#',
 *        ...
 *      ],
 *      telemetry: {
 *      },
 *      setPoints: {
 *      },
 *      commandLog: []
 *    }
 *  }
 * }
 */
const initialState = {
  devices: {},
  isUpdatingDeviceName: false,
  disconnectError: false,
  disconnectErrorView: false,
};

export default createReducer(initialState, {
  // CLEAR STATE
  [CLEAR_STATE]: () => initialState,

  // CLEAR DEVICE TELEMETRY
  [CLEAR_DEVICE_TELEMETRY]: (state, action) => {
    const { deviceId } = action;

    return updateDevice(state, deviceId, { telemetry: {} });
  },

  // CONNECT DEVICE
  [CONNECT_DEVICE_REQUEST]: (state) => state,
  [CONNECT_DEVICE_SUCCESS]: (state) => state,
  [CONNECT_DEVICE_FAILURE]: (state, action) => {
    const { deviceId } = action;

    return updateDevice(state, deviceId, {
      subscription: null,
      subscribedTo: null,
      isConnected: false,
    });
  },

  // DISCONNECT DEVICE
  [DISCONNECT_DEVICE_REQUEST]: (state) => state,
  [DISCONNECT_DEVICE_FAILURE]: (state) => state,
  [DISCONNECT_DEVICE_SUCCESS]: (state, action) => {
    const { deviceId } = action;

    return updateDevice(state, deviceId, {
      telemetry: {},
      subscription: null,
      subscribedTo: null,
      commandLog: [],
    });
  },

  // RECONNECT DEVICE
  [START_RECONNECT_DEVICE_REQUEST]: (state) => state,
  [START_RECONNECT_DEVICE_FAILURE]: (state) => state,
  [START_RECONNECT_DEVICE_SUCCESS]: (state, action) => {
    const { deviceId, timerId } = action;

    return updateDevice(state, deviceId, { reconnectTimerId: timerId });
  },

  // SET RECONNECT DEVICE COMPLETE
  [SET_RECONNECT_DEVICE_COMPLETE]: (state, action) => {
    const { deviceId } = action;
    return updateDevice(state, deviceId, { reconnectTimerId: null });
  },


  // SET DEVICE CONNECTED/DISCONNECTED
  [SET_DEVICE_CONNECTED]: (state, action) => {
    const { deviceId } = action;

    return updateDevice(state, deviceId, {
      isConnected: true,
      isConnectedDeviceVisible: true,
    });
  },
  [SET_DEVICE_DISCONNECTED]: (state, action) => {
    const { deviceId } = action;

    return updateDevice(state, deviceId, { isConnected: false });
  },

  [SET_DEVICE_VISIBILITY_TO_FALSE]: (state, action) => {
    const { deviceId } = action;

    return updateDevice(state, deviceId, { isConnectedDeviceVisible: false });
  },

  // UPDATE DEVICE NAME
  //A "disconnectError" relates to the users laptop/tablet/etc.
  //not being able to connect to the internet.
  [UPDATE_DISCONNECT_ERROR_TO_TRUE]: (state) => {
    return { ...state, disconnectError: true };
  },

  // UPDATE DEVICE NAME
  [UPDATE_DISCONNECT_ERROR_TO_FALSE]: (state) => {
    return { ...state, disconnectError: false };
  },

  //UPDATE DEVICE NAME
  //A "disconnectViewError" relates to the users laptop/tablet/etc.
  //not being able to connect to the internet and is used to disable
  //view after a timeout or if attempting to use the dashboard
  //while their side isn't connected.
  [UPDATE_DISCONNECT_ERROR_VIEW_TO_TRUE]: (state) => {
    return { ...state, disconnectErrorView: true };
  },

  // UPDATE DEVICE NAME
  [UPDATE_DISCONNECT_ERROR_VIEW_TO_FALSE]: (state) => {
    return { ...state, disconnectErrorView: false };
  },

  // SET SET POINTS
  [SET_SET_POINTS_REQUEST]: (state) => state,
  [SET_SET_POINTS_FAILURE]: (state) => state,

  // GET SET POINTS
  [GET_SET_POINTS_REQUEST]: (state) => state,
  [GET_SET_POINTS_FAILURE]: (state) => state,

  // UPDATE SET POINTS
  [UPDATE_SET_POINTS]: (state, action) => {
    const { deviceId, setPoints } = action;

    return updateDevice(state, deviceId, { setPoints });
  },

  // EXECUTE COMMAND
  [EXECUTE_COMMAND_REQUEST]: (state, action) => {
    const { deviceId, command } = action;

    return updateCommandLog(state, {
      deviceId,
      logEntry: {
        command,
        status: 'requested',
        timestamp: (new Date()).getTime(),
      },
    });
  },
  [EXECUTE_COMMAND_FAILURE]: (state) => state,

  // UPDATE DEVICE NAME
  [UPDATE_DEVICE_NAME_REQUEST]: (state) => {
    return { ...state, isUpdatingDeviceName: true };
  },
  [UPDATE_DEVICE_NAME_SUCCESS]: (state) => {
    return { ...state, isUpdatingDeviceName: false };
  },
  [UPDATE_DEVICE_NAME_FAILURE]: (state) => {
    return { ...state, isUpdatingDeviceName: false };
  },

  // UPDATE COMMAND LOG
  [UPDATE_COMMAND_LOG]: (state, action) => {
    const { deviceId, logEntry } = action;

    // Get the current command log or use an empty array if this
    // is the first log entry.
    const commandLog = state.devices[deviceId].commandLog || [];

    // Create a brand new array with the log entry appended to be redux safe.
    const newCommandLog = [
      ...commandLog,
      logEntry,
    ];

    return updateDevice(state, deviceId, { commandLog: newCommandLog });
  },

  // UPDATE DEVICE SUBSCRIPTION
  [UPDATE_DEVICE_SUBSCRIPTION]: (state, action) => {
    const { deviceId, subscription, subscriptionList } = action;

    return updateDevice(state, deviceId, {
      subscription,
      subscribedTo: subscriptionList
    });
  },

  // UPDATE TELEMETRY DATA
  [UPDATE_TELEMETRY_DATA]: (state, action) => {
    const { deviceId, telemetry } = action;

    return updateDevice(state, deviceId, { telemetry });
  },

});

/**
 * Makes an update to a device in a state in a redux
 * safe way.
 *
 * @param {object} state current state.
 * @param {string} deviceId id of the device to update.
 * @param {object} updates object of updates to merge to the device object.
 */
const updateDevice = (state, deviceId, updates) => {
  return {
    ...state,
    devices: {
      ...state.devices,
      [deviceId]: { ...state.devices[deviceId], ...updates },
    },
  };
};

/**
 * Will append a new command log to the commandLog for
 * a device.
 *
 * @param {object} state current state
 * @param {object} action action details
 */
const updateCommandLog = (state, action) => {
  const { deviceId, logEntry } = action;

  // Get the current command log or use an empty array if this
  // is the first log entry.
  const commandLog = state.devices[deviceId].commandLog || [];

  // Create a brand new array with the log entry appended to be redux safe.
  const newCommandLog = [
    ...commandLog,
    logEntry,
  ];

  return updateDevice(state, deviceId, { commandLog: newCommandLog });
};

