const actionPrefix = "app/siteMap";

export const CLEAR_STATE = `${actionPrefix}/CLEAR_STATE`;
export const INIT_SITEMAP = `${actionPrefix}/INIT_SITEMAP`;
export const MAP_FULL_SCREEN = `${actionPrefix}/MAP_FULL_SCREEN`;

export const SITEMAP_SAVE_REQUEST = `${actionPrefix}/SITEMAP_SAVE_REQUEST`;
export const SITEMAP_SAVE_SUCCESS = `${actionPrefix}/SITEMAP_SAVE_SUCCESS`;
export const SITEMAP_SAVE_FAILEAR = `${actionPrefix}/SITEMAP_SAVE_FAILEAR`;

export const SITEMAP_LOAD_REQUEST = `${actionPrefix}/SITEMAP_LOAD_REQUEST`;
export const SITEMAP_LOAD_SUCCESS = `${actionPrefix}/SITEMAP_LOAD_SUCCESS`;
export const SITEMAP_LOAD_FAILEAR = `${actionPrefix}/SITEMAP_LOAD_FAILEAR`;
