// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import {
  iotDeviceIsConnectedVisibility,
  isAlarmOnForDevice,
  isWarningOnForDevice,
  isEngineRunningForDevice,
}
  from '_redux/iot/selectors';
import { isUserFetchingDeviceCardSettings } from '_redux/dashboard/selectors';

// Components
import CurrentEngine from '_components/device_card/CurrentEngine.component';
import MiniCurrentEngine from '_components/device_card/MiniCurrentEngine.component';
import DeviceSettings from '_components/device_settings/DeviceSettings.component';
import SignalStrength from '_components/device_card/MiniSignalStrength.component';


// React-Native
import { Image, TouchableOpacity } from 'react-native';

// Native Base
import {
  Card,
  CardItem,
  Spinner,
  Text,
  View,
} from 'native-base';

import { styles as global, card_yellow, card_gray, card_red, card_green } from '_style/Global.style';
import { styles } from '_components/device_card/MiniDeviceCard.style';

class MiniDeviceCard extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showSettings: false,
    };

    this._handleSettingsButtonPress = this._handleSettingsButtonPress.bind(this);
    this._handleCloseSettingsButtonPress = this._handleCloseSettingsButtonPress.bind(this);
  }

  _handleSettingsButtonPress() {
    this.setState({
      showSettings: true,
    });
  }

  _handleCloseSettingsButtonPress() {
    this.setState({
      showSettings: false,
    });
  }

  render() {

    const {
      deviceId,
      deviceName,
      deviceIsConnected,
      disabled = false,
      isFetchingCardSettings,
      isAlarmOnForDevice,
      isEngineRunningForDevice,
      isWarningOnForDevice,
      disconnectError,
      disconnectErrorView,
    } = this.props;
    const { showSettings } = this.state;

    let headerColor = card_gray;
    if (isEngineRunningForDevice) headerColor = card_green;
    if (isWarningOnForDevice) headerColor = card_yellow;
    if (isAlarmOnForDevice) headerColor = card_red;

    return (
      <Card style={[global.card, styles.deviceCard]}>
        {
          disabled &&
          <View style={[styles.disabledContainer]}>
            <Text style={[styles.disabledText]}>This device has been suspended.</Text>
          </View>
        }
        {
          !deviceIsConnected &&
          <View style={[global.disconnectedContainer]}>
            <Spinner color='white' />
          </View>
        }
        {(disconnectError || disconnectErrorView) &&
          <View style={[global.disconnectedErrorContainer]}></View>
        }
        {/* Header */}
        <CardItem header style={[styles.deviceCardTitle, { backgroundColor: headerColor }]}>

          {/* Device Id */}
          <Text style={[
            global.fontRobotoCondensedBold,
            global.textDark,
            styles.deviceCardTitleText
          ]}>
            {deviceName || deviceId}
          </Text>

          {/* Signal & Settings */}
          <View style={[styles.rightContainer]}>
            {
              deviceIsConnected ?
                <SignalStrength strength={5} />
                :
                null
            }
          </View>
        </CardItem>

        {/* Current Engine & Settings */}
        <CardItem style={[styles.deviceCardContent]}>
          {
            showSettings ?
              <DeviceSettings deviceId={deviceId} />
              :
              <MiniCurrentEngine deviceId={deviceId} />
          }
        </CardItem>
      </Card>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    deviceIsConnected: iotDeviceIsConnectedVisibility(state, ownProps.deviceId),
    isFetchingCardSettings: isUserFetchingDeviceCardSettings(state, ownProps.deviceId),
    isAlarmOnForDevice: isAlarmOnForDevice(state, ownProps.deviceId),
    isWarningOnForDevice: isWarningOnForDevice(state, ownProps.deviceId),
    isEngineRunningForDevice: isEngineRunningForDevice(state, ownProps.deviceId),
    disconnectError: state.iot.disconnectError,
    disconnectErrorView: state.iot.disconnectErrorView,
  };
};

MiniDeviceCard.propTypes = {
  deviceId: PropTypes.string.isRequired,
  deviceName: PropTypes.string,
  deviceIsConnected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  isFetchingCardSettings: PropTypes.bool.isRequired,
  isAlarmOnForDevice: PropTypes.bool.isRequired,
  isWarningOnForDevice: PropTypes.bool.isRequired,
  isEngineRunningForDevice: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(MiniDeviceCard);
