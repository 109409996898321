// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Image } from 'react-native';

// Redux
import { connect } from 'react-redux';

// Native Base
import { styles } from '_components/device_card/MiniSignalStrength.style';

class SignalStrength extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Image
        style={[styles.signalStrength]}
        source={require('_assets/images/network-signal-5.png')}
      />
    );
  }
}

SignalStrength.propTypes = {
  strength: PropTypes.number.isRequired,
};

export default connect(null, null)(SignalStrength);
