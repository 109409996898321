
/**
 * Finds a job by its id.
 *
 * @param {*} state current state
 * @param {*} jobId job id to find
 */
export const getJobById = (jobs, jobId) => {
  return jobs.find((job) => job.job_id === jobId);
};

/**
 * Gets a single device out of the state by id.
 *
 * @param {object} state current state
 * @param {string} deviceId id of the device
 *
 * @returns {object}
 */
export const getUserDeviceById = (state, deviceId) => {
  return state.dashboard.devices.find((device) => device['device_id'] === deviceId);
};

/**
 *
 * @param {*} state
 * @param {*} deviceId
 */
export const getUserDeviceCardSettingsById = (state, deviceId) => {
  const device = getUserDeviceById(state, deviceId);
  const { card_settings = {}} = device || {};

  return card_settings;
};

/**
 * Returns true if there is a request to get a particular device card
 * settings. False otherwise.
 *
 * @param {*} state
 * @param {*} deviceId
 */
export const isUserFetchingDeviceCardSettings = (state, deviceId) => {

  return state.dashboard.isFetchingUserDeviceCardSettings[deviceId] ? true : false;
};

/**
 *
 * @param {*} state
 */
export const getLastViewedJobId = (state) => {
  return state.dashboard.lastViewedJobId;
};
