import { StyleSheet } from "react-native";
export const lighter_gray = "#B3B3B3";

const buttonText = {
  marginLeft: "auto",
  marginRight: "auto",
};

export const styles = StyleSheet.create({
  confirmRemovalModalContent: {
    width: 425,
    marginLeft: 75,
    marginRight: 75,
  },
  confirmRemovalText: {
    textAlign: "center",
    color: "#EEB310",
    fontFamily: "Roboto",
    Weight: 700,
    marginTop: 20,
    fontSize: 24,
    letterSpacing: 1.6,
  },
  btnstyle: {
    height: 40,
    marginTop: 40,
    marginBottom: 15,
  },
  cancelButtonText: {
    ...buttonText,
    color: "#FFFFFF",
    fontSize: 18,
    Weight: 700,
  },
});
