import React, { Component } from "react";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  LoadScript,
  DrawingManager,
} from "@react-google-maps/api";
import { Col, Row, Grid } from "react-native-easy-grid";
import { Text, Button, Spinner } from "native-base";
import { navigate } from "_routes/RootNavigation";

import OptionPopup from "_components/create_site/Archive.component";
const containerStyle = {
  height: "100%",
  width: "100%",
};

import ConfirmSuccess from "_components/common/SiteSuccessModal.component";
import { styles as global } from "_style/Global.style";
import {
  Image,
  StyleSheet,
  TouchableOpacity,
  View,
  AsyncStorage,
} from "react-native";
import FormInput from "_components/common/FormInput.component";
import IconButtons from "../components/common/IconButtons";
import { styles as iconButtonsStyles } from "../components/common/IconButtons.style";
import { styles } from "./SiteMapEdit.style";
import { styles2 } from "./ShowMap.style";
import { Images } from "../assets/";
import EditButtonComponent from "../components/common/EditButton.component";
import Modal from "_components/common/Modal.component";
import SitePage from "./Site.page";
import Sharesitepage from "_components/create_site/ShareSite.component";

import { connect } from "react-redux";
import SiteMapEditModal from "_components/create_site/SiteMapEditModal";
import AccessControl, {
  ADMIN,
  MANUFACTURER,
  CREW_LEADER,
  CREW_MEMBER,
  DEALER,
  OWNER,
} from "_components/auth/AccessControl.component";

class ShowMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ShowSiteEditModal: false,
      ShowConfirmModal: false,
      EditSite: false,
      BackToPre: false,
      ShowOptionModal: false,
      SiteShareModal: false,
      location: "",
      isLoaded: false,
      zoom: 10,
    };
    //Bind the functions
  }
  componentDidMount() {
    this.setState({ location: this.props.route.params.location });
    this.setState({ zoom: this.props.route.params.zoom || 14 });
    if (this.props.route.params.location) {
      setTimeout(() => {
        this.setState({ isLoaded: true });
      }, 1000);
    }
  }

  _closeOptionModal = () => {
    this.setState({ ShowOptionModal: false });
  };

  _CloseAndSave = () => {
    this.setState({ ShowConfirmModal: true, ShowOptionModal: false });
  };

  _CloseShareModal = () => {
    this.setState({ SiteShareModal: false });
  };

  showEditModal = () => {
    return (
      <Modal
        isVisible={this.state.ShowSiteEditModal}
        title={"EDIT DETAILS"}
        subTitle={"Update the  job Site Details"}
        onClose={() => this.setState({ ShowSiteEditModal: false })}
        content={
          this.state.ShowSiteEditModal ? (
            <SiteMapEditModal
              onClick={() =>
                this.setState({
                  ShowConfirmModal: true,
                  ShowSiteEditModal: false,
                })
              }
              onClose={() =>
                this.setState({
                  ShowOptionModal: true,
                  ShowSiteEditModal: false,
                })
              }
            />
          ) : null
        }
      />
    );
  };

  confirmModal = () => {
    return (
      <Modal
        isVisible={this.state.ShowConfirmModal}
        hideTitle={true}
        hideExitIcon={true}
        content={
          this.state.ShowConfirmModal ? (
            <ConfirmSuccess
              title={"SUCCESS"}
              subTitle={"YOU HAVE UPDATED \n THE SITE DETAILS"}
              onClose={() => this.setState({ ShowConfirmModal: false })}
              buttonText={"CLOSE"}
            />
          ) : null
        }
      />
    );
  };

  CloseOptionModal = () => {
    return (
      <Modal
        isVisible={this.state.ShowOptionModal}
        title={"SAVE DETAILS"}
        hideExitIcon={true}
        content={
          this.state.ShowOptionModal ? (
            <OptionPopup
              onCancel={() => this._closeOptionModal}
              submitButtonText={"SAVE DETAILS"}
              confirmationText={
                "Would you like to save the details? \n All unsaved changes will be lost"
              }
              submit={() => this._CloseAndSave}
              cancelButtonText={"CANCEL"}
            />
          ) : null
        }
      />
    );
  };

  sharesiteModal = () => {
    return (
      <Modal
        isVisible={this.state.SiteShareModal}
        title={"SHARE SITE"}
        subTitle={"Share youre job site to other users via"}
        hideExitIcon={false}
        onClose={() => this.setState({ SiteShareModal: false })}
        content={
          this.state.SiteShareModal ? (
            <Sharesitepage
              confirmationText={"Job site link"}
              submit={() => this._CloseShareModal}
              submitButtonText={"DONE"}
            />
          ) : null
        }
      />
    );
  };
  onLoad = (marker) => {
    console.log("marker: ", marker);
  };

  render() {
    if (this.state.BackToPre) {
      return <SitePage />;
    }
    return (
      <Grid style={[styles.mainContainer]}>
        <Col style={[styles.subContainer, styles2.bRadius]} size={9}>
          <View>
            {this.sharesiteModal()}
            {this.showEditModal()}
            {this.confirmModal()}
            {this.CloseOptionModal()}
          </View>
          <View style={[styles2.mainViewContainer]}>
            <Row size={1} style={[styles.toolBox, styles2.rowMainContent]}>
              <View>
                <View style={[styles2.topContent]}>
                  <TouchableOpacity
                    onClick={() => navigate("Sites")}
                    //onClick={() => this.setState({ BackToPre: true })}
                    style={styles.backButton}
                  >
                    <View style={[styles.backButtonCont]}>
                      <Image
                        source={require("_assets/images/back-icon.svg")}
                        style={[styles.backButtonImg]}
                      />
                      <Text style={styles.backButtonText}>BACK</Text>
                    </View>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={styles2.editButton}
                    onClick={() =>
                      navigate("Site", {
                        location: this.state.location,
                        zoom: this.state.zoom,
                      })
                    }
                  >
                    <AccessControl
                      allowedGroups={[
                        ADMIN,
                        MANUFACTURER,
                        CREW_LEADER,
                        DEALER,
                        OWNER,
                      ]}
                    >
                      <View style={[styles.backButtonCont]}>
                        <Image
                          source={require("_assets/images/edit-icon.png")}
                          style={[styles2.editButtonImg]}
                        />
                        <Text style={styles2.editButtonText}>EDIT MAP</Text>
                      </View>
                    </AccessControl>
                    <AccessControl allowedGroups={[CREW_MEMBER]}>
                      <View style={[styles.backButtonCont]}>
                        <Image
                          source={require("_assets/images/group-icon.svg")}
                          style={[styles2.groupButtonImg]}
                        />
                        <Text style={styles2.editButtonText}>VIEW GROUPS</Text>
                      </View>
                    </AccessControl>
                  </TouchableOpacity>
                </View>
              </View>
            </Row>
            <Row size={8} style={[styles.mapCont, styles2.mapsubContent]}>
              {!this.state.isLoaded ? (
                <View style={{ flex: 1, alignItems: "center" }}>
                  <Spinner color={"red"} />
                </View>
              ) : (
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={this.state.location}
                  zoom={this.state.zoom}
                >
                  <Marker position={this.state.location} />
                </GoogleMap>
              )}
            </Row>
          </View>
        </Col>
      </Grid>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isFetchingUserInfo: state.user.isFetchingUserInfo,
  };
};
export default connect(mapStateToProps, null)(ShowMap);
