export const IMAGE_PATH = "./images";
///Users/mithun/test/SpadesAdminMobileApp/src/assets/images/spadesapp_white.png
export const Images = {
  EYE_ICON: require(`${IMAGE_PATH}/eye-icon.svg`),
  CLICK_EYE_ICON: require(`${IMAGE_PATH}/click-eye-icon.svg`),
  CREATE_ICON: require(`${IMAGE_PATH}/create-icon.svg`),
  UNDO_ICON: require(`${IMAGE_PATH}/undo-icon.svg`),
  REDO_ICON: require(`${IMAGE_PATH}/redo-icon.svg`),
  NOTICE_IMAGE: require(`${IMAGE_PATH}/notice-icon.svg`),
  SUCCESS_IMAGE: require(`${IMAGE_PATH}/success-check.png`),
  UNARCHIVE_ICON: require(`${IMAGE_PATH}/unarchive-icon.svg`),
  ICON__DELETE: require(`${IMAGE_PATH}/icon__delete.png`),
  ICON_BIN: require(`${IMAGE_PATH}/DELETE.svg`),
  ICON_RESTORE: require(`${IMAGE_PATH}/RESTORE.svg`),
  ICON_VIEW: require(`${IMAGE_PATH}/VIEW.svg`),
  ICON__EDIT: require(`${IMAGE_PATH}/edit-icon.png`),
  CULVERT_ICO: require(`${IMAGE_PATH}/tool_icon/Culvert-ico.svg`),
  CULVERT_ICO_OFF: require(`${IMAGE_PATH}/tool_icon/Culvert.png`),
  CULVERT_ICO_ON: require(`${IMAGE_PATH}/tool_icon/Culvert_On.png`),
  FIELD_ICO: require(`${IMAGE_PATH}/tool_icon/Field-ico.png`),
  HOSE_ICO: require(`${IMAGE_PATH}/tool_icon/Hose-ico.svg`),
  OBSTRACLE_ICO: require(`${IMAGE_PATH}/tool_icon/Obstracle-ico.png`),
  PIPE_LINE_ICO: require(`${IMAGE_PATH}/tool_icon/pipe-line-ico.png`),
  PIT_ICO: require(`${IMAGE_PATH}/tool_icon/Pit-ico.png`),
  PUMP_ICO: require(`${IMAGE_PATH}/tool_icon/Pump-ico.svg`),
  PUMP_ICO_OFF: require(`${IMAGE_PATH}/tool_icon/Pump.png`),
  PUMP_ICO_ON: require(`${IMAGE_PATH}/tool_icon/Pump_On.png`),
  RISER_PIPE_ICO: require(`${IMAGE_PATH}/tool_icon/Riser-Pipe-Ico.svg`),
  RISER_PIPE_ICO_OFF: require(`${IMAGE_PATH}/tool_icon/Riser.png`),
  RISER_PIPE_ICO_ON: require(`${IMAGE_PATH}/tool_icon/Riser_On.png`),
  SET_LAYOUT_ICO: require(`${IMAGE_PATH}/tool_icon/Set-Layout-ico.png`),
  LBL_IMG_ICO: require(`${IMAGE_PATH}/label_marker.png`),
  DOC_MENU: require(`${IMAGE_PATH}/docMenu.svg`),
  SHARE_ICON: require(`${IMAGE_PATH}/share-icon.png`),
  NOTE_ICON: require(`${IMAGE_PATH}/Notes.svg`),
  MENU_SAHRE_ICON: require(`${IMAGE_PATH}/menuShare.svg`),
  MENU_MAP_ICON: require(`${IMAGE_PATH}/menuMap.svg`),
  MENU_EDIT: require(`${IMAGE_PATH}/EDIT.svg`),
  EDIT_NOTES: require(`${IMAGE_PATH}/EditNotes.svg`),
  OTHER_ICO: require(`${IMAGE_PATH}/tool_icon/Other.png`),
  OTHER_ICO_OFF: require(`${IMAGE_PATH}/tool_icon/Other-icon.png`),
  OTHER_ICO_ON: require(`${IMAGE_PATH}/tool_icon/Other-icon_On.png`),
  MENU_SITE: require(`${IMAGE_PATH}/menu_site.svg`),
  UP_ARROW: require(`${IMAGE_PATH}/upArrow.svg`),
  DOWN_ARROW: require(`${IMAGE_PATH}/downArrow.svg`),
  UP_ARROW_DISABLED: require(`${IMAGE_PATH}/upArrow_disabled.svg`),
  DOWN_ARROW_DISABLED: require(`${IMAGE_PATH}/downArrow_disabled.svg`),
};
