import { StyleSheet } from 'react-native';
import {
  roboto
} from '_style/Global.style';

export const styles = StyleSheet.create({
  valvesContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    width: 208,
  },
  valvesRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: '#424242',
    width: 208,
    paddingTop: 2,
    paddingBottom: 2,
  },
  valveLabel: {
    fontWeight: 'bold',
    fontFamily: roboto,
    fontSize:14,
  },
  auxilaryButtonContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 3,
  },
  auxilaryButtonOff: {
    width: 211,
    height: 39,
  },
  auxilaryButtonOn: {
    width: 200,
    height: 28,
  }
});
