// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Image, ImageBackground, TouchableOpacity } from 'react-native';

// Redux
import { connect } from 'react-redux';

// Native Base
import {
  View,
  Text,
} from 'native-base';


import { styles as global } from '_style/Global.style';
import { styles } from '_components/device_card/OnOffGroup.style';

class OnOffGroup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      on: props.on,
    };

    this._onOn = this._onOn.bind(this);
    this._onOff = this._onOff.bind(this);
  }

  _onOn() {
    const { onOn } = this.props;
    this.setState({
      on: true,
    });
    onOn();
  }

  _onOff() {
    const { onOff } = this.props;
    this.setState({
      on: false,
    });
    onOff();
  }

  render() {
    const { on } = this.state;

    return (
      <View style={[styles.onOffGroupContainer]}>
        {
          on ?
            <>
              <TouchableOpacity onPress={this._onOff}>
                <ImageBackground
                  style={[styles.onOffButton]}
                  source={require('_assets/images/open-closed-button-off.png')}
                >
                  <Text style={[styles.onOffButtonText, styles.onOffButtonOffText]}>OFF</Text>
                </ImageBackground>
              </TouchableOpacity>

              <TouchableOpacity disabled>
                <ImageBackground
                  style={[styles.onOffButton]}
                  source={require('_assets/images/open-closed-button-open-on.png')}
                >
                  <Text style={[styles.onOffButtonText, styles.onOffButtonOnText]}>ON</Text>
                </ImageBackground>
              </TouchableOpacity>
            </>
            :
            <>
              <TouchableOpacity disabled>
                <ImageBackground
                  style={[styles.onOffButton]}
                  source={require('_assets/images/open-closed-button-closed-on.png')}
                >
                  <Text style={[styles.onOffButtonText, styles.onOffButtonOnText]}>OFF</Text>
                </ImageBackground>
              </TouchableOpacity>

              <TouchableOpacity onPress={this._onOn}>
                <ImageBackground
                  style={[styles.onOffButton]}
                  source={require('_assets/images/open-closed-button-off.png')}
                >
                  <Text style={[styles.onOffButtonText, styles.onOffButtonOffText]}>ON</Text>
                </ImageBackground>
              </TouchableOpacity>
            </>
        }
      </View>
    );
  }
}

OnOffGroup.propTypes = {
  onOn: PropTypes.func.isRequired,
  onOff: PropTypes.func.isRequired,
  on: PropTypes.bool.isRequired,
};

export default connect(null, null)(OnOffGroup);
