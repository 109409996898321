import React from "react";
import { Text, Button, Icon, Accordion } from "native-base";
import { Image, TouchableOpacity, View } from "react-native";
import CreateGroupButton from "../common/CreateGroupButton";
import { styles } from "./MapEditor.style";
import { genGUID } from "_util";
import { Images } from "../../assets";
import { DrawElementConst, ToolTypes } from "./mapUtil";

import AccessControl, {
  ADMIN,
  MANUFACTURER,
  CREW_LEADER,
  CREW_MEMBER,
  DEALER,
  OWNER,
} from "_components/auth/AccessControl.component";
import GroupComponent from "./GroupAccordian";
import GroupAccordian from "./GroupAccordian";

const GroupPanel = ({
  drawData,
  groups,
  selected,
  groupsChanged,
  selectedTab,
  onChangeGroup,
  onSelectLayer,
  onSelectGroup,
  onChangeGroupVisibility,
  onGroupEdit,
}) => {
  const [drawLayers, setDrawLayers] = React.useState([]);
  const [groupData, setGroupData] = React.useState([]);
  const [groupAccordianData, setGroupAccordianData] = React.useState([]);
  const [unGroupAccordianData, setUnGroupAccordianData] = React.useState([]);

  React.useEffect(() => {
    if (selectedTab === 1) {
      if (selected === null) {
        const selectedItem = drawData.find((x) => x.selected);
        selectedItem && delete selectedItem.selected;
      }
      setDrawLayers(drawData);
      setGroupData(groups);
      constructGroupedDataArray();
      constructUnGroupedDataArray();
    }
  }, [drawData, selected, groups, groupsChanged, selectedTab]);

  function constructUnGroupedDataArray() {
    if (drawData) {
      const drawItems = drawData
        .filter(
          (layer) => !layer.group || (layer.group && layer.group.length === 0)
        )
        .filter(
          (layer) =>
            layer.drawType !== ToolTypes.PIT &&
            layer.drawType !== ToolTypes.OBSTACLE
        );
      const myData = [
        {
          name: "UNGROUPED ITEMS",
          selected: true,
          items: drawItems,
          expanded: drawItems.length > 0,
        },
      ];
      setUnGroupAccordianData(myData);
    }
  }

  function constructGroupedDataArray() {
    if (groupsChanged || (groups && drawData)) {
      const myData = groups.map((group) => {
        const newGroup = group;
        const inGroups = drawData.filter((layer) =>
          layer.group && layer.group.find((groupId) => groupId === group.id)
        );
        newGroup.items = inGroups;
        return newGroup;
      });
      setGroupAccordianData(myData);
    }
  }

  const onNewGroup = () => {
    if (
      !!selected &&
      selected.type === DrawElementConst.group &&
      selected.state === DrawElementConst.EDIT
    ) {
      return;
    }
    groupData.forEach((x) => {
      if (x.selected) delete x.selected;
    });
    const tempGroup = Object.assign([], groupData);
    tempGroup.push({
      id: genGUID(),
      type: DrawElementConst.group,
      name: "group(" + tempGroup.length + ")",
      state: DrawElementConst.NEW,
      newGroup: true,
      calulatedValues: {
        totalArea: null,
      },
    });
    setGroupData(tempGroup);
    onChangeGroup(tempGroup);
    const newlyAddedGroup = tempGroup[tempGroup.length - 1];
    newlyAddedGroup.state = DrawElementConst.EDIT;
    newlyAddedGroup.selected = true;
    onSelectGroup(newlyAddedGroup);
  };

  const onSelectedLayer = (item) => {
    onSelectLayer(item);
  };

  const hideUnHideGroup = (group) => {
    if (
      !!selected &&
      selected.type === DrawElementConst.group &&
      selected.state === DrawElementConst.EDIT
    ) {
      return;
    }
    group.visible = !group.visible;
    onChangeGroupVisibility(group);
  };

  const onSelectedGroup = (item) => {
    if (
      !!selected &&
      selected.type === DrawElementConst.group &&
      selected.state === DrawElementConst.EDIT
    ) {
      return;
    }
    groupData.forEach((x) => {
      if (x.selected) delete x.selected;
    });
    setGroupData(groupData);
    item.state = "VIEW";
    item.selected = true;
    onSelectGroup(item);
  };

  const onEditGrp = (group) => {
    if (
      !!selected &&
      selected.type === DrawElementConst.group &&
      selected.state === DrawElementConst.EDIT
    ) {
      return;
    }
    const tempGroup = Object.assign([], groupData);
    tempGroup.forEach((x) => {
      if (x.selected) delete x.selected;
    });
    group.selected = true;
    group.state = DrawElementConst.EDIT;
    const findIndex = tempGroup.findIndex((grp) => grp.id === group.id);
    if (findIndex > -1) {
      tempGroup.splice(findIndex, 1, group);
    }
    setGroupData(tempGroup);
    onChangeGroup(tempGroup);
    onSelectGroup(group);
    onGroupEdit(group.id, null, DrawElementConst.EDIT);
  };

  return (
    <>
      <View style={{ alignItems: "center", marginTop: 14 }}>
        <AccessControl
          allowedGroups={[ADMIN, MANUFACTURER, CREW_LEADER, DEALER, OWNER]}
        >
          <CreateGroupButton
            buttonWithText={true}
            label={"Create Group"}
            imageName={"CREATE_ICON"}
            onPress={() => onNewGroup()}
          />
        </AccessControl>
      </View>
      <View
        style={{
          alignItems: "left",
          maxHeight: "75vh",
          overflowY: "hidden",
        }}
      >
        <AccessControl
          allowedGroups={[ADMIN, MANUFACTURER, CREW_LEADER, DEALER, OWNER]}
        >
          <View
            style={{
              alignItems: "left",
              height: "20vh",
              overflowY: "auto",
              marginLeft: 10,
            }}
          >
            <GroupAccordian
              leftIcon={false}
              rightIcon={false}
              iconColor={"#9F9F9F"}
              maxHeight={20}
              onSelectedGroup={() => {}}
              headerStyles={{ width: "10vw", color: "#9F9F9F" }}
              dataArray={unGroupAccordianData}
              onSelectedLayer={onSelectedLayer}
            />
          </View>
        </AccessControl>

        <View
          style={{
            alignItems: "left",
            maxHeight: "55vh",
            overflowY: "auto",
            marginLeft: 10,
          }}
        >
          <GroupAccordian
            leftIcon={true}
            rightIcon={true}
            dataArray={groupAccordianData}
            onSelectedGroup={onSelectedGroup}
            hideUnHideGroup={hideUnHideGroup}
            onSelectedLayer={onSelectedLayer}
            onEditGrp={onEditGrp}
          />
        </View>
      </View>
    </>
  );
};
export default React.memo(GroupPanel);
