import React, {
  Component,
} from 'react';

const API_HOST = "https://tcd5fhp4g6.execute-api.us-east-1.amazonaws.com/dev/sites/";

export class SiteAPI extends Component {

  constructor(props) {
    super(props);
    //initPromises.push(this.getToken());
    this.state = {

    }
  }
  async fetchSiteData(cd) {
    return await fetch(API_HOST,
      { method: 'GET' })
      .then(res => res.json())
      .then(
        (result) => {
          return cd(result)
        },
        (error) => {
          return cd(error)
        }
      )
  }

  async getSiteData(value, cd) {
    return await fetch(API_HOST + value.siteID,
      { method: 'GET' })
      .then(res => res.json())
      .then(
        (result) => {
          return cd(result)
        },
        (error) => {
          return cd(error)
        }
      )
  }

  async deleteSiteitem(value, cd) {
    return await fetch(API_HOST + value.data.siteId,
      { method: 'DELETE' })
      .then(res => res.json())
      .then(
        (result) => {
          return cd(result)
        },
        (error) => {
          return cd(error)
        }
      )
  }
  async archivedSiteitem(value, cd) {
    const formdata = { "status": "ARCHIVED" }

    return await fetch(API_HOST + value.data.siteId,
      {
        method: 'PATCH',
        body: JSON.stringify(formdata),
      })
      .then(res => res.json())
      .then(
        (result) => {
          return cd(result)
        },
        (error) => {
          return cd(error)
        }
      )
  }

  async unarchivedSiteitem(value, cd) {
    var formdata = { "status": "ACTIVE" }
    return await fetch(API_HOST + value.data.siteId,
      {
        method: 'PATCH',
        body: JSON.stringify(formdata),
      })
      .then(res => res.json())
      .then(
        (result) => {
          return cd(result)
        },
        (error) => {
          return cd(error)
        }
      )
  }

  async updateSiteDetails(val, cd) {
    var formdata = {
      "siteName": val.data.site_name,
      "siteManager": {
        "name": val.data.manager,
        "email": val.data.email,
        "phone": val.data.phone_number,
      },
      "siteAddress": {
        "place": val.data.address,
        "longitude": val.data.placeCenter.lng,
        "latitude": val.data.placeCenter.lat
      },
      "notes": val.data.note,
      "status": val.data.status
    }

    return await fetch(API_HOST + val.data.siteId,
      {
        method: 'PUT',
        body: JSON.stringify(formdata),
      })
      .then(res => res.json())
      .then(
        (result) => {
          return cd(result)
        },
        (error) => {
          return cd(error)
        }
      )
  }
  async getDocuments(value, cd) {
    return await fetch(API_HOST + value.id + "/documents",
      { method: 'GET' })
      .then(res => res.json())
      .then(
        (result) => {
          return cd(result)
        },
        (error) => {
          return cd(error)
        }
      )
  }
  async updateDocument(value, cd) {
    var formdata = [{
      "id": value.passData.id,
      "name": value.passData.name,
      "isLocked": value.passData.isLocked
    }]
    return await fetch(API_HOST + value.passData.siteId + "/documents",
      {
        method: 'PATCH',
        body: JSON.stringify(formdata),
      })
      .then(res => res.json())
      .then(
        (result) => {
          return cd(result)
        },
        (error) => {
          return cd(error)
        }
      )
  }
  async lockDocuments(value, cd) {
    var formdata =value.passData.selectItemsList
    
    return await fetch(API_HOST + value.passData.siteId + "/documents",
      {
        method: 'PATCH',
        body: JSON.stringify(formdata),
      })
      .then(res => res.json())
      .then(
        (result) => {
          return cd(result)
        },
        (error) => {
          return cd(error)
        }
      )
  }
  async uploadDocuments(value, cd) {
    var formdata = [{
      "id": value.passData.id,
      "name": value.passData.name,
      "isLocked": value.passData.isLocked
    }]
    return await fetch(API_HOST + value.passData.siteId + "/documents",
      {
        method: 'POST',
        body: JSON.stringify(formdata),
      })
      .then(res => res.json())
      .then(
        (result) => {
          return cd(result)
        },
        (error) => {
          return cd(error)
        }
      )
  }
  
  async deleteDocuments(value, cd) {
   
    return await fetch(API_HOST + value.passData.siteId + "/documents?documentIds="+value.passData.selectItemsIDs,
      {
        method: 'DELETE',
      })
      .then(res => res.json())
      .then(
        (result) => {
          return cd(result)
        },
        (error) => {
          return cd(error)
        }
      )
  }

}
export default new SiteAPI();

