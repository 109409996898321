// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// UI Framework
import { Image, TouchableOpacity, Platform } from 'react-native';
import {
  Text,
  View,
} from 'native-base';

import WebModal from 'modal-enhanced-react-native-web';
import MobileModal  from 'react-native-modal';

let PlatformModal;
if (Platform.OS !== 'web') {
  PlatformModal = MobileModal;
} else {
  PlatformModal = WebModal;
}


// Style
import { styles } from '_components/common/Modal.style';

class BasicModal extends Component {

  constructor(props) {
    super(props);

    this._close = this._close.bind(this);
  }

  _close() {
    const { onClose } = this.props;

    onClose();
  }

  render() {
    const {
      title = '',
      subTitle,
      content,
      isVisible,
      hideExitIcon = false,
      hideTitle = false,
      headerColor,
      animIn,
      animOut,
      inTiming,
      outTiming,
      propagateSwipe,
      onBackdropPress,
    } = this.props;

    return (
      <View style={[styles.container]}>
        <PlatformModal
        transparent={true}
        animationIn={animIn}
        animationOut={animOut}
        animationInTiming={inTiming}
        animationOutTiming={outTiming}
        isVisible={isVisible}
        backdropColor={'#0F0F0E'}
        backdropOpacity={0.9}
        propagateSwipe={propagateSwipe}
        onBackdropPress={onBackdropPress}
        >
          <View style={[styles.modalContent]}>

            <View style={[styles.modalHeader]}>
              {
                !hideExitIcon &&
                  <View style={[styles.exitIconContainer]}>
                    <TouchableOpacity onPress={this._close}>
                      <Image
                        style={[styles.exitIcon]}
                        source={require('_assets/images/exit-icon.svg')}
                      />
                    </TouchableOpacity>
                  </View>
              }
              {
                !hideTitle &&
                  <Text
                    style={[styles.modalTitle, hideExitIcon ? { marginTop: 25 } : {color:headerColor?headerColor:"white"}]}
                  >
                    {title}
                  </Text>
              }
            </View>
            {
              subTitle &&
                <Text style={[styles.modalSubTitle]}>
                  {subTitle}
                </Text>
            }
            <View style={[styles.content]}>
              { content }
            </View>
          </View>
        </PlatformModal>
      </View>
    );
  }
}

BasicModal.propTypes = {
  content: PropTypes.any,
  hideExitIcon: PropTypes.bool,
  hideTitle: PropTypes.bool,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  animIn: PropTypes.string,
  animOut: PropTypes.string,
  inTiming: PropTypes.string,
  outTiming: PropTypes.any,
  headerColor: PropTypes.string,
  propagateSwipe: PropTypes.any,
  onBackdropPress: PropTypes.func,
};


export default connect(null, null)(BasicModal);


