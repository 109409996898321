import React, { Component } from "react";
import { navigate } from "_routes/RootNavigation";
import { connect } from "react-redux";
import { List } from "native-base";
import PropTypes from "prop-types";
import { styles as global } from "_style/Global.style";
import { styles } from "./CreateSite.style";
import { styles2 } from "_pages/Site.style";
// UI Framework
import { Button, Spinner, Text, View } from "native-base";
import {
  FlatList,
  StyleSheet,
  TouchableOpacity,
  Platform,
  Image,
} from "react-native";
import { Row, Col, Grid } from "react-native-easy-grid";
import FormInput from "_components/common/FormInputOn.component";
import SearchBox from "_components/create_site/LocationSearchBox.component";
import {
  formatPhoneNumber,
  validateFormData,
  EMAIL_CONSTRAINT_ALLOW_EMPTY,
  PHONE_NUMBER_CONSTRAINT,
  REQUIRED_CONSTRAINT,
} from "_util/form-util";

import validation from "validate.js";

import Geocoder from "react-native-geocoding";
import { ScrollView } from "react-native";
// Style
const API_HOST = "https://pxadckr5df.execute-api.us-east-1.amazonaws.com/sites";
const GOOGLE_MAP_GEO_LOCATION =
  "https://maps.googleapis.com/maps/api/geocode/json?address=";

const defaultState = {
  site_name: "",
  address: "",
  phone_number: "",
  email: "",
  manager: "",
  note: "",
  errorMessage: "",
  errorMessages: {},
  placeCenter: {},
  placeChecked: false,
};

// Form Validation
const constraints = {
  site_name: REQUIRED_CONSTRAINT("Site name"),
  //address: REQUIRED_CONSTRAINT("Address"),
  //phone_number: PHONE_NUMBER_CONSTRAINT,
  email: EMAIL_CONSTRAINT_ALLOW_EMPTY,
  //manager: REQUIRED_CONSTRAINT("Manager"),
  //note: REQUIRED_CONSTRAINT("Note"),
};

var validatePhonenumber =
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
var EnteredPhone = "";

export class CreateSite extends Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
    this._handleFormInput = this._handleFormInput.bind(this);
  }
  componentWillUnmount = () => {
    document.querySelectorAll(".pac-container").forEach((element) => {
      element.remove();
    });
  };

  //input handler
  _handleFormInput = (fieldId, value) => {
    const { errorMessages } = this.state;

    if (fieldId === "phone_number") {
      //value = validatePhonenumber.test(value);
      value = formatPhoneNumber(value);
    }

    // Always set the value of the form field to whatever the
    // user typed.
    this.setState({
      [fieldId]: value,
    });

    // Validate the form field
    const validationResult = validation.validate(
      { [fieldId]: value },
      fieldId === "email" && value.length > 0 || fieldId === "site_name"?
      { [fieldId]: constraints[fieldId] }
      :null
    );

    // Either set an error message or clear it for the form field.
    if (validationResult) {
      this.setState({
        errorMessages: {
          ...errorMessages,
          [fieldId]: validationResult[fieldId][0],
        },
      });
    } else {
      this.setState({
        errorMessages: {
          ...errorMessages,
          [fieldId]: null,
        },
      });
    }
  };

  onChangePalce = (place) => {
    console.log(place);
    this.state.placeChecked = place.lat
      ? this.setState({ placeChecked: false })
      : this.setState({ placeChecked: true });
    let myKey = "AIzaSyBU0_g8nGzHxsr-BQUuQvSZ3zuPBSKAFjg";
    fetch(
      GOOGLE_MAP_GEO_LOCATION + place.lat + "," + place.lng + "&key=" + myKey
    )
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          placeCenter: place,
          address: responseJson.results[0].formatted_address,
        });
      });
  };
  phoneFormat = (value) => {
    const cleaned = value.replace(/\D/g, "");
    if (cleaned.length > 10) {
      return;
    }
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    const formatted = !match[2]
      ? match[1]
      : match[1] +
        "-" +
        match[2] +
        (match[3] ? "-" + match[3] : "") +
        (match[4] ? "" + match[4] : "");
    this.setState({ phone_number: formatted });
  };

  ValidateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    alert("You have entered an invalid email address!");
    return false;
  }

  render() {
    const { errorMessage, errorMessages } = this.state;

    const { stateValue = this.state } = this.props;

    return (
      <View>
        <ScrollView
          contentContainerStyle={[
            styles.SiteCreateModalContent,
            { zIndex: -1 },
          ]}
        >
          <Grid>
            <Row style={{marginBottom:Object.values(errorMessages).every((x) => x === "site name is required.")?  "2.1%" : "3.3%" }}>
              <Col size={2}>
                <Text style={[styles.tableText]}>Site Name :</Text>
              </Col>
              <Col size={5} style={{ marginTop: 0, paddingLeft: 8 }}>
                <FormInput
                  fieldId={"site_name"}
                  onChange={this._handleFormInput}
                  onInput={(e) => this.setState({ site_name: e })}
                  placeholder={""}
                  maxLength={256}
                  inputValue={this.state.site_name}
                  itemStyles={[styles.item]}
                  inputStyles={[styles.input]}
                  errorMessage={errorMessages["site_name"] || ""}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: "7.8%" }}>
              <Col size={2}>
                <Text style={[styles.tableText]}>Address :</Text>
              </Col>
              <Col
                size={5}
                style={{ marginTop: -8, zIndex: 1110, paddingLeft: 8 }}
              >
                <View>
                  <SearchBox
                    fieldId={"address"}
                    placeholder={""}
                    width={380}
                    maxLength={500}
                    multiline2={true}
                    multiline={true}
                    itemStyles={[styles.item]}
                    inputStyles={[styles.input]}
                    errorMessage={errorMessages["address"] || ""}
                    onPlaceChange={(place) => {
                      this.onChangePalce(place);
                    }}
                  />
                  <Text style={{ color: "red", fontSize: 12, marginLeft: 15 }}>
                    {this.state.placeChecked && "Address is required"}
                  </Text>
                </View>
              </Col>
            </Row>
            <Row style={{ marginBottom: "1%" }}>
              <Col size={2}>
                <Text style={[styles.tableText]}>Site Manager Name:</Text>
              </Col>
              <Col size={5} style={{ marginTop: -8, paddingLeft: 8 }}>
                <FormInput
                  fieldId={"manager"}
                  onInput={(e) => this.setState({ manager: e })}
                  inputValue={this.state.manager}
                  maxLength={256}
                  onChange={this._handleFormInput}
                  placeholder={""}
                  itemStyles={[styles.item]}
                  inputStyles={[styles.input]}
                  //errorMessage={errorMessages["manager"] || ""}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: "1%" }}>
              <Col size={2}>
                <Text style={[styles.tableText]}>Phone Number:</Text>
              </Col>
              <Col size={5} style={{ marginTop: -8, paddingLeft: 8 }}>
                
                <FormInput
                  fieldId={"phone_number"}
                  maxLength={15}
                  onInput={(e) => this.phoneFormat(e)}
                  // onKeyDown={(e) => this.phoneFormat(this.state.phone_number, e)}
                  onChange={() => {}}
                  // onChange={(e) => this.phoneFormat(e)}
                  placeholder={""}
                  inputValue={this.state.phone_number}
                  itemStyles={[styles.item]}
                  inputStyles={[styles.input]}
                  //errorMessage={errorMessages["phone_number"] || ""}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom:Object.values(errorMessages).every((x) => x === "Email is invalid.")?  "1%" : "1.7%" }}>
              <Col size={2}>
                <Text style={[styles.tableText]}>Email Address:</Text>
              </Col>
              <Col size={5} style={{ marginTop: -8, paddingLeft: 8 }}>
                <FormInput
                  fieldId={"email"}
                  onInput={(e) => this.setState({ email: e })}
                  inputValue={this.state.email}
                  maxLength={256}
                  onChange={this._handleFormInput}
                  placeholder={""}
                  itemStyles={[styles.item]}
                  inputStyles={[styles.input]}
                  errorMessage={errorMessages["email"] || ""}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 2, marginBottom: 12 }}>
              <Col size={2}>
                <Text style={[styles.tableText]}>Notes :</Text>
              </Col>
              <Col size={5} style={{ marginTop: -8, paddingLeft: 8 }}>
                <FormInput
                  fieldId={"note"}
                  maxLength={500}
                  onInput={(e) => this.setState({ note: e })}
                  inputValue={this.state.note}
                  onChange={this._handleFormInput}
                  placeholder={""}
                  itemStyles={[styles.item]}
                  inputStyles={[styles.input]}
                  multiline2={true}
                  multiline={true}
                />
              </Col>
            </Row>
          </Grid>
          <Button
            disabled={this.props.disabled}
            style={[
              styles.confirmationButton,
              {
                marginBottom: 10,
                height: 40,
                borderRadius: 5,
                marginTop: "6%",
                marginBottom: "5%",
              },
            ]}
            onPress={() => {
              this.setState({
                phone_number: formatPhoneNumber(this.state.phone_number),
              }),
                Object.values(errorMessages).every(
                  (x) => x === null || x === ""
                )
                  ? setTimeout(() => {
                      this.props.showConfirmModal(this.state);
                    }, 100)
                  : null;
            }}
          >
            <Text style={[styles.confirmationButtonText]}>CREATE SITE</Text>
          </Button>
        </ScrollView>
      </View>
    );
  }
}

CreateSite.PropTypes = {
  showConfirmModal: PropTypes.func.isRequired,
  stateValue: PropTypes.any,
  disabled: PropTypes.any,
};
export default connect(null, null)(CreateSite);
//export default new CreateSite();
