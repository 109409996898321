import { StyleSheet } from 'react-native';
import {
  red,
  roboto,
} from '_style/Global.style';

export const styles = StyleSheet.create({
  deviceCard: {
    backgroundColor: '#212121',
    borderRadius: 5,
    height: 540,
    width: 320,
    marginLeft: 10,
    marginTop: 10,
  },
  deviceCardContent: {
    alignItems: 'flex-start',
    backgroundColor: '#212121',
    borderRadius: 5,
    height: '100%',
    paddingLeft: 6,
    paddingRight: 6,
    paddingTop: 6,
    paddingBottom: 6,
  },
  deviceCardTitle: {
    backgroundColor: '#B2B2B2',
    height: 32,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderRadius: 0,
  },
  deviceCardTitleText: {
    fontSize: 14,
    paddingTop: 13,
    textTransform: 'uppercase',
    width: '100%',
  },
  rightContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  settingsButton: {
    width: 22,
    height: 24,
    marginLeft: 30,
  },
  closeSettingsButton: {
    width: 19,
    height: 19,
    marginLeft: 33,
  },
  disabledText: {
    color: red,
    fontSize: 32,
    fontFamily: roboto,
    fontWeight: 'bold',
    textAlign: 'center',
  },
});
