import { StyleSheet } from "react-native";

export const styles2 = StyleSheet.create({
  main: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
    height: "100%",
    width: "100%",
    padding: 25,
    overflow: "auto",
    backgroundColor: "#383838",
  },
  titleText: {
    marginTop: "15%",
    fontFamily: "Roboto",
    fontSize: 28,
    fontWeight: "700",
    textAlign: "center",
  },
  btn: {
    margin: 18,
    borderRadius: 5,
    height: 40,
    marginTop: "12%",
  },
  btnText: {
    fontFamily: "Roboto",
    fontWeight: "700",
    color: "#FAFAFA",
    fontSize: 18,
  },
  subTitle: {
    marginTop: "10%",
    fontsize: 24,
    fontFamily: "Roboto",
    fontWeight: "700",
    textAlign: "center",
  },
  notifications: {},
  SiteCreateModalContent: {
    width: 425,
    marginLeft: 75,
    marginRight: 75,
  },
  buttonSize: {
    width: 75,
    height: 75,
  },
  buttonSize: {
    hover: {
      width: 85,
      height: 85,
    },
  },

  SiteCongrtzModalContent: {
    backgroundColor: "#212121",
    borderRadius: 10,
    marginLeft: 75,
    marginRight: 75,
  },
  buttonsizeImageMap: {
    width: 20,
    height: 29,
  },
  chocolateIconContent: {
    width: "3%",
    alignItems: "center",
    justifyContent: "center",
  },
  chocoloteIcon: {
    width: 12,
    height: 18,
  },
});
