// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Image, TouchableOpacity } from 'react-native';

// Redux
import { connect } from 'react-redux';

// Native Base
import {
  View,
} from 'native-base';


import { styles as global } from '_style/Global.style';
import { styles } from '_components/device_settings/LeftRightGroup.style';

class LeftRightGroup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      left: props.left,
    };

    this._leftOn = this._leftOn.bind(this);
    this._rightOn = this._rightOn.bind(this);
  }

  _leftOn() {
    const { onLeft } = this.props;
    this.setState({
      left: true,
    });
    onLeft();
  }

  _rightOn() {
    const { onRight } = this.props;
    this.setState({
      left: false,
    });
    onRight();
  }

  render() {
    const { left } = this.state;

    return (
      <View style={[styles.leftRightGroup]}>
        {
          left ?
          <>
              <TouchableOpacity disabled>
                <Image
                  style={ [styles.leftButtonOn] }
                  source={require('_assets/images/left-button-on.png')}
                />
              </TouchableOpacity>

              <TouchableOpacity onPress={this._rightOn}>
                <Image
                  style={ [styles.rightButtonOff] }
                  source={require('_assets/images/right-button-off.png')}
                />
              </TouchableOpacity>
            </>
            :
            <>
              <TouchableOpacity onPress={this._leftOn}>
                <Image
                  style={ [styles.leftButtonOff] }
                  source={require('_assets/images/left-button-off.png')}
                />
              </TouchableOpacity>

              <TouchableOpacity disabled>
                <Image
                  style={ [styles.rightButtonOn] }
                  source={require('_assets/images/right-button-on.png')}
                />
              </TouchableOpacity>
            </>
        }

      </View>
    );
  }
}

LeftRightGroup.propTypes = {
  onLeft: PropTypes.func.isRequired,
  onRight: PropTypes.func.isRequired,
  left: PropTypes.bool.isRequired,
};

export default connect(null, null)(LeftRightGroup);
