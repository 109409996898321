import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    backgroundColor: '#1A1A1A',
    height: 50,
  },
});
