
import { StyleSheet } from 'react-native';
import {
  robotoCondensed,
} from '_style/Global.style';

export const styles = StyleSheet.create({
  pressureControlContainer: {
    alignItems: 'center',
  },
  pressureTargetContainer: {
    alignItems: 'center',
    borderRadius: 2,
    width: 82,
    height: 100,
    marginTop: 5,
  },
  pressureArrowButton: {
    height: 56,
    width: 56,
  },
  pressureTargetLabel: {
    fontSize: 14,
    fontFamily: robotoCondensed,
    textAlign: 'center',
    width: 50,
  },
  pressureTargetUnit: {
    fontSize: 14,
    fontFamily: robotoCondensed,
  },
  pressureTargetValue: {
    fontSize: 24,
  },
  outletControlContainer: {
    alignItems: 'center',
    marginTop: 10,
  },

  inletControlContainer: {
    alignItems: 'center',
    marginTop: 10,
  }
});

