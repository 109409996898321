import { makeActionCreator } from '_redux/rootActions';

import {
  CLEAR_STATE,
  SHOW_TOAST,
} from '_redux/toasts/types';

/**
 * Clear state.
 */
export const clearState = makeActionCreator(CLEAR_STATE);
export const showToast = makeActionCreator(SHOW_TOAST, 'message', 'isError', 'openDelay');
