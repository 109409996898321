
// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { CONTROL_POINTS } from '_redux/iot/utils';
import {
  executeCommand,
  setSetPoints,
} from '_redux/iot/actions';
import {
  getSetPointsForDevice,
  getTelemetryForDevice,
  iotDeviceIsConnectedVisibility,
  isAlarmOnForDevice,
  isEngineRunningForDevice,
  isWarningOnForDevice,
} from '_redux/iot/selectors';
import {
  getUserDeviceCardSettingsById,
} from '_redux/dashboard/selectors';
import { SUPPORTED_COMMANDS } from '_redux/iot/utils';

// Components
import VerticalSlider from '_components/device_card/VerticalSlider.component';
import SliderTicks from '_components/device_card/SliderTicks.component';

import { debounce } from '_util';

// UI Framework
import {
  Image,
  ImageBackground,
  TouchableOpacity,
} from 'react-native';
import {
  Card,
  CardItem,
  Spinner,
  Text,
  View,
} from 'native-base';
import { Grid, Col } from 'react-native-easy-grid';

// Styles
import { styles as global, card_yellow, card_gray, card_red, card_green, textLight } from '_style/Global.style';
import { styles } from '_components/device_card/MiniCurrentHydroStat.style';

const PSItoKPA = 6.89476;
const DEFAULT_HYDROSTAT_SPEED = 0;

/**
 * Contains the components of the Hydrostat card for a device
 *
 * TODO: refactor to break out some of this into separate components.
 */
class MiniCurrentHydroStat extends Component {

  constructor(props) {
    super(props);

    const {
      [CONTROL_POINTS.COMMANDED_HYDROSTAT_SPEED]: commandedHydrostatSpeed,
    } = props.setPoints.C || {};

    this.state = {
      commandedHydrostatSpeed: commandedHydrostatSpeed || DEFAULT_HYDROSTAT_SPEED,
    };

    this._dragChange = this._dragChange.bind(this);

    this._setMaintainPressure20 = this._setMaintainPressure20.bind(this);
    this._setMaintainPressure15 = this._setMaintainPressure15.bind(this);
    this._setMaintainPressure10 = this._setMaintainPressure10.bind(this);
    this._setMaintainPressure5 = this._setMaintainPressure5.bind(this);

    this._setMaintainPressure = debounce(this._setMaintainPressure.bind(this), 0);
    this._setHydrostat = debounce(this._setHydrostat.bind(this), 1000);
  }

  /**
   * Event handler for when the user drags the slider up and down. This
   * gets invoked when the user lets go of the slider at its final
   * spot.
   *
   * @param {*} commandedHydrostatSpeed value of the slider.
   */
  _dragChange(commandedHydrostatSpeed) {

    this.setState({
      commandedHydrostatSpeed,
    });

    this._setHydrostat();
  }

  /**
   * Executes the set hydrostat command and updates the control point.
   */
  _setHydrostat() {
    const { deviceId, setSetPoints } = this.props;
    const { commandedHydrostatSpeed } = this.state;

    // executeCommand(
    //   deviceId,
    //   SUPPORTED_COMMANDS.SET_HYDROSTAT_SPEED,
    //   {
    //     data: {
    //       speed: commandedHydrostatSpeed,
    //     },
    //   },
    // );

    setSetPoints(deviceId, {
      C: {
        [CONTROL_POINTS.COMMANDED_HYDROSTAT_SPEED]: commandedHydrostatSpeed,
      },
    });
  }

  /**
   * Event Handler: Quickset Inlet PSI 20
   */
  _setMaintainPressure20() {
    this._setMaintainPressure(20);
  }
  /**
   * Event Handler: Quickset Inlet PSI 15
   */
  _setMaintainPressure15() {
    this._setMaintainPressure(15);
  }
  /**
   * Event Handler: Quickset Inlet PSI 10
   */
  _setMaintainPressure10() {
    this._setMaintainPressure(10);
  }
  /**
   * Event Handler: Quickset Inlet PSI 5
   */
  _setMaintainPressure5() {
    this._setMaintainPressure(5);
  }

  /**
   * Executes the set maintain pressures command.
   *
   * @param {*} pressure pressure to maintain.
   */
  _setMaintainPressure(pressure) {
    const { deviceId, setSetPoints, executeCommand, telemetry } = this.props;

    if(telemetry.mode != 1) {
      executeCommand(deviceId, SUPPORTED_COMMANDS.SET_THROTTLE_MODE, {
        data: {
          mode: 'pressure',
        },
      });
    }


    setSetPoints(deviceId, {
      C: {
        [CONTROL_POINTS.COMMANDED_PUMP_INLET_PRESSURE]: pressure * PSItoKPA,
      },
    });

    // executeCommand(
    //   deviceId,
    //   SUPPORTED_COMMANDS.SET_MAINTAIN_PRESSURES,
    //   {
    //     data: {
    //       type: 'suction',
    //       pressure: pressure * PSItoKPA,
    //     }
    //   }
    // );
  }

  /**
   * Handler to make sure that if the initial get set points
   * request is slow and the component has already rendered that
   * we can catch when the set points do come back and update the UI
   * accordingly.
   *
   * Also catches the instance where another user on another instance
   * of the app has made an update to the set points.
   */
  componentDidUpdate(prevProps) {
    // Incoming props.
    const { setPoints = {} } = this.props;
    const { [CONTROL_POINTS.COMMANDED_HYDROSTAT_SPEED]: commandedHydrostatSpeed } = setPoints.C || {};

    // Previous props.
    const { setPoints: prevSetPoints = {} } = prevProps;
    const { [CONTROL_POINTS.COMMANDED_HYDROSTAT_SPEED]: prevCommandedHydrostatSpeed } = prevSetPoints.C || {};

    // If the incoming props don't match the previous props,
    // update the state using the new props.
    if (commandedHydrostatSpeed !== prevCommandedHydrostatSpeed) {
      this.setState({
        commandedHydrostatSpeed,
      });
    }
  }

  render() {

    const {
      deviceId,
      deviceName,
      deviceIsConnected,
      telemetry,
      deviceSettings,
      isAlarmOnForDevice,
      isEngineRunningForDevice,
      isWarningOnForDevice,
      disconnectError,
      disconnectErrorView,
    } = this.props;

    const { hydrostat: showHydrostat = 'HIDE' } = deviceSettings;
    if (showHydrostat === 'HIDE') {
      return null;
    }

    const { commandedHydrostatSpeed } = this.state;

    const {
      hcm,
      hs,
      shp1,
      shp2,
      spd,
      sfr,
      stf,
    } = telemetry;

    const sliderThumb = (
      <Image style={[styles.sliderThumb]}
        source={require('_assets/images/slider-thumb-hydrostat.png')}
      />
    );

    let headerColor = card_gray;
    if (isEngineRunningForDevice) headerColor = card_green;
    if (isAlarmOnForDevice) headerColor = card_red;
    if (isWarningOnForDevice) headerColor = card_yellow;

    const hydrostatMode = (hcm === 1) ? 'AUTO' : 'MANUAL';

    return (
      <Card style={ [global.card, styles.hydroStatCard] }>
        {
          !deviceIsConnected &&
            <View style={[global.disconnectedContainer]}>

              <Spinner color="white" />
            </View>
        }
        {(disconnectError || disconnectErrorView) &&
          <View style={[global.disconnectedErrorContainer]}></View>
        }
        {/* Header */}
        <CardItem header style={ [styles.hydroStatCardTitle, { backgroundColor: headerColor }] }>

          {/* Device Id */}
          <Text style={[
            global.fontRobotoCondensedBold,
            global.textDark,
            styles.hydrostatCardTitleText
          ]}>
            HYDROSTAT
          </Text>
        </CardItem>

        {/* Hydrostat */}
        <CardItem style={ [styles.hydroStatCardContent]}>
          <Grid>

            <Col size={25} style={[styles.sliderContainer]}>
              <Text style={[styles.sliderTitle]}>
                HYDROSTAT SET POINT
              </Text>
              <Grid>
                <Col size={25} style={{ height: 440, paddingRight: 20 }}>
                  <SliderTicks
                    min={0}
                    max={100}
                    tickCount={10}
                    onPress={this._dragChange}
                  />
                </Col>
                <Col size={75}>
                  <VerticalSlider
                    value={commandedHydrostatSpeed} // Need to pick out set point value.
                    disabled={hcm === 1}
                    min={0}
                    max={100}
                    onChange={() => {}}
                    onComplete={this._dragChange}
                    width={16}
                    height={420}
                    borderRadius={18}
                    maximumTrackTintColor={'#B3B3B3'}
                    minimumTrackTintColor={'#424242'}
                    showBallIndicator={true}
                    ballIndicatorTextColor={'#FAFAFA'}
                    ballIndicatorColor={'#1A1A1A'}
                    ballIndicatorPosition={-60}
                    ballIndicatorWidth={48}
                    ballIndicatorTextBorderColor={'#D3D3D3'}
                    step={1}
                    renderIndicator={sliderThumb}
                    indicatorSuffix={'%'}
                  />
                </Col>
              </Grid>
            </Col>
          </Grid>
        </CardItem>
      </Card>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    deviceSettings: getUserDeviceCardSettingsById(state, ownProps.deviceId),
    deviceIsConnected: iotDeviceIsConnectedVisibility(state, ownProps.deviceId),
    setPoints: getSetPointsForDevice(state, ownProps.deviceId),
    telemetry: getTelemetryForDevice(state, ownProps.deviceId),
    isAlarmOnForDevice: isAlarmOnForDevice(state, ownProps.deviceId),
    isWarningOnForDevice: isWarningOnForDevice(state, ownProps.deviceId),
    isEngineRunningForDevice: isEngineRunningForDevice(state, ownProps.deviceId),
    disconnectError: state.iot.disconnectError,
    disconnectErrorView: state.iot.disconnectErrorView,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    executeCommand: (deviceId, command, data) => dispatch(executeCommand(deviceId, command, data)),
    setSetPoints: (deviceId, setPoints) => dispatch(setSetPoints(deviceId, setPoints)),
  };
};

MiniCurrentHydroStat.propTypes = {
  deviceId: PropTypes.string.isRequired,
  deviceName: PropTypes.string,
  deviceIsConnected: PropTypes.bool.isRequired,
  deviceSettings: PropTypes.any.isRequired,
  executeCommand: PropTypes.func.isRequired,
  telemetry: PropTypes.any.isRequired,
  setPoints: PropTypes.any.isRequired,
  setSetPoints: PropTypes.func.isRequired,
  isAlarmOnForDevice: PropTypes.bool.isRequired,
  isWarningOnForDevice: PropTypes.bool.isRequired,
  isEngineRunningForDevice: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(MiniCurrentHydroStat);
