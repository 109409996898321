import { StyleSheet } from 'react-native';
import { textLight, robotoMonoBold } from '_style/Global.style';

export const styles = StyleSheet.create({
  mainContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  timerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',

    backgroundColor: 'black',
    borderRadius: 2,

    width: 168,
    height: 36,

    marginBottom: 5,
  },
  timerText: {
    color: textLight,
    fontFamily: robotoMonoBold,
    fontSize: 24,
  },
  buttonContainer: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    width: 168,
    height: 36,
    marginLeft: 3,
  },
});
