import { makeActionCreator } from "_redux/rootActions";
import { API } from "aws-amplify";
import {
  CLEAR_STATE,
  MAP_FULL_SCREEN,
  INIT_SITEMAP,
  SITEMAP_SAVE_REQUEST,
  SITEMAP_SAVE_SUCCESS,
  SITEMAP_SAVE_FAILEAR,
  SITEMAP_LOAD_REQUEST,
  SITEMAP_LOAD_SUCCESS,
  SITEMAP_LOAD_FAILEAR,
} from "_redux/siteMap/types";
import { SITE_MAP_API_ID } from "_config/api-constants";
export const clearState = makeActionCreator(CLEAR_STATE);
export const mapFullScreen = makeActionCreator(MAP_FULL_SCREEN, "status");
export const siteMapEditInit = makeActionCreator(INIT_SITEMAP);

export const makeMapFullScreen = (status) => {
  return (dispatch) => {
    dispatch(mapFullScreen(status));
  };
};

export const siteMapEditInitialized = () => {
  return (dispatch) => {
    dispatch(siteMapEditInit());
  };
};

export const siteMapSaveRequest = makeActionCreator(SITEMAP_SAVE_REQUEST);
export const siteMapSaveSucess = makeActionCreator(SITEMAP_SAVE_SUCCESS);
export const siteMapSaveFailear = makeActionCreator(SITEMAP_SAVE_FAILEAR);

export const siteMapLoadRequest = makeActionCreator(SITEMAP_LOAD_REQUEST);
export const siteMapLoadSucess = makeActionCreator(
  SITEMAP_LOAD_SUCCESS,
  "mapdata"
);
export const siteMapLoadFailear = makeActionCreator(SITEMAP_LOAD_FAILEAR);

export const saveMapData = (siteId, body) => {
  return async (dispatch) => {
    dispatch(siteMapSaveRequest());
    try {
      const results = await API.post(SITE_MAP_API_ID, `/sites/${siteId}/map`, {
        body,
      });
      if (results.status === "success") {
        dispatch(siteMapSaveSucess());
        dispatch(loadMapData(siteId));
      } else {
        dispatch(siteMapSaveFailear());
      }
    } catch (e) {
      console.error(e);
      dispatch(siteMapSaveFailear());
    }
  };
};

export const loadMapData = (siteId) => {
  return async (dispatch) => {
    dispatch(siteMapLoadRequest());
    try {
      const mapData = await API.get(SITE_MAP_API_ID, `/sites/${siteId}/map`);
      dispatch(siteMapLoadSucess(mapData));
    } catch (e) {
      console.error(e);
      dispatch(siteMapLoadFailear());
    }
  };
};
