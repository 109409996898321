import { StyleSheet } from "react-native";
import { light_gray, yellow } from "_style/Global.style";

export const styles = StyleSheet.create({
  main: {
    flexDirection: "row",
    alignItems: "center",
  },
  checkbox: {
    backgroundColor: light_gray,
    borderRadius: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  label: {
    fontSize: 14,
    marginLeft: 10,
  },
  dot: {
    backgroundColor: yellow,
  },
});
