import { createReducer } from '_redux/rootActions';

// Types
import {
  CLEAR_STATE,
  CLEAR_GROUP_CHILDREN_FOR_ASSIGNMENT,

  ADD_GROUP_REQUEST,
  ADD_GROUP_SUCCESS,
  ADD_GROUP_FAILURE,

  GET_GROUP_REQUEST,
  GET_GROUP_SUCCESS,
  GET_GROUP_FAILURE,

  GET_GROUP_USERS_REQUEST,
  GET_GROUP_USERS_SUCCESS,
  GET_GROUP_USERS_FAILURE,

  ADD_SYSTEM_USER_REQUEST,
  ADD_SYSTEM_USER_SUCCESS,
  ADD_SYSTEM_USER_FAILURE,

  REMOVE_SYSTEM_USER_REQUEST,
  REMOVE_SYSTEM_USER_SUCCESS,
  REMOVE_SYSTEM_USER_FAILURE,

  GET_GROUP_CHILDREN_REQUEST,
  GET_GROUP_CHILDREN_SUCCESS,
  GET_GROUP_CHILDREN_FAILURE,

  GET_GROUP_CHILDREN_FOR_ASSIGNMENT_REQUEST,
  GET_GROUP_CHILDREN_FOR_ASSIGNMENT_SUCCESS,
  GET_GROUP_CHILDREN_FOR_ASSIGNMENT_FAILURE,

  UPDATE_GROUP_REQUEST,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAILURE,

  INVITE_SYSTEM_USER_REQUEST,
  INVITE_SYSTEM_USER_SUCCESS,
  INVITE_SYSTEM_USER_FAILURE,

  CHECK_SYSTEM_USER_ELIGIBILITY_REQUEST,
  CHECK_SYSTEM_USER_ELIGIBILITY_SUCCESS,
  CHECK_SYSTEM_USER_ELIGIBILITY_FAILURE,

  SEARCH_DEVICES_REQUEST,
  SEARCH_DEVICES_SUCCESS,
  SEARCH_DEVICES_FAILURE,

  SUSPEND_DEVICE_REQUEST,
  SUSPEND_DEVICE_SUCCESS,
  SUSPEND_DEVICE_FAILURE,

  ASSIGN_DEVICE_REQUEST,
  ASSIGN_DEVICE_SUCCESS,
  ASSIGN_DEVICE_FAILURE,

  CHECK_AND_ADD_IN_PROGRESS,
  CHECK_AND_ADD_COMPLETE,

  SEARCH_GROUP_CHILDREN_REQUEST,
  SEARCH_GROUP_CHILDREN_SUCCESS,
  SEARCH_GROUP_CHILDREN_FAILURE,
  
  SEARCH_GROUP_USERS_REQUEST,
  SEARCH_GROUP_USERS_SUCCESS,
  SEARCH_GROUP_USERS_FAILURE,

} from '_redux/admin/types';

const initialState = {
  isAddingGroup: false,
  addGroupFailed: false,
  addGroupErrorMessage: '',

  isFetchingGroup: false,
  fetchGroupFailed: false,
  fetchGroupErrorMessage: '',
  group: {},

  isFetchingGroupChildren: false,
  fetchGroupChildrenFailed: false,
  fetchGroupChildrenErrorMessage: '',
  groupChildren: [],

  isFetchingGroupUsers: false,
  fetchGroupUsersFailed: false,
  fetchGroupUsersErrorMessage: '',
  groupUsers: [],

  isFetchingGroupDevices: false,
  fetchGroupDevicesFailed: false,
  fetchGroupDevicesErrorMessage: '',
  searchCount: 0,
  groupDevices: [],

  isUpdatingGroup: false,
  updateGroupFailed: false,
  updateGroupErrorMessage: '',

  isInvitingSystemUser: false,
  inviteSystemUserFailed: false,
  inviteSystemUserErrorMessage: '',

  isCheckingSystemUserEligibility: false,
  systemUserEligibilityError: '',

  isAddingSystemUser: false,
  addSystemUserFailed: false,
  addSystemUserErrorMessage: '',

  isRemovingSystemUser: false,
  removeSystemUserFailed: false,
  removeSystemUserErrorMessage: '',
  removingSystemUserCognitoSub: '',

  isSuspendingDevice: false,
  suspendDeviceFailed: false,
  suspendDeviceErrorMessage: '',

  isFetchingGroupChildrenForAssignment: false,
  fetchGroupChildrenForAssignmentFailed: false,
  fetchGroupChildrenForAssignmentError: '',
  groupChildrenForAssignment: [],

  isAssigningDevice: false,
  assignDeviceFailed: false,
  assignDeviceError: '',

  checkAndAddInProgress: false,
};

export default createReducer(initialState, {
  // CLEAR STATE
  [CLEAR_STATE]: () => initialState,


  // CHECK ELIGIBILITY AND ADD SYSTEM USER OPERATION
  [CHECK_AND_ADD_IN_PROGRESS]: (state) => {
    return {...state, checkAndAddInProgress: true};
  },
  [CHECK_AND_ADD_COMPLETE]: (state) => {
    return {...state, checkAndAddInProgress: false};
  },

  // ADD GROUP
  [ADD_GROUP_REQUEST]: (state) => {
    return {
      ...state,
      isAddingGroup: true,
      addGroupFailed: false,
      addGroupErrorMessage: '',
    };
  },
  [ADD_GROUP_SUCCESS]: (state) => {
    return {
      ...state,
      isAddingGroup: false,
      addGroupFailed: false,
      addGroupErrorMessage: '',
    };
  },
  [ADD_GROUP_FAILURE]: (state, action) => {
    const { error } = action;
    return {
      ...state,
      isAddingGroup: false,
      addGroupFailed: true,
      addGroupErrorMessage: error,
    };
  },

  // GET GROUP CHILDREN
  [GET_GROUP_CHILDREN_REQUEST]: (state) => {
    return {
      ...state,
      isFetchingGroupChildren: true,
      fetchGroupChildrenFailed: false,
      fetchGroupChildrenErrorMessage: '',
      groupChildren: [],
    };
  },
  [GET_GROUP_CHILDREN_SUCCESS]: (state, action) => {
    const { children } = action;

    return {
      ...state,
      isFetchingGroupChildren: false,
      fetchGroupChildrenFailed: false,
      fetchGroupChildrenErrorMessage: '',
      groupChildren: children,
    };
  },
  [GET_GROUP_CHILDREN_FAILURE]: (state, action) => {
    const { error } = action;
    return {
      ...state,
      isFetchingGroupChildren: true,
      fetchGroupChildrenFailed: false,
      fetchGroupChildrenErrorMessage: error,
      groupChildren: [],
    };
  },

  // GET GROUP USERS
  [GET_GROUP_USERS_REQUEST]: (state) => {
    return {
      ...state,
      isFetchingGroupUsers: true,
      fetchGroupUsersFailed: false,
      fetchGroupUsersErrorMessage: '',
      groupUsers: [],
    };
  },
  [GET_GROUP_USERS_SUCCESS]: (state, action) => {
    const { groupUsers } = action;

    return {
      ...state,
      isFetchingGroupUsers: false,
      fetchGroupUsersFailed: false,
      fetchGroupUsersErrorMessage: '',
      groupUsers,
    };
  },
  [GET_GROUP_USERS_FAILURE]: (state, action) => {
    const { error } = action;
    return {
      ...state,
      isFetchingGroupUsers: false,
      fetchGroupUsersFailed: true,
      fetchGroupUsersErrorMessage: error,
      groupUsers: [],
    };
  },

  // GET GROUP
  [GET_GROUP_REQUEST]: (state) => {
    return {
      ...state,
      isFetchingGroup: true,
      fetchGroupFailed: false,
      fetchGroupErrorMessage: '',
      group: {},
    };
  },
  [GET_GROUP_SUCCESS]: (state, action) => {
    const { group } = action;

    return {
      ...state,
      isFetchingGroup: false,
      fetchGroupFailed: false,
      fetchGroupErrorMessage: '',
      group,
    };
  },
  [GET_GROUP_FAILURE]: (state, action) => {
    const { error } = action;
    return {
      ...state,
      isFetchingGroup: false,
      fetchGroupFailed: true,
      fetchGroupErrorMessage: error,
      group: {},
    };
  },

  /**
   * UPDATE GROUP
   */
  [UPDATE_GROUP_REQUEST]: (state) => {
    return {
      ...state,
      isUpdatingGroup: true,
      updateGroupFailed: false,
      updateGroupErrorMessage: '',
    };
  },
  [UPDATE_GROUP_SUCCESS]: (state, action) => {
    const { groupInfo } = action;
    const updatedGroupChildren = updateGroup(state, groupInfo.group_id, groupInfo);
    let updatedGroup = state.group;
    if (groupInfo.group_id === state.group.group_id) {
      updatedGroup = groupInfo;
    }
    return {
      ...state,
      isUpdatingGroup: false,
      updateGroupFailed: false,
      updateGroupErrorMessage: '',
      group: updatedGroup,
      groupChildren: updatedGroupChildren,
    };
  },
  [UPDATE_GROUP_FAILURE]: (state, action) => {
    const { error } = action;
    return {
      ...state,
      isUpdatingGroup: false,
      updateGroupFailed: true,
      updateGroupErrorMessage: error,
    };
  },

  // INVITE SYSTEM USER
  [INVITE_SYSTEM_USER_REQUEST]: (state) => {
    return {
      ...state,
      isInvitingSystemUser: true,
      inviteSystemUserFailed: false,
      inviteSystemUserErrorMessage: '',
    };
  },
  [INVITE_SYSTEM_USER_SUCCESS]: (state) => {
    return {
      ...state,
      isInvitingSystemUser: false,
      inviteSystemUserFailed: false,
      inviteSystemUserErrorMessage: '',
    };
  },
  [INVITE_SYSTEM_USER_FAILURE]: (state, action) => {
    const { error } = action;
    return {
      ...state,
      isInvitingSystemUser: false,
      inviteSystemUserFailed: true,
      inviteSystemUserErrorMessage: error,
    };
  },

  // CHECK SYSTEM USER ELIGIBILITY
  [CHECK_SYSTEM_USER_ELIGIBILITY_REQUEST]: (state) => {
    return {
      ...state,
      isCheckingSystemUserEligibility: true,
      systemUserEligibilityError: '',
    };
  },
  [CHECK_SYSTEM_USER_ELIGIBILITY_SUCCESS]: (state, action) => {
    const { error } = action;
    return {
      ...state,
      isCheckingSystemUserEligibility: false,
      systemUserEligibilityError: error,
    };
  },
  [CHECK_SYSTEM_USER_ELIGIBILITY_FAILURE]: (state) => {
    return {
      ...state,
      isCheckingSystemUserEligibility: false,
      systemUserEligibilityError: 'FAILED_CHECK',
    };
  },

  // ADD SYSTEM USER
  [ADD_SYSTEM_USER_REQUEST]: (state) => {
    return {
      ...state,
      isAddingSystemUser: true,
      addSystemUserFailed: false,
      addSystemUserErrorMessage: '',
    };
  },
  [ADD_SYSTEM_USER_SUCCESS]: (state) => {
    return {
      ...state,
      isAddingSystemUser: false,
      addSystemUserFailed: false,
      addSystemUserErrorMessage: '',
    };
  },
  [ADD_SYSTEM_USER_FAILURE]: (state, action) => {
    const { error } = action;
    return {
      ...state,
      isAddingSystemUser: false,
      addSystemUserFailed: true,
      addSystemUserErrorMessage: error,
    };
  },

  // REMOVE SYSTEM USER
  [REMOVE_SYSTEM_USER_REQUEST]: (state, action) => {
    const { cognitoSub } = action;
    return {
      ...state,
      isRemovingSystemUser: true,
      removeSystemUserFailed: false,
      removeSystemUserErrorMessage: '',
      removingSystemUserCognitoSub: cognitoSub,

    };
  },
  [REMOVE_SYSTEM_USER_SUCCESS]: (state, action) => {
    const { cognitoSub } = action;

    const newGroupUsers = state.groupUsers.slice();
    newGroupUsers.splice(getGroupUserIndex(state, cognitoSub), 1);

    return {
      ...state,
      isRemovingSystemUser: false,
      removeSystemUserFailed: false,
      removeSystemUserErrorMessage: '',
      removingSystemUserCognitoSub: '',
      groupUsers: newGroupUsers,
    };
  },
  [REMOVE_SYSTEM_USER_FAILURE]: (state, action) => {
    const { error } = action;
    return {
      ...state,
      isRemovingSystemUser: false,
      removeSystemUserFailed: true,
      removeSystemUserErrorMessage: error,
    };
  },

  // SEARCH DEVICES
  [SEARCH_DEVICES_REQUEST]: (state) => {
    return {
      ...state,
      isFetchingGroupDevices: true,
      fetchGroupDevicesFailed: false,
      fetchGroupDevicesErrorMessage: '',
      groupDevices: [],
      searchCount: 0,
    };
  },
  [SEARCH_DEVICES_SUCCESS]: (state, action) => {
    const { results } = action;
    return {
      ...state,
      isFetchingGroupDevices: false,
      fetchGroupDevicesFailed: false,
      fetchGroupDevicesErrorMessage: '',
      groupDevices: results.devices,
      searchCount: results.totalCount,
    };
  },
  [SEARCH_DEVICES_FAILURE]: (state, action) => {
    const { error } = action;

    return {
      ...state,
      isFetchingGroupDevices: false,
      fetchGroupDevicesFailed: true,
      fetchGroupDevicesErrorMessage: error,
      groupDevices: [],
      searchCount: 0,
    };
  },

  // SUSPEND DEVICE
  [SUSPEND_DEVICE_REQUEST]: (state) => {
    return {
      ...state,
      isSuspendingDevice: true,
      suspendDeviceFailed: false,
      suspendDeviceErrorMessage: '',
    };
  },
  [SUSPEND_DEVICE_SUCCESS]: (state, action) => {
    const { deviceId, suspend } = action;
    const updatedGroupDevices = updateGroupDevice(state, deviceId, { is_suspended: suspend });
    return {
      ...state,
      isSuspendingDevice: false,
      suspendDeviceFailed: false,
      suspendDeviceErrorMessage: '',
      groupDevices: updatedGroupDevices,
    };
  },
  [SUSPEND_DEVICE_FAILURE]: (state) => {
    return {
      ...state,
      isSuspendingDevice: false,
      suspendDeviceFailed: true,
      suspendDeviceErrorMessage: '',
    };
  },

  // GET GROUP CHILDREN FOR ASSIGNMENT
  [CLEAR_GROUP_CHILDREN_FOR_ASSIGNMENT]: (state) => {
    return {
      ...state,
      isFetchingGroupChildrenForAssignment: false,
      fetchGroupChildrenForAssignmentFailed: false,
      fetchGroupChildrenForAssignmentError: '',
      groupChildrenForAssignment: [],
    };
  },
  [GET_GROUP_CHILDREN_FOR_ASSIGNMENT_REQUEST]: (state) => {
    return {
      ...state,
      isFetchingGroupChildrenForAssignment: true,
      fetchGroupChildrenForAssignmentFailed: false,
      fetchGroupChildrenForAssignmentError: '',
      groupChildrenForAssignment: [],
    };
  },
  [GET_GROUP_CHILDREN_FOR_ASSIGNMENT_SUCCESS]: (state, action) => {
    const { children } = action;
    return {
      ...state,
      isFetchingGroupChildrenForAssignment: false,
      fetchGroupChildrenForAssignmentFailed: false,
      fetchGroupChildrenForAssignmentError: '',
      groupChildrenForAssignment: children,
    };
  },
  [GET_GROUP_CHILDREN_FOR_ASSIGNMENT_FAILURE]: (state, action) => {
    const { error } = action;
    return {
      ...state,
      isFetchingGroupChildrenForAssignment: false,
      fetchGroupChildrenForAssignmentFailed: false,
      fetchGroupChildrenForAssignmentError: error,
      groupChildrenForAssignment: [],
    };
  },

  // ASSIGN DEVICE
  [ASSIGN_DEVICE_REQUEST]: (state) => {
    return {
      ...state,
      isAssigningDevice: true,
      assignDeviceFailed: false,
      assignDeviceError: '',
    };
  },
  [ASSIGN_DEVICE_SUCCESS]: (state) => {
    return {
      ...state,
      isAssigningDevice: false,
      assignDeviceFailed: false,
      assignDeviceError: '',
    };
  },
  [ASSIGN_DEVICE_FAILURE]: (state, action) => {
    const { error } = action;

    return {
      ...state,
      isAssigningDevice: false,
      assignDeviceFailed: true,
      assignDeviceError: error,
    };
  },

  // SEARCH GROUP CHILDREN
  [SEARCH_GROUP_CHILDREN_REQUEST]: (state) => {
    return {
      ...state,
      isFetchingGroupChildren: true,
      fetchGroupChildrenFailed: false,
      fetchGroupChildrenErrorMessage: '',
      groupChildren: [],
    };
  },
  [SEARCH_GROUP_CHILDREN_SUCCESS]: (state, action) => {
    const { children } = action;
    return {
      ...state,
      isFetchingGroupChildren: false,
      fetchGroupChildrenFailed: false,
      fetchGroupChildrenErrorMessage: '',
      groupChildren: children,
    };
  },
  [SEARCH_GROUP_CHILDREN_FAILURE]: (state, action) => {
    const { error } = action;

    return {
      ...state,
      isFetchingGroupChildren: false,
      fetchGroupChildrenFailed: true,
      fetchGroupChildrenErrorMessage: error,
      groupChildren: [],
    };
  },

  // SEARCH GROUP USERS
  [SEARCH_GROUP_USERS_REQUEST]: (state) => {
    return {
      ...state,
      isFetchingGroupUsers: true,
      fetchGroupUsersFailed: false,
      fetchGroupUsersErrorMessage: '',
      groupUsers: [],
    };
  },
  [SEARCH_GROUP_USERS_SUCCESS]: (state, action) => {
    const { groupUsers } = action;

    return {
      ...state,
      isFetchingGroupUsers: false,
      fetchGroupUsersFailed: false,
      fetchGroupUsersErrorMessage: '',
      groupUsers,
    };
  },
  [SEARCH_GROUP_USERS_FAILURE]: (state, action) => {
    const { error } = action;
    return {
      ...state,
      isFetchingGroupUsers: false,
      fetchGroupUsersFailed: true,
      fetchGroupUsersErrorMessage: error,
      groupUsers: [],
    };
  },
});

const getGroupDeviceIndex = (state, deviceId) => {
  return state.groupDevices.findIndex((device) => device.device_id === deviceId);
};

/**
 * Utility method to get the array index of the group in the
 * groupChildren array.
 *
 * @param {*} state redux state
 * @param {*} groupId group id of the child group.
 */
const getGroupIndex = (state, groupId) => {
  return state.groupChildren.findIndex((group) => group.group_id === groupId);
};

/**
 * Utility method to get the array index of the user in the groupUsers
 * array.
 *
 * @param {*} state redux state
 * @param {*} cognitoSub cognito sub of the user to find.
 */
const getGroupUserIndex = (state, cognitoSub) => {
  return state.groupUsers.findIndex((user) => user.cognito_sub === cognitoSub);
};

/**
 * Update a crew member in the state in a redux safe way.
 *
 * @param {*} state redux state
 * @param {string} cognitoSub the cognito sub of the crew member
 * @param {*} updates object of updates to apply to the crew member
 */
const updateGroup = (state, groupId, updates) => {
  const groupIndex = getGroupIndex(state, groupId);

  return state.groupChildren.map((group, index) => {
    if (index !== groupIndex) {
      return group;
    }

    return {
      ...group,
      ...updates,
    };
  });
};

const updateGroupDevice = (state, deviceId, updates) => {
  const deviceIndex = getGroupDeviceIndex(state, deviceId);

  return state.groupDevices.map((device, index) => {
    if (index !== deviceIndex) {
      return device;
    }

    return {
      ...device,
      ...updates,
    };
  });
};
