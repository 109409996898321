import { StyleSheet } from 'react-native';
import {
  robotoCondensed
} from '_style/Global.style';

export const styles = StyleSheet.create({
  alarmContainer: {
    marginTop: 10,
  },
  alarmSection: {
    flexDirection: 'row',
    borderTopWidth: 1,
    borderTopColor: '#424242',
    width: '100%',
    paddingTop: 10,
    paddingBottom: 10,
  },
  alarmDetailsSection: {
    borderTopWidth: 1,
    borderTopColor: '#424242',
    width: '100%',
    paddingTop: 10,
    paddingBottom: 10,
  },
  alarmTextLabel: {
    paddingLeft: 10,
    fontFamily: robotoCondensed,
    fontSize: 24,
    width: 75,
    textAlign: 'right',
  },
  alarmText: {
    fontFamily: robotoCondensed,
    fontSize: 24,
    paddingLeft: 40,
  },
  alarmTextAction: {
    fontFamily: robotoCondensed,
    fontSize: 20,
    paddingLeft: 40,
  },
  center: {
    justifyContent: 'center',
  },
  noPadding: {
    paddingLeft: 0,
  }
});
