// React
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";

// Amplify
import { Auth } from 'aws-amplify';

// UI Framework
import { Button, Text, View } from "native-base";

// Style
import { styles as global, placeholderTextColor, red } from '_style/Global.style';
import { styles } from '_components/common/ChangePasswordModal.style';

// Custom Components.
import FormInput from '_components/common/FormInput.component';
import {
  checkPasswordEquality,
  updateFormData,
  PASSWORD_CONSTRAINT,
} from '_util/form-util';

const defaultState = {
  isGettingCode: false,
  isResetting: false,
  isResending: false,
  failedReset: false,
  failedGettingCode: false,
  enteredUsername: false,
  touchedConfirmPassword: false,
  usernameScreenErrorMessage: '',
  passwordResetScreenErrorMessage: '',
  formData: {},
  errorMessages: {},
  passwordSuccessMessage: '',
  passwordErrorMessage: '',
};

const resetPasswordConstraints = {
  password: PASSWORD_CONSTRAINT,
  confirmPassword: {
    equality: {
      attribute: 'password',
      message: '^Passwords do not match'
    }
  },
};


class ChangePasswordModal extends Component {
  constructor(props) {
    super(props);

    this.state = defaultState;

    this._onSave = this._onSave.bind(this);
    this._onCancel = this._onCancel.bind(this);

    this._handleFormInput = this._handleFormInput.bind(this);
  }

  _handleFormInput(fieldId, value){
    const { touchedConfirmPassword, formData, errorMessages } = this.state;

if (fieldId === 'password' || fieldId === 'confirmPassword') {
    const updatedState = checkPasswordEquality(
      fieldId,
      value,
      formData,
      errorMessages,
      touchedConfirmPassword,
      resetPasswordConstraints,
    );
    this.setState(updatedState);
  }else{
  this.setState(updateFormData(
    fieldId,
    value,
    {},
    formData,
    errorMessages,
  ));
  }
  }

  _onSave = async () => {
    const { formData, passwordErrorMessage, passwordSuccessMessage } = this.state;
    const { oldPassword, password, confirmPassword} = formData;
    let errorFlag = 0;

    if(password === confirmPassword){
      try {
        await Auth.changePassword(
          Auth.user,
          oldPassword,
          password
        );
      } catch (error) {
        errorFlag = 1;
        console.error('Error changing password', error);
        if(error.code !='NotAuthorizedException'){
        this.setState({
          passwordSuccessMessage: '',
          passwordErrorMessage: JSON.stringify(error.message).slice(1, -1),
        });
        }else{
        this.setState({
          passwordSuccessMessage: '',
          passwordErrorMessage: 'Old password is not correct.'
        });
        }
      }finally{
        if(errorFlag !== 1){
            this.setState(defaultState);
            this.setState({
              passwordSuccessMessage: 'Password changed successfully! To change your password again, please retype each field.',
              passwordErrorMessage: '',
            });
        }
      }
    }else{
      this.setState({
        passwordSuccessMessage: '',
        passwordErrorMessage: '',
     });
    }
  };

  _onCancel() {
    this.props.onCancel();
    this.setState(defaultState);
  }

  render() {
      const {
        errorMessages, passwordSuccessMessage, passwordErrorMessage
      } = this.state;

    return (

      <View style={[styles.changePasswordModalContent]}>
        <View style={[styles.formInput]}>
            <FormInput
              key={'oldPassword'}
              fieldId={'oldPassword'}
              placeholder={'Old Password'}
              helpTextStyle={global.authInputHelpText}
              placeholderTextColor={placeholderTextColor}
              inputStyles={[global.authInput]}
              itemStyles={[global.authItem]}
              secure={true}
              onChange={this._handleFormInput}
              insetShadow={false}
              //errorMessage={errorMessages['oldPassword'] || ''}
              errorMessageStyle={[global.authInputErrorText]}
              showHide={true}
            />
          </View>
        <View style={[styles.formInput]}>
            <FormInput
              key={'password'}
              fieldId={'password'}
              placeholder={'New Password'}
              helpText={'Password must be at least 8 characters long and contain 1 number, 1 uppercase letter, and 1 lowercase letter'}
              helpTextStyle={global.authInputHelpText}
              placeholderTextColor={placeholderTextColor}
              inputStyles={[global.authInput]}
              itemStyles={[global.authItem]}
              secure={true}
              onChange={this._handleFormInput}
              insetShadow={false}
              errorMessage={errorMessages['password'] || ''}
              errorMessageStyle={[global.authInputErrorText]}
              showHide={true}
            />
          </View>
          <View style={[styles.formInput]}>
            <FormInput
              key={'confirmPassword'}
              fieldId={'confirmPassword'}
              placeholder={'Confirm New Password'}
              placeholderTextColor={placeholderTextColor}
              inputStyles={[global.authInput]}
              itemStyles={[global.authItem]}
              secure={true}
              onChange={this._handleFormInput}
              insetShadow={false}
              errorMessage={errorMessages['confirmPassword'] || ''}
              errorMessageStyle={[global.authInputErrorText]}
              showHide={true}
            />
          </View>
        <View style={[styles.actionButtonContainer]}>
          <Button style={[global.confirmationButton, { marginBottom: 5 }]} onPress={this._onSave}>
            <Text style={[global.confirmationButtonText]}>SAVE</Text>
          </Button>
          <Text style={{ marginBottom: 10 }}>{passwordSuccessMessage + passwordErrorMessage}</Text>
          <Button style={[global.confirmationButton, { marginBottom: 15 }]} onPress={this._onCancel}>
            <Text style={[global.confirmationButtonText]}>GO BACK</Text>
          </Button>
        </View>
      </View>

    );
  }
}

ChangePasswordModal.propTypes = {
  onCancel: PropTypes.func.isRequired,

  data: PropTypes.any,

};

export default connect(null, null)(ChangePasswordModal);
