import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  notificationsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: '#1A1A1A',
    borderRadius: 10,
    // height: 500,
    minWidth: 400,
    width: '100%',
  },
  title: {
    fontSize: 48,
    fontWeight: 'bold',
    margin: 24,
    letterSpacing: 1.6,
  },
  notificationRowsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 50,
    width: '100%',
  },
  notificationRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    // height: 58,
    marginLeft: 20,
    marginRight: 20,
  },
  lightRow: {
    backgroundColor: '#212121',
  },
  subjectTypeContainer: {
    marginLeft: 20,
    minWidth: 330,
  },
  subject: {
    fontSize: 18,
    letterSpacing: 1.6,
  },
  type: {
    fontSize: 12,
    letterSpacing: 1.6,
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    minWidth: 250,
  },
  viewButton: {
    width: 200,
    height: 55,
  },
  trashIcon: {
    width: 26,
    height: 28,
    marginRight: 20,
  },
  noNotificationsText:{
    textAlign: 'center',
  }
});
