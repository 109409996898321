import React, { Component } from "react";

import { Col, Row, Grid } from "react-native-easy-grid";
import { Text, Button, Avatar, Spinner } from "native-base";
import { navigate } from "_routes/RootNavigation";
import { styles2 } from "_pages/UploadDocuments.style";
import OptionPopup from "_components/create_site/Archive.component";
import EditPopup from "_components/create_site/EditDoc.component";

import ConfirmSuccess from "_components/common/SiteSuccessModal.component";
import { styles as global } from "_style/Global.style";
import {
  Image,
  TouchableOpacity,
  View,
  FlatList,
  Platform,
  CheckBox,
  Dimensions,
} from "react-native";
import { styles } from "./SiteMapEdit.style";
import { Images } from "../assets/";
import Modal from "_components/common/Modal.component";
import SiteEditPage from "./SiteMapEdit.page";
import { card } from "_assets/images/back-icon.png";
import { connect } from "react-redux";
import UploadDocumentsModal from "_components/create_site/UploadDocumentsModal";
import { ScrollView } from "react-native";
import GridList from "react-native-grid-list";
import Carousel, { Pagination } from "react-native-snap-carousel";
import * as DocumentPicker2 from "expo-document-picker";
import { Auth } from "aws-amplify";
import pdfImage from "../assets/images/showPdfImage.svg";
import unknownImage from "../assets/images/unknownImage.jpeg";
import { sliderWidth, itemWidth } from "./UploadDocumentCarouselStyle";
import AccessControl, {
  ADMIN,
  MANUFACTURER,
  CREW_LEADER,
  CREW_MEMBER,
  DEALER,
  OWNER,
} from "_components/auth/AccessControl.component";
//import API from "_redux/site/api";
import {
  clearState,
  fetchSiteDocsData,
  editSiteDocsData,
  lockSiteDocsData,
  deleteSiteDocs,
  uploadSiteDocs,
} from "_redux/siteDocuments/actions";

import TEMP_API from "_config/_aws-exports.js";
import { log } from "react-native-reanimated";

class UploadDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ShowSiteEditModal: false,
      ShowConfirmModal: false,
      EditSite: false,
      BackToPre: false,
      ShowDeleteModal: false,
      SiteShareModal: false,
      LargeSizeImageUrl: "",
      imageDownloadUrl: "",
      ImageName: "",
      ImageView: true,
      ShowLockModal: false,
      ShowUnLockModal: false,
      ShowImageFulllModal: false,
      documentUploadModal: false,
      isCheckBoxSelected: false,
      showCheckBoxes: false,
      ShowEditModal: false,
      documentsArray: [],
      pdfsArray: [],
      checkedItemsArray: [],
      DocResult: [],
      selectImageIndex: 0,
      checkBoxChecked: false,
      FileObject: {},
      FileList: [],
      isLoaded: false,
      documentsData: {},
      documentID: "",
      documentLock: 0,
      siteId: "",
      siteStatus: this.props.route.params.activeStatus,
      invalidFileCount: 0,
      windowWidth: "",
      windowHeight: "",
      screenWidth: "",
      screenHeight: "",
    };
    //Bind the functions
  }
  componentWillMount() {
    this.setState({
      siteStatus: this.props.route.params.activeStatus,
    });
  }
  componentDidMount() {
    const windowWidth = Dimensions.get("window").width;
    const windowHeight = Dimensions.get("window").height;
    const screenWidth = Dimensions.get("screen").width;
    const screenHeight = Dimensions.get("screen").height;
    var siteId = this.props.route.params.siteID;
    this.setState({
      siteStatus: this.props.route.params.activeStatus,
      windowWidth: windowWidth,
      windowHeight: windowHeight,
      screenWidth: screenWidth,
      screenHeight: screenHeight,
    });
    this.getDocumentsData(siteId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.fetchDocumentsData !== this.props.fetchDocumentsData) {
      if (this.props.fetchDocumentsData) {
        this.setState({
          isLoaded: true,
          documentsData: this.props.fetchDocumentsData,
          documentsArray: this.props.fetchDocumentsData,
          imageDownloadUrl:
            this.props.fetchDocumentsData.length > 0
              ? this.props.fetchDocumentsData[0].urlPath
              : null,
          pdfsArray: this.props.fetchDocumentsData.filter(
            (e) => e.type === "application/pdf"
          ),
          unLockedItems: this.props.fetchDocumentsData.filter(
            (e) => e.isLocked === 0
          ),
          documentID:
            this.props.fetchDocumentsData.length > 0
              ? this.props.fetchDocumentsData[0].id
              : "",
          LargeSizeImageUrl:
            this.props.fetchDocumentsData.length > 0
              ? this.props.fetchDocumentsData[0].urlPath
              : "",
          ImageName:
            this.props.fetchDocumentsData.length > 0
              ? this.props.fetchDocumentsData[0].name
              : "",
        });
      }
    }

    if (prevProps.editDocumentsDataRes !== this.props.editDocumentsDataRes) {
      if (this.props.editDocumentsDataRes) {
        if (this.props.editDocumentsDataRes.status === "success") {
          this.setState({
            ShowEditModal: false,
            documentsArray: [],
            checkedItemsArray: [],
            selectImageIndex: 0,
          });
          this.getDocumentsData(this.props.route.params.siteID);
          this.refs.isCarousel;
        } else {
          this.setState({ ShowEditModal: false });
        }
      }
    }
    if (prevProps.lockDocumentsDataRes !== this.props.lockDocumentsDataRes) {
      if (this.props.lockDocumentsDataRes) {
        if (this.props.lockDocumentsDataRes.status === "success") {
          this.setState({
            ShowLockModal: false,
            ShowUnLockModal: false,
            checkedItemsArray: [],
            selectImageIndex: 0,
          });
          this.getDocumentsData(this.props.route.params.siteID);
        } else {
          this.setState({ ShowLockModal: false, ShowUnLockModal: false });
        }
      }
    }
    if (
      prevProps.deleteDocumentsDataRes !== this.props.deleteDocumentsDataRes
    ) {
      if (this.props.deleteDocumentsDataRes) {
        if (
          this.props.deleteDocumentsDataRes.message === "Site documents deleted"
        ) {
          this.setState({ ShowDeleteModal: false, selectImageIndex: 0 });
          this.getDocumentsData(this.props.route.params.siteID);
          this.refs.isCarousel;
        } else {
          this.setState({ ShowDeleteModal: false, isLoaded: true });
        }
      }
    }
  }

  componentWillUnmount() {
    const { clearState } = this.props;
    clearState();
  }

  getDocumentsData = (id) => {
    this.setState({
      isLoaded: false,
    });
    const { fetchDocumentsFromStore } = this.props;
    fetchDocumentsFromStore(id);
  };

  componentWillUnmount = () => {
    {
      this.state.checkedItemsArray.map((item, index) => {
        return (item.isChecked = false);
      });
    }
  };

  renderGridList = ({ item, index }) => {
    !this.state.showCheckBoxes
      ? this.state.checkedItemsArray.map((item, index) => {
          return (item.isChecked = false);
        })
      : null;
    return (
      <View key={index} style={[styles2.gridStyle]}>
        <View style={[styles2.checkBoxView]}>
          {this.state.showCheckBoxes ? (
            <CheckBox
              value={item.isChecked}
              tintColors={{ true: "#2C2B2B", false: "#EEB310" }}
              onCheckColor="#EEB310"
              onValueChange={() => this.OnCheckChange(item.id)}
              style={[styles2.checkBoxStyles]}
            />
          ) : null}
          {item.type === "application/pdf" ? (
            <Image
              source={require("_assets/images/PDF_Icon.svg")}
              onClick={() => this.CardClcik(item, index)}
              style={[
                styles2.imageSize,
                {
                  borderWidth: 2,
                  borderColor:
                    (this.state.selectImageIndex == index &&
                      !this.state.showCheckBoxes) ||
                    item.isChecked
                      ? "#EEB310"
                      : "transparent",
                },
              ]}
            />
          ) : item.type === "image/jpg" ||
            item.type === "image/jpeg" ||
            item.type === "image/png" ? (
            <Image
              source={{ uri: item.urlPath }}
              onClick={() => this.CardClcik(item, index)}
              style={[
                styles2.imageSize,
                {
                  borderWidth: 2,
                  borderColor:
                    (this.state.selectImageIndex == index &&
                      !this.state.showCheckBoxes) ||
                    item.isChecked
                      ? "#EEB310"
                      : "transparent",
                },
              ]}
            />
          ) : (
            <Image
              source={require("_assets/images/unknownImage.jpeg")}
              onClick={() => this.CardClcik(item, index)}
              style={[
                styles2.imageSize,
                {
                  borderWidth: 2,
                  borderColor:
                    (this.state.selectImageIndex == index &&
                      !this.state.showCheckBoxes) ||
                    item.isChecked
                      ? "#EEB310"
                      : "transparent",
                },
              ]}
            />
          )}

          {item.isLocked ? (
            <Image
              source={require("_assets/images/lock-img-icon.png")}
              //onClick={() => this.CardClcik(item, index)}
              style={[
                {
                  width: 31,
                  height: 31,
                  marginLeft: -40,
                  zIndex: 2,
                  marginTop: 64,
                },
              ]}
            />
          ) : null}
        </View>
      </View>
    );
  };

  renderItemsList = ({ item, index }) => {
    return (
      <View
        key={index}
        style={[
          styles2.listRenderItemsContainer,
          {
            borderWidth: 2,
            borderColor:
              this.state.selectImageIndex == index ? "#EEB310" : "transparent",
            backgroundColor: index % 2 === 0 ? "#212121" : "#1A1A1A",
          },
        ]}
      >
        <View style={[styles2.subRenderContainer]}>
          <View style={[{ flexDirection: "row", width: "94%" }]}>
            {this.state.showCheckBoxes ? (
              <CheckBox
                value={item.isChecked}
                onValueChange={() => this.OnCheckChange(item.id)}
                onCheckColor="#fff"
                tintColor="#fff"
                onFillColor="#fff"
                style={styles2.checkbox}
              />
            ) : null}

            <Text
              style={[styles2.imageNameText]}
              onClick={() => this.CardClcik(item, index)}
            >
              {item.name}
            </Text>
          </View>
          {item.isLocked ? (
            <View style={{ marginLeft: "5%" }}>
              <Image
                source={require("_assets/images/lock-icon.svg")}
                //onClick={() => this.CardClcik(item, index)}
                style={[{ width: 15, height: 15, marginRight: 2, zIndex: 2 }]}
              />
            </View>
          ) : null}
        </View>
      </View>
    );
  };

  _closeDeleteOptionModal = () => {
    this.setState({ ShowDeleteModal: false });
  };
  _closeEditOptionModal = () => {
    this.setState({ ShowEditModal: false });
  };

  _EditDocumentName = () => {
    this.setState({ ShowEditModal: false, isLoaded: false });

    var imageName = this.state.ImageName;
    var dotIndex = imageName.lastIndexOf(".");
    var nameWithoutExtension =
      dotIndex >= 0 ? imageName.substring(0, dotIndex) : imageName;

    var passData = {
      name: nameWithoutExtension,
      id: this.state.documentID,
      isLocked: this.state.documentLock,
      siteId: this.props.route.params.siteID,
    };

    const { updateDocumentName } = this.props;
    updateDocumentName(passData);
  };

  _DeleteDocuments = () => {
    this.setState({ ShowDeleteModal: false, isLoaded: false });

    const selectItemsIDs =
      this.state.checkedItemsArray.length > 0
        ? this.state.checkedItemsArray.map((e) => e.id).toString()
        : this.state.documentID;

    var passData = {
      selectItemsIDs: selectItemsIDs,
      siteId: this.props.route.params.siteID,
    };
    const { deleteSiteDocs } = this.props;
    deleteSiteDocs(passData);
    this.state.checkedItemsArray = [];
  };

  _DownloadDocuments = () => {
    let selectItemsAvatarURLs =
      this.state.checkedItemsArray.length > 0
        ? this.state.checkedItemsArray.map((e) => e.urlPath)
        : [this.state.imageDownloadUrl];

    selectItemsAvatarURLs.forEach((url, index) => {
      setTimeout(() => {
        const link = document.createElement("a");
        link.href = url;
        link.download = url.substring(url.lastIndexOf("/") + 1);
        link.click();
      }, index * 1000); // delay by 1 second for each download
    });
    // this.state.checkedItemsArray = [];

    this.setState({
      checkedItemsArray: [],
      isCheckBoxSelected: false,
      showCheckBoxes: false,
      selectImageIndex: 0,
    });

    this.state.checkedItemsArray.map((item, index) => {
      return (item.isChecked = false);
    });
  };

  _LockDocuments = () => {
    this.setState({
      ShowLockModal: false,
      isLoaded: false,
    });
    var passData = [
      {
        id: this.state.documentID,
        name: this.state.ImageName,
        isLocked: 1,
      },
    ];

    const selectItemsList =
      this.state.checkedItemsArray.length && this.state.showCheckBoxes > 0
        ? this.state.checkedItemsArray.map(({ id, name, isLocked }) => ({
            id,
            name,
            isLocked: 1,
          }))
        : passData;
    var passData = {
      selectItemsList: selectItemsList,
      siteId: this.props.route.params.siteID,
    };
    const { lockSiteDocs } = this.props;
    lockSiteDocs(passData);
    this.state.checkedItemsArray = [];
  };

  _UnLockDocuments = () => {
    this.setState({ ShowUnLockModal: false, isLoaded: false });
    var passData = [
      {
        id: this.state.documentID,
        name: this.state.ImageName,
        isLocked: 0,
      },
    ];
    const selectItemsList =
      this.state.checkedItemsArray.length && this.state.showCheckBoxes > 0
        ? this.state.checkedItemsArray.map(({ id, name, isLocked }) => ({
            id,
            name,
            isLocked: 0,
          }))
        : passData;
    var passData = {
      selectItemsList: selectItemsList,
      siteId: this.props.route.params.siteID,
    };
    const { lockSiteDocs } = this.props;
    lockSiteDocs(passData);
    this.state.checkedItemsArray = [];
  };

  _closeLockOptionModal = () => {
    this.setState({ ShowLockModal: false });
  };
  _closeUnLockOptionModal = () => {
    this.setState({ ShowUnLockModal: false });
  };

  _CloseAndSave = () => {
    this.setState({ ShowConfirmModal: true, ShowDeleteModal: false });
  };

  _CloseShareModal = () => {
    this.setState({ SiteShareModal: false });
  };

  confirmModal = () => {
    return (
      <Modal
        isVisible={this.state.ShowConfirmModal}
        hideTitle={true}
        hideExitIcon={true}
        content={
          this.state.ShowConfirmModal ? (
            <ConfirmSuccess
              title={"SUCCESS"}
              titleColor={"#006644"}
              imageStyle={{ height: 112, width: 112 }}
              imageName={"SUCCESS_IMAGE"}
              subTitle={"YOU HAVE \n SUCCESSFULLY UPLOADED \nTHE DOCUMENTS"}
              onClose={() => this.setState({ ShowConfirmModal: false })}
              buttonText={"CLOSE"}
            />
          ) : null
        }
      />
    );
  };
  EditOptionModal = () => {
    return (
      <Modal
        isVisible={this.state.ShowEditModal}
        title={"EDIT DOCUMENT"}
        subTitle={"Update Document Information"}
        hideExitIcon={true}
        content={
          this.state.ShowEditModal ? (
            <EditPopup
              onCancel={() => this._closeEditOptionModal}
              submitButtonText={"SAVE"}
              inputValue={this.state.ImageName}
              inputPlace={this.state.ImageName}
              onChange={(e) => this.setState({ ImageName: e })}
              labelText={"Name :"}
              submit={() =>
                this.state.ImageName ? this._EditDocumentName() : null
              }
              cancelButtonText={"CANCEL"}
            />
          ) : null
        }
      />
    );
  };
  DeleteOptionModal = () => {
    return (
      <Modal
        isVisible={this.state.ShowDeleteModal}
        title={"DELETE DOCUMENT"}
        outTiming={800}
        hideExitIcon={true}
        content={
          <OptionPopup
            onCancel={() => this._closeDeleteOptionModal}
            submitButtonText={"DELETE"}
            confirmationText={
              this.state.checkedItemsArray.length > 1 &&
              this.state.showCheckBoxes
                ? `Would you like to permanently delete \n selected (${this.state.checkedItemsArray.length}) items from your documents?`
                : "Would you like to permanently delete \n this item from your documents?"
            }
            submit={() => this._DeleteDocuments()}
            cancelButtonText={"DO NOT DELETE"}
          />
        }
      />
    );
  };

  LockOptionModal = () => {
    return (
      <Modal
        isVisible={this.state.ShowLockModal}
        title={"LOCK DOCUMENT"}
        hideExitIcon={true}
        content={
          this.state.ShowLockModal ? (
            <OptionPopup
              onCancel={() => this._closeLockOptionModal}
              submitButtonText={"LOCK"}
              confirmationText={
                "Would you like to lock the document? \n Only Owners and Crew Leaders will have \n visibility to the document"
              }
              submit={() => this._LockDocuments()}
              cancelButtonText={"CANCEL"}
            />
          ) : null
        }
      />
    );
  };

  UnLockOptionModal = () => {
    return (
      <Modal
        isVisible={this.state.ShowUnLockModal}
        title={"UNLOCK DOCUMENT"}
        hideExitIcon={true}
        content={
          this.state.ShowUnLockModal ? (
            <OptionPopup
              onCancel={() => this._closeUnLockOptionModal}
              submitButtonText={"UNLOCK"}
              confirmationText={
                "Would you like to unlock the document? \n Only Owners and Crew Leaders will have \n visibility to the document"
              }
              submit={() => this._UnLockDocuments()}
              cancelButtonText={"CANCEL"}
            />
          ) : null
        }
      />
    );
  };

  renderSingleImageList = ({ item, index }) => {
    return (
      <View style={styles2.fullViewSwiperDiv}>
        <Text style={[styles2.fullViewSwiperText]}>{item.name}</Text>
        <Image
          source={{
            uri:
              item.type === "image/jpg" ||
              item.type === "image/jpeg" ||
              item.type === "image/png"
                ? item.urlPath
                : item.type === "application/pdf"
                ? pdfImage
                : unknownImage,
          }}
          style={[styles2.imageBigSize]}
        />
      </View>
    );
  };

  SingleFullImage = () => {
    return (
      <View style={[styles2.imageContainer]}>
        <Button
          style={[styles2.leftSideBtn]}
          onPress={() => {
            this.refs.isCarousel.snapToPrev();
          }}
        >
          <Image
            source={require("_assets/images/arrowLeft.svg")}
            style={[styles2.sideBtnImg]}
          />
        </Button>
        <View style={{ alignContent: "center" }}>
          <AccessControl
            allowedGroups={[ADMIN, MANUFACTURER, CREW_LEADER, DEALER, OWNER]}
          >
            <Carousel
              //ref={(c) => { this._carousel = c; }}
              layout={"default"}
              //layoutCardOffset={`2`}
              ref={"isCarousel"}
              //parallaxFactor={0.3}
              onSnapToItem={(index) =>
                this.setState({ selectImageIndex: index })
              }
              data={this.state.documentsArray}
              loop={true}
              loopClonesPerSide={this.state.documentsArray.length}
              renderItem={this.renderSingleImageList}
              //sliderWidth={styles2.w_100}
              sliderWidth={sliderWidth}
              containerCustomStyle={{ flex: 1, marginRight: "4vw" }}
              slideStyle={{ flex: 1, height: "70vh" }}
              //itemWidth={styles2.w_100}
              itemWidth={itemWidth}
              firstItem={this.state.selectImageIndex}
              scale="scale"
            />
          </AccessControl>
          <AccessControl allowedGroups={[CREW_MEMBER]}>
            <Carousel
              //ref={(c) => { this._carousel = c; }}
              layout={"default"}
              //layoutCardOffset={`2`}
              ref={"isCarousel"}
              //parallaxFactor={0.3}
              onSnapToItem={(index) =>
                this.setState({ selectImageIndex: index })
              }
              data={this.state.unLockedItems}
              loop={true}
              //loopClonesPerSide={this.state.unLockedItems.length}
              renderItem={this.renderSingleImageList}
              sliderWidth={sliderWidth}
              containerCustomStyle={{ flex: 1, marginRight: "4vw" }}
              slideStyle={{ flex: 1, height: "70vh" }}
              itemWidth={itemWidth}
              firstItem={this.state.selectImageIndex}
              scale="scale"
            />
          </AccessControl>
        </View>
        <Button
          style={[styles2.rightSideBtn]}
          onPress={() => {
            this.refs.isCarousel.snapToNext();
          }}
        >
          <Image
            style={[styles2.sideBtnImg]}
            source={require("_assets/images/arrowRight.svg")}
          />
        </Button>
      </View>
    );
  };

  renderMultipleImageList = ({ item, index }) => {
    return (
      <View style={styles2.fullViewSwiperDiv}>
        <Text style={[styles2.fullViewSwiperText]}>{item.name}</Text>
        <Image source={{ uri: item.urlPath }} style={[styles2.imageBigSize]} />
      </View>
    );
  };
  MultipleFullImages = () => {
    return (
      <View style={[styles2.imageContainer]}>
        <Button
          style={[styles2.leftSideBtn]}
          onPress={() => {
            this.refs.isCarousel.snapToPrev();
          }}
        >
          <Image
            source={require("_assets/images/arrowLeft.svg")}
            style={styles2.sideBtnImg}
          />
        </Button>
        <View>
          <Carousel
            //ref={(c) => { this._carousel = c; }}
            layout={"default"}
            //layoutCardOffset={`2`}
            ref={"isCarousel"}
            //parallaxFactor={0.3}
            onSnapToItem={(index) => this.setState({ selectImageIndex: index })}
            data={this.state.checkedItemsArray}
            renderItem={this.renderSingleImageList}
            sliderWidth={sliderWidth}
            containerCustomStyle={{ flex: 1, height: 600, marginRight: "4vw" }}
            slideStyle={{ flex: 1, height: "70vh" }}
            itemWidth={itemWidth}
            firstItem={this.state.selectImageIndex}
          />
        </View>
        <Button
          style={[styles2.rightSideBtn]}
          onPress={() => {
            this.refs.isCarousel.snapToNext();
          }}
        >
          <Image
            style={[styles2.sideBtnImg]}
            source={require("_assets/images/arrowRight.svg")}
          />
        </Button>
      </View>
    );
  };

  FullImageViewModal = () => {
    return (
      <Modal
        isVisible={this.state.ShowImageFulllModal}
        onClose={() => this.setState({ ShowImageFulllModal: false })}
        hideTitle={true}
        propagateSwipe={true}
        onBackdropPress={() => this.setState({ ShowImageFulllModal: false })}
        content={
          this.state.ShowImageFulllModal &&
          this.state.checkedItemsArray.length > 0 &&
          this.state.showCheckBoxes
            ? this.MultipleFullImages()
            : this.SingleFullImage()
        }
      />
    );
  };

  UploadDocumentModal = () => {
    var API = TEMP_API.API.endpoints.filter(
      (e) => e.name === "BazookaFarmstarSiteMapAPI"
    );
    const pickDocument = async () => {
      try {
        let result = await DocumentPicker2.getDocumentAsync({
          type: "image/jpg, image/jpeg, image/png, application/pdf",
          multiple: true,
          copyToCacheDirectory: true,
        });

        this.setState({ FileObject: result, FileList: result.output });

        var selectedFiles = this.state.FileList;
        var FileLength = this.state.FileList.length;
        let largeFileCount = 0;

        for (let i = 0; i < FileLength; i++) {
          const file = selectedFiles[i];
          if (file.size > 25 * 1024 * 1024) {
            largeFileCount = largeFileCount + 1;
          }
        }
        this.setState({ invalidFileCount: largeFileCount });
        if (result.type === "cancel") {
          return;
        } else if (result.type === "success") {
          let imgArr = [];
          // imgArr.push(uri);
          let { name, size, uri } = result;
          imgArr.push(uri);
          if (Platform.OS === "ios") {
            const pickerResult = await FileSystem.downloadAsync(
              result.uri,
              fileUri
            );
          } else {
            const pickDoc = {
              name: result.name,
              uri: result.uri,
            };
          }
        } else {
          return;
        }
      } catch (error) {
        this.setState({
          success: false,
          successModVisible: true,
          successLineText: `We can't support this file.!`,
        });
      }
    };
    const filesCount =
      this.state.FileList.length === 1
        ? this.state.FileList.length + " File is ready to upload"
        : this.state.FileList.length > 1
        ? this.state.FileList.length + " Files are ready to upload"
        : "";

    const validation =
      this.state.FileList.length === 1 && this.state.invalidFileCount === 1
        ? "Your file is too large"
        : this.state.FileList.length > 1 && this.state.invalidFileCount === 1
        ? "One of the files you trying to upload is too large"
        : this.state.FileList.length > 1 && this.state.invalidFileCount > 1
        ? "Some of the files you trying to upload is too large"
        : "";

    const uploadDocument = async () => {
      this.setState({
        isLoaded: false,
        documentUploadModal: false,
      });
      const session = await Auth.currentSession();
      const token = session.getAccessToken().getJwtToken();

      var selectedFiles = this.state.FileList;
      const body = new FormData();
      var FileLength = this.state.FileList.length;
      let isSizeValid = true;

      for (var i = 0; i < FileLength; i++) {
        body.append("file", selectedFiles[i]);
      }

      // for (let i = 0; i < fileLength; i++) {
      //   const file = selectedFiles[i];
      //   if (file.size > 0.01 * 1024 * 1024) {
      //     isSizeValid = false;
      //     break;
      //   }
      //   body.append('file', file);
      // }

      // if (!isSizeValid) {
      //   this.setState({
      //     success: false,
      //     successModVisible: true,
      //     successLineText: 'File size should be less than 25MB',
      //   });
      //   return;
      // }

      // await API_HOST.post(SITE_MAP_API_ID,`/sites/${this.props.route.params.siteID}}/documents`,{body});
      // const {addDocuments} =this.props;
      // addDocuments(this.props.route.params.siteID,body);

      //await API_HOST.post(API_HOST,`/sites/${this.props.route.params.siteID}}/documents`,{body});

      await fetch(
        API[0].endpoint + `/sites/${this.props.route.params.siteID}/documents`,
        {
          method: "POST",
          headers: {
            Authorization: "bearer " + token,
          },
          body,
        }
      )
        .then((res) => res.json())
        .then((result) => {
          if (result.message === "Success") {
            this.getDocumentsData(this.props.route.params.siteID);
            this.setState({
              isLoaded: true,
              ShowConfirmModal: true,
              FileList: [],
            });
          } else {
            this.setState({
              ShowConfirmModal: true,
              documentUploadModal: false,
            });
          }
        });
    };

    return (
      <Modal
        isVisible={this.state.documentUploadModal}
        title={"UPLOAD DOCUMENTS"}
        subTitle={"Upload related documents of the job site"}
        headerColor={"#EEB310"}
        onClose={() =>
          this.setState({ documentUploadModal: false, FileList: [] })
        }
        onBackdropPress={() =>
          this.setState({ documentUploadModal: false, FileList: [] })
        }
        content={
          this.state.documentUploadModal ? (
            <UploadDocumentsModal
              fileName={filesCount}
              validationSize={validation}
              onClick={() =>
                this.state.FileList.length > 0 ? uploadDocument() : null
              }
              filePick={() => pickDocument()}
            />
          ) : null
        }
      />
    );
  };

  CardClcik = (card, index) => {
    this.setState({
      LargeSizeImageUrl:
        card.type === "image/jpg" ||
        card.type === "image/jpeg" ||
        card.type === "image/png"
          ? card.urlPath
          : card.type === "application/pdf"
          ? pdfImage
          : unknownImage,
      ImageName: card.name,
      selectImageIndex: index,
      documentID: card.id,
      documentLock: card.isLocked,
      imageDownloadUrl: card.urlPath,
    });
    if (this.state.showCheckBoxes) {
      this.OnCheckChange(card.id);
    }
  };

  OnCheckChange = (id) => {
    const arr = [...this.state.documentsArray];
    this.state.checkedItemsArray = [...this.state.checkedItemsArray];
    const item = arr.find((item) => item.id === id);
    item.isChecked = !item.isChecked;
    if (item.isChecked) {
      this.state.checkedItemsArray = [...this.state.checkedItemsArray, item];
    } else {
      this.state.checkedItemsArray.splice(
        this.state.checkedItemsArray.indexOf(item),
        1
      );
    }
    this.setState({ documentsArray: arr });
    // can use a callback to update parent from here
  };

  render() {
    var state = this.state;
    if (this.state.BackToPre) {
      return <SiteEditPage />;
    }

    return (
      <Grid style={[styles.mainContainer]}>
        <Col style={[styles.subContainer, styles2.mainColumn]} size={9}>
          <View>
            {this.confirmModal()}
            {this.EditOptionModal()}
            {this.DeleteOptionModal()}
            {this.LockOptionModal()}
            {this.UnLockOptionModal()}
            {this.FullImageViewModal()}
            {this.UploadDocumentModal()}
          </View>
          {!this.state.isLoaded ? (
            <View style={{ flex: 1, alignItems: "center" }}>
              <Spinner color={"red"} />
            </View>
          ) : (
            <View style={[styles2.mainView]}>
              <Row style={[styles2.rowRadius]} size={7}>
                <Col size={4} style={[styles2.toolBox, styles2.topLeftDiv]}>
                  <View>
                    <View style={[styles2.topHead]}>
                      <View style={[styles2.topHeadSub1]}>
                        <TouchableOpacity
                          onClick={() =>
                            this.props.route.params.fromSiteMenu
                              ? navigate("Sites")
                              : navigate("SiteEdit")
                          }
                          //onClick={() => this.setState({ BackToPre: true })}
                          style={styles.backButton}
                        >
                          <View style={[styles.backButtonCont]}>
                            <Image
                              source={require("_assets/images/back-icon.svg")}
                              style={[styles.backButtonImg]}
                            />
                            <Text style={styles.backButtonText}>BACK</Text>
                          </View>
                        </TouchableOpacity>
                        <AccessControl
                          allowedGroups={[
                            ADMIN,
                            MANUFACTURER,
                            CREW_LEADER,
                            DEALER,
                            OWNER,
                          ]}
                        >
                          {this.state.documentsArray.length > 0 && (
                            <Image
                              source={
                                this.state.showCheckBoxes
                                  ? require("_assets/images/showCheckBox-iconClick.svg")
                                  : require("_assets/images/showCheckBox-icon.svg")
                              }
                              style={[
                                styles2.Thumbnailbtn,
                                { cursor: "pointer", marginLeft: "5%" },
                              ]}
                              onClick={() =>
                                this.setState({
                                  showCheckBoxes: !this.state.showCheckBoxes,
                                  checkedItemsArray: this.state.showCheckBoxes
                                    ? this.state.checkedItemsArray
                                    : [],
                                })
                              }
                            />
                          )}
                        </AccessControl>
                      </View>
                      <View style={[styles2.topHeadSub2]}>
                        <TouchableOpacity
                          onClick={() => this.setState({ ImageView: true })}
                          style={styles2.Thumbnailbtn}
                        >
                          <Image
                            source={
                              this.state.ImageView &&
                              this.state.documentsArray.length > 0
                                ? require("_assets/images/Thumbnail-View-Active.png")
                                : require("_assets/images/Thumbnail-View-InActive.png")
                            }
                            style={[styles2.Thumbnailbtn]}
                          />
                        </TouchableOpacity>
                        <TouchableOpacity
                          onClick={() =>
                            this.state.documentsArray.length > 0
                              ? this.setState({ ImageView: false })
                              : null
                          }
                          style={[styles2.Thumbnailbtn, { marginRight: 24 }]}
                        >
                          <Image
                            source={
                              this.state.ImageView > 0
                                ? require("_assets/images/ListView-InActive.png")
                                : require("_assets/images/ListView-Active.png")
                            }
                            style={[styles2.Thumbnailbtn]}
                          />
                        </TouchableOpacity>
                      </View>
                    </View>
                    <View>
                      {this.state.ImageView ? (
                        <ScrollView
                          style={[styles2.scrollDiv, { paddingBottom: 18 }]}
                        >
                          <AccessControl
                            allowedGroups={[
                              ADMIN,
                              MANUFACTURER,
                              CREW_LEADER,
                              DEALER,
                              OWNER,
                            ]}
                          >
                            {this.state.documentsArray.length > 0 ? (
                              <FlatList
                                key={"#"}
                                showSeparator={true}
                                data={this.state.documentsArray}
                                numColumns={3}
                                itemStyle={{
                                  width: "100%",
                                  paddingBottom: "5vh",
                                }}
                                renderItem={this.renderGridList}
                              />
                            ) : (
                              <Text style={[styles2.emptyItem]}>
                                NO DOCUMENTS
                              </Text>
                            )}
                          </AccessControl>
                          <AccessControl allowedGroups={[CREW_MEMBER]}>
                            {this.state.unLockedItems !== 0 ? (
                              <FlatList
                                key={"#"}
                                showSeparator={true}
                                data={this.state.unLockedItems}
                                numColumns={3}
                                itemStyle={{ width: "100%" }}
                                renderItem={this.renderGridList}
                              />
                            ) : (
                              <Text style={[styles2.emptyItem]}>
                                NO DOCUMENTS
                              </Text>
                            )}
                          </AccessControl>
                        </ScrollView>
                      ) : (
                        <View
                          style={[
                            styles2.scrollDiv,
                            { paddingTop: "1%", paddingBottom: "10%" },
                          ]}
                        >
                          <AccessControl
                            allowedGroups={[
                              ADMIN,
                              MANUFACTURER,
                              CREW_LEADER,
                              DEALER,
                              OWNER,
                            ]}
                          >
                            {this.state.documentsArray.length > 0 ? (
                              <FlatList
                                key={"_"}
                                numColumns={1}
                                data={this.state.documentsArray}
                                //showsVerticalScrollIndicator={false}
                                //showsHorizontalScrollIndicator={false}
                                itemStyle={{ width: "100%", paddingBottom: 18 }}
                                renderItem={this.renderItemsList}
                                style={[styles2.w_100]}
                              />
                            ) : (
                              <Text style={[styles2.emptyItem]}>
                                NO DOCUMENTS
                              </Text>
                            )}
                          </AccessControl>
                          <AccessControl allowedGroups={[CREW_MEMBER]}>
                            {this.state.unLockedItems !== 0 ? (
                              <FlatList
                                key={"_"}
                                numColumns={1}
                                //showsVerticalScrollIndicator={false}
                                //showsHorizontalScrollIndicator={false}
                                itemStyle={{ width: "100%", paddingBottom: 18 }}
                                data={this.state.unLockedItems}
                                renderItem={this.renderItemsList}
                                style={[styles2.w_100]}
                              />
                            ) : (
                              <Text style={[styles2.emptyItem]}>
                                NO DOCUMENTS
                              </Text>
                            )}
                          </AccessControl>
                        </View>
                      )}
                    </View>
                  </View>
                </Col>
                <Col size={5} style={[styles2.mapCont, styles2.topRightDiv]}>
                  {this.state.documentsArray.length > 0 ? (
                    <Image
                      source={{ uri: this.state.LargeSizeImageUrl }}
                      style={[styles2.imageBigSizePreview]}
                    />
                  ) : (
                    <View
                      style={{
                        flex: 1,
                        borderWidth:
                          this.state.documentsArray.length === 0 ? 1 : 0,
                        borderColor: "#F5F5F5",
                      }}
                    >
                      <Text style={[styles2.emptyItem]}>NO PREVIEW</Text>
                    </View>
                  )}
                </Col>
              </Row>
              <Row size={1} style={{ minHeight: 50 }}>
                <Col
                  size={4}
                  style={[
                    styles2.toolBox,
                    styles2.leftBottomDiv,
                    { minWidth: 50 },
                  ]}
                >
                  <AccessControl
                    allowedGroups={[
                      ADMIN,
                      MANUFACTURER,
                      CREW_LEADER,
                      DEALER,
                      OWNER,
                    ]}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        flex: 1,
                        marginTop: -1,
                        paddingBottom: "2vh",
                      }}
                    >
                      <TouchableOpacity
                        onClick={() =>
                          this.state.documentsArray.length > 0
                            ? this.setState({ ShowLockModal: true })
                            : null
                        }
                        style={[styles.lockButton, { marginTop: -10 }]}
                      >
                        <View style={[styles.backButtonCont]}>
                          <Image
                            source={
                              this.state.documentsArray.length > 0
                                ? require("_assets/images/lock-icon.png")
                                : require("_assets/images/lockUnclick.svg")
                            }
                            style={[styles2.lockButton]}
                          />
                        </View>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onClick={() =>
                          this.state.documentsArray.length > 0
                            ? this.setState({ ShowUnLockModal: true })
                            : null
                        }
                        style={[
                          styles.lockButton,
                          { marginLeft: 8, marginTop: -10 },
                        ]}
                        // styles.backButton,
                        // { marginLeft: 6, marginTop: -10 },
                        // ]}lockUnclick
                      >
                        <View style={[styles.backButtonCont]}>
                          <Image
                            source={
                              this.state.documentsArray.length > 0
                                ? require("_assets/images/unlock-icon.svg")
                                : require("_assets/images/UnlockUnclick.svg")
                            }
                            style={[styles2.lockButton, { width: 24 }]}
                          />
                        </View>
                      </TouchableOpacity>
                    </View>
                  </AccessControl>
                </Col>
                <Col
                  size={4}
                  style={[styles2.bottumRightRow, { minWidth: 250 }]}
                >
                  <View style={[styles.downDiv, styles2.rightBottomDiv]}>
                    <TouchableOpacity
                      onPress={() =>
                        this.state.documentsArray.length > 0
                          ? this.setState({ ShowImageFulllModal: true })
                          : null
                      }
                      style={[
                        styles2.bottomButton,
                        {
                          marginLeft: -11,
                          width:
                            this.state.windowWidth < 1050 ? "fit-content" : 104,
                        },
                      ]}
                    >
                      <Text
                        style={
                          this.state.documentsArray.length > 0
                            ? styles2.siteButtonText
                            : styles2.siteButtonTextInact
                        }
                      >
                        VIEW FULL
                      </Text>
                    </TouchableOpacity>
                    <AccessControl
                      allowedGroups={[
                        ADMIN,
                        MANUFACTURER,
                        CREW_LEADER,
                        DEALER,
                        OWNER,
                      ]}
                    >
                      <Button
                        onClick={() => {
                          !this.state.showCheckBoxes &&
                          this.state.documentsArray.length > 0
                            ? this.setState({ ShowEditModal: true })
                            : null;
                        }}
                        style={[
                          styles2.bottomButton,
                          {
                            width:
                              this.state.windowWidth < 1050
                                ? "fit-content"
                                : 95,
                          },
                        ]}
                      >
                        <Text
                          style={[
                            styles2.siteButtonText,
                            {
                              color:
                                this.state.showCheckBoxes ||
                                this.state.documentsArray.length === 0
                                  ? "#615F5F"
                                  : "#FAFAFA",
                            },
                          ]}
                        >
                          EDIT
                        </Text>
                      </Button>
                      <Button
                        onClick={() => {
                          this.state.documentsArray.length > 0
                            ? this.setState({ ShowDeleteModal: true })
                            : null;
                        }}
                        style={[
                          styles2.bottomButton,
                          {
                            width:
                              this.state.windowWidth < 1050
                                ? "fit-content"
                                : 95,
                          },
                        ]}
                      >
                        <Text
                          style={[
                            this.state.documentsArray.length > 0
                              ? styles2.siteButtonText
                              : styles2.siteButtonTextInact,
                          ]}
                        >
                          DELETE
                        </Text>
                      </Button>
                      <Button
                        onPress={() =>
                          this.state.documentsArray.length > 0
                            ? this._DownloadDocuments()
                            : null
                        }
                        style={[
                          styles2.bottomButton,
                          {
                            width:
                              this.state.windowWidth < 1050
                                ? "fit-content"
                                : 95,
                          },
                        ]}
                        //style={[styles2.downloaButton]}
                      >
                        <Text
                          style={[
                            this.state.documentsArray.length > 0
                              ? styles2.siteButtonText
                              : styles2.siteButtonTextInact,
                          ]}
                        >
                          DOWNLOAD
                        </Text>
                      </Button>
                    </AccessControl>
                  </View>
                </Col>
              </Row>
              <AccessControl
                allowedGroups={[
                  ADMIN,
                  MANUFACTURER,
                  CREW_LEADER,
                  DEALER,
                  OWNER,
                ]}
              >
                <Button
                  style={[
                    global.confirmationButton,
                    styles.submitFeedbackButton,
                    {
                      backgroundColor:
                        state.siteStatus === "ARCHIVED" ? "#616161" : "#EEB310",
                      height: 88,
                      marginTop: 4,
                    },
                  ]}
                  onClick={() => {
                    state.siteStatus === "ACTIVE"
                      ? this.setState({ documentUploadModal: true })
                      : null;
                  }}
                >
                  <Text
                    style={[
                      global.confirmationButtonText,
                      { textAlign: "center" },
                      styles.submitFeedbackButtonText,
                      {
                        color:
                          state.siteStatus === "ARCHIVED"
                            ? "#424242"
                            : "#000000",
                        fontSize: 48,
                        fontWeight: "700",
                      },
                    ]}
                  >
                    UPLOAD DOCUMENTS
                  </Text>
                </Button>
              </AccessControl>
            </View>
          )}
        </Col>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fetchDocumentsData: state.siteDocsDetails.sitedocdata,
    editDocumentsDataRes: state.siteDocsDetails.sitedoceditdata,
    lockDocumentsDataRes: state.siteDocsDetails.sitedoclockdata,
    deleteDocumentsDataRes: state.siteDocsDetails.sitedocdeletedata,
    uploadDocumentsDataRes: state.siteDocsDetails.sitedocuploaddata,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDocumentsFromStore: (SiteId) => dispatch(fetchSiteDocsData(SiteId)),
    updateDocumentName: (data) => dispatch(editSiteDocsData(data)),
    lockSiteDocs: (data) => dispatch(lockSiteDocsData(data)),
    addDocuments: (siteId, body) => dispatch(uploadSiteDocs(siteId, body)),
    deleteSiteDocs: (data) => dispatch(deleteSiteDocs(data)),
    clearState: () => dispatch(clearState()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UploadDocuments);
