import React from "react";
import { Text } from "native-base";
import { Image, TouchableOpacity, View, TextInput } from "react-native";
import { styles as iconButtonsStyles } from "./IconButtons.style";
import { Images } from "../../assets";
import { ToolTypes } from "../map/mapUtil";

const ToolBoxButtons = (props) => {
  const {
    label,
    onPress,
    imageName,
    width,
    toolData,
    pressed,
    drawType,
    onChangeDiameter,
    buttonStyle,
    modifywidth,
  } = props;
  const handleChange = (text) => {
    onChangeDiameter(text);
  };

  return (
    <View
      style={[
        {
          flex: 1,
          flexDirection: "row",
          justifyContent: "space-between",
          flexWrap: "nowrap",
        },
        buttonStyle,
      ]}
    >
      <TouchableOpacity onPress={onPress} style={{ width: "80%" }}>
        <View
          style={[
            iconButtonsStyles.buttonWrapper,
            { height: 29,width:`${modifywidth}` },
            width === "content" ? { width: "fit-content" } : { width: "auto" },
            pressed ? iconButtonsStyles.selected : "",
          ]}
        >
          {label ? (
            <View style={[iconButtonsStyles.textWrapper]}>
              <Text style={iconButtonsStyles.buttonLabel}>{label}</Text>
            </View>
          ) : (
            <></>
          )}
          {imageName ? (
            <View style={iconButtonsStyles.iconWrapper}>
              <Image
                style={[iconButtonsStyles.iconStyleSmall]}
                source={Images[imageName]}
              />
            </View>
          ) : (
            <></>
          )}
        </View>
      </TouchableOpacity>
      {drawType === ToolTypes.HOSE ||
      drawType === ToolTypes.SET_LAYOUT ||
      drawType === ToolTypes.PIPE_LINE ? (
        <View
          style={{
            width: "20%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TextInput
            keyboardType="numeric"
            autoCapitalize={"none"}
            defaultValue={width.toString()}
            style={[
              {
                width: 35,
                height: 28,
                fontSize: 12,
                marginTop:-4,
                color: "#FFFFFF",
                textAlign: "center",
                backgroundColor: "#0C0C0C",
                borderRadius: 4,
                borderColor: "#9F9F9F",
                borderWidth: 1,
              },
              // toolData.invalid ? {borderColor: "red"} : ""
            ]}
            onChangeText={handleChange}
          />
        </View>
      ) : (
        <View style={{ width: "40px" }}></View>
      )}
    </View>
  );
};
export default React.memo(ToolBoxButtons);
