import { StyleSheet } from 'react-native';
import { light_gray, lighter_gray, roboto, yellow } from '_style/Global.style';

export const styles = StyleSheet.create({
  main: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

  navNumber: {
    fontFamily: roboto,
    fontSize: 24,
    fontWeight: 'bold',
    color: light_gray,

    marginLeft: 20,
    marginRight: 20,

    borderBottomWidth: 1,
    borderBottomColor: light_gray,
  },

  activeNavNumber: {
    fontFamily: roboto,
    fontSize: 24,
    fontWeight: 'bold',
    color: yellow,

    marginLeft: 20,
    marginRight: 20,

    borderBottomWidth: 1,
    borderBottomColor: yellow,
  },

  disabledNavArrow: {
    color: light_gray,
    fontSize: 32,
    fontWeight: 'bold',
  },

  enabledNavArrow: {
    color: lighter_gray,
    fontSize: 32,
    fontWeight: 'bold',
  },
});
