import { StyleSheet } from "react-native";
import { green } from "_style/Global.style";

export const placeholderTextColor = "rgba(255, 255, 255, 0.6)";

export const textLight = "#FAFAFA";
export const textMedium = "#424242";
export const textDark = "#000000";
export const light_yellow = "#F2C94C";
export const yellow = "#EEB310";
export const red = "red";
export const lighter_gray = "#B3B3B3";
export const light_gray = "#424242";
export const dark_gray = "#212121";
export const darker_gray = "#1A1A1A";
export const container_gray = "#383838";
export const card_gray = "#B2B2B2";
export const card_yellow = yellow;
export const card_red = "#C82A32";
export const card_green = "#006644";
export const roboto = "Roboto";
export const robotoMono = "Roboto Mono";
export const robotoMonoBold = "Roboto Mono Bold";
export const robotoCondensed = "Roboto Condensed";
export const robotoCondensedBold = "Roboto Condensed Bold";

export const SFPro = "SF Pro";
// Common Configurations
const button = {
  borderRadius: 5,
};
const buttonText = {
  marginLeft: "auto",
  marginRight: "auto",
};
export const styles = StyleSheet.create({
  feedback: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    height: "100%",
    width: "100%",
    padding: 25,
    backgroundColor: "#383838",
    overflow: "auto",
  },
  SiteCreateModalContent: {
    width:"fit-content",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 55,
    marginRight: 55,
  },
  input: {
    width: 380,
    backgroundColor: light_gray,
    height: 41,
    borderColor: "rgba(255, 255, 255, 0.15)",
    borderRadius: 4,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    fontSize: 18,
    fontFamily: "SF Pro",
    color: "#FAFAFA",

    // marginBottom: 20,
  },
  item: {
    width: 380,
    borderBottomWidth: 0,
    marginLeft: 0,
  },
  confirmationButton: {
    ...button,
    width: 424,
    backgroundColor: yellow,
    shadowColor: "black",
    shadowRadius: 8,
    shadowOpacity: 0.5,
    shadowOffset: { width: 4, height: 4 },
    elevation: 0.5,
  },
  confirmationButtonText: {
    ...buttonText,
    color: textDark,
    fontWeight: "bold",
    letterSpacing: 1.6,
    fontFamily: "Roboto",
  },
  tableText: {
    textAlign: "left",
    fontSize: 16,
    fontFamily: "Roboto Condensed",
    marginRight: 5,
    width: 152,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#1A1A1A",
    width: "100%",
    borderRadius: 10,
    padding: 15,
    marginTop: 25,
  },
  submitFeedbackButtonContainer: {
    width: "100%",
    marginTop: 15,
  },
  submitFeedbackButton: {
    height: 88,
  },
  submitFeedbackButtonText: {
    fontSize: 48,
  },
  formTitle: {
    fontSize: 30,
    fontWeight: "bold",
  },
  formSubtitle: {
    fontSize: 14,
    textAlign: "center",
    maxWidth: 450,
    marginTop: 20,
  },
  mainForm: {
    marginTop: 25,
    width: "100%",
    maxWidth: 700,
  },
  formRow: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  formItem: {
    flex: 1,
    marginLeft: 5,
    marginRight: 5,
    marginBottom: 20,
  },
  errorMessage: {
    color: "red",
  },
  feedbackSuccessMessage: {
    color: green,
    fontSize: 64,
  },
});
