import { StyleSheet } from 'react-native';
import {
  yellow,
  textDark,
  textMedium,
} from '_style/Global.style';

export const styles = StyleSheet.create({
  deviceSettings: {
    width: "100%",
  },
  deviceSettingsContainer: {
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  deviceSettingsContent: {
    width: "100%",
    height: 432,
    paddingHorizontal: 0,
  },
  saveButton: {
    backgroundColor: yellow,
    flexDirection: 'row',
    width: "100%", 
    height: 40,
    borderRadius: 5,
    justifyContent: 'center',
  },
  saveButtonText: {
    margin: 'auto',
    fontSize: 18,
  },
  saveButtonTextDisabled: {
    color: textMedium,
  },
  saveButtonTextEnabled: {
    color: textDark,
  },
  savingSpinner: {
    height: 25,
  },
  errorText: {
    margin: 'auto',
    fontSize: 10,
    color: 'red',
  },
});
