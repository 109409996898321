import { API } from 'aws-amplify';
import { JOBS_API_ID } from '_config/api-constants';
import { makeActionCreator } from '_redux/rootActions';
import { updateLastViewedJob } from '_redux/dashboard/actions';
import { getLastViewedJobId } from '_redux/dashboard/selectors';

import { getUserCognitoSub } from '_redux/user/utils';

import {
  CLEAR_STATE,

  ADD_JOB_REQUEST,
  ADD_JOB_SUCCESS,
  ADD_JOB_FAILURE,

  EDIT_JOB_REQUEST,
  EDIT_JOB_SUCCESS,
  EDIT_JOB_FAILURE,

  GET_JOBS_REQUEST,
  GET_JOBS_SUCCESS,
  GET_JOBS_FAILURE,

  GET_USER_JOBS_REQUEST,
  GET_USER_JOBS_SUCCESS,
  GET_USER_JOBS_FAILURE,

  DELETE_JOB_REQUEST,
  DELETE_JOB_SUCCESS,
  DELETE_JOB_FAILURE,

  SET_DEFAULT_JOB_REQUEST,
  SET_DEFAULT_JOB_SUCCESS,
  SET_DEFAULT_JOB_FAILURE,

  UPDATE_DEVICE_ORDER_REQUEST,
  UPDATE_DEVICE_ORDER_SUCCESS,
  UPDATE_DEVICE_ORDER_FAILURE,

} from '_redux/jobs/types';
import {  } from './types';

export const clearState = makeActionCreator(CLEAR_STATE);

/**
 * Create and add a new job.
 *
 * @param {string} jobName name of the job
 * @param {Array<string>} deviceIds list of device ids to add to the job
 * @param {Array<string>} crewMemberIds list of crew member ids to add to the job
 * @param {boolean} isDefault
 */
export const addJob = (jobName, deviceIds, crewMemberIds, isDefault, cognitoSub) => {
  return async (dispatch) => {
    dispatch(addJobRequest());

    try {
      if (!cognitoSub) {
        cognitoSub = await getUserCognitoSub();
      }

      const body = {
        jobName,
        cognitoSub,
        deviceIds,
        crewMemberIds,
        isDefault,
      };

      await API.post(JOBS_API_ID, '/jobs', { body });
      dispatch(getJobs());
      dispatch(addJobSuccess());

    } catch (e) {
      console.error(e);
      dispatch(addJobFailure());
    }
  };
};
export const addJobRequest = makeActionCreator(ADD_JOB_REQUEST);
export const addJobSuccess = makeActionCreator(ADD_JOB_SUCCESS);
export const addJobFailure = makeActionCreator(ADD_JOB_FAILURE);

/**
 * Edit an existing job.
 *
 * @param {number} jobId
 * @param {string} jobName
 * @param {array<string>} deviceIds
 * @param {array<string>} crewMemberIds
 * @param {boolean} isDefault
 */
export const editJob = (jobId, jobName, deviceIds, crewMemberIds, isDefault, cognitoSub) => {
  return async (dispatch) => {
    dispatch(editJobRequest());

    try {
      if (!cognitoSub) {
        cognitoSub = await getUserCognitoSub();
      }

      const body = {
        jobName,
        cognitoSub,
        deviceIds,
        crewMemberIds,
        isDefault,
      };
      console.log(body);
      await API.put(JOBS_API_ID, `/jobs/${jobId}`, { body });
      dispatch(getJobs());
      dispatch(editJobSuccess());

    } catch (e) {
      console.error(e);
      dispatch(editJobFailure());
    }
  };
};
export const editJobRequest = makeActionCreator(EDIT_JOB_REQUEST);
export const editJobSuccess = makeActionCreator(EDIT_JOB_SUCCESS);
export const editJobFailure = makeActionCreator(EDIT_JOB_FAILURE);


/**
 * Get the jobs for the current user.
 */
export const getJobs = () => {
  return async (dispatch) => {
    dispatch(getJobsRequest());

    try {
      const cognitoSub = await getUserCognitoSub();

      const jobs = await API.get(JOBS_API_ID, `/user/${encodeURIComponent(cognitoSub)}/jobs`);

      dispatch(getJobsSuccess(jobs));

    } catch (e) {
      console.error(e);
      dispatch(getJobsFailure());
    }
  };
};
export const getJobsRequest = makeActionCreator(GET_JOBS_REQUEST);
export const getJobsSuccess = makeActionCreator(GET_JOBS_SUCCESS, 'jobs');
export const getJobsFailure = makeActionCreator(GET_JOBS_FAILURE);

/**
 * Get the jobs for a specific user.
 */
export const getUserJobs = (cognitoSub) => {
  return async (dispatch) => {
    dispatch(getUserJobsRequest());

    try {
      const jobs = await API.get(JOBS_API_ID, `/user/${encodeURIComponent(cognitoSub)}/jobs`);

      dispatch(getUserJobsSuccess(jobs));

    } catch (e) {
      console.error(e);
      dispatch(getUserJobsFailure());
    }
  };
};
export const getUserJobsRequest = makeActionCreator(GET_USER_JOBS_REQUEST);
export const getUserJobsSuccess = makeActionCreator(GET_USER_JOBS_SUCCESS, 'jobs');
export const getUserJobsFailure = makeActionCreator(GET_USER_JOBS_FAILURE);

/**
 * Delete a single job.
 *
 * @param {number} jobId
 */
export const deleteJob = (jobId) => {
  return async (dispatch, getState) => {
    dispatch(deleteJobRequest());

    try {
      await API.del(JOBS_API_ID, `/jobs/${encodeURIComponent(jobId)}`);

      dispatch(getJobs());
      dispatch(deleteJobSuccess());

      // If the user deleted the job that they viewed last,
      // clear the last viewed job out of the state.
      const lastViewedJobId = getLastViewedJobId(getState());
      if (jobId === lastViewedJobId) {
        dispatch(updateLastViewedJob(null));
      }


    } catch (e) {
      console.error(e);
      dispatch(deleteJobFailure());
    }
  };
};
export const deleteJobRequest = makeActionCreator(DELETE_JOB_REQUEST);
export const deleteJobSuccess = makeActionCreator(DELETE_JOB_SUCCESS);
export const deleteJobFailure = makeActionCreator(DELETE_JOB_FAILURE);

/**
 * Set a job as the default.
 *
 * @param {string} ownerId
 * @param {number} jobId
 */
export const setDefaultJob = (ownerId, jobId) => {
  return async (dispatch) => {
    dispatch(setDefaultJobRequest());

    try {
      ownerId = encodeURIComponent(ownerId);
      jobId = encodeURIComponent(jobId);

      await API.post(
        JOBS_API_ID,
        `/user/${ownerId}/jobs/${jobId}/default`,
      );

      dispatch(setDefaultJobSuccess(jobId));

    } catch (e) {
      console.error(e);
      dispatch(setDefaultJobFailure());
    }
  };
};
export const setDefaultJobRequest = makeActionCreator(SET_DEFAULT_JOB_REQUEST);
export const setDefaultJobSuccess = makeActionCreator(SET_DEFAULT_JOB_SUCCESS, 'jobId');
export const setDefaultJobFailure = makeActionCreator(SET_DEFAULT_JOB_FAILURE);

/**
 * Set a job as the default.
 *
 * @param {string} ownerId
 * @param {number} jobId
 */
export const updateJobDeviceOrder = (jobId, deviceIds) => {
  return async (dispatch) => {
    dispatch(updateJobDeviceOrderRequest());

    try {

      await API.put(
        JOBS_API_ID,
        `/jobs/${jobId}/deviceOrder`,
        {
          body: {
            deviceIds,
          },
        },
      );

      dispatch(updateJobDeviceOrderSuccess());

    } catch (e) {
      console.error(e);
      dispatch(updateJobDeviceOrderFailure(e.response.data));
    }
  };
};
export const updateJobDeviceOrderRequest = makeActionCreator(UPDATE_DEVICE_ORDER_REQUEST);
export const updateJobDeviceOrderSuccess = makeActionCreator(UPDATE_DEVICE_ORDER_SUCCESS);
export const updateJobDeviceOrderFailure = makeActionCreator(UPDATE_DEVICE_ORDER_FAILURE, 'error');
