import { StyleSheet } from 'react-native';
import {
  darker_gray,
  red,
  textLight,
  yellow,
  light_gray,
} from '_style/Global.style';

export const styles = StyleSheet.create({
  devices: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    height: '100%',
    width: '100%',
    padding: 25,
    backgroundColor: '#383838',
  },
  deviceTable: {
    backgroundColor: '#1A1A1A',
    width: '100%',
    borderRadius: 10,
    padding: 15,
    marginTop: 25,
  },
  deviceRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 58,
    marginLeft: 10,
    marginRight: 10,
  },
  lightRow: {
    backgroundColor: '#212121',
  },
  deviceDetailsContainer: {
    marginLeft: 10
  },
  deviceId: {
    fontSize: 18,
    letterSpacing: 1.6,
  },
  deviceName: {
    fontSize: 12,
    letterSpacing: 1.6,
  },

  confirmRemovalModalContent: {
    width: 425,
    marginLeft: 75,
    marginRight: 75,
  },
  confirmRemovalText: {
    textAlign: 'center',
    marginBottom: 40,
    letterSpacing: 1.6,
  },

  addDeviceButtonContainer: {
    width: '100%',
    marginTop: 15,
  },
  addDeviceButton: {
    height: 88,
  },
  addDeviceButtonText: {
    fontSize: 48,
  },
  addDeviceModalContent: {
    width: 425,
    marginLeft: 75,
    marginRight: 75,
  },
  addDeviceModalText: {
    marginBottom: 15,
    textAlign: 'center',
    letterSpacing: 1.6,
  },
  deviceCodeContainer: {
    width: 395,
    height: 65,
    borderRadius: 4,
    marginBottom: 45,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 10,
    paddingBottom: 10,
  },
  deviceCodePrefixText: {
    fontSize: 24,
    textAlign: 'center',
    padding: 10,
    letterSpacing: 5,
  },
  codeInput: {
    fontSize: 64,
    height: 65,
    width: 395,
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    backgroundColor: darker_gray,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    fontFamily: 'Roboto',
  },

  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  editDeviceModalContent: {
    width: 425,
    marginLeft: 75,
    marginRight: 75,
  },

  editDeviceModalText: {
    marginBottom: 20,
    fontSize: 14,
    alignSelf: 'center',
    letterSpacing: 1.6
  },

  editDeviceCurrentIdText: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 20,
  },

  errorMessage: {
    color: red,
    textAlign: 'center',
    padding: 20,
  },
    
  nameDeviceHeader: {
    color: textLight,
    textAlign: 'center',
    letterSpacing: 1.6,
    marginBottom: 0,
  },

  nameDeviceFooter: {
    color: yellow,
    textAlign: 'center',
    letterSpacing: 1.6,
    marginTop: 0,
    marginBottom: 25,
  },

  nameDeviceFormInput: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 10,
    paddingBottom: 10,
  },

  nameDeviceItem: {
    width: '100%',
    borderBottomWidth: 0,
    marginLeft: 0,
  },

  nameDeviceInput: {
    width: '100%',

    backgroundColor: darker_gray,
    height: 40,
    borderColor: 'rgba(255, 255, 255, 0.15)',
    borderRadius: 4,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderBottomWidth: 0,

    fontSize: 18,
    fontFamily: 'Roboto',
    color: '#FAFAFA',

    textAlign: 'center',

    paddingLeft: 20,
    paddingRight: 20,
    // marginBottom: 20,
  },

});
