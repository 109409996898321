// Redux
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

// Reducers
import adminReducer from '_redux/admin/reducers';
import crewMembersReducer from '_redux/crewMembers/reducers';
import dashboardReducer from '_redux/dashboard/reducers';
import feedbackReducer from '_redux/feedback/reducers';
import iotReducer from '_redux/iot/reducers';
import jobReducer from '_redux/jobs/reducers';
import toastsReducer from '_redux/toasts/reducers';
import userReducer from '_redux/user/reducers';
import { CLEAR_STATE } from '_redux/rootActions';

import siteMapReducer from '_redux/siteMap/reducers';
import siteDetailsReducer from '_redux/site/reducers';
import siteDocumentsReducer from '_redux/siteDocuments/reducers';

// Config
import { DISABLE_REDUX_LOGS } from '_config/_aws-exports.js';

const enhancerList = [];
const devToolsExtension = window && window.__REDUX_DEVTOOLS_EXTENSION__;

if (typeof devToolsExtension === 'function') {
  enhancerList.push(devToolsExtension());
}

// Only create the redux logger for development.
if (!DISABLE_REDUX_LOGS) {
  enhancerList.push(applyMiddleware(thunk, createLogger()));
} else {
  enhancerList.push(applyMiddleware(thunk));
}

const appReducer = combineReducers({
  admin: adminReducer,
  dashboard: dashboardReducer,
  crewMembers: crewMembersReducer,
  feedback: feedbackReducer,
  iot: iotReducer,
  job: jobReducer,
  toasts: toastsReducer,
  user: userReducer,
  siteMap: siteMapReducer,
  siteDetails: siteDetailsReducer,
  siteDocsDetails: siteDocumentsReducer,
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if(action.type === CLEAR_STATE)
     state = undefined;

  return appReducer(state, action);
};

// Initialize the store with our reducers and our enhancers (e.g. thunk)
const initStore = () => createStore(
  rootReducer,
  compose(...enhancerList),
);

export { initStore };
