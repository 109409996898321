import { createReducer } from '_redux/rootActions';

// Types
import {
  CLEAR_STATE,

  ADD_JOB_REQUEST,
  ADD_JOB_SUCCESS,
  ADD_JOB_FAILURE,

  EDIT_JOB_REQUEST,
  EDIT_JOB_SUCCESS,
  EDIT_JOB_FAILURE,

  GET_JOBS_REQUEST,
  GET_JOBS_SUCCESS,
  GET_JOBS_FAILURE,

  GET_USER_JOBS_REQUEST,
  GET_USER_JOBS_SUCCESS,
  GET_USER_JOBS_FAILURE,

  DELETE_JOB_REQUEST,
  DELETE_JOB_SUCCESS,
  DELETE_JOB_FAILURE,

  SET_DEFAULT_JOB_REQUEST,
  SET_DEFAULT_JOB_SUCCESS,
  SET_DEFAULT_JOB_FAILURE,

  UPDATE_DEVICE_ORDER_REQUEST,
  UPDATE_DEVICE_ORDER_SUCCESS,
  UPDATE_DEVICE_ORDER_FAILURE,
} from '_redux/jobs/types';

const initialState = {
  isAddingJob: false,
  isGettingJobs: false,
  isGettingUserJobs: false,
  isDeletingJob: false,
  isEditingJob: false,
  isSettingDefaultJob: false,
  jobs: [],

  isUpdatingDeviceOrder: false,
  updateDeviceOrderFailed: false,
  updateDeviceOrderError: '',
};

export default createReducer(initialState, {
  // CLEAR STATE
  [CLEAR_STATE]: () => initialState,

  // ADD JOB
  [ADD_JOB_REQUEST]: (state) => {
    return {...state, isAddingJob: true};
  },
  [ADD_JOB_SUCCESS]: (state) => {
    return {...state, isAddingJob: false};
  },
  [ADD_JOB_FAILURE]: (state) => {
    return {...state, isAddingJob: false};
  },

  // EDIT JOB
  [EDIT_JOB_REQUEST]: (state) => {
    return {...state, isEditingJob: true};
  },
  [EDIT_JOB_SUCCESS]: (state) => {
    return {...state, isEditingJob: false};
  },
  [EDIT_JOB_FAILURE]: (state) => {
    return {...state, isEditingJob: false};
  },

  // GET JOBS
  [GET_JOBS_REQUEST]: (state) => {
    return {...state, isGettingJobs: true };
  },
  [GET_JOBS_SUCCESS]: (state, action) => {
    const { jobs } = action;

    return {...state, isGettingJobs: false, jobs };
  },
  [GET_JOBS_FAILURE]: (state) => {
    return {...state, isGettingJobs: false };
  },

  // GET USER JOBS
  [GET_USER_JOBS_REQUEST]: (state) => {
    return {...state, isGettingUserJobs: true };
  },
  [GET_USER_JOBS_SUCCESS]: (state, action) => {
    const { jobs } = action;

    return {...state, isGettingUserJobs: false, jobs };
  },
  [GET_USER_JOBS_FAILURE]: (state) => {
    return {...state, isGettingUserJobs: false };
  },

  // DELETE JOB
  [DELETE_JOB_REQUEST]: (state) => {
    return {...state, isDeletingJob: true };
  },
  [DELETE_JOB_SUCCESS]: (state) => {
    return {...state, isDeletingJob: false };
  },
  [DELETE_JOB_FAILURE]: (state) => {
    return {...state, isDeletingJob: false };
  },

  // SET DEFAULT JOB
  [SET_DEFAULT_JOB_REQUEST]: (state) => {
    return {...state, isSettingDefaultJob: true };
  },
  [SET_DEFAULT_JOB_SUCCESS]: (state, action) => {
    return updateDefaultJob(state, action);
  },
  [SET_DEFAULT_JOB_FAILURE]: (state) => {
    return {...state, isSettingDefaultJob: false };
  },

  // UPDATE DEVICE ORDER
  [UPDATE_DEVICE_ORDER_REQUEST]: (state) => {
    return {
      ...state,
      isUpdatingDeviceOrder: true,
      updateDeviceOrderFailed: false,
      updateDeviceOrderError: '',
    };
  },
  [UPDATE_DEVICE_ORDER_SUCCESS]: (state) => {
    return {
      ...state,
      isUpdatingDeviceOrder: false,
      updateDeviceOrderFailed: false,
      updateDeviceOrderError: '',
    };
  },
  [UPDATE_DEVICE_ORDER_FAILURE]: (state, action) => {
    const { error } = action;

    return {
      ...state,
      isUpdatingDeviceOrder: false,
      updateDeviceOrderFailed: true,
      updateDeviceOrderError: error,
    };
  },

});

/**
 * Redux safe update of the jobs in the state to reflect a new default job.
 *
 * @param {*} state
 * @param {*} action
 */
const updateDefaultJob = (state, action) => {
  const { jobId } = action;

  const jobIndex = state.jobs.findIndex((job) => job.job_id === Number(jobId));

  const updatedJobs = state.jobs.map((item, index) => {
    // If this isn't the new default job, set the is_default prop to 0
    if (index !== jobIndex) {
      return {
        ...item,
        is_default: 0,
      };
    // Otherwise this is the new default job so set the is_default prop to 1
    } else {
      return {
        ...item,
        is_default: 1,
      };
    }
  });

  return { ...state, jobs: updatedJobs, isSettingDefaultJob: false };
};
