import { StyleSheet } from 'react-native';
import {
  darker_gray,
  red,
  roboto,
  textLight,
  yellow,
} from '_style/Global.style';

export const styles = StyleSheet.create({
  main: {
    width: 650,
    alignItems: 'center',
  },
  actionButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 50,
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 625,
  },
  leftCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 250,
    flex: 1,
    padding: 10,
  },
  rightCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    height: 250,
    overflow: 'auto',
    backgroundColor: darker_gray,
    borderRadius: 2,
    padding: 20,
  },
  groupContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  groupTitleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  groupLabelText: {
    fontFamily: roboto,
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: 1.6,
  },
  selectedGroupText: {
    fontFamily: roboto,
    fontSize: 14,
    letterSpacing: 1.6,
  },
  groupInputRow: {
    backgroundColor: darker_gray,
    padding: 10,
    width: '100%',
    marginTop: 5,
    borderRadius: 2,
  },

  actionButton: {
    width: 425,
    marginTop: 10,
    marginBottom: 10,
  },
  editButton: {
    width: 55,
    height: 20,
    backgroundColor: yellow,
  },
  editButtonText: {
    fontFamily: roboto,
    fontSize: 12,
    fontWeight: 'bold',
    marginLeft: 'auto',
    marginRight: 'auto',
    letterSpacing: 1.6,
    paddingLeft: 0,
    paddingRight: 0,
  },

  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    flex: 1,
    overflow: 'auto',
  },
  detailsTitleText: {
    fontFamily: roboto,
    color: textLight,
    letterSpacing: 1.6,
    fontSize: 14,
    width: '100%',
    marginLeft: 10,
    marginRight: 10,
    padding: 5,
  },

  detailsContentRow: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflow: 'auto',
    padding: 10,
    flex: 1,
  },

  detailsFooterRow: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginTop: 10,
  },

  selectButton: {
    width: 170,
    height: 20,
    backgroundColor: yellow,
  },
  selectButtonText: {
    fontFamily: roboto,
    fontSize: 12,
    fontWeight: 'bold',
    marginLeft: 'auto',
    marginRight: 'auto',
    letterSpacing: 1.6,
    paddingLeft: 0,
    paddingRight: 0,
  },
  checkboxRow: {
    width: '100%',
    padding: 5,
  },

  errorMessage: {
    color: red,
    fontFamily: roboto,
    fontSize: 14,
    textAlign: 'center',
    letterSpacing: 1.6,
    padding: 20,
  },
  deviceId: {
    fontFamily: roboto,
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: 1.6,
    marginBottom: 30,
  },
});
