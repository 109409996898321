import { StyleSheet } from "react-native";

export const styles2 = StyleSheet.create({
  main: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
    height: "100%",
    width: "100%",
    padding: 25,
    overflow: "auto",
    backgroundColor: "#383838",
    // width: 688,
    // height: 671,
  },

  menuIcons: {
    width: 35,
    height: 38,
    marginLeft: 10,
    marginRight: 10,
  },
  archivedIcons: {
    width: 35,
    height: 38,
    marginLeft: 14,
    marginRight: 14,
  },
  editIcon: {
    width: 28,
    height: 29,
    marginRight: 20,
    marginLeft: 20,
  },
  editIcon2: {
    width: 35,
    height: 38,
    marginRight: 10,
    marginLeft: 14,
  },
  notifications: {},
  SiteCreateModalContent: {
    height: "100%",
    width: "fit-content",
    marginLeft: 75,
    marginRight: 75,
  },
  buttonSize: {
    width: 75,
    height: 75,
  },
  buttonSize: {
    hover: {
      width: 85,
      height: 85,
    },
  },
  SiteCongrtzModalContent: {
    borderRadius: 10,
    marginLeft: 75,
    marginRight: 75,
  },
  buttonsizeImageMap: {
    width: 20,
    height: 29,
  },
  chocolateIconContent: {
    width: "3%",
    alignItems: "center",
    justifyContent: "center",
  },
  chocoloteIcon: {
    width: 12,
    height: 18,
  },
  mTop: {
    marginRight: 8,
  },
  listFontSize: {
    fontSize: 18,
  },
  listFieldFont: {
    fontSize: 12,
    marginTop: 6,
    color: "#EEB310",
  },
  orderBtnContainer: {
    width: "10%",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingRight: 16,
  },
  listAddrztext: {
    textDecorationLine: "underline",
    width: "28%",
    cursor: "pointer",
    fontSize: 11,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  rowLeftDiv: {
    width: "20%",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  menuButton: {
    width: 30,
    height: 6,
    marginTop: 12,
    marginRight: 1,
  },
  archiveButton: {
    color: "#757575",
    textAlign: "center",
    fontSize: 12,
    padding: 2,
  },
  listRow: {
    flex: 1,
    width: "100%",
    alignItems: "stretch",
  },
  activeSiteBtn: {
    width: "100%",
    backgroundColor: "#212121",
    height: 42,
  },
  activeSiteBtnText: {
    width: "100%",
    textAlign: "center",
    fontSize: 16,
    color: "#757575",
  },
  archivedBtnDiv: {
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    flexDirection: "row",
  },
  archivedBtnImg: {
    width: 21,
    height: 20,
    marginTop: 2,
    marginRight: 10,
  },
  archiveBtnTxt: {
    textAlign: "center",
    fontSize: 16,
    color: "#FFFFFF",
  },
});
