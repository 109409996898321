// React
import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";

// UI Framework
import { Button, Text, View } from "native-base";

// Style
import { styles as global } from "_style/Global.style";
import { styles } from "./Archive.style";

class ArchiveModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { confirmationText, submitButtonText, cancelButtonText } = this.props;

    return (
      <View style={[styles.confirmRemovalModalContent]}>
        <Text style={[styles.confirmRemovalText]}>{confirmationText}</Text>
        <Button
          style={[global.confirmationButton, styles.btnstyle]}
          onPress={this.props.submit}
        >
          <Text style={[global.confirmationButtonText, { fontSize: 18 }]}>
            {submitButtonText}
          </Text>
        </Button>
        <Button
          style={[global.cancelButton, styles.btnstyle]}
          onPress={this.props.onCancel()}
        >
          <Text style={[global.cancelButtonText, { fontSize: 18,color: "#FAFAFA" }]}>
            {cancelButtonText}
          </Text>
        </Button>
      </View>
    );
  }
}

ArchiveModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string,
  confirmationText: PropTypes.any,
  cancelButtonText: PropTypes.string,
};

export default connect(null, null)(ArchiveModal);
