export const FAULT_CODES = {
  "524100": {
    faultMessage:
      "J1939 CANBUS FAILURE, CONTROLLER UNABLE TO COMMUNICATE WITH ENGINE ECU/ECM",
    actionMessage:
      "PLEASE CHECK YOUR EQUIPMENT, AND POWER CYCLE THE CONTROLLER TO CONTINUE BY USING THE E - STOP",
  },
  "524101": {
    faultMessage: "AUTO MODE NO MANUAL START",

    actionMessage: "",
  },
  "524102": {
    faultMessage: "STOP BUTTON PRESSED",

    actionMessage: "",
  },
  "524103": {
    faultMessage: "OVERSPEED",

    actionMessage:
      "PLEASE CHECK YOUR EQUIPMENT, AND POWER CYCLE THE CONTROLLER TO CONTINUE BY USING THE E - STOP",
  },
  "524104": {
    faultMessage: "UNDERSPEED",

    actionMessage:
      "PLEASE CHECK YOUR EQUIPMENT, AND POWER CYCLE THE CONTROLLER TO CONTINUE BY USING THE E - STOP",
  },
  "524105": {
    faultMessage: "ECU RELAY NOT SET/ACTIVATED",

    actionMessage:
      "PLEASE CHECK YOUR EQUIPMENT, AND POWER CYCLE THE CONTROLLER TO CONTINUE BY USING THE E - STOP",
  },
  "524106": {
    faultMessage: "RPM ABOVE ZERO IN THE STOPPED STATE",

    actionMessage: "",
  },
  "524107": {
    faultMessage: "MANUAL MODE NO AUTO START",

    actionMessage: "",
  },
  "524108": {
    faultMessage: "OVERCRANK",

    actionMessage:
      "PLEASE CHECK YOUR EQUIPMENT, AND POWER CYCLE THE CONTROLLER TO CONTINUE BY USING THE E - STOP",
  },
  "524109": {
    faultMessage: "LOW OIL PRESSURE",

    actionMessage:
      "PLEASE CHECK YOUR EQUIPMENT, AND POWER CYCLE THE CONTROLLER TO CONTINUE BY USING THE E - STOP",
  },
  "524110": {
    faultMessage: "HIGH OIL PRESSURE",

    actionMessage:
      "PLEASE CHECK YOUR EQUIPMENT, AND POWER CYCLE THE CONTROLLER TO CONTINUE BY USING THE E - STOP",
  },
  "524111": {
    faultMessage: "HIGH OIL TEMPERATURE",

    actionMessage:
      "PLEASE CHECK YOUR EQUIPMENT, AND POWER CYCLE THE CONTROLLER TO CONTINUE BY USING THE E - STOP",
  },
  "524112": {
    faultMessage: "LOW ENGINE TEMPERATURE",

    actionMessage:
      "PLEASE CHECK YOUR EQUIPMENT, AND POWER CYCLE THE CONTROLLER TO CONTINUE BY USING THE E - STOP",
  },
  "524113": {
    faultMessage: "HIGH ENGINE TEMPERATURE",

    actionMessage:
      "PLEASE CHECK YOUR EQUIPMENT, AND POWER CYCLE THE CONTROLLER TO CONTINUE BY USING THE E - STOP",
  },
  "524114": {
    faultMessage: "LOW FUEL LEVEL",

    actionMessage:
      "PLEASE CHECK YOUR EQUIPMENT, AND POWER CYCLE THE CONTROLLER TO CONTINUE BY USING THE E - STOP",
  },
  "524115": {
    faultMessage: "WEAK BATTERY",

    actionMessage: "",
  },
  "524116": {
    faultMessage: "LOW BATTERY",

    actionMessage: "",
  },
  "524117": {
    faultMessage: "HIGH BATTERY",

    actionMessage: "",
  },
  "524118": {
    faultMessage: "LOW DISCHARGE PRESSURE",

    actionMessage:
      "PLEASE CHECK YOUR EQUIPMENT, AND POWER CYCLE THE CONTROLLER TO CONTINUE BY USING THE E - STOP",
  },
  "524119": {
    faultMessage: "HIGH DISCHARGE PRESSURE",

    actionMessage:
      "PLEASE CHECK YOUR EQUIPMENT, AND POWER CYCLE THE CONTROLLER TO CONTINUE BY USING THE E - STOP",
  },
  "524120": {
    faultMessage: "RUN TO DESTRUCT MODE ENABLED",

    actionMessage: "",
  },
  "524121": {
    faultMessage: "SPEED SIGNAL LOST",

    actionMessage:
      "PLEASE CHECK YOUR EQUIPMENT, AND POWER CYCLE THE CONTROLLER TO CONTINUE BY USING THE E - STOP",
  },
  "524122": {
    faultMessage: "NOT IN AUTO",

    actionMessage: "",
  },
  "524123": {
    faultMessage: "TRANSDUCER SHORTED",

    actionMessage:
      "PLEASE CHECK YOUR EQUIPMENT, AND POWER CYCLE THE CONTROLLER TO CONTINUE BY USING THE E - STOP",
  },
  "524124": {
    faultMessage: "TRANSDUCER OPEN",

    actionMessage:
      "PLEASE CHECK YOUR EQUIPMENT, AND POWER CYCLE THE CONTROLLER TO CONTINUE BY USING THE E - STOP",
  },
  "524125": {
    faultMessage: "NO FLYWHEEL TEETH SET",

    actionMessage:
      "PLEASE CHECK YOUR EQUIPMENT, AND POWER CYCLE THE CONTROLLER TO CONTINUE BY USING THE E - STOP",
  },
  "524126": {
    faultMessage: "BATTERY CHARGER FAILURE",

    actionMessage: "",
  },
  "524127": {
    faultMessage: "LOW LUBE LEVEL",

    actionMessage:
      "PLEASE CHECK YOUR EQUIPMENT, AND POWER CYCLE THE CONTROLLER TO CONTINUE BY USING THE E - STOP",
  },
  "524128": {
    faultMessage: "FUEL LEAK",

    actionMessage:
      "PLEASE CHECK YOUR EQUIPMENT, AND POWER CYCLE THE CONTROLLER TO CONTINUE BY USING THE E - STOP",
  },
  "524130": {
    faultMessage: "FUEL FILTER RESTRICTION",

    actionMessage: "",
  },
  "524131": {
    faultMessage: "AIR DAMPER CLOSED",

    actionMessage: "",
  },
  "524132": {
    faultMessage: "AIR FILTER RESTRICTION",

    actionMessage: "",
  },
  "524133": {
    faultMessage: "OIL FILTER RESTRICTION",

    actionMessage: "",
  },
  "524134": {
    faultMessage: "EMERGENCY STOP",

    actionMessage:
      "PLEASE CHECK YOUR EQUIPMENT, AND POWER CYCLE THE CONTROLLER TO CONTINUE BY USING THE E - STOP",
  },
  "524135": {
    faultMessage: "USER INPUT FAULT",

    actionMessage: "",
  },
  "524136": {
    faultMessage: "AUTO START INHIBIT INPUT",

    actionMessage: "",
  },
  "524137": {
    faultMessage: "MANUAL MODE ONLY",

    actionMessage: "",
  },
  "524138": {
    faultMessage: "OIL LIFE EXPIRED",

    actionMessage: "",
  },
  "524139": {
    faultMessage: "OIL FILTER LIFE EXPIRED",

    actionMessage: "",
  },
  "524140": {
    faultMessage: "BELT LIFE EXPIRED",

    actionMessage: "",
  },
  "524141": {
    faultMessage: "BATTERY LIFE EXPIRED",

    actionMessage: "",
  },
  "524142": {
    faultMessage: "FUEL FILTER LIFE EXPIRED",

    actionMessage: "",
  },
  "524143": {
    faultMessage: "AIR FILTER LIFE EXPIRED",

    actionMessage: "",
  },
  "524144": {
    faultMessage: "NO FLYWHEEL TEETH SET",

    actionMessage: "",
  },
  "524145": {
    faultMessage: "NO TEMPERATURE SENDER SET",

    actionMessage:
      "PLEASE CHECK YOUR EQUIPMENT, AND POWER CYCLE THE CONTROLLER TO CONTINUE BY USING THE E - STOP",
  },
  "524146": {
    faultMessage: "NO OIL PRESSURE SENDER SET",

    actionMessage:
      "PLEASE CHECK YOUR EQUIPMENT, AND POWER CYCLE THE CONTROLLER TO CONTINUE BY USING THE E - STOP",
  },
  "524147": {
    faultMessage: "REGEN NEEDED LOW",

    actionMessage: "",
  },
  "524148": {
    faultMessage: "REGEN NEEDED MODERATE",

    actionMessage: "",
  },
  "524149": {
    faultMessage: "REGEN NEEDED HIGH",

    actionMessage: "",
  },
  "524150": {
    faultMessage: "REGEN NEEDED UNKNOWN",

    actionMessage: "",
  },
  "524151": {
    faultMessage: "PASSIVE REGEN",

    actionMessage: "",
  },
  "524152": {
    faultMessage: "ACTIVE REGEN",

    actionMessage: "",
  },
  "524153": {
    faultMessage: "ENGINE COOLANT LEVEL LOW",

    actionMessage: "",
  },
  "524154": {
    faultMessage: "NO FLOW",

    actionMessage: "",
  },
  "524177": {
    faultMessage: "HIGH PUMP OIL TEMPERATURE",

    actionMessage:
      "PLEASE CHECK YOUR EQUIPMENT, AND POWER CYCLE THE CONTROLLER TO CONTINUE BY USING THE E - STOP",
  },
  "524178": {
    faultMessage: "HIGH PUMP HOUSING TEMPERATURE",

    actionMessage:
      "PLEASE CHECK YOUR EQUIPMENT, AND POWER CYCLE THE CONTROLLER TO CONTINUE BY USING THE E - STOP",
  },
  "524179": {
    faultMessage: "WATER IN FUEL",

    actionMessage: "",
  },
  "524180": {
    faultMessage: "LOW ENGINE COOLANT TEMPERATURE",

    actionMessage:
      "PLEASE CHECK YOUR EQUIPMENT, AND POWER CYCLE THE CONTROLLER TO CONTINUE BY USING THE E - STOP",
  },
  "524187": {
    faultMessage: "LOW PUMP SUCTION PRESSURE SHUTDOWN",

    actionMessage:
      "PLEASE CHECK YOUR EQUIPMENT, AND POWER CYCLE THE CONTROLLER TO CONTINUE BY USING THE E - STOP",
  },
  "524188": {
    faultMessage: "LINE BREAK DETECTED - ENGINE SENT TO IDLE",

    actionMessage: "PLEASE CHECK YOUR LINES AND PUMPS",
  },
};
