import React from "react";
import { GoogleMap } from "@react-google-maps/api";
import { Col, Row, Grid } from "react-native-easy-grid";
import { Tabs, Tab, Spinner, Text } from "native-base";
import { View } from "react-native";
import ToolBoxButtons from "../common/ToolBoxButton.component";
import UndoRedoButton from "../common/UndoRedoButton";
import MapElements from "./MapElements";
import TopBarPop from "./TopBarPopup";
import { styles } from "./MapEditor.style";
import { connect } from "react-redux";
import { navigate } from "_routes/RootNavigation";

import AccessControl, {
  ADMIN,
  MANUFACTURER,
  CREW_LEADER,
  CREW_MEMBER,
  DEALER,
  OWNER,
} from "_components/auth/AccessControl.component";
import { showToast } from "_redux/toasts/actions";
import { getUserGroups } from "_redux/user/selectors";

const containerStyle = {
  height: "100%",
  width: "100%",
};

import GroupPanel from "./GroupPanel";
import {
  DrawElementConst,
  GoogleDrawType,
  GoogleMapType,
  ToolTypes,
  ToolsButtons,
  UNDO_REDO_CONSTANTS,
  calculateStrokeWeight,
  StrokeMinWeight,
} from "./mapUtil";
import { ScrollView } from "react-native";
import { Images } from "../../assets";
import { DistanceUtil } from "../../../util/distance.util";

function MapEditor(props) {
  const [map, setMap] = React.useState(null);
  const [mapReadOnly, setMapReadOnly] = React.useState(false);
  const [mapDataChange, setMapDataChange] = React.useState(false);
  const [drawData, setDrawData] = React.useState(props.elements);
  const [tools, setTools] = React.useState(ToolsButtons);
  const [type, setType] = React.useState("");
  const [selectedDrawType, setSelectedDrawType] = React.useState(null);
  const [drawingManager, setDrawingManager] = React.useState(null);
  const [selected, setSelected] = React.useState(null);
  const [tabPage, setTabPage] = React.useState(0);
  const [editMode, setEditMode] = React.useState(DrawElementConst.EDIT);
  const [groups, setGroups] = React.useState(props.groups);
  const [groupsChanged, setGroupsChanged] = React.useState(false);
  const [selectedGroup, setSelectedGroup] = React.useState(null);
  const [groupEdit, setGroupEdit] = React.useState(false);
  const [center, setCenter] = React.useState(props.location);
  const [oldZoomVal, setOldZoomVal] = React.useState(0);
  const [zoom, setZoom] = React.useState(props.zoom);
  const [undoRedoStacks, setUndoRedoStacks] = React.useState({
    undoLength: -1,
    redoLength: -1,
  });
  const [deletedItemId, setDeletedItemId] = React.useState(null);
  const [mapOptions, setMapOptions] = React.useState({
    mapTypeId: GoogleMapType.SATELLITE,
    mapTypeControl: true,
    draggable: true,
    scrollwheel: true,
    panControl: true,
    zoom: zoom,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
  });
  const [zoomedAreaSize, setZoomedAreaSize] = React.useState(0);
  const tabRef = React.useRef(null);
  const childRef = React.useRef();
  const childRefTopBar = React.useRef();

  React.useEffect(() => {
    const { userGroups, isMapDataLoaded } = props;
    setDrawData([]);
    setGroups([]);
    setTimeout(() => {
      setDrawData(props.elements);
      setGroups(props.groups);
    });
    setSelected(null);
    setUndoRedoStacks({
      undoLength: -1,
      redoLength: -1,
    });
    if (map !== null && props.zoom) {
      map.setCenter(props.location);
      map.setZoom(props.zoom);
      setMapDataChange(false);
      setMapReadOnly(props.readOnly);

      const { userGroups } = props;

      if (userGroups && userGroups[0] === CREW_MEMBER) {
        setMapReadOnly(true);
      } else {
        setMapReadOnly(props.readOnly);
      }
    }
  }, [props.readOnly, props.elements]);

  React.useEffect(() => {
    setMapDataChange(props.isSaveMapSucess);
    if (props.isSaveMapSucess) {
      childRef.current.clearUndoRedoStacks();
    }
  }, [props.isSaveMapSucess]);

  React.useEffect(() => {
    deleteConfirmGroup();
  }, [props.deleteConfirmGroup]);

  React.useEffect(() => {
    const { userGroups, isMapDataLoaded } = props;
    if (userGroups && userGroups[0] === CREW_MEMBER) {
      setEditMode(DrawElementConst.GROUP);
      setMapReadOnly(props.readOnly);
      setTabPage(1);
    } else {
      setEditMode(DrawElementConst.EDIT);
      setMapReadOnly(props.readOnly);
      setTabPage(0);
    }
  }, []);

  const updateDrawOption = (value, settings) => {
    delete settings.error;
    const { maxWeight } = settings;
    const val = Number(value);
    if (maxWeight && 1 <= val && val <= maxWeight) {
      settings.strokeWeight = parseFloat(val, 10);
      let opts = drawingManager[`${drawingManager.drawingMode}Options`];
      if (!opts) {
        if (
          settings.drawType === ToolTypes.HOSE ||
          settings.drawType === ToolTypes.PIPE_LINE ||
          settings.drawType === ToolTypes.SET_LAYOUT
        ) {
          opts = drawingManager.polylineOptions;
          opts = { ...opts, ...settings };
        }
      }
      opts.actualStrokeWeight = value;
      opts.strokeWeight = calculateStrokeWeight(
        zoom,
        settings.strokeWeight,
        StrokeMinWeight
      );
      drawingManager.setOptions(opts);
    } else {
      props.showToast(
        `${settings.drawType} diameter should be 1 to ${maxWeight}.`,
        true,
        0
      );
      setSelectedDrawType(null);
      settings.error = true;
      drawingManager.setDrawingMode(null);
      setType(null);
    }
  };

  const setDrawOption = (item, settings) => {
    setSelected(null);
    if (settings.error || selectedDrawType === settings.drawType) {
      drawingManager.setDrawingMode(null);
      setSelectedDrawType(null);
      setType(null);
      if (settings.maxWeight && settings.error) {
        props.showToast(
          `${settings.drawType} diameter should be 1 to ${settings.maxWeight}.`,
          true,
          0
        );
      }
      return;
    }
    if (!!item) {
      const mySettings = Object.assign({}, settings);
      const newObject = {
        drawingMode: item,
      };
      if (
        settings.drawType === ToolTypes.HOSE ||
        settings.drawType === ToolTypes.PIPE_LINE ||
        settings.drawType === ToolTypes.SET_LAYOUT
      ) {
        mySettings.actualStrokeWeight = mySettings.strokeWeight;
        mySettings.strokeWeight = calculateStrokeWeight(
          zoom,
          mySettings.strokeWeight,
          StrokeMinWeight
        );
      }
      newObject[`${item}Options`] = {
        ...mySettings,
      };
      drawingManager.setOptions(newObject);
    } else {
      drawingManager.setDrawingMode(null);
    }
    setSelectedDrawType(settings.drawType);
    setType(item);
  };

  const onLoad = React.useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds(center);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback((map) => {
    setMap(null);
  }, []);

  const onSelectedLayer = (element) => {
    childRef.current.onSelectItem(element);
  };

  const onSelectedGroup = (group) => {
    if (!groupEdit) {
      group.selected = true;
      setTimeout(() => {
        setSelected(group);
        setSelectedGroup(group);
      });
    } else {
      setSelected(group);
    }
  };

  const onSelectedItem = (element) => {
    const tempDrawData = Object.assign([], drawData);
    const prevSelected = tempDrawData.find((x) => x.selected);
    prevSelected && delete prevSelected.selected;
    if (element) {
      element.selected = true;
    }
    if (editMode === DrawElementConst.GROUP) {
      if (groupEdit) {
        setSelected(element);
        setSelectedGroup(element);
        if (element.groupable) {
          const foundIndex = element.group.findIndex(
            (groupId) => groupId === selectedGroup.id
          );
          if (foundIndex === -1) {
            element.group.push(selectedGroup.id);
            childRefTopBar.current.addToGroup(element);
          } else {
            element.group.splice(foundIndex, 1);
            if (element.group.length > 0) {
              const visibility = isAnyGroupVisible(element.group);
              element.visible = visibility;
            } else {
              element.visible = true;
            }
            childRefTopBar.current.removeFromGroup(element);
          }
        }
      } else {
        setSelected(element);
      }
    } else {
      setSelected(element);
    }
  };

  const onCompleteChange = (data, { undoStack, redoStack }) => {
    if (data === null) {
      setSelectedDrawType(null);
      return;
    }
    setUndoRedoStacks({
      undoLength: undoStack.length,
      redoLength: redoStack.length,
    });
    setDeletedItemId(null);
    setSelectedDrawType(null);
    setMapDataChange(true);
    setDrawData(data);
    if (selected) {
      selected.calulatedValues = {
        totalArea: null,
        length: null,
        elevation: null,
        diameter: null,
        eleAndLengthChartData: null,
      };
      setSelected(null);
      setTimeout(() => setSelected(selected));
      if (selected.drawType === ToolTypes.FIELD) {
        updatedRelatedGroups(selected);
      }
    }
  };

  function updatedRelatedGroups(selected) {
    const relatedGroups = selected.group;
    relatedGroups.forEach((groupId) => {
      const matchdGroup = groups.find((group) => group.id === groupId);
      if (matchdGroup) {
        matchdGroup.calulatedValues = {
          totalArea: null,
        };
      }
    });
  }

  const onDrawManageLoad = (drawManager) => {
    setDrawingManager(drawManager);
  };

  const onChangeTab = (changeTabProps) => {
    if (
      groupEdit ||
      (selectedGroup && selectedGroup.state === DrawElementConst.EDIT)
    ) {
      if (changeTabProps.i === 0) {
        tabRef.current.goToPage(1);
      }
      return;
    }
    const newTabIndex = changeTabProps.i;
    setTabPage(newTabIndex);
    setSelected(null);
    setSelectedGroup(null);
    setMapReadOnly(props.readOnly);
    if (newTabIndex === 0) {
      setEditMode(DrawElementConst.EDIT);
    } else {
      setEditMode(DrawElementConst.GROUP);
    }
  };

  const onEditItem = (element) => {
    if (element === null) {
      const newGroupIndex = groups.findIndex((grp) => grp.newGroup);
      if (newGroupIndex > -1) {
        groups.splice(newGroupIndex, 1);
        setSelected(null);
        setSelectedGroup(null);
        setGroupsChanged(false);
        setGroupEdit(true);
        setTimeout(() => {
          setGroupsChanged(true);
          setGroupEdit(false);
        });
        setGroups(groups);
        setMapDataChange(true);
      }
      return;
    }

    const type = selected.type;
    const selectedDataSet = type === DrawElementConst.group ? groups : drawData;
    const slIndex = selectedDataSet.findIndex((x) => x.id === element.id);
    if (slIndex > -1) {
      childRef.current.updateBeforeEditItem(selectedDataSet[slIndex]);
      const tempData = Object.assign([], selectedDataSet);
      if (type === DrawElementConst.group && element.newGroup) {
        delete element.newGroup;
        element.state = DrawElementConst.EDIT;
      }
      tempData.splice(slIndex, 1, element);
      setSelected(element);
      if (type === DrawElementConst.group) {
        if (!groupEdit) {
          setGroupEdit(true);
        }
        setGroups(tempData);
      } else {
        setDrawData(tempData);
      }
    }
  };

  const onDeleteItem = (e) => {
    const type = selected.type;
    const selectedDataSet = type === DrawElementConst.group ? groups : drawData;
    const deleted = selected.id;
    const slIndex = selectedDataSet.findIndex((x) => x.id === selected.id);
    if (slIndex > -1) {
      if (type === DrawElementConst.group) {
        props.onShowWarning({
          deletegroup: true,
        });
        setSelected(selected);
        setSelectedGroup(selected);
      } else {
        const tempData = Object.assign([], selectedDataSet);
        tempData.splice(slIndex, 1);
        setDeletedItemId(deleted);
        setSelected(null);
      }
      setMapDataChange(true);
    }
  };

  function deleteConfirmGroup() {
    if (selected) {
      const type = selected.type;
      const selectedDataSet =
        type === DrawElementConst.group ? groups : drawData;
      const deleted = selected.id;
      const slIndex = selectedDataSet.findIndex((x) => x.id === selected.id);
      if (slIndex > -1) {
        const tempData = Object.assign([], selectedDataSet);
        tempData.splice(slIndex, 1);
        const tempDrawData = Object.assign([], drawData);
        tempDrawData.forEach((item) => {
          const itemInGroupIndex = item.group.findIndex(
            (groupId) => groupId === selected.id
          );
          if (itemInGroupIndex > -1) {
            item.group.splice(itemInGroupIndex, 1);
            item.visible = true;
          }
        });
        setSelected(null);
        setSelectedGroup(null);
        setGroups(tempData);
        setMapDataChange(true);
        setDrawData(tempDrawData);
      }
    }
  }

  const onChangeGroup = (groups) => {
    setGroups(groups);
    setMapDataChange(true);
  };

  const onChangeGroupVisibile = (groupIn) => {
    const chnagedGroupIndex = groups.findIndex(
      (group) => group.id === groupIn.id
    );
    groups.splice(chnagedGroupIndex, 1, groupIn);
    setGroups(groups);
    setGroupsChanged(false);
    setTimeout(() => setGroupsChanged(true));
    childRef.current.onGroupVisibilityChange(groupIn);
  };

  const onGroupAddRemoveItems = (groupId, item, mode) => {
    let selectedGrp = groups.find((group) => group.id === groupId);
    selectedGrp.calulatedValues = {
      totalArea: null,
    };
    setSelected(null);
    setTimeout(() => {
      setSelected(selectedGrp);
      setSelectedGroup(selectedGrp);
      setMapDataChange(true);
    });
  };

  const onEditGroupComplete = (groupId) => {
    let selectedGrp = groups.find((group) => group.id === groupId);
    setSelected(null);
    setTimeout(() => {
      setGroupEdit(false);
      setSelected(selectedGrp);
      setSelectedGroup(selectedGrp);
    });
  };

  const onGroupEdit = (groupId, item, mode) => {
    setMapDataChange(true);
    let selectedGrp = groups.find((group) => group.id === groupId);
    if (mode) {
      const tempGroups = Object.assign([], groups);
      const groupIndex = tempGroups.findIndex((group) => group === groupId);
      if (groupIndex > -1) {
        const newGroupItem = (tempGroups[tempGroups].state = mode);
        tempGroups.splice(groupIndex, 1, newGroupItem);
      }
      setGroupEdit(mode === DrawElementConst.EDIT);
    }
    if (item) {
      const tempDrawData = Object.assign([], drawData);
      const foundIndex = tempDrawData.findIndex(
        (element) => item.id === element.id
      );
      if (foundIndex > -1) {
        tempDrawData.splice(foundIndex, 1, item);
      }
    }
    setSelected(null);
    setTimeout(() => {
      setSelected(selectedGrp);
      setSelectedGroup(selectedGrp);
    });
  };

  const onZoomChange = () => {
    if (oldZoomVal === 0) {
      setOldZoomVal(zoom);
    }
    setZoom((map && map.getZoom()) || zoom);
  };

  const onIdleEvent = () => {
    setOldZoomVal(0);
    calcBounds();
  };

  const onClickMap = () => {
    if (!groupEdit) {
      setSelected(null);
      childRef.current.onSelectItem(null);
    }
  };

  function calcBounds() {
    if (map ? map.getBounds() : null) {
      setTimeout(() => {
        const bounds = map.getBounds();
        const sw = bounds.getSouthWest();
        const ne = bounds.getNorthEast();
        const southWest = new google.maps.LatLng(sw.lat(), sw.lng());
        const northEast = new google.maps.LatLng(ne.lat(), ne.lng());
        const southEast = new google.maps.LatLng(sw.lat(), ne.lng());
        const northWest = new google.maps.LatLng(ne.lat(), sw.lng());
        const SQM_Fraction = 0.00025;
        const calulatedArea = DistanceUtil.getAreaByPosition([
          northEast,
          northWest,
          southWest,
          southEast,
        ]);
        const totalArea = calulatedArea * SQM_Fraction;
        setZoomedAreaSize(DistanceUtil.roundWithDecimals(totalArea, 0));
        console.log("ViewPort Area:" + totalArea);
        console.log("Zoom Levels: Old = " + oldZoomVal + ": Current = " + zoom);
        childRef.current.onZoomChange({
          previousZoom: oldZoomVal,
          currentZoom: zoom,
        });
      }, 10);
    }
  }

  function prepareSaveData() {
    const saveGroups = Object.assign([], groups);
    const saveElements = Object.assign([], drawData);
    return {
      groups: saveGroups.map((grp) => {
        delete grp.selected;
        delete grp.expanded;
        return grp;
      }),
      elements: saveElements.map((element) => {
        if (element.selected) {
          element.icon = Images[`${element.img}_OFF`];
        }
        delete element.selected;
        delete element.label;
        return element;
      }),
      zoomLevel: zoom,
      location: (map.getCenter() && map.getCenter().toJSON()) || props.location,
      fieldCount: saveElements.filter(
        (element) => element.drawType === ToolTypes.FIELD
      ).length,
      isLocked: false,
    };
  }

  const onBackClick = () => {
    const saveData = prepareSaveData();
    if (!props.readOnly) {
      props.onExitMap(mapDataChange, saveData);
    } else {
      props.onExitMap(false, saveData);
    }
  };

  const onEditMap = () => {
    navigate("Site", {
      location: center,
      zoom: zoom,
      readOnly: false,
    });
  };

  const onGroupEditCancel = (groupId, tempActions, mode) => {
    let selectedGrp = groups.find((group) => group.id === groupId);
    const tempDrawData = Object.assign([], drawData);
    tempActions.forEach((groupAction) => {
      const foundItemInData = drawData.find(
        (drawItem) => drawItem.id === groupAction.item.id
      );
      const groupItemIndex = foundItemInData.group.findIndex(
        (grpId) => grpId === groupId
      );
      if (groupItemIndex > -1) {
        if (groupAction.action === DrawElementConst.GROUP) {
          foundItemInData.group.splice(groupItemIndex, 1);
        } else {
          foundItemInData.group.push(groupId);
        }
      } else {
        if (groupAction.action === DrawElementConst.UNGROUP) {
          foundItemInData.group.push(groupId);
        }
      }
    });
    setSelected(null);
    setTimeout(() => {
      setGroupEdit(false);
      setSelected(selectedGrp);
      setSelectedGroup(selectedGrp);
    });
  };

  const onSaveMapData = () => {
    const saveData = prepareSaveData();
    props.onSaveMapData(saveData);
  };

  const onPerformUndo = () => {
    childRef.current.onApplyUndoRedo(UNDO_REDO_CONSTANTS.UNDO);
    setTimeout(() => {
      setSelected(null);
    });
  };

  const onPerformRedo = () => {
    childRef.current.onApplyUndoRedo(UNDO_REDO_CONSTANTS.REDO);
    setTimeout(() => {
      setSelected(null);
    });
  };

  const isFieldAvalableInDroup = (groupId) => {
    const foundFieldInGroup = drawData
      .filter(
        (element) =>
          element.drawType === ToolTypes.FIELD && element.group.length > 0
      )
      .find((filed) => filed.group.find((grpId) => grpId === groupId));
    return foundFieldInGroup;
  };

  const getEementsByGroupId = (groupId) => {
    return drawData
      .filter((element) => element.group.find((grpId) => grpId === groupId))
      .filter((element) => element.drawType === ToolTypes.FIELD);
  };

  const isAnyGroupVisible = (groupIds) => {
    const found = groupIds.find((groupId) =>
      groups.find((group) => group.id === groupId && !group.visible)
    );
    return found ? true : false;
  };

  const deleteItemById = (itemId) => {
    onDeleteItem();
    setTimeout(() => {
      onClickMap();
    });
  };

  return (
    <Grid
      style={[
        styles.mainContainer,
        props.getMapFullScreenStatus ? styles.fullScreen : {},
      ]}
    >
      {!props.isMapDataLoaded ? (
        <View
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 999,
          }}
        >
          <Spinner style={{ height: "100%" }} color={"red"} />
        </View>
      ) : (
        <></>
      )}
      <Row size={5} style={[styles.topContainer]}>
        <TopBarPop
          ref={childRefTopBar}
          style={[styles.topSubContainer]}
          selected={selected}
          readOnly={props.readOnly}
          groupEditMode={groupEdit}
          map={map}
          mode={editMode}
          onSaveMapData={onSaveMapData}
          onEditMap={onEditMap}
          onDeleteItem={onDeleteItem}
          onEditItem={onEditItem}
          backBtnClick={onBackClick}
          onAddRemoveToGroup={onGroupAddRemoveItems}
          onAddRemoveToGroupCancel={onGroupEditCancel}
          onEditGroupComplete={onEditGroupComplete}
          isFieldAvalableInDroup={isFieldAvalableInDroup}
          getGroupElement={getEementsByGroupId}
        />
      </Row>
      <Row style={[styles.row, styles.bottomContainer]} size={75}>
        <AccessControl
          allowedGroups={[ADMIN, MANUFACTURER, CREW_LEADER, DEALER, OWNER]}
        >
          <Col
            size={props.readOnly ? 0 : 1.6}
            style={[styles.toolBox, props.readOnly ? { display: "none" } : {}]}
          >
            <View>
              <Tabs
                tabContainerStyle={[styles.tabContainStyle]}
                ref={(ref) => {
                  tabRef.current = ref;
                }}
                style={[styles.tabPanel]}
                page={tabPage}
                onChangeTab={onChangeTab}
                tabBarUnderlineStyle={{
                  backgroundColor: "#EEB310",
                  marginRight: 5,
                  width: 0,
                  height: 0,
                  borderRadius: 5,
                }}
              >
                <Tab
                  heading="MAP"
                  tabStyle={[styles.tabPanel, styles.mapTap]}
                  activeTabStyle={[styles.activeMapPanel]}
                  textStyle={{ color: "#616161", fontWeight: "700" }}
                  activeTextStyle={{ color: "#1A1A1A" }}
                  style={[styles.tabPanel, { paddingHorizontal: 10 }]}
                >
                  <Row style={[styles.Undopanel]}>
                    <UndoRedoButton
                      disabled={undoRedoStacks.redoLength < 0}
                      onClick={() => {}}
                      imageName={"UNDO_ICON"}
                      buttonWithText={true}
                      label={"UNDO"}
                      onPress={onPerformUndo}
                    />
                    <UndoRedoButton
                      disabled={undoRedoStacks.undoLength < 0}
                      onClick={() => {}}
                      imageName={"REDO_ICON"}
                      buttonWithText={true}
                      label={"REDO"}
                      onPress={onPerformRedo}
                    />
                  </Row>
                  <Row style={{ minHeight: 400, marginTop: "2.5%" }}>
                    <Col>
                      {tools.map((tool) => {
                        return (
                          <ToolBoxButtons
                            key={tool.name}
                            drawType={tool.data.drawType}
                            width={tool.data.strokeWeight}
                            buttonWithText={true}
                            label={tool.label}
                            imageName={tool.img}
                            pressed={selectedDrawType === tool.data.drawType}
                            onChangeDiameter={(value) =>
                              updateDrawOption(value, tool.data)
                            }
                            onPress={() => {
                              setDrawOption(tool.googleDrawType, tool.data);
                            }}
                          />
                        );
                      })}
                    </Col>
                  </Row>
                </Tab>

                <Tab
                  heading="GROUPS"
                  tabStyle={[styles.tabPanel, styles.groupTab]}
                  activeTabStyle={[styles.activeGroupPanel]}
                  textStyle={{ color: "#616161", fontWeight: "700" }}
                  activeTextStyle={{ color: "#1A1A1A" }}
                  style={styles.tabPanel}
                >
                  <GroupPanel
                    drawData={drawData}
                    selected={selected}
                    groups={groups}
                    selectedTab={tabPage}
                    groupsChanged={groupsChanged}
                    onSelectLayer={onSelectedLayer}
                    onSelectGroup={onSelectedGroup}
                    onChangeGroup={onChangeGroup}
                    onGroupEdit={onGroupEdit}
                    onChangeGroupVisibility={onChangeGroupVisibile}
                  />
                </Tab>
              </Tabs>
            </View>
          </Col>
        </AccessControl>

        <AccessControl allowedGroups={[CREW_MEMBER]}>
          <Col
            size={props.readOnly ? 0 : 1.6}
            style={[styles.toolBox, props.readOnly ? { display: "none" } : {}]}
          >
            <GroupPanel
              drawData={drawData}
              selected={selected}
              groups={groups}
              selectedTab={tabPage}
              onSelectLayer={onSelectedItem}
              onSelectGroup={onSelectedGroup}
              onChangeGroup={onChangeGroup}
              onGroupEdit={onGroupEdit}
              onChangeGroupVisibility={onChangeGroupVisibile}
            />
          </Col>
        </AccessControl>

        <Col size={props.readOnly ? 12 : 10.4} style={[styles.mapContainer]}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            options={mapOptions}
            center={center}
            zoom={zoom}
            onZoomChanged={onZoomChange}
            onIdle={onIdleEvent}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onClick={onClickMap}
          >
            {/* Child components, such as markers, info windows, etc. */}
            {/* <ElevationChat/> */}
            <MapElements
              ref={childRef}
              drawData={drawData}
              map={map}
              drawManager={drawingManager}
              readOnly={mapReadOnly}
              mode={editMode}
              drawType={type}
              selectedGroup={selectedGroup}
              selectedItem={selected}
              groupEdit={groupEdit}
              onShowWarning={props.onShowWarning}
              deletedItemId={deletedItemId}
              isAnyGroupVisible={isAnyGroupVisible}
              onSelected={onSelectedItem}
              onChangeComplete={onCompleteChange}
              onDrawManage={onDrawManageLoad}
              onDeleteItemById={deleteItemById}
            />
          </GoogleMap>
        </Col>
      </Row>
    </Grid>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    showToast: (message, isError) => dispatch(showToast(message, isError)),
  };
};

const mapStateToProps = (state) => {
  return {
    getMapFullScreenStatus: state.siteMap.isMapFulScreen,
    isSaveMapSucess: state.siteMap.isSaveSiteMap,
    isMapDataLoaded: state.siteMap.isLoadSiteMap,
    userGroups: getUserGroups(state),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MapEditor);
