import { StyleSheet } from 'react-native';
import {
  roboto,
  textLight,
} from '_style/Global.style';
import { dark_gray, light_gray, lighter_gray } from '../../style/Global.style';

export const styles = StyleSheet.create({
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    width: '100%',
    flex: 1,
  },
  tableCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    height: '100%',
    flex: 1,
    paddingLeft: 10,
    paddingRight: 10,
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 10,
    paddingRight: 10,
  },
  headerRow: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  tableText: {
    fontFamily: roboto,
    fontSize: 14,
    color: textLight,
    letterSpacing: 1.6,
  },
  highlightHeader: {
    backgroundColor: light_gray,
    borderColor: lighter_gray,
    borderWidth: 1,
    borderRadius: 2,
  },
  highlightRow: {
    backgroundColor: dark_gray,
    borderRadius: 2,
  },
  rightSeparator: {
    borderRightWidth: 1,
    borderRightColor: '#757575',
  }
});
