// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Image, TouchableOpacity } from 'react-native';

// Redux
import { connect } from 'react-redux';

// Native Base
import {
  View,
} from 'native-base';


import { styles as global } from '_style/Global.style';
import { styles } from '_components/device_settings/ShowHideGroup.style';

class ShowHideGroup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
    };

    this._showOn = this._showOn.bind(this);
    this._hideOn = this._hideOn.bind(this);
  }

  _showOn() {
    const { onShow } = this.props;
    this.setState({
      show: true,
    });
    onShow();
  }

  _hideOn() {
    const { onHide } = this.props;
    this.setState({
      show: false,
    });
    onHide();
  }

  render() {
    const { show } = this.state;

    return (
      <View style={[styles.showHideGroup]}>
        {
          show ?
          <>
              <TouchableOpacity disabled>
                <Image
                  style={ [styles.showButtonOn] }
                  source={require('_assets/images/show-button-on.png')}
                />
              </TouchableOpacity>

              <TouchableOpacity onPress={this._hideOn}>
                <Image
                  style={ [styles.hideButtonOff] }
                  source={require('_assets/images/hide-button-off.png')}
                />
              </TouchableOpacity>
            </>
            :
            <>
              <TouchableOpacity onPress={this._showOn}>
                <Image
                  style={ [styles.showButtonOff] }
                  source={require('_assets/images/show-button-off.png')}
                />
              </TouchableOpacity>

              <TouchableOpacity disabled>
                <Image
                  style={ [styles.hideButtonOn] }
                  source={require('_assets/images/hide-button-on.png')}
                />
              </TouchableOpacity>
            </>
        }

      </View>
    );
  }
}

ShowHideGroup.propTypes = {
  onShow: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default connect(null, null)(ShowHideGroup);
